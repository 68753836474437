import React, { Component } from 'react';
import { BlankCard, FormatAmountNumber, StyledModal } from '../Base/CommonUIComponents';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import moment from 'moment-timezone';

class ProposalHistoryModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            pageSize: 10,
            pageIndex: 0,
            proposalHistoryList: []
        };
    }

    componentDidMount(){
        this.getproposalHistoryList();
    }

    getproposalHistoryList = () => {
        const { salesDealId } = this.props;
        const { pageSize, pageIndex } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ proposalHistoryList: data.data, block: false });
                return;
            }
            else
                this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/ProposalHistory/${salesDealId}?pageSize=${pageSize}&pageIndex=${pageIndex}`);
    }

    doSearch = () => {
        this.setState({ block: true }, () => 
            this.getproposalHistoryList()
        );
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getproposalHistoryList());
        }
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getproposalHistoryList());
    }

    render() {
        const { toggleModal, modal } = this.props;
        const { block, error, pageSize, pageIndex, proposalHistoryList } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} className="bigModal" size="xl">
                <BlankCard block={block} error={error}>
                    <Row>
                        <Col>
                            <h5><FormattedMessage id="SalesDeal.ProposalHistory"/></h5>
                        </Col>
                        <Col className='text-right'>
                            <Button className="btn-sm btn-host " onClick={this.doSearch}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                    {proposalHistoryList && proposalHistoryList.length > 0 ?
                        <div className='mt-2'>
                            <div className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white' style={{ borderRadius: '4px', gap: '1%' }}>
                                <div style={{ width: '15%' }} className='text-muted'>
                                    <FormattedMessage id={`SalesDeal.ResNumber`}/>
                                </div>
                                <div style={{ width: '10%' }} className='text-muted'>
                                    <FormattedMessage id={`SalesDeal.Version`}/>
                                </div>
                                <div style={{ width: '15%' }} className='text-muted'>
                                    <FormattedMessage id={`SalesDeal.UpdatedAt`}/>
                                </div>
                                <div style={{ width: '15%' }} className='text-muted'>
                                    <FormattedMessage id={`SalesDeal.CheckIn`}/>
                                </div>
                                <div style={{ width: '15%' }} className='text-muted'>
                                    <FormattedMessage id={`SalesDeal.CheckOut`}/>
                                </div>
                                <div style={{ width: '15%' }} className='text-muted text-right'>
                                    <FormattedMessage id={`SalesDeal.TotalValue`}/>
                                </div>
                            </div>
                            <div style={{ maxHeight: '50vh', overflow: 'auto' }} className='veryCoolScrollBar'>
                                {proposalHistoryList.map((res, key) =>
                                    <div key={key}
                                        className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white'
                                        style={{
                                            borderRadius: '4px',
                                            gap: '1%'
                                        }}
                                    >
                                        <div style={{ width: '15%' }}>
                                            <div title={res.externalId} className='text-truncate'>
                                                {res.externalId}
                                            </div>
                                        </div>
                                        <div style={{ width: '10%' }}>
                                            <div title={res.version} className='text-truncate'>
                                                {res.version}
                                            </div>
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <div title={res.updatedAt} className='text-truncate'>
                                                {moment(res.updatedAt).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <div title={res.startDate} className='text-truncate'>
                                                {moment(res.startDate).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <div title={res.endDate} className='text-truncate'>
                                                {moment(res.endDate).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                        <div style={{ width: '15%' }}>
                                            <div title={res.totalValue} className='text-truncate text-right'>
                                                <FormatAmountNumber currency={global.hotelCurrency} value={res.totalValue??0}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Row className="mt-3">
                                <Col>
                                    <ButtonGroup onClick={this.changePageSize}>
                                        <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                        <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                        <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                        <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                    </ButtonGroup>
                                </Col>

                                <Col className="text-right">
                                    <span>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                            <i className="fas fa-angle-left" />
                                        </Button>
                                        <span className="mr-2">
                                            <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                        </span>
                                        <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={proposalHistoryList && proposalHistoryList.length < pageSize}>
                                            <i className="fas fa-angle-right" />
                                        </Button>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    :
                        <div>
                            <FormattedMessage id="SalesDeal.NoProposalHistory"/>
                        </div>
                    }
                </BlankCard>
            </StyledModal>
        );
    }
}

export default ProposalHistoryModal;
