import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import html2canvas from "html2canvas";


class RenderHtmlAsImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            imageSrc: null,
        };

        this.templateRef = React.createRef();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;

        this.generateImage();
    }

    generateImage = () => {
        window.setTimeout(
            () => {
                if (this.templateRef.current) {
                    var iframe = this.templateRef.current;

                    html2canvas(iframe.contentDocument.documentElement).then((canvas) => {
                        canvas.style.width = `100%`;
                        canvas.style.height = `100%`;

                        document.getElementById(this.props.id).appendChild(canvas);
                    });
                }
            },
            500
        );
    };

    render() {
        const { html, id } = this.props;

        return (
            <div>
                <iframe
                    id="myIframe"
                    title="Meu iFrame"
                    srcDoc={html}
                    height="100%"
                    width="100%"
                    style={{ position: 'relative', left: '-999999999999px' }}
                    ref={this.templateRef}
                />
                <div id={id} className="text-center" ></div>
            </div>
        );
    }
}

export default injectIntl(RenderHtmlAsImage);