import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard, KebabMenu, StyledCard, StyledModal } from "../../Base/CommonUIComponents";
import { Button, Col, Row, Card, Input, Form, FormGroup } from "reactstrap";
import { getAPI, postAPI, deleteAPI } from "../../Base/API";
import { handleNotification } from '../../Base/Notification';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import CustomSelect from "../../Base/CustomSelect";


class LoyaltyCardLevels extends Component {
    constructor(props) {
        super(props);

        this.state = {
            Loyalty: null,
            block: true,
            hasLoyalty: false,
            deleteModal: false,
            loyaltyCardTypes: []
        };
    }

    componentDidMount = () => {
        this.GetLevels();
    }

    GetLevels = () =>{
        getAPI(result => {
            const { data, error } = result;

            const hasLoyalty = global.modules && global.modules.some(m => m === 'LoyaltyProgram');
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false, hasLoyalty: hasLoyalty });

                return;
            }
            if (data) {
                const Focused = data.response.map((level) => { return ({ id: level.id, code: false, minRoomNightsToLevel: false, minPointsToLevel: false }) });

                data.response.sort((a, b) => (a.minRoomNightsToLevel + a.minPointsToLevel > b.minRoomNightsToLevel + b.minPointsToLevel)
                    ? 1 : ((b.minRoomNightsToLevel + b.minPointsToLevel > a.minRoomNightsToLevel + a.minPointsToLevel) ? -1 : 0));

                this.setState({ error: errorMessage, Loyalty: data.response, Focused: Focused, hasLoyalty: hasLoyalty }, () => this.getLoyaltyCardTypes());
            }
            else {
                this.setState({ error: errorMessage, block: false, hasLoyalty: hasLoyalty });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`);
    }

    saveLoyaltyCardLevel = (e, card) => {
        e.preventDefault();
        const { Loyalty } = this.state;

        if (card.minPointsToLevel !== null && Loyalty.some(l => (card.id ? l.id !== card.id : true) && l.loyaltyCardTypeConfigCode === card.loyaltyCardTypeConfigCode && l.minPointsToLevel === card.minPointsToLevel)) {
            const data = { errors: [{ code: '', message: <FormattedMessage id="LoyaltyCardLevel.LoyaltyCardsCannotHaveSameMinPoints" /> }] };
            
            handleNotification(data);
            return;
        }


        card.minRoomNightsToLevel = 0;

        const body = {
            "request": card
        };


        if(card.isNew){
            body.request.id= '';
        }

        this.setState({ blockSave: true });

        postAPI(result => {
            const { data, error } = result;
                    
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ errorSave: errorMessage, blockSave: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);

                    this.setState({ blockSave: false, errorSave: null });
                }
                else if (data.response) {
                    if (card.id) {
                        const index = Loyalty.findIndex(l => l.id === card.id);

                        Loyalty[index] = data.response[0];
                    }
                    else Loyalty.push(data.response[0])

                    this.OrderCards(Loyalty);
                    this.setState({ Loyalty, blockSave: false, errorSave: null, modal: false });

                    handleNotification(data, this.props.intl.formatMessage({ id: "LoyaltyCardLevel.Success" }));
                }
            }
            else {
                this.setState({ blockSave: false, errorSave: null });
            }
        },`/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig`, body)
    }

    OrderCards = (Loyalty) => {
        Loyalty.sort((a,b) => (a.minRoomNightsToLevel + a.minPointsToLevel > b.minRoomNightsToLevel + b.minPointsToLevel)
        ? 1 : ((b.minRoomNightsToLevel + b.minPointsToLevel > a.minRoomNightsToLevel + a.minPointsToLevel) ? -1 : 0));
    }

    deleteCard = (id) => {
        this.setState({ block: true }, () => {
            const { Loyalty } = this.state;
            const card = Loyalty.find((card) => card.id === id);

            if(card.isNew){
                Loyalty.splice(Loyalty.indexOf(card), 1);
            }
            else{
                deleteAPI(result => {
                    const { data, error } = result;

                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                        this.setState({ error: errorMessage, block: false });

                        return;
                    }
                    if (data) {
                        Loyalty.splice(Loyalty.indexOf(card), 1);

                        this.OrderCards(Loyalty);

                        this.setState({ Loyalty, block: false, deleteModal: false, selectedConfig: null });
                        handleNotification(data, this.props.intl.formatMessage({ id: "LoyaltyCardLevel.Success" }))
                    }
                },`/api/gms/LoyaltyCard/v1/LoyaltyCardLevelConfig/${id}`)
            }
            this.setState({ Loyalty });
        });
    }

    toggleModal = (modal, value) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedConfig: value
        }));
    }

    getLoyaltyCardTypes = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, loyaltyCardTypes: data.response?.map((el) => ({ label: el.description, code: el.code, value: el.code })) });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/LoyaltyCard/v1/LoyaltyCardTypeConfig`);
    }

    render() {
        const { block, error, Loyalty, hasLoyalty, selectedConfig, modal, deleteModal, loyaltyCardTypes } = this.state;

        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-2">
                    <Col>
                        <h5>
                            <i className="fas fa-id-card-alt mr-2" />
                            <FormattedMessage id="LoyaltyCardLevel.Title" />
                        </h5>
                    </Col>
                    <Col className="text-right col-2">
                        <Button className="btn-sm btn-host mr-2" onClick={() => this.toggleModal('modal', null)}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                {Loyalty ?
                    <div className="p-2">
                        {loyaltyCardTypes.filter(type => Loyalty.find(({loyaltyCardTypeConfigCode}) => loyaltyCardTypeConfigCode === type.value)).map((type, key) =>
                            <div key={key}>
                                {key > 0 ? <hr/> :''}
                                <div className="mt-2">
                                    <div>
                                        <b>{type.code}</b>
                                        <span className="mx-1">
                                            -
                                        </span>
                                        <span>
                                            {type.label}
                                        </span>
                                    </div>
                                    <Row className="mt-2">
                                        {Loyalty.filter(({loyaltyCardTypeConfigCode}) => loyaltyCardTypeConfigCode === type.value).map((level, key) =>
                                            <Col key={key} className="col-3 p-2" style={{ padding: '1.2rem', borderRadius: '5px' }}>
                                                <Card className="border-0 shadow h-100 p-2 px-3">
                                                    <Row>
                                                        <Col>
                                                            <div className="title-sm"> <FormattedMessage id="LoyaltyCardLevel.Code" /> </div>
                                                            <b>{level.code}</b>
                                                        </Col>
                                                        {hasLoyalty ?
                                                            <Col className="col-3 text-center pr-0">
                                                                <div className="title-sm" style={{ color: '#0665ff' }}> Min </div>
                                                                <b>{level.minPointsToLevel === null ? '-' : level.minPointsToLevel}</b>
                                                            </Col>
                                                        : ''}
                                                        <Col className="col-1 pr-2 text-right">
                                                            <KebabMenu
                                                                editFunction={() => this.toggleModal('modal', level)}
                                                                deleteFunction={() => this.toggleModal('deleteModal', level)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-3">
                                                        <div className="title-sm"> <FormattedMessage id="LoyaltyCardLevel.Description" /> </div>
                                                        <span title={level.description} className="text-ellipsis" style={{ display: 'block' }}>
                                                            {level.description}
                                                        </span>
                                                    </div>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </div>
                        )}
                        {Loyalty.find(({loyaltyCardTypeConfigCode}) => !loyaltyCardTypeConfigCode) ?
                            <>
                                {Loyalty.find(({loyaltyCardTypeConfigCode}) => loyaltyCardTypeConfigCode) ?
                                    <>
                                        <hr/>
                                        <div>
                                            <FormattedMessage id="LoyaltyCardLevel.Default"/>
                                        </div>
                                    </>
                                :''}
                                <Row className="mt-2">
                                    {Loyalty.filter(({loyaltyCardTypeConfigCode}) => !loyaltyCardTypeConfigCode).map((level, key) =>
                                        <Col key={key} className="col-3 p-2" style={{ padding: '1.2rem', borderRadius: '5px' }}>
                                            <Card className="border-0 shadow h-100 p-2 px-3">
                                                <Row>
                                                    <Col>
                                                        <div className="title-sm"> <FormattedMessage id="LoyaltyCardLevel.Code" /> </div>
                                                        <b>{level.code}</b>
                                                    </Col>
                                                    {hasLoyalty ?
                                                        <Col className="col-3 text-center pr-0">
                                                            <div className="title-sm" style={{ color: '#0665ff' }}> Min </div>
                                                            <b>{level.minPointsToLevel === null ? '-' : level.minPointsToLevel}</b>
                                                        </Col>
                                                    : ''}
                                                    <Col className="col-1 pr-2 text-right">
                                                        <KebabMenu
                                                            editFunction={() => this.toggleModal('modal', level)}
                                                            deleteFunction={() => this.toggleModal('deleteModal', level)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <div className="mt-3">
                                                    <div className="title-sm"> <FormattedMessage id="LoyaltyCardLevel.Description" /> </div>
                                                    <span title={level.description} className="text-ellipsis" style={{ display: 'block' }}>
                                                        {level.description}
                                                    </span>
                                                </div>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </>
                        :''}
                    </div>
                :''}

                {modal ?
                    <CardLevelModal
                        modal={modal}
                        loyaltyCardTypes={loyaltyCardTypes}
                        toggleModal={() => this.toggleModal('modal', null)}
                        config={selectedConfig}
                        hasLoyalty={hasLoyalty}
                        saveLoyaltyCardLevel={this.saveLoyaltyCardLevel}
                        block={this.state.blockSave}
                        error={this.state.errorSave}
                    />
                : ''}


                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal', selectedConfig)}
                        actionFunction={() => this.deleteCard(selectedConfig.id)}
                        text={<FormattedMessage id="LoyaltyCardLevel.ConfirmDeleteText" values={{ level: selectedConfig.code }} />}
                    />
                : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(LoyaltyCardLevels)



class CardLevelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: this.props.config || {}
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: isNaN(parseInt(value)) ? value : parseInt(value)
            }
        }));
    }

    handleSelect = (combo, name) => {
        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const { modal, toggleModal, hasLoyalty, saveLoyaltyCardLevel, loyaltyCardTypes } = this.props;
        const { config } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="lg">
                <BlankCard block={this.props.block} error={this.props.error}>
                    <Form onSubmit={(e) => saveLoyaltyCardLevel(e, config)}>
                        <Row className="align-items-center">
                            <Col>
                                <h5>{config.id ? <FormattedMessage id="LoyaltyCardLevel.EditCardLevel" /> : <FormattedMessage id="LoyaltyCardLevel.CreateCardLevel" />}</h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <FormGroup row className="pt-2">
                            <Col sm={6}>
                                <div> <FormattedMessage id="LoyaltyCardLevel.Code" /> </div>
                                <Input
                                    required
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    value={config.code}
                                    name="code"
                                    maxLength={20}
                                />
                            </Col>

                            {hasLoyalty ?
                                <>
                                    <Col sm={6}>
                                        <div> <FormattedMessage id="LoyaltyCardLevel.MinPointsToLevel" /> </div>
                                        <Input
                                            type="number"
                                            onChange={(e) => this.handleChange(e)}
                                            value={config.minPointsToLevel}
                                            name="minPointsToLevel"
                                            min={0}
                                        />
                                    </Col>
                                </>
                            : ''}
                            {global.modules && global.modules.some(m => m === 'OneCardByType') ?
                                <Col sm={6} className="mt-2">
                                    <div> <FormattedMessage id="LoyaltyCardLevel.CardType"/> </div>
                                    <CustomSelect
                                        isSearchable={false}
                                        placeholder={""}
                                        required={true}
                                        options={loyaltyCardTypes}
                                        onChange={(e) => this.handleSelect(e, 'loyaltyCardTypeConfigCode')}
                                        value={config.loyaltyCardTypeConfigCode ? loyaltyCardTypes.find(el => el.value === config.loyaltyCardTypeConfigCode) : null}
                                    />
                                </Col>
                            :''}
                            <Col sm={12} className="mt-2">
                                <div> <FormattedMessage id="LoyaltyCardLevel.Description" /> </div>
                                <Input
                                    required
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    value={config.description}
                                    name="description"
                                    maxLength={50}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </BlankCard>
            </StyledModal>
        )
    }
}