import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Col, Row, Button, UncontrolledTooltip, Card } from 'reactstrap';
import { StyledCard, StyledModal } from "../../Base/CommonUIComponents";
import { CustomTable } from '../../Base/CustomTable';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import moment from 'moment';


class BulkVouchersCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            vouchers: [],
            columns: this.getTableColumns() 
        };
    }

    componentDidMount() {
    }

    getTableColumns = () => {
        return csvFields.map(el => {
            return {
                dataField: el.dataField,
                text: this.props.intl.formatMessage({ id: `BulkVouchersCreation.${el.text}` })
            }
        }).concat([
            {
                dataField: 'status',
                text: 'Status',
                formatter: (cell, row, idx) => this.getRowStatus(cell, row, idx) 
            }
        ])
    }

    getRowStatus = (cell, row, idx) => {
        return (
            <div>
                {!cell &&
                    <UncontrolledTooltip target={`Tooltip${idx}`} placement="left" >
                        <div>
                            <b><FormattedMessage id='BulkVouchersCreation.Validations' />:</b>

                            {row.rowValidations.map((validation, key) => {
                                return (
                                    <div key={key}><FormattedMessage id={`BulkVouchersCreation.${validation}`} /></div>
                                )
                            })}
                        </div>
                    </UncontrolledTooltip>
                }
                <span id={`Tooltip${idx}`}>
                    <i style={{ color: cell ? '#76EFAC' : '#EE6A5F' }} className={`fas fa-${cell ? 'check' : 'times'}`}></i>
                </span>
            </div>
        )
    }

    emitVouchersBulk = (e) => {
        e.preventDefault();        
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="BulkVouchersCreation.VouchersCreated" />, <FormattedMessage id="generic.success" />);

                    this.setState({
                        error: errorMessage,
                        createdVouchers: data.response,
                        createdVouchersModal: true,
                        vouchers: [],
                        fileName: null,
                        block: false
                    });
                }
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/Voucher/v1/vouchers/bulk`, JSON.stringify({ request: this.state.vouchers }));
    }

    handleCsvFile = (csv) => {
        var reader = new FileReader();
        const fileName = csv.name;

        reader.readAsText(csv);

        reader.onload = (e) => {
            var allTextLines = e.target.result.split(/\r\n|\n/).filter(t => t);
            var lines = allTextLines.map(data => data.split(/,|;/));

            if (lines[0] && lines[0].some(el => el === 'External CRM Id')) {
                lines.shift();
            }
            
            if (lines.length > 1000) {
                const data = {
                    warnings: [
                        { message: <FormattedMessage id="BulkVouchersCreation.MaxLengthMessage" /> }
                    ]
                }

                handleNotification(data);

                this.setState({ vouchers: [], fileName: null });
                return;
            }

            var list = [];

            lines.forEach((line) => {
                var object = {};

                csvFields.forEach((el, idx) => {
                    object[el.dataField] = line[idx]
                })

                object.status = true;
                object.rowValidations = [];


                if (!object.externalCrmId && !object.externalId) {
                    object.rowValidations.push("MissingExternalId");
                    object.status = false;
                }

                if (!object.firstName && !object.lastName) {
                    object.rowValidations.push("MissingProfileName");
                    object.status = false;
                }

                if (object.email && !object.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    object.rowValidations.push("InvalidEmail");
                    object.status = false;
                }

                if (!object.voucherTypeId) {
                    object.rowValidations.push("MissingVoucherType");
                    object.status = false;
                }
                else if (isNaN(object.voucherTypeId)) {
                    object.rowValidations.push("InvalidVoucherType");
                    object.status = false;
                }

                if (object.salePrice && isNaN(object.salePrice)) {
                    object.rowValidations.push("InvalidSalePrice");
                    object.status = false;
                }
                if (object.voucherValue && isNaN(object.voucherValue)) {
                    object.rowValidations.push("InvalidVoucherValue");
                    object.status = false;
                }

                if (object.validUntil && this.isValidDate(object.validUntil) === false) {
                    object.rowValidations.push("InvalidValidUntil");
                    object.status = false;
                }

                if (object.hotelId && !object.hotelId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
                    object.rowValidations.push("InvalidHotelId");
                    object.status = false;
                }

                if ((object.resIdValue || object.resIdDate || object.hotelId) && !(object.resIdValue && object.resIdDate && object.hotelId)) {
                    object.rowValidations.push("MissingResData");
                    object.status = false;
                }


                list.push(object);
            })

            this.setState({ vouchers: list, fileName });
        }
    }

    isValidDate = (string) => {
        let isValid = false;
        const dateValues = string.includes('-') ? string.split('-') : string.split('/');

        if (dateValues.length === 3 && dateValues[0].length === 4 && dateValues[1].length === 2 && dateValues[2].length === 2) {
            const month = parseInt(dateValues[1]);
            const day = parseInt(dateValues[2]);
            const daysInMonth = moment(`${dateValues[0]}-${month}`, "YYYY-MM").daysInMonth();

            if (month > 0 && month < 13 && day > 0 && day <= daysInMonth) {
                isValid = true;
            }
        }

        return isValid;
    }

    handleFiles = (event) => {
        var files = event.target.files;

        if (window.FileReader && files[0].size < 2097152) {
            this.handleCsvFile(files[0]);
        }
        else {
            this.setState({ fileName: null });
            this.errorHandler("FileReader is not supported in this browser.")
        }
    };

    fileChanged = (e) => {
        e.target.value = null;
    };

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }))
    }


    render() {
        const { block, error, columns } = this.state;
        
        return (
            <StyledCard block={block} error={error} icon="icon-icon-vouchers" title="NavMenu.BulkVouchersCreation" help={help} id="bulkVouchersCreationHelp">
                <Row className="mb-4 mt-3">
                    <Col sm={4}>
                        <div className="custom-file">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="customFile"
                                onChange={(e) => this.handleFiles(e)}
                                accept=".csv"
                                required
                                onClick={(e) => this.fileChanged(e)}
                            />
                            <label className="custom-file-label" htmlFor="customFile">
                                {this.state.fileName ? this.state.fileName : <FormattedMessage id="generic.ChooseFile" />}
                            </label>
                        </div>
                    </Col>
                    <Col className="text-right">
                        <Button disabled={this.state.vouchers.length < 1 || !this.state.vouchers.every(v => v.status)} className="btn btn-sm btn-host float-right" onClick={this.emitVouchersBulk}>
                            <i className="fas fa-save"></i>
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <CustomTable
                            data={this.state.vouchers}
                            columns={columns}
                            totalSize={this.state.vouchers.length}
                            shadow={false}
                            exportCSV={true}
                            showTotal={true}
                            search={true}
                            wrapperClasses="table-responsive"
                            hideSizePerPage={false}
                        />
                    </Col>
                </Row>

                {this.state.createdVouchersModal &&
                    <StyledModal toggleModal={() => this.toggleModal("createdVouchersModal")} modal={this.state.createdVouchersModal} size="xl" style={{ minWidth: 0, maxWidth: '80%', width: '80%' }}>
                        <Row className="align-items-center mb-3 mt-2">
                            <Col>
                                <h5><FormattedMessage id="BulkVouchersCreation.CreatedVouchers" /></h5>
                            </Col>
                        </Row>

                        <Card className="border-0 shadow py-2 text-muted mb-3" body>
                            <Row>
                                <Col> <FormattedMessage id="BulkVouchersCreation.Email" /> </Col>
                                <Col sm={2}> <FormattedMessage id="BulkVouchersCreation.VoucherValue" /> </Col>
                                <Col> <FormattedMessage id="BulkVouchersCreation.ResIdValue" /> </Col>
                                <Col> <FormattedMessage id="BulkVouchersCreation.VoucherId" /> </Col>
                            </Row>
                        </Card>

                        {this.state.createdVouchers.map((voucher, idx) =>
                            <Card className="border-0 shadow mb-2" body key={idx}>
                                <Row className="py-1">
                                    <Col> {voucher.email} </Col>
                                    <Col sm={2}> {voucher.voucherValue} </Col>
                                    <Col> {voucher.resIdValue} </Col>
                                    <Col> {voucher.voucherId} </Col>
                                </Row>
                            </Card>
                        )}
                    </StyledModal>
                }

            </StyledCard>
        );
    }
}

export default injectIntl(BulkVouchersCreation);



const csvFields = [
    { dataField: 'externalCrmId', text: "ExternalCrmId" },
    { dataField: 'externalId', text: "ExternalId" },
    { dataField: 'firstName', text: "FirstName" },
    { dataField: 'lastName', text: "LastName" },
    { dataField: 'email', text: "Email" },
    { dataField: 'fiscalNumber', text: "FiscalNumber" },
    { dataField: 'invoiceReference', text: "InvoiceReference" },
    { dataField: 'voucherTypeId', text: "VoucherTypeId" },
    { dataField: 'salePrice', text: "SalePrice" },
    { dataField: 'voucherValue', text: "VoucherValue" },
    { dataField: 'validUntil', text: "ValidUntil" },
    { dataField: 'resIdValue', text: "ResIdValue" },
    { dataField: 'resIdDate', text: "ResIdDate" },
    { dataField: 'pmsReservationId', text: "PmsReservationId" },
    { dataField: "pmsReservationDetailId", text: "PmsReservationDetailId" },
    { dataField: "hotelId", text: "ReservationHotelId" },
    { dataField: "issueCode", text: "IssueCode" },
    { dataField: "notes", text: "VoucherNotes" }
]


const help = <div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.ExternalCrmId" />*: </b> <FormattedMessage id="BulkVouchersCreation.ExternalCrmIdText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.ExternalId" />*: </b> <FormattedMessage id="BulkVouchersCreation.ExternalIdText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.FirstName" />** </b> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.LastName" />**  </b> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.Email" /> <FormattedMessage id="ImportProfile.Required" /> </b> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.FiscalNumber" />  </b> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.InvoiceReference" />  </b> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.VoucherTypeId" /> <FormattedMessage id="ImportProfile.Required" />: </b> <FormattedMessage id="BulkVouchersCreation.VoucherTypeIdText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.SalePrice" />  </b> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.VoucherValue" /> </b> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.ValidUntil" />: </b> <FormattedMessage id="BulkVouchersCreation.ValidUntilText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.ResIdValue" />***: </b> <FormattedMessage id="BulkVouchersCreation.ResIdValueText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.ResIdDate" />***: </b> <FormattedMessage id="BulkVouchersCreation.ResIdDateText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.PmsReservationId" />: </b> <FormattedMessage id="BulkVouchersCreation.PmsReservationIdText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.PmsReservationDetailId" />: </b> <FormattedMessage id="BulkVouchersCreation.PmsReservationDetailIdText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.ReservationHotelId" />**: </b> <FormattedMessage id="BulkVouchersCreation.ReservationHotelIdText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.IssueCode" />: </b> <FormattedMessage id="BulkVouchersCreation.IssueCodeText" /> </div>
    <div> <b> <FormattedMessage id="BulkVouchersCreation.VoucherNotes" />: </b> <FormattedMessage id="BulkVouchersCreation.VoucherNotesText" /> </div>
    <br />
    <div>* <FormattedMessage id="BulkVouchersCreation.ExternalIdRequiredText" /></div>
    <div>** <FormattedMessage id="BulkVouchersCreation.ProfileNameRequiredText" /></div>
    <div>*** <FormattedMessage id="BulkVouchersCreation.ReservationDataRequiredText" /></div>
</div >