import React, { Component } from 'react';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Input, FormGroup } from 'reactstrap';
import moment from 'moment';
import { SelectHotel } from '../../Base/CommonUIComponents';


class SalesDealStep2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleDates = ({ startDate, endDate }) => {
        if (startDate)
            this.props.handleDate('businessStartDate', startDate);

        if (endDate)
            this.props.handleDate('businessEndDate', endDate)
    }


    render() {
        const { handleChange, handleSelect, handleDate, deal } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Col>
                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.NegotiationStartDate" /></div>
                        <SingleDatePicker
                            id="negotiationStartDate"
                            isOutsideRange={day => day > moment().set({ hour: 23, minute: 59 })}
                            date={deal.negotiationStartDate ? moment(deal.negotiationStartDate) : null}
                            onDateChange={date => handleDate('negotiationStartDate', date)}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            small={true}
                            numberOfMonths={1}
                            required={true}
                            showDefaultInputIcon={true}
                            showClearDate={true}
                        />
                    </Col>
                    <Col>
                        <div className={`title-sm ${(!deal.negotiationStartDate || deal.status === 'InProgress') ? '' : 'required'}`}> <FormattedMessage id="SalesDeal.NegotiationEndDate" /></div>
                        <SingleDatePicker
                            id="negotiationEndDate"
                            isOutsideRange={day => deal.negotiationStartDate ? day <= moment(deal.negotiationStartDate) : null}
                            date={deal.negotiationEndDate ? moment(deal.negotiationEndDate) : null}
                            onDateChange={date => handleDate('negotiationEndDate', date)}
                            focused={this.state.focused2}
                            onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                            small={true}
                            numberOfMonths={1}
                            required={true}
                            showDefaultInputIcon={true}
                            disabled={!deal.negotiationStartDate || deal.status === 'InProgress'}
                            showClearDate={true}
                        />
                    </Col>
                    <Col>
                        <div className="title-sm"> <FormattedMessage id="SalesDeal.Budget" /></div>
                        <Input
                            type="number"
                            name="budget"
                            placeholder=""
                            value={deal.budget || ''}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col className="fullWidth">
                        <div className="title-sm"> <FormattedMessage id="SalesDeal.BusinessDate" /></div>
                        <DateRangePicker
                            startDate={deal.businessStartDate ? moment(deal.businessStartDate) : null}
                            startDateId="businessStartDate"
                            endDate={deal.businessEndDate ? moment(deal.businessEndDate) : null}
                            endDateId="businessEndDate"
                            isOutsideRange={_ => false}
                            onDatesChange={this.handleDates}
                            focusedInput={this.state.focusedInput}
                            showClearDates={true}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col>
                        <div className="title-sm"> <FormattedMessage id="SalesDeal.ExpectedEndDate" /></div>
                        <SingleDatePicker
                            id="expectedEndDate"
                            isOutsideRange={day => day <= moment()}
                            date={deal.expectedEndDate ? moment(deal.expectedEndDate) : ''}
                            onDateChange={date => handleDate('expectedEndDate', date || null)}
                            focused={this.state.focused3}
                            onFocusChange={({ focused }) => this.setState({ focused3: focused })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            showClearDate={true}
                        />
                    </Col>
                    <Col>
                        <div className="title-sm"> <FormattedMessage id="SalesDeal.ExpectedValue" /></div>
                        <Input
                            type="number"
                            name="expectedValue"
                            placeholder=""
                            value={deal.expectedValue || ''}
                            onChange={handleChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col>
                        <div className="title-sm"> <FormattedMessage id="SalesDeal.Adults" /></div>
                        <Input
                            type="number"
                            name="adults"
                            placeholder=""
                            value={deal.adults || ''}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col>
                        <div className="title-sm"> <FormattedMessage id="SalesDeal.Children" /></div>
                        <Input
                            type="number"
                            name="children"
                            placeholder=""
                            value={deal.children || ''}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col/>
                </FormGroup>
                <FormGroup row>
                    <Col>
                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.RelatedHotels" /></div>
                        <SelectHotel
                            isSearchable
                            isMulti
                            name='hotelIds'
                            icon={"icon-icon-hotel"}
                            onChangeFunc={handleSelect}
                            placeholder={'Hotel'}
                            value={deal.hotelIds || ''}
                            params='?onlyFromHotelGroup=true'
                        />
                    </Col>
                </FormGroup>
            </div>
        )
    }
}

export default injectIntl(SalesDealStep2);