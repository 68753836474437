import React, { Component } from 'react';
import { Row, Col, Button, Input, Card, InputGroup, InputGroupText, Form, Badge } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard, DefaultPage, SelectHotel, KebabMenu } from '../../Base/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { getNrNights, getPMSReservationStatus, getProfileType } from '../../Base/ReferenceDataFunctions';
import EmitVoucherModal from '../../Card/EmitVoucherModal';


class SearchReservation extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            reservation: null,
            emitVoucherModal: false,
            reservationId: "Reservation Number: "
        }
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hotelId: global.hotelId,
                    hasHeyCard: global.modules && global.modules.some(m => m === 'HeyCard'),
                    block: false
                }, () => {
                    if (this.props.hideFilters) {
                        this.searchByParams();
                    }
                    else if (this.props.location?.selectedHotelId && this.props.location?.selectedRes) {
                        this.searchByParams(this.props.location.selectedHotelId, this.props.location.selectedRes);
                    }
                });
            },
            global.modules ? 0 : 2500
        );
    }

    searchByParams = (hotel, reservation) => {
        this.setState({
            hotelId: hotel || this.props.selectedHotelId,
            reservationId: reservation || this.props.selectedRes
        }, this.doSearch);
    }

    doSearch = (e) => {
        if (e) e.preventDefault();

        if (this.state.hasHeyCard) {
            this.getReservationVouchers();
        }
        else {
            this.getReservationDetails();
        }
    }

    getReservationDetails = (vouchers) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                let reservation = null;

                if (data.response && data.response.length > 0) {
                    reservation = data.response[0];

                    if (reservation.reservationProfile && reservation.reservationProfile.length > 0) {
                        reservation.hotelReservation && reservation.hotelReservation.forEach(rrs => {
                            const rrsGuests = [];

                            rrs.guestsRphs && rrs.guestsRphs.forEach(guestRph => {
                                const profileIndex = reservation.reservationProfile.findIndex(rp => rp.rph === guestRph);

                                if (profileIndex !== -1) {
                                    rrsGuests.push(reservation.reservationProfile[profileIndex]);

                                    reservation.reservationProfile[profileIndex].hidden = true;
                                }
                            })

                            rrs.guestsInfo = rrsGuests;

                            rrs.associatedVouchers = vouchers?.filter(v => v.pmsReservationDetailId.toString() === rrs.pmsId);
                        })
                    }
                }

                this.setState({ reservation: reservation, block: false, errorMessage: null });
            }
            else {
                this.setState({ block: false, errorMessage: null });
            }
        }, `/api/gms/Reservation/v1/GetReservationDetails/${this.state.reservationId}?hotelId=${this.state.hotelId}`);
    }
    
    getReservationVouchers = () => {
        this.setState({ blockVouchers: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockVouchers: false }, this.getReservationDetails);
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                this.setState({ vouchers: data.response, totalItems: data.count, blockVouchers: false }, () => this.getReservationDetails(data.response));
            }
            else this.getReservationDetails();
        }, `/api/Voucher/VoucherReservation/v1/ReservationVouchers?pageSize=999&pageIndex=0&resNumber=${this.state.reservationId}`);
    }

    updateVouchers = () => {
        this.setState({ emitVoucherModal: false }, this.doSearch)
    }


    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleModal = (modal, roomStayPmsId, guestInfo, channelRoomTypeCode) => {
        var voucherReservation = null;

        if (roomStayPmsId) {
            const { reservation } = this.state;

            voucherReservation = {
                resIdValue: reservation.resIdValue,
                resIdDate: reservation.resIdDate,
                pmsReservationId: reservation.pmsId,
                pmsReservationDetailId: roomStayPmsId,
                hotelId: reservation.hotelId
            }
        }

        this.setState(prevState => ({
            [modal]: !prevState[modal],
            voucherReservation,
            roomStayMainGuest: guestInfo ? guestInfo.find(g => g.isMainGuest) ?? guestInfo[0] : null,
            channelRoomTypeCode
        }))
    }

    render() {
        const { blockVouchers, block, error, reservation, emitVoucherModal, voucherReservation } = this.state;
        const { hideFilters } = this.props;

        return (
            <StyledCard icon={!hideFilters && 'icon-icon-reservas'} title={!hideFilters && 'NavMenu.SearchReservation'} error={error} block={block || blockVouchers}>

                {emitVoucherModal ?
                    <EmitVoucherModal
                        modal={emitVoucherModal}
                        toggleModal={() => this.toggleModal("emitVoucherModal")}
                        updateVouchers={this.updateVouchers}
                        renderClientInfo={true}
                        roomCode={this.state.channelRoomTypeCode}
                        voucherReservation={voucherReservation}
                        profile={this.state.roomStayMainGuest}
                        isBillingInfoRequired={false}
                    />
                : ''}


                <Form onSubmit={this.doSearch} className="my-3">
                    {hideFilters !== true ?
                        <Row className="align-items-center">
                            <Col sm={3} className="pr-2">
                                <SelectHotel
                                    name={'hotelId'}
                                    icon={'icon-icon-hotel'}
                                    onChangeFunc={this.handleSelect}
                                    placeholder={<FormattedMessage id="generic.Hotel" />}
                                    value={this.state.hotelId || ''}
                                    params='?onlyFromHotelGroup=true'
                                    required
                                />
                            </Col>
                            <Col sm={3}>
                                <InputGroup size="sm text-right">
                                    <InputGroupText className="shadow">
                                        <i className="fas fa-key fa-fw" />
                                    </InputGroupText>
                                    <Input
                                        className="border-left-0"
                                        type="text"
                                        name="reservationId"
                                        value={this.state.reservationId}
                                        onChange={this.handleChange}
                                        placeholder={this.props.intl.formatMessage({ id: "ReservationDetails.ReservationId" })}
                                        required
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn btn-sm btn-host" type="submit">
                                    <i className="icon-icon-search" />
                                </Button>
                            </Col>
                        </Row>
                    : ''}
                </Form>

                {reservation?.id ?
                    <div>
                        <Card className="px-4 border-0 shadow mb-3" body>
                            <Row className="border-bottom pb-2 mb-3">
                                <Col>
                                    <b><span className="icon-icon-reservas mr-2" /> <FormattedMessage id="ReservationDetails.Reservation" /></b>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ReservationDetails.ReservationId" /> </div>
                                    <b>{reservation.resIdValue}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ReservationDetails.ReservationDate" /></div>
                                    <b>{reservation.resIdDate ? moment(reservation.resIdDate).format("YYYY-MM-DD") : '-'}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ReservationDetails.LastUpdate" /></div>
                                    <b>{reservation.updatedAt ? moment(reservation.updatedAt).format("YYYY-MM-DD") : '-'}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ReservationList.GuestCount" /></div>
                                    <b>{reservation.guestCount ||'-'}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ReservationDetails.Currency" /></div>
                                    <b>{reservation.currencyCode || '-'}</b>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ReservationList.Source" /></div>
                                    <b>{reservation.source || '-'}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="Reservation.ChannelCode" /></div>
                                    <b>{reservation.channelCode || '-'}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ProfileRevenue.SegmentCode" /> </div>
                                    <b>{reservation.segmentCode || '-'}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ProfileRevenue.SubSegmentCode" /></div>
                                    <b>{reservation.subSegmentCode || '-'}</b>
                                </Col>
                                <Col>
                                    <div className="title-sm"><FormattedMessage id="ProfileRevenue.DistChannelCode" /></div>
                                    <b>{reservation.distChannelCode || '-'}</b>
                                </Col>
                            </Row>
                        </Card>

                        <Card className="px-4 border-0 shadow mb-3" body>
                            <Row className="border-bottom pb-2 mb-3">
                                <Col>
                                    <b>
                                        <span className="icon-icon-room-reservation mr-2" />
                                        <FormattedMessage id="ReservationDetails.RoomStays" />
                                    </b>
                                </Col>
                            </Row>

                            {reservation.hotelReservation && reservation.hotelReservation.map((roomStay, key) =>
                                <div className="mb-4">
                                    <ReservationRoomStay
                                        key={key}
                                        roomStay={roomStay}
                                        intl={this.props.intl}
                                        toggleModal={() => this.toggleModal('emitVoucherModal', roomStay.pmsId, roomStay.guestsInfo, roomStay.channelRoomTypeCode)}
                                        hasHeyCard={this.state.hasHeyCard}
                                        hideActions={this.props.hideRoomActions}
                                        selectRoom={this.props.selectRoom ? () => this.props.selectRoom(reservation, roomStay) : null}
                                        selectedPmsRoomId={this.props.selectedPmsRoomId}
                                    />
                                </div>
                            )}
                        </Card>

                        {reservation.reservationProfile?.some(rp => rp.hidden !== true) ?
                            <Card className="px-4 border-0 shadow mb-3" body>
                                <Row className="border-bottom pb-2 mb-3">
                                    <Col>
                                        <b>
                                            <span className="icon-icon-profile mr-2" />
                                            <FormattedMessage id="ReservationDetails.Profiles" />
                                        </b>
                                    </Col>
                                </Row>

                                <Row>
                                    {reservation.reservationProfile.map((profile, key) => profile.hidden !== true &&
                                        <Col key={key} className="col-4 pb-2">
                                            <GuestCard
                                                profile={profile}
                                                intl={this.props.intl}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </Card>
                        : ''}

                        {reservation.hotelService && reservation.hotelService.length > 0 ?
                            <Card className="px-4 border-0 shadow mb-3" body >
                                <Row className="border-bottom pb-2 mb-3">
                                    <Col>
                                        <b>
                                            <span className="fas fa-hot-tub mr-2" />
                                            <FormattedMessage id="ReservationDetails.Services" />
                                        </b>
                                    </Col>
                                </Row>
                                <Row className="mb-1 text-muted">
                                    <Col>
                                        <FormattedMessage id="ReservationDetails.ServiceCode" />
                                    </Col>
                                    <Col>
                                        <FormattedMessage id="ReservationDetails.PriceTypeCode" />
                                    </Col>
                                    <Col>
                                        <FormattedMessage id="ReservationDetails.Duration" />
                                    </Col>
                                    <Col>
                                        <FormattedMessage id="ReservationDetails.NumberOfGuests" />
                                    </Col>
                                    <Col>
                                        <FormattedMessage id="ReservationDetails.Amount" />
                                    </Col>
                                </Row>
                                {reservation.hotelService && reservation.hotelService.map((item, key) =>
                                    <Row className="overbglight py-2" key={key}>
                                        <Col><b>{item.serviceCode} </b></Col>
                                        <Col>{item.servicePricingType || '-'} </Col>
                                        <Col>{item.duration || '-'} </Col>
                                        <Col>{item.guestCount || '-'} </Col>
                                        <Col>{item.amountAfterTax || '-'}</Col>
                                    </Row>
                                )}
                            </Card>
                        : ''}

                        {reservation.reservationComments.length > 0 ?
                            <Card className="px-4 border-0 shadow mb-3" body>
                                <Row className="border-bottom pb-2 mb-3">
                                    <Col>
                                        <b>
                                            <span className="far fa-comment mr-2" />
                                            <FormattedMessage id="ReservationDetails.Comments" />
                                        </b>
                                    </Col>
                                </Row>

                                {reservation.reservationComments.map((item, key) =>
                                    <li className="mailboxballon overbglight p-2" key={key}> {item.comment}</li>
                                )}
                            </Card>
                        : ''}
                    </div>
                    : <DefaultPage icon="icon-icon-search" text="NavMenu.SearchReservation" />}
            </StyledCard>
        );
    }
}
export default injectIntl(SearchReservation);




const ReservationRoomStay = ({ roomStay, intl, toggleModal, hasHeyCard, hideActions, selectRoom, selectedPmsRoomId }) => {
    const status = getPMSReservationStatus(intl).find(el => el.value == roomStay.reservationStatus);


    return <Card className={"pt-3 pb-2 bg-light overbglight " + (selectRoom ? ' pointer ' : '') + (selectedPmsRoomId === roomStay.pmsId ? ' bg-light-host-selected ' : '')} body onClick={() => { if (selectRoom) selectRoom() }}>
        <Row>
            <Col style={{ fontSize: '15.5px' }}>
                <i className="fas fa-bed fa-sm mr-2" /> <b>{roomStay.channelRoomTypeCode}</b>
            </Col>
            <Col className="d-flex align-items-center justify-content-end col-2">
                {status ?
                    <Badge className="py-1 px-2 mr-2" color={status.color} > {status.label} </Badge>
                :
                    <Badge className="py-1 px-2 mr-2" color="secondary" > {roomStay.reservationStatusCode} </Badge>
                }

                {hasHeyCard && hideActions !== true ?
                    <KebabMenu
                        extraFields={[
                            { text: "EmitVoucherModal.EmitVoucher", function: _ => toggleModal(), icon: "icon-icon-vouchers mr-2" }
                        ]}
                    />
                : ''}
            </Col>
        </Row>

        <hr className="border-host mt-2" />

        <div className="ml-4">
            <Row className="mb-5">
                <Col>
                    <div className="title-sm"><FormattedMessage id="ReferenceDataFunctions.CheckIn" /></div>
                    <b>{roomStay.checkIn ? moment(roomStay.checkIn).format("YYYY-MM-DD HH:mm") : '-'}</b>
                </Col>
                <Col>
                    <div className="title-sm"><FormattedMessage id="ReferenceDataFunctions.CheckOut" /></div>
                    <b>{roomStay.checkOut ? moment(roomStay.checkOut).format("YYYY-MM-DD HH:mm") : '-'}</b>
                </Col>
                <Col>
                    <div className="title-sm"><FormattedMessage id="ReservationList.PriceList" /></div>
                    <b>{(roomStay.hotelRateCode && roomStay.hotelRateCode.length > 0 && roomStay.hotelRateCode[0].priceListCode) || '-'}</b>
                </Col>
                <Col>
                    <div className="title-sm"><FormattedMessage id="ReservationList.Package" /></div>
                    <b>{(roomStay.hotelRateCode && roomStay.hotelRateCode.length > 0 && roomStay.hotelRateCode[0].ratePlanCode) || '-'}</b>
                </Col>
                <Col>
                    <div className="title-sm"><FormattedMessage id="ReservationList.Nights" /></div>
                    <b>{getNrNights(roomStay.checkIn, roomStay.checkOut) || '-'}</b>
                </Col>
                <Col>
                    <div className="title-sm"><FormattedMessage id="ReservationDetails.Commission" /></div>
                    <b>{roomStay.amountCommission || '-'}</b>
                </Col>
                <Col>
                    <div className="title-sm"><FormattedMessage id="ReservationDetails.Amount" /></div>
                    <b>{roomStay.amountAfterTax || '-'}</b>
                </Col>
            </Row>

            {roomStay.guestsInfo?.length > 0 ?
                <>
                    <Row>
                        <Col>
                            <b>
                                <i className="icon-icon-profile mr-1" />
                                <FormattedMessage id="ReservationDetails.Guests" />
                            </b>
                        </Col>
                    </Row>
                    <hr className="mt-2" />
                    <Row>
                        {roomStay.guestsInfo.map((guest, index) => 
                            <Col className="col-4 mb-3 " key={index}>
                                <GuestCard
                                    profile={guest}
                                    intl={intl}
                                />
                            </Col>
                        )}
                    </Row>
                </>
                : ''}

            {roomStay.associatedVouchers?.length > 0 ?
                <div className="my-4">
                    <Row>
                        <Col>
                            <b>
                                <i className="icon-icon-vouchers mr-1" />
                                <FormattedMessage id="ProfileDetails.Vouchers" />
                            </b>
                        </Col>
                    </Row>

                    <hr className="mt-2" />

                    <div className="ml-4">
                        <Row className="text-muted mb-2">
                            <Col> <FormattedMessage id="generic.Hotel" /> </Col>
                            <Col> <FormattedMessage id="BatchLogs.CreatedAt"/> </Col>
                            <Col> Voucher Id </Col>
                        </Row>

                        {roomStay.associatedVouchers.map((voucher, index) =>
                            <Row key={index} className="py-1">
                                <Col> {voucher.hotelName} </Col>
                                <Col> {voucher.createdAt ? moment(voucher.createdAt).format("YYYY-MM-DD HH:mm") : ''} </Col>
                                <Col> <a href={`/VoucherDetails/${voucher.voucher?.id}`} target="_blank"> {voucher.voucher?.voucherId || ''} </a></Col>
                            </Row>
                        )}
                    </div>
                </div>
            : ''}
        </div>
    </Card>
}


const GuestCard = ({ profile, intl }) => {
    const profileType = profile.isMainGuest ? { icon: 'fas fa-crown fa-sm color-orange' } : getProfileType(intl).find(p => p.value === profile.type);

    return <Card className="border-0 h-100 p-2 mb-2 pointer overbglight" body style={{ backgroundColor: "transparent" }} onClick={() => window.open("/ProfileDetails/" + profile.id, '_blank')} >
        <Row>
            <Col className="col-3 d-flex align-items-center justify-content-center" style={{ margin: 'auto' }}>
                <Badge className="profileBadge" style={{ height: '48px', width: '48px', fontSize: '16px' }}>
                    {profile.firstName && profile.firstName.substr(0, 1).toUpperCase()}{profile.lastName && profile.lastName.substr(0, 1).toUpperCase()}
                </Badge>
            </Col>

            <Col className="col-9 border-right">
                <Row className="mb-2">
                    <Col className="text-ellipsis" title={`${profile.firstName} ${profile.lastName}`}>
                        <i className={`${profileType?.icon} mr-1`} />
                        <b className="font-weight-bold m-0 pt-3" style={{ fontSize: '16px' }}>
                            {profile.firstName} {profile.lastName}
                        </b>
                    </Col>
                    {profile.isMaster ?
                        <Col className="col-3 text-right">
                            <Badge color='primary'> Master </Badge>
                        </Col>
                    : ''}
                </Row>

                <Row className="font_size_xxs">
                    <Col>
                        <div className="title-sm"><FormattedMessage id="ReservationDetails.Nationality" /></div>
                        {
                            profile.nationality ?
                                <i className={`flag-icon flag-icon-${profile.nationality.toLowerCase()}`} />
                                :
                                ' - '
                        }
                    </Col>
                    <Col>
                        <div className="title-sm"><FormattedMessage id="ReservationDetails.BirthDate" /></div>
                        {profile.birthDate ? moment(profile.birthDate).format("YYYY-MM-DD") : ' - '}
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}

