import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { Col, Row } from 'reactstrap';

class SalesDealProposalEvents extends Component {
    
    render() {
        const { filters } = this.props;

        return (
            <div>
                <div className='d-flex align-items-center justify-content-between text-muted'>
                    <div style={{ fontSize: '1.1em' }}>
                        <i className="fas fa-bed mr-2"/>
                        <FormattedMessage id="SalesDeal.Events"/>
                    </div>
                    <div style={{ fontSize: '1.4em' }}>
                        <FormatAmountNumber value={5500}/>
                    </div>
                </div>
                <div>
                    <Row className='mt-2 w-100 px-2 d-flex align-items-center flex-nowrap text-muted'>
                        <Col className='col-3 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', }}>
                            EventName
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            EventType
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            CreateDate
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            EventManager
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Adults
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Children
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Status
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Price
                        </Col>
                    </Row>
                    <div>
                        {[1,2,3,4].map((a, key) =>
                            <div key={key}>
                                <Row className='w-100 px-2 d-flex align-items-center flex-nowrap'>
                                    <Col className='col-3 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '' }}>
                                        EventName {a}
                                    </Col>
                                    <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                        EventType {a}
                                    </Col>
                                    <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                        CreateDate {a}
                                    </Col>
                                    <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                        EventManager {a}
                                    </Col>
                                    <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                        24
                                    </Col>
                                    <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                        10
                                    </Col>
                                    <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                        Definitive
                                    </Col>
                                    <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                        <FormatAmountNumber value={1233}/>
                                    </Col>
                                </Row>
                                <div>
                                    <Row className='mt-2 w-100 px-2 d-flex align-items-center flex-nowrap text-muted'>
                                        <Col className='col-3 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', }}>
                                            SpaceName
                                        </Col>
                                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                                            SpaceTypeDescription
                                        </Col>
                                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                                            FromDate
                                        </Col>
                                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                                            ToDate
                                        </Col>
                                    </Row>
                                    {[1,2,3].map((b, key) =>
                                        <Row key={key} className='w-100 px-4 d-flex align-items-center flex-nowrap'>
                                            <Col className='col-3 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '' }}>
                                                SpaceName {b}
                                            </Col>
                                            <Col className='col-3 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                                SpaceTypeDescription {a}
                                            </Col>
                                            <Col className='col-3 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                                FromDate
                                            </Col>
                                            <Col className='col-3 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                                ToDate
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesDealProposalEvents;
