import React, { Component } from 'react';
import { Row, Col, Input, Button, Badge, ButtonGroup, Card } from 'reactstrap';
import { StyledCard } from '../../../Base/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../../Base/API';
import CustomSelect from '../../../Base/CustomSelect';


class Actions extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            cacheKey: null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    resetCache = (e, cacheKey) => {
        if (e) e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
            }
            else {
                handleNotification(data, <FormattedMessage id="Actions.CacheResetSuccessfully" />, <FormattedMessage id="generic.success" />);
                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/resetUserCache?cacheKey=${cacheKey}`);

        if (cacheKey === 'userCache') {
            this.setState({ blockScheduleTask: true });

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockScheduleTask: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState(({ error: errorMessage, blockScheduleTask: false }));
                    }
                }
                else {
                    handleNotification(data, <FormattedMessage id="Actions.ScheduleTaskCacheResetSuccessfully" />, <FormattedMessage id="generic.success" />);
                    this.setState({ blockScheduleTask: false });
                }
            }, `/api/gmsscheduletask/DataQuality/resetUserCache?cacheKey=${cacheKey}` );
        }
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const { block, blockScheduleTask, error } = this.state;

        const cacheOption = [
            { value: 'userCache', label: 'User Cache' },
            { value: 'TagGroupDetail', label: 'Tag Group Cache' },
            { value: `ReferenceData:${global.hotelGroupId}`, label: 'Reference Data Cache' },
            { value: `hotelCache`, label: 'Hotel Cache' }
        ]

        return (
            <StyledCard block={block || blockScheduleTask} error={error} icon={'fas fa-sync'} title={'NavMenu.Actions'}>
                <Card className="border-0 shadow mt-4 pb-4" body>
                    <Row className="align-items-center" >
                        <Col sm={3}>
                            <h5 className="m-0"> Reset Cache </h5>
                        </Col>
                    </Row>

                    <Row className="pl-5 mt-4">
                        {cacheOption.map((cache, key) =>
                            <Col key={key} sm={6}>
                                <Row className="align-items-center py-2">
                                    <Col sm={6}>
                                        <span className="m-0"> {cache.label} </span>
                                    </Col>
                                    <Col>
                                        <Button className="btn btn-host btn-sm" onClick={(e) => this.resetCache(e, cache.value)} >
                                            <i className="fas fa-sync" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Card>
            </StyledCard>
        );
    }
}
export default injectIntl(Actions)