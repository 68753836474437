import React, { Component } from 'react'
import { Row, Col, Input, Label } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../../Base/CustomSelect';
import {SelectHotelByHotelGroup, SelectHotel } from '../../../Base/CommonUIComponents';
import Authorization from '../../../Base/Authorization';

class FormSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comboOptions: [{ value: true , label: <FormattedMessage id="generic.yes" />}, { value: false, label: <FormattedMessage id="generic.no" /> }],
        }
    }

    render() {
        const { comboOptions } = this.state;
        const { survey, handleInputChange, handleChangeCombo, tags } = this.props;
        return (
            <div>
                <Row>
                    <Col className='col-12'>
                        <Label className='title-sm'><FormattedMessage id="FormBuilder.FormName" /> <span className='text-danger'>*</span></Label>
                        <Input 
                            type="text"
                            value={survey.name}
                            name="name"
                            required={true}
                            onChange={handleInputChange}
                            placeholder={''}
                        />
                    </Col>
                    <Col className='col-12 mt-3'>
                        <Label className='title-sm'><FormattedMessage id="FormBuilder.AssociatedHotel" /> <span className='text-danger'>*</span></Label>
                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <SelectHotel
                                    name="hotelId"
                                    onChangeFunc={handleChangeCombo}
                                    value={survey.hotelId}
                                    placeholder={''}
                                    required
                                    isClearable={true}
                                />
                            )}
                            no={() =>
                                <SelectHotelByHotelGroup
                                    name="hotelId"
                                    onChangeFunc={handleChangeCombo}
                                    value={survey.hotelId}
                                    placeholder={''}
                                    required
                                    isClearable={true}
                                />
                            }
                        />
                    </Col>
                    <Col className='col-12 mt-3'>
                        <Label className='title-sm'><FormattedMessage id="FormBuilder.ActiveForm" /><span className='text-danger'>*</span></Label>
                        <CustomSelect
                            id="ActiveForm" 
                            options={comboOptions}
                            required={true}
                            isSearchable={false}
                            value={comboOptions.find(el => el.value === survey.active) || ''}
                            onChange={e => handleChangeCombo('active', e)}
                            placeholder={''} 
                        />
                    </Col>
                    <Col className='col-12 mt-3'>
                        <Label className='title-sm'><FormattedMessage id="FormBuilder.DoubleOptIn" /><span className='text-danger'>*</span></Label>
                        <CustomSelect
                            id="DoubleOptIn" 
                            options={comboOptions}
                            required={true}
                            isSearchable={false}
                            value={comboOptions.find(el => el.value === survey.dOptIn) || comboOptions.find(el => el.value === false)}
                            onChange={e => handleChangeCombo('dOptIn', e)}
                            placeholder={''} 
                        />
                    </Col>
                    <Col className='col-12 mt-3'>
                        <Label className='title-sm'><FormattedMessage id="FormBuilder.Tag" /></Label>
                        <CustomSelect
                            id="SelectTag" 
                            options={tags}
                            isSearchable={true}
                            value={
                                tags.map(el => el.options.find(tag => tag.value === survey.tagId))
                            || 
                                ''
                            }
                            onChange={e => handleChangeCombo('tagId', e)}
                            placeholder={''} 
                            isClearable={true}
                        />
                    </Col>
                    <Col className='col-12 mt-3'>
                        <Label className='title-sm'><FormattedMessage id="PNFormDetails.anonymous" /><span className='text-danger'>*</span></Label>
                        <CustomSelect
                            id="anonymous" 
                            options={comboOptions}
                            required={true}
                            isSearchable={false}
                            value={comboOptions.find(el => el.value === survey.anonymous) || ''}
                            onChange={e => handleChangeCombo('anonymous', e)}
                            placeholder={''} 
                            isDisabled={true}
                        />
                    </Col>
                    <Col className='col-12 mt-3'>
                        <Label className='title-sm'><FormattedMessage id="FormBuilder.CreateUser" /><span className='text-danger'>*</span></Label>
                        <CustomSelect
                            id="createProfile" 
                            options={comboOptions}
                            required={true}
                            isSearchable={false}
                            value={comboOptions.find(el => el.value === survey.createProfile) || ''}
                            onChange={e => handleChangeCombo('createProfile', e)}
                            placeholder={''} 
                            isDisabled={true}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(FormSettings);
