import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home/Home';
import { Profile } from './components/Profile/Profile';
import HotelList from './components/Hotel/HotelList';
import HotelDetail from "./components/Hotel/HotelDetail";
import SearchProfile from './components/ProfileMenu/SearchProfile';
import ProfileDetails from './components/ProfileMenu/ProfileDetails';
import ProfileMergeDetail from './components/ProfileMenu/MergeProfiles/ProfileMergeDetail';
import ProfileAnalysis from './components/ProfileMenu/ProfileAnalysis/ProfileAnalysis';
import ScheduleTask from './components/Admin/ScheduleTask';
import ApiGatewayUserManagement from './components/Admin/Users/APIGatewayUsers/ApiGatewayUserManagement';
import ApiGatewayUserDetails from './components/Admin/Users/APIGatewayUsers/ApiGatewayUserDetails';
import QueueStatusDashboard from './components/Admin/QueueStatusDashboard';
import DataQualityTemplateDashboard from './components/DataQuality/DataQualityTemplateDashboard';
import DataQualityTemplateDetail from './components/DataQuality/DataQualityTemplateDetail';
import RuleDashboard from './components/DataQuality/RuleDashboard';
import RuleDetail from './components/DataQuality/RuleDetail';
import TopicManagement from './components/Admin/Config/TopicManagement';
import ManageRegEx from './components/Admin/ManageRegEx';
import RegExDetail from './components/Admin/RegExDetail';
import HotelGroup from './components/Admin/HotelGroup';
import TaskResultDashboard from './components/DataQuality/TaskResultDashboard';
import TaskResultDetail from './components/DataQuality/TaskResultDetail';
import SyncEvents from './components/Admin/SyncEvents';
import ReservationSummary from './components/Analytics/ReservationSummary';
import Revenue from './components/Analytics/Revenue';
import QualityMetrics from './components/Analytics/QualityMetrics';
import DataQualityIssues from './components/DataQuality/DataQualityIssues/DataQualityIssues';
import ReservationList from './components/Reservations/ReservationList';
import CurrentHotelGroup from './components/Hotel/CurrentHotelGroup';
import TagsList from './components/Admin/Config/Tags/TagsList';
import TagGroupsList from './components/Admin/Config/Tags/TagGroupsList';
import TagMapping from './components/Config/TagMapping/TagMapping';
import GoogleBusinessConfig from './components/GoogleBusinessConfig/GoogleBusinessConfig';
import GoogleBusinessNotification from './components/GoogleBusinessConfig/GoogleBusinessNotification';
import GoogleBusinessReview from './components/GoogleBusinessConfig/GoogleBusinessReview';
import GoogleBusinessQA from './components/GoogleBusinessConfig/GoogleBusinessQA';
import GoogleBusinessLocations from './components/GoogleBusinessConfig/GoogleBusinessLocations';
import GoogleBusinessLocationDetail from './components/GoogleBusinessConfig/GoogleBusinessLocationDetail';
import GoogleBusinessFood from './components/GoogleBusinessConfig/GoogleBusinessFood';
import GoogleBusinessPosts from './components/GoogleBusinessConfig/GoogleBusinessPosts';
import GoogleBusinessMedia from './components/GoogleBusinessConfig/GoogleBusinessMedia';

import InquiryList from './components/Marketing/InquiryList';
import MarketingConfigEgoi from './components/Marketing/MarketingConfigEgoi';
import CampaignList from './components/Marketing/CampaignList';
import EgoiList from './components/Marketing/EgoiList/EgoiList';
import TransactionalTemplateList from './components/Marketing/TransactionalTemplateList';
import EgoiWebhookList from './components/Marketing/Webhooks/EgoiWebhookList';
import DomainList from './components/Marketing/DomainList';
import TagList from './components/Marketing/TagList';
import SendersList from './components/Marketing/SendersList';
import ShortenerList from './components/Marketing/ShortenerList';
import AlertsList from './components/Marketing/AlertsList';
import AlertDetails from './components/Marketing/AlertDetails';
import Campaigns from './components/Marketing/Campaigns/Campaigns';
import CampaignDetails from './components/Marketing/Campaigns/CampaignDetails';
import StatsEgoi from './components/Marketing/StatsEgoi';
import EgoiServiceActivation from './components/Marketing/EgoiServiceActivation';
import CustomCampaign from './components/Marketing/CustomCampaigns/CustomCampaign';
import CustomCampaignDetails from './components/Marketing/CustomCampaigns/CustomCampaignDetails';
import MarketingAlerts from './components/Marketing/Alerts/MarketingAlerts';
import MarketingAlertDetails from './components/Marketing/Alerts/MarketingAlertDetails';
import FormList from './components/Marketing/Forms/FormList';
import FormSubmissions from './components/Marketing/Forms/FormSubmissions';
import ExportProfiles from './components/Marketing/ExportProfiles/ExportProfiles';
import CommunicationStats from './components/Marketing/CommunicationStats';
import AutomationList from './components/Marketing/AutomationList';
import CommunicationJourney from './components/Marketing/CommunicationJourney/CommunicationJourney';
import SendDetails from './components/Marketing/SendDetails/SendDetails';

import reviewProConfig from './components/ReviewPro/Surveys/reviewProConfig';
import reviewProInquiry from './components/ReviewPro/Surveys/reviewProInquiry';
import MapForm from './components/ReviewPro/Surveys/MapForm';
import ReviewProMenu from './components/ReviewPro/ReviewProMenu';
import ReviewProExportProfiles from './components/ReviewPro/ExportProfiles/ReviewProExportProfiles';
import Stats from './components/ReviewPro/Stats/Stats';
import BatchLogs from './components/Integrations/BatchLogs/BatchLogs';
import AdminUserManagement from './components/Admin/Users/AdminUserManagement';
import ManageUser from './components/Config/ManageUser';
import DataClean from './components/Admin/Maintenance/DataClean';
import ProfileScore from './components/Config/ProfileScore/ProfileScore';
import QualityRuns from './components/Admin/HealthCheck/QualityRuns';
import { TopMergeProfiles } from './components/Admin/HealthCheck/TopMergeProfiles';
import { ProfileMasterDetails } from './components/Admin/HealthCheck/ProfileMasterDetails';
import MostRepeated from './components/DataQuality/MostRepeated';
import SystemEvents from './components/Admin/HealthCheck/SystemEvents';
import ReferenceData from './components/Config/ReferenceData/ReferenceData';
import Pipelines from './components/SalesAutomation/Config/Pipelines';
import PipelineStages from './components/SalesAutomation/Config/PipelineStages';
import { ProfileMatchConfig } from './components/Config/ProfileMatchConfig/ProfileMatchConfig';
import LoyaltyCardRules from './components/Config/LoyaltyCard/LoyaltyCardRules';
import LoyaltyCardLevels from './components/Config/LoyaltyCard/LoyaltyCardLevels';
import LoyaltyCardAttribution from './components/Config/LoyaltyCard/LoyaltyCardAttribution/LoyaltyCardAttribution';
import ConfigCardPoints from './components/Config/ConfigCardPoints/ConfigCardPoints';
import Mappings from './components/Config/ConfigMappings/Mappings';
import CustomTags from './components/Config/CustomTags/CustomTags';
import ProfileNowMenu from './components/Config/ProfileNowConfig/ProfileNowMenu';
import ConfigPNForms from './components/Admin/Config/PNForms/ConfigPNForms';
import PNFormAnswers from './components/Config/ProfileNowConfig/Surveys/PNFormAnswers';
import MapPNForm from './components/Config/ProfileNowConfig/Surveys/MapPNForm';
import DataRulesActions from './components/Admin/Config/DataRulesActions/DataRulesActions';
import DataRuleConfig from './components/Admin/Config/DataRulesActions/DataRuleConfig';
import Actions from './components/Admin/Config/Actions/Actions';
import FormsAndReviews from './components/Analytics/FormsAndReviews/FormsAndReviews';
import UnsubscribedStatistics from './components/Analytics/Unsubscribed/UnsubscribedStatistics';
import CancelationForecast from './components/Analytics/CancelationForecast/CancelationForecast';

import './custom.css'
import 'react-block-ui/style.css';
import "react-notifications/lib/notifications.css";
import 'react-dates/lib/css/_datepicker.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import Authorization from './components/Base/Authorization';
import ImportProfile from './components/ProfileMenu/ImportProfiles/ImportProfile';
import CustomExport from './components/Marketing/ExportProfiles/CustomExport';
import AssociateTags from './components/ProfileMenu/AssociateTags';
import HijiffyInbox from './components/Integrations/HijiffyInbox';
import HijiffyProfile from './components/Integrations/HijiffyProfile';
import HijiffyContacts from './components/Integrations/HijiffyContacts';
import { Account } from './components/Marketing/Account';
import SuppressionList from './components/Marketing/SuppressionList';
import MapFormModal from './components/Marketing/Forms/MapFormModal';
import ConfigProfileDataQuality from './components/Config/ProfileDataQuality/ConfigProfileDataQuality';
import ProfileSummary from './components/Analytics/ProfileSummary/ProfileSummary';
import Stripo from './components/Marketing/Stripo/Stripo';
import StripoHome from './components/Marketing/Stripo/StripoHome';
import ApiLogs from './components/Integrations/ApiLogs/ApiLogs';
import DeleteProfiles from './components/Admin/Actions/DeleteProfiles/DeleteProfiles';
import CampaignResults from './components/Analytics/CampaignResults/CampaignResults';
import WhatsAppConfig from './components/Marketing/WhatsApp/WhatsAppConfig';
import NumberManagement from './components/Marketing/WhatsApp/NumberManagement';
import TemplateManagement from './components/Marketing/WhatsApp/TemplateManagement';
import Analytics from './components/Marketing/WhatsApp/Analytics/Analytics';
import MediaManagement from './components/Marketing/WhatsApp/Media/MediaManagement';
import { RetentionPolicyRule } from './components/Config/RetentionPolicyRule';
import DailyReport from './components/DataQuality/Report/DailyReport';
import TypeForm from './components/Marketing/TypeForm/TypeForm';
import TypeformForms from './components/Marketing/TypeForm/TypeformForms';
import TypeformAnswers from './components/Marketing/TypeForm/TypeformAnswers';
import MapTypeForm from './components/Marketing/TypeForm/MapTypeForm';
import RunningProcesses from './components/Admin/Maintenance/RunningProcesses';
import WhatsAppChat from './components/ProfileMenu/WhatsApp/WhatsAppChat';
import MachineLearningConfig from './components/Admin/Config/MachineLearning/MachineLearningConfig';
import PromptConfig from './components/Config/GPTConfig/PromptConfig';
import SummaryConfig from './components/Config/ReviewSummaryConfig/SummaryConfig';
import ConfigRuns from './components/Config/ReviewSummaryConfig/ConfigRuns';
import AIConfig from './components/Config/AIConfig/AIConfig';
import SearchForm from './components/Forms/SearchForm/SearchForm';
import SearchReservation from './components/Admin/Reservations/SearchReservation';
import MonthlyReportRuns from './components/DataQuality/Report/MonthlyReport/MonthlyReportRuns';
import MonthlyReportComparison from './components/DataQuality/Report/MonthlyReport/MonthlyReportComparison';
import ConsentsConfig from './components/Admin/Config/Consents/ConsentsConfig';
import { LoyaltyCardTypes } from './components/Config/LoyaltyCard/LoyaltyCardTypes';
import AccessList from './components/Admin/Users/AccessList';
import DetailProfileSearch from './components/ProfileMenu/ProfileInteractions/DetailProfileSearch';
import { AvailableCards } from './components/Card/AvailableCards';
import SearchCards from './components/Card/SearchCards';
import SyncStatus from './components/Admin/HealthCheck/SyncStatus/SyncStatus';
import AllDQTemplates from './components/Admin/HealthCheck/AllDQTemplates';
import DeletedProfiles from './components/ProfileMenu/DeletedProfiles/DeletedProfiles';
import MonitorCampaigns from './components/Admin/HealthCheck/Campaigns/MonitorCampaigns';
import MonitorReservations from './components/Admin/HealthCheck/Reservations/MonitorReservations';
import DuplicateRules from './components/Admin/HealthCheck/Rules/DuplicateRules';
import PlatformDeepDive from './components/Admin/HealthCheck/PlatformDeepDive/PlatformDeepDive';
import VoucherDetails from './components/Card/VoucherDetails/VoucherDetails';
import MailConfig from './components/Marketing/SendGrid/MailConfig';
import SendGridAdmin from './components/Admin/Users/SendGridAdmin.js';
import FormBuilder from './components/Config/ProfileNowConfig/Surveys/FormBuilder.js';
import BulkVouchersCreation from './components/Card/BulkVouchers/BulkVouchersCreation';
import CampaignTargets from './components/Marketing/CampaignTargets/CampaignTargets.js';
import Targets from './components/Marketing/CampaignTargets/Targets.js';
import DecryptMessages from './components/Admin/DecryptMessages';
import CustomFields from './components/Config/CustomFields/CustomFields';
import TwilioMessagingAdmin from './components/Admin/Users/TwilioMessagingAdmin.js';
import MessageConfig from './components/Marketing/TwilioMessage/MessageConfig.js';
import ProfileCreditBalance from './components/Analytics/ProfileCreditBalance.js';
import ResendReservation from './components/Admin/ResendReservation';
import ReservationsByProfile from './components/Analytics/ReservationsByProfile';
import ProcessIssues from './components/Integrations/ProcessIssues/ProcessIssues';
import GoogleAnalyticsAccounts from './components/Config/GoogleAnalytics/GoogleAnalyticsAccounts';
import SalesProcess from './components/SalesProcess/SalesProcess.js';
import CustomerJourneyDashboard from './components/Marketing/CustomerJourney/CustomerJourneyDashboard.js';
import JourneyBuilder from './components/Marketing/CustomerJourney/JourneyBuilder.js';


export default class App extends Component {

    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />

                <Authorization
                    perform="admin:view"
                    yes={() => (
                        <>
                            <Route exact path='/ScheduleTask' component={ScheduleTask} />
                            <Route exact path='/ApiGatewayUserManagement' component={ApiGatewayUserManagement} />
                            <Route path='/ApiGatewayUserDetails/:id?' component={ApiGatewayUserDetails} />
                            <Route exact path='/QueueStatusDashboard' component={QueueStatusDashboard} />
                            <Route path='/TopicManagement' component={TopicManagement} />
                            <Route path='/ManageRegEx' component={ManageRegEx} />
                            <Route path='/RegExDetail/:id?' component={RegExDetail} />
                            <Route exact path='/HotelGroup' component={HotelGroup} />
                            <Route path='/SyncEvents' component={SyncEvents} />
                            <Route path='/TagGroupsList' component={TagGroupsList} />
                            <Route path='/TagsList' component={TagsList} />
                            <Route exact path='/AdminUserManagement' component={AdminUserManagement} />
                            <Route exact path='/DataClean' component={DataClean} />
                            <Route exact path='/QualityRuns' component={QualityRuns} />
                            <Route exact path='/TopMergeProfiles' component={TopMergeProfiles} />
                            <Route exact path='/TopMergeProfiles/:masterId' component={ProfileMasterDetails} />
                            <Route exact path='/SystemEvents' component={SystemEvents} />
                            <Route exact path='/ConfigPNForms' component={ConfigPNForms} />
                            <Route exact path='/DataRulesActions' component={DataRulesActions} />
                            <Route exact path='/DataRuleConfig/:id?' component={DataRuleConfig} />
                            <Route exact path='/Actions' component={Actions} />
                            <Route path='/DeleteProfiles' component={DeleteProfiles} />
                            <Route exact path='/RunningProcesses' component={RunningProcesses} />
                            <Route exact path='/MachineLearningConfig' component={MachineLearningConfig} />
                            <Route exact path='/ConsentsConfig' component={ConsentsConfig} />
                            <Route exact path='/AccessList' component={AccessList} />
                            <Route exact path='/SendGridAdmin' component={SendGridAdmin} />
                            <Route exact path='/TwilioMessagingAdmin' component={TwilioMessagingAdmin} />
                            <Route exact path='/SyncStatus' component={SyncStatus} />
                            <Route exact path='/AllDQTemplates' component={AllDQTemplates} />
                            <Route exact path='/MonitorCampaigns' component={MonitorCampaigns} />
                            <Route exact path='/MonitorReservations' component={MonitorReservations} />
                            <Route exact path='/DuplicateRules' component={DuplicateRules} />
                            <Route exact path='/MessageDecryption' component={DecryptMessages} />
                            <Route exact path='/ResendReservation' component={ResendReservation} />
                            <Route exact path='/PlatformDeepDive' component={PlatformDeepDive} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="dataQualityMenu:view"
                    yes={() => (
                        <>
                            <Route exact path='/DataQualityTemplateDashboard' component={DataQualityTemplateDashboard} />
                            <Route path='/DataQualityTemplateDetail/:id?' component={DataQualityTemplateDetail} />
                            <Route path='/RuleDashboard' component={RuleDashboard} />
                            <Route path='/RuleDetail/:id?' component={RuleDetail} />
                            <Route exact path='/TaskResultDashboard' component={TaskResultDashboard} />
                            <Route path='/TaskResultDetail/:id?' component={TaskResultDetail} />
                            <Route path='/DataQualityIssues' component={DataQualityIssues} />
                            <Route path='/ReportsConfig' component={DailyReport} />

                            <Authorization
                                perform="monthlyReportRuns:view"
                                yes={() => (
                                    <>
                                        <Route path='/MonthlyReportRuns' component={MonthlyReportRuns} />
                                        <Route path='/MonthlyReportComparison' component={MonthlyReportComparison} />
                                    </>
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="mostRepeated:view"
                                yes={() => (
                                    <Route path='/MostRepeated' component={MostRepeated} />
                                )}
                                no={() => <div></div>}
                            />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="hotelMenu:view"
                    yes={() => (
                        <>
                            <Route exact path='/HotelList' component={HotelList} />
                            <Route path="/HotelDetail/:id?" component={HotelDetail} />
                            <Route path='/CurrentHotelGroup' component={CurrentHotelGroup} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="analiticsMenu:view"
                    yes={() => (
                        <>
                            <Authorization
                                perform="qualityMetrics:view"
                                yes={() => (
                                    <Route path='/QualityMetrics' component={QualityMetrics} />
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="profileSummary:view"
                                yes={() => (
                                    <Route path='/ProfileSummary' component={ProfileSummary} />
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="campaignResults:view"
                                yes={() => (
                                    <>
                                        <Route path="/CampaignResults" component={CampaignResults} />
                                    </>
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="formsAndReviews:view"
                                yes={() => (
                                    <Route path='/FormsAndReviews' component={FormsAndReviews} />
                                )}
                                no={() => <div></div>}
                            />
                            
                            <Route path="/UnsubscribedStatistics" component={UnsubscribedStatistics} />
                            <Route path="/CancelationForecast" component={CancelationForecast} />
                            <Route path="/ProfileCreditBalance" component={ProfileCreditBalance} />

                            <Authorization
                                perform="reservationsByProfile:view"
                                yes={() => (
                                    <Route path='/ReservationsByProfile' component={ReservationsByProfile} />
                                )}
                                no={() => <div></div>}
                            />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="profileMenu:view"
                    yes={() => (
                        <>
                            <Authorization
                                perform="searchProfile:view"
                                yes={() => (
                                    <>
                                        <Route exact path='/SearchProfile' component={SearchProfile} />
                                        <Route path='/ProfileDetails/:id?' component={ProfileDetails} />
                                    </>
                                )}
                                no={() => <div></div>}
                            />
                            
                            <Route exact path='/DetailProfileSearch' component={DetailProfileSearch} />


                            <Authorization
                                perform="ProfileMenu:import"
                                yes={() => (
                                    <Route path='/ImportProfile' component={ImportProfile} />
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="searchReservation:view"
                                yes={() => (
                                    <Route path='/SearchReservation' component={SearchReservation} />
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="WhatsApp:view"
                                yes={() => (
                                    <Route path='/WhatsAppChat' component={WhatsAppChat} />
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="deletedProfiles:view"
                                yes={() => (
                                    <Route path='/DeletedProfiles' component={DeletedProfiles} />
                                )}
                                no={() => <div />}
                            />
                        </>
                    )}
                    no={() => <div></div>}
                />


                <Authorization
                    perform="mergeProfiles:view"
                    yes={() => (
                        <>
                            <Route exact path='/ProfileMergeDetail' component={ProfileMergeDetail} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="profileAnalysis:view"
                    yes={() => (
                        <>
                            <Route path='/ProfileAnalysis' component={ProfileAnalysis} />
                        </>
                    )}
                    no={() => <div></div>}
                />


                <Authorization
                    perform="formsMenu:view"
                    yes={() => (
                        <>
                            <Route exact path='/SearchForm' component={SearchForm} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="configMenu:view"
                    yes={() => (
                        <>
                            <Route path='/TagMapping' component={TagMapping} />
                            <Route path='/ProfileScore' component={ProfileScore} />
                            <Route exact path='/ManageUser' component={ManageUser} />
                            <Route path='/ReferenceData' component={ReferenceData} />
                            <Route path='/ProfileMatchConfig' component={ProfileMatchConfig} />
                            <Route path='/LoyaltyCardRules' component={LoyaltyCardRules} />
                            <Route path='/ProfileNowConfig' component={ProfileNowMenu} />
                            <Route path='/FormBuilder/:id?' component={FormBuilder}/>
                            <Route path='/PNFormAnswers/:id' component={PNFormAnswers} />
                            <Route path='/MapPNForm' component={MapPNForm} />
                            <Route path='/CustomFields' component={CustomFields} />


                            <Authorization
                                perform="loyaltyCardAttribution:view"
                                yes={() => (
                                    <Route path='/LoyaltyCardAttribution' component={LoyaltyCardAttribution} />
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="loyaltyCardLevels:view"
                                yes={() => (
                                    <Route path='/LoyaltyCardLevels' component={LoyaltyCardLevels} />
                                )}
                                no={() => <div></div>}
                            />
                            <Authorization
                                perform="configCardPoints:view"
                                yes={() => (
                                    <Route path='/ConfigCardPoints' component={ConfigCardPoints} />
                                )}
                                no={() => <div></div>}
                            />
                            <Authorization
                                perform="loyaltyCardTypes:view"
                                yes={() => (
                                    <Route path='/LoyaltyCardTypes' component={LoyaltyCardTypes} />
                                )}
                                no={() => <div></div>}
                            />
                            <Route path='/Mappings' component={Mappings} />
                            <Route path='/CustomTags' component={CustomTags} />

                            <Authorization
                                perform="profileDataQuality:view"
                                yes={() => (
                                    <Route path='/ConfigProfileDataQuality' component={ConfigProfileDataQuality} />
                                )}
                                no={() => <div></div>}
                            />

                            <Route path='/RetentionPolicyRule' component={RetentionPolicyRule} />

                            <Route path='/SummaryConfig' component={SummaryConfig} />
                            <Route path='/ConfigRuns/:id?' component={ConfigRuns} />
                            <Authorization
                                perform="GPT:usage"
                                yes={() => (
                                    <Route path='/AIConfig' component={AIConfig} />
                                )}
                                no={() => <div></div>}
                            />


                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    <Route path='/GoogleAnalyticsAccounts' component={GoogleAnalyticsAccounts} />
                                )}
                                no={() => <div></div>}
                            />

                            <Authorization
                                perform="salesProcess:view"
                                yes={() => (
                                    <Route path='/SalesProcess' component={SalesProcess} />
                                )}
                                no={() => <div></div>}
                            />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="reviewPro:view"
                    yes={() => (
                        <>
                            <Route path='/ReviewProMenu' component={ReviewProMenu} />
                            <Route path='/reviewProConfig' component={reviewProConfig} />
                            <Route path='/reviewProInquiry/:id' component={reviewProInquiry} />
                            <Route path='/ReviewProExportProfiles' component={ReviewProExportProfiles} />
                            <Route path='/ReviewProMapForm' component={MapForm} />
                            <Route path='/CustomExport' component={CustomExport} />
                            <Route path='/ReviewProStats' component={Stats} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="marketingAutomationMenu:view"
                    yes={() => (
                        <>
                            <Route path='/InquiryList' component={InquiryList} />
                            <Route path='/MarketingConfigEgoi' component={MarketingConfigEgoi} />
                            <Route path='/CampaignList' component={CampaignList} />
                            <Route path='/CampaignTargets' component={CampaignTargets}/>
                            <Route path='/Targets/:id?' component={Targets}/>
                            <Route path='/EgoiList' component={EgoiList} />
                            <Route path='/TransactionalTemplateList' component={TransactionalTemplateList} />
                            <Route path='/EgoiWebhookList' component={EgoiWebhookList} />
                            <Route path='/DomainList' component={DomainList} />
                            <Route path='/TagList' component={TagList} />
                            <Route path='/SendersList' component={SendersList} />
                            <Route path='/ShortenerList' component={ShortenerList} />
                            <Route path='/AlertsList' component={AlertsList} />
                            <Route path='/AlertDetails/:id?' component={AlertDetails} />
                            <Route path='/Campaigns' component={Campaigns} />
                            <Route path='/CampaignDetails/:id?' component={CampaignDetails} />
                            <Route path='/StatsEgoi' component={StatsEgoi} />
                            <Route path='/EgoiServiceActivation' component={EgoiServiceActivation} />
                            <Route path='/CustomCampaign' component={CustomCampaign} />
                            <Route path='/CustomCampaignDetails/:id?' component={CustomCampaignDetails} />
                            <Route path='/MarketingAlerts' component={MarketingAlerts} />
                            <Route path='/MarketingAlertDetails/:id?' component={MarketingAlertDetails} />
                            <Route path='/FormList' component={FormList} />
                            <Route path='/FormSubmissions' component={FormSubmissions} />
                            <Route path='/EgoiMapForm' component={MapFormModal} />
                            <Route path='/ExportProfiles' component={ExportProfiles} />
                            <Route path='/CommunicationStats/:id?' component={CommunicationStats} />
                            <Route path='/AutomationList' component={AutomationList} />
                            <Route path='/CommunicationJourney' component={CommunicationJourney} />
                            <Route path='/SendDetails' component={SendDetails} />
                            <Route path='/Account' component={Account} />
                            <Route path='/SuppressionList' component={SuppressionList} />
                            <Route path='/AssociateTags' component={AssociateTags} />

                            <Authorization
                                perform="stripo:edit"
                                yes={() => (
                                    <>
                                        <Route path='/EmailCreation/:id/:duplicate?/:groupId?' component={Stripo}/>
                                        <Route path='/EmailTemplate' component={StripoHome}/>
                                    </>
                                )}
                                no={() => <div/>}
                            />
                            <Authorization
                                perform="WhatsApp:view"
                                yes={() => (
                                    <>
                                        <Route path='/WhatsAppConfig' component={WhatsAppConfig}/>
                                        <Route path='/NumberManagement' component={NumberManagement}/>
                                        <Route path='/WhatsAppAnalytics' component={Analytics}/>
                                        <Route path='/TemplateManagement' component={TemplateManagement} />
                                        <Route path='/MediaManagement' component={MediaManagement}/>
                                    </>
                                )}
                                no={() => <div/>}
                            />
                            <Authorization
                                perform="TypeForm:view"
                                yes={() => (
                                    <>
                                        <Route path='/Typeform' component={TypeForm}/>
                                        <Route path='/TypeformForms' component={TypeformForms}/>
                                        <Route path='/TypeformAnswers/:id' component={TypeformAnswers}/>
                                        <Route path='/MapTypeForm/:id' component={MapTypeForm}/>
                                    </>
                                )}
                                no={() => <div/>}
                            />
                            <Route path='/GoogleBusinessConfig' component={GoogleBusinessConfig} />
                            <Route path='/GoogleBusinessNotification/:accountId' component={GoogleBusinessNotification} />
                            <Route path='/GoogleBusinessReview/:accountId' component={GoogleBusinessReview} />
                            <Route path='/GoogleBusinessQA/:accountId' component={GoogleBusinessQA} />
                            <Route path='/GoogleBusinessLocations/:accountId' component={GoogleBusinessLocations} />
                            <Route path='/GoogleBusinessLocationDetail/:accountId/:locationId' component={GoogleBusinessLocationDetail} />
                            <Route path='/GoogleBusinessFood/:accountId' component={GoogleBusinessFood} />
                            <Route path='/GoogleBusinessPosts/:accountId' component={GoogleBusinessPosts} />
                            <Route path='/GoogleBusinessMedia/:accountId' component={GoogleBusinessMedia} />
                            

                            <Route path='/MailConfig' component={MailConfig} />

                            <Authorization
                                perform="adminOperation:view"
                                yes={() => (
                                    <Route path='/MessageConfig' component={MessageConfig} />
                                )}
                            />

                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    <Route path='/CustomerJourney' component={CustomerJourneyDashboard} />
                                )}
                            />
                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    <Route path='/JourneyBuilder/:id?/:journeyRunId?' component={JourneyBuilder} />
                                )}
                            />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Route exact path='/Profile' component={Profile} />


                <Authorization
                    perform="adminOperation:view"
                    yes={() => (
                        <>
                            <Route path='/ReservationSummary' component={ReservationSummary} />
                            <Route path='/Revenue' component={Revenue} />
                            <Route path='/ReservationList' component={ReservationList} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="integrations:view"
                    yes={() => (
                        <>
                            <Route path='/BatchLogs' component={BatchLogs} />
                            <Route path='/ApiLogs' component={ApiLogs} />
                            <Route path='/HijiffyInbox' component={HijiffyInbox} />
                            <Route path='/HijiffyContacts' component={HijiffyContacts} />
                            <Route path='/HijiffyProfile' component={HijiffyProfile} />
                            <Route path='/ProcessIssues' component={ProcessIssues} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="salesAutomation:view"
                    yes={() => (
                        <>
                            <Route path='/Pipelines' component={Pipelines} />
                            <Route path='/PipelineStages/:id' component={PipelineStages} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="GPT:config"
                    yes={() => (
                        <Route path='/PromptConfig' component={PromptConfig} />
                    )}
                    no={() => <div></div>}
                />

                <Authorization
                    perform="heyCard:view"
                    yes={() => (
                        <>
                            <Route exact path='/AvailableCards' component={AvailableCards} />
                            <Route exact path='/SearchCards' component={SearchCards} />
                            <Route path='/VoucherDetails/:id?' component={VoucherDetails} />
                            <Route exact path='/BulkVouchersCreation' component={BulkVouchersCreation} />
                        </>
                    )}
                    no={() => <div></div>}
                />

                <NotificationContainer />
            </Layout>
        );
    }
}
