import React, { Component } from 'react'
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button, Card, Badge } from 'reactstrap';
import { KebabMenu } from '../Base/CommonUIComponents';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import SalesPipelineDetails from './SalesPipelineDetails';


export default class SalesPipeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: null
        };
    }

    toggleModal = (modal, pipeline) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedPipeline: pipeline
        }))
    }

    render() {
        const { error, modal, deleteModal, selectedPipeline } = this.state;
        const { salesPipelines, getSalesPipelines, deletePipeline, blockPipelines } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={blockPipelines}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${this.props.icon} mr-2`} />
                                    <FormattedMessage id="SalesPipeline.SalesPipeline" />
                                </h4>
                            </Col>
                            <Col className='col-2 text-right'>
                                <Button className="btn-sm btn-host mr-2" onClick={_ => this.toggleModal('modal')}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <Row className='mt-2'>
                        {salesPipelines?.map((pipeline, k) =>
                            <Col className='col-4 mb-3' key={k}>
                                <Card body className='p-3 h-100'>
                                    <Row>
                                        <Col onClick={_ => this.toggleModal("modal", pipeline)} className='cursor-pointer'>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <b>{pipeline.name}</b>
                                                    </div>
                                                </Col>
                                                <Col className=' text-right'>
                                                    <Badge className='ml-2' color='primary'>
                                                        <FormattedMessage id={`ReferenceDataFunctions.${pipeline.type}`} />
                                                    </Badge>
                                                </Col>
                                            </Row>
                                            <div className='text-muted title-sm'>
                                                {pipeline.description}
                                            </div>
                                        </Col>
                                        <Col className='col-1 d-flex align-items-center justify-content-center'>
                                            <KebabMenu
                                                deleteFunction={_ => this.toggleModal('deleteModal', pipeline)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}

                        {modal ?
                            <SalesPipelineDetails
                                modal={modal}
                                toggleModal={() => this.toggleModal('modal')}
                                pipeline={selectedPipeline}
                                getSalesPipelines={getSalesPipelines}
                            />
                            : ''}

                        {deleteModal ?
                            <ConfirmActionModal
                                modal={deleteModal}
                                toggleModal={_ => this.toggleModal('deleteModal')}
                                actionFunction={_ => deletePipeline(selectedPipeline.id)}
                                text={<FormattedMessage id="SalesPipeline.ConfirmDeleteText" values={{ pipeline: selectedPipeline.name }} />}
                                block={blockPipelines}
                            />
                            : ''}
                    </Row>
                </BlockUi>
            </div>
        )
    }
}
