import React, { Component, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, CardBody, Col, Row, Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import { StyledCard } from "../Base/CommonUIComponents";
import { getAPI, postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import CustomSelect from "../Base/CustomSelect";
import { DateRangePicker } from 'react-dates';
import { FormattedMessage } from "react-intl";
import { Doughnut, Line } from 'react-chartjs-2';
import moment from 'moment';

export class StatsEgoi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            activeTab: '0',
            startDate: moment().subtract(10, 'days'),
            endDate: moment(),
            groupBy: 'day',
            emailReport: { labels: [], datasets: [] },
            emailReportMetrics: { labels: [], datasets: [] },
            smsReport: { labels: [], datasets: [] },
            smsReportMetrics: { labels: [], datasets: [] },
            emailReportPie: { labels: [], datasets: [] },
            emailPie: { labels: [], datasets: [] },
            smsReportPie: { labels: [], datasets: [] },
            smsPie: { labels: [], datasets: [] }
        };
    }

    componentDidMount() {
        this.getMailStats();
        this.getSmsStats();
    }

    getMailStats = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                this.buildLineChartData(data.response, 'emailReport', 'emailReportMetrics', 'emailPie', 'emailReportPie');
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/email/reports?dateStart=${moment(this.state.startDate).format("YYYY-MM-DD")}&dateEnd=${moment(this.state.endDate).format("YYYY-MM-DD")}&groupBy=${this.state.groupBy}`);
    }

    getSmsStats = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                }
                this.buildLineChartData(data.response, 'smsReport', 'smsReportMetrics', 'smsPie', 'smsReportPie');
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/egoitransactional/v2/sms/reports?dateStart=${moment(this.state.startDate).format("YYYY-MM-DD")}&dateEnd=${moment(this.state.endDate).format("YYYY-MM-DD")}&groupBy=${this.state.groupBy}`);
    }

    buildLineChartData = (data, report, metricsReport, pie, resportPie) => {
        //Dates
        let dates = [];

        if (this.state.groupBy === 'month') {
            var currMonth = moment(this.state.startDate).clone();
            dates.push(currMonth.format('YYYY-MMM'))
            while (currMonth.add(1, 'month').diff(this.state.endDate) < 0) {
                dates.push(moment(currMonth).format('YYYY-MMM'));
            }
        } else {
            var currDay = moment(this.state.startDate).clone();
            dates.push(currDay.format('YYYY-MM-DD'))
            while (currDay.add(1, 'day').diff(this.state.endDate) < 0) {
                dates.push(moment(currDay).format('YYYY-MM-DD'));
            }
        }

        //Setup
        let reportData = {
            labels: [],
            datasets: [
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.ClicksTotal" }), data: Array(dates.length).fill(0), borderColor: '#0867fa', backgroundColor: '#0867fa', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.ClicksUnique" }), data: Array(dates.length).fill(0), borderColor: '#48d7ea', backgroundColor: '#48d7ea', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.ViewsTotal" }), data: Array(dates.length).fill(0), borderColor: '#8B75EA', backgroundColor: '#8B75EA', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.ViewsUnique" }), data: Array(dates.length).fill(0), borderColor: '#d6edfe', backgroundColor: '#d6edfe', fill: false },
            ]
        };

        let metricsData = {
            labels: [],
            datasets: [
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.MetricProcessed" }), data: Array(dates.length).fill(0), borderColor: '#0867fa', backgroundColor: '#0867fa', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.MetricsSent" }), data: Array(dates.length).fill(0), borderColor: '#48d7ea', backgroundColor: '#48d7ea', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.MetricsTotal" }), data: Array(dates.length).fill(0), borderColor: '#8B75EA', backgroundColor: '#8B75EA', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.EventRemoved" }), data: Array(dates.length).fill(0), borderColor: '#d6edfe', backgroundColor: '#d6edfe', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.EventSoftBounce" }), data: Array(dates.length).fill(0), borderColor: '#FF9827', backgroundColor: '#FF9827', fill: false },
                { label: this.props.intl.formatMessage({ id: "StatsEgoi.EventHardBounce" }), data: Array(dates.length).fill(0), borderColor: '#FFBE2D', backgroundColor: '#FFBE2D', fill: false },
            ]
        };

        let reportPie = {
            labels: reportData.datasets.map(x => x.label),
            datasets: [{ label: 'Total', data: Array(4).fill(0),  backgroundColor: ['#0867fa', '#48d7ea', '#8B75EA', '#d6edfe'] }]
        };

        let metricsPie = {
            labels: metricsData.datasets.map(x => x.label),
            datasets: [{ label: 'Total', data: Array(6).fill(0), backgroundColor: ['#0867fa', '#48d7ea', '#8B75EA', '#d6edfe', '#FF9827', '#FFBE2D'] }]
        };

        reportData.labels = dates;
        metricsData.labels = dates;


        //Fill
        data && data.forEach(el => {
            const index = dates.findIndex(d => moment(el.timePeriod).isSame(d));

            //Line
            reportData.datasets[0].data[index] = el.clicksTotal;
            reportData.datasets[1].data[index] = el.clicksUnique;
            reportData.datasets[2].data[index] = el.viewsTotal;
            reportData.datasets[3].data[index] = el.viewsUnique;


            metricsData.datasets[0].data[index] = el.metricProcessed;
            metricsData.datasets[1].data[index] = el.metricsSent;
            metricsData.datasets[2].data[index] = el.metricsTotal;
            metricsData.datasets[3].data[index] = el.eventRemoved;
            metricsData.datasets[4].data[index] = el.eventSoftBounce;
            metricsData.datasets[5].data[index] = el.eventHardBounce;

            //Pie
            reportPie.datasets[0].data[0] = el.clicksTotal;
            reportPie.datasets[0].data[1] += el.clicksUnique;
            reportPie.datasets[0].data[2] += el.viewsTotal;
            reportPie.datasets[0].data[3] += el.viewsUnique;


            metricsPie.datasets[0].data[0] += el.metricProcessed;
            metricsPie.datasets[0].data[1] += el.metricsSent;
            metricsPie.datasets[0].data[2] += el.metricsTotal;
            metricsPie.datasets[0].data[3] += el.eventRemoved;
            metricsPie.datasets[0].data[4] += el.eventSoftBounce;
            metricsPie.datasets[0].data[5] += el.eventHardBounce;

        })


        this.setState({
            [report]: reportData, [metricsReport]: metricsData,
            [pie]: metricsPie, [resportPie]: reportPie
        })
    }

    toggle(val) { this.setState({ activeTab: val }) }

    doSearch = () => {
        this.getMailStats();
        this.getSmsStats();
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    render() {
        const groupBy = [
            { value: 'day', label: <FormattedMessage id="StatsEgoi.Day" /> },
            { value: 'month', label: <FormattedMessage id="StatsEgoi.Month" /> },
        ]


        const optionsLine = {
            responsive: true,
            plugins: { datalabels: { display: false } },
            scales: {
                x: {
                    display: true
                },
                y: {
                    ticks: { precision: 0 },
                    beginAtZero: true,
                    min: 0,
                    grid: {
                        display: false
                    },
                }
            }
        }

        const optionsPie = {
            maintainAspectRatio: false,
            cutout: '60%',
            plugins: {
                datalabels: { display: false }, legend: {
                    position: "left"
                }
            },              
        }

        return (
            <StyledCard block={this.state.block} error={this.state.error} >
                <Row>
                    <Col>
                        <h5><i className="icon-icon-Satistics-menu mr-1" /> <FormattedMessage id="NavMenu.StatsEgoi" /></h5>
                    </Col>
                </Row>

                <Row className="mb-3 align-items-center">
                    <Col className="col-2">
                        <CustomSelect
                            options={groupBy}
                            isClearable
                            isSearchable
                            placeholder={<FormattedMessage id="StatsEgoi.GroupBy" />}
                            onChange={(e) => this.handleSelect(e, "groupBy")}
                            value={groupBy.find(c => c.value === this.state.groupBy)}
                        />
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={this.state.startDate}
                            startDateId="startDate"
                            isOutsideRange={day => false}
                            endDate={this.state.endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>

                    <Col className="col-1 text-right">
                        <Button className=" btn btn-host btn-sm" onClick={this.doSearch}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>

                </Row>
                
                <Nav tabs className="border-0">
                    <NavItem >
                        <NavLink className={this.state.activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => { this.toggle('0'); }} style={{ cursor: "pointer" }}>
                            <b> <FormattedMessage id="StatsEgoi.Email" /></b>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={this.state.activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => { this.toggle('1'); }} style={{ cursor: "pointer" }}>
                            <b> <FormattedMessage id="StatsEgoi.Sms" /></b>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="0" className="border-0 my-0 p-0 ">
                        <Row className="my-3">
                            <Col className="col-7">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.ClicksAndViews" /></h5>
                                        <Line data={this.state.emailReport} height={85} options={optionsLine} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-5">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.TotalClicksAndViews" /></h5>
                                        <div className="w-100">
                                            <Doughnut data={this.state.emailReportPie} height={250} options={optionsPie} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-7">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.MetricsAndEvents" /></h5>
                                        <Line data={this.state.emailReportMetrics} height={85} options={optionsLine} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-5">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.TotalMetricsAndEvents" /></h5>
                                        <div className="w-100">
                                            <Doughnut data={this.state.emailPie} height={250} options={optionsPie} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="1" className="border-0 my-0 p-0 ">
                        <Row className="my-3">
                            <Col className="col-7">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.ClicksAndViews" /></h5>
                                        <Line data={this.state.smsReport} height={85} options={optionsLine} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-5">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.TotalClicksAndViews" /></h5>
                                        <div className="w-100">
                                            <Doughnut data={this.state.smsReportPie} height={250} options={optionsPie} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-7">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.MetricsAndEvents" /></h5>
                                        <Line data={this.state.smsReportMetrics} height={85} options={optionsLine} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="col-5">
                                <Card className="shadow border-0 h-100">
                                    <CardBody>
                                        <h5 className="text-muted"><FormattedMessage id="StatsEgoi.TotalMetricsAndEvents" /></h5>
                                        <div className="w-100">
                                            <Doughnut data={this.state.smsPie} height={250} options={optionsPie} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </StyledCard>
        );
    }
}
export default injectIntl(StatsEgoi)
