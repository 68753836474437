import React, { Component } from 'react';
import { ButtonGroup, Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Authorization from './Authorization';
import { CustomTable } from './CustomTable';
import { getAPI } from './API';
import { handleNotification } from './Notification';
import { ErrorAlert } from './ErrorAlert';
import BlockUi from 'react-block-ui';
import moment from 'moment';


export class ProfileHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            modal: false,
            columns: [],
            history: [],
            pageSize: 25,
            pageIndex: 0,
            totalItems: 0
        };

    }

    componentDidMount() {
        this.setState({
            columns: getDataColumns(this.props.component)
        });
    }

    doSearch = () => {
        this.setState({
            modal: true
        }, this.getHistory);
    }

    getHistory = () => {
        this.setState({ block: true, history: [] });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const history = this.getHistoryWithHighlightedFields(data.response);

                this.setState({
                    history,
                    totalItems: data.totalItems,
                    block: false,
                    errorMessage: []
                });
            }
            else this.setState({ block: false });
        }, `/api/gms/Profile/v1/profile/${this.props.profileId}/history/${this.props.component}?pageIndex=${this.state.pageIndex}&pageSize=${this.state.pageSize}`)
    }

    getHistoryWithHighlightedFields = (data) => {
        let history = [];

        if (data[0] && typeof history === 'object' && Object.values(data[0]) && Object.values(data[0])[0]) {
            history = Object.values(data[0])[0].sort((a, b) => moment(a.updatedAt).isSame(moment(b.updatedAt)) ? b.version - a.version : moment(b.updatedAt).diff(moment(a.updatedAt), 'days'));

            history.forEach((info, idx) => {
                const nextObj = history[idx + 1];

                if (idx === 0 || info.id !== history[idx - 1].id) {
                    info.isCurrent = true;
                }

                if (nextObj && info.id === nextObj.id) {
                    let repeatedFields = [];

                    Object.entries(info).forEach(entry => {
                        if (entry[1] !== nextObj[entry[0]]) {
                            repeatedFields.push(entry[0]);
                        }
                    })

                    history[idx + 1].highlightedFields = repeatedFields;
                }
            })
        }

        return history;
    }

    onColumnToggle = (field) => {
        const { columns } = this.state;
        const index = columns.findIndex(el => el.dataField === field);

        if (index !== -1) {
            columns[index].hidden = !columns[index].hidden;
        }

        this.setState({ columns });
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page > 0 ? page - 1 : 0,
            pageSize: sizePerPage,
        }, this.getHistory);
    }

    render() {
        const { modal, columns, history, pageIndex, pageSize, totalItems } = this.state;

        return (
            <Authorization
                perform="history:view"
                yes={() => (
                    <>
                        <HistoryButton
                            onClickFunction={this.doSearch} 
                        />

                        {modal ?
                            <Modal isOpen={modal} fade={false} size={"xl"} style={{ maxWidth: '90%', width: '90%' }} >
                                <Button onClick={this.toggleModal} className="closeModal">
                                    <i className="fas fa-times fa-sm"/>
                                </Button>
                                <ModalBody className="font_size_xxs">
                                    <BlockUi tag="div" blocking={this.state.block}>
                                        <ErrorAlert error={this.state.error} />

                                        <Row className="mb-3 mt-2">
                                            <Col>
                                                <h5> <FormattedMessage id={`HistoryComponents.${this.props.component}History`} /> </h5>
                                            </Col>
                                        </Row>

                                        <CustomTable
                                            keyField="auditId"
                                            data={history}
                                            columns={columns}
                                            shadow={false}
                                            exportCSV={false}
                                            showTotal={true}
                                            remote={true}
                                            page={pageIndex + 1}
                                            sizePerPage={pageSize}
                                            totalSize={totalItems}
                                            hideSizePerPage={false}
                                            onTableChange={this.handleTableChange}
                                            wrapperClasses="table-responsive"
                                            toggleList={{ show: true, width: '160px', onColumnToggle: this.onColumnToggle }}
                                        />
                                    </BlockUi>
                                </ModalBody>
                            </Modal>
                        : ''}
                    </>
                )}
                no={() => <div></div>}
            />
        )
    }
}

export const HistoryButton = ({ onClickFunction }) => (
    <Button className="btn btn-host btn-sm" onClick={onClickFunction} >
        <i className="fas fa-history" />
    </Button>
);


const getDataColumns = (component) => {
    let columns = [];

    const column = (dataField, text, hidden = false, formatter) => {
        return {
            dataField,
            text,
            headerStyle: { whiteSpace: 'pre' },
            hidden,
            formatter: (cell, row) => cell ? formatter ? formatter.isTimestamp ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : formatter.isDate ? moment(cell).format("YYYY-MM-DD") : formatter(cell, row) : cell : cell,
            classes: (cell, row) => row.isCurrent ? "bg-host-light" : row.highlightedFields?.some(hf => hf === dataField) ? "bg-yellow-light" : ""
        }
    }

    const commonColumns = [
        column('createdAt', 'Created At', true, { isTimestamp: true }),
        column('updatedAt', 'Updated At', false, { isTimestamp: true }),
        column('createdByName', 'Created By', true ),
        column('updatedByName', 'Updated By', false ),
        column('version', 'Version', false ),
        column('deleted', 'Deleted', true ),
        //AuditID?
    ];

    
    switch (component) {
        case 'profile':
            columns = [
                column('id', 'Id', true ),
                column('type', 'Type' ),
                column('titleCode', 'Title Code',  ),
                column('firstName', 'First Name',  ),
                column('lastName', 'Last Name',  ),
                column('middleName', 'Other Name',  ),
                column('fullName', 'Full Name',  ),
                column('gender', 'Gender' ),
                column('nationality', 'Nationality' ),
                column('language', 'Language' ),
                column('birthDate', 'Birth Date', false, { isDate: true }),
                column('anagram', 'Anagram', true ),
                column('isMaster', 'Is Master',),
                column('isPortal', 'Is Portal',),
                column('masterId', 'Master Id',  ),
                column('profileLastUpdate', 'Profile Last Update',  ),
                column('externalId', 'External Id',  ),
                column('birthDay', 'Birth Day', true ),
                column('birthMonth', 'Birth Month', true ),
                column('birth Year', 'Birth Year', true ),
                column('source', 'Source' ),
                column('identifiersLastUpdate', 'Identifiers Last Update', false, { isTimestamp: true }),
                column('guestInfo', 'Guest Info', true, (cell) => cell ? <span className="text-ellipsis">{cell}</span> : '' ),
                column('searchableGuestNumber', 'Searchable Guest Number',  ),
                column('fullNameVector', 'Full Name Vector', true ),
                column('rightToBeForgotten', 'Right To Be Forgotten',),
                column('isManualMerge', 'Is Manual Merge',),
                column('isAutomaticMerge', 'Is Automatic Merge',),
                column('automaticMergeAt', 'Merge At', false, { isTimestamp: true }),
                column('iss', 'Iss' ),
                column('pmsGlobalId', 'Pms Global Id', true ),
                column('pmsId', 'Pms Id',  ),
                column('pmsTimestamp', 'Pms Timestamp', true, { isTimestamp: true }),
                column('hotelName', 'Hotel', true )
            ];
            break;
        case 'address':
            columns = [
                column('id', 'Id' ),
                column('type', 'Type' ),
                column('addressLine1', 'Address Line 1',  ),
                column('addressLine2', 'Address Line 2',  ),
                column('addressLine3', 'Address Line 3',  ),
                column('addressLine4', 'Address Line 4',  ),
                column('coordinates', 'Coordinates' ),
                column('locality', 'Locality' ),
                column('region', 'Region' ),
                column('postalCode', 'Postal Code',  ),
                column('country', 'Country' ),
                column('anagram', 'Anagram', true),
                column('hotelName', 'Hotel', true)
            ];
            break;
        case 'billing':
            columns = [
                column('id', 'Id' ),
                column('pmsId', 'Pms Id', ),
                column('profileGlobalId', 'Profile Global Id',  ),
                column('targetProfileId', 'Target Profile Id',  ),
                column('targetProfileGlobalId', 'Target Profile Global Id',  ),
                column('mode', 'Mode' ),
                column('targetItem', 'Target Item',  ),
                column('pmsTimestamp', 'Pms Timestamp', true, { isTimestamp: true } ),
                column('isDelete', 'Is Delete',  ),
                column('hotelName', 'Hotel', )
            ];
            break;
        case 'consent':
            columns = [
                column('id', 'Id' ),
                column('type', 'Type' ),
                column('active', 'Active' ),
                column('method', 'Method' ),
                column('consentBegin', 'Consent Begin', false, { isDate: true } ),
                column('consentEnd', 'Consent End', false, { isDate: true } ),
                column('pmsId', 'Pms Id',  ),
                column('pmsTimestamp', 'Pms Timestamp', true, { isTimestamp: true } ),
                column('hotelName', 'Hotel', true)
            ];
            break;
        case 'contact':
            columns = [
                column('id', 'Id' ),
                column('type', 'Type', false),
                column('contact', 'Contact' ),
                column('main', 'Main' ),
                column('active', 'Active' ),
                column('pmsId', 'Pms Id', ),
                column('pmsTimestamp', 'Pms Timestamp', true, { isTimestamp: true } ),
                column('hotelName', 'Hotel', true)
            ];
            break;
        case 'credit':
            columns = [
                column('id', 'Id' ),
                column('hasCityLedger', 'Has City Ledger',  ),
                column('creditLimit', 'Credit Limit',  ),
                column('creditLimitType', 'Credit Limit Type',  ),
                column('creditLimitPOS', 'Credit Limit POS',  ),
                column('creditLimitTypePOS', 'Credit Limit Type POS',  ),
                column('financeAccount', 'Finance Account',  ),
                column('paymentDueDays', 'Credit Conditions',  ),
                column('hotelName', 'Hotel', ),
            ];
            break;
        case 'default':
            columns = [
                column('id', 'Id' ),
                column('defaultRoomCategory', 'Room Category',  ),
                column('defaultRoom', 'Room',  ),
                column('defaultPackage', 'Package',  ),
                column('defaultPricelist', 'Price List',  ),
                column('defaultDiscount', 'Discount',  ),
                column('defaultDiscountRule', 'Discount Rule',  ),
                column('defaultPricelistPOS', 'Price list POS',  ),
                column('defaultRestaurantTable', 'Restaurant Table',  ),
                column('hotelName', 'Hotel', )
            ];
            break;
        case 'document':
            columns = [
                column('id', 'Id' ),
                column('type', 'Type' ),
                column('number', 'Number' ),
                column('issueDate', 'Issue Date', false, { isDate: true } ),
                column('validUntil', 'Valid Until', false, { isDate: true } ),
                column('hotelName', 'Hotel', true)
            ];
            break;
        case 'information':
            columns = [
                column('id', 'Id' ),
                column('area', 'Area' ),
                column('notes', 'Notes' ),
                column('pmsId', 'Pms Id',  ),
                column('pmsProfileId', 'Pms Profile Id',  ),
                column('sectionCode', 'Section Code',  ),
                column('sectionDescription', 'Section Description',  ),
                column('pmsCreatedOn', 'Pms Created On', true ),
                column('pmsUpdatedOn', 'Pms Updated On', true ),
                column('pmsTimeStamp', 'Pms TimeStamp', true, { isTimestamp: true } ),
                column('hotelName', 'Hotel', )
            ];
            break;
        case 'membershipcard':
            columns = [
                column('id', 'Id' ),
                column('pmsId', 'Pms Id',  ),
                column('cardId', 'Card Id',  ),
                column('validFrom', 'Valid From', false, { isDate: true } ),
                column('validTo', 'Valid To', false, { isDate: true } ),
                column('cardNumber', 'Card Number',  ),
                column('notes', 'Notes' ),
                column('cardTypeCode', 'Card Type Code',  ),
                column('cardTypeDescription', 'Card Type Description',  ),
                column('membershipSourceId', 'Membership Source Id',  ),
                column('membershipSourceCode', 'Membership Source Code',  ),
                column('membershipTypeCode', 'Membership Type Code'),
                column('pmsTimestamp', 'Pms Timestamp', true, { isTimestamp: true } ),
                column('points', 'Points'),
                column('hotelName', 'Hotel', true),
                column('active', 'Active')
            ];
            break;
        case 'preference':
            columns = [
                column('id', 'Id' ),
                column('type', 'Type' ),
                column('action', 'Action' ),
                column('notes', 'Notes' ),
                column('hotelName', 'Hotel', )
            ];
            break;
        case 'salesmarketing':
            columns = [
                column('id', 'Id' ),
                column('segment', 'Segment' ),
                column('subSegment', 'Sub Segment',  ),
                column('distributionChannel', 'Distribution Channel',  ),
                column('vipCode', 'Vip Code',  ),
                column('hotelName', 'Hotel', ) 
            ];
            break;
        case 'socialmedia':
            columns = [
                column('id', 'Id' ),
                column('type', 'Type' ),
                column('url', 'Url' ),
                column('socialId', 'Social Id',  ),
                column('notes', 'Notes' ),
                column('main', 'Main' ),
                column('pmsId', 'Pms Id',  ),
                column('pmsTimestamp', 'Pms Timestamp', true, { isTimestamp: true } ),
                column('hotelName', 'Hotel', )
            ];
            break;
        case 'tag':
            columns = [
                column('id', 'Id' ),
                column('tagId', 'Tag Id',  ),
                column('frequency', 'Frequency' ),
                column('hotelName', 'Hotel',  )
            ];
            break;
        case 'warnings':
            columns = [
                column('id', 'Id' ),
                column('hasWarning', 'Has Warning',  ),
                column('warningText', 'Warning Text',  ),
                column('blackListCode', 'Black List Code',  ),
                column('blackListDescription', 'Black List Description',  ),
                column('hotelName', 'Hotel', )
            ];
            break;
        case 'customdata':
            columns = [
                column('id', 'Id'),
                column('value', 'Value'),
                column('typeCode', 'Type Code'),
                column('pmsId', 'Pms Id',),
                column('pmsTimestamp', 'Pms Timestamp', true, { isTimestamp: true }),
                column('hotelName', 'Hotel',),
                column('hotelGroupId', 'Hotel Group',)
            ];
            break;
    }

    return columns.concat(commonColumns);
}