import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap';
import { KebabMenu, StyledCard } from "../../Base/CommonUIComponents";
import { FormattedMessage } from 'react-intl';
import profileNow from '../../../img/profileNow.png';
import SurveyJS from '../../Base/SurveyJS';
import { getAPI, postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { isAValidJson } from '../../Marketing/CommunicationJourney/CommonFunctions';
import ConfirmActionModal from '../../Base/ConfirmActionModal';

class ProfileNowMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            pnForms: [],
            previewModal: false,
            formDetails: {},
            deleteModal: false,
            formToDelete: null
        };
    }

    componentDidMount() {
        this.getPNForms();
    }

    getPNForms = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.getReviewMappings(data.response);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey`);
    }

    getReviewMappings = (pnForms) => {
        if (pnForms) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, pnForms, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    if (data.response && data.response.length > 0) {
                        pnForms.forEach(el => {
                            el.isMapped = data.response.some(rm => rm.formId == el.id);
                        })
                    }

                    this.setState({ pnForms, block: false });
                }
                else this.setState({ block: false, pnForms });
            }, `/api/gms/Review/v1/reviewMapping/PNForm`);
        }
        else {
            this.setState({ pnForms: pnForms || [], block: false });
        }
    }

    getSelectedForm = (id) => {
        this.setState({ block: true })
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ selectedForm: data.response ? data.response[0] : null, previewModal: true });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Survey/v1/survey/${id}`);
    }

    toggleModal = () => {
        this.setState(prev => ({ previewModal: !prev.previewModal }));
    }

    createForm = () => {
        this.props.history.push({ pathname: `/FormBuilder` });
    }

    toggleDeleteModal = (formId) => {
        this.setState(prev => ({ deleteModal: !prev.deleteModal, formToDelete: formId }));
    }

    deleteForm = () => {
        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({ block: false, formToDelete: null }, () => 
                        handleNotification(data, <FormattedMessage id="ProfileNowMenu.FormDeleted" />, <FormattedMessage id="generic.success" />)
                    );
                }
                this.setState({ error: errorMessage, deleteModal: false, formToDelete: null }, () => this.getPNForms());
            }, `/api/gms/Survey/v1/survey/${this.state.formToDelete}/delete`)
        );
    }

    goToMapping = (id, name) => {
        this.props.history.push({ pathname: `/MapPNForm?form=${id}`, state: { surveyName: name } });
        this.props.history.go();
    }

    goToAnswers = (id, name) => {
        this.props.history.push({ pathname: `/PNFormAnswers/${id}`, state: { surveyName: name } });
    }

    editForm = (id) => {
        this.props.history.push({ pathname: `/FormBuilder/${id}` });
    }

    render() {
        const { block, error, pnForms, previewModal, selectedForm, deleteModal } = this.state;
        
        return (
            <StyledCard block={block} error={error} >
                <Row>
                    <Col className='d-flex align-items-center'>
                        <h5 className='m-0'>
                            <img className='mr-2' alt="" src={profileNow} height="18px"/>
                            <FormattedMessage id="ProfileNowMenu.Title"/>
                        </h5>
                    </Col>
                    <Col className='col-2 text-right'>
                        <Button onClick={this.createForm} className="btn btn-sm btn-host">
                            <i className="fas fa-plus"></i>
                        </Button>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    {pnForms?.map((form, idx) =>
                        <Col className="h-100 col-4 mb-3" key={idx}>
                            <Card className="border-0 shadow p-3">
                                <Row>
                                    <Col>
                                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                            <h5 className="mb-0 mr-2">{form.name}</h5>
                                            {form.isMapped ?
                                                <>
                                                    <i className="fas fa-random mr-2" id={`isMapped${idx}`} />
                                                    <UncontrolledTooltip target={`isMapped${idx}`} placement="bottom">
                                                        <FormattedMessage id="ProfileNowMenu.Mapped" />
                                                    </UncontrolledTooltip>
                                                </>
                                            :''}
                                            {form.anonymous ?
                                                <>
                                                    <i className="fas fa-user-secret mr-2" id={`anonymous${idx}`}/>
                                                    <UncontrolledTooltip target={`anonymous${idx}`} placement="bottom">
                                                        <FormattedMessage id="ProfileNowMenu.Anonymous" />
                                                    </UncontrolledTooltip>
                                                </>
                                            :''}
                                            {form.createProfile ?
                                                <>
                                                    <i className="fas fa-user-plus mr-2" id={`createProfile${idx}`}/>
                                                    <UncontrolledTooltip target={`createProfile${idx}`} placement="bottom">
                                                        <FormattedMessage id="ProfileNowMenu.CreatesUsers" />
                                                    </UncontrolledTooltip>
                                                </>
                                            :''}
                                        </div>
                                    </Col>
                                    <Col className='text-right col-2'>
                                        <KebabMenu
                                            deleteFunction={_ => this.toggleDeleteModal(form.id)}
                                            editFunction={form.anonymous && form.createProfile ? _ => this.editForm(form.id) : null}
                                            previewFunction={_ => this.getSelectedForm(form.id)}
                                            extraFields={
                                                !(form.anonymous && form.createProfile) ?
                                                    [{
                                                        function: _ => this.goToAnswers(form.id, form.name),
                                                        icon: "far fa-comment-dots mr-2",
                                                        text: "ProfileNowMenu.Replies"
                                                    },
                                                    {
                                                        function: _ => this.goToMapping(form.id, form.name),
                                                        icon: "fas fa-random mr-2",
                                                        text: "ProfileNowMenu.Mapping"
                                                    }]
                                                :
                                                    [{
                                                        function: _ => this.goToAnswers(form.id, form.name),
                                                        icon: "far fa-comment-dots mr-2",
                                                        text: "ProfileNowMenu.Replies"
                                                    }]
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )}
                </Row>
                <Modal isOpen={previewModal} toggle={this.toggleModal} fade={false} size={"lg"} style={{ minWidth: '80vw' }}>
                    <ModalBody>
                        {selectedForm?.rawForm && isAValidJson(selectedForm.rawForm) ?
                            <SurveyJS
                                formId={selectedForm.id}
                            />
                            : <div className="text-center"> <FormattedMessage id="ConfigPNForms.SurveyUnavailable" /> </div>}
                    </ModalBody>
                </Modal>

                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={_ => this.toggleDeleteModal()}
                        actionFunction={() => this.deleteForm()}
                        text={<FormattedMessage id="ConfigPNForms.DeleteFormQuestion" />}
                        block={block}
                    />
                    : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(ProfileNowMenu)
