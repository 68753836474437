import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Col, Row } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import { Pagination } from '../../Base/PaginationComponents';
import TemplatePreview from './TemplatePreview';
import WhatsAppTemplates from './WhatsAppTemplates';

class TemplateManagement extends Component {

  constructor (props) {
      super(props);
      this.state = {
          block: true,
          error: null,
          templates: [],
          nameSearch: null,
          stateSearch: null,
          catSearch: null,
          selectedTemplateId: null,
          hasNext: false,
          hasBefore: false,
          previousCursor: null,
          nextCursor: null,
          currLang: null,
          currentPage: 1
      };
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates = (cursor, isPrevious) => {
    getAPI(result => {
      const { data, error } = result;
      const errorMessage = [];

      if (error) {
          errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
          this.setState({ error: errorMessage, block: false });
          return;
      }
      if (data) {
          if (data.errors && data.errors.length > 0) {
            this.setState({ block: false }, () => handleNotification(data));
          }
          else if(data.response){
            const templates = [];

            data.response[0].data.forEach((template) => {
              const curr = templates.find(({ name }) => name === template.name);

              if(curr){
                curr.templates.push(template);
              }
              else{
                const newTemplate = {
                  templates: [template],
                  name: template.name
                };
                templates.push(newTemplate);
              }
            });

            const hasNext = data.response[0]?.paging && data.response[0]?.paging.next ? true : false;
            const hasBefore = data.response[0]?.paging && data.response[0]?.paging.previous ? true : false;
            const previousCursor = data.response[0]?.paging.cursors ? data.response[0]?.paging.cursors.before : null;
            const nextCursor = data.response[0]?.paging.cursors ? data.response[0]?.paging.cursors.after : null;

            this.setState({ templates: templates, block: false, hasNext, hasBefore, previousCursor, nextCursor });
          }
      }
      else {
          this.setState({ error: errorMessage, block: false });
      }
    }, `/api/gms/WhatsApp/v1/messageTemplate?limit=10${cursor ?`&pageCursor=${cursor}` : ''}${isPrevious ?`&next=${false}` : ''}`)
  }

  search = (e) => {
    e.preventDefault();
  }

  handleSearch = (e) => {
    const { value, name } = e.target;

    this.setState({ [name]: value });
  }

  handleSelect = (combo, name) => {
    this.setState({ [name]: combo.value });
  }

  editTemplate = (e, name, currLang) => {
    e.preventDefault();
    this.setState({ selectedTemplateId: name, currLang });
  }

  returnFunc = (e) => {
      e.preventDefault();

      this.setState({ selectedTemplateId: null });
  }
  
  orderComponents = (components) => {
    const res = components.sort((a, b) => {
      if(a.type === "HEADER") return -1;
      if(b.type === "HEADER") return 1;

      if(a.type === "BODY") return -1;
      if(b.type === "BODY") return 1;

      if(a.type === "FOOTER") return -1;
      if(b.type === "FOOTER") return 1;

      if(a.type === "BUTTONS") return -1;
      return 1;
    });
    return res;
  }

  handlePagination = (pos) => {
    const { hasNext, hasBefore, previousCursor, nextCursor, currentPage } = this.state;

    if(pos > 0 && hasNext) this.setState({ block: true, currentPage: currentPage + 1 }, () => this.getTemplates(nextCursor, false));
    if(pos < 0 && hasBefore) this.setState({ block: true, currentPage: currentPage - 1 }, () => this.getTemplates(previousCursor, true));
  }

  render() {
    const { templates, error, block, selectedTemplateId, currLang, hasNext, hasBefore, currentPage } = this.state;
    const { history } = this.props;
    
    return (
      (selectedTemplateId !== 0 && selectedTemplateId === null) ?
        <StyledCard error={error} block={block}>
          <div>
            <div>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/WhatsAppConfig"> 
                            <FormattedMessage id="NavMenu.WhatsAppConfig"/>
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        <FormattedMessage id="WhatsApp.TemplateManagement"/>
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            <Row>
              <Col className='text-right'>
                  <Button className="btn- btn-host btn-small btn-sm" onClick={e => this.editTemplate(e, 0)}>
                      <i className="fas fa-plus"/>
                  </Button>
              </Col>
            </Row>
            <Row className='mt-2'>
              {templates?.map((master, key) => 
                  <TemplatePreview
                    history={history}
                    editTemplate={this.editTemplate}
                    languages={master.templates?.map(({ language }) => language)}
                    name={master.name}
                    templates={master.templates}
                    key={key}
                    orderComponents={this.orderComponents}
                    components={master.components}
                  />
              )}
            </Row>
            {hasNext || hasBefore ?
                <Row className="my-4">
                    <Col className="text-right mr-3">
                        <Pagination
                            isPrevDisabled={!hasBefore}
                            isNextDisabled={!hasNext}
                            currentPage={currentPage}
                            handlePrevButton={() => this.handlePagination(-1)}
                            handleNextButton={() => this.handlePagination(1)}
                        />
                    </Col>
                </Row>
            :''}
          </div>
        </StyledCard>
      :
        <WhatsAppTemplates
          isNew
          returnFunc={this.returnFunc}
          lang={currLang}
          templates={templates?.find(({ name }) => name === selectedTemplateId)?.templates}
          orderComponents={this.orderComponents}
        />
    )
  }
}

export default injectIntl(TemplateManagement);