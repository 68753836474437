import React, { Component } from 'react';
import { BlankCard, StyledModal } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, ButtonGroup, Col, Form, Input, Row } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';

class ImportGroupResModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            reservationList: [],
            pageSize: 10,
            pageIndex: 0,
            searchTerm: "",
            startDate: null,
            endDate: null,
            selectedGroupResId: null,
            hotelId: null
        };
    }

    componentDidMount(){
    }

    getGroupRes = () => {
        const { pageSize, pageIndex, searchTerm, startDate, endDate } = this.state;
        let qs = "&";

        if(searchTerm)
            qs += `searchTerm=${searchTerm}`;
        
        if(startDate)
            qs += `checkinRangeFrom=${startDate}`;
            
        if(endDate)
            qs += `checkinRangeTo=${endDate}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ reservationList: data.response, block: false });
                return;
            }
            else
                this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/PmsGroupReservations?pageSize=${pageSize}&pageIndex=${pageIndex}${qs}`);
    }

    searchGroupRes = (e) => {
        e.preventDefault();
        this.setState({ block: true }, () => this.getGroupRes());
    }

    handleComboChange = (combo, name) => {
        this.setState({ [name]: combo?.value });
    }
    
    handleInputChange = (e) => {
        const { value, name } = e.target;

        this.setState({ [name]: value });
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getGroupRes());
        }
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getGroupRes());
    }

    selectGroupResId = (id) => {
        this.setState({ selectedGroupResId: id === this.state.selectedGroupResId ? null : id });
    }

    importRes = () => {
        const { selectedGroupResId, hotelId } = this.state;
        this.setState({ block: true }, () => 
            this.props.importGroupRes(selectedGroupResId, hotelId, null, () => this.setState({ block: false }))
        );
    }

    render() {
        const { toggleModal, modal } = this.props;
        const { block, error, reservationList, pageSize, pageIndex, searchTerm, startDate, endDate, hotelId, selectedGroupResId } = this.state;

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} className="bigModal" size="xl">
                <BlankCard block={block} error={error}>
                    <Form onSubmit={this.searchGroupRes}>
                        <Row>
                            <Col>
                                <h5><FormattedMessage id="SalesDeal.ImportGroupRes"/></h5>
                            </Col>
                            <Col className="col-1 text-right">
                                <Button type="button" className="btn-sm btn-host mr-2" onClick={this.importRes} disabled={!selectedGroupResId}>
                                    <i className="fas fa-file-upload"/>
                                </Button>
                                <Button className="btn-sm btn-host" type="submit">
                                    <i className="fas fa-search"/>
                                </Button>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col className="col-3">
                                <div className='title-sm'>
                                    <FormattedMessage id="SalesDeal.Hotel"/>
                                </div>
                                <CustomSelect
                                    placeholder=""
                                    options={global.hotelList}
                                    onChange={e => this.handleComboChange(e, 'hotelId')}
                                    value={global.hotelList.find(({value}) => value === hotelId)}
                                    required={true}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </Col>
                            <Col className="col-6">
                                <div className='title-sm'>
                                    <FormattedMessage id="SalesDeal.FreeSearch"/>
                                </div>
                                <Input
                                    type="text"
                                    value={searchTerm}
                                    name="searchTerm"
                                    onChange={this.handleInputChange}
                                />
                            </Col>
                            <Col className='col-3'>
                                <div className='title-sm'>
                                    <FormattedMessage id="SalesDeal.Dates"/>
                                </div>
                                <DateRangePicker
                                    startDate={startDate}
                                    startDateId="startDate"
                                    isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                    endDate={endDate}
                                    endDateId="endDate"
                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                />
                            </Col>
                        </Row>
                        {reservationList ?
                            <div className='mt-4'>
                                {reservationList && reservationList.length > 0 ?
                                    <>
                                        <div style={{ maxHeight: '50vh', overflow: 'auto' }} className='veryCoolScrollBar'>
                                            {reservationList.map((res, key) =>
                                                <div key={key} onClick={_ => this.selectGroupResId(res.groupReservationNumber)}
                                                    className='cursor-pointer shadow p-3 mb-3'
                                                    style={{
                                                        borderRadius: '4px',
                                                        background: res.groupReservationNumber === selectedGroupResId ? '#d3eaff' : 'white'
                                                    }}
                                                >
                                                    <div className='w-100 d-flex align-items-center justify-content-between'>
                                                        <div style={{ width: '30%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.CompanyName`}/>
                                                            </div>
                                                            <div title={res.companyEntityName} className='text-truncate'>
                                                                {res.companyEntityName ? res.companyEntityName : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '12%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.Status`}/>
                                                            </div>
                                                            <div title={res.reservationStatusDescription} className='text-truncate'>
                                                                {res.reservationStatusDescription ? res.reservationStatusDescription : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '12%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.CheckIn`}/>
                                                            </div>
                                                            <div title={res.checkIn} className='text-truncate'>
                                                                {res.checkIn ? moment(res.checkIn).format('YYYY-MM-DD') : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '12%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.CheckOut`}/>
                                                            </div>
                                                            <div title={res.checkOut} className='text-truncate'>
                                                                {res.checkOut ? moment(res.checkOut).format('YYYY-MM-DD') : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '6%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.NumberRooms`}/>
                                                            </div>
                                                            <div title={res.rooms} className='text-truncate'>
                                                                {res.rooms ? res.rooms : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '6%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.NumberSpaces`}/>
                                                            </div>
                                                            <div title={res.spaces} className='text-truncate'>
                                                                {res.spaces ? res.spaces : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '6%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.Pax`}/>
                                                            </div>
                                                            <div title={res.pax} className='text-truncate'>
                                                                {res.pax ? res.pax : '-'}
                                                            </div>
                                                        </div>
                                                        <div style={{ width: '16%' }} className='text-right'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.ResNumber`}/>
                                                            </div>
                                                            <div title={res.groupReservationNumber} className='text-truncate'>
                                                                {res.groupReservationNumber ? res.groupReservationNumber : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2 w-100 d-flex align-items-center justify-content-between'>
                                                        <div style={{ width: '30%' }}>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id={`SalesDeal.Block`}/>
                                                            </div>
                                                            <div title={res.allotmentBlockDescription} className='text-truncate'>
                                                                {res.allotmentBlockDescription ? res.allotmentBlockDescription : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <Row className="mt-3">
                                            <Col>
                                                <ButtonGroup onClick={this.changePageSize}>
                                                    <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                                    <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                                    <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                                    <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                                </ButtonGroup>
                                            </Col>

                                            <Col className="text-right">
                                                <span>
                                                    <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                                        <i className="fas fa-angle-left" />
                                                    </Button>
                                                    <span className="mr-2">
                                                        <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                                    </span>
                                                    <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={reservationList && reservationList.length < pageSize}>
                                                        <i className="fas fa-angle-right" />
                                                    </Button>
                                                </span>
                                            </Col>
                                        </Row>
                                    </>
                                :''}
                            </div>
                        :
                            <div className='py-4'>
                                <FormattedMessage id="SalesDeal.NoGroupReservations"/>
                            </div>
                        }
                    </Form>
                </BlankCard>
            </StyledModal>
        );
    }
}

export default injectIntl(ImportGroupResModal);