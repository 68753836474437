import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input, CustomInput } from 'reactstrap';
import { getSocialMediaList } from '../Base/ReferenceDataFunctions';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { putAPI } from "../Base/API";

class SocialMediaDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            socialMediaData: this.props.socialMedia ? this.props.socialMedia : {},
            socialMediaList: [],
            disableMain: false
        };
    }

    componentDidMount() {
        const { allSocialMedia, socialMedia } = this.props;
        const socialMediaList = allSocialMedia ? getSocialMediaList().map(sm => ({ ...sm, alreadyHasMain: allSocialMedia.some(el => (socialMedia ? el.id !== socialMedia.id : true) && el.type === sm.value && el.main === true) })) : getSocialMediaList();
        let disableMain = false;

        if (socialMedia) {
            disableMain = allSocialMedia.some(sm => sm.id !== socialMedia.id && sm.type === socialMedia.type && sm.main === true);
        }

        this.setState({ socialMediaList, disableMain });
    }

    handleChange = (e) => {
        const { name, value, checked } = e.target;

        this.setState({
            socialMediaData: {
                ...this.state.socialMediaData,
                [name]: (name === 'main' ? checked : value)
            }
        })
    }

    handleChangeSelect = (combo) => {
        const alreadyHasMain = combo && combo.alreadyHasMain;

        this.setState(prevState => ({
            socialMediaData: {
                ...prevState.socialMediaData,
                type: combo ? combo.value : null,
                main: alreadyHasMain ? false : prevState.socialMediaData.main,
            },
            disableMain: alreadyHasMain
        }))
    };

    saveSocialMedia = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var socialMedia = { ...this.state.socialMediaData };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="SocialMediaDetails.SocialMediaSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'socialMedia');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/socialMedia/${this.props.profileId}`, JSON.stringify({ request: socialMedia }));
    }

    render() {
        const { block, error, socialMediaData, socialMediaList } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveSocialMedia}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.props.socialMedia ? <FormattedMessage id="SocialMediaDetails.EditSocialMedia" /> : <FormattedMessage id="SocialMediaDetails.AddSocialMedial" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-8">
                                    <FormGroup row>
                                        <Label sm={3}> <FormattedMessage id="SocialMediaDetails.Type" /></Label>
                                        <Col sm={9}>
                                            <CustomSelect
                                                options={socialMediaList}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e)}
                                                value={socialMediaList.find(sm => sm.value === socialMediaData.type)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row id="isMain">
                                        <Label sm={4}> <FormattedMessage id="SocialMediaDetails.Main" /></Label>
                                        <Col sm={8} className="d-flex align-items-center justify-content-end">
                                            <CustomInput
                                                type="switch"
                                                id="1"
                                                name="main"
                                                onChange={(e) => this.handleChange(e)}
                                                checked={socialMediaData.main ? socialMediaData.main : false}
                                                disabled={this.state.disableMain}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={2}> URL </Label>
                                        <Col sm={10}>
                                            <Input
                                                type="text"
                                                name="url"
                                                onChange={(e) => this.handleChange(e)}
                                                value={socialMediaData.url ? socialMediaData.url : ''}
                                                required
                                                maxLength="200"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={2}> <FormattedMessage id="SocialMediaDetails.Notes" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                onChange={(e) => this.handleChange(e)}
                                                value={socialMediaData.notes ? socialMediaData.notes : ''}
                                                maxLength="1000"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(SocialMediaDetails)