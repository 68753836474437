import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';

class ComponentCreation extends Component {
  
    constructor (props) {
      super(props);
      this.state = {
        availableFormats: [ 'IMAGE', 'VIDEO', 'DOCUMENT', 'TEXT' ],
        buttonsAvailableTypes: [ 'QUICK_REPLY', 'CALL_TO_ACTION' ],
        buttonsTypeofActionList: [ 'PHONE_NUMBER', 'URL' ],
        buttonsType: null,
        mimeType: [ 'audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg, image/jpeg, image/png, video/mp4, video/3gp, image/webp, text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      };
    }

    handleButtonsType = (e, key) => {
        const { value } = e;
        const { buttonsType } = this.state;
        if(buttonsType !== value ) this.setState({ buttonsType: value }, () => this.props.toggleButtons());
    }

    addButton = (e) => {
        e.preventDefault();
        this.props.createNewButton(this.state.buttonsType);
    }

    handleButtonsText = (e, keyButtons, name) => {
        this.props.handleButtons(e.target ? e.target.value : e.value, keyButtons, name);
    }

    removeButton = (e, key) => {
        e.preventDefault();
        this.props.createNewButton(this.state.buttonsType, true, key);
    }

    dynamicFieldsChange = (e, part, key) => {
        this.props.handleInput(e, part, true, key);
    }

    render() {
        const { part, components, intl, mediaList, headerMedia, handleInput, handleMedia } = this.props;
        const { availableFormats, buttonsType, buttonsAvailableTypes, mimeType, buttonsTypeofActionList } = this.state;
        
        const formatOptions = availableFormats.map((format) => {
            return({
                value: format,
                label: intl.formatMessage({ id: `WhatsApp.${format}` })
            });
        });

        const buttonsTypeOptions = buttonsAvailableTypes.map((type) => {
            return({
                value: type,
                label: intl.formatMessage({ id: `WhatsApp.${type}` })
            });
        });

        const buttonsTypeofActionOptions = buttonsTypeofActionList.map((action) => {
            return({
                value: action,
                label: intl.formatMessage({ id: `WhatsApp.${action}` })
            });
        });
        
        const mediaOptions = mediaList.map((media) => {
            return({
                value: media.contentId,
                label: media.filename
            });
        });

        const currentComponent = components.find(({ type }) => type === part);

        const buttonsCurrentType = part === "BUTTONS" && currentComponent && currentComponent.buttons && currentComponent.buttons[0] ?
        currentComponent.buttons[0].type === "QUICK_REPLY" ? 'QUICK_REPLY' : 'CALL_TO_ACTION' : buttonsType;

        const buttonsTypeofAction = (k) => {
            return (part === "BUTTONS" && currentComponent && currentComponent.buttons && currentComponent.buttons[k] ?
            currentComponent.buttons[k].type !== "QUICK_REPLY" ? currentComponent.buttons[k].type : null : null);
        };

        return (
            <>
                {part === "HEADER" ? 
                    <Row className='mt-2' style={{ justifyContent: 'space-between' }}>
                        <Col className='col-6'>
                            <CustomSelect
                                options={formatOptions}
                                name="format"
                                value={currentComponent ? formatOptions.find(({ value }) => value === currentComponent.format) : ''}
                                onChange={e => handleInput({target:{value:e?.value, name: "format"}}, part)}
                                isClearable
                            />
                        </Col>
                        {currentComponent && currentComponent.format !== 'TEXT' ?
                            <Col>
                                <FormGroup row>
                                    <Col sm={9} >
                                        <div className="custom-file">
                                            <input
                                                id='media'
                                                required
                                                type="file"
                                                className="custom-file-input"
                                                accept={mimeType}
                                                onChange={handleMedia}
                                            />
                                            <label className="custom-file-label" for="customFile">
                                                {headerMedia ? headerMedia.name : <FormattedMessage id="generic.ChooseFile"/>}
                                            </label>
                                        </div>
                                    </Col>
                                </FormGroup>
                            </Col>
                        :''}
                    </Row>
                :''}
                {(part !== "HEADER" && part !== "BUTTONS") || currentComponent?.format === "TEXT" ?
                    <Row className='mt-2'>
                        <Col>
                            {currentComponent?.text && currentComponent?.text.includes('{{') && currentComponent?.text.includes('}}') && part !== "FOOTER" && part !== "BUTTONS" ?
                                <Row>
                                    {currentComponent?.text.split('{{')?.slice(1).map((text, key) =>
                                        <Col key={key} className='col-3'>
                                            <Input
                                                value={currentComponent.example  && currentComponent.example.body_text && currentComponent.example.body_text[0]
                                                && currentComponent.example.body_text[0][0] ? currentComponent.example.body_text[0][key] : null}
                                                placeholder={key + 1}
                                                type="text"
                                                onChange={e => this.dynamicFieldsChange(e , part, key)}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            :''}
                            
                            <Row className='mt-2'>
                                <Col>
                                    {part === "BODY" ?
                                        <Input
                                            max={'1024'}
                                            type="textarea"
                                            value={currentComponent?.text}
                                            name="text"
                                            onChange={e => handleInput(e, part)}
                                            style={{ height: '200px' }}
                                        />
                                    :
                                        <Input
                                            max={'60'}
                                            name="text"
                                            value={currentComponent?.text}
                                            onChange={e => handleInput(e, part)}
                                        />
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                :''}
                {part === "BUTTONS" ?
                    <>
                        <Row className='mt-2'>
                            <Col className='d-flex'>
                                <CustomSelect
                                    className="w-100"
                                    options={buttonsTypeOptions}
                                    value={buttonsCurrentType ? buttonsTypeOptions.find(({ value }) => value === buttonsCurrentType) : null}
                                    onChange={e => this.handleButtonsType(e, 'buttonsType')}
                                />
                                {buttonsCurrentType ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', marginLeft: '1rem' }}>
                                        <Button onClick={this.addButton} disabled={buttonsCurrentType === "QUICK_REPLY" ?
                                        currentComponent?.buttons.filter(({active}) => active).length === 3 : currentComponent?.buttons.filter(({active}) => active).length === 2}>
                                            <i className="fas fa-plus"/>
                                        </Button>
                                    </div>
                                :''}
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col>
                                {buttonsCurrentType === "QUICK_REPLY" ?
                                    <Row>
                                        {currentComponent?.buttons?.map((button, keyButtons) =>
                                            <Col className='col-4' key={keyButtons} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Input
                                                    type='text'
                                                    value={button.text}
                                                    onChange={e => this.handleButtonsText(e, keyButtons, 'text')}
                                                    maxLength='25'
                                                />
                                                <div style={{ position: 'absolute', cursor: 'pointer', right: '2rem' }} onClick={e => this.removeButton(e, keyButtons)}>
                                                    <i className="fas fa-times"/>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                : buttonsCurrentType === "CALL_TO_ACTION" ?
                                    <>
                                        {currentComponent?.buttons?.map((button, keyButtons) =>
                                            <Row key={keyButtons} className="mt-2">
                                                <Col>
                                                    <Row>
                                                        <Col className='col-4'>
                                                            <div>
                                                                <b><FormattedMessage id="WhatsApp.ButtonsTypeofAction"/></b>
                                                            </div>
                                                            <div>
                                                                <CustomSelect
                                                                    options={buttonsTypeofActionOptions}
                                                                    onChange={e => this.handleButtonsText(e, keyButtons, 'type')}
                                                                    isSearchable={false}
                                                                    value={buttonsTypeofAction(keyButtons) && buttonsTypeofActionOptions.find(({value}) => value === buttonsTypeofAction(keyButtons))}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div>
                                                                <b><FormattedMessage id="WhatsApp.ButtonsText"/></b>
                                                            </div>
                                                            <div>
                                                                <Input
                                                                    type='text'
                                                                    value={button.text}
                                                                    onChange={e => this.handleButtonsText(e, keyButtons, "text")}
                                                                    maxLength='25'
                                                                />
                                                            </div>
                                                        </Col>
                                                        {button.type === "URL" ?
                                                            <Col>
                                                                <div>
                                                                    <b><FormattedMessage id="WhatsApp.URL"/></b>
                                                                </div>
                                                                <div>
                                                                    <Input
                                                                        type='text'
                                                                        value={button.url}
                                                                        onChange={e => this.handleButtonsText(e, keyButtons, "url")}
                                                                        maxLength='2000'
                                                                    />
                                                                </div>
                                                            </Col>
                                                        :
                                                            <Col>
                                                                <div>
                                                                    <b><FormattedMessage id="WhatsApp.ButtonsPhone"/></b>
                                                                </div>
                                                                <div>
                                                                    <Input
                                                                        type='text'
                                                                        value={button.phone_number}
                                                                        onChange={e => this.handleButtonsText(e, keyButtons, "phone_number")}
                                                                        maxLength='25'
                                                                    />
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>
                                                <Col className='col-1 p-0 cursor-pointer' style={{ display: 'flex', alignItems: 'end', fontSize: '1.2em', justifyContent: 'center' }}>
                                                    <div style={{ paddingBottom: '0.5rem' }} onClick={e => this.removeButton(e, keyButtons)}>
                                                        <i className="fas fa-times"/>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                :''}
                            </Col>
                        </Row>
                    </>
                :''}
            </>
        )
    }
}

export default injectIntl(ComponentCreation);