import React, { Component } from 'react'
import { StyledCard, KebabMenu } from '../../Base/CommonUIComponents'
import { Col, Row, Card, CardBody } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import TwilioServiceDetails from './TwilioServiceDetails';

class TwilioServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            pageIndex: 0,
            pageSize: 10,
            selectedService: null
        };
    }

    componentWillReceiveProps() {
        this.toggleModal();
    }

    toggleModal = (id) => {
        this.setState({ selectedService: id });
    }

    render() {
        const { page, services } = this.props;
        const { block, error, selectedService } = this.state;

        return (
            <>
                {!selectedService ?
                        <StyledCard block={block} error={error}>
                            < Row className="mb-4 py-1" >
                                <Col>
                                    <h5> <i className={page?.icon} /> {page?.name} </h5>
                                </Col>
                            </Row >
                            {services && services.services && services.services.length > 0 ?
                                <div>
                                    {services.services.map((service, key) =>
                                        <Card key={key} className="border-0 shadow mt-2">
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <div>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="TwilioMessaging.UserName" />
                                                            </div>
                                                            <div className='d-flex align-items-center'>
                                                                <b>{service.friendly_name}</b>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="TwilioMessaging.SID" />
                                                            </div>
                                                            <div>
                                                                {service.sid}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="TwilioMessaging.CreatedAt" />
                                                            </div>
                                                            <div>
                                                                {moment(service.date_created).format('DD MMMM YYYY, hh:mm a')}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className='col-2 d-flex align-items-center justify-content-end'>
                                                        <KebabMenu
                                                            extraFields={[
                                                                { text: "TwilioMessaging.AssociatedPhones", function: _ => this.toggleModal(service), icon: "fas fa-phone mr-2" }
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )}
                                </div>
                                : ''
                            }
                        </StyledCard>
                        :
                    <TwilioServiceDetails
                        page={page}
                        block={block}
                        error={error}
                        toggleModal={this.toggleModal}
                        selectedService={selectedService}
                    />}
            </>
        )
    }
}
export default injectIntl(TwilioServices);