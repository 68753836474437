import React, { Component } from 'react'
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import { Badge, Button, ButtonGroup, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import CustomToolTip from '../Base/CustomToolTip';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import SalesActionsPopOver from './SalesActionsPopOver';

class SalesDealList extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            modal: false,
            pipelineId: null,
            busStartDate: null,
            busEndDate: null,
            staffIds: [],
            negStartDate: null,
            negEndDate: null,
            salesSourceId: null,
            pageSize: 25,
            pageIndex: 0,
        };
    }

    componentDidMount(){
        this.getDealList();
    }

    getDealList = () => {
        const { pipelineId, busStartDate, busEndDate, staffIds, negStartDate, negEndDate, salesSourceId, pageSize, pageIndex } = this.state;
        let qs = `?pageIndex=${pageIndex}&pageSize=${pageSize}&`;

        if(pipelineId)
            qs += `pipelineId=${pipelineId}&`;
        if(busStartDate)
            qs += `busStartDate=${busStartDate}&`;
        if(busEndDate)
            qs += `busEndDate=${busEndDate}&`;
        if(staffIds && staffIds.length > 0)
            staffIds.forEach(s => {
                qs += `staffIds=${s}&`;
            });
        if(negStartDate)
            qs += `negStartDate=${negStartDate}&`;
        if(negEndDate)
            qs += `negEndDate=${negEndDate}&`;
        if(salesSourceId)
            qs += `salesSourceId=${salesSourceId}&`;

        this.props.getDealList(qs, (errorMessage) => {
            this.setState({ block: false, error: errorMessage })
        });
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    handleCombo = (combo, name) => {
        this.setState({ [name]: combo?.value });
    }

    handleBusDateChanges = ({ startDate, endDate }) => {
        this.setState({ busStartDate: startDate, busEndDate: endDate });
    }

    searchDeals = (e) => {
        e.preventDefault();
        this.setState({ block: true }, () => this.getDealList());
    }

    handleMultiCombo = (combo, name) => {
        this.setState({ [name]: combo ? combo?.map(cmb => cmb.value) : [] });
    }

    handlePageIndex = (p) => {
        const { pageIndex } = this.state;
        this.setState({ pageIndex: parseInt(pageIndex + p), block: true }, () => this.getDealList());
    }

    changePageSize = (evt) => {
        const { value } = evt.target;
        const { pageSize } = this.state;

        if (pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getDealList());
        }
    }

    render() {
        const { block, error, modal, pipelineId, busStartDate, busEndDate, staffIds, negStartDate, negEndDate, salesSourceId, pageSize, pageIndex } = this.state;
        const { icon, salesUsers, salesPipelines, salesSources, intl, selectDealDetail, getDeals, dealList } = this.props;
        
        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    {modal ?
                        <SalesDealModal
                            modal={true}
                            salesUsers={salesUsers}
                            toggleModal={this.toggleModal}
                            salesStages={salesPipelines.flatMap(({stages}) => stages)}
                            salesPipelines={salesPipelines}
                            editableSalesPipeline={true}
                            salesSources={salesSources}
                            getDeals={this.getDealList}
                        />
                    :''}
                    <div className='mb-4'>
                        <h4 className='m-0'>
                            <i className={`${icon} mr-2`} />
                            <FormattedMessage id="SalesProcess.SalesDealList" />
                        </h4>
                    </div>
                    <Row>
                        <Col className='col-2'>
                            <div className='title-sm'>
                                <i className={`fas fa-sliders-h mr-2`}/>
                                <FormattedMessage id="SalesProcess.SalesPipelines"/>
                            </div>
                            <div>
                                <CustomSelect
                                    options={salesPipelines}
                                    required={true}
                                    isClearable={true}
                                    isMulti={false}
                                    value={salesPipelines?.filter(({ value }) => value === pipelineId) || ''}
                                    isSearchable={true}
                                    onChange={e => this.handleCombo(e, 'pipelineId')}
                                    placeholder={intl.formatMessage({ id: "SalesProcess.SalesPipelines" })}
                                />
                            </div>
                        </Col>
                        <Col className='col-2'>
                            <div className='title-sm'>
                                <i className={`fas fa-code-branch mr-2`}/>
                                <FormattedMessage id="SalesProcess.SalesSources"/>
                            </div>
                            <div>
                                <CustomSelect
                                    options={salesSources}
                                    required={true}
                                    isClearable={true}
                                    isMulti={false}
                                    value={salesSources?.filter(({ value }) => value === salesSourceId) || ''}
                                    isSearchable={true}
                                    onChange={e => this.handleCombo(e, 'salesSourceId')}
                                    placeholder={intl.formatMessage({ id: "SalesProcess.SalesSources" })}
                                />
                            </div>
                        </Col>
                        <Col className='col-3'>
                            <div className='title-sm'>
                                <i className={`fas fa-users mr-2`}/>
                                <FormattedMessage id="SalesProcess.Staff"/>
                            </div>
                            <div>
                                <CustomSelect
                                    options={salesUsers}
                                    required={true}
                                    isClearable={true}
                                    isMulti={true}
                                    value={salesUsers?.filter(({value}) => staffIds.includes(value))}
                                    isSearchable={true}
                                    onChange={e => this.handleMultiCombo(e, 'staffIds')}
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                />
                            </div>
                        </Col>
                        <Col className='col-2'>
                            <div className='title-sm'>
                                <i className="far fa-calendar-alt mr-2"/>
                                <FormattedMessage id="SalesProcess.NegotiationDates"/>
                            </div>
                            <div>
                                <DateRangePicker
                                    startDate={negStartDate}
                                    startDateId="negStartDateId"
                                    endDate={negEndDate}
                                    endDateId="negEndDateId"
                                    isOutsideRange={_ => false}
                                    onDatesChange={this.handleBusDateChanges}
                                    focusedInput={this.state.focusedInput2}
                                    showClearDates={true}
                                    onFocusChange={focusedInput => this.setState({ focusedInput2: focusedInput })}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                />
                            </div>
                        </Col>
                        <Col className='col-2'>
                            <div className='title-sm'>
                                <i className="far fa-calendar-alt mr-2"/>
                                <FormattedMessage id="SalesProcess.BusinessDates"/>
                            </div>
                            <div>
                                <DateRangePicker
                                    startDate={busStartDate}
                                    startDateId="busStartDateId"
                                    endDate={busEndDate}
                                    endDateId="busEndDateId"
                                    isOutsideRange={_ => false}
                                    onDatesChange={this.handleBusDateChanges}
                                    focusedInput={this.state.focusedInput}
                                    showClearDates={true}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                                />
                            </div>
                        </Col>
                        <Col className='col-1 text-right'>
                            <Button className="btn-sm btn-host mr-2" onClick={this.toggleModal}>
                                <i className="fas fa-plus" />
                            </Button>
                            <Button className="btn-sm btn-host" onClick={this.searchDeals}>
                                <i className="fas fa-search"/>
                            </Button>
                        </Col>
                    </Row>
                    {dealList ?
                        <div className='mt-4'>
                            <div className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white' style={{ borderRadius: '4px', gap: '1%' }}>
                                <div style={{ width: '15%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.Deal`}/>
                                </div>
                                <div style={{ width: '9%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.SalesPipeline`}/>
                                </div>
                                <div style={{ width: '9%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.SalesSource`}/>
                                </div>
                                <div style={{ width: '9%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.Staff`}/>
                                </div>
                                <div style={{ width: '10%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.RelatedHotels`}/>
                                </div>
                                <div style={{ width: '6%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.EffectiveValue`}/>
                                </div>
                                <div style={{ width: '12%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.NegotiationDates`}/>
                                </div>
                                <div style={{ width: '12%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.BusinessDates`}/>
                                </div>
                                <div style={{ width: '3%' }} className='title-sm'>
                                    <FormattedMessage id={`SalesProcess.ActionsStatus`}/>
                                </div>
                                <div style={{ width: '6%' }} className='title-sm text-right'>
                                    <FormattedMessage id={`SalesProcess.DealStatus`}/>
                                </div>
                            </div>
                            <div className='mt-2'>
                                {dealList.map((deal, key) => {
                                    const pipeline = salesPipelines?.find(({value}) => value === deal.salesPipelineStage?.salesPipelineId);
                                    const source = salesSources?.find(({value}) => value === deal.salesSourceId);

                                    return (
                                        <div onClick={_ => selectDealDetail(deal.id, true)} className='d-flex align-items-center justify-content-between shadow p-3 mb-2 bg-white cursor-pointer' key={key} style={{ borderRadius: '4px', gap: '1%' }}>
                                            <div style={{ width: '15%' }}>
                                                <div className='text-truncate'>
                                                    <b id={`dealName-${key}`}>{deal.name}</b>
                                                    <CustomToolTip placementPrefix="coolTooltip bs-tooltip" placement='right' target={`dealName-${key}`}>
                                                        <div>
                                                            <b>{deal.name}</b>
                                                        </div>
                                                        <div>
                                                            {deal.summary}
                                                        </div>
                                                    </CustomToolTip>
                                                </div>
                                                {deal.customerId ?
                                                    <div style={{ fontSize: '0.8em' }} className='text-muted hover-underline text-truncate'>
                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                            {deal.customer.name}
                                                        </Link>
                                                    </div>
                                                :''}
                                            </div>
                                            <div style={{ width: '9%' }}>
                                                <div className='text-truncate' style={{ fontSize: '0.9em' }}>
                                                    {pipeline ?
                                                        <>
                                                            <span id={`pipeline-${key}`}>{pipeline.label}</span>
                                                            <CustomToolTip placementPrefix="coolTooltip bs-tooltip" placement='right' target={`pipeline-${key}`}>
                                                                <div>
                                                                    <b>{pipeline.label}</b>
                                                                </div>
                                                                <div className='my-1 px-2 h-100 d-flex w-100' style={{ minWidth: '80px' }}>
                                                                    {pipeline?.stages ?
                                                                        pipeline?.stages.map((stage, key) =>{
                                                                            const selectedStage = stage.value === deal.salesPipelineStageId;
                                                                            return (
                                                                                <div key={key} className='h-100' style={{ width: '100%' }}>
                                                                                    <div className='text-center pipeline smallPipeline'>
                                                                                        <div className={`stagearrow top small smallPipeline noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                        <div className={`stagearrow bottom small noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        )
                                                                    :''}
                                                                </div>
                                                            </CustomToolTip>
                                                        </>
                                                    :'-'}
                                                </div>
                                            </div>
                                            <div style={{ width: '9%' }}>
                                                <div className='text-truncate' style={{ fontSize: '0.9em' }}>
                                                    {source ?
                                                        <span>
                                                            {source.label}
                                                        </span>
                                                    :'-'}
                                                </div>
                                            </div>
                                            <div style={{ width: '9%' }}>
                                                {deal.salesDealUser ?
                                                    <div style={{ fontSize: '0.9em', maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                        {deal.salesDealUser.map((user, k) => {
                                                            const u = salesUsers?.find(({value}) => value === user.userId);
                                                            return (
                                                                u ?
                                                                    <div key={k}>
                                                                        <div className={k ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                            {`${u.firstName} ${u.lastName}`}
                                                                        </div>
                                                                    </div>
                                                                :''
                                                            )}
                                                        )}
                                                    </div>
                                                :'-'}
                                            </div>
                                            <div style={{ width: '10%' }}>
                                                {deal.hotels ?
                                                    <div style={{ fontSize: '0.9em', maxHeight: '22px' }} className='overflow-auto veryCoolScrollBar'>
                                                        {deal.hotels.map((hotel, key) => 
                                                            <div key={key} className={key ? 'mt-1 text-truncate' : 'text-truncate'}>
                                                                {hotel.name}
                                                            </div>
                                                        )}
                                                    </div>
                                                :'-'}
                                            </div>
                                            <div style={{ width: '6%' }}>
                                                <div style={{ fontSize: '0.9em' }} className='text-truncate'>
                                                    {deal.effectiveValue ?
                                                        <FormatAmountNumber value={deal.effectiveValue}/>
                                                    :'-'}
                                                </div>
                                            </div>
                                            <div style={{ width: '12%' }}>
                                                <div style={{ fontSize: '0.9em' }} className='text-truncate' title={`${moment(deal.negotiationStartDate).format('YYYY/MM/DD')}${deal.negotiationEndDate ? ` - ${moment(deal.negotiationStartDate).format('YYYY/MM/DD')}` : ''}`}>
                                                    {deal.negotiationStartDate ?
                                                        <span>
                                                            {moment(deal.negotiationStartDate).format('YYYY/MM/DD')}
                                                        </span>
                                                    :'-'}

                                                    {deal.negotiationEndDate ?
                                                        <>
                                                            <span className='mx-1'>-</span>
                                                            <span>
                                                                {moment(deal.negotiationStartDate).format('YYYY/MM/DD')}
                                                            </span>
                                                        </>
                                                    :''}
                                                </div>
                                            </div>
                                            <div style={{ width: '12%' }}>
                                                <div style={{ fontSize: '0.9em' }} className='text-truncate' title={`${moment(deal.negotiationStartDate).format('YYYY/MM/DD')}${deal.negotiationEndDate ? ` - ${moment(deal.negotiationStartDate).format('YYYY/MM/DD')}` : ''}`}>
                                                    {deal.negotiationStartDate ?
                                                        <span>
                                                            {moment(deal.businessStartDate).format('YYYY/MM/DD')}
                                                        </span>
                                                    :'-'}
                                                    {deal.negotiationEndDate ?
                                                        <>
                                                            <span className='mx-1'>-</span>
                                                            <span>
                                                                {moment(deal.businessStartDate).format('YYYY/MM/DD')}
                                                            </span>
                                                        </>
                                                    :''}
                                                </div>
                                            </div>
                                            <div style={{ width: '3%' }}>
                                                <SalesActionsPopOver
                                                    id={`SalesAction-${key}`}
                                                    salesUsers={salesUsers}
                                                    placement={'left'}
                                                    deal={deal}
                                                    getDeals={getDeals}
                                                    selectedPipeline={deal.salesPipelineId}
                                                    small={true}
                                                />
                                            </div>
                                            <div className='text-right' style={{ width: '6%' }}>
                                                <Badge id={`dealStatus-${key}`} style={{ fontSize: '0.7em' }} color={(deal.status === "WaitingHotel" || deal.status === "WaitingClient") ? "primary" : deal.status === "Finished" ? "success" : "danger"} className="py-1 px-2">
                                                    <FormattedMessage id={`SalesProcess.${deal.status}`}/>
                                                </Badge>
                                                {deal.statusReason ?
                                                    <CustomToolTip placementPrefix="coolTooltip bs-tooltip" placement='left' target={`dealStatus-${key}`}>
                                                        {deal.statusReason}
                                                    </CustomToolTip>
                                                :''}
                                            </div>
                                        </div>
                                    )}
                                )}
                                <Row className="mt-3">
                                    <Col>
                                        <ButtonGroup onClick={this.changePageSize}>
                                            <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                            <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                            <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                            <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                        </ButtonGroup>
                                    </Col>

                                    <Col className="text-right">
                                        <span>
                                            <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.handlePageIndex(-1)} disabled={pageIndex === 0}>
                                                <i className="fas fa-angle-left" />
                                            </Button>
                                            <span className="mr-2">
                                                <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                            </span>
                                            <Button className="btn btn-host btn-sm" onClick={_ => this.handlePageIndex(1)} disabled={dealList && dealList.length < pageSize}>
                                                <i className="fas fa-angle-right" />
                                            </Button>
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    :''}
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesDealList);