import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, UncontrolledCollapse, Badge } from 'reactstrap';
import { handleNotification } from '../../../Base/Notification';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import { getPMSReservationStatus } from '../../../Base/ReferenceDataFunctions';
import { CustomTable } from '../../../Base/CustomTable';
import { ErrorAlert } from "../../../Base/ErrorAlert";
import CustomSelect from "../../../Base/CustomSelect";
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../../Base/API';
import BlockUi from 'react-block-ui';
import moment from 'moment';

import { Bar } from 'react-chartjs-2';
//import { Chart } from 'chart.js';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReservationDetails from './ReservationDetails';
import { FormatAmountNumber, SelectHotelWithLoadedData } from '../../../Base/CommonUIComponents';
import { CheckAuthorization } from '../../../Base/Authorization';

//import { CategoryScale } from 'chart.js'; 
//Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

export function getTableColumns(intl) {
    const getNrNights = (row) => {
        if (row.checkIn && row.checkOut) {
            const ci = moment(row.checkIn).format('YYYY-MM-DD');
            const co = moment(row.checkOut).format('YYYY-MM-DD');

            return moment(co).diff(moment(ci), 'days')
        }
    }


    return [
        {
            dataField: 'hotelName',
            text: intl.formatMessage({ id: "generic.Hotel" }),
            formatter: (cell, row) => <span> {cell} {global.showComplexes && row.complexCode ? ` - ${row.complexCode}` : ''} </span>,
            headerStyle: () => {
                return { width: "200px" };
            }
        },
        {
            dataField: 'resIdValue',
            text: intl.formatMessage({ id: "ReservationList.ReservationId" })
        },
        {
            dataField: 'pmsId',
            text: "Pms Id",
            hidden: true
        },
        {
            dataField: 'source',
            text: intl.formatMessage({ id: "ReservationList.Source" })
        },
        {
            dataField: 'channelCode',
            text: intl.formatMessage({ id: "ReservationList.Channel" }),
            hidden: true,
        },
        {
            dataField: 'distChannelCode',
            text: intl.formatMessage({ id: "ReservationList.Market" }),
            hidden: true,
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'channelRoomTypeCodeOriginal',
            text: intl.formatMessage({ id: "ReservationList.ChannelRoomTypeCodeOriginal" }),
            hidden: true,
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'channelRoomTypeCode',
            text: intl.formatMessage({ id: "ReservationList.ChannelRoomTypeCode" }),
            hidden: true,
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'roomName',
            text: intl.formatMessage({ id: "ReservationList.RoomNumber" }),
            hidden: true,
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'resIdDate',
            text: intl.formatMessage({ id: "ReservationList.ReservationDate" }),
            formatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD') : '',
            csvFormatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD') : ''
        },
        {
            dataField: 'checkIn',
            text: intl.formatMessage({ id: "ReferenceDataFunctions.CheckIn" }),
            formatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD') : '',
            csvFormatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD') : ''
        },
        {
            dataField: 'checkOut',
            text: intl.formatMessage({ id: "ReferenceDataFunctions.CheckOut" }),
            formatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD') : '',
            csvFormatter: (cell, row) => cell ? moment(cell).format('YYYY-MM-DD') : ''
        },
        {
            dataField: 'cancelDate',
            text: intl.formatMessage({ id: "ReservationList.CancelDate" }),
            formatter: (cell, row) => cell && cell !== '0001-01-01T00:00:00' ? moment(cell).format('YYYY-MM-DD') : '',
            csvFormatter: (cell, row) => cell && cell !== '0001-01-01T00:00:00' ? moment(cell).format('YYYY-MM-DD') : '',
            hidden: true
        },
        {
            dataField: 'nights',
            text: intl.formatMessage({ id: "ReservationList.Nights" }),
            formatter: (cell, row) => getNrNights(row),
            csvFormatter: (cell, row) => getNrNights(row)
        },
        {
            dataField: 'priceListCode',
            text: intl.formatMessage({ id: "ReservationList.PriceList" }),
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'ratePlanCode',
            text: intl.formatMessage({ id: "ReservationList.Package" }),
            hidden: true,
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'guestCount',
            text: intl.formatMessage({ id: "ReservationList.GuestCount" }),
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'adults',
            text: intl.formatMessage({ id: "ReservationList.Adults" }),
            hidden: true,
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'children',
            text: intl.formatMessage({ id: "ReservationList.Children" }),
            hidden: true,
            csvFormatter: (cell, row) => cell || ''
        },
        {
            dataField: 'amountCommission',
            text: intl.formatMessage({ id: "ReservationList.AmountCommission" }),
            formatter: (cell, row) => cell ? <FormatAmountNumber value={cell} currency={row.hotelCurrencyCode} /> : '',
            csvFormatter: (cell, row) => cell ? intl.formatNumber(cell, { maximumFractionDigits: 2, style: "currency", currency: row.hotelCurrencyCode || global.hotelCurrency }) : ''
        },
        {
            dataField: 'amountAfterTax',
            text: intl.formatMessage({ id: "ReservationList.AmountAfterTax" }),
            formatter: (cell, row) => cell ? <FormatAmountNumber value={cell} currency={row.hotelCurrencyCode} /> : '',
            csvFormatter: (cell, row) => cell ? intl.formatNumber(cell, { maximumFractionDigits: 2, style: "currency", currency: row.hotelCurrencyCode || global.hotelCurrency }) : ''
        },
        {
            dataField: 'statusNumber',
            text: intl.formatMessage({ id: "ReservationList.Status" }),
            formatter: (cell, row) => {
                const status = getPMSReservationStatus(intl).find(el => el.value == cell);

                if (status) {
                    return <Badge className="py-1 w-100" color={status.color} >
                        {status.label}
                    </Badge>
                }
                else {
                    return <Badge className="py-1 w-100" color="secondary" >
                        {row.status}
                    </Badge>
                }
            },
            csvFormatter: (cell, row) => {
                const status = getPMSReservationStatus(intl).find(el => el.value == cell);

                return status.label;
            } 
        }
    ];
}

class ProfileReservations extends Component {
    ProfileReservations = false;
    constructor(props) {
        super(props);

        this.state = {
            blockSummary: false,
            blockDetails: false,
            collapse: false,
            reservationList: [],
            reservationSummary: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            pastReservations: 0,
            futureReservations: 0,
            totalRoomNights: 0,
            avgStays: 0,
            barChart: null,
            isExpanded: false,
            stayFromDate: null,
            stayToDate: null,
            columns: getTableColumns(this.props.intl),
            hotelId: this.props.fixedHotelFilter ?? null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.profileId) {
            this.doSearch();
        }

        this.getResColumns();
    }

    doSearch = () => {
        const { pageSize, pageIndex } = this.state;

        this.getProfileReservationsSummary()
        this.getProfileReservations(pageSize, pageIndex);
    }

    getProfileReservations = (pageSize, pageIndex, cb) => {
        this.setState({ blockDetails: true });

        var params = '';
        if (this.props.startDate) params += `&startDate=${moment(this.props.startDate).format('YYYY-MM-DD')}`;
        if (this.props.endDate) params += `&endDate=${moment(this.props.endDate).format('YYYY-MM-DD')}`;
        if (this.state.stayFromDate) params += `&stayFromDate=${moment(this.state.stayFromDate).format('YYYY-MM-DD')}`;
        if (this.state.stayToDate) params += `&stayToDate=${moment(this.state.stayToDate).format('YYYY-MM-DD')}`;
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockDetails: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({ reservationList: data.response, totalItems: data.totalItems }, _ => {
                        if (cb) {
                            cb();
                            this.getProfileReservations(this.state.pageSize, this.state.pageIndex);
                        }
                    });
                }

                this.setState({ blockDetails: false });
            }
        }, `/api/gms/Reservation/v1/reservations/profile/${this.props.profileId}?pageSize=${pageSize}&pageIndex=${pageIndex}` + params)
    }

    getProfileReservationsSummary = () => {
        this.setState({ blockSummary: true });

        var params = '';
        if (this.props.startDate) params += `startDate=${moment(this.props.startDate).format('YYYY-MM-DD')}&`;
        if (this.props.endDate) params += `endDate=${moment(this.props.endDate).format('YYYY-MM-DD')}&`;
        if (this.state.stayFromDate) params += `stayFromDate=${moment(this.state.stayFromDate).format('YYYY-MM-DD')}&`;
        if (this.state.stayToDate) params += `stayToDate=${moment(this.state.stayToDate).format('YYYY-MM-DD')}&`;
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockSummary: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({
                        reservationSummary: data.response
                    }, () => this.calculateData(data.response))
                }

                this.setState({ blockSummary: false });
            }
        }, `/api/gms/Reservation/v1/reservations/profile/${this.props.profileId}/summary?` + params)
    }

    calculateData = (reservationSummary) => {
        //NSC = no show or canceled
        var pastReservations = 0, pastReservationsNSC = 0, futureReservations = 0, futureReservationsNSC = 0, totalRoomNights = 0, totalCountableReservations = 0;

        //Line chart dates
        //var dates = this.getDates(reservationSummary);

        var barChart = {
            labels: [],
            datasets: [
                { label: this.props.intl.formatMessage({ id: "ProfileReservations.RoomNights" }), type: "line", yAxisID: 'roomNights', data: [], fill: false, borderColor: "#42D1A5", backgroundColor: "#42D1A5", borderWidth: "1" },
                { label: this.props.intl.formatMessage({ id: "ProfileReservations.Reservations" }), yAxisID: 'reservations', data: [], fill: false, borderColor: "#0867fa", backgroundColor: "#0867fa", borderWidth: "1" },
                { label: this.props.intl.formatMessage({ id: "ProfileReservations.NoShowOrCanceled" }), yAxisID: 'reservations', data: [], fill: false, borderColor: "#F16D84", backgroundColor: "#F16D84", borderWidth: "1" },
            ]
        }

        //Chart dates
        barChart.labels = reservationSummary && reservationSummary.reduce((acc, cur) => {
            cur.dataByDate && cur.dataByDate.forEach(date => {
                if (!acc.some(el => el === moment(date.date).format('YYYY-MM-DD'))) {
                    acc.push(moment(date.date).format('YYYY-MM-DD'));
                }
            })

            return acc;
        }, []).sort((a, b) => moment(a) - moment(b));


        reservationSummary && reservationSummary.forEach(hotel => {
            futureReservations += hotel.futureReservations;
            futureReservationsNSC += hotel.canceledFutureReservations

            hotel.dataByDate && hotel.dataByDate.forEach(date => {
                const index = barChart.labels.findIndex(el => el === moment(date.date).format('YYYY-MM-DD'));

                date.dataByChannel && date.dataByChannel.forEach(channel => {
                    channel.dataByStatus && channel.dataByStatus.forEach(reservations => {

                        if (reservations.pmsStatus != 99) {
                            if (reservations.pmsStatus != 6 && reservations.pmsStatus != 7) {
                                //Cards
                                totalRoomNights += reservations.totalRoomNights;
                                totalCountableReservations += reservations.totalReservations;

                                pastReservations += reservations.totalReservations


                                //Bar Chart
                                barChart.datasets[0].data[index] = barChart.datasets[0].data[index] ? barChart.datasets[0].data[index] + reservations.totalRoomNights : 0 + reservations.totalRoomNights;
                                barChart.datasets[1].data[index] = barChart.datasets[1].data[index] ? barChart.datasets[1].data[index] + reservations.totalReservations : 0 + reservations.totalReservations;
                            }
                            else {
                                //Cards
                                pastReservationsNSC += reservations.totalReservations                                 


                                //Bar Chart
                                if (!barChart.datasets[0].data[index]) barChart.datasets[0].data[index] = 0;
                                barChart.datasets[2].data[index] = barChart.datasets[2].data[index] ? barChart.datasets[2].data[index] + reservations.totalReservations : 0 + reservations.totalReservations;
                            }
                        }
                    })
                })
            })
        })

        var avgStays = totalRoomNights / totalCountableReservations;


        this.setState({
            pastReservations, pastReservationsNSC, futureReservations, futureReservationsNSC, totalRoomNights, avgStays, barChart
        })
    }

    getDates = (reservationSummary) => {
        let dates = [];

        if (this.props.startDate && this.props.endDate) {
            dates.push(moment(this.props.startDate).format('YYYY-MM-DD'));
            var currDate = moment(this.props.startDate);

            while (currDate.add(1, 'days').diff(this.props.endDate) < 0) {
                dates.push(moment(currDate).format('YYYY-MM-DD'));
            }
        }
        else if (reservationSummary) {
            reservationSummary
                .map(el => (el.dataByDate.map(a => a.date))).flat().sort((a, b) => { return moment(a) - moment(b) })
                .forEach((data) => {
                    const date = moment(data).format('YYYY-MM-DD');

                    if (!dates.some(el => el === date)) {
                        dates.push(date);
                    }
                })
        }

        return dates;
    }

    getResColumns = () => {
        const hasML_ReservationCancel = global.modules && global.modules.some(m => m === 'ML_ReservationCancel');

        if (hasML_ReservationCancel) {
            const { columns } = this.state;

            const cancelProbColumns = {
                dataField: 'cancelationProbability',
                text: this.props.intl.formatMessage({ id: "ReservationList.CancelationProbability" }),
                formatter: (cell, row) => cell && (row.checkIn && moment(row.checkIn).diff(moment(), 'days') > -1) && (row.statusNumber !== 10) ? `${cell}%` : '',
                hidden: true,
                csvFormatter: (cell, row) => cell && (row.checkIn && moment(row.checkIn).diff(moment(), 'days') > -1) && (row.statusNumber !== 10) ? `${cell}%` : ''
            }

            columns.splice(columns.length - 1, 0, cancelProbColumns);
        }
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getProfileReservations(this.state.pageSize, this.state.pageIndex) });
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => { this.getProfileReservations(this.state.pageSize, this.state.pageIndex); this.getProfileReservationsSummary() })
    }

    handleCustomExportClick = (cb) => {
        const maxPageSize = 1000;
        const startPageIndex = 0;
        this.setState({ block: true }, _ => this.getProfileReservations(maxPageSize, startPageIndex, cb));
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    toggleExpanded = () => {
        this.setState(prevState => ({
            isExpanded: !prevState.isExpanded
        }))
    }

    toggleResModal = (name, selected) => {
        this.setState(prevState => ({
            [name]: !prevState[name],
            selectedReservation: selected
        }))
    }

    onColumnToggle = (field) => {
        const { columns } = this.state;
        const index = columns.findIndex(el => el.dataField === field);

        if (index !== -1) {
            columns[index].hidden = !columns[index].hidden;
        }

        this.setState({ columns });
    }

    render() {
        const { error, blockSummary, blockDetails, isExpanded, resModal, stayFromDate, stayToDate, columns } = this.state;
        const { startDate, endDate, handleDates, hotels, canEditProfile } = this.props;

        const hasManualReservations = CheckAuthorization("manualReservation:edit", null) && global.modules && global.modules.some(m => m === 'ManualReservations');
        const canEditReservations = hasManualReservations && !this.props.profile.isMaster && this.props.profile.source !== "API";


        let optionsBar = {
            maintainAspectRatio: false,
            //interaction: { intersect: false, },
            plugins: { datalabels: { display: false } },
            maxBarThickness: 60,
            scales: {
                x: {
                    stacked: false,
                    grid: { display: true },
                },
                reservations: {
                    ticks: { precision: 0 },
                    beginAtZero: true,
                    min: 0,
                    position: 'left',
                    title: {
                        display: true,
                        text: `# ${this.props.intl.formatMessage({ id: "ProfileReservations.Reservations" })}`
                    }
                },
                roomNights: {
                    ticks: { precision: 0 },
                    beginAtZero: true,
                    min: 0,
                    position: 'right',
                    title: {
                        display: true,
                        text: `# ${this.props.intl.formatMessage({ id: "ProfileReservations.RoomNights" })}`
                    }
                }
            }
        }

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleResModal('resModal', row);
            }
        };

        return (
            <div>
                {resModal ?
                    <ReservationDetails
                        modal={resModal}
                        canEditReservations={canEditReservations}
                        toggleModal={() => this.toggleResModal('resModal')}
                        profile={this.props.profile}
                        hotels={this.props.hotels}
                        doSearch={this.doSearch}
                        selectedReservation={this.state.selectedReservation}
                        canEditProfile={canEditProfile}
                    />
                : ''}

                <ErrorAlert error={error} />
                <div className="mx-2 p-2">
                    <Row className="mb-3 align-items-center justify-content-end">
                        <Col className="col-3 mt-3">
                            <SelectHotelWithLoadedData
                                name='hotelId'
                                onChangeFunc={this.handleChangeSelect}
                                value={this.state.hotelId || ''}
                                options={hotels}
                            />
                        </Col>
                        <Col className="text-right mt-3">
                            <h5><b> <FormattedMessage id="ProfileDetails.Reservations" /></b></h5>
                        </Col>
                        <Col className="col-6 d-flex justify-content-end align-items-center">                                
                            <div className="mr-2">
                                <div className="text-muted" style={{ fontSize: '10px' }}>
                                    <FormattedMessage id="ProfileReservations.StayDate" />
                                </div>
                                <DateRangePicker
                                    startDate={stayFromDate}
                                    startDateId="stayFromDate"
                                    isOutsideRange={day => false}
                                    endDate={stayToDate}
                                    endDateId="stayToDate"
                                    onDatesChange={({ startDate, endDate }) => this.setState({ stayFromDate: startDate, stayToDate: endDate })}
                                    focusedInput={this.state.focusedInput2}
                                    onFocusChange={focusedInput => this.setState({ focusedInput2: focusedInput })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                /> 
                            </div>
                            <div className="mr-2">
                                <div className="text-muted" style={{ fontSize: '10px' }}>
                                    <FormattedMessage id="ProfileReservations.BookingDate" />
                                </div>
                                <DateRangePicker
                                    startDate={startDate}
                                    startDateId="bookFromDate"
                                    isOutsideRange={day => false}
                                    endDate={endDate}
                                    endDateId="bookToDate"
                                    onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate)}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                />
                            </div>

                            <div>
                                <Button className="btn btn-host ml-2 mt-3" onClick={this.doSearch}>
                                    <i className="icon-icon-search"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>


                    <BlockUi tag="div" blocking={blockSummary}>
                        <Row style={isExpanded ? { height: '625px' } : { height: '405px' }} className="mb-2">
                            {!isExpanded ?
                                <Col className="col-3">
                                    <Card className="mb-3 border-0 shadow">
                                        <CardBody className="p-3">
                                            <Row>
                                                <Col><h5 className="text-muted"><FormattedMessage id="ProfileReservations.PastReservations" /></h5></Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-right">
                                                    <h5> <b># {this.state.pastReservations}</b> {this.state.pastReservationsNSC ? <b className="color-light-red ml-3"># {this.state.pastReservationsNSC}</b> : ''}</h5>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3 border-0 shadow">
                                        <CardBody className="p-3">
                                            <Row>
                                                <Col><h5 className="text-muted"><FormattedMessage id="ProfileReservations.FutureReservations" /></h5> </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-right">
                                                    <h5> <b># {this.state.futureReservations}</b> {this.state.futureReservationsNSC ? <b className="color-light-red ml-3"># {this.state.futureReservationsNSC}</b> : ''}</h5>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card className="mb-3 border-0 shadow">
                                        <CardBody className="p-3">
                                            <Row>
                                                <Col><h5 className="text-muted"><FormattedMessage id="ProfileReservations.RoomNights" /></h5></Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-right"><h5><b># {this.state.totalRoomNights}</b></h5></Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card className="shadow border-0">
                                        <CardBody className="p-3">
                                            <Row>
                                                <Col><h5 className="text-muted"><FormattedMessage id="ProfileReservations.AvgStay" /></h5></Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-right"><h5><b># <FormattedNumber value={this.state.avgStays || 0} maximumFractionDigits={2} /></b></h5></Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                : ''}
                            <Col className={"h-100 " + (isExpanded ? '' : ' col-9')}>
                                <Card className="shadow border-0 display-flex justify-content-center" style={isExpanded ? { height: '600px' } : { height: '380px' }} body>
                                    <div style={{ position: 'absolute', top: '0', right: '8px' }}>
                                        <i className={"fas text-host pointer pt-2 " + (isExpanded ? ' fa-compress-alt' : ' fa-expand-alt')} onClick={this.toggleExpanded} />
                                    </div>
                                    {this.state.barChart ?
                                        <Bar data={this.state.barChart} options={optionsBar} />
                                    : ''}
                                </Card>
                            </Col>
                        </Row>
                    </BlockUi>

                    <BlockUi tag="div" blocking={blockDetails}>
                        <Row >
                            <Col>
                                <Card className="border-0 shadow">
                                    <CardBody>
                                        <Row id="toggler" className="pointer">
                                            <Col className="text-muted">
                                                <h5> <FormattedMessage id="ProfileReservations.ReservationDetails" /></h5>
                                            </Col>
                                            <Col className="text-right">
                                                <span>
                                                    <i className="fas fa-chevron-up mr-2"></i>
                                                </span>
                                            </Col>
                                        </Row>

                                        <UncontrolledCollapse toggler="#toggler" defaultOpen={true} className="mt-2">
                                            { canEditReservations &&
                                                <Button className="btn btn-sm btn-host float-right" onClick={() => this.toggleResModal('resModal')}>
                                                    <i className="fas fa-plus"></i>
                                                </Button>
                                            }
                                            <CustomTable
                                                keyField="roomStayId"
                                                data={this.state.reservationList ? this.state.reservationList : []}
                                                columns={columns}
                                                exportCSV={true}
                                                customExportCsv={true}
                                                handleCustomExportClick={this.handleCustomExportClick}
                                                customExportTooltip={<FormattedMessage id="TaskResultDetail.ExportCsvTooltip" values={{ elems: 1000 }} />}
                                                showTotal={true}
                                                remote={true}
                                                toggleList={{ show: true, width: '160px', onColumnToggle: this.onColumnToggle }}
                                                page={this.state.pageIndex + 1}
                                                sizePerPage={this.state.pageSize}
                                                onTableChange={this.handleTableChange}
                                                totalSize={this.state.totalItems}
                                                hideSizePerPage={this.state.reservationList && this.state.reservationList.length > 50 ? false : true}
                                                selectRow={selectRow}
                                            />
                                        </UncontrolledCollapse>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </BlockUi>
                </div>
            </div>
        );
    }
}
export default injectIntl(ProfileReservations)