
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Col, Row, Label, Input, UncontrolledTooltip } from 'reactstrap';
import {  postAPI } from "../Base/API";
import { StyledCard } from "../Base/CommonUIComponents";
import { handleNotification } from '../Base/Notification';
import { getRegularExpressionType } from "../Base/ReferenceDataFunctions";
import CustomSelect from '../Base/CustomSelect';

export class RegExDetail extends Component {

    constructor(props) {
        super(props);
        this.form= React.createRef();
        this.saveRule = this.saveRule.bind(this);
        this.state = {
            block: false,
            rule: {},
            textSample: null, 
            matchText: false,
            highlightText: null,
            transformText: null,
            hotel: null
        };
    }

    componentDidMount() {
        this.setState({ rule: this.props.location.rule ? this.props.location.rule : {} });
    }

    saveRule() {
        if (!this.form.current.reportValidity()) {
            return;
        }

        let payload = { request: this.state.rule };
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="RegExDetail.UpdateSucess" />, <FormattedMessage id="generic.success" />);
                this.setState({ rule: data.response[0] });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/regularExpressionRule', payload);
    }

    filterData(event) {
        event.preventDefault();

        try {
            let ignoreRes = event.target.value;
            let applyRes = "";
            let matchText = false;
            let replaceRes = "";

            const { regularExpressionTransform, regularExpression } = this.state.rule;

            if (regularExpressionTransform) {
                let rule = regularExpression;
                let changeTo = regularExpressionTransform;
                var flags = rule.replace(/.*\/([gimy]*)$/, '$1');
                var pattern = rule.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                var regex = new RegExp(pattern, flags);

                if (replaceRes && replaceRes.length > 0) {
                    replaceRes = replaceRes.replace(regex, changeTo);
                }
                else {
                    replaceRes = ignoreRes.replace(regex, changeTo);
                }
            }


            if (regularExpression) {
                let regexString = regularExpression;

                var flags = regexString.replace(/.*\/([gimy]*)$/, '$1');
                var pattern = regexString.replace(new RegExp('^/(.*?)/' + flags + '$'), '$1');
                var regex = new RegExp(pattern, flags);

                applyRes = ignoreRes.replace(regex, (match) => `<b className="color-green" id="match">${match}</b>`);

                let html = new DOMParser().parseFromString(applyRes, "text/html")

                if (html && html.body && html.body.firstChild && html.body.firstChild.id === "match") {
                    matchText = true;
                }
            }

            this.setState({
                textSample: event.target.value,
                applyRes: applyRes,
                matchText,
                replaceRes: replaceRes
            });

        }
        catch (e) {
            console.log(e);
        }

    }

    handleChange(evt) {
        const value = evt.target ? evt.target.value : null;
        const name = evt.target.name;

        this.setState({
            rule: {
                ...this.state.rule,
                [name]: value
            }
        });
    };

    handleChangeCombo = (name, el) => {
        const value = el ? el.value : null;
        this.setState({
            rule: {
                ...this.state.rule,
                [name]: value
            }
        });
    };


    render() {
        const { block, error, rule } = this.state;
        
        return (

            <StyledCard block={block} error={error} icon={'fas fa-list-ol'} title={'RuleDetail.title'}>
                <Row className="mb-1">
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={this.saveRule.bind(this)}>
                                <i className="fas fa-save" ></i>
                            </Button>
                    </Col>
                </Row>
                <form ref={this.form}>
                    <Row>
                        <Col>
                            <b>Rule Definiton</b>
                            <hr className="my-1" />
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Name</Label></Col>
                                <Col><Input type="text" name="code" required value={this.state.rule.code} onChange={(evt) => this.handleChange(evt)}> </Input> </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Description</Label></Col>
                                <Col><Input type="text" name="description" required value={this.state.rule.description} onChange={(evt) => this.handleChange(evt)}> </Input> </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Type </Label></Col>
                                <Col>
                                    <CustomSelect
                                        options={getRegularExpressionType()}
                                        required
                                        isClearable
                                        value={this.state.rule.regularExpressionType && getRegularExpressionType().find(el => el.value === this.state.rule.regularExpressionType)}
                                        onChange={this.handleChangeCombo.bind(this, 'regularExpressionType')}
                                        isSearchable
                                        placeholder={''}
                                    />
                                </Col>
                            </Row>
                       
                            <Row className="mb-2">
                                <Col className="col-3"><Label> Regular Expression </Label></Col>
                                <Col><Input type="textarea" name="regularExpression" required value={this.state.rule.regularExpression || ''} onChange={(evt) => this.handleChange(evt)}>  </Input> </Col>
                            </Row>

                            {this.state.rule.regularExpressionType && this.state.rule.regularExpressionType === 'Transform' ?
                                <Row className="mb-2">
                                    <Col className="col-3"><Label> Transform Regular Expression </Label></Col>
                                    <Col><Input type="textarea" name="regularExpressionTransform" required value={this.state.rule.regularExpressionTransform || ''} onChange={(evt) => this.handleChange(evt)}>  </Input> </Col>
                                </Row>
                            : ''}
                        </Col>

                        <Col>
                            <b> <FormattedMessage id="RuleDetail.RuleValidation" /></b>
                            <hr className="my-1" />
                            <Row className="mb-2">
                                <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.FilledSample" /></Label></Col>
                                <Col><Input type="textarea" rows="2" onChange={(e) => this.filterData(e)}  > </Input> </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleAfterIgnore" /></Label></Col>
                                <Col>{this.state.ignoreRes}</Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleDetection" /></Label></Col>
                                <Col><div dangerouslySetInnerHTML={{ __html: this.state.applyRes }}></div></Col>
                                <Col className="col-1 text-center">
                                    {this.state.matchText ?
                                        <>
                                            <i className="color-green fas fa-check-circle" id="check-circle" />

                                            <UncontrolledTooltip placement="left" target="check-circle" >
                                                <FormattedMessage id="RuleDetail.ValidText" />
                                            </UncontrolledTooltip>
                                        </>
                                        : <>
                                            <i className="color-light-red fas fa-times-circle" id="times-circle"></i>

                                            <UncontrolledTooltip placement="left" target="times-circle" >
                                                <FormattedMessage id="RuleDetail.RuleNotApplied" />
                                            </UncontrolledTooltip>
                                        </>}
                                </Col>
                            </Row>
                            {rule.regularExpressionTransform &&
                                <Row className="mb-2">
                                    <Col className="col-3"><Label> <FormattedMessage id="RuleDetail.RuleReplacement" /></Label></Col>
                                    <Col>
                                        {this.state.replaceRes}
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </form>
            </StyledCard>
        );
    }
}
export default injectIntl(RegExDetail)
