import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Button, UncontrolledPopover } from 'reactstrap';
import { getSalesDealActionTypes } from '../Base/ReferenceDataFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import { KebabMenu } from '../Base/CommonUIComponents';
import SalesActionModal from './SalesActionModal';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { handleNotification } from '../Base/Notification';
import { putAPI } from '../Base/API';

class SalesActionsPopOver extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            hasMissedAction: false,
            hasNextAction: false,
            allFinished: false,
            allActionTypes: getSalesDealActionTypes(this.props.intl),
            actionableActionTypes: getSalesDealActionTypes(this.props.intl, undefined, true),
            selectedAction: null,
            modal: false,
            orderedActions: [],
            customers: [],
            confirmActionModal: false,
            status: null
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props)
            this.initialCalc(nextProps);
    }

    componentDidMount(){
        this.initialCalc(this.props);
    }

    initialCalc = (props) => {
        const { deal } = props;
        const { actionableActionTypes } = this.state;

        if(!deal)
            return;

        const customers = [];

        if(deal.customer){
            customers.push({
                value: deal.customer.id,
                label: deal.customer.name,
                ...deal.customer
            });
        }
        if(deal.mainContact){
            customers.push({
                value: deal.mainContact.id,
                label: deal.mainContact.name,
                ...deal.mainContact
            });
        }
        if(deal.salesDealProfile){
            deal.salesDealProfile.forEach(p => {
                customers.push({
                    value: p.profileId,
                    label: p.name,
                    ...p
                });
            });
        }
        
        const actionableActions = deal.salesDealActions?.filter(({type}) => actionableActionTypes.some(({value}) => value === type)) || [];

        this.setState({
            customers,
            hasMissedAction: actionableActions.length > 0 &&
                actionableActions.some(({startDate, endDate, status}) =>
                    (
                        status === "OnGoing" &&
                        endDate &&
                        moment().isAfter(startDate, 'date') &&
                        moment().isAfter(endDate, 'date')
                    ) ||
                    (
                        status === "Scheduled" &&
                        moment().isAfter((endDate??startDate), 'date')
                    )
                ),
            hasOnGoingAction: actionableActions.length > 0 &&
                actionableActions.some(({startDate, endDate, status}) =>
                    (
                        status === "Scheduled" ||
                        status === "OnGoing"
                    ) &&
                    moment().isSameOrAfter(startDate, 'date') &&
                    moment().isSameOrBefore(endDate, 'date')
                ),
            hasNextAction: actionableActions.length > 0 &&
                actionableActions.some(({startDate, status}) =>
                    status === "Scheduled" &&
                    moment().isBefore(startDate, 'date')
                ),
            allFinished: actionableActions.length > 0 && !actionableActions.some(({status}) => status === "Scheduled"),
            orderedActions: deal.salesDealActions?.filter(({type, startDate, endDate, status}) =>
                actionableActionTypes.some(({value}) => value === type) &&
                (
                    (
                        (startDate && moment().isSameOrBefore(startDate, 'day')) || // Vai decorrer
                        (endDate && moment().isSameOrBefore(endDate, 'day')) // A decorrer
                    ) ||
                    (
                        (status === "Scheduled" || status === "OnGoing") &&
                        startDate &&
                        moment().isSameOrAfter(startDate, 'day')
                    )
                )
            )?.sort((a, b) => moment(a.startDate).isAfter(b.startDate, 'day') ? -1 : 1) || [] // order by start date
        });
    }

    toggleModal = (selectedAction) => {
        this.setState({ selectedAction, modal: !this.state.modal })
    }
    
    toggleConfirmActionModal = (selectedAction, status) => {
        this.setState({
            confirmActionModal: !this.state.confirmActionModal,
            selectedAction,
            status
        })
    }

    finishOrRemoveAction = () => {
        const { selectedAction, status } = this.state;
        
        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.data && data.data.length > 0) {
                        this.setState({
                            error: errorMessage,
                            block: false,
                            status: null,
                            selectedAction: null,
                            confirmActionModal: false
                        }, () => handleNotification(data, (status === "Finished" ? <FormattedMessage id="SalesDeal.StatusChanged"/> : <FormattedMessage id="SalesDeal.ActionCanceled"/>), <FormattedMessage id="generic.success" />));

                        return;
                    }   
                }

                this.setState({ error: errorMessage, block: false, status: null, selectedAction: null, confirmActionModal: false });
            }, `/api/gms/SalesProcess/v1/deals/${selectedAction.id}/status/${status}`);
        });
    }

    updateActions = () => {
        const { getDeals, selectedPipeline } = this.props;
        getDeals(selectedPipeline);
    }

    render() {
        const { block, hasMissedAction, hasNextAction, allFinished, orderedActions, selectedAction, allActionTypes, customers, modal, confirmActionModal, status, hasOnGoingAction } = this.state;
        const { intl, salesUsers, deal, small, id = "SalesActionsPopOver", placement = "right" } = this.props;
        
        return (
            <div onClick={e => e.stopPropagation()}>
                <div id={id} className={small ? '' : 'pl-2 pb-2'}>
                    {hasMissedAction ?
                        <div className='actionStatusIcon' style={{ background: "#dc3545" }}>
                            <b title={intl.formatMessage({ id: `SalesProcess.MissedAction` })}>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                        </div>
                    : hasOnGoingAction ?
                        <div className='actionStatusIcon' style={{ background: "#0665ff" }}>
                            <b title={intl.formatMessage({ id: `SalesProcess.OnGoingAction` })}>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                        </div>
                    : hasNextAction ?
                        <div className='actionStatusIcon' style={{ background: "lightgray" }}>
                            <b title={intl.formatMessage({ id: `SalesProcess.ScheduledAction` })}>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                        </div>
                    : allFinished ?
                        <div className='actionStatusIcon' style={{ background: "#28a745" }}>
                            <b title={intl.formatMessage({ id: `SalesProcess.AllActionsFinished` })}>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                        </div>
                    :
                        <div className='actionStatusIcon' style={{ background: "#ffc107" }}>
                            <b title={intl.formatMessage({ id: `SalesProcess.NoActions` })}>
                                {placement === "left" ?
                                    '<'
                                :'>'}
                            </b>
                        </div>
                    }
                </div>

                <UncontrolledPopover target={id} placement={placement} placementPrefix='coolPopover bs-popover'>
                    <div className='py-2 pl-3' style={{ minWidth: '350px', maxWidth: '450px' }}>
                        {orderedActions.length > 0 ?
                            <div className='veryCoolScrollBar overflow-auto' style={{ maxHeight: '250px' }}>
                                <div className='pr-3'>
                                    {orderedActions.map((action, key) => {
                                        const type = allActionTypes.find(({value}) => value === action.type);
                                        const responsableUserId = action.salesDealUser && (action.salesDealUser.find(({role}) => role === "Responsible")?.userId ?? action.salesDealUser[0]?.userId);
                                        const responsableUser = salesUsers.find(({id}) => id === responsableUserId);
                                        const status = moment().isSameOrAfter(action.startDate, 'date') ? (action.endDate && moment().isSameOrBefore(action.endDate, 'date')) ? "OnGoing" : "Late" : "Soon";

                                        return (
                                            <div key={key} className='w-100 py-2' style={{ borderTop: key ? '1px solid #a8a8a8' : '' }}>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div>
                                                        <i className={type.icon}/>
                                                    </div>
                                                    <div className='px-3' style={{ width: '-webkit-fill-available' }}>
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <div className='text-truncate'>{action.title}</div>
                                                            <div>
                                                                {status === "Late" ?
                                                                    <i title={intl.formatMessage({ id: `SalesProcess.LateAction` })} className="text-danger fas fa-exclamation-circle"/>
                                                                : status === "Soon" ?
                                                                    <i title={intl.formatMessage({ id: `SalesProcess.ActionSoon` })} style={{ color: 'lightgrey' }} className="fas fa-minus-circle"/>
                                                                :
                                                                    <i title={intl.formatMessage({ id: `SalesProcess.OnGoingAction` })} style={{ color: '#0665ff' }} className="fas fa-arrows-alt-h"/>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='text-muted d-flex align-items-center justify-content-between' style={{ fontSize: '0.9em' }}>
                                                            <div>
                                                                <span>
                                                                    {moment(action.startDate).format('YYYY-MM-DD hh:mm')}
                                                                </span>
                                                                {action.endDate && moment(action.endDate).isSame(action.startDate, 'date') ?
                                                                    <>
                                                                        <span className='mx-1'>
                                                                            -
                                                                        </span>
                                                                        {moment(action.startDate).isSame(action.endDate, 'day') ?
                                                                            <span>
                                                                                {moment(action.endDate).format('hh:mm')}
                                                                            </span>
                                                                        :
                                                                            <span>
                                                                                {moment(action.endDate).format('YYYY-MM-DD hh:mm')}
                                                                            </span>
                                                                        }
                                                                    </>
                                                                :''}
                                                            </div>
                                                            {responsableUser ?
                                                                <div>
                                                                    {`${responsableUser.firstName} ${responsableUser.lastName}`}
                                                                </div>
                                                            :''}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <KebabMenu
                                                            extraFields={[
                                                                {
                                                                    function: _ => this.toggleConfirmActionModal(action, 'Finished'),
                                                                    icon: 'far fa-check-square mr-2',
                                                                    text: 'SalesProcess.MarkAsFinished'
                                                                }
                                                            ]}
                                                            editFunction={_ => this.toggleModal(action)}
                                                            deleteFunction={_ => this.toggleConfirmActionModal(action, 'Cancelled')}
                                                            deleteText={'SalesProcess.CancelAction'}
                                                        />
                                                    </div>
                                                </div>
                                                <hr className='my-2'/>
                                                <div className='text-muted' style={{ fontSize: '0.9em' }}>
                                                    {action.summary}
                                                </div>
                                            </div>
                                        )}
                                    )}
                                </div>
                            </div>
                        :
                            <div className='d-flex align-items-center justify-content-center py-3'>
                                <div>
                                    <FormattedMessage id="SalesProcess.NoActions"/>
                                </div>
                            </div>
                        }
                        <div className='pr-3'>
                            <Button className="btn btn-sm btn-host mt-2 w-100 mb-1" onClick={_ => this.toggleModal()}>
                                <i className="fas fa-plus mr-1"></i>
                                <FormattedMessage id="SalesProcess.CreateAction"/>
                            </Button>
                        </div>
                    </div>
                </UncontrolledPopover>

                {modal ?
                    <SalesActionModal
                        customers={customers}
                        toggle={_ => this.toggleModal()}
                        salesUsers={salesUsers}
                        selectedAction={selectedAction}
                        updateActions={this.updateActions}
                        salesDealId={deal.id}
                        salesPipelineStageId={deal.salesPipelineStageId}
                    />
                :''}

                {confirmActionModal ?
                    <ConfirmActionModal
                        modal={confirmActionModal}
                        toggleModal={() => this.toggleConfirmActionModal()}
                        actionFunction={this.finishOrRemoveAction}
                        text={status === "Finished" ? <FormattedMessage id={`SalesDeal.ConfirmChangeActionStatusToFinished`}/> : <FormattedMessage id="SalesDeal.ConfirmDeleteText" values={{ action: selectedAction.title }}/>}
                        block={block}
                    />
                :''}
            </div>
        );
    }
}

export default injectIntl(SalesActionsPopOver);