import React, { Component } from 'react'
import { Button, Col, Modal, ModalBody, Row, Badge, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard } from "../../Base/CommonUIComponents";
import moment from 'moment';

class TwilioMessageLogModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { block, error, modal, selectedMessage } = this.props;
        return (
            <Modal isOpen={modal} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={_ => this.props.toggleModal(null)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Row className="mb-2">
                            <Col>
                                <h5>
                                    <i className="fas fa-comments mr-2"></i>
                                    <FormattedMessage id="TwilioMessaging.PreviewMessage" />
                                </h5>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.Direction" />: </b>
                                {selectedMessage.direction}
                            </Col>
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.DateSent" />: </b>
                                {moment(selectedMessage.date_sent).format('DD MMMM YYYY, hh:mm a')}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.From" />: </b>
                                {selectedMessage.from}
                            </Col>
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.To" />: </b>
                                {selectedMessage.to}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.Price" />: </b>
                                {selectedMessage.price*-1??0.00} {selectedMessage.price_unit}
                            </Col>
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.Status" />: </b>
                                <Badge id="badgeStatus" className="py-1" color={selectedMessage.status === 'delivered' ? 'primary' : 'secondary'}>{selectedMessage.status}</Badge>
                                {selectedMessage.status !== 'delivered' && selectedMessage.error_code ?
                                    <UncontrolledTooltip placement="bottom" target="badgeStatus" >
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="TwilioMessaging.Code" />: {selectedMessage.error_code}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="TwilioMessaging.Message" />: {selectedMessage.error_message}
                                            </Col>
                                        </Row>
                                    </UncontrolledTooltip>
                            :''}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <b><FormattedMessage id="TwilioMessaging.Message" />: </b>
                                {selectedMessage.body}
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(TwilioMessageLogModal)
