import React, { Component } from 'react';
import { Row, Col, Card, Badge, Input } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';


export function getSalesSourceTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'Form', label: intl.formatMessage({ id: "ReferenceDataFunctions.Form" }) },
        { value: 'Partner', label: intl.formatMessage({ id: "ReferenceDataFunctions.Partner" }) },
        { value: 'Direct', label: intl.formatMessage({ id: "ReferenceDataFunctions.Direct" }) },
        { value: 'Renegotiation', label: intl.formatMessage({ id: "ReferenceDataFunctions.Renegotiation" }) },
    ]
}

export function getSalesPipelineTypes(intl) {
    return [
        { value: 'Event', label: intl.formatMessage({ id: "ReferenceDataFunctions.Event" }) },
        { value: 'GroupReservation', label: intl.formatMessage({ id: "ReferenceDataFunctions.GroupReservation" }) },
        { value: 'Partnership', label: intl.formatMessage({ id: "ReferenceDataFunctions.Partnership" }) }
    ]
}

export function getSalesPipelineStageTypes(intl) {
    return [
        { value: 'Lead', label: intl.formatMessage({ id: "ReferenceDataFunctions.Lead" }) },
        { value: 'DealProcess', label: intl.formatMessage({ id: "ReferenceDataFunctions.DealProcess" }) },
        { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
}

export function getDealStatus(intl) {
    return [
        { value: 'WaitingClient', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingClient" }) },
        // { value: 'InProgress', label: intl.formatMessage({ id: "ReferenceDataFunctions.InProgress" }) },
        { value: 'WaitingHotel', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingHotel" }) },
        { value: 'Cancelled', label: intl.formatMessage({ id: "ReferenceDataFunctions.Cancelled" }) },
        { value: 'Finished', label: intl.formatMessage({ id: "ReferenceDataFunctions.Finished" }) },
        { value: 'Effective', label: intl.formatMessage({ id: "ReferenceDataFunctions.Effective" }) }
    ]
}

export function getProfileRoles(intl) {
    return [
        { value: 'Responsible', label: intl.formatMessage({ id: "ReferenceDataFunctions.Responsible" }) },
        { value: 'Assistant', label: intl.formatMessage({ id: "ReferenceDataFunctions.Assistant" }) },
        { value: 'Related', label: intl.formatMessage({ id: "ReferenceDataFunctions.Related" }) },
        { value: 'Partner', label: intl.formatMessage({ id: "ReferenceDataFunctions.Partner" }) }
    ]
}

export function getUsersRoles(intl) {
    return [
        { value: 'Responsible', label: intl.formatMessage({ id: "ReferenceDataFunctions.Responsible" }) },
        { value: 'Assistant', label: intl.formatMessage({ id: "ReferenceDataFunctions.Assistant" }) },
        { value: 'Related', label: intl.formatMessage({ id: "ReferenceDataFunctions.Related" }) }
    ]
}

    export function getUsersRoleType(intl){ 
        return [
            { value: 'Manager', label: intl.formatMessage({ id: "ReferenceDataFunctions.Manager" }) },
            { value: 'SalesRep', label: intl.formatMessage({ id:"ReferenceDataFunctions.SalesRep" }) },
        ]
    }

export const ProfileCard = ({ name, role, notes, roleList, handleListElement, removeElement }) => {
    const nameDivided = name?.split(' ');

    return <div>
        <Row className="align-items-center">
            <Col className="d-flex align-items-center">
                {nameDivided ?
                    <Badge className="profileBadge mr-2">
                        {nameDivided[0].substr(0, 1).toUpperCase()}{nameDivided?.length > 1 ? nameDivided[nameDivided.length - 1].substr(0, 1).toUpperCase() : ''}
                    </Badge>
                    : ''}

                <span style={{ "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", display: 'block' }}>
                    {name}
                </span>
            </Col>

            <Col>
                <CustomSelect
                    placeholder=""
                    options={roleList}
                    value={roleList.find(t => t.value === role) || ''}
                    onChange={(combo) => handleListElement('role', combo ? combo.value : null)}
                    isClearable
                    isSearchable
                    required
                />
            </Col>
            <Col className="pr-0">
                <Input
                    required
                    type="text"
                    name="notes"
                    placeholder=""
                    value={notes || ''}
                    onChange={(event) => handleListElement('notes', event.target.value)}
                    maxLength={500}
                />
            </Col>
            <Col sm={1} className=" text-right pr-4">
                <i className="fas fa-trash-alt text-danger pointer" onClick={removeElement} />
            </Col>
        </Row>
    </div>
};