import React, { Component } from 'react';
import { Row, Col, Input, Button, Badge, ButtonGroup, Form } from 'reactstrap';
import { getContactTypeList, getDocumentList, getProfileType } from '../Base/ReferenceDataFunctions';
import { SelectHotel, SelectIsMaster, StyledCard } from '../Base/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { CustomTable } from '../Base/CustomTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import countryList from "react-select-country-list";
import { getAPI } from '../Base/API';
import moment from "moment";
import CustomSelect from '../Base/CustomSelect';
import { SingleDatePicker } from 'react-dates';

import Authorization from '../Base/Authorization';

class SearchProfile extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            profiles: null,
            pageIndex: 0,
            pageSize: this.props.pageSize ? this.props.pageSize : 10,
            totalItems: 0,
            hotels: [],
            hotelId: this.props.hotelId ? this.props.hotelId : null,
            name: null,
            otherName: null,
            countries: countryList().getData(),
            country: null,
            type: this.props.profileType ? this.props.profileType : null,
            birthDate: null,
            contactType: null,
            contact: null,
            docType: null,
            docNumber: null,
            cardNumber: null,
            searchableGuestNumber: null,
            isMaster: this.props.isMaster !== undefined ? this.props.isMaster : true,
            fromAnotherPage: this.props.fromAnotherPage ? this.props.fromAnotherPage : false,
            disableMasterFilter: this.props.disableMasterFilter !== undefined ? this.props.disableMasterFilter : false,
            disableProfileType: this.props.disableProfileType !== undefined ? this.props.disableProfileType : false,
            disabledHotelFilter: true,
            mergeProfile: this.props.mergeProfile ? this.props.mergeProfile : false
        }

        this.doSearch = this.doSearch.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("keydown", this.onKeyPressed.bind(this));
    }

    componentDidMount() {
        this._isMounted = true;
        
        this.updateStateFilters();
    }

    componentWillMount() {
        document.addEventListener("keydown", this.onKeyPressed.bind(this));
    }

    onKeyPressed(e) {
      if(e && e.code === 'Enter') this.doSearch();
    }

    updateStateFilters = () => {
        if (this.props.fromAnotherPage !== true) {
            let params = new URLSearchParams(window.location.search);

            if (!params.has('isMaster') && !params.toString()) params.append('isMaster', this.state.isMaster)
            if (!params.has('page')) params.append('page', 1)
            if (!params.has('pageSize')) params.append('pageSize', 10)
            this.props.history.push({ search: `?${params}` });

            let pageIndex = params.get('page') - 1;
            let pageSize = params.get('pageSize');
            let hotelId = params.get('hotelId');
            let name = params.get('name');
            let otherName = params.get('otherName');
            let country = params.get('country');
            let type = params.get('type');
            let birthDate = params.get('birthDate');
            let contactType = params.get('contactType');
            let contact = params.get('contact');
            let docType = params.get('docType');
            let docNumber = params.get('docNumber');
            let isMaster = params.get('isMaster');
            let cardNumber = params.get('cardNumber');
            let searchableGuestNumber = params.get('searchableGuestNumber');

            this.setState({ pageSize, pageIndex, hotelId, name, otherName, country, type, birthDate, contactType, contact, docType, docNumber, isMaster, cardNumber, searchableGuestNumber },
                () => {
                    if (Object.keys(Object.fromEntries(params.entries())).some(key => key !== "page" && key !== "pageSize" && key !== "isMaster")){
                        this.getProfiles(true);
                    }
                });
        }
    }

    getProfiles = (doNotUpdate) => {
        this.setState(({ block: true }));

        const params = this.updateAndGetUrlParams(doNotUpdate);

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
                else {
                    if (data.response && data.response.length > 0) {
                        data.response.filter(profile => !profile.isMaster && profile.masterId).map(profile => profile.linked = true);
                    }

                    this.setState({ profiles: data.response, error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/Profiles` + params)
    }

    updateAndGetUrlParams = (doNotUpdate) => {
        const { pageSize, pageIndex, hotelId, name, otherName, country, type, birthDate, contactType, docType, docNumber, isMaster, cardNumber, searchableGuestNumber } = this.state;

        let contact = this.state.contact;

        if (contact && contact.includes('@') && contact.includes(' ')) {
            contact = contact.replace(" ", "");
        }


        if (doNotUpdate !== true && this.props.fromAnotherPage !== true) {
            let url = `?page=${pageIndex + 1}&pageSize=${pageSize}&`;
            
            const params = {};
            
            if (hotelId) params.hotelId= hotelId; //url += `&hotelId=${hotelId}`;

            if (name) params.name = name; //url += `&name=${name}`;

            if (otherName) params.otherName = otherName; //url += `&otherName=${otherName}`;

            if (country) params.country = country; //url += `&country=${country}`;

            if (type) params.type = type; //url += `&type=${type}`;

            if (birthDate) params.birthDate = moment(birthDate).format("YYYY-MM-DD"); //url += `&birthDate=${moment(birthDate).format("YYYY-MM-DD")}`;

            if (contactType) params.contactType = contactType; //url += `&contactType=${contactType}`;

            if (contact) params.contact = contact.toLowerCase(); //url += `&contact=${contact.toLowerCase()}`;

            if (docType) params.docType = docType; //url += `&docType=${docType}`;

            if (docNumber) params.docNumber = docNumber; //url += `&docNumber=${docNumber}`;

            if (isMaster !== undefined && isMaster !== null) params.isMaster = isMaster; //url += `&isMaster=${isMaster}`;

            if (cardNumber !== undefined && cardNumber !== null) params.cardNumber = cardNumber; //url += `&cardNumber=${cardNumber}`;

            if (searchableGuestNumber !== undefined && searchableGuestNumber !== null) params.searchableGuestNumber = searchableGuestNumber; //url += `&searchableGuestNumber=${searchableGuestNumber}`;
            
            url += new URLSearchParams(params).toString();           

            this.props.history.push({ search: url });
        }

        var params = `?pageSize=${pageSize}&pageIndex=${pageIndex}`;

        if (hotelId) params += `&hotelId=${hotelId}`;
        if (name) params += `&name=${encodeURIComponent(name)}`;
        if (otherName) params += `&middleName=${encodeURIComponent(otherName)}`;
        if (country) params += `&country=${country}`;
        if (type) params += `&type=${type}`;
        if (birthDate) params += `&dateOfBirth=${moment(birthDate).format("YYYY-MM-DD")}`;
        if (contactType) params += `&contactType=${contactType}`;
        if (contact) params += `&contact=${encodeURIComponent(contact.toLowerCase())}`;
        if (docType) params += `&docType=${docType}`;
        if (docNumber) params += `&docNumber=${encodeURIComponent(docNumber)}`;
        if (isMaster) params += `&isMaster=${isMaster}`;
        if (cardNumber) params += `&cardNumber=${cardNumber}`;
        if (searchableGuestNumber) params += `&searchableGuestNumber=${searchableGuestNumber}`;
        if (this.state.isMaster !== undefined && this.state.isMaster !== null)  params += `&isMaster=${ this.state.isMaster }`

        return params;
    }
    
    setCombo = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    setIsMater = (name, combo) => {
        const value = combo && combo.value;

        this.setState(prevState => ({
            [name]: value,
            searchableGuestNumber: value ? null : prevState.searchableGuestNumber
        }));
    }

    handleChange(evt) {
        if (evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    doSearch(e) {
        if (e) e.preventDefault();

        this.setState({ pageIndex: 0, block: true }, this.getProfiles);
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ block: true, pageSize: value }, () => this.getProfiles());
        }
    }

    render() {
        const { block, blockHotels, hotels, hotelId, name, otherName, countries, country, type, birthDate, focused, contactType, contact, docType, docNumber, fromAnotherPage, isMaster, error, profiles, pageIndex, pageSize, cardNumber, searchableGuestNumber } = this.state;
        
        const columns = [
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Type" }),
                formatter: (cell, row) => {
                    if (cell) {
                        const type = getProfileType(this.props.intl).find(el => el.value === cell);

                        return <div className="text-muted text-center"> {type ? <i className={type.icon} /> : '-'} </div>
                    }
                    return '';
                },
                style: { width: '50px' }
            },
            {
                dataField: 'firstName',
                text: this.props.intl.formatMessage({ id: "SearchProfile.FirstName" }) + (this.props.showHotel ? ` / ${this.props.intl.formatMessage({ id: "generic.Hotel" })}` : ''),
                formatter: (cell, row) => {
                    return <div>
                        <div> {cell} {row.lastName}</div>
                        {this.props.showHotel && row.linked ? <span className="text-muted font_size_xxs"> Hotel {row.hotelName} </span> : ''}
                    </div> 
                }
            },
            {
                dataField: 'middleName',
                text: this.props.intl.formatMessage({ id: "ProfileDetails.OtherNames" })
            },
            {
                dataField: 'birthDate',
                text: this.props.intl.formatMessage({ id: "SearchProfile.BirthDate" }),
                formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                style: { width: '180px' }
            },
            {
                dataField: 'gender',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Gender" }),
                style: { width: '90px' }
            },
            {
                dataField: 'language',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Language" }),
                style: { width: '90px' }
            },
            {
                dataField: 'nationality',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Nationality" }),
                formatter: (cell, row) => cell ? <div><i className={('ml-2 mr-2 flag-icon flag-icon-') + (cell.toLowerCase())}></i> {countryList().getLabel(cell)} </div> : '',
                style: { width: '250px', paddingRight: '30px' }
            },
            /*{
                dataField: 'contacts',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Contacts" }),
                formatter: (cell, row) => {
                    return cell && cell.map(el => {
                        const type = getContactTypeList(this.props.intl).find(t => t.value === el.type);

                        return <div className="text-ellipsis" style={{ fontSize: '13px' }}>
                            <i className={type?.icon || ''} /> {el.contact}
                        </div>
                    })
                }
            },
            {
                dataField: 'documents',
                text: this.props.intl.formatMessage({ id: "SearchProfile.Documents" }),
                formatter: (cell, row) => {
                    return cell && cell.map(el => {
                        const type = getDocumentList(this.props.intl).find(t => t.value === el.type);

                        return <div className="text-ellipsis" style={{ fontSize: '13px' }}>
                            <i className={type?.icon || ''} /> {el.number}
                        </div>
                    })
                }
            },*/
            {
                dataField: 'membershipCard',
                text: this.props.intl.formatMessage({ id: "SearchProfile.MembershipCard" }),
                formatter: (cell, row) => {
                    return cell && cell.map(el =>
                        <div className="text-ellipsis" style={{ fontSize: '13px' }}>
                            {el.cardTypeCode} - {el.cardNumber}
                        </div>
                    )
                }
            },
            {
                dataField: 'isMaster',
                text: 'Master',
                formatter: (cell, row) => cell ? <Badge color='primary'> Master </Badge> : row.linked ? <Badge className=' color-white bg-cyan'> Linked </Badge> : ''
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (fromAnotherPage) {
                    this.props.addProfile(row);
                }
                else {
                    this.props.history.push({ pathname: `/ProfileDetails/${row.id}` })
                }
            }
        };


        return (
            <div>
                <StyledCard icon="" block={block || blockHotels} error={error} onKeyDown={this.onKeyPressed} tabIndex="0">
                    <div style={{ display: 'grid', gridTemplateRows: this.state.mergeProfile ? '1fr auto 1fr' : 'auto 1fr' , height: this.props.fromAnotherPage ? '61vh' : '88vh' }}>
                        <div style={{ zIndex: '999' }}>
                            <Row>
                                <Col>
                                    <h5 className="m-0">
                                        <i className="fas fa-users mr-2"></i>
                                        <FormattedMessage id="SearchProfile.SearchProfile" />
                                    </h5>
                                </Col>
                            </Row>

                            <Form onSubmit={this.doSearch} className="my-4 smallInput">
                                <Row>
                                    <Col className="col-3">
                                        <SelectHotel
                                            name='hotelId'
                                            icon="icon-icon-hotel"
                                            onChangeFunc={this.setCombo}
                                            placeholder={<FormattedMessage id="SearchProfile.Hotel" />}
                                            value={hotelId || ''}
                                            addDefaultValue={!(new URLSearchParams(window.location.search).has("hotelId"))}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-host btn-sm float-right" type="submit">
                                            <i className="icon-icon-search" />
                                        </Button>
                                        {!this.state.fromAnotherPage ?
                                            <Authorization
                                                perform="searchProfile:add"
                                                yes={() => (
                                                    <Button className="btn btn-host btn-sm float-right mr-2" onClick={() => this.props.history.push({ pathname: `/ProfileDetails/` })}>
                                                        <i className="fas fa-plus" />
                                                    </Button>
                                                )}
                                                no={() => <div></div>}
                                            />
                                            : ''}
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                    <Col className="col-3">
                                        <FormattedMessage id="SearchProfile.Name">
                                            {
                                                placeholder => <Input type="text" id="name" name="name" placeholder={placeholder} value={name ? name : ''} onChange={this.handleChange} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                    <Col className="pr-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.ContactType" />}
                                            options={getContactTypeList(this.props.intl)}
                                            value={getContactTypeList(this.props.intl).find(co => co.value === contactType)}
                                            onChange={this.setCombo.bind(this, 'contactType')}
                                            isClearable
                                            isSearchable
                                        />
                                    </Col>
                                    <Col className="pl-0">
                                        <FormattedMessage id="SearchProfile.Contact">
                                            {
                                                placeholder => <Input type="text" id="contact" name="contact" placeholder={placeholder} value={contact ? contact : ''} onChange={this.handleChange} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.EntityType" />}
                                            options={getProfileType(this.props.intl)}
                                            value={getProfileType(this.props.intl).find(t => t.value === type)}
                                            onChange={this.setCombo.bind(this, 'type')}
                                            isClearable
                                            isSearchable
                                            isDisabled={this.state.disableProfileType}
                                        />
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '13vw' }}>
                                        <FormattedMessage id="SearchProfile.CardNumber">
                                            {
                                                placeholder => <Input type="text" id="cardNumber" name="cardNumber" placeholder={placeholder} value={cardNumber ? cardNumber : ''} onChange={this.handleChange} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                    <Col className="birthDate" style={{ maxWidth: '13vw' }}>
                                        <FormattedMessage id="SearchProfile.DateOfBirth">
                                            {
                                                placeholder =>
                                                    <SingleDatePicker
                                                        id="dateOfBirthSearchProfile"
                                                        isOutsideRange={day => day > moment()}
                                                        date={birthDate ? moment(birthDate) : null}
                                                        focused={focused}
                                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                                        small={true}
                                                        numberOfMonths={1}
                                                        showDefaultInputIcon={true}
                                                        onDateChange={date => this.setState({ birthDate: date })}
                                                        placeholder={placeholder}
                                                    />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-3">
                                        <FormattedMessage id="ProfileDetails.OtherNames">
                                            {
                                                placeholder => <Input type="text" id="otherName" name="otherName" placeholder={placeholder} value={otherName ? otherName : ''} onChange={this.handleChange} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                    <Col className="pr-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.DocType" />}
                                            options={getDocumentList(this.props.intl)}
                                            value={getDocumentList(this.props.intl).find(doc => doc.value === docType)}
                                            onChange={this.setCombo.bind(this, 'docType')}
                                            isClearable
                                            isSearchable
                                        />
                                    </Col>
                                    <Col className="pl-0">
                                        <FormattedMessage id="SearchProfile.DocIdentification">
                                            {
                                                placeholder => <Input type="text" id="docNumber" name="docNumber" placeholder={placeholder} value={docNumber ? docNumber : ''} onChange={this.handleChange} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '15vw' }}>
                                        <CustomSelect
                                            placeholder={<FormattedMessage id="SearchProfile.Country" />}
                                            options={countries}
                                            value={countries.find(c => c.value === country)}
                                            onChange={this.setCombo.bind(this, 'country')}
                                            isClearable
                                            isSearchable
                                        />
                                    </Col>
                                    <Col className="px-2" style={{ maxWidth: '13vw' }}>
                                        <SelectIsMaster
                                            name="isMaster"
                                            onChangeFunc={this.setIsMater}
                                            value={isMaster}
                                            placeholder={<FormattedMessage id="SearchProfile.IsMaster" />}
                                            isDisabled={this.state.disableMasterFilter}
                                        />
                                    </Col>
                                    <Col style={{ maxWidth: '13vw' }}>
                                        <FormattedMessage id="SearchProfile.GuestNumber">
                                            {
                                                placeholder => <Input type="number" id="searchableGuestNumber" name="searchableGuestNumber" placeholder={placeholder} value={searchableGuestNumber ? searchableGuestNumber : ''} onChange={this.handleChange} disabled={isMaster ? true : false} />
                                            }
                                        </FormattedMessage>
                                    </Col>
                                </Row>
                            </Form>
                        </div>

                        {profiles ?
                            <div style={{ display: 'grid', gridTemplateRows: '1fr auto', overflow: 'hidden' }}>
                                <div className="veryCoolScrollBar tableWithScroll">
                                    <CustomTable
                                        data={profiles}
                                        columns={columns}
                                        selectRow={selectRow}
                                        page={pageIndex + 1}
                                        sizePerPage={pageSize}
                                        rowStyle={{ cursor: 'pointer' }}
                                        hasPagination={false}
                                    />
                                </div>

                                <Row className="mt-3">
                                    <Col>
                                        <ButtonGroup onClick={this.changePageSize}>
                                            <Button className={(pageSize == 10 ? "bg-host text-white" : "text-secondary") + " page-link"} value={10}> 10 </Button>
                                            <Button className={(pageSize == 25 ? "bg-host text-white" : "text-secondary") + " page-link"} value={25}> 25 </Button>
                                            <Button className={(pageSize == 30 ? "bg-host text-white" : "text-secondary") + " page-link"} value={30}> 30 </Button>
                                            <Button className={(pageSize == 50 ? "bg-host text-white" : "text-secondary") + " page-link"} value={50}> 50 </Button>
                                        </ButtonGroup>
                                    </Col>

                                    <Col className="text-right">
                                        <span>
                                            <Button className="btn btn-host btn-sm mr-2" onClick={_ => this.setState({ pageIndex: parseInt(pageIndex - 1), block: true }, () => this.getProfiles(pageIndex))} disabled={pageIndex === 0}>
                                                <i className="fas fa-angle-left" />
                                            </Button>
                                            <span className="mr-2">
                                                <FormattedMessage id="SearchProfile.Page" values={{ page: parseInt(pageIndex + 1) }} />
                                            </span>
                                            <Button className="btn btn-host btn-sm" onClick={_ => this.setState({ pageIndex: parseInt(pageIndex + 1), block: true }, () => this.getProfiles(pageIndex + 2))} disabled={profiles && profiles.length < pageSize}>
                                                <i className="fas fa-angle-right" />
                                            </Button>
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <div className="text-center d-flex align-items-center flex-column justify-content-center" style={{ height: this.props.fromAnotherPage ? "30vh" : "40vh" }}>
                                <span className="mb-3"> <i className="icon-icon-search text-muted" style={{ fontSize: "80px" }} /> </span>
                                <FormattedMessage id="generic.SelectSearchCriteria" />
                            </div>
                        }
                    </div>

                </StyledCard>
            </div>
        );
    }
}
export default injectIntl(SearchProfile)