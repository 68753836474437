import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Breadcrumb, BreadcrumbItem, Button, Col, Form, Input, Nav, NavItem, Row, NavLink, Badge, Collapse, CustomInput, ModalBody, Modal } from 'reactstrap';
import { getAPI, getMultiPartAPI, postAPI, postMultiPartAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import { getLanguagesCodes, getWhastAppStatus, getWhatsAppCategories } from '../../Base/ReferenceDataFunctions';
import { Link } from 'react-router-dom';
import WhatsAppMessage from './WhatsAppMessage';
import countryList from 'react-select-country-list';
import ComponentCreation from './ComponentCreation';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import SpeechBubble from './SpeechBubble';

class WhatsAppTemplates extends Component {
  
  constructor (props) {
    super(props);
    this.state = {
        block: true,
        error: null,
        templates: [],
        currId: null,
        componentParts: ['HEADER', 'BODY', 'FOOTER', 'BUTTONS'],
        templateCategories: ["TRANSACTIONAL", "OTP", "MARKETING"],
        languages: [],
        modal: false,
        exampleBlock: false,
        mediaURL: null,
        mediaList: [],
        currentMedia: { preview: null, type: '' }
    };
  }

  componentDidMount(){
    this.getCurrTemplate();
  }

  getCurrTemplate = () => {
    const { isCopy, lang, templates } = this.props;

    const languages = templates ? templates.map(({ language }) => language) : [undefined];
    
    let newTemplates = [this.newTemplate()];
    
    if(lang !== 0 && templates) newTemplates = templates;
    let newTemplate = newTemplates.find(({language}) => language === lang);
    
    this.setState({ templates: newTemplates, languages: languages, currId: newTemplate ? newTemplate.id : 0, block: false });
  }

  newTemplate = (isCopy) => {
    const { templates, currId } = this.state;

    let curr;

    if(isCopy && templates && templates.length > 0){
      curr = templates.find(({id}) => currId === id);
    };

    const template = {
      name: curr ? curr.name : '',
      language: undefined,
      components: curr ? curr.components : [],
      category: curr ? curr.category : '',
      status: 'NEW',
      unsavedChanges: true,
      id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)),
      isNew: true,
      isCopy: curr ? true : false
    };

    while(templates.filter(({id}) => id === template.id).length > 0){
      template.id = Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1));
    };

    return template;
  }

  handleChange = (e) => {
    e.preventDefault();
    const { templates, currId } = this.state;
    const { value, name } = e.target;

    const copy = [...templates];
    const template = copy.find(({ id }) => id === currId);

    template[name] = value;

    this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
  }

  handleSelect = (combo, name) => {
    const { templates, currId } = this.state;
    const copy = [...templates];
    const template = copy.find(({ id }) => id === currId);

    template[name] = combo.value;

    this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
  }

  editTemplate = () => {
    const { templates, currId, mediaURL } = this.state;
    const copy = [...templates];
    
    const curr = copy.find(({ id }) => id === currId);

    if(!curr || !curr.components?.length > 0) return;

    const body = {
      components: [...curr.components]
    };

    let buttons = body.components.find(({ type }) => type === "BUTTONS")?.buttons;

    if(buttons){
      buttons = buttons.map((button) => {
        delete button.active;
        return button;
      });
    };

      if (!buttons || buttons.length === 0) {
          const component = body.components.findIndex(({ type }) => type === "BUTTONS");

          if (component !== -1) body.components.splice(component, 1);
    };

    body.components = body.components.filter((comp) => {
      if(comp.format || comp.text || comp.buttons){
        return comp;
      };
    });

    if(body.components.length === 0 || body.components.filter(({type}) => type === "BODY").length === 0) return;

    const header = body.components.find(({ type }) => type === "HEADER");

    if(header.format !== "TEXT" && header.text) delete header.text;
    if(header.format === "TEXT" && header.example && header.example.header_handle) delete header.example.header_handle;
    // if(header.format !== "TEXT" && header.example.header_handle && header.example.header_handle[0]) header.example.header_handle[0] = mediaURL;

    this.setState({ block: true }, () => {
      postAPI(result => {
          const { data, error } = result;
          const errorMessage = [];
          if (error) {
              errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
              this.setState({ error: errorMessage, block: false, modal: false });
              return;
          }
          if (data) {
            if(data.errors && data.errors.length > 0){
              data.errors.forEach((errorField) => {
                errorMessage.push({ message: errorField.message, messageType: 'danger' });
              });
              this.setState({ error: errorMessage, block: false, modal: false });
            };
            if(data.response && data.response.length > 0 && data.response[0].success){
              curr.unsavedChanges = false;
              this.setState({ templates: copy, modal: false }, () =>
                handleNotification(data, null, <FormattedMessage id="stripo.saved" />)
              );
            }
          }
          this.setState({ error: errorMessage, block: false, modal: false });
      }, `/api/gms/WhatsApp/v1/updateMessageTemplate?templateId=${currId}`, body);
    });
  }

  saveTemplate = () => { //For New Templates
    const { templates, currId, mediaURL } = this.state;
    const copy = [...templates];
    
    const curr = copy.find(({ id }) => id === currId);

    if(!curr || !curr.components || curr.components.length === 0) return;

    const body = {...curr};

    body.components = body.components.filter((comp) => { // Clear Empty Components
      if(comp.format || comp.text || comp.buttons){
        return comp;
      };
    });

    if(body.components.length === 0 || body.components.filter(({type}) => type === "BODY").length === 0) return;

    if(body.components.filter(({ type }) => type === "BODY").length === 0) return;
    
    delete body.isNew;
    delete body.isCopy;
    delete body.unsavedChanges;
    delete body.status;
    delete body.id;

    body.name = body.name.replaceAll(' ', '');
    body.name = body.name.toLowerCase();

    let buttons = body.components.find(({ type, buttons }) => type === "BUTTONS" && buttons && buttons.length > 0)?.buttons;
    
    if(buttons){
      buttons = buttons.map((button) => {
        delete button.active;
        return button;
      });
    }
    
    const header = body.components.find(({ type }) => type === "HEADER");

    if(header.format !== "TEXT" && header.text) delete header.text;
    if(header.format === "TEXT" && header.example && header.example.header_handle) delete header.example.header_handle;
    // if(header.format !== "TEXT" && header.example.header_handle && header.example.header_handle[0]) header.example.header_handle[0] = mediaURL;

    this.setState({ block: true }, () => {
      postAPI(result => {
          const { data, error } = result;
          const errorMessage = [];
          if (error) {
              errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
              this.setState({ error: errorMessage, block: false, modal: false });
              return;
          }
          if (data) {
            if(data.errors && data.errors.length > 0){
              data.errors.forEach((errorField) => {
                errorMessage.push({ message: errorField.message, messageType: 'danger' });
              });
              this.setState({ error: errorMessage, block: false, modal: false });
            };
            if(data.response && data.response.length > 0 && data.response[0].id){
              curr.unsavedChanges = false;
              curr.id = data.response[0].id;
              this.setState({ templates: copy, modal: false, currId: data.response[0].id }, () =>
                handleNotification(data, <FormattedMessage id="stripo.successfulyExported" />, <FormattedMessage id="AdminUserManagement.Success" />)
              );
            }
          }
          this.setState({ error: errorMessage, block: false, modal: false });
      }, `/api/gms/WhatsApp/v1/messageTemplate`, body);
    });
  }

  createTemplate = () => {
    const templates = [...this.state.templates];
    const languages = [...this.state.languages];
    
    const newTemplate = this.newTemplate(true);

    templates.push(newTemplate);
    languages.push(undefined);

    this.setState({ templates, languages, currId: newTemplate.id });
  }

  changeCurrId = (lang) => {
    const { currId, templates } = this.state;
    const newID = templates.find(({language}) => language === lang)?.id
    if(currId !== newID){
      this.setState({ currId: newID });
    };
  }

  handleLang = (e) => {
    const { currId, templates } = this.state;
    const copy = [...templates];
    const curr = copy.find(({ id }) => currId === id);
    curr.language = e.value;

    this.setState({ template: copy, languages: copy.map(({language}) => language) });
  }

  toogleUnsavedChanges = () => {
    const { templates, currId } = this.state;
    const copy = [...templates];
    const curr = copy.find(({ id }) => id === currId);

    if(curr && !curr.unsavedChanges){
      curr.unsavedChanges = true;
      this.setState({ templates: copy });
    }
  }

  handleInput = (e, part, isExample, key) => {
    const { templates, currId, currentMedia } = this.state;
    const { value, name } = e.target;
    const copy = [...templates];
    const curr = copy.find(({ id }) => id === currId);

    let componentPart = curr.components && curr.components.find(({ type }) => type === part);
    
    if(!componentPart) {
      componentPart = {
        type: part
      };
      
      if(!curr.components) curr.components = [];
      curr.components.push(componentPart);
    };

    if(!e){
      curr.components.splice(curr.components.findIndex(({ type }) => type === part), 1);
    };

    if(!isExample) {
      componentPart[name] = value;
    }
    else {
      if(part === "BODY"){
        if(!componentPart.example || !componentPart.example.body_text){
          componentPart.example = {
            body_text: [[]]
          };
        };

        componentPart.example.body_text[0][key] = value;
      }
      else if(part === "HEADER"){
        if(componentPart.format === "TEXT"){
          if(!componentPart.example || !componentPart.example.header_text){
            componentPart.example = {
              header_text: []
            };
          };

          componentPart.example.header_text[key] = value;
        }
        else{
          componentPart.example = {
            header_handle: [
              value
            ]
          };
        }
      }
    };

    this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
  }

  handleButtons = (val, key, name) => {//Edit Buttons
    const { templates, currId } = this.state;

    const copy = [...templates];
    const curr = copy.find(({ id }) => id === currId);

    const componentPart = curr.components && curr.components.find(({ type }) => type === 'BUTTONS');

    if(!componentPart) return;

    const button = componentPart.buttons[key];

    if(!button) return;

    button[name] = val;

    this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
    return;
  }

  toggleButtons = () =>{//toggle buttons "active"
    const { templates, currId } = this.state;

    const copy = [...templates];
    const curr = copy.find(({ id }) => id === currId);

    const componentPart = curr.components && curr.components.find(({ type }) => type === 'BUTTONS');

    if(!componentPart) return;

    componentPart.buttons = [];

    this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
    return;
  }

  createNewButton = (buttonsType, remove, idx) => {//Creates a default button for edition or remover a button
    const { templates, currId } = this.state;

    const copy = [...templates];
    const curr = copy.find(({ id }) => id === currId);

    let componentPart = curr.components && curr.components.find(({ type }) => type === 'BUTTONS');

    if(!componentPart) {
      componentPart = {
        type: "BUTTONS",
        buttons: []
      };
      
      if(!curr.components) curr.components = [];
      curr.components.push(componentPart);
    };

    if(remove) {
      componentPart.buttons.splice(idx, 1);
      this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
      return;
    };

    if(buttonsType === 'QUICK_REPLY'){
      componentPart.buttons.push({
        text:'',
        type: buttonsType,
        active: true
      });

      this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
      return;
    };

    componentPart.buttons.push({
      text:'',
      active: true,
      type: 'URL' //PHONE_NUMBER????
    });

    this.setState({ templates: copy }, () => this.toogleUnsavedChanges());
    return;
  }

  toggleExportModal = (e) => {
    e.preventDefault();
    this.setState(prev => ({ modal: !prev.modal }));
  }

  exportTemplate = (e) => {
    e.preventDefault();
    const { templates, currId } = this.state;
    const copy = [...templates];

    const curr = copy.find(({ id }) => id === currId);
    if(curr.isNew) this.saveTemplate();
    else this.editTemplate();
  }

  uploadMedia = (media) => {

    const formData = new FormData();
    formData.append('mediaFile', media);

    postMultiPartAPI(result => {
      const { data, error } = result;

      var errorMessage = []
      if (error) {
          errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

          this.setState({ error: errorMessage, block: false });
          return;
      }
      if (data) {
          if (data.errors && data.errors.length > 0) {
              handleNotification(data);
              this.setState({ error: errorMessage, block: false });
          }
          else if (data.response && data.response.length > 0) {
            const fileHandle = data.response[0]?.h;
            this.setState({ fileHandle, block: false },
              () => {
                handleNotification(data, <FormattedMessage id="MediaManagement.MediaUploaded" />, <FormattedMessage id="General.Success" />);
                this.handleInput({target:{value:fileHandle, name: "format"}}, "HEADER", true)
              }
            );
          }
      }
      else this.setState({ error: errorMessage, block: false });
  }, `/api/gms/WhatsApp/v1/UploadWhatsAppTemplateMedia`, formData);
  }

  handleMedia = (e) => {
    e.preventDefault();

    let file = e.target.files[0];

    if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            this.setState({
                headerMedia: file,
                currentMedia: { type: file.type, preview: reader.result }
            }, () => this.uploadMedia(file));
        };
    }
    else {
        document.getElementById('media').value = "";
        this.setState({ mediaPreview: null })
    }
  }

  render() {
    const { error, block, templates, currId, modal, languages, headerMedia, componentParts, templateCategories, mediaList, exampleBlock, currentMedia } = this.state;
    const { intl, returnFunc, orderComponents } = this.props;

    const categoryOptions = templateCategories.map((cat) => {
      return({
        value: cat,
        label: intl.formatMessage({ id: `WhatsApp.${cat}` })
      });
    });
    
    const LanguageOptions = getLanguagesCodes().map((code) => {
      return {
        value: code,
        label: code
      };
    });

    const template = templates?.find(({id}) => id === currId)
    const whatsAppStatus = getWhastAppStatus();

    const curr = templates.find(({ id }) => id === currId);

    // const headerMedia = curr?.components?.find(({ type }) => type === "HEADER")?.example?.header_handle;
    
    return (
      <StyledCard error={error} block={block}>
        <Breadcrumb>
            <BreadcrumbItem>
              <div onClick={returnFunc} className="fakeA">
                <FormattedMessage id="WhatsApp.TemplateManagement"/>
              </div>
            </BreadcrumbItem>
            <BreadcrumbItem active>
                {template?.name && !template.isNew ? template.name : <FormattedMessage id="WhatsApp.NewTemplate"/>}
            </BreadcrumbItem>
        </Breadcrumb>
        <ConfirmActionModal
          text={intl.formatMessage({ id: "WhatsApp.ExportMessageTitle" })}
          modal={modal}
          toggleModal={this.toggleExportModal}
          actionFunction={this.exportTemplate}
          block={block}
        />
        {template ?
          <Form onSubmit={this.toggleExportModal}>
            <Row>
              <Col style={{ display: 'flex', alignItems: 'center' }}>
                <Badge style={{ background: whatsAppStatus.find(({ value }) => value === template.status) ?
                whatsAppStatus.find(({ value }) => value === template.status).badgeColor : '#6c757d' }}>
                    <FormattedMessage id={`WhatsApp.${template.status}`}/>
                </Badge>
              </Col>
              <Col className='text-right'>
                <Button
                  className="btn btn-host btn-sm float-right"
                  type="submit"
                  disabled={template.status !== 'REJECTED' && template.status !== 'APPROVED' && template.status !== 'PAUSED' && template.status !== 'NEW'}
                >
                    <i className="fas fa-envelope" />
                </Button>
              </Col>
            </Row>
            <Row id="WhatsAppTemplates py-4 mt-2">
              <Col>
                <Row>
                  <Col>
                    <Row className='my-2'>
                      <Col>
                        <h5><FormattedMessage id="WhatsApp.Template"/></h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormattedMessage id="WhatsApp.Name"/>
                        <div className='mt-2'>
                          <Input
                            name="name"
                            value={template.name}
                            onChange={this.handleChange}
                            readOnly={!template.isNew || template.isCopy}
                            required
                          />
                        </div>
                      </Col>

                      <Col>
                        <FormattedMessage id="WhatsApp.Language"/>
                        <div className='mt-2'>
                          <CustomSelect
                              options={LanguageOptions}
                              required
                              isOptionDisabled={(option) => templates.filter(({ language }) => language === option.value).length > 0}
                              isSearchable
                              placeholder={""}
                              onChange={this.handleLang}
                              value={template?.language ? LanguageOptions?.find(c => c.value === template?.language) : null}
                              enableRequired
                              isDisabled={!template.isNew}
                          />
                        </div>
                      </Col>

                      <Col>
                        <FormattedMessage id="WhatsApp.Category"/>
                        <div className='mt-2'>
                          <CustomSelect
                            onChange={(combo) => this.handleSelect(combo, 'category')}
                            options={categoryOptions}
                            isDisabled={!template.isNew || template.isCopy}
                            required
                            value={template.category && categoryOptions ? categoryOptions.find(({ value }) => value === template.category) : null}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <Row className='my-2'>
                      <Col>
                        <h5><FormattedMessage id="WhatsApp.Components"/></h5>
                      </Col>
                    </Row>
                    {componentParts.map((part, key) => 
                      <div key={key}>
                        <Row className='mt-4'>
                          <Col>
                            <b><FormattedMessage id={`WhatsApp.${part}`}/></b>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <ComponentCreation
                              handleMedia={this.handleMedia}
                              mediaList={mediaList}
                              headerMedia={headerMedia}
                              exampleBlock={exampleBlock}
                              part={part}
                              handleInput={this.handleInput}
                              toogleUnsavedChanges={this.toogleUnsavedChanges}
                              components={template.components}
                              createNewButton={this.createNewButton}
                              handleButtons={this.handleButtons}
                              toggleButtons={this.toggleButtons}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Nav tabs>
                      {languages?.map((language, key) => 
                        <NavItem key={key} className="cursor-pointer">
                            <NavLink className={template.language === language ? 'tab-border-host text-host border-0 h-100' : 'border-0 h-100'} onClick={() => this.changeCurrId(language)}>
                              {language ?
                                <>
                                  <span className={`flag-icon flag-icon-${language.includes('_') ? language.split('_')[1].toLowerCase() : language.toLowerCase()} mb-2 px-2 `}/>
                                  {!templates.find((t) => t.language === language) || templates.find((t) => t.language === language).unsavedChanges ?
                                      <span className='stripoUnsavedChanges'/>
                                  :''}
                                </>
                              :
                              <>
                                <div>
                                  Default
                                  {!templates.find((t) => t.language === language) || templates.find((t) => t.language === language).unsavedChanges ?
                                    <span className='stripoUnsavedChanges'/>
                                  :''}
                                </div>
                              </>
                              }
                            </NavLink>
                        </NavItem>
                      )}
                      <NavItem>
                          <NavLink className="d-flex h-100">
                              <div>
                                  <Button type='button' disabled={!languages || languages.filter((lang) => lang === undefined).length > 0} onClick={this.createTemplate} className="btn-host btn-sm" style={{ 'fontSize': '0.35rem' }}  >
                                      <i className="fas fa-plus"/>
                                  </Button>
                              </div>
                          </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
                  <Row>
                    <Col>
                        <WhatsAppMessage big={true}>
                            <SpeechBubble
                              maxWidth={'55%'}
                              currentMedia={currentMedia}
                              components={orderComponents(template.components.filter(({ active }) => active !== false))}
                              showInteractions={false}
                            />
                        </WhatsAppMessage>
                    </Col>
                  </Row>
              </Col>
            </Row>
          </Form>
        :''}
      </StyledCard>
    )
  }
}

export default injectIntl(WhatsAppTemplates);