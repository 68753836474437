import React, { Component } from 'react'
import { StyledCard } from '../../Base/CommonUIComponents'
import { Col, Row, Form, Input, Button, Badge } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import moment from 'moment';
import { Pagination, ResultsPerPage } from '../../Base/PaginationComponents';
import TwilioMessageLogModal from './TwilioMessageLogModal';
import { CustomTable } from '../../Base/CustomTable';
import { SingleDatePicker } from 'react-dates';

class TwilioMessageLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            pageIndex: 0,
            pageSize: 10,
            messageModal: false
        };
    }

    componentDidMount() {
        this.getMessageLog();
    }

    getMessageLog = (value, page) => {
        const { pageSize, To, From, sentDate } = this.state;

        value = value?.split("&PageToken=").pop().split("&Page=")[0]
        this.setState({block: true}, 
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data.response) {
                    this.setState({ messageLog: data.response, block: false, pageToken: value });
                    return;
                }

                this.setState({ block: false });
            }
        }, `/api/gms/Twilio/v1/sms/messageLog?PageSize=${pageSize}&Page=${page ? page : 0}&Uri=${value ? value : ''}&To=${To ? To : ''}&From=${From ? From : ''}&SentDate=${sentDate ? moment(sentDate).format("YYYY-MM-DD") : ''}`));
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, this.getMessageLog);
        }
    }

    handlePagination = (value, uri) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value,
        }, this.getMessageLog(uri, this.state.pageIndex + value));
    }

    toggleMessageModal = (mess) => {
        this.setState({ messageModal: !this.state.messageModal, selectedMessage: mess })
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    doSearch = (e) => {
        e.preventDefault();

        this.setState({
            pageIndex: 0
        }, () => this.getMessageLog())
    }

    render() {
        const { page } = this.props;
        const { block, error, messageLog, pageIndex, pageSize, messageModal, selectedMessage, From, To, sentDate } = this.state;

        const columnsCSV = [
            {
                dataField: 'direction',
                text: this.props.intl.formatMessage({ id: "TwilioMessaging.Direction" }),
                sort: true
            },
            {
                dataField: 'price',
                text: this.props.intl.formatMessage({ id: "TwilioMessaging.Price" }),
                sort: true,
                formatter: (cell, row) => {
                    return (cell * -1) + ' ' + row.price_unit
                },
            },
            {
                dataField: 'from',
                text: this.props.intl.formatMessage({ id: "TwilioMessaging.From" }),
                sort: true,
            },
            {
                dataField: 'to',
                text: this.props.intl.formatMessage({ id: "TwilioMessaging.To" }),
                sort: true
            },
            {
                dataField: 'date_sent',
                text: this.props.intl.formatMessage({ id: "TwilioMessaging.DateSent" }),
                sort: true,
                formatter: (cell) => {
                    return moment(cell).format('DD MMMM YYYY, hh:mm a')
                },
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "TwilioMessaging.Status" }),
                sort: true,
                formatter: (cell) => {
                    return <Badge className="py-1" color={cell === 'delivered' ? 'primary' : 'secondary'}>{cell}</Badge>
                },
            }
        ];

        const selectRow = {
            mode: "radio",
            hideSelectColumn: true,
            bgColor: "#f8f9fa",
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleMessageModal(messageLog.messages[rowIndex]);
            }
        };

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                </Row>
                <div className='mt-2'>
                    <Form onSubmit={this.doSearch}>
                        <Row className="mb-2 mt-3 align-items-center">
                            <Col className="col-3 pr-1">
                                <Input
                                    type="text"
                                    name="From"
                                    onChange={(e) => this.handleChange(e)}
                                    placeholder={this.props.intl.formatMessage({ id: "TwilioMessaging.From" })}
                                    value={From|| ''}
                                    maxLength="14"
                                />
                            </Col>
                            <Col className="col-3 pr-1">
                                <Input
                                    type="text"
                                    name="To"
                                    onChange={(e) => this.handleChange(e)}
                                    placeholder={this.props.intl.formatMessage({ id: "TwilioMessaging.To" })}
                                    value={To || ''}
                                    maxLength="14"
                                />
                            </Col>
                            <Col className="col-3 pr-1">
                                <SingleDatePicker
                                    id="sentDate"
                                    isOutsideRange={day => false}
                                    date={sentDate ? moment(sentDate) : ''}
                                    focused={this.state.focused}
                                    onFocusChange={({ focused }) => this.setState({ focused })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    onDateChange={date => this.setState({ sentDate: date ? moment(date).format("YYYY-MM-DD") : null})}
                                    showClearDate={true}
                                />
                            </Col>
                            <Col className="text-right" >
                                <Button className="btn btn-sm btn-host" type="submit">
                                    <i className="icon-icon-search"></i>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {messageLog && messageLog.messages ?
                        <>
                            <CustomTable
                                keyField='DateSent'
                                data={messageLog.messages}
                                columns={columnsCSV}
                                totalSize={messageLog.messages.length}
                                shadow={false}
                                showTotal={false}
                                search={false}
                                hideSizePerPage={false}
                                hasPagination={false}
                                selectRow={selectRow}
                            />
                            <Row className="my-3">
                                <Col>
                                    <ResultsPerPage
                                        changeSizePerPage={this.changePageSize}
                                        pageSize={pageSize}
                                    />
                                </Col>

                                <Col className="text-right">
                                    <Pagination
                                        isPrevDisabled={pageIndex === 0}
                                        isNextDisabled={messageLog.next_page_uri === null}
                                        currentPage={pageIndex + 1}
                                        handlePrevButton={() => this.handlePagination(-1, messageLog.previous_page_uri)}
                                        handleNextButton={() => this.handlePagination(1, messageLog.next_page_uri)}
                                    />
                                </Col>
                            </Row>
                        </>

                        : ''}
                </div>
                {messageModal ?
                    <TwilioMessageLogModal
                        modal={messageModal}
                        toggleModal={this.toggleMessageModal}
                        block={block}
                        selectedMessage={selectedMessage}
                    />
                    : ''}
            </StyledCard>
        )
    }
}
export default injectIntl(TwilioMessageLog);