import React, { Component } from 'react'
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard, StyledModal } from '../../Base/CommonUIComponents';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import SalesDealStep1 from './SalesDealStep1';
import SalesDealStep2 from './SalesDealStep2';
import SalesDealStep3 from './SalesDealStep3';
import SalesDealStep4 from './SalesDealStep4';
import moment from 'moment';


class SalesDealModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            steps: [
                { step: 1, active: true, name: 'Deal' },
                { step: 2, active: false, name: 'Dates' },
                { step: 3, active: false, name: 'Client' },
                { step: 4, active: false, name: 'Staff' }
            ],
            deal: this.props.deal || {
                salesPipelineStageId: this.props.salesStages ? this.props.salesStages[0].value : null,
                salesPipelineId: this.props.salesPipelineId ? this.props.salesPipelineId : this.props.salesPipelines ? this.props.salesPipelines[0]?.value : null
            }
        };
    }

    componentDidMount() {
        const { steps } = this.state;
        
        if(steps && this.props.specificEditStep){
            steps.forEach(el => {
                el.active = el.step === this.props.specificEditStep;
            })
            this.setState({ steps });
        }
    }

    saveDeal = () => {
        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }

                    if (this.props.updateDeal) {
                        this.props.updateDeal(data);
                    }
                    else if (this.props.getDeals) this.props.getDeals();

                    this.props.toggleModal();

                    handleNotification(data, <FormattedMessage id="SalesDeal.SavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals`, {...this.state.deal });
        })
    }

    changeStep = (value) => {
        const { steps } = this.state;

        steps.forEach(el => {
            el.active = el.step === value;
        })

        this.setState({ steps });
    }

    handleSelect = (name, combo) => {
        const { salesStages } = this.props;
        let { deal } = this.state;
        let targetValue = null;

        if (Array.isArray(combo)) {
            targetValue = combo.map(el => el.value);
        }
        else {
            targetValue = combo ? combo.value : null;
        }

        if(name === "salesPipelineId"){
            deal.salesPipelineStageId = salesStages.find(({salesPipelineId}) => salesPipelineId === targetValue)?.value;
        }

        deal = {...deal, [name]: targetValue};

        this.setState({ deal });
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            deal: {
                ...this.state.deal,
                [name]: value
            }
        })
    }

    handleDate = (name, date) => {
        this.setState({
            deal: {
                ...this.state.deal,
                [name]: date ? moment(date).format("YYYY-MM-DD") : null
            }
        })
    }

    changeProfile = (name, profile) => {
        this.setState({
            deal: {
                ...this.state.deal,
                [`${name}Id`]: profile.profileId,
                [name]: profile
            }
        })
    }

    handleProfilesList = (name, list) => {
        this.setState({
            deal: {
                ...this.state.deal,
                [name]: list
            }
        })
    }

    handleListElement = (listName, index, propName, value, isToRemove) => {
        if (index !== -1) {
            const { deal } = this.state;

            if (isToRemove) {
                deal[listName].splice(index, 1);
            }
            else
                deal[listName][index][propName] = value;

            this.setState({ deal });
        }        
    }

    isDisabled = () => {
        const { steps, deal } = this.state;

        if (steps[0].active && deal.name && deal.summary && deal.salesSourceId && deal.salesPipelineStageId && deal.status && (deal.status === 'Cancelled' ? deal.statusReason : true)) {
            return false
        }
        else if (steps[1].active && deal.hotelIds && deal.hotelIds.length > 0 && deal.negotiationStartDate) {
            return false
        }
        else if (steps[2].active && deal.customerId && deal.mainContactId && (!deal.salesDealProfile || deal.salesDealProfile?.length === 0 || deal.salesDealProfile?.every(el => el.role))) {
            return false
        }

        return true;
    }


    render() {
        const { block, error, steps, deal } = this.state;
        const { modal, toggleModal, salesStages, salesSources, salesUsers, salesPipelines, editableSalesPipeline } = this.props;

        const currentStep = steps.find(el => el.active);

        return (
            <StyledModal toggleModal={toggleModal} modal={modal} size="xl">
                <BlankCard block={block} error={error}>
                    <Row className="mb-4">
                        <Col className="col-1 text-left d-flex align-items-center">
                            {steps[0].active === false ?
                                <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step - 1)}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                                : ''}
                        </Col>

                        <Col className="py-1">
                            <ul className="steps">
                                {steps.map((step, key) =>
                                    <li className={'step ' + (step.active ? ' step-active' : '')} style={{ cursor: 'pointer' }} key={key}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.Step${step.step}`} />
                                            </span>
                                            <span className="step-circle my-1" onClick={() => this.changeStep(step.step)} />
                                            <span className="step-text small">
                                                <FormattedMessage id={`SalesDeal.${step.name}`} />
                                            </span>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </Col>

                        <Col className="col-1 text-right d-flex align-items-center">
                            {steps[3].active ?
                                <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={(e) => this.saveDeal(e)} /*disabled={!deal.salesDealUser || deal.salesDealUser.length === 0}*/>
                                    <i className="fas fa-save" />
                                </Button>
                                :
                                <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={_ => this.changeStep(currentStep.step + 1)} disabled={this.isDisabled()}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            }
                        </Col>
                    </Row>



                    {steps[0].active ?
                        <SalesDealStep1
                            deal={deal}
                            sources={salesSources}
                            stages={salesStages}
                            salesPipelines={salesPipelines}
                            editableSalesPipeline={editableSalesPipeline}
                            handleSelect={this.handleSelect}
                            handleChange={this.handleChange}
                        />
                    : ''}

                    {steps[1].active ?
                        <SalesDealStep2
                            deal={deal}
                            handleSelect={this.handleSelect}
                            handleDate={this.handleDate}
                            handleChange={this.handleChange}
                        />
                    : ''}

                    {steps[2].active ?
                        <SalesDealStep3
                            deal={deal}
                            handleProfilesList={this.handleProfilesList}
                            changeProfile={this.changeProfile}
                            handleListElement={this.handleListElement}
                        />
                    : ''}

                    {steps[3].active ?
                        <SalesDealStep4
                            deal={deal}
                            handleProfilesList={this.handleProfilesList}
                            handleChange={this.handleChange}
                            handleListElement={this.handleListElement}
                            usersList={salesUsers}
                            salesUsers={salesUsers}
                        />
                    : ''}
                </BlankCard>
            </StyledModal>
        )
    }
}

export default injectIntl(SalesDealModal);