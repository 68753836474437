import moment from 'moment-timezone';
import React, { Component } from 'react'
import { DateRangePicker } from 'react-dates';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Button, Col, Pagination, Row, UncontrolledTooltip } from 'reactstrap';
import { getAPI } from '../../Base/API';
import Authorization from '../../Base/Authorization';
import { StyledCard } from '../../Base/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import { TotalResults } from '../../Base/PaginationComponents';
import { FormAnswers } from '../Common/MarketingFunctions';

class TypeformAnswers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            formAnswers: [],
            datei: moment().add(-7, "days"),
            datef: moment(),
            pageIndex: 0,
            pageSize: 9,
            totalItems: 0,
            formQuestionData: null
        };
    }

    componentDidMount() {
        this.getFormQuestions();
    }

    getFormQuestions = () => {
        const { match } = this.props;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            };

            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                };
                this.setState({ block: false, formQuestionData: data.response[0] }, () => this.getFormAnswers(data.response[0]));
                return;
            };
            
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/TypeForm/FormQuestions/${match.params.id}`);
    }


    getFormAnswers = (formQuestion) => {
        this.setState({ block: true }, () => {
            const { pageSize, pageIndex, datei, datef } = this.state;
            const { match, intl } = this.props;
            var params = `&pageSize=${pageSize}&pageIndex=${pageIndex}`;

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    };

                    const replaceDynamicQuestion = (string, resId) => {
                        if(!string.includes('{{')) return string;
                        const currentResponse = data.response?.find(({response_id}) => response_id === resId);
                        let result = string.slice();
                        const subString = string.substring(string.indexOf('{{'), (string.indexOf('}}') + 2));
                        const answerId = subString.substring(subString.indexOf(':'), string.indexOf('}}')).slice(1, -2);
                        const answer = currentResponse?.answers.find(({field}) => field?.ref === answerId)?.text;

                        result = result.replace(subString, answer);

                        return result;
                    };

                    const getAnswer = (answer) => {
                        if(!answer) return null;
                        if(answer.type === "text") return answer.text;
                        if(answer.type === "boolean") return answer[answer.type] ? intl.formatMessage({ id: 'generic.yes'}) : intl.formatMessage({ id: 'generic.no'});
                        if(answer[answer.type] !== null && answer[answer.type]?.label) return answer[answer.type].label;
                        if(answer[answer.type] !== null) return answer[answer.type];
                    }
                    
                    const formAnswers = formQuestion ? data.response.map((formAnswer) => {
                        return ({
                            title: formQuestion?.title,
                            id: formQuestion?.id,
                            language: formQuestion?.settings?.language,
                            questions: formAnswer.answers.map((answer) => {
                                const question = formQuestion.fields.find(({id}) => id === answer.field.id);
                                const questionTitle = replaceDynamicQuestion(question?.title, formAnswer?.response_id);

                                const res = {
                                    answer: getAnswer(answer),
                                    question: questionTitle,
                                    required: question?.validations?.required,
                                    type: answer.type
                                };
                                
                                return res;
                            })
                        });
                    }) : [];

                    this.setState({ formAnswers, totalItems: data.totalItems, block: false });
                    return;
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/TypeForm/Forms/${match.params.id}?startDate=${datei.format('YYYY-MM-DD')}&endDate=${datef.format('YYYY-MM-DD')}T23:59:00` + params);
        });
    }

    handlePagination = (value) => {
        const { formQuestionData } = this.state;
        this.setState(prev => ({
            block: true,
            pageIndex: prev.pageIndex + value
        }), () => this.getFormAnswers(formQuestionData));
    }

    render() {
        const { block, error, datei, datef, formQuestionData, focusedInput, formAnswers, totalItems, pageIndex, pageSize } = this.state;
        const { intl, match } = this.props;
        
        return (
            <StyledCard block={block} error={error} >
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/Typeform">
                            <FormattedMessage id="TypeFormMenu.Title"/>
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link to="/TypeformForms"> 
                            <FormattedMessage id="TypeFormMenu.Forms"/>
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                        {formQuestionData?.title || 'Form'}
                    </BreadcrumbItem>
                </Breadcrumb>

                <Row className="mb-3">
                    <Col sm={3}>
                        <DateRangePicker
                            startDate={datei ? moment(datei) : null}
                            startDateId="startDate"
                            isOutsideRange={day => day > moment()}
                            endDate={datef ? moment(datef) : null}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ datei: startDate, datef: endDate })}
                            focusedInput={focusedInput}
                            onFocusChange={fI => this.setState({ focusedInput: fI })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                            required
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.getFormAnswers(formQuestionData)}>
                            <i className="icon-icon-search" />
                        </Button>

                        <Authorization
                            perform="TypeForm:map"
                            yes={() => (
                                <>
                                    <Button className="btn- btn-host btn-small btn-sm ml-2" id="mapForm" tag={Link} to={{ pathname: `/MapTypeForm/${match.params.id}` }}>
                                        <i className="fas fa-random" />
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" target="mapForm">
                                        <FormattedMessage id="MapFormModal.Map" />
                                    </UncontrolledTooltip>
                                </>
                            )}
                            no={() => <div></div>}
                        />
                    </Col>
                </Row>

                <Row>
                    {formAnswers && formAnswers.map((answer, k) => {
                        return <Col className="col-4 mb-4" key={k}>
                            <FormAnswers answers={answer.questions} isTypeForm={true}>
                                <Row>
                                    <Col><h5 className="m-0 text-host"> <FormattedMessage id="MapFormModal.Answer" /> {k + 1}</h5></Col>
                                    <Col className="text-right">
                                        {answer.submitted_at ? moment(answer.submitted_at).format("YYYY-MM-DD") : ''}
                                    </Col>
                                </Row>

                                <hr />
                            </FormAnswers>
                        </Col>
                    })}
                </Row>


                {totalItems > 0 ?
                    <Row className="mb-4">
                        <Col>
                            <span className="ml-2">
                                <TotalResults
                                    end={pageIndex * pageSize + formAnswers.length}
                                    start={pageIndex * pageSize + 1}
                                    total={totalItems}
                                />
                            </span>
                        </Col>

                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                :
                    <div className="text-center mt-4">
                        <FormattedMessage id="FormList.ThereAreNoSubmittedForms"/>
                    </div>
                }
            </StyledCard>
        )
  }
}

export default injectIntl(TypeformAnswers);