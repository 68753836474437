import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getRelationType } from '../Base/ReferenceDataFunctions';
import SearchProfile from '../ProfileMenu/SearchProfile';
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";

class AssociationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            profile: null,
            originalRelationType: null,
            inverseRelationType: null,
            hotelId: null,
            profileType: null,
            isMaster: null,
            addDuplicated: false,
            disableMasterFilter: false
        };
    }

    componentDidMount() {
        if (this.props.modalType === 'Duplicated') {
            this.setState({
                isMaster: false,
                hotelId: this.props.hotelId,
                disableMasterFilter: true,
                addDuplicated: true
            })
        }
        else if (this.props.modalType === 'Family') {
            this.setState({
                profileType: 'Person',
                disableProfileType: true,
                originalRelationType: 'Family',
                inverseRelationType: 'Family',
                addDuplicated: false,
                disableMasterFilter: false
            })
        }
        else if (this.props.modalType === 'Linked') {
            this.setState({
                disableProfileType: false,
                originalRelationType: 'Linked',
                inverseRelationType: 'Linked',
                addDuplicated: false,
                disableMasterFilter: false
            })
        }
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    };

    selectProfile = (profile) => {
        this.setState({ profile })
    }

    render() {
        const { block, error } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} >
                <Button onClick={this.props.toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={(e) => {
                            if (this.props.modalType === 'Duplicated') {
                                this.props.associateProfile(e, this.state.profile);
                            }
                            else {
                                this.props.addRelation(this.state.profile, this.state.originalRelationType, this.state.inverseRelationType, e);
                            }
                            this.props.toggle();
                        }}>
                            <Row className="mb-3">
                                <Col>
                                    <h5><FormattedMessage id="AssociatedProfiles.AssociateProfile" /></h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit" disabled={!this.state.profile}> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            {!this.state.addDuplicated ?
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="AssociatedProfiles.OriginalRelationType" /></Label>
                                            <Col sm={8} >
                                                <CustomSelect
                                                    options={getRelationType().filter(el => el.value !== 'Family')}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    value={getRelationType().find(el => el.value === this.state.originalRelationType)}
                                                    onChange={(e) => this.handleChangeSelect(e, "originalRelationType")}
                                                    isDisabled={this.state.originalRelationType === 'Family' || this.state.originalRelationType === 'Linked'}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label sm={4}> <FormattedMessage id="AssociatedProfiles.InverseRelationType" /></Label>
                                            <Col sm={8} >
                                                <CustomSelect
                                                    options={getRelationType().filter(el => el.value !== 'Family')}
                                                    required
                                                    isClearable
                                                    isSearchable
                                                    placeholder={""}
                                                    value={getRelationType().find(el => el.value === this.state.inverseRelationType)}
                                                    onChange={(e) => this.handleChangeSelect(e, "inverseRelationType")}
                                                    isDisabled={this.state.inverseRelationType === 'Family' || this.state.originalRelationType === 'Linked'}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            : ''}
                                                        
                            <Row className="mb-4 mt-2">
                                <Col>
                                    <b><FormattedMessage id="AssociatedProfiles.SelectedProfile" />: </b> {this.state.profile ? <span>{this.state.profile.firstName } {this.state.profile.lastName} </span> : <FormattedMessage id="AssociatedProfiles.NoProfileSelected" />}
                                </Col>
                            </Row>                            

                        </Form>
                        <Row>
                            <Col>
                                <SearchProfile
                                    fromAnotherPage={true}
                                    addProfile={this.selectProfile}
                                    pageSize={5}
                                    isMaster={this.state.isMaster}
                                    hotelId={this.state.hotelId}
                                    disableMasterFilter={this.state.disableMasterFilter}
                                    profileType={this.state.profileType}
                                    disableProfileType={this.state.disableProfileType}
                                />
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(AssociationModal)