import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import pt from 'react-intl/locale-data/pt';

import messages_en from '../components/Translations/en.json';
import messages_pt from '../components/Translations/pt.json';

import moment from 'moment';

addLocaleData([...en, ...pt]);

const messagesLang = {
    'en': messages_en,
    'pt': messages_pt
}

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.switchToLangEN = () => this.setState({ locale: 'en', messages: messages_en }, () => { localStorage.setItem("locale", 'en') });
        this.switchToLangPT = () => this.setState({ locale: 'pt', messages: messages_pt }, () => { localStorage.setItem("locale", 'pt') });
        this.currentProperty = (prop) => this.setState({ currentProperty: prop });
        this.state = {
            locale: 'en',
            messages: messages_en,
            switchToLangEN: this.switchToLangEN,
            switchToLangPT: this.switchToLangPT,
            currentProperty: this.currentProperty,
        }
    }

    componentDidMount() {
        var language = localStorage.getItem("locale");

        if (this.state.locale !== language) {
            if (!language) {
                const languageCode = navigator.language.split(/[-_]/)[0];
                language = languageCode === 'pt' ? 'pt' : 'en';
            };

            this.setState({ locale: language, messages: messagesLang[language] });
        };
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;

        moment.locale(locale)

        return (
            <Context.Provider value={this.state}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="en">
                    {children}
                </IntlProvider>
            </Context.Provider>    
        );
    }
}

export { IntlProviderWrapper, Context as IntlContext }
