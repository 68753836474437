import React, { Component } from 'react'
import { Button, Card, CardBody, Col, Row, Collapse, Input, Form } from 'reactstrap';
import { postAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { injectIntl, FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/CustomSelect';
import BlockUi from 'react-block-ui';

class DecryptMessages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            showDecryptMsg: false,
            decryptMessage: null,
            encryptedMessage: null,
            tag: null,
            tagOptions: [
                {label: "Profile", value: "Profile"},
                {label: "Profile-DataConsents", value: "Profile-DataConsents"},
                {label: "Profile-MembershipCard", value: "Profile-MembershipCard"},
                {label: "Reservation", value: "Reservation"},
                {label: "Revenue", value: "Revenue"},
                {label: "Profile-GuestJourney", value: "Profile-GuestJourney"},
                {label: "Profile-DepartmentInfo", value: "Profile-DepartmentInfo"},
                {label: "Profile-CompanyLinks", value: "Profile-CompanyLinks"},
                {label: "Profile-Relation", value: "Profile-Relation"},
                {label: "Profile-ProfessionalContact", value: "Profile-ProfessionalContact"},
                {label: "Profile-Family", value: "Profile-Family"},
                {label: "Profile-BillingInstructions", value: "Profile-BillingInstructions"},
                {label: "Segments", value: "Segments"},
                {label: "SubSegments", value: "SubSegments"},
                {label: "DistributionChannels", value: "DistributionChannels"},
                {label: "StatCodesValidation", value: "StatCodesValidation"},
                {label: "VipCodes", value: "VipCodes"},
                {label: "SocialNetwork", value: "SocialNetwork"},
                {label: "Sections", value: "Sections"},
                {label: "CancelReasons", value: "CancelReasons"},
                {label: "Charges", value: "Charges"},
                {label: "TaskSections", value: "TaskSections"},
                {label: "profile-pricelist", value: "profile-pricelist"},
            ]
        }
    }

    decryptMessage = (e, tag, encryptedData) => {
        e.preventDefault();
        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error, isLoading } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                    else if (data.response && data.response.length > 0) {
                        this.setState({ decryptMessage: data.response[0], showDecryptMsg: true, block: false });
                    }
                }
                else this.setState({ block: false });
            }, `/api/gms/SyncEvent/v1/DecryptMessage?tag=${tag}`, encryptedData);
        });
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const { encryptedMessage, decryptMessage, tag, tagOptions, showDecryptMsg, block } = this.state;
        return (
            <BlockUi tag="div" className='mt-4' blocking={block}>
                <Form onSubmit={(e) => this.decryptMessage(e, tag, encryptedMessage)}>
                    <Row>
                        <Col>
                            <h5>
                                <i className="fas fa-file-import mr-2"></i> <FormattedMessage id="DecryptMessage.DecryptedMessageTitle"/>
                            </h5>
                        </Col>
                        <Col>
                            <Button className="float-right btn btn-sm btn-host" type='submit'>
                                <i class="fas fa-file-import"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col className='col-4'>
                            <CustomSelect
                                className="select-host"
                                options={tagOptions}
                                placeholder={this.props.intl.formatMessage({ id: "DecryptMessage.Tag" })}
                                value={tagOptions.find(el => el.value === tag)}
                                onChange={this.handleChangeSelect.bind(this, 'tag')}
                                isSearchable={true}
                                required
                            />
                        </Col>
                        <Col>
                            <Input
                                className="input-host"
                                style={{padding: '8px', minHeight: '130px'}}
                                type="textarea"
                                placeholder={this.props.intl.formatMessage({ id: "DecryptMessage.EncryptedMessage" })}
                                value={encryptedMessage}
                                onChange={(e) => this.setState({ encryptedMessage: e.target.value })}
                                required
                            />
                        </Col>
                    </Row>
                </Form>
                {showDecryptMsg && decryptMessage &&
                    <Card>
                        <CardBody>
                            <Col>
                                <div className="float-right btn btn-sm btn-host"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        navigator.clipboard.writeText(JSON.stringify(decryptMessage));
                                        handleNotification('', <FormattedMessage id="DecryptMessage.CopyMessage" />);
                                    }}>
                                    <div>
                                        <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-11'>
                                <pre className="mb-0 mt-2">
                                    <code>
                                        {JSON.stringify(decryptMessage, null, 2)}
                                    </code>
                                </pre>
                            </Col>
                        </CardBody>
                    </Card>
                }
            </BlockUi>
        )
    }
};

export default injectIntl(DecryptMessages)
