import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Badge, Col, Row, CustomInput, UncontrolledTooltip } from 'reactstrap';
import { CheckIcon } from './MergeFunctions';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getISSOptions } from '../../Base/ReferenceDataFunctions';

class ProfileMainData extends Component {



    render() {
        const { profile, index, removeProfile, handleAllSwitches, handleSwitch, state } = this.props;
        const isLinked = !profile.isMaster;

        return (
            <Col className="col-6 border-right" style={{ display: 'inline-block', height: '100%' }}>
                <Row className="border-bottom mb-3 position-sticky" style={{ 'top': '5px', zIndex: '1000', height: '60px' }}>
                    <Col className="col-11 d-flex align-items-center pr-1 pointer mb-2" onClick={() => window.open("/ProfileDetails/" + profile.id, '_blank')}>
                        <Badge className="profileBadge mr-2">
                            {profile.firstName && profile.firstName.substr(0, 1).toUpperCase()}{profile.lastName && profile.lastName.substr(0, 1).toUpperCase()}
                        </Badge>
                        <div className=" text-ellipsis">
                            <b className="font-weight-bold m-0"> {profile.firstName} {profile.lastName}</b>
                            {isLinked && profile.searchableGuestNumber ? <span> ({profile.searchableGuestNumber}) </span> : ''}
                            {!profile.isMaster && profile.masterId === profile.id ? '' : profile.masterId && (state.master.id === undefined || state.master.id !== profile.masterId) ?
                                <>
                                    <i className="fas fa-sm fa-exclamation-triangle ml-2" style={{ color: '#ffc107' }} id={`hasMaster${profile.id}`} />

                                    <UncontrolledTooltip placement="bottom" target={`hasMaster${profile.id}`}>
                                        <FormattedMessage id="ProfileMergeDetail.AlreadyAssociatedWithMaster" /> {profile.masterId}
                                    </UncontrolledTooltip>
                                </>
                                : ''}

                            {isLinked ?
                                <div className="text-muted text-ellipsis" style={{ fontSize: '12px' }}>
                                    {profile.hotelName}
                                </div>
                            : ''}
                        </div>
                    </Col>
                    <Col className="col-1 text-right pl-1" >
                        <span onClick={() => removeProfile(index + 1, profile.id)} style={{ textDecoration: 'underline', cursor: 'pointer' }}> <i className="fas fa-trash-alt" /> </span>
                        <span onClick={() => handleAllSwitches(index + 1, profile, false)} style={{ color: 'blue', cursor: 'pointer', position: 'absolute', bottom: '0', right: '12px' }}>
                            <FormattedMessage id="ProfileMergeDetail.SelectAll" />
                        </span>
                    </Col>
                </Row>

                <Row className={"mb-1 overbglight border-bottom overflow-hidden "} style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'type' + (index + 1)}
                            type="switch"
                            name="type"
                            onChange={(e) => handleSwitch(e, profile.type)}
                            checked={state['type' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className={"col-10 d-flex align-items-center pl-4 " }>
                        <span> {profile.type} </span>
                    </div>
                    <CheckIcon checkIcon={profile.typeCheckIcon} />
                </Row>

                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'firstName' + (index + 1)}
                            type="switch"
                            name="firstName"
                            onChange={(e) => handleSwitch(e, profile.firstName)}
                            checked={state['firstName' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span> {profile.firstName} </span>
                    </div>
                    <CheckIcon checkIcon={profile.firstNameCheckIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'lastName' + (index + 1)}
                            type="switch"
                            name="lastName"
                            onChange={(e) => handleSwitch(e, profile.lastName)}
                            checked={state['lastName' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span> {profile.lastName} </span>
                    </div>
                    <CheckIcon checkIcon={profile.lastNameCheckIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span> {profile.fullName} </span>
                    </div>
                    <CheckIcon checkIcon={profile.fullNameCheckIcon} keyIcon={profile.fullNameKeyIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'middleName' + (index + 1)}
                            type="switch"
                            name="middleName"
                            onChange={(e) => handleSwitch(e, profile.middleName)}
                            checked={state['middleName' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span> {profile.middleName} </span>
                    </div>
                    <CheckIcon checkIcon={profile.middleNameCheckIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'gender' + (index + 1)}
                            type="switch"
                            name="gender"
                            onChange={(e) => handleSwitch(e, profile.gender)}
                            checked={state['gender' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span> {profile.gender} </span>
                    </div>
                    <CheckIcon checkIcon={profile.genderCheckIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'nationality' + (index + 1)}
                            type="switch"
                            name="nationality"
                            onChange={(e) => handleSwitch(e, profile.nationality)}
                            checked={state['nationality' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span> {profile.nationality} </span>
                    </div>
                    <CheckIcon checkIcon={profile.nationalityCheckIcon} keyIcon={profile.nationalityKeyIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'language' + (index + 1)}
                            type="switch"
                            name="language"
                            onChange={(e) => handleSwitch(e, profile.language)}
                            checked={state['language' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span> {profile.language} </span>
                    </div>
                    <CheckIcon checkIcon={profile.languageCheckIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'birthDate' + (index + 1)}
                            type="switch"
                            name="birthDate"
                            onChange={(e) => handleSwitch(e, profile.birthDate)}
                            checked={state['birthDate' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span>{profile.birthDate ? moment(profile.birthDate).format('YYYY-MM-DD') : ''} </span>
                    </div>
                    <CheckIcon checkIcon={profile.birthDateCheckIcon} keyIcon={profile.birthDateKeyIcon} />
                </Row>
                <Row className="mb-1 overbglight border-bottom overflow-hidden" style={{ height: '38px' }}>
                    <div className="col-1 d-flex align-items-center">
                        <CustomInput
                            id={'iss' + (index + 1)}
                            type="switch"
                            name="iss"
                            onChange={(e) => handleSwitch(e, profile.iss)}
                            checked={state['iss' + (index + 1)] ? true : false}
                        />
                    </div>
                    <div className="col-10 d-flex align-items-center pl-4">
                        <span>{profile.iss !== null ? getISSOptions(this.props.intl)[profile.iss]?.label : ''} </span>
                    </div>
                    <CheckIcon checkIcon={profile.issIcon} keyIcon={profile.issIcon} />
                </Row>
            </Col>
        );
    }
}
export default injectIntl(withRouter(ProfileMainData))