import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Input, FormGroup } from 'reactstrap';
import CustomSelect from '../../Base/CustomSelect';
import { getDealStatus } from '../SalesProcessFunctions';


class SalesDealStep1 extends Component {

    render() {
        const { handleChange, handleSelect, sources, intl, deal, stages, salesPipelines, editableSalesPipeline } = this.props;
        
        return (
            <div>
                <FormGroup row>
                    <Col>
                        <div className="title-sm required"> <FormattedMessage id="generic.name" /></div>
                        <Input
                            required
                            type="text"
                            name="name"
                            placeholder=""
                            value={deal.name || ''}
                            onChange={handleChange}
                            maxLength={100}
                        />
                    </Col>
                    <Col>
                        <div className="title-sm required"> <FormattedMessage id="generic.status" /></div>
                        <CustomSelect
                            required
                            name="type"
                            placeholder=""
                            isClearable={false}
                            isSearchable={false}
                            options={getDealStatus(intl)}
                            value={getDealStatus(intl).find(({ value }) => deal.status === value) || ''}
                            onChange={(combo) => handleSelect('status', combo)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col>
                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.Summary" /></div>
                        <Input
                            required
                            type="text"
                            name="summary"
                            placeholder=""
                            value={deal.summary || ''}
                            onChange={handleChange}
                            maxLength={100}
                        />
                    </Col>
                    <Col>
                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.Source" /></div>
                        <CustomSelect
                            name="salesSourceId"
                            placeholder=""
                            isClearable={true}
                            isSearchable={true}
                            options={sources}
                            value={sources?.find(({ value }) => deal.salesSourceId === value) || ''}
                            onChange={(combo) => handleSelect('salesSourceId', combo)}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col className='col-6'>
                        <div className="title-sm required"> <FormattedMessage id="SalesDeal.Pipelines" /></div>
                        <CustomSelect
                            name="salesPipelineId"
                            placeholder=""
                            isClearable={true}
                            isSearchable={true}
                            options={salesPipelines}
                            value={salesPipelines?.find(({ value }) => deal.salesPipelineId === value) || ''}
                            onChange={(combo) => handleSelect('salesPipelineId', combo)}
                            isDisabled={!editableSalesPipeline}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col>
                        <div className="title-sm"> <FormattedMessage id="SalesDeal.Stage" /></div>

                        <div className='mt-1 h-100 d-flex w-100'>
                            {stages?.filter(({salesPipelineId}) => salesPipelineId === deal.salesPipelineId).map((stage, key) => {
                                const isSelected = stage.value === deal.salesPipelineStageId;

                                return (
                                    <div key={key} className='h-100 pointer' style={{ width: '100%' }} onClick={_ => handleChange({ target: { name: 'salesPipelineStageId', value: stage.value } })}>
                                        <div className='text-center pipeline smallPipeline' style={{ height: '50px' }}>
                                            <div className={`arrow top smallPipeline noShadow ${isSelected ? 'selectedStage' : ''}`} style={{ height: '25px' }}></div>
                                            <div className={'pipelineName ' + (isSelected ? ' color-white ' : '')}>
                                                <span>{stage.label}</span>
                                            </div>
                                            <div className={`arrow bottom noShadow ${isSelected ? 'selectedStage' : ''}`} ></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </FormGroup>

                {deal.status === 'Cancelled' ?
                    <FormGroup row>
                        <Col>
                            <div className="title-sm required"> <FormattedMessage id="SalesDeal.CancellationReason" /></div>
                            <Input
                                required
                                type="textarea"
                                name="statusReason"
                                placeholder=""
                                value={deal.statusReason || ''}
                                onChange={handleChange}
                                maxLength={100}
                            />
                        </Col>
                    </FormGroup>
                : ''}
            </div>
        )
    }
}

export default injectIntl(SalesDealStep1);