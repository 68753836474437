import React, { Component } from 'react'
import { Model } from "survey-core";
import { Converter } from "showdown";
import { Survey } from "survey-react-ui";
import { Col, Row } from 'reactstrap';
import "./DefaultForm.css";

export default class SurveyPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false
        };
    }
    
    render() {
        const { formData, css, hotelLogo, hotelName, activeCountryTab } = this.props;
        
        const survey = new Model(formData);

        survey.focusFirstQuestionAutomatic = false;

        survey.locale = activeCountryTab;

        const converter = new Converter();
        survey.onTextMarkdown.add(function (survey, options) {
            if(!options || !options.text) return;
            let str = converter.makeHtml(options.text);
            if(str){
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
            }
            options.html = str; 
        });

        return (
            <div id='formComponent'>
                <style>
                    {css}
                </style>
                {hotelLogo || hotelName ?
                    <Row className='surveyHeader'>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            {hotelLogo ?
                                <img src={hotelLogo} style={{ maxWidth: '70%', height: 'auto', maxHeight: '70px' }} alt="Logo" className='mr-4'/>
                            :''}
                            {hotelName ?
                                <div className='hotelName'>
                                    <h4>{hotelName}</h4>
                                </div>
                            :''}
                        </Col>
                    </Row>
                :''}
                <Survey model={survey} />
            </div>
        );
    }
}