import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { deepClone, getNextColor } from './ReferenceDataFunctions';
import { default as ChartGoogle } from "react-google-charts";


export const ChartLegend = ({ label, color, borderColor, isVisible = true, toggleIsVisible }) => {

    return <div className="mb-1 pl-2" onClick={toggleIsVisible}
        style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '12px', fontStyle: 'normal', color: '#6f6f6f', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        <div className="align-items-center" style={{ display: 'inline-flex' }} title={label}>
            <div style={{ width: '35px', height: '9.5px', backgroundColor: color, border: `2px solid ${borderColor || color}` }} className="mr-2" />
            <span style={isVisible ? {} : { textDecoration: 'line-through', textDecorationThickness: '2px' }}>
                {label}
            </span>
        </div>
    </div>
}

export const RenderLegend = ({ legend, toggleIsVisible }) => {
    return legend ?
        legend.map((leg, index) =>
            leg.groupedLegend ?
                <div key={index}>
                    <div style={{ fontSize: '12px', color: '#6f6f6f' }}>
                        {leg.label}
                    </div>
                    <div>
                        {leg.groupedLegend.map((group, key) =>
                            <ChartLegend
                                key={`${leg.label}-${key}`}
                                color={group.color || '#e5e5e5'}
                                isVisible={group.isVisible}
                                label={group.label || 'N/A'}
                                toggleIsVisible={() => toggleIsVisible(group)}
                            />
                        )}
                    </div>
                </div>
            :
                <ChartLegend
                    key={index}
                    color={getNextColor(index)}
                    label={leg.label || 'N/A'}
                    isVisible={leg.isVisible}
                    toggleIsVisible={() => toggleIsVisible(leg)}
                />
        )
    : ''
}


//Only supports Pie or Doughnut charts yet
export const ChartWithCustomLegend = ({ data, groupedLegend = null, type, height, options = null, legendPlacement = "right", colSize = null }) => {
    const [chartData, setChartData] = useState(null);
    const [legend, setLegend] = useState(null);


    useEffect(() => {
        const clonedData = deepClone(data);

        if (groupedLegend) {
            setLegend(groupedLegend);
        }
        else {
            const chartLegend = clonedData.labels.map(label => (
                { label, isVisible: true }
            ))

            setLegend(chartLegend);
        }

        setChartData(clonedData)
    }, [data, groupedLegend]);



    const toggleIsVisible = (label) => {
        const { updatedLegend, updatedData } = handleIsVisible(label, legend, chartData, data);

        setChartData(updatedData);
        setLegend(updatedLegend);
    }



    return <Row className={"align-items-center h-100 " + (legendPlacement === "left" ? ' flex-row-reverse' : '')}>
        <Col sm={colSize ? colSize.chart : 5} className="p-0 d-flex align-items-center pr-2">
            {chartData &&
                <Doughnut data={chartData} height={height} options={options || getPieOptions()} />
            }
        </Col>
        <Col sm={colSize ? colSize.legend : 7} className={"d-flex justify-content-center flex-column " + (legendPlacement === "left" ? " pr-0 " : " pl-0 ")} style={{ cursor: 'default', height: '80%' }}>
            <div className="verticalScroll" style={{}}>
                <RenderLegend
                    legend={legend}
                    toggleIsVisible={toggleIsVisible}
                />
            </div>
        </Col>
    </Row>
}

export function GeoChart({ data, big, setFilters, label }){
    const labelData = label || data;

    return(
        <Row>
            <Col className="col-10" style={{ maxHeight: big ? '' : '380px', overflow: 'hidden' }}>
                <ChartGoogle
                    className='GeoChartHomePage'
                    width='100%'
                    height='auto'
                    chartType="GeoChart"
                    data={data}
                    options={{
                        colorAxis: { colors: ['#d6edfe', '#0867fa'] }
                    }}
                />
            </Col>

            <Col className="col-2 verticalScroll" style={{ maxHeight: '330px', overflow: 'auto' }}>
                {labelData && labelData.sort((a, b) => b[1] - a[1]).map((arr, key) => key > 0 ?
                    <Row className="mb-2 text-right" key={key} style={{ cursor: 'pointer' }}>
                        <Col onClick={() => arr[0] && setFilters('nationality', arr[0])} className="px-0 text-right">
                            {arr[0] ?
                                <span style={{ borderRadius: '3px', fontSize: '18px' }} className={' mr-1 fa-md flag-icon flag-icon-' + arr[0].toLowerCase()} />
                            : <b className='mr-2' > NA </b>}
                        </Col>
                        <Col className="px-2 col-5 text-left">
                            <span > {arr[1]} </span>
                        </Col>
                    </Row>
                    : '')}
            </Col>
        </Row>
    );
}


//#region chart options
export function getPieOptions() {
    return {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '60%',
        plugins: {
            datalabels: { display: false },
            legend: {
                display: false
            }
        }
    };
}
//#endregion



//#region aux functions
function handleIsVisible(label, legend, chartData, data) {
    const isGroupedLegend = legend && legend[0].groupedLegend;
    const updatedData = { ...chartData };
    const updatedLegend = [...legend];


    if (isGroupedLegend) {
        let groupLegend = -1, legendIndex = -1;

        for (var i = 0; i < legend.length; i++) {
            const lgIndex = [...legend][i].groupedLegend.findIndex(leg => leg.label === label.label);

            if (lgIndex !== -1) {
                groupLegend = i;
                legendIndex = lgIndex;

                break;
            }
        }

        if (groupLegend !== -1 && legendIndex !== -1) {
            const dataIndex = [...legend].flatMap(gl => gl.groupedLegend).findIndex(leg => leg.label === label.label);

            if (dataIndex !== -1) {
                //updatedData
                if (legend[groupLegend].groupedLegend[legendIndex].isVisible) {
                    updatedData.datasets[0].data[dataIndex] = 0; //Fill data with 0 to hide label in chart
                }
                else {
                    updatedData.datasets[0].data[dataIndex] = data.datasets[0].data[dataIndex];
                }
            }


            //updatedLegend
            updatedLegend[groupLegend].groupedLegend[legendIndex] = {
                ...updatedLegend[groupLegend].groupedLegend[legendIndex],
                isVisible: !updatedLegend[groupLegend].groupedLegend[legendIndex].isVisible
            };
        }
    }
    else {
        const legendIndex = [...legend].findIndex(leg => leg.label === label.label);

        if (legendIndex !== -1) {
            //updatedData
            if (legend[legendIndex].isVisible) {
                updatedData.datasets[0].data[legendIndex] = 0; //Fill data with 0 to hide label in chart
            }
            else {
                updatedData.datasets[0].data[legendIndex] = data.datasets[0].data[legendIndex];
            }


            //updatedLegend
            updatedLegend[legendIndex] = { ...updatedLegend[legendIndex], isVisible: !updatedLegend[legendIndex].isVisible };
        }
    }



    return { updatedLegend, updatedData }
}
//#endregion