import React, { Component } from 'react';
import { Badge, Col, Row } from 'reactstrap';

export class CampaignExcludedTags extends Component {

    render() {
        const { handleExcludedTag, filters, tagList } = this.props;

        return (
            <Row>
                <Col>
                    <div className="mt-5 scrollableDiv" style={{ height: '90%' }}>
                        {tagList && tagList.map((el, k) =>
                            <Row className="mt-2 pl-1" key={k}>
                                <Col className="col-2"> {el.code}:  </Col>
                                <Col className="d-flex col-10">
                                    {
                                        el.tag && el.tag.map((tag, k1) => {
                                            const tagFilter = filters.excludedTags && filters.excludedTags.find(t => t.id === tag.id);
                                            const isSelected = tagFilter !== undefined;

                                            return (
                                                <span key={k1}>
                                                    <Badge className=" my-1 mr-2 d-flex align-items-center" color={isSelected ? "primary" : "secondary"} style={{ cursor: 'pointer', height: '30px' }}>
                                                        <span onClick={() => handleExcludedTag(tag)}>{tag.code} </span>
                                                    </Badge>
                                                </span>
                                            );
                                        })
                                    }
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }
}