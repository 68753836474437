import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import DashboardCard from '../../Base/DashboardCard';
import Chart from "react-google-charts";
import { getReviewFields } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';

export class FormsAndReviews extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            blockForms: false,
            fromDate: moment().subtract(15, 'days'),
            toDate: moment(),
            formsByDay: { "datasets": [], "labels": [] },
            formsByCountry: [["Country", "Total"]]
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        var dates = Array(moment(this.state.toDate).diff(moment(this.state.fromDate), 'days')).fill(undefined)
            .reduce((acc, cur) => {
                acc.push(moment(this.state.fromDate).add(acc.length, 'days').format('YYYY-MM-DD'));

                return acc;
            }, [moment(this.state.fromDate).format('YYYY-MM-DD')]);



        this.getFormStatistics(dates);
        this.getReviewStatistics(dates);
    }

    getFormStatistics = (dates) => {
        this.setState({ blockForms: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockForms: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                let datasets = [];

                if (global.modules && global.modules.includes("ProfileNowForms")) {
                    datasets.push({
                        label: 'PN Forms',
                        data: new Array(dates.length).fill(0),
                        borderColor: '#0665ff',
                        backgroundColor: '#0665ff',
                    });
                }

                if (global.modules && global.modules.includes("TypeForm")) {
                    datasets.push({
                        label: 'Typeform',
                        data: new Array(dates.length).fill(0),
                        borderColor: 'black',
                        backgroundColor: 'black',
                    });
                }

                if (global.modules && global.modules.includes("ReviewPro")) {
                    datasets.push({
                        label: 'Review Pro',
                        data: new Array(dates.length).fill(0),
                        borderColor: '#459b3c',
                        backgroundColor: '#459b3c',
                    });
                }

                if (global.modules && global.modules.includes("Egoi")) {
                    datasets.push({
                        label: 'Egoi',
                        data: new Array(dates.length).fill(0),
                        borderColor: '#04aad4',
                        backgroundColor: '#04aad4',
                    });
                }

                const formsByDay = {
                    labels: dates,
                    datasets: datasets,
                }

                let formsByCountry = [["Country", "Total"]];

                data.response && data.response.forEach(day => {
                    const index = dates.findIndex(el => moment(day.date).format('YYYY-MM-DD') === el);

                    day.nationality.forEach(country => {
                        const countryIndex = formsByCountry.findIndex(el => el[0] === country.nationality);

                        if (countryIndex !== -1) {
                            formsByCountry[countryIndex][1] += country.received;
                        }
                        else {
                            const arr = [country.nationality, country.received];
                            formsByCountry.push(arr);
                        }


                        if (index !== -1) {
                            formsByDay.datasets[0].data[index] += country.source.egoi ?? 0;
                            formsByDay.datasets[1].data[index] += country.source.reviewPro ?? 0;
                            formsByDay.datasets[2].data[index] += country.source.pnForm ?? 0;
                            formsByDay.datasets[3].data[index] += country.source.typeForm ?? 0;
                        }
                    })

                })

                this.setState({ formsByDay, formsByCountry, blockForms: false });
            }
        }, `/api/gms/FormExternal/v1/statistics?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}&byNationality=true`);
    }

    getReviewStatistics = (dates) => {
        this.setState({ blockReviews: true });

        const params = "&summary=true&summaryPopulation=true&summaryResponseRate=true&summaryMaxMin=true&dailyPopulation=true&dailyResponseRate=true&dailyMaxMin=true&showNullItems=true";

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockReviews: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.reviewCharts(data.response, dates);
            }
        }, `/api/gms/Review/v1/statistics/v2?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}` + params);
    }

    reviewCharts = (data, dates) => {
        const answersPerDay = {
            labels: dates,
            datasets: [
                {
                    label: this.props.intl.formatMessage({ id: "FormsAndReviews.Answers" }),
                    data: new Array(dates.length).fill(0),
                    borderColor: '#0665ff',
                    backgroundColor: '#0665ff',
                }
            ],
        }

        let avgReviews = getReviewFields().map(el => ({
            review: el.value.charAt(0).toLowerCase() + el.value.slice(1),
            label: el.label,
            average: 0
        }))


        if (data && data.length > 0) {

            //daily
            data[0].daily.forEach(day => {
                const index = dates.findIndex(el => moment(day.date).format('YYYY-MM-DD') === el);

                if (index) {
                    answersPerDay.datasets[0].data[index] = day.stats.received;
                }
            })

            //summary
            avgReviews.forEach(review => {
                if (data[0].summary.stats[review.review]) {
                    Object.assign(review, data[0].summary.stats[review.review])
                }
            })

            avgReviews.sort((a, b) => { return b.average - a.average });
        }


        this.setState({ answersPerDay, avgReviews, blockReviews: false });
    }

    render() {
        const { blockForms, blockReviews, error, formsByDay, formsByCountry, answersPerDay, avgReviews } = this.state;

        let options = {
            plugins: {
                datalabels: { display: false },
                legend: {
                    position: "top"
                }
            },
            scales: {
                x: {
                    display: true
                },
                y: {
                    beginAtZero: true,
                    min: 0,
                    grid: { display: false },
                }
            },
        }

        return (
            <StyledCard icon={'fas fa-user-edit'} title={'NavMenu.FormsAndReviews'} error={error} block={blockForms || blockReviews}>
                <Row className="my-3">
                    <Col>
                        <DateRangePicker
                            startDate={this.state.fromDate}
                            startDateId="fromDate"
                            isOutsideRange={day => day > moment()}
                            endDate={this.state.toDate}
                            endDateId="toDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>

                    <Col className="col-1 text-right pt-2 mr-3">
                        <Button className=" btn btn-host btn-sm" type="submit" onClick={this.getData}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col sm={6}>
                        <DashboardCard header="FormsAndReviews.FormsPerDay" >
                            {formsByDay ?
                                <div className="w-100">
                                    <Line data={formsByDay} height={100} options={options} />
                                </div>
                                : ''}
                        </DashboardCard>
                    </Col>
                    <Col sm={6}>
                        <DashboardCard header="FormsAndReviews.FormsByCountry" >
                            <Row>
                                <Col className="col-10" style={{ maxHeight: '330px', overflow: 'hidden' }}>
                                    <Chart
                                        className='CustomChart'
                                        width='100%'
                                        height='auto'
                                        chartType="GeoChart"
                                        data={formsByCountry}
                                        options={{
                                            colorAxis: { colors: ['#d6edfe', '#0867fa'] }
                                        }}
                                    />
                                </Col>

                                <Col style={{ maxHeight: '330px', overflow: 'auto' }} className="col-2">
                                    {formsByCountry && formsByCountry.sort((a, b) => b[1] - a[1]).map((arr, key) => key > 0 ?
                                        <Row className="mb-2 text-right" key={key} style={{ cursor: 'pointer' }}>
                                            <Col onClick={() => arr[0] && this.setFilters('nationality', arr[0])} className="px-0 text-right">
                                                {arr[0] !== 'N/A' ?
                                                    <span style={{ borderRadius: '3px', fontSize: '18px' }} className={' mr-1 fa-md flag-icon flag-icon-' + arr[0].toLowerCase()} />
                                                    : <b className='mr-2' > NA </b>}
                                            </Col>
                                            <Col className="px-2 col-5 text-left">
                                                <span > {arr[1]} </span>
                                            </Col>
                                        </Row>
                                        : '')}
                                </Col>
                            </Row>
                        </DashboardCard>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <DashboardCard header="FormsAndReviews.ReviewsPerDay" >
                            {answersPerDay ?
                                <div className="w-100">
                                    <Line data={answersPerDay} height={100} options={options} />
                                </div>
                                : ''}
                        </DashboardCard>
                    </Col>
                    <Col sm={6}>
                        <DashboardCard header="FormsAndReviews.AvgReviews" >
                            <Row className="text-muted pr-3">
                                <Col className="mb-2" style={{ flex: '0 0 38%', maxWidth: '38%' }}>
                                    <FormattedMessage id="FormsAndReviews.Review" />
                                </Col>
                                <Col className="px-2 text-center">
                                    <FormattedMessage id="FormsAndReviews.Answers" />
                                </Col>
                                <Col className="px-2 text-center">
                                    <FormattedMessage id="FormsAndReviews.Min" />
                                </Col>
                                <Col className="px-2 text-center">
                                    <FormattedMessage id="FormsAndReviews.Max" />
                                </Col>
                                <Col className="px-2 text-center">
                                    <FormattedMessage id="FormsAndReviews.Avg" />
                                </Col>
                            </Row>
                            <div className="verticalScroll pr-2" style={{ height: '260px', overflowX: 'hidden', overflowY: 'auto' }}>
                                {avgReviews?.map((review, idx) =>
                                    <Row key={idx} className="py-2 overbglight">
                                        <Col style={{ flex: '0 0 38%', maxWidth: '38%' }}>
                                            <b> {review.label} </b>
                                        </Col>
                                        <Col className="px-2 text-center">{review.population || 0}  </Col>
                                        <Col className="px-2 text-center">{review.min || 0} </Col>
                                        <Col className="px-2 text-center">{review.max || 0} </Col>
                                        <Col className="px-2 text-center">
                                            {review.average ? parseFloat(review.average).toFixed(2) : 0}
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        </DashboardCard>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(FormsAndReviews);