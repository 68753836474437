import React, { Component } from 'react';
import { Button, Col, Input, Modal, ModalBody, Row } from 'reactstrap';
import { FormattedMessage } from "react-intl";
import { BlankCard } from '../../Base/CommonUIComponents';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import { postAPI } from '../../Base/API';

export class SendGridEmailModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            senderEmail: null,
            senderName: null,
            email: null
        };
    }

    handleSelect = (combo) => {
        if (combo) {
            this.setState({ senderEmail: combo.value, senderName: combo.name });
        }
        else {
            this.setState({ senderEmail: null, senderName: null });
        }
    }

    handleChange = (evt) => {
        if (evt) {
            const { value } = evt.target;
            this.setState({ email: value });
        }
    }

    sendMessage = () => {
        const { templateId } = this.props;
        const { senderEmail, senderName, email } = this.state;
        if (!this.form.current.reportValidity()) {
            return;
        }

        const request = {
            templateId: templateId,
            senderName: senderName,
            senderEmail: senderEmail,
            email: email
        };

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendEmailModal.MessageSentWithSuccess" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SendGrid/v1/email/send`, request);
    }

    render() {
        const { isOpen, senders, templateName, toggle } = this.props;
        const { block, error } = this.state;

        return (
            <Modal isOpen={isOpen} fade={false} size={"lg"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-3">
                                <Col className="col-9 pl-1">
                                    <h5>{templateName}</h5>
                                </Col>
                                <Col className="text-right col-3">
                                    <Button className="btn btn-host btn-sm" onClick={this.sendMessage}>
                                        <FormattedMessage id="ProfileDetails.SendEmail" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col className="col-2 px-1">
                                    <FormattedMessage id="SendEmailModal.Sender" />
                                </Col>
                                <Col className="col-10">
                                    <CustomSelect
                                        name="from"
                                        options={senders}
                                        onChange={this.handleSelect}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col className="col-2 px-1">
                                    <FormattedMessage id="SendEmailModal.To" />
                                </Col>
                                <Col className="col-10">
                                    <Input
                                        type="email"
                                        name='to'
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}