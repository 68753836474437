import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, Input, Label, Modal, ModalBody, Row } from 'reactstrap';
import CustomSelect from "../../../Base/CustomSelect";
import { CommonHelper, StyledCard } from '../../../Base/CommonUIComponents';

class GoogleAnalyticsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enabled: this.props.analyticsObj.enabled,
            utm_campaign: this.props.analyticsObj.utm_campaign,
            utm_content: this.props.analyticsObj.utm_content,
            utm_medium: this.props.analyticsObj.utm_medium,
            utm_source: this.props.analyticsObj.utm_source,
            utm_term: this.props.analyticsObj.utm_term,
            combo: [{ value: true, label: <FormattedMessage id="SendGrid.Yes" />}, {value: false, label: <FormattedMessage id="SendGrid.No" /> }],
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeComboSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    saveConfigState = () => {
        this.props.toggle()
        this.props.updateAnalyticsObjStatus(this.state.enabled, this.state.utm_campaign, 
            this.state.utm_content, this.state.utm_medium, this.state.utm_source, this.state.utm_term)
    }

    render() {
        const { enabled, utm_campaign, utm_content, utm_medium, utm_source, utm_term, combo } = this.state;
        const { toggle } = this.props;
        return (
            <Modal isOpen={true} toggle={toggle} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"/>
                </Button>
                <ModalBody>
                    <StyledCard>
                        <Form onSubmit={this.saveConfigState}>
                            <Row>
                                <Col>
                                    <h5 className='m-0'><FormattedMessage id="SendGrid.GoogleTitle"/></h5>
                                </Col>
                                <Col className='col-2 text-right'>
                                    <Button className=" btn btn-host btn-sm">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <div className='mt-4'>
                                <Row>
                                    <Col className='col-6 mt-3'>
                                        <Label className='text-muted'><FormattedMessage id="SendGrid.GoogleState"/></Label>
                                        <CustomSelect
                                            options={combo}
                                            required
                                            value={combo.find(el => el.value === enabled)}
                                            onChange={this.handleChangeComboSelect.bind(this, "enabled")}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.GoogleSource" /></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.GoogleSourceHelp" />} id={'GoogleSourceHelp'} /></Label>
                                        </div>
                                        <Input 
                                            type="text"
                                            required
                                            name="utm_source"
                                            value={utm_source}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.GoogleMedium" /></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.GoogleMediumHelp" />} id={'GoogleMediumHelp'} /></Label>
                                        </div>
                                        <Input 
                                            type="text"
                                            required
                                            name="utm_medium"
                                            value={utm_medium}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.GoogleTerm" /></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.GoogleTermHelp" />} id={'GoogleTermHelp'} /></Label>
                                        </div>
                                        <Input 
                                            type="text"
                                            required
                                            name="utm_term"
                                            value={ utm_term }
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.GoogleContent" /></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.GoogleContentHelp" />} id={'GoogleContentHelp'} /></Label>
                                        </div>
                                        <Input 
                                            type="text"
                                            required
                                            name="utm_content"
                                            value={utm_content}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='d-flex align-items-center'>
                                            <Label className='text-muted'><FormattedMessage id="SendGrid.GoogleName" /></Label>
                                            <Label className='text-muted'><CommonHelper placement="right" help={<FormattedMessage id="SendGrid.GoogleNameHelp" />} id={'GoogleNameHelp'} /></Label>
                                        </div>
                                        <Input 
                                            type="text"
                                            required
                                            name="utm_campaign"
                                            value={utm_campaign}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </StyledCard>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(GoogleAnalyticsModal)
