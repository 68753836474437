import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalBody, Row, Col, Button, CardBody } from 'reactstrap'
import { getAPI } from '../../Base/API';
import { handleNotification } from "../../Base/Notification";
import moment from 'moment'
import BlockUi from 'react-block-ui';

class ActivityDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: false,
            activityData: null,
            templateName: null,
        };
    }

    componentDidMount(){
        this.getSendGridActivityObjDetail();
    }

    getSendGridActivityObjDetail = () => {
        const { selectedObjId } = this.props;
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response) {
                    this.setState({ activityData: data.response }, () => this.getSendGridActivityTemplatename());
                }else{
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SendGrid/v1/messages/${selectedObjId}`);
        });
    }

    getSendGridActivityTemplatename = () => {
        const { activityData } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                handleNotification(data);
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                this.setState({ templateName: data.response.name, block: false });
            }else{
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/SendGrid/v1/templates/${activityData.template_id}`);
    }

    

    render() {
        const { block, error, activityData, templateName } = this.state;
        const { modal } = this.props;
        
        return (
            
            <Modal isOpen={modal} toggle={() => this.props.toggleModal} fade={false} size={"lg"} style={{ minWidth: 0, marginTop: '10%' }}>
                <Button onClick={this.props.toggleModal(null)} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                
                <ModalBody style={{minHeight: '200px'}}>
                    <BlockUi tag="div" blocking={block}>
                        <CardBody error={error} className='activityDetailCard'>
                            <Row className='mb-4'>
                                <Col>
                                    <h5>
                                        <FormattedMessage id="SendGridActivity.EmailInformation" />
                                    </h5>
                                </Col>
                            </Row>
                            
                            <Row className='mb-3 align-items-center'>
                                {activityData && activityData.to_email && !block ?
                                    <Col className='d-flex flex-column'>
                                        <span className='font-weight-bold'><FormattedMessage id="SendGridActivity.To" /></span>
                                        <span> {activityData.to_email} </span>
                                    </Col>
                                : ''}
                                {activityData && activityData.from_email && !block ?
                                    <Col className='d-flex flex-column'>
                                        <span className='font-weight-bold'><FormattedMessage id="SendGridActivity.From" /></span>
                                        <span> {activityData.from_email} </span>
                                    </Col>
                                : ''}
                            </Row>

                            <Row className='mb-5 align-items-center'>
                                {activityData && activityData.subject && !block ?
                                    <Col className='d-flex flex-column'>
                                        <span className='font-weight-bold'><FormattedMessage id="SendGridActivity.Subject" /></span>
                                        <span> {activityData.subject} </span>
                                    </Col>
                                : ''}
                                {activityData && (activityData.templateName || activityData.template_id) && !block ?
                                    <Col className='d-flex flex-column'>
                                        <span className='font-weight-bold'><FormattedMessage id="SendGridActivity.TransactionalTemplate" /></span>
                                        <div className='d-flex flex-column align-items-start justify-content-center'>
                                            <span> {templateName} </span>
                                            <div>
                                                <span className='pr-1'> <FormattedMessage id="SendGridActivity.Id" className='pr-1' /> </span>
                                                <span> {activityData.template_id} </span>
                                            </div>
                                        </div>
                                    </Col>
                                : ''}
                            </Row>

                            {activityData && activityData.events && activityData.events.length > 0 && !block ?
                                <Row>
                                    <Col className='col-12 d-flex align-items-center mb-2'>
                                        <span className='font-weight-bold'><FormattedMessage id="SendGridActivity.EventHistory" /></span>
                                    </Col>
                                </Row>
                            : ''}

                            <div className='verticalScroll' style={{maxHeight: '300px', overflow: 'auto', overflowX: 'hidden'}}>
                                {activityData && activityData.events && activityData.events.length > 0 && !block ?
                                    activityData.events.map((event, index) =>
                                        <div key={index}>
                                            <Row>
                                                <Col >
                                                    <span className='text-muted'>
                                                        {(event.event_name === 'processed' || event.event_name === 'delivered') ?
                                                            <i className="fas fa-check-circle text-success mx-1" />
                                                        : event.event_name === 'open' ?
                                                            <i class="fas fa-arrow-circle-right text-primary mx-1"></i>
                                                        : event.event_name === 'click' ?
                                                            <span className='iconCircle'><i className="iconStyle fas fa-mouse-pointer text-light ml-2 fa-xs"></i></span>
                                                        :event.event_name === 'deferred' || event.event_name === 'spam_report' ?
                                                            <i className="fas fa-exclamation-circle text-warning mx-1" />
                                                        :  
                                                            <i className="fas fa-times-circle text-danger mx-1" />}
                                                        <FormattedMessage id={`SendGridActivity.${event.event_name}`}  />
                                                    </span>
                                                </Col>
                                                <Col className='text-right'>
                                                    {moment(event.processed).format('DD/MM/YYYY hh:mm')}
                                                </Col>
                                                
                                            </Row>
                                            {index < activityData.events.length - 1 && (
                                                <div className='vertical-line'></div>
                                            )}
                                        </div>
                                    )
                                : ''}
                            </div>
                                
                        </CardBody>
                    </BlockUi>
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(ActivityDetail);