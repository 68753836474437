import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { Card, Col, Row } from 'reactstrap';
import Chart from 'react-google-charts';

class SalesDealProposalStats extends Component {

    render() {
        const { filters } = this.props;
        
        return (
            <div>
                <div>
                    <div className='d-flex align-items-center justify-content-between text-muted'>
                        <div style={{ fontSize: '1.1em' }}>
                            <i className="fas fa-bed mr-2"/>
                            <FormattedMessage id="SalesDeal.Rooms"/>
                        </div>
                        <div style={{ fontSize: '1.4em' }}>
                            <FormatAmountNumber value={3000}/>
                        </div>
                    </div>
                    <Row className='mt-3'>
                        <Col className='col-6'>
                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.DailyPricePerson"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            <FormatAmountNumber value={163}/>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Day", "Price" ],
                                                ["17/09", 1000 ],
                                                ["18/09", 1170 ],
                                                ["19/09", 660 ],
                                                ["20/09", 1030 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                                chart: {
                                                //   title: "Company Performance",
                                                //   subtitle: "Sales, Expenses, and Profit: 2014-2017",
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className='col-6'>
                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.PriceRoom"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            <FormatAmountNumber value={378}/>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Category", "Price" ],
                                                ["Cat 1", 400 ],
                                                ["Cat 2", 460 ],
                                                ["Cat 3", 1120 ],
                                                ["Cat 4", 540 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                                chart: {
                                                //   title: "Company Performance",
                                                //   subtitle: "Sales, Expenses, and Profit: 2014-2017",
                                                },
                                            }}
                                        />                                                                    
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className='mt-4'>
                    <div className='d-flex align-items-center justify-content-between text-muted'>
                        <div style={{ fontSize: '1.1em' }}>
                            <i className="fas fa-theater-masks mr-2"/>
                            <FormattedMessage id="SalesDeal.Events"/>
                        </div>
                        <div style={{ fontSize: '1.4em' }}>
                            <FormatAmountNumber value={3000}/>
                        </div>
                    </div>
                    <Row className='mt-3 pb-3'>
                        <Col className='col-6'>
                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.DailyPricePerson"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            <FormatAmountNumber value={103}/>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Day", "Price"],
                                                ["17/09", 1000 ],
                                                ["18/09", 1170 ],
                                                ["19/09", 660 ],
                                                ["20/09", 1030 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                                chart: {
                                                //   title: "Company Performance",
                                                //   subtitle: "Sales, Expenses, and Profit: 2014-2017",
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col className='col-6'>
                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                <div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='text-muted'>
                                            <i className="fas fa-chart-line mr-2"/>
                                            <FormattedMessage id="SalesDeal.PriceSpace"/>
                                        </div>
                                        <div style={{ fontSize: '1.4em' }}>
                                            <FormatAmountNumber value={5020}/>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <Chart
                                            chartType="Bar"
                                            width="100%"
                                            height="300px"
                                            data={[
                                                ["Space", "Price" ],
                                                ["Space 1", 200 ],
                                                ["Space 2", 250 ],
                                                ["Space 3", 300 ],
                                                ["Space 4", 350 ],
                                            ]}
                                            options={{
                                                legend: {position: 'none'},
                                                chart: {
                                                //   title: "Company Performance",
                                                //   subtitle: "Sales, Expenses, and Profit: 2014-2017",
                                                },
                                            }}
                                        />                                                                    
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default SalesDealProposalStats;
