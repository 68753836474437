import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, UncontrolledCollapse } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import { CustomTable } from '../../Base/CustomTable';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { DateRangePicker } from 'react-dates';
import { Doughnut, Bar } from 'react-chartjs-2';
import Chart from "react-google-charts";
import { getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { FormatAmountNumber, SelectHotelWithLoadedData } from '../../Base/CommonUIComponents';


const getColumns = (intl) => {

    return [
        {
            dataField: 'complexCode',
            text: intl.formatMessage({ id: "ProfileRevenue.ComplexCode" })
        },
        {
            dataField: 'code',
            text: intl.formatMessage({ id: "ProfileRevenue.Code" })
        },
        {
            dataField: 'description',
            text: intl.formatMessage({ id: "ProfileRevenue.Description" })
        },
        {
            dataField: 'bookingDate',
            text: intl.formatMessage({ id: "ProfileRevenue.BookingDate" }),
            formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : ''
        },
        {
            dataField: 'units',
            text: intl.formatMessage({ id: "ProfileRevenue.Units" })
        },
        {
            dataField: 'unitPrice',
            text: intl.formatMessage({ id: "ProfileRevenue.UnitPrice" }),
            formatter: (cell, row) => cell ? <FormatAmountNumber value={cell} currency={row.hotelCurrencyCode} /> : ''
        },
        {
            dataField: 'isPackage',
            text: intl.formatMessage({ id: "ProfileRevenue.IsPackage" }),
            formatter: (cell, row) => cell ? <FormattedMessage id="generic.yes" /> : <FormattedMessage id="generic.no" />
        },
        {
            dataField: 'discountPercent',
            text: intl.formatMessage({ id: "ProfileRevenue.DiscountPercent" })
        },
        {
            dataField: 'taxAmount',
            text: intl.formatMessage({ id: "ProfileRevenue.TaxAmount" }),
            formatter: (cell, row) => cell ? <FormatAmountNumber value={cell} currency={row.hotelCurrencyCode} /> : ''
        },
        {
            dataField: 'segmentCode',
            text: intl.formatMessage({ id: "ProfileRevenue.SegmentCode" }),
            hidden: true
        },
        {
            dataField: 'subSegmentCode',
            text: intl.formatMessage({ id: "ProfileRevenue.SubSegmentCode" }),
            hidden: true
        },
        {
            dataField: 'distChannelCode',
            text: intl.formatMessage({ id: "ProfileRevenue.DistChannelCode" }),
            hidden: true
        },
        {
            dataField: 'chargeGroupCode',
            text: intl.formatMessage({ id: "ProfileRevenue.ChargeGroup" }),
            formatter: (cell, row) => `${cell} - ${row.chargeGroupDescription}`,
            hidden: true
        },
        {
            dataField: 'salesGroupCode',
            text: intl.formatMessage({ id: "ProfileRevenue.SalesGroup" }),
            formatter: (cell, row) => `${cell} - ${row.salesGroupDescription}`,
            hidden: true
        },
        {
            dataField: 'source',
            text: intl.formatMessage({ id: "ProfileRevenue.Source" }),
            hidden: true
        },
    ];
}


class ProfileRevenue extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            blockSummary: false,
            blockDetails: false,
            collapse: false,
            amountByHotel: null,
            totalAmount: 0,
            totalResAmount: [],
            monthRevenue: { datasets: [], labels: [] },
            revenueList: [],
            amountPerCurrency: [],
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            treeMapExpanded: false,
            barChartExpanded: false,
            hotelId: this.props.fixedHotelFilter ?? null,
            columns: getColumns(this.props.intl)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.profileId) {
            this.getProfileRevenue();
            this.getRevenue();
        }
    }

    getRevenue = () => {
        this.setState({ blockDetails: true });

        var params = '';
        if (this.props.startDate) params += `&startDate=${moment(this.props.startDate).format('YYYY-MM-DD')}`;
        if (this.props.endDate) params += `&endDate=${moment(this.props.endDate).format('YYYY-MM-DD')}`;
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockDetails: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({
                        revenueList: data.response,
                        totalItems: data.totalItems
                    })
                }

                this.setState({ blockDetails: false });
            }
        }, `/api/gms/Reservation/v1/revenue/${this.props.profileId}/profile?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params)
    }

    getProfileRevenue = () => {
        this.setState({ blockSummary: true });

        var params = '';
        if (this.props.startDate) params += `beginDate=${moment(this.props.startDate).format('YYYY-MM-DD')}&`;
        if (this.props.endDate) params += `endDate=${moment(this.props.endDate).format('YYYY-MM-DD')}&`;
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockSummary: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ blockSummary: false }, () => this.getProfileReservationsSummary(data.response));
            }
        }, `/api/gms/Reservation/v1/revenue/${this.props.profileId}/reveneuSummary?` + params)
    }

    getProfileReservationsSummary = (revenueData) => {
        this.setState({ blockSummary: true });

        var params = '';
        if (this.props.startDate) params += `startDate=${moment(this.props.startDate).format('YYYY-MM-DD')}&`;
        if (this.props.endDate) params += `endDate=${moment(this.props.endDate).format('YYYY-MM-DD')}&`;
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockSummary: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.chartDates(revenueData, data.response);

                this.setState({ blockSummary: false });
            }
        }, `/api/gms/Reservation/v1/reservations/profile/${this.props.profileId}/summary?` + params)
    }

    chartDates = (revenueSummary, reservationsData) => {
        var dates = [];

        revenueSummary && revenueSummary.forEach(revenue => {
            if (revenue.isPayment === false) {
                if (!dates.some(el => el === moment(revenue.bookingDate).format('YYYY-MM-DD'))) {
                    dates.push(moment(revenue.bookingDate).format('YYYY-MM-DD'));
                }
            }
        });
                
        reservationsData && reservationsData.forEach(hotel => {
            hotel.dataByDate && hotel.dataByDate.forEach(date => {
                if (!dates.some(el => el === moment(date.date).format('YYYY-MM-DD'))) {
                    dates.push(moment(date.date).format('YYYY-MM-DD'));
                }
            })
        });

        dates = dates.sort((a, b) => moment(a) - moment(b));

        this.calculateData(revenueSummary, reservationsData, dates);
    }

    calculateData = (revenueSummary, reservationSummary, dates) => {
        var amountPerCurrency = new Map(), amountHotel = [], monthRev = { datasets: [], labels: dates };
        var amountByCharges = [["Location", "Parent", "Total Amount", "Color"], ["Sales Group", null, 0, 0]];
        var amountByHotel = { datasets: [{ label: "Amount by Hotel", data: [], backgroundColor: ['#48d7ea', '#8B75EA', '#d6edfe', '#FF9827', '#FFBE2D', "#F16D84", '#CF53FA'] }], labels: [] };
        const colors = ['#48d7ea', '#8B75EA', '#d6edfe', '#FF9827', '#FFBE2D', "#F16D84", '#CF53FA'];
        const hasMoreThanOneCurrency = revenueSummary.some(el => el.hotelCurrencyCode !== revenueSummary[0].hotelCurrencyCode);

        // monthRev.datasets.push({ label: this.props.intl.formatMessage({ id: "ProfileRevenue.Payments" }), data: Array(monthRev.labels.length).fill(0), backgroundColor: '#FFBE2D', borderColor: '#FFBE2D', type: 'line', yAxisID: 'payments', fill: false }  );

        revenueSummary && revenueSummary.forEach((revenue, i) => {
            if (revenue.isPayment === false) {
                const index = monthRev.labels.findIndex(el => el === moment(revenue.bookingDate).format('YYYY-MM-DD'));

                //Pie
                const currentHotel = amountHotel.find(el => el.hotelId === revenue.hotelId);

                if (currentHotel === undefined) {
                    amountHotel.push({ hotelId: revenue.hotelId, hotelName: revenue.hotelName, totalAmount: revenue.totalAmount, hotelCurrencyCode: revenue.hotelCurrencyCode });
                }
                else {
                    currentHotel.totalAmount += revenue.totalAmount;
                }

                //TreeMap amount by charges
                amountByCharges = this.treeMapLevels(amountByCharges, revenue, hasMoreThanOneCurrency);


                //Card total amount
                if (amountPerCurrency.get(revenue.hotelCurrencyCode) === undefined) amountPerCurrency.set(revenue.hotelCurrencyCode, revenue.totalAmount);
                else amountPerCurrency.set(revenue.hotelCurrencyCode, revenue.totalAmount + amountPerCurrency.get(revenue.hotelCurrencyCode));


                //Bar chart
                const revenueLabel = hasMoreThanOneCurrency ? `${revenue.description} (${revenue.hotelCurrencyCode})` : revenue.description;

                if (!monthRev.datasets.some(el => el.label === revenueLabel)) {
                    const colorIdx = monthRev.datasets.length || 0;
                    monthRev.datasets.push({ label: revenueLabel, data: [], backgroundColor: colors[colorIdx], yAxisID: 'charges', stack: 'Stack 0' });

                    monthRev.datasets[monthRev.datasets.length - 1].data[index] = revenue.totalAmount;
                }
                else {
                    const datasetIndex = monthRev.datasets.indexOf(monthRev.datasets.find(el => el.label === revenueLabel));
                    monthRev.datasets[datasetIndex].data[index] = monthRev.datasets[datasetIndex].data[index] ? monthRev.datasets[datasetIndex].data[index] + revenue.totalAmount : 0 + revenue.totalAmount;
                }
            }
            else {
                //monthRev.datasets[0].data[index] = monthRev.datasets[0].data[index] ? monthRev.datasets[0].data[index] + revenue.totalAmount : 0 + revenue.totalAmount;
            }
        })

        let totalResAmount = new Map();
        const resTreeMap = []
        const resDatasets = [];

        reservationSummary && reservationSummary.forEach(hotel => {
            let hotelIdx = resDatasets.findIndex(el => el.label === `Reservations`);
            let currencyIdx = resTreeMap.findIndex(el => el[0].v === `Reservations-${hotel.hotelCurrencyCode}`);



            if (hotelIdx === -1) {
                //Bar chart
                const opacity = resDatasets.length ? (1 - (resDatasets.length * 0.2)) : 1;

                hotelIdx = resDatasets.length;
                resDatasets.push({
                    label: `Reservations (${hotel.hotelCurrencyCode})`,
                    data: [],
                    backgroundColor: opacity ? `rgba(8, 103, 250, ${opacity})` : 'grey',
                    yAxisID: 'reservations',
                    stack: 'Stack 1'
                });
            }

            if (currencyIdx === -1) {
                //Total Reservations Amount
                totalResAmount.set(hotel.hotelCurrencyCode, 0);

                //TreeMap
                currencyIdx = resTreeMap.length;
                resTreeMap.push([{ v: `Reservations-${hotel.hotelCurrencyCode}`, f: `Reservations (${hotel.hotelCurrencyCode})` }, 'Sales Group', 0, 0]);
            }


            hotel.dataByDate && hotel.dataByDate.forEach(date => {
                date.dataByChannel && date.dataByChannel.forEach(channel => {
                    channel.dataByStatus && channel.dataByStatus.forEach(reservations => {

                        if (reservations.pmsStatus != 6 && reservations.pmsStatus != 7) {
                            const index = monthRev.labels.findIndex(el => el === moment(date.date).format('YYYY-MM-DD'));
                            const day = moment(date.date).format('DD-MMMM');
                            const year = moment(date.date).format('YYYY');

                            const yearIdx = amountByCharges.findIndex(el => el[0].v === `Reservations-${hotel.hotelCurrencyCode}-${year}` && el[1] === `Reservations-${hotel.hotelCurrencyCode}`);
                            const dayIdx = amountByCharges.findIndex(el => el[0].v === `Reservations-${hotel.hotelCurrencyCode}-${day}-${year}` && el[1] === `Reservations-${hotel.hotelCurrencyCode}-${year}`);
                            
                            //Total Reservations Amount
                            totalResAmount.set(hotel.hotelCurrencyCode, reservations.totalAmount + totalResAmount.get(hotel.hotelCurrencyCode));

                            //TreeMap
                            resTreeMap[currencyIdx][2] += reservations.totalAmount;
                            resTreeMap[currencyIdx][3] += reservations.totalAmount;


                            if (dayIdx === -1) {
                                amountByCharges.push([{ v: `Reservations-${hotel.hotelCurrencyCode}-${day}-${year}`, f: `${day}` }, `Reservations-${hotel.hotelCurrencyCode}-${year}`, reservations.totalAmount, reservations.totalAmount]);
                            }
                            else {
                                amountByCharges[dayIdx][2] = amountByCharges[dayIdx][2] ? amountByCharges[dayIdx][2] + reservations.totalAmount : 0 + reservations.totalAmount;
                                amountByCharges[dayIdx][3] = amountByCharges[dayIdx][3] ? amountByCharges[dayIdx][3] + reservations.totalAmount : 0 + reservations.totalAmount;
                            }

                            if (yearIdx === -1) {
                                amountByCharges.push([{ v: `Reservations-${hotel.hotelCurrencyCode}-${year}`, f: `${year}` }, `Reservations-${hotel.hotelCurrencyCode}`, reservations.totalAmount, reservations.totalAmount]);
                            }
                            else {
                                amountByCharges[yearIdx][2] = amountByCharges[yearIdx][2] ? amountByCharges[yearIdx][2] + reservations.totalAmount : 0 + reservations.totalAmount;
                                amountByCharges[yearIdx][3] = amountByCharges[yearIdx][3] ? amountByCharges[yearIdx][3] + reservations.totalAmount : 0 + reservations.totalAmount;
                            }


                            //Bar chart
                            resDatasets[hotelIdx].data[index] = resDatasets[hotelIdx].data[index] ? resDatasets[hotelIdx].data[index] + reservations.totalAmount : 0 + reservations.totalAmount;
                        }
                    })
                })
            })
        })

        resTreeMap.forEach(el => {
            amountByCharges.splice(2, 0, el);
        })
        //amountByCharges.splice(2, 0, resTreeMap.map(el=> el));

        monthRev.datasets = resDatasets.concat(monthRev.datasets);


        amountHotel.forEach(item => {
            amountByHotel.labels.push(`${item.hotelName} (${item.hotelCurrencyCode})`);
            amountByHotel.datasets[0].data.push(parseFloat(item.totalAmount).toFixed(2));
        });


        this.setState({
            amountPerCurrency, amountByHotel, treeMap: amountByCharges, monthRevenue: monthRev, totalResAmount
        })
    }

    treeMapLevels = (amountByCharges, revenue) => {
        const day = moment(revenue.bookingDate).format('DD MMMM');
        const year = moment(revenue.bookingDate).format('YYYY');

        const salesGroupID = `SalesGroup-${(revenue.salesGroupDescription || 'N/A')}(${revenue.hotelCurrencyCode})`;
        const chargeGroupID = `${salesGroupID}-ChargeGroup-${(revenue.chargeGroupDescription || 'N/A')}`;
        const chargeChartID = `${chargeGroupID}-Charge-${revenue.description}`;
        const yearID = `${chargeGroupID}${chargeChartID}-${year}`;
        const dayID = `${chargeGroupID}${chargeChartID}-${yearID}-${day}`;

        const salesGroupIdx = amountByCharges.findIndex(el => el[0].v === salesGroupID);
        const chargeGroupIdx = amountByCharges.findIndex(el => el[0].v === chargeGroupID && el[1] === salesGroupID);
        const chargeIdx = amountByCharges.findIndex(el => el[0].v === chargeChartID && el[1] === chargeGroupID);
        const yearIdx = amountByCharges.findIndex(el => el[0].v === yearID && el[1] === chargeChartID);
        const dayIdx = amountByCharges.findIndex(el => el[0].v === dayID && el[1] === yearID);


        const treeMapData = [
            { label: { v: salesGroupID, f: `${(revenue.salesGroupDescription || 'N/A')} (${revenue.hotelCurrencyCode})` }, index: salesGroupIdx, parent: 'Sales Group' },
            { label: { v: chargeGroupID, f: `${(revenue.chargeGroupDescription || 'N/A')} (${revenue.hotelCurrencyCode})` }, index: chargeGroupIdx, parent: salesGroupID },
            { label: { v: chargeChartID, f: `${revenue.description} (${revenue.hotelCurrencyCode})` }, index: chargeIdx, parent: chargeGroupID },
            { label: { v: yearID, f: `${year}` }, index: yearIdx, parent: chargeChartID },
            { label: { v: dayID, f: `${day}` }, index: dayIdx, parent: yearID }
        ]


        treeMapData.forEach(el => {
            if (el.index === -1) {
                amountByCharges.push([el.label, el.parent, revenue.totalAmount, revenue.totalAmount]);
            }
            else {
                amountByCharges[el.index][2] = amountByCharges[el.index][2] ? amountByCharges[el.index][2] + revenue.totalAmount : 0 + revenue.totalAmount;
                amountByCharges[el.index][3] = amountByCharges[el.index][3] ? amountByCharges[el.index][3] + revenue.totalAmount : 0 + revenue.totalAmount;
            }
        })


        return amountByCharges;
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getRevenue() });
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => { this.getRevenue(); this.getProfileRevenue() })
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    toggleExpanded = (expanded) => {
        this.setState(prevState => ({
            [expanded]: !prevState[expanded]
        }))
    }

    onColumnToggle = (field) => {
        const { columns } = this.state;
        const index = columns.findIndex(el => el.dataField === field);

        if (index !== -1) {
            columns[index].hidden = !columns[index].hidden;
        }

        this.setState({ columns });
    }

    render() {
        const { error, blockSummary, blockDetails, treeMapExpanded, barChartExpanded, amountPerCurrency, totalResAmount } = this.state;
        const { startDate, endDate, handleDates, hotels } = this.props;

        let optionsPie = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '60%',
            plugins: {
                datalabels: { display: false },
                legend: {
                    position: "left"
                },
            }
        }

        const options = {
            responsive: true,
            maxBarThickness: 60,
            maintainAspectRatio: false,
            plugins: { datalabels: { display: false } },
            scales: {
                x: {
                    stacked: true,
                    gridLines: { display: false }
                },
                charges: {
                    min: 0,
                    display: true,
                    stacked: true,
                    beginAtZero: true,
                    ticks: { precision: 0 },
                    position: 'left',
                    title: {
                        display: true,
                        text: this.props.intl.formatMessage({ id: "ProfileRevenue.ChargesAmount" })
                    }
                },
                reservations: {
                    stacked: true,
                    type: 'linear',
                    beginAtZero: true, 
                    min: 0,
                    display: true,
                    position: 'right',
                    title: {
                        display: true,
                        text: this.props.intl.formatMessage({ id: "ProfileReservations.Reservations" })
                    }
                }
                //{
                //    stacked: true,
                //    type: 'linear',
                //    ticks: { beginAtZero: true, min: 0 },
                //    display: true,
                //    position: 'right',
                //    id: 'payments',
                //    scaleLabel: {
                //        display: true,
                //        labelString: this.props.intl.formatMessage({ id: "ProfileRevenue.PaymentAmount" })
                //    }
                //}
            }
        }
        
        const treeMapOptions = {
            minColor: "#D6EDFE",
            midColor: "#75AEFC",
            maxColor: "#0867FA",
            headerHeight: 20,
            fontColor: "black",
            showScale: true,
            headerColor: '#D6EDFE',
            fontFamily: "Poppins, sans-serif",
            generateTooltip: (row, size, value) => {
                return (
                    '<div style="background:#fd9; padding:10px; border-style:solid"> Total Amount: ' + parseFloat(size).toFixed(2) + '</div>'
                )
            },
        };


        return (
            <div>
                <ErrorAlert error={error} />
                <div className="m-2 p-2">
                    <Row className="mb-3 align-items-center">
                        <Col className="col-3 pr-1">
                            <SelectHotelWithLoadedData
                                name='hotelId'
                                onChangeFunc={this.handleChangeSelect}
                                value={this.state.hotelId || ''}
                                options={hotels}
                            />
                        </Col>
                            
                        <Col className="text-right">
                            <h5><b> <FormattedMessage id="ProfileDetails.Revenue" /></b></h5>
                        </Col>
                        <Col className=" text-right col-6">
                            <DateRangePicker
                                startDate={startDate}
                                startDateId="startDate"
                                isOutsideRange={_ => false}
                                endDate={endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => handleDates( startDate, endDate )}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                           
                            <Button className="btn  btn-host ml-2 mt-1" onClick={this.doSearch}>
                                <i className="icon-icon-search"></i>
                            </Button>
                        </Col>
                    </Row>
                       

                    <BlockUi tag="div" blocking={blockSummary}>
                        <Row className="mb-4">
                            {!treeMapExpanded ?
                                <Col className="col-4 h-100" id="columnTest" >
                                    <Card className="mb-2 shadow border-0 px-3" body style={{ minHeight: '12%', padding: '10px 0' }}>
                                        <Row>
                                            <Col className={"pr-1 " + ([...amountPerCurrency]?.some(el => el[1].toFixed(2).length > 6) ? 'col-7' : 'col-8')}>
                                                <h5 className="text-muted m-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={this.props.intl.formatMessage({ id: "ProfileRevenue.TotalReservationAmount" })}>
                                                    <FormattedMessage id="ProfileRevenue.TotalChargesAmount" />
                                                </h5>
                                            </Col>
                                            <Col className="text-right pl-0">
                                                {amountPerCurrency ?
                                                    [...amountPerCurrency].map((currency, key) => 
                                                        <h5 className={"mb-0" + (key > 0 ? ' mt-2' : '')} key={key}>
                                                            <b> <FormatAmountNumber value={currency[1]} currency={currency[0]} /></b>
                                                        </h5>
                                                    )
                                                : <h5>-</h5>}
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card className="mb-2 shadow border-0 px-3" body style={{ height: '12%', padding: '10px 0' }}>
                                        <Row>
                                            <Col className={"pr-1 " + ([...totalResAmount]?.some(el => el[1].toFixed(2).length > 6) ? 'col-7' : 'col-8')}>
                                                <h5 className="text-muted m-0" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={this.props.intl.formatMessage({ id: "ProfileRevenue.TotalReservationAmount" }) }>
                                                    <FormattedMessage id="ProfileRevenue.TotalReservationAmount" />
                                                </h5>
                                            </Col>
                                            <Col className="text-right pl-0">
                                                {totalResAmount ?
                                                    [...totalResAmount].map((currency, key) =>
                                                        <h5 className={"mb-0" + (key > 0 ? ' mt-2' : '')} key={key}>
                                                            <b> <FormatAmountNumber value={currency[1]} currency={currency[0]} /></b>
                                                        </h5>
                                                    )
                                                : <span>-</span>}
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card className="shadow border-0" body style={{ height: '70%' }}>
                                        <Row>
                                            <Col><h5 className="text-muted"><FormattedMessage id="ProfileRevenue.RevenueByHotel" /></h5></Col>
                                        </Row>
                                        <Row className="h-100 align-items-center">
                                            <Col>
                                                {this.state.amountByHotel ? 
                                                    <div className="w-100">
                                                        <Doughnut height={150} data={this.state.amountByHotel} options={optionsPie} />
                                                    </div>
                                                : ''}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            : ''}

                            <Col className={treeMapExpanded ? '' : "col-8 "} style={treeMapExpanded ? { height: '600px' } : { height: document.getElementById("columnTest")?.offsetHeight || '330px' }}>
                                <Card className="shadow pl-3 border-0 h-100" body>
                                    <div style={{ position: 'absolute', top: '0', right: '8px' }}>
                                        <i className={"fas text-host pointer pt-2 " + (treeMapExpanded ? ' fa-compress-alt' : ' fa-expand-alt')} onClick={() => this.toggleExpanded('treeMapExpanded')} />
                                    </div>
                                    {this.state.amountByHotel && this.state.amountByHotel.labels && this.state.amountByHotel.labels.length > 0 ?
                                        <Chart
                                            className="pl-3"
                                            chartType="TreeMap"
                                            width="100%"
                                            height="100%"
                                            data={this.state.treeMap}
                                            options={treeMapOptions}
                                        />
                                        : ''
                                    }
                                </Card>
                            </Col>
                        </Row>

                        <div className="mb-4">
                            <Card className=" border-0 shadow pb-4" body style={barChartExpanded ? { height: '600px' } : { height: '340px' }}>
                                <div style={{ position: 'absolute', top: '0', right: '8px' }}>
                                    <i className={"fas text-host pointer pt-2 " + (barChartExpanded ? ' fa-compress-alt' : ' fa-expand-alt')} onClick={() => this.toggleExpanded('barChartExpanded')} />
                                </div>
                                <Bar height={70} data={this.state.monthRevenue} options={options} datasetKeyProvider={() => { return btoa(Math.random()).substring(0, 6) }} />
                            </Card>
                        </div>
                    </BlockUi>

                    <BlockUi tag="div" blocking={blockDetails}>
                        <Row >
                            <Col>
                                <Card className="border-0 shadow">
                                    <CardBody>
                                        <Row id="toggler" className="pointer" >
                                            <Col className="text-muted">
                                                <h5>Revenue Details</h5>
                                            </Col>
                                            <Col className="text-right">
                                                <span>
                                                    <i className="fas fa-chevron-up mr-2"/>
                                                </span>

                                            </Col>
                                        </Row>
                                        <UncontrolledCollapse toggler="#toggler" defaultOpen={true} className="mt-2">
                                            <CustomTable
                                                data={this.state.revenueList ? this.state.revenueList : []}
                                                columns={this.state.columns}
                                                showTotal={true}
                                                remote={true}
                                                page={this.state.pageIndex + 1}
                                                sizePerPage={this.state.pageSize}
                                                onTableChange={this.handleTableChange}
                                                totalSize={this.state.totalItems}
                                                toggleList={{ show: true, width: '160px', onColumnToggle: this.onColumnToggle }}
                                            />  
                                        </UncontrolledCollapse>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </BlockUi>
                </div>
            </div>
        );
    }
}
export default injectIntl(ProfileRevenue)