import React, { Component } from 'react'
import { Row, Col, Button, Card, Badge } from 'reactstrap';
import { ErrorAlert } from '../Base/ErrorAlert';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { deleteAPI, getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { KebabMenu } from '../Base/CommonUIComponents';
import SalesSourceDetails from './SalesSourceDetails';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export default class SalesSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            sources: []
        };
    }

    componentDidMount() {
        this.getSalesSourceList();
    }

    getSalesSourceList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ sources: data.data || [], block: false });
                return;
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/SalesProcess/v1/sources`);
    }

    deleteSource = (id) => {
        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ deleteModal: false, block: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }
                    else {
                        this.setState({ deleteModal: false, block: false }, () => {
                            handleNotification(data, <FormattedMessage id="SalesSource.DeletedSuccessfully" />, <FormattedMessage id="generic.success" />);
                            this.getSalesSourceList();
                        });
                    }
                }
                this.setState({ error: errorMessage, block: false });

            }, `/api/gms/SalesProcess/v1/sources/${id}`)
        );
    }

    toggleModal = (modal, source) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedSource: source
        }))
    }

    render() {
        const { block, error, sources, modal, deleteModal, selectedSource } = this.state;
        const { salesUsers, salesPipelines } = this.props;

        return (
            <div className="border-0 h-100 pb-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='mb-4'>
                        <Row>
                            <Col>
                                <h4 className='m-0'>
                                    <i className={`${this.props.icon} mr-2`} />
                                    <FormattedMessage id="SalesProcess.SalesSource" />
                                </h4>
                            </Col>
                            <Col className='col-2 text-right'>
                                <Button className="btn-sm btn-host mr-2" onClick={_ => this.toggleModal('modal')}>
                                    <i className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <Row className='mt-2'>
                        {sources?.map((source, k) => {
                            const user = salesUsers?.find(({id}) => id === source.responsableId);
                            return <Col className='col-12 mb-3' key={k}>
                                <Card body className='p-3 h-100'>
                                    <Row className="align-items-center">
                                        <Col onClick={_ => this.toggleModal("modal", source)} className='cursor-pointer'>
                                            <Row className="align-items-center">
                                                <Col className='col-2'>
                                                    <div>
                                                        <b>{source.name}</b>
                                                    </div>
                                                    <div>
                                                        <div className='text-muted title-sm text-truncate' title={source.description}>
                                                            {source.description}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="mb-2">
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.MainContact" />
                                                    </div>
                                                    {source.mainContactId ?
                                                        <Link to={{ pathname: "/ProfileDetails/" + source.mainContactId }} className="hrefStyle">
                                                            {source.mainContactName || source.mainContactId || '-'}
                                                        </Link>
                                                    :
                                                        <span>
                                                            {source.mainContactName || source.mainContactId || '-'}
                                                        </span>
                                                    }
                                                </Col>
                                                <Col className="mb-2">
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.Responsable" />
                                                    </div>
                                                    {source.responsableId ?
                                                        user?.firstName && user?.lastName ?
                                                            <div>
                                                                {`${user.firstName} ${user.lastName}`}
                                                            </div>
                                                        :
                                                            source.responsableId
                                                    :'-'}
                                                </Col>
                                                <Col className="mb-2">
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.StartDate" />
                                                    </div>
                                                    {source.startDate || '-'}
                                                </Col>
                                                <Col  className="mb-2">
                                                    <div className='text-muted title-sm mb-0'>
                                                        <FormattedMessage id="SalesSource.EndDate" />
                                                    </div>
                                                    {source.endDate || '-'}
                                                </Col>
                                                <Col className='text-right col-1'>
                                                    <Badge className='ml-2' color='primary'>
                                                        <FormattedMessage id={`ReferenceDataFunctions.${source.type}`} />
                                                    </Badge>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='col-1 text-right'>
                                            <KebabMenu
                                                deleteFunction={_ => this.toggleModal('deleteModal', source)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        })}
                    </Row>

                    {modal ?
                        <SalesSourceDetails
                            modal={modal}
                            salesPipelines={salesPipelines}
                            toggleModal={() => this.toggleModal('modal')}
                            source={selectedSource}
                            getSalesSourceList={this.getSalesSourceList}
                            salesUsers={salesUsers}
                        />
                    : ''}

                    {deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={_ => this.toggleModal('deleteModal')}
                            actionFunction={_ => this.deleteSource(selectedSource.id)}
                            text={<FormattedMessage id="SalesSource.ConfirmDeleteText" values={{ source: selectedSource.name }} />}
                            block={block}
                        />
                    : ''}
                </BlockUi>
            </div>
        )
    }
}
