import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Form, Input, Modal, Row } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import { ErrorAlert } from '../Base/ErrorAlert';
import microsoft365 from '../../img/Microsoft365.png';
import { getUsersRoleType } from './SalesProcessFunctions';

class SalesProcessUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { toggleModal, block, user, handleUserChange, createUser, handleComboChange, adalUsersOptions, blockAdal, errorModal, logInOutUserExternal, hasOffice365 } = this.props;
        
        return (
            <Modal isOpen={true} toggle={toggleModal}>
                <ErrorAlert error={errorModal}/>
                <BlockUi tag="div" blocking={block}>
                    <Button onClick={toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"/>
                    </Button>
                    <Card className='border-0 p-4 shadow'>
                        <Form onSubmit={createUser}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h5><FormattedMessage id="SalesDeal.CreateUser" /></h5>
                                </div>
                                <Button className="btn btn-host btn-sm" type="submit">
                                    <i className="fas fa-save" />
                                </Button>
                            </div>
                            <div>
                                <Row>
                                    <Col className='col-12'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.PNUser" />
                                        </div>
                                        <div className='big-combo'>
                                            <CustomSelect
                                                isLoading={blockAdal}
                                                value={adalUsersOptions.find(({ value }) => value === user.userId)}
                                                options={adalUsersOptions}
                                                placeholder=""
                                                required={true}
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={(combo) => handleComboChange(combo, 'userId')}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.FirstName" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.firstName}
                                                name="firstName"
                                                required={true}
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.LastName" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.lastName}
                                                name="lastName"
                                                required={true}
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.Email" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.email}
                                                name="email"
                                                required={true}
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col className='col-6 mt-3'>
                                        <div className='title-sm required'>
                                            <FormattedMessage id="SalesDeal.Role" />
                                        </div>
                                        <div>
                                            <CustomSelect
                                                value={getUsersRoleType(this.props.intl).find(({ value }) => value === user.role)}
                                                options={getUsersRoleType(this.props.intl)}
                                                placeholder=""
                                                required={true}
                                                isClearable={false}
                                                onChange={(combo) => handleComboChange(combo, 'role')}
                                            />
                                        </div>
                                    </Col>
                                    <Col className={`col-${hasOffice365 && global.userId === user.userId ? '10' : '12'} mt-3`}>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesDeal.Notes" />
                                        </div>
                                        <div>
                                            <Input
                                                type="text"
                                                value={user.notes}
                                                name="notes"
                                                onChange={handleUserChange}
                                            />
                                        </div>
                                    </Col>
                                    {hasOffice365 && global.userId === user.userId ?
                                        <Col className='col-2 mt-3 d-flex align-items-end justify-content-end'>
                                            <Button className='btn btn-sm btn-host btn-secondary d-flex align-items-center justify-content-center officeButton' type='button' onClick={logInOutUserExternal}>
                                                <div className='mr-1'>
                                                    {user.hasExternalAccess ?
                                                        <FormattedMessage id="SalesProcess.DisconnectOffice365"/>
                                                    :
                                                        <FormattedMessage id="SalesProcess.ConnectOffice365"/>
                                                    }
                                                </div>
                                                <img alt="" src={microsoft365} height="22px"/>
                                            </Button>
                                        </Col>
                                    :''}
                                </Row>
                            </div>
                        </Form>
                    </Card>
                </BlockUi>
            </Modal>
        );
    }
}

export default injectIntl(SalesProcessUserModal);
