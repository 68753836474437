import React, { Component } from 'react';
import { Badge, Col, Row } from 'reactstrap';

export class CampaignTags extends Component {

    render() {
        const { changeTagMin, handleTag, filters, tagList } = this.props;

        return (
            <Row>
                <Col>
                    <div className="mt-5 scrollableDiv" style={{ height: '90%' }}>
                        {tagList && tagList.map((el, k) =>
                            <Row className="mt-2 pl-1" key={k}>
                                <Col className="col-2"> {el.code}:  </Col>
                                <Col className="d-flex col-10">
                                    {el.tag && el.tag.map((tag, k1) => {
                                        const tagFilter = filters.tags && filters.tags.find(t => t.id === tag.id);
                                        const isSelected = tagFilter !== undefined ? true : false;

                                        return <span key={k1}>
                                            <Badge className=" my-1 mr-2 d-flex align-items-center" color={isSelected ? "primary" : "secondary"} style={{ cursor: 'pointer', height: '30px' }} >
                                                <span onClick={() => handleTag(tag)}>{tag.code} </span>
                                                {isSelected ?
                                                    <span className="bg-white text-host ml-2 px-1 invisible-input " style={{ borderRadius: '20px' }}>
                                                        <input className="hide-input-arrows text-center" style={{ width: '30px' }}
                                                            type="number" min="0"
                                                            onChange={(e) => changeTagMin(e, tagFilter)}
                                                            value={tagFilter.min} />
                                                    </span>
                                                    : ''}
                                            </Badge>
                                        </span>
                                    }
                                    )}
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>    
        );
    }
}