import moment from 'moment-timezone';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { Badge, Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Col, CustomInput, Form, Input, Modal, ModalBody, Nav, NavItem, NavLink, Row, UncontrolledTooltip } from 'reactstrap';
import { deleteAPI, getAPI, postAPI } from '../../Base/API';
import Authorization from '../../Base/Authorization';
import { StyledCard } from '../../Base/CommonUIComponents';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import CustomSelect from '../../Base/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import OpenAi from './OpenAi';
import PreviewMobile from './PreviewMobile';
import PreviewDesktop from './PreviewDesktop';
import TemplateVersions from './TemplateVersions';

class Stripo extends Component {

    constructor (props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            block: true,
            specialLinks: [
                {
                    "category":"Custom Links",
                    "entries":[
                        {
                            "label": "Remove Link",
                            "value": "{REMOVE_LINK}"
                        },
                        {
                            "label": "Keypass Link",
                            "value": "{{reservation.KeypassLink.value}}"
                        },
                        {
                            "label": "Form Url",
                            "value": "{{formUrl.value}}"
                        },
                        {
                            "label": "Double Opt In",
                            "value": "{{doubleOptIn.value}}"
                        },
                        {
                            "label": "Online Checkin Link",
                            "value": "{{reservation.OnlineCheckinLink.value}}"
                        },
                    ]
                },
            ],
            specialLinksSendGrid: [
                {
                    "category": "Custom Links",
                    "entries": [
                        {
                            "label": "Remove Link",
                            "value": "{REMOVE_LINK}"
                        },
                        {
                            "label": "Keypass Link",
                            "value": "{{reservation.KeypassLink}}"
                        },
                        {
                            "label": "Form Url",
                            "value": "{{formUrl}}"
                        },
                        {
                            "label": "Double Opt In",
                            "value": "{{doubleOptIn}}"
                        },
                        {
                            "label": "Online Checkin Link",
                            "value": "{{reservation.OnlineCheckinLink}}"
                        },
                    ]
                },
            ],
            customTagsEgoi: [
                {
                    "label": "First Name",
                    "value": "{{profile.FirstName.value}}"
                },
                {
                    "label": "Last Name",
                    "value": "{{profile.LastName.value}}"
                },
                {
                    "label": "Middle Name",
                    "value": "{{profile.MiddleName.value}}"
                },
                {
                    "label": "Full Name",
                    "value": "{{profile.FullName.value}}"
                },
                {
                    "label": "Online Checkin Pin",
                    "value": "{{reservation.OnlineCheckinPin.value}}"
                },
                {
                    "label": "Online Checkin Link",
                    "value": "{{reservation.OnlineCheckinLink.value}}"
                },
                {
                    "label": "Remove Link",
                    "value": "{REMOVE_LINK}"
                },
                {
                    "label": "Keypass Link",
                    "value": "{{reservation.KeypassLink.value}}"
                },
                {
                    "label": "Form Url",
                    "value": "{{formUrl.value}}"
                },
                {
                    "label": "Double Opt In",
                    "value": "{{doubleOptIn.value}}"
                },
                {
                    "label": "Check In",
                    "value": "{{reservation.CheckIn.value}}"
                },
                {
                    "label": "CheckOut",
                    "value": "{{reservation.CheckOut.value}}"
                },
                {
                    "label": "Reservation Id",
                    "value": "{{reservation.ResIdValue.value}}"
                },
                {
                    "label": "Adults",
                    "value": "{{reservation.Adults.value}}"
                },
                {
                    "label": "Children",
                    "value": "{{reservation.Children.value}}"
                },
                {
                    "label": "Amount",
                    "value": "{{reservation.Amount.value}}"
                },
                {
                    "label": "Room",
                    "value": "{{reservation.Room.value}}"
                },
                {
                    "label": "NumberOfNights",
                    "value": "{{reservation.NumberOfNights.value}}"
                },
                {
                    "label": "Package",
                    "value": "{{reservation.Package.value}}"
                },
                {
                    "label": "Card Number",
                    "value": "{{loyalty.CardNumber.value}}"
                },
                {
                    "label": "Card Points",
                    "value": "{{loyalty.CardPoints.value}}"
                },
                {
                    "label": "Card Level",
                    "value": "{{loyalty.CardLevel.value}}"
                }
            ],
            customTagsEgoiVoucher: [
                {
                    "label": "Voucher Id",
                    "value": "{{voucher.VoucherId.value}}"
                },
                {
                    "label": "Valid From",
                    "value": "{{voucher.ValidFrom.value}}"
                },
                {
                    "label": "Valid Until",
                    "value": "{{voucher.ValidUntil.value}}"
                },
                {
                    "label": "Voucher Value",
                    "value": "{{voucher.VoucherValue.value}}"
                }
            ],
            customTagsSendGrid: [
                {
                    "label": "First Name",
                    "value": "{{profile.FirstName}}"
                },
                {
                    "label": "Last Name",
                    "value": "{{profile.LastName}}"
                },
                {
                    "label": "Middle Name",
                    "value": "{{profile.MiddleName}}"
                },
                {
                    "label": "Full Name",
                    "value": "{{profile.FullName}}"
                },
                {
                    "label": "Online Checkin Pin",
                    "value": "{{reservation.OnlineCheckinPin}}"
                },
                {
                    "label": "Online Checkin Link",
                    "value": "{{reservation.OnlineCheckinLink}}"
                },
                {
                    "label": "Remove Link",
                    "value": "{REMOVE_LINK}"
                },
                {
                    "label": "Keypass Link",
                    "value": "{{reservation.KeypassLink}}"
                },
                {
                    "label": "Form Url",
                    "value": "{{formUrl}}"
                },
                {
                    "label": "Double Opt In",
                    "value": "{{doubleOptIn}}"
                },
                {
                    "label": "Check In",
                    "value": "{{reservation.CheckIn}}"
                },
                {
                    "label": "CheckOut",
                    "value": "{{reservation.CheckOut}}"
                },
                {
                    "label": "Reservation Id",
                    "value": "{{reservation.ResIdValue}}"
                },
                {
                    "label": "Adults",
                    "value": "{{reservation.Adults}}"
                },
                {
                    "label": "Children",
                    "value": "{{reservation.Children}}"
                },
                {
                    "label": "Amount",
                    "value": "{{reservation.Amount}}"
                },
                {
                    "label": "Room",
                    "value": "{{reservation.Room}}"
                },
                {
                    "label": "NumberOfNights",
                    "value": "{{reservation.NumberOfNights}}"
                },
                {
                    "label": "Package",
                    "value": "{{reservation.Package}}"
                },
                {
                    "label": "Card Number",
                    "value": "{{loyalty.CardNumber}}"
                },
                {
                    "label": "Card Points",
                    "value": "{{loyalty.CardPoints}}"
                },
                {
                    "label": "Card Level",
                    "value": "{{loyalty.CardLevel}}"
                },
            ],
            customTagsSendGridVoucher: [
                {
                    "label": "Voucher Id",
                    "value": "{{voucher.VoucherId}}"
                },
                {
                    "label": "Valid From",
                    "value": "{{voucher.ValidFrom}}"
                },
                {
                    "label": "Valid Until",
                    "value": "{{voucher.ValidUntil}}"
                },
                {
                    "label": "Voucher Value",
                    "value": "{{voucher.VoucherValue}}"
                }
            ],
            error: null,
            content: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">

            <head>
                <meta charset="UTF-8">
                <meta content="width=device-width, initial-scale=1" name="viewport">
                <meta name="x-apple-disable-message-reformatting">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta content="telephone=no" name="format-detection">
                <title></title>
                <!--[if (mso 16)]>
                <style type="text/css">
                a {text-decoration: none;}
                </style>
                <![endif]-->
                <!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
                <!--[if gte mso 9]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG></o:AllowPNG>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
            <![endif]-->
            </head>

            <body>
                <div class="es-wrapper-color">
                    <!--[if gte mso 9]>
                        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
                            <v:fill type="tile" color="#f6f6f6"></v:fill>
                        </v:background>
                    <![endif]-->
                    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td class="esd-email-paddings" valign="top">
                                    <table class="esd-header-popover es-header" cellspacing="0" cellpadding="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="esd-stripe" align="center">
                                                    <table class="es-header-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="es-p20t es-p20r es-p20l esd-structure" align="left">
                                                                    <!--[if mso]><table width="560" cellpadding="0"
                                        cellspacing="0"><tr><td width="180" valign="top"><![endif]-->
                                                                    <table class="es-left" cellspacing="0" cellpadding="0" align="left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="es-m-p0r es-m-p20b esd-container-frame" width="180" valign="top" align="center">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td><td width="20"></td><td width="360" valign="top"><![endif]-->
                                                                    <table class="es-right" cellspacing="0" cellpadding="0" align="right">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="esd-container-frame" width="360" align="left">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td></tr></table><![endif]-->
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="es-content" cellspacing="0" cellpadding="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="esd-stripe" align="center">
                                                    <table class="es-content-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="es-p20t es-p20r es-p20l esd-structure" align="left">
                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="esd-container-frame" width="560" valign="top" align="center">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="esd-footer-popover es-footer" cellspacing="0" cellpadding="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="esd-stripe" align="center">
                                                    <table class="es-footer-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="esd-structure es-p20t es-p20b es-p20r es-p20l" align="left">
                                                                    <!--[if mso]><table width="560" cellpadding="0"
                                    cellspacing="0"><tr><td width="270" valign="top"><![endif]-->
                                                                    <table class="es-left" cellspacing="0" cellpadding="0" align="left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="es-m-p20b esd-container-frame" width="270" align="left">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td><td width="20"></td><td width="270" valign="top"><![endif]-->
                                                                    <table class="es-right" cellspacing="0" cellpadding="0" align="right">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="esd-container-frame" width="270" align="left">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td></tr></table><![endif]-->
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </body>

            </html>`,
            style: `.es-menu td,
            img {
                border: 0;
            }
            
            .es-menu td a,
            img {
                display: block;
            }
            
            .es-menu td a,
            body,
            h1,
            h2,
            h3,
            h4,
            h5,
            ol li,
            p,
            ul li {
                font-family: arial, 'helvetica neue', helvetica, sans-serif;
            }
            
            .es-footer,
            .es-header,
            .es-wrapper {
                background-repeat: repeat;
                background-position: center top;
            }
            
            h1,
            h2,
            h3 {
                color: #333;
            }
            
            #outlook a {
                padding: 0;
            }
            
            .es-button {
                mso-style-priority: 100 !important;
                text-decoration: none !important;
            }
            
            a[x-apple-data-detectors] {
                color: inherit !important;
                text-decoration: none !important;
                font-size: inherit !important;
                font-family: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
            }
            
            .es-menu td a,
            img {
                text-decoration: none;
            }
            
            a.es-button,
            button.es-button,
            h1,
            h2,
            h3 {
                font-weight: 400;
                font-style: normal;
            }
            
            .es-desk-hidden {
                display: none;
                float: left;
                overflow: hidden;
                width: 0;
                max-height: 0;
                line-height: 0;
                mso-hide: all;
            }
            
            body {
                width: 100%;
                -webkit-text-size-adjust: 100%;
                -ms-text-size-adjust: 100%
            }
            
            table {
                mso-table-lspace: 0pt;
                mso-table-rspace: 0pt;
                border-collapse: collapse;
                border-spacing: 0px;
            }
            
            .es-wrapper,
            body,
            table td {
                padding: 0;
                margin: 0;
            }
            
            .es-content,
            .es-footer,
            .es-header {
                table-layout: fixed !important;
                width: 100%;
            }
            
            img {
                outline: 0;
                -ms-interpolation-mode: bicubic;
            }
            
            hr,
            p {
                margin: 0;
            }
            
            h1,
            h2,
            h3,
            h4,
            h5 {
                Margin: 0;
                line-height: 120%;
                mso-line-height-rule: exactly;
            }
            
            a,
            ol li,
            p,
            ul li {
                -webkit-text-size-adjust: none;
                -ms-text-size-adjust: none;
                mso-line-height-rule: exactly;
            }
            
            .es-left {
                float: left;
            }
            
            .es-right {
                float: right;
            }
            
            .es-p5 {
                padding: 5px;
            }
            
            .es-p5t {
                padding-top: 5px;
            }
            
            .es-p5b {
                padding-bottom: 5px;
            }
            
            .es-p5l {
                padding-left: 5px;
            }
            
            .es-p5r {
                padding-right: 5px;
            }
            
            .es-p10 {
                padding: 10px;
            }
            
            .es-p10t {
                padding-top: 10px;
            }
            
            .es-p10b {
                padding-bottom: 10px;
            }
            
            .es-p10l {
                padding-left: 10px;
            }
            
            .es-p10r {
                padding-right: 10px;
            }
            
            .es-p15 {
                padding: 15px;
            }
            
            .es-p15t {
                padding-top: 15px;
            }
            
            .es-p15b {
                padding-bottom: 15px;
            }
            
            .es-p15l {
                padding-left: 15px;
            }
            
            .es-p15r {
                padding-right: 15px;
            }
            
            .es-p20 {
                padding: 20px;
            }
            
            .es-p20t {
                padding-top: 20px;
            }
            
            .es-p20b {
                padding-bottom: 20px;
            }
            
            .es-p20l {
                padding-left: 20px;
            }
            
            .es-p20r {
                padding-right: 20px;
            }
            
            .es-p25 {
                padding: 25px;
            }
            
            .es-p25t {
                padding-top: 25px;
            }
            
            .es-p25b {
                padding-bottom: 25px;
            }
            
            .es-p25l {
                padding-left: 25px;
            }
            
            .es-p25r {
                padding-right: 25px;
            }
            
            .es-p30 {
                padding: 30px;
            }
            
            .es-p30t {
                padding-top: 30px;
            }
            
            .es-p30b {
                padding-bottom: 30px;
            }
            
            .es-p30l {
                padding-left: 30px;
            }
            
            .es-p30r {
                padding-right: 30px;
            }
            
            .es-p35 {
                padding: 35px;
            }
            
            .es-p35t {
                padding-top: 35px;
            }
            
            .es-p35b {
                padding-bottom: 35px;
            }
            
            .es-p35l {
                padding-left: 35px;
            }
            
            .es-p35r {
                padding-right: 35px;
            }
            
            .es-p40 {
                padding: 40px;
            }
            
            .es-p40t {
                padding-top: 40px;
            }
            
            .es-p40b {
                padding-bottom: 40px;
            }
            
            .es-p40l {
                padding-left: 40px;
            }
            
            .es-p40r {
                padding-right: 40px;
            }
            
            .es-menu td a img {
                display: inline-block !important;
                vertical-align: middle;
            }
            
            s {
                text-decoration: line-through;
            }
            
            ol li,
            p,
            ul li {
                line-height: 150%;
            }
            
            ol li,
            ul li {
                Margin-bottom: 15px;
                margin-left: 0;
            }
            
            a {
                text-decoration: underline;
            }
            
            .es-wrapper {
                width: 100%;
                height: 100%;
            }
            
            .es-wrapper,
            .es-wrapper-color {
                background-color: #f6f6f6;
            }
            
            .es-footer,
            .es-header {
                background-color: transparent;
            }
            
            .es-button-border,
            a.es-button,
            button.es-button {
                background: #fff;
                display: inline-block;
                width: auto;
            }
            
            .es-content-body,
            .es-footer-body,
            .es-header-body {
                background-color: #fff;
            }
            
            .es-content-body ol li,
            .es-content-body p,
            .es-content-body ul li,
            .es-footer-body ol li,
            .es-footer-body p,
            .es-footer-body ul li,
            .es-header-body ol li,
            .es-header-body p,
            .es-header-body ul li {
                color: #333;
                font-size: 14px;
            }
            
            .es-content-body a,
            .es-header-body a {
                color: #2cb543;
                font-size: 14px;
            }
            
            .es-footer-body a {
                color: #fff;
                font-size: 14px;
            }
            
            .es-infoblock,
            .es-infoblock ol li,
            .es-infoblock p,
            .es-infoblock ul li {
                line-height: 120%;
                font-size: 12px;
                color: #ccc;
            }
            
            .es-content-body h1 a,
            .es-footer-body h1 a,
            .es-header-body h1 a,
            h1 {
                font-size: 30px;
            }
            
            .es-infoblock a {
                font-size: 12px;
                color: #ccc;
            }
            
            h2 {
                font-size: 24px;
            }
            
            h3 {
                font-size: 20px;
            }
            
            .es-content-body h2 a,
            .es-footer-body h2 a,
            .es-header-body h2 a {
                font-size: 24px;
            }
            
            .es-content-body h3 a,
            .es-footer-body h3 a,
            .es-header-body h3 a {
                font-size: 20px;
            }
            
            a.es-button,
            button.es-button {
                padding: 10px 20px;
                border-radius: 0;
                font-size: 16px;
                font-family: 'times new roman', times, baskerville, georgia, serif;
                line-height: 120%;
                color: #00e;
                text-decoration: none;
                text-align: center;
                mso-padding-alt: 0;
                mso-border-alt: 10px solid #ffffff;
            }
            
            .es-button-border {
                border: 2px solid #000;
                border-radius: 0;
            }
            
            @media only screen and (max-width:600px) {
            
                .adapt-img,
                .es-adapt-td,
                .es-adaptive table,
                .es-left,
                .es-right {
                    width: 100% !important;
                }
            
                a,
                ol li,
                p,
                ul li {
                    line-height: 150% !important;
                }
            
                h1,
                h1 a,
                h2,
                h2 a,
                h3,
                h3 a {
                    line-height: 120% !important;
                }
            
                .es-content-body h1 a,
                .es-footer-body h1 a,
                .es-header-body h1 a,
                h1 {
                    font-size: 30px !important;
                }
            
                .es-content-body h2 a,
                .es-footer-body h2 a,
                .es-header-body h2 a,
                h2 {
                    font-size: 24px !important;
                }
            
                .es-content-body h3 a,
                .es-footer-body h3 a,
                .es-header-body h3 a,
                h3 {
                    font-size: 20px !important;
                }
            
                .es-content-body a,
                .es-content-body ol li,
                .es-content-body p,
                .es-content-body ul li,
                .es-footer-body a,
                .es-footer-body ol li,
                .es-footer-body p,
                .es-footer-body ul li,
                .es-header-body a,
                .es-header-body ol li,
                .es-header-body p,
                .es-header-body ul li,
                .es-menu td a {
                    font-size: 14px;
                }
            
                .es-infoblock a,
                .es-infoblock ol li,
                .es-infoblock p,
                .es-infoblock ul li {
                    font-size: 12px;
                }
            
                .es-hidden,
                .es-mobile-hidden,
                [class=gmail-fix] {
                    display: none !important;
                }
            
                .es-m-txt-c,
                .es-m-txt-c h1,
                .es-m-txt-c h2,
                .es-m-txt-c h3 {
                    text-align: center !important;
                }
            
                .es-m-txt-r,
                .es-m-txt-r h1,
                .es-m-txt-r h2,
                .es-m-txt-r h3 {
                    text-align: right !important;
                }
            
                .es-m-txt-l,
                .es-m-txt-l h1,
                .es-m-txt-l h2,
                .es-m-txt-l h3 {
                    text-align: left !important;
                }
            
                .es-m-txt-c img,
                .es-m-txt-l img,
                .es-m-txt-r img {
                    display: inline !important;
                }
            
                .es-button-border,
                table.es-social,
                table.es-social td {
                    display: inline-block !important;
                }
            
                a.es-button,
                button.es-button {
                    font-size: 18px;
                    display: inline-block;
                }
            
                .es-content,
                .es-content table,
                .es-footer,
                .es-footer table,
                .es-header,
                .es-header table {
                    width: 100% !important;
                    max-width: 600px !important;
                }
            
                .es-adapt-td {
                    display: block !important;
                }
            
                .es-desk-hidden,
                tr.es-desk-hidden {
                    display: table-row !important;
                }
            
                .adapt-img {
                    height: auto !important;
                }
            
                .es-m-p0 {
                    padding: 0 !important;
                }
            
                .es-m-p0r {
                    padding-right: 0 !important;
                }
            
                .es-m-p0l {
                    padding-left: 0 !important;
                }
            
                .es-m-p0t {
                    padding-top: 0 !important;
                }
            
                .es-m-p0b {
                    padding-bottom: 0 !important;
                }
            
                .es-m-p20b {
                    padding-bottom: 20px !important;
                }
            
                table.es-desk-hidden,
                td.es-desk-hidden,
                tr.es-desk-hidden {
                    width: auto !important;
                    overflow: visible !important;
                    float: none !important;
                    max-height: inherit !important;
                    line-height: inherit !important;
                }
            
                table.es-desk-hidden {
                    display: table !important;
                }
            
                td.es-desk-menu-hidden {
                    display: table-cell !important;
                }
            
                .es-menu td {
                    width: 1% !important;
                }
            
                .es-desk-hidden,
                .esd-block-html table,
                table.es-table-not-adapt {
                    width: auto !important;
                }
            
                .es-desk-hidden {
                    overflow: visible !important;
                    max-height: inherit !important;
                }
            }`,
            master: null,
            templates: [],
            activeTab: '1',
            pageSize: 10,
            pageIndex: 0,
            currentTemplateId: null,
            activeCountryTab: "Default",
            deleteModal: false,
            exportModal: false,
            clickTracking: true,
            openTracking: true,
            deleteIfExists: true,
            hasUnsubscribe: false,
            openAIModal: false,
            previewModal: false,
            allowHardWarning: false,
            hasModuleEgoi: false,
            hasModuleSendGrid: false,
            dynamic: true,
            previewText: null,
            previewTextChanged: false,
            versionsModal: false
        };
    }

    setWrapperRef(node) {
      this.wrapperRef = node;
    }

    componentWillMount() {
        this._isMounted = true;
        document.addEventListener("mousedown", this.handleClickOutside);
        window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
        document.addEventListener("keydown", this.onKeyPressed.bind(this));
    }

    handleClickOutside = (event) => {
        const { allowHardWarning, templates } = this.state;
        const { intl } = this.props;
        const unsavedChanges = templates?.filter(({unsavedChanges}) => unsavedChanges).length > 0;

        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && unsavedChanges && allowHardWarning) {
            const top = event.target.getBoundingClientRect().top;
            if(
                event.target.className &&
                top < 80 &&
                (!event.target.className.includes('color') &&
                !event.target.className.includes('esdev') &&
                !event.target.className.includes('strip') &&
                !event.target.className.includes('modal')                    
                )
            ){
                if(!window.confirm(intl.formatMessage({id: 'stripo.confirmUnsaved'}))){
                    event.preventDefault();
                }
                else{
                    this.setState({ allowHardWarning: false });
                };
            }
        }
    }

    handleBeforeUnload(e){
        const { templates } = this.state;
        if(templates.filter(({unsavedChanges}) => unsavedChanges).length > 0) {
            e.returnValue = 'Unsaved changes';
            return "Unsaved changes";
        };
    }

    onKeyPressed(e) {
        if(e && e.code === 'KeyS' && e.ctrlKey){
            this.onSubmit(e);
        }
    }
    
    componentDidMount(){
        window.setTimeout(
            () => {
                this.setState({
                    hasModuleEgoi: global.modules?.includes('Egoi'),
                    hasModuleSendGrid: global.modules?.includes('SendGrid')
                });
            },
            global.modules ? 0 : 2500
        );
        this.getMaster();
    }

    getAuth = async () => {
        return new Promise (resolve => getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            else if(data.errors && data.errors.length > 0){
                handleNotification(data);
                return;
            }
            else if (data?.response?.length > 0) {
                resolve(data.response[0]);
                return data.response[0];
            }
            else{
                handleNotification(data);
            }
        }, `/api/gms/Template/v1/authenticate`));
    }

    getMaster = () => {
        const { pageSize, pageIndex } = this.state;

        const groupId = this.props.match.params.groupId;
        const id = this.props.match.params.id;

        if(id) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    this.initializeStripo();
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.initializeStripo();
                    };
                    if (data.response?.length > 0) {
                        this.setState({ master: data.response[0] }, () => this.getEmails(data.response[0]?.id));
                    }
                    else{
                        handleNotification(data);
                        this.initializeStripo();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Template/v1/templates/master?id=${id}&pageSize=${pageSize}&pageIndex=${pageIndex}${groupId && groupId !== "null" ? `&groupId=${groupId}` : ''}`);
        }
        else{
            this.initializeStripo();
        }
    }

    getEmails = (masterId) => {
        let { pageSize, pageIndex, allowHardWarning } = this.state;

        const copyId = this.props.match.params.duplicate;

        if(copyId && copyId !== "false"){
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    this.initializeStripo();
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.initializeStripo();
                    };
                    if(data.response?.length > 0){
                        allowHardWarning = true;
                        this.setState({ templates: data.response.map(t =>{
                            t.unsavedChanges = true;
                            t.templateMasterId = copyId;
                            t.id = Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1));
                            t.isNew = true;
                            return t
                        }) }, () => this.initializeStripo());
                    }
                    else{
                        this.initializeStripo();
                    }
                }
                this.setState({ error: errorMessage, block: false, allowHardWarning });
            }, `/api/gms/Template/v1/templates?masterId=${copyId}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        }
        else if(masterId) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    this.initializeStripo();
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.initializeStripo();
                    };
                    if(data.response?.length > 0){
                        this.setState({ templates: data.response }, () => this.initializeStripo());
                    }
                    else{
                        this.initializeStripo();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Template/v1/templates?masterId=${masterId}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        }
        else{
            this.initializeStripo();
        }
    }

    initializeStripo = (id) => {
        const { customTagsEgoi, customTagsEgoiVoucher, customTagsSendGrid, customTagsSendGridVoucher, hasModuleSendGrid, templates, specialLinks, specialLinksSendGrid } = this.state;
        const newTemplates = [];

        let content = this.state.content;
        let style = this.state.style;

        let defaultTemplate = id ? templates?.find((t) => t.id === id) : templates?.find(({isDefault}) => isDefault);

        if(defaultTemplate){
            content = defaultTemplate.content;
            style = defaultTemplate.style;
        }
        else if(templates && templates.length === 0){
            defaultTemplate = {
                isDefault: true,
                id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)),
                content: content,
                style: style,
                unsavedChanges: true,
                isNew: true
            };
            newTemplates.push(defaultTemplate);
            this.setState({ templates: newTemplates, allowHardWarning: true });
        }
        else{
            defaultTemplate = templates[0];
            defaultTemplate.isDefault = true;
            defaultTemplate.country = null;
            defaultTemplate.unsavedChanges = true;
            content = defaultTemplate.content;
            style = defaultTemplate.style;
            this.setState({ templates, allowHardWarning: true });
        }

        if (defaultTemplate.content.includes('class="PreviewText"')) {
            var previewText = document.createElement('html')
            previewText.innerHTML = defaultTemplate.content.trim();

            let newPreviewText = previewText.getElementsByClassName("PreviewText")[0].innerHTML;
            this.setState({ previewText: newPreviewText });
        }

        const isSharedAccount = global.modules?.includes(m => m === 'StripoSharedAccount');

        const randomId = defaultTemplate?.id;
        const hasHeyCard = global.modules?.some(m => m === 'HeyCard');
        this.setState({ currentTemplateId: randomId, block: false }, () =>
            window.Stripo.init({
                settingsId: 'stripoSettingsContainer',
                previewId: 'stripoPreviewContainer',
                codeEditorButtonId: 'codeEditor',
                locale: 'en',
                html: content,
                css: style,
                onTemplateLoaded: this.onTemplateLoaded,
                conditionsEnabled: true,
                customConditionsEnabled: true,
                onDataChanged: this.updateUnsavedChanges,
                mergeTags: [{
                    "category": "Profile Now",
                    "entries": hasModuleSendGrid ? (hasHeyCard ? customTagsSendGrid.concat(customTagsSendGridVoucher) : customTagsSendGrid) : (hasHeyCard ? customTagsEgoi.concat(customTagsEgoiVoucher) : customTagsEgoi)
                }],
                specialLinks: hasModuleSendGrid ? specialLinksSendGrid : specialLinks,
                apiRequestData: {
                    emailId: !isSharedAccount ? 1 : randomId,
                    userId: global.hotelGroupId
                },
                userFullName: 'Profile Now',
                getAuthToken:(callback) => {
                    this.getAuth().then(val => 
                        callback(val)
                    );
                }
            })
        );
    }
    
    componentWillUnmount(){
        this._isMounted = false;
        document.removeEventListener("mousedown", this.handleClickOutside);
        document.removeEventListener("keydown", this.onKeyPressed.bind(this));
        window.removeEventListener('beforeunload', this.handleBeforeUnload.bind(this));

        if(window.StripoApi)
            window.StripoApi.stop();
    }

    onTemplateLoaded = () => {
        this.setState({ block: false }, () => this.validateUnsubscribeTag());
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        const { currentTemplateId, templates, master, previewText, previewTextChanged } = this.state;
        const copy = [...templates];
        const currTemplate = copy.find(({ id }) => id === currentTemplateId);

        var previewFillText = "&#847; &zwnj; &nbsp; &#8199; &shy;";
        var fillText = "&#847; &zwnj; &nbsp; &#8199; &shy;";
        var i = previewText?.length??0;
        while (i < 400) {
            previewFillText = previewFillText + fillText;
            i++;
        }
        

        window.StripoApi.getTemplate((html, css) => {
            if (previewTextChanged) {
                var previewTextUpdate = document.createElement('html')
                previewTextUpdate.innerHTML = html.trim();
                if (html.includes('class="PreviewText"')) {
                    previewTextUpdate.getElementsByClassName("PreviewText")[0].innerHTML = previewText;
                    if (html.includes('class="PreviewTextFill"')) {
                        previewTextUpdate.getElementsByClassName("PreviewTextFill")[0].innerHTML = previewFillText;
                    } else {
                        var tr = previewTextUpdate.getElementsByTagName("tr")[0];
                        var td2 = document.createElement('td');
                        td2.className = "PreviewTextFill";
                        td2.innerHTML = previewFillText;
                        tr.appendChild(td2);
                    }
                               
                    html = previewTextUpdate.innerHTML;
                } else {
                    var bodyPreviewText = document.createElement('body');
                    bodyPreviewText.innerHTML = previewTextUpdate.childNodes[1].innerHTML;
                    var table = document.createElement('table');
                    var tbody = document.createElement('tbody');
                    var tr = document.createElement('tr');
                    var td = document.createElement('td');
                    var td2 = document.createElement('td');
                    table.style.cssText = "display:none";
                    td.className = "PreviewText";
                    td.innerHTML = previewText;
                    td2.className = "PreviewTextFill";
                    td2.innerHTML = previewFillText;
                    tr.appendChild(td);
                    tr.appendChild(td2);
                    tbody.appendChild(tr)
                    table.appendChild(tbody);
                    bodyPreviewText.insertBefore(table, bodyPreviewText.firstChild);
                    previewTextUpdate.childNodes[1].innerHTML = bodyPreviewText.innerHTML;
                    html = previewTextUpdate.innerHTML;
                }
            }

            const body = {
                id: currTemplate.isNew ? null : currentTemplateId,
                content: html,
                style: css,
                country: currTemplate.country,
                subject: currTemplate.subject,
                templateMasterId: master?.id,
                isDefault: currTemplate.isDefault
            };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };

                if (data && data.response?.length > 0) {
                    currTemplate.unsavedChanges = false;
                    currTemplate.id = data.response[0]?.id;
                    currTemplate.isNew = false;
                    currTemplate.content = html;
                    currTemplate.style = css;
                    this.setState({ block: false, templates: copy, currentTemplateId: currTemplate.id },
                        () => {
                            handleNotification(data, null, <FormattedMessage id="stripo.saved" />);
                            this.validateUnsubscribeTag();
                            this.initializeStripo(currTemplate.id);
                        }
                    );
                    
                    return;
                }
                else{
                    handleNotification(data);
                }

                this.setState({ block: false });
            }, `/api/gms/Template/v1/templates`, body);
        }, false);
    }

    validateUnsubscribeTag = () => {
        const { currentTemplateId, templates } = this.state;

        const currTemplate = templates.find(({id}) => id === currentTemplateId);

        this.setState({ hasUnsubscribe: currTemplate.content?.includes('{REMOVE_LINK}') });
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val });
    }

    toggleActiveCountryTab = (val) => {
        const { templates, currentTemplateId } = this.state;
        const copy = [...templates]

        const id = val === "Default" ? copy.find(({isDefault}) => isDefault)?.id : copy[val]?.id;
        const curr = copy.find((t) => t.id === currentTemplateId);

        if(currentTemplateId !== id){
            this.setState({ block: true }, () =>{
                try{
                    window.StripoApi.getTemplate((html, css) => {
                        if(curr){
                            curr.content = html;
                            curr.style = css;
                        }
                        this.setState({ activeCountryTab: val, currentTemplateId: id, templates: copy }, () => this.restartStripo(id));
                    })
                }
                catch(e) {
                    console.error(e);
                }
            });
        };
    }

    restartStripo = (id) => {
        window.StripoApi.stop();
        this.initializeStripo(id);
    }

    addTab = (currId) => {
        const { templates, master, style, content } = this.state;

        const copyTemplate = currId ? templates.find(({id}) => id === currId) : null;

        const newTemplate =  {
            content: content,
            country: null,
            isDefault: false,
            style: style,
            subject: '',
            templateMasterId: master?.id,
            id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)),
            isNew: true,
            unsavedChanges: true
        }

        if(copyTemplate){
            newTemplate.subject = copyTemplate.subject;
            window.StripoApi.getTemplate((html, css) => {
                newTemplate.content = html;
                newTemplate.style = css;

                while(templates.filter(({id}) => id === newTemplate.id).length > 0){
                    newTemplate.id = Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1));
                };
        
                const copy = [...templates];
        
                copy.push(newTemplate);
        
                this.setState({ templates: copy, block: true, allowHardWarning: true }, () => this.toggleActiveCountryTab(copy.findIndex(({id}) => id === newTemplate.id)));
            });
        }
        else{

            while(templates.filter(({id}) => id === newTemplate.id).length > 0){
                newTemplate.id = Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1));
            };
    
            const copy = [...templates];
    
            copy.push(newTemplate);
    
            this.setState({ templates: copy, currentTemplateId: newTemplate.id, allowHardWarning: true, activeCountryTab: copy.findIndex(({id}) => id === newTemplate.id), block: true }, () => this.restartStripo(newTemplate.id));
        }
    }

    handleCountry = (combo, action) => {
        const { currentTemplateId, templates } = this.state;

        const copy = [...templates];
        const emailTemplate = copy.find(({id}) => id === currentTemplateId);

        emailTemplate.country = combo?.value;
        emailTemplate.unsavedChanges = true;

        this.setState({ templates: copy, allowHardWarning: true });
    }

    deleteTemplate = () => {
        const { currentTemplateId, templates } = this.state;

        const isNew = this.props.match.params.id ? false : true;

        const copy = [...templates];
        const currTemplate = copy.find(({id}) => id === currentTemplateId);
        if(currTemplate.isNew || isNew){
            copy.splice(templates.findIndex(({id}) => id === currentTemplateId), 1);
            this.setState({ templates: copy, deleteModal: false }, () => this.toggleActiveCountryTab("Default"));
            return;
        };

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };

                const defaultId = copy.find(({isDefault}) => isDefault).id;

                copy.splice(templates.findIndex(({id}) => id === currentTemplateId), 1);

                this.setState({ block: false, templates: copy, currentTemplateId: defaultId, activeCountryTab: 'Default', deleteModal: false }, () => {
                    handleNotification(data,<FormattedMessage id="stripo.successfulyDeleted" />, <FormattedMessage id="AdminUserManagement.Success" />);
                    this.restartStripo(defaultId);
                });
            },`/api/gms/Template/v1/templates/${currentTemplateId}`)
        );
    }

    copyTemplate = () => {
        const { currentTemplateId } = this.state;

        this.addTab(currentTemplateId);
    }

    handleSubject = (e) => {
        e.preventDefault();
        const { currentTemplateId, templates } = this.state;

        const { value } = e?.target;

        const copy = [...templates];
        const emailTemplate = copy.find(({id}) => id === currentTemplateId);

        emailTemplate.subject = value;
        emailTemplate.unsavedChanges = true;

        this.setState({ templates: copy, allowHardWarning: true });
    }

    handlePreview = (e) => {
        const { currentTemplateId, templates } = this.state;

        const { value } = e?.target;

        const copy = [...templates];
        const emailTemplate = copy.find(({ id }) => id === currentTemplateId);

        emailTemplate.unsavedChanges = true;
        this.setState({ templates: copy, allowHardWarning: true, previewText: value, previewTextChanged: true });
    }

    toggleDeleteModal = () => {
        this.setState(prev => ({ deleteModal: !prev.deleteModal }));
    }

    updateUnsavedChanges = (currId) => {
        const { currentTemplateId, templates } = this.state;
        let emailTemplate;

        if(currId){
            emailTemplate = templates.find(({id}) => id === currId);
        }
        else{
            emailTemplate = templates.find(({id}) => id === currentTemplateId);
        }

        emailTemplate.unsavedChanges = true;

        this.setState({ templates, allowHardWarning: true });
        return;
    }

    exportTemplate = (e) => {
        e.preventDefault();
        const { currentTemplateId, openTracking, clickTracking, templates, deleteIfExists, dynamic } = this.state;
        window.StripoApi.compileEmail((e, html) => {
            if(e){
                this.setState({ error: { message: e, messageType: 'danger' }, block: false });
            };

            const body = JSON.stringify(html);
            this.setState({ block: true }, () =>
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false, exportModal: false });
                        return;
                    };
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                        return;
                    };
                    const copy = [...templates];
                    const currTemplate = copy?.find(({id}) => id === currentTemplateId);

                    currTemplate.exportId = data.response[0];
                    currTemplate.exportDate = moment().format('YYYY/MM/DD');

                    this.setState({ block: false, exportModal: false, templates: copy },
                        () => handleNotification(data,<FormattedMessage id="stripo.successfulyExported" />, <FormattedMessage id="AdminUserManagement.Success" />)
                    );
                },`/api/gms/Template/v1/templates/${currentTemplateId}/export?clickTracking=${clickTracking}&openTracking=${openTracking}&deleteIfExists=${deleteIfExists}&dynamic=${dynamic}`, body)
            );
        }, true)
    }

    toggleExportModal = () => {
        this.setState(prev => ({ exportModal: !prev.exportModal }));
    }

    handleInput = (e) => {
        const { checked, name } = e?.target;
        this.setState({ [name]: checked });
    }

    toggleOpenAIModal = (e) => {
        e.preventDefault();
        this.setState(prev => ({ openAIModal: !prev.openAIModal }));
    }

    togglePreviewModal = () => {
        const { previewModal } = this.state;
        if(previewModal){
            this.setState({ previewModal: false, compiledHtml: '' });
        }
        else{
            window.StripoApi.compileEmail((e, html) => {
                if(e){
                    this.setState({ error: { message: e, messageType: 'danger' }, block: false });
                    return;
                };
                this.setState({ previewModal: !previewModal, compiledHtml: html });
            });
        }
    }

    toggleVersionsModal = () => {
        this.setState(prevstate => ({ versionsModal: !prevstate.versionsModal }));
    }

    render() {
        const { block, error, compiledHtml, currentTemplateId, master, templates, activeTab, activeCountryTab, deleteModal, exportModal, openTracking, deleteIfExists,
            clickTracking, hasUnsubscribe, openAIModal, previewModal, hasModuleEgoi, hasModuleSendGrid, dynamic, previewText, versionsModal } = this.state;
        const { intl } = this.props;

        const emailTemplate = templates?.find(({id}) => id === currentTemplateId);
        const countryOptions = countryList().getData();
        return (
            <StyledCard block={block} error={error}>
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/EmailTemplate">{master?.type??"Emails"}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>
                        {master ? master.name : <FormattedMessage id="stripo.createNewTemplate"/>}
                    </BreadcrumbItem>
                </Breadcrumb>
                <div ref={this.setWrapperRef}>
                    <OpenAi
                        openAIModal={openAIModal}
                        toggleOpenAIModal={this.toggleOpenAIModal}
                    />

                    {deleteModal ?
                        <ConfirmActionModal
                            block={block}
                            error={error}
                            text={intl.formatMessage({ id: "stripo.deleteConfirm" })}
                            toggleModal={this.toggleDeleteModal}
                            modal={deleteModal}
                            actionFunction={this.deleteTemplate}
                        />
                    :''}
                    <div>
                        {previewModal ?
                            <Modal isOpen={previewModal} toggle={this.togglePreviewModal}>
                                <ModalBody className='p-4' id="StripoPreview">
                                    <Row>
                                        <Col>
                                            <h5><FormattedMessage id="stripo.Preview"/></h5>
                                        </Col>
                                    </Row>
                                    <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <PreviewDesktop
                                            compiledHtml={compiledHtml}
                                        />
                                        <PreviewMobile
                                            compiledHtml={compiledHtml}
                                        />
                                    </div>
                                </ModalBody>
                            </Modal>
                        :''}
                    </div>

                    {versionsModal ? 
                        <TemplateVersions 
                            togglePreviewModal={this.toggleVersionsModal}
                            previewModal={versionsModal}
                            currentTemplateId={currentTemplateId}
                            intl={intl}
                        />
                    :''}

                    {exportModal ?
                        <Modal style={{ display: 'flex', height: '90%', alignItems: 'center', justifyContent: 'center' }} isOpen={exportModal} className="smallModal">
                            <Button onClick={this.toggleExportModal} className="closeModal">
                                <i className="fas fa-times fa-sm"></i>
                            </Button>
                            <ModalBody className='p-4' style={{ borderRadius: '8px' }}>
                                <Form onSubmit={this.exportTemplate}>
                                    <Row>
                                        <Col className='text-center'>
                                            <h4><FormattedMessage id="stripo.exportTemplate"/></h4>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col>
                                            <b><FormattedMessage id="stripo.name"/></b>
                                        </Col>
                                        <Col>
                                            {master?.name}
                                        </Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Col>
                                            <b><FormattedMessage id="stripo.subject"/></b>
                                        </Col>
                                        <Col>
                                            {emailTemplate?.subject}
                                        </Col>
                                    </Row>
                                    {emailTemplate?.exportDate ?
                                        <Row className='my-1'>
                                            <Col>
                                                <b><FormattedMessage id="stripo.exportDate"/></b>
                                            </Col>
                                            <Col>
                                                {moment(emailTemplate?.exportDate).format('YYYY-MM-DD')}
                                            </Col>
                                        </Row>
                                    :''}
                                    {hasModuleEgoi ?
                                        <>
                                            <Row className='my-1'>
                                                <Col>
                                                    <b><FormattedMessage id="stripo.clickTracking"/></b>
                                                </Col>
                                                <Col>
                                                    <CustomInput
                                                        type="switch"
                                                        name="clickTracking"
                                                        id="clickTracking"
                                                        checked={clickTracking}
                                                        onChange={this.handleInput}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='my-1'>
                                                <Col>
                                                    <b><FormattedMessage id="stripo.openTracking"/></b>
                                                </Col>
                                                <Col>
                                                    <CustomInput
                                                        type='switch'
                                                        name="openTracking"
                                                        id="openTracking"
                                                        checked={openTracking}
                                                        onChange={this.handleInput}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='my-1'>
                                                <Col>
                                                    <b><FormattedMessage id="stripo.deleteIfExists"/></b>
                                                    <div className='text-muted' style={{ fontSize: '0.8em' }}><FormattedMessage id="stripo.deleteIfExistsWarning"/></div>
                                                </Col>
                                                <Col>
                                                    <CustomInput
                                                        type='switch'
                                                        name="deleteIfExists"
                                                        id="deleteIfExists"
                                                        checked={deleteIfExists}
                                                        onChange={this.handleInput}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    :
                                        <Row className='my-1'>
                                            <Col>
                                                <b><FormattedMessage id="stripo.dynamic"/></b>
                                                <div className='text-muted' style={{ fontSize: '0.8em' }}><FormattedMessage id="stripo.dynamicExplain"/></div>
                                            </Col>
                                            <Col>
                                                <CustomInput
                                                    type='switch'
                                                    name="dynamic"
                                                    id="dynamic"
                                                    checked={dynamic}
                                                    onChange={this.handleInput}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    <Row className='mt-3'>
                                        <Col className='text-center'>
                                            <Button style={{ fontSize: '1em' }} disabled={!emailTemplate?.subject} className="btn btn-sm btn-host">
                                                <FormattedMessage id="stripo.export"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </ModalBody>
                        </Modal>
                    :''}
                    <Row>
                        <Col>
                            <Nav tabs className="border-0 mb-3">
                                <NavItem>
                                    <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                        <b><FormattedMessage id="stripo.config"/></b>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                        <b><FormattedMessage id="stripo.edition" /></b>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col>
                            {master?.type === "Email" ?
                                <Nav tabs>
                                    {templates.sort((a, b) => a.isDefault ? -1 : 0)?.map((t, key) =>
                                        t.isDefault ?
                                            <NavItem key={key}>
                                                <NavLink className={activeCountryTab === 'Default' ? 'tab-border-host text-host border-0 cursor-pointer' : 'border-0 cursor-pointer'} onClick={() => this.toggleActiveCountryTab('Default')}>
                                                    <span className='mb-2' /> Default
                                                    {t.unsavedChanges ?
                                                        <span className='stripoUnsavedChanges'/>
                                                    :''}
                                                </NavLink>
                                            </NavItem>
                                        :
                                            <NavItem key={key} className="cursor-pointer">
                                                <NavLink className={activeCountryTab === key ? 'tab-border-host text-host border-0' : 'border-0'} onClick={() => this.toggleActiveCountryTab(key)}>
                                                    <span className={`flag-icon flag-icon-${t.country && t.country.toLowerCase()} mb-2 px-2`} />
                                                    {t.unsavedChanges ?
                                                        <span className='stripoUnsavedChanges'/>
                                                    :''}
                                                </NavLink>
                                            </NavItem>
                                    )}
                                    <NavItem>
                                        <NavLink className="d-flex h-100">
                                            <div>
                                                <Button onClick={this.addTab} className=" btn-host btn-sm " style={{ 'fontSize': '0.35rem' }}  >
                                                    <i className="fas fa-plus"/>
                                                </Button>
                                            </div>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            :''}
                        </Col>
                        <Col className='text-right'>
                            <Authorization
                                perform="GPT:usage"
                                yes={() => (
                                    global.modules && global.modules.some(m => m === 'ChatGPT') &&
                                        <>
                                            <Button style={{ height: '35px', width: '35px' }} type="button" onClick={this.toggleOpenAIModal} id="contentIdeas" className="btn btn-sm btn-host btn btn-secondary p-2 control-button mr-2">
                                                <i className="fas fa-lightbulb" />
                                            </Button>
                                                <UncontrolledTooltip placement="bottom" target={`contentIdeas`}>
                                                    <FormattedMessage id="stripo.contentIdeasToolTip" />
                                            </UncontrolledTooltip>
                                        </>
                                )}
                            />
                            <Button type='button' onClick={this.togglePreviewModal} style={{ height: '35px', width: '35px' }} title='Preview' className="btn btn-sm btn-host mr-2 btn p-2 btn-secondary control-button">
                                <i className="fas fa-eye"/>
                            </Button>
                            <Button style={{ height: '35px' }} id="codeEditor" className="btn btn-sm btn-host mr-1 btn btn-secondary p-2 control-button">
                                <b>{`</>`}</b>
                            </Button>
                        </Col>
                    </Row>
                    {activeTab === '0' ?
                        <Card style={{ height: '85%', width: '33.3333%', position: 'absolute', zIndex: '10', border: '0' }}>
                            <CardBody>
                                <Form className='py-2' onSubmit={this.onSubmit}>
                                    <Row>
                                        <Col className='col-2'>
                                            {emailTemplate?.exportId ?
                                                <Badge color="primary"><FormattedMessage id="stripo.exported"/></Badge>
                                            :''}
                                        </Col>
                                        <Col>
                                            {!hasUnsubscribe ?
                                                <Badge color="danger"><FormattedMessage id="stripo.noUnsubscribe"/></Badge>
                                            :''}
                                        </Col>
                                        <Col className='text-right'>
                                            {master?.type === "Email" ?
                                                <>
                                                    <Authorization
                                                        perform="admin:view"
                                                        yes={() => (
                                                            <Button className="btn btn-sm btn-host mr-1" onClick={this.toggleVersionsModal} title={intl.formatMessage({ id: "stripo.versions" })}>
                                                                <i className="fas fa-history"></i>
                                                            </Button>
                                                        )}
                                                        no={() => <div></div>} 
                                                    />
                                                    <Button className="btn btn-sm btn-host mr-1" onClick={this.copyTemplate} title={intl.formatMessage({ id: "stripo.duplicate" })}>
                                                        <i className="fas fa-copy"/>
                                                    </Button>
                                                    {hasModuleEgoi || hasModuleSendGrid ?
                                                        <Button
                                                            className='btn btn-host btn-sm mr-1'
                                                            disabled={!emailTemplate?.subject || emailTemplate.unsavedChanges}
                                                            onClick={this.toggleExportModal}
                                                            title={intl.formatMessage({ id: "stripo.publish" })}>
                                                            <i className="fas fa-envelope" />
                                                        </Button>
                                                    :''}
                                                    <Button className="btn btn-sm btn-delete mr-1" disabled={activeCountryTab === "Default"} title={intl.formatMessage({ id: "stripo.delete" })} onClick={this.toggleDeleteModal}>
                                                        <i className="far fa-trash-alt"/>
                                                    </Button>
                                                </>
                                            :''}
                                            <Button type='submit' className="btn btn-host btn-sm" title={intl.formatMessage({ id: "stripo.save" })}>
                                                <i className="fas fa-save"/>
                                                {emailTemplate?.unsavedChanges ?
                                                    <span className='stripoUnsavedChanges'/>
                                                :''}
                                            </Button>
                                        </Col>
                                    </Row>
                                    {master?.type === "Email" ?
                                        <>
                                            <Row className='my-2'>
                                                <Col className='col-3' style={{ display: 'flex', alignItems: 'center' }}>
                                                    <b><FormattedMessage id="stripo.subject"/></b>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        type='text'
                                                        required
                                                        maxLength={'100'}
                                                        name='subject'
                                                        value={emailTemplate?.subject ? emailTemplate.subject : ''}
                                                        onChange={this.handleSubject}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col className='col-3' style={{ display: 'flex', alignItems: 'center' }}>
                                                    <b><FormattedMessage id="stripo.preview"/></b>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        type='text'
                                                        maxLength={'400'}
                                                        name='preview'
                                                        value={previewText ? previewText : ''}
                                                        onChange={this.handlePreview}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    :''}
                                    {activeCountryTab !== "Default" ?
                                        <Row className='my-2'>
                                            <Col className='col-3' style={{ display: 'flex', alignItems: 'center' }}>
                                                <b><FormattedMessage id="stripo.country"/></b>
                                            </Col>
                                            <Col>
                                                <CustomSelect
                                                    options={countryOptions}
                                                    required
                                                    isOptionDisabled={(option) => templates.filter(({country}) => country === option.value).length > 0}
                                                    isSearchable
                                                    placeholder={""}
                                                    onChange={this.handleCountry}
                                                    value={emailTemplate?.country ? countryOptions?.find(c => c.value === emailTemplate?.country?.toUpperCase()) : null}
                                                    enableRequired
                                                />
                                            </Col>
                                        </Row>
                                    :''}
                                    <Row className='my-2'>
                                        <Col className='col-3'>
                                            <b><FormattedMessage id="stripo.lastUpdate"/></b>
                                        </Col>
                                        <Col>
                                            {moment(emailTemplate?.updatedAt).format('YYYY-MM-DD')}
                                        </Col>
                                    </Row>
                                    {emailTemplate?.exportId && hasModuleEgoi ?
                                        <>
                                            <Row className='my-2'>
                                                <Col className='col-3'>
                                                    <b><FormattedMessage id="stripo.exportDate"/></b>
                                                </Col>
                                                <Col>
                                                    {moment(emailTemplate?.exportDate).format('YYYY-MM-DD')}
                                                </Col>
                                            </Row>
                                            <Row className='my-2'>
                                                <Col className='col-3'>
                                                    <b><FormattedMessage id="stripo.exportId"/></b>
                                                </Col>
                                                <Col>
                                                    {emailTemplate?.exportId}
                                                </Col>
                                            </Row>
                                        </>
                                    :''}
                                </Form>
                            </CardBody>
                        </Card>
                    :''}
                    <Row>
                        <Col className='col-4' id="stripoSettingsContainer"></Col>
                        <Col id="stripoPreviewContainer"></Col>
                    </Row>
                </div>
            </StyledCard>
        )
    }
}

export default injectIntl(Stripo)