import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment'
import 'moment-timezone';
import * as countryList from 'react-select-country-list';
import { Badge } from 'reactstrap';
import egoi from '../../img/egoi.png';
import reviewPro from '../../img/LogoReviewPro.png';
import roiback from '../../img/roiback.jpg';
import profileNow from '../../img/profileNow.png';
import typeform from '../../img/Typeform.ico';


export function getCurrency() {
    const opt = [
        {
            "value": "EUR",
            "label": "EUR (€)"
        },
        {
            "value": "USD",
            "label": "USD ($)"
        },
        {
            "value": "BRL",
            "label": "BRL (R$)"
        },
        {
            "value": "CAD",
            "label": "CAD (C$)"
        },
        {
            "value": "GBP",
            "label": "GBP (£)"
        },
        {
            "value": "CHF",
            "label": "CHF (₣)"
        }
    ];
    return opt;
};

export function getSalesDealActionTypes(intl, creatable, actionable) {
    const opt = [
        { label: intl.formatMessage({ id: `SalesProcess.Email` }), icon: 'far fa-envelope', value: 'Email', actionable: true, creatable: true },
        { label: intl.formatMessage({ id: `SalesProcess.Call` }), icon: 'fas fa-phone', value: 'Call', actionable: true, creatable: true },
        { label: intl.formatMessage({ id: `SalesProcess.Note` }), icon: 'far fa-sticky-note', value: 'Note', actionable: false, creatable: true },
        { label: intl.formatMessage({ id: `SalesProcess.File` }), icon: 'fas fa-file', value: 'File', actionable: false, creatable: true },
        { label: intl.formatMessage({ id: `SalesProcess.Meeting` }), icon: 'far fa-building', value: 'Meeting', actionable: true, creatable: true },
        { label: intl.formatMessage({ id: `SalesProcess.Status` }), icon: 'fas fa-power-off', value: 'Status', actionable: false, creatable: false },
        { label: intl.formatMessage({ id: `SalesProcess.Stage` }), icon: 'fas fa-power-off', value: 'Stage', actionable: false, creatable: false }
    ];

    return opt
        .filter(a => actionable === undefined || a.actionable === actionable)
        .filter(a => creatable === undefined || a.creatable === creatable);
};


export function getSendGridScopes() {
    const scopes = [
        "access_settings.activity.read",
        "access_settings.whitelist.create",
        "access_settings.whitelist.delete",
        "access_settings.whitelist.read",
        "access_settings.whitelist.update",
        "alerts.create",
        "alerts.delete",
        "alerts.read",
        "alerts.update",
        "api_keys.create",
        "api_keys.delete",
        "api_keys.read",
        "api_keys.update",
        "asm.groups.create",
        "asm.groups.delete",
        "asm.groups.read",
        "asm.groups.update",
        "billing.create",
        "billing.delete",
        "billing.read",
        "billing.update",
        "browsers.stats.read",
        "categories.create",
        "categories.delete",
        "categories.read",
        "categories.stats.read",
        "categories.stats.sums.read",
        "categories.update",
        "clients.desktop.stats.read",
        "clients.phone.stats.read",
        "clients.stats.read",
        "clients.tablet.stats.read",
        "clients.webmail.stats.read",
        "credentials.create",
        "credentials.delete",
        "credentials.read",
        "credentials.update",
        "devices.stats.read",
        "email_activity.read",
        "geo.stats.read",
        "ips.assigned.read",
        "ips.pools.create",
        "ips.pools.delete",
        "ips.pools.ips.create",
        "ips.pools.ips.delete",
        "ips.pools.ips.read",
        "ips.pools.ips.update",
        "ips.pools.read",
        "ips.pools.update",
        "ips.read",
        "ips.warmup.create",
        "ips.warmup.delete",
        "ips.warmup.read",
        "ips.warmup.update",
        "mail_settings.address_whitelist.read",
        "mail_settings.address_whitelist.update",
        "mail_settings.bcc.read",
        "mail_settings.bcc.update",
        "mail_settings.bounce_purge.read",
        "mail_settings.bounce_purge.update",
        "mail_settings.footer.read",
        "mail_settings.footer.update",
        "mail_settings.forward_bounce.read",
        "mail_settings.forward_bounce.update",
        "mail_settings.forward_spam.read",
        "mail_settings.forward_spam.update",
        "mail_settings.plain_content.read",
        "mail_settings.plain_content.update",
        "mail_settings.read",
        "mail_settings.spam_check.read",
        "mail_settings.spam_check.update",
        "mail_settings.template.read",
        "mail_settings.template.update",
        "mail.batch.create",
        "mail.batch.delete",
        "mail.batch.read",
        "mail.batch.update",
        "mail.send",
        "mailbox_providers.stats.read",
        "marketing_campaigns.create",
        "marketing_campaigns.delete",
        "marketing_campaigns.read",
        "marketing_campaigns.update",
        "newsletter.create",
        "newsletter.delete",
        "newsletter.read",
        "newsletter.update",
        "partner_settings.new_relic.read",
        "partner_settings.new_relic.update",
        "partner_settings.read",
        "partner_settings.sendwithus.read",
        "partner_settings.sendwithus.update",
        "stats.global.read",
        "stats.read",
        "subusers.create",
        "subusers.credits.create",
        "subusers.credits.delete",
        "subusers.credits.read",
        "subusers.credits.remaining.create",
        "subusers.credits.remaining.delete",
        "subusers.credits.remaining.read",
        "subusers.credits.remaining.update",
        "subusers.credits.update",
        "subusers.delete",
        "subusers.monitor.create",
        "subusers.monitor.delete",
        "subusers.monitor.read",
        "subusers.monitor.update",
        "subusers.read",
        "subusers.reputations.read",
        "subusers.stats.monthly.read",
        "subusers.stats.read",
        "subusers.stats.sums.read",
        "subusers.summary.read",
        "subusers.update",
        "suppression.blocks.create",
        "suppression.blocks.delete",
        "suppression.blocks.read",
        "suppression.blocks.update",
        "suppression.bounces.create",
        "suppression.bounces.delete",
        "suppression.bounces.read",
        "suppression.bounces.update",
        "suppression.create",
        "suppression.delete",
        "suppression.invalid_emails.create",
        "suppression.invalid_emails.delete",
        "suppression.invalid_emails.read",
        "suppression.invalid_emails.update",
        "suppression.read",
        "suppression.spam_reports.create",
        "suppression.spam_reports.delete",
        "suppression.spam_reports.read",
        "suppression.spam_reports.update",
        "suppression.unsubscribes.create",
        "suppression.unsubscribes.delete",
        "suppression.unsubscribes.read",
        "suppression.unsubscribes.update",
        "suppression.update",
        "templates.create",
        "templates.delete",
        "templates.read",
        "templates.update",
        "templates.versions.activate.create",
        "templates.versions.activate.delete",
        "templates.versions.activate.read",
        "templates.versions.activate.update",
        "templates.versions.create",
        "templates.versions.delete",
        "templates.versions.read",
        "templates.versions.update",
        "tracking_settings.click.read",
        "tracking_settings.click.update",
        "tracking_settings.google_analytics.read",
        "tracking_settings.google_analytics.update",
        "tracking_settings.open.read",
        "tracking_settings.open.update",
        "tracking_settings.read",
        "tracking_settings.subscription.read",
        "tracking_settings.subscription.update",
        "user.account.read",
        "user.credits.read",
        "user.email.create",
        "user.email.delete",
        "user.email.read",
        "user.email.update",
        "user.multifactor_authentication.create",
        "user.multifactor_authentication.delete",
        "user.multifactor_authentication.read",
        "user.multifactor_authentication.update",
        "user.password.read",
        "user.password.update",
        "user.profile.read",
        "user.profile.update",
        "user.scheduled_sends.create",
        "user.scheduled_sends.delete",
        "user.scheduled_sends.read",
        "user.scheduled_sends.update",
        "user.settings.enforced_tls.read",
        "user.settings.enforced_tls.update",
        "user.timezone.read",
        "user.username.read",
        "user.username.update",
        "user.webhooks.event.settings.read",
        "user.webhooks.event.settings.update",
        "user.webhooks.event.test.create",
        "user.webhooks.event.test.read",
        "user.webhooks.event.test.update",
        "user.webhooks.parse.settings.create",
        "user.webhooks.parse.settings.delete",
        "user.webhooks.parse.settings.read",
        "user.webhooks.parse.settings.update",
        "user.webhooks.parse.stats.read",
        "whitelabel.create",
        "whitelabel.delete",
        "whitelabel.read",
        "whitelabel.update"
    ]
      
    const opt = scopes.map((s) => ({ value: s, label: s }));
    return opt;
};

export function getWhastAppStatus() {
    return [
        { value: 'IN_REVIEW', badgeColor: '#ffc107' },
        { value: 'PENDING', badgeColor: '#ffc107' },
        { value: 'NEW', badgeColor: '#0665ff' },
        { value: 'REJECTED', badgeColor: '#dc3545' },
        { value: 'ACTIVE_QUALITY_PENDING', badgeColor: '#007bff' },
        { value: 'ACTIVE_HIGH_QUALITY', badgeColor: '#007bff' },
        { value: 'ACTIVE_MEDIUM_QUALITY', badgeColor: '#007bff' },
        { value: 'ACTIVE_LOW_QUALITY', badgeColor: '#007bff' },
        { value: 'APPROVED', badgeColor: '#28a745' },
        { value: 'PAUSED', badgeColor: '#ffc107' },
        { value: 'DISABLED', badgeColor: '#6c757d' },
        { value: 'APPEAL_REQUEST', badgeColor: '#ffc107' }
    ];
};

export function getWhatsAppCategories() {
    return [
        'TICKET_UPDATE',
        'SHIPPING_UPDATE',
        'ISSUE_RESOLUTION',
        'APPOINTMENT_UPDATE',
        'ACCOUNT_UPDATE'
    ];
};

export function getEntityList() {
    const ent = [
        {
            "value": "Profile",
            "label": <FormattedMessage id="ReferenceDataFunctions.Profile" />
        },
        {
            "value": "ProfileAddress",
            "label": <FormattedMessage id="ReferenceDataFunctions.ProfileAddress" />
        },
        {
            "value": "ProfileConsent",
            "label": <FormattedMessage id="ReferenceDataFunctions.ProfileConsent" />
        },
        {
            "value": "ProfileContact",
            "label": <FormattedMessage id="ReferenceDataFunctions.ProfileContact" />
        },
        {
            "value": "ProfileDocument",
            "label": < FormattedMessage id="ReferenceDataFunctions.ProfileDocument" />
        },
        {
            "value": "ProfileInformation",
            "label": <FormattedMessage id="ReferenceDataFunctions.ProfileInformation" />
        },
        {
            "value": "ProfilePreference",
            "label": <FormattedMessage id="ReferenceDataFunctions.ProfilePreference" />
        },
        {
            "value": "ProfileSaleMarketing",
            "label": <FormattedMessage id="ReferenceDataFunctions.ProfileSaleMarketing" />
        },
        {
            "value": "ProfileSocialMedia",
            "label": <FormattedMessage id="ReferenceDataFunctions.ProfileSocialMedia" />
        }
    ];
    return ent;
};

export function getFieldList(entity, intl) {
    const ent = [];
    if (entity === 'Profile') {
        ent.push({ "value": "BirthDate", "label": <FormattedMessage id="ReferenceDataFunctions.BirthDate" /> }); 
        ent.push({ "value": "FirstName", "label": <FormattedMessage id="ReferenceDataFunctions.FirstName" />, split: true });
        ent.push({ "value": "FullName", "label": <FormattedMessage id="ReferenceDataFunctions.FullName" /> });
        ent.push({ "value": "Gender", "label": <FormattedMessage id="ReferenceDataFunctions.Gender" />, split: true });
        ent.push({ "value": "IsMaster", "label": <FormattedMessage id="ReferenceDataFunctions.IsMaster" />, fieldOptions: [{ value: true, label: 'Master' }, { value: false, label: 'Not Master' }] });
        ent.push({ "value": "Language", "label": <FormattedMessage id="ReferenceDataFunctions.Language" />, split: true });
        ent.push({ "value": "LastName", "label": <FormattedMessage id="ReferenceDataFunctions.LastName" />, split: true });
        ent.push({ "value": "MiddleName", "label": <FormattedMessage id="ReferenceDataFunctions.MiddleName" />, split: true  });
        ent.push({ "value": "Nationality", "label": <FormattedMessage id="ReferenceDataFunctions.Nationality" />, split: true, fieldOptions: countryList().getData() });
        ent.push({ "value": "TitleCode", "label": <FormattedMessage id="ReferenceDataFunctions.TitleCode" /> });
        ent.push({ "value": "Type", "label": <FormattedMessage id="ReferenceDataFunctions.Type" />, split: true, fieldOptions: getProfileType(intl) });
    }
    else if (entity === 'ProfileAddress') {
        ent.push({ "value": "AddressLine1", "label": <FormattedMessage id="ReferenceDataFunctions.AddressLine1" />, split: true });
        ent.push({ "value": "AddressLine2", "label": <FormattedMessage id="ReferenceDataFunctions.AddressLine2" />, split: true });
        ent.push({ "value": "AddressLine3", "label": <FormattedMessage id="ReferenceDataFunctions.AddressLine3" />, split: true });
        ent.push({ "value": "AddressLine4", "label": <FormattedMessage id="ReferenceDataFunctions.AddressLine4" />, split: true });
        ent.push({ "value": "Country", "label": <FormattedMessage id="ReferenceDataFunctions.Country" />, split: true });
        ent.push({ "value": "Locality", "label": <FormattedMessage id="ReferenceDataFunctions.Locality" />, split: true });
        ent.push({ "value": "PostalCode", "label": <FormattedMessage id="ReferenceDataFunctions.PostalCode" />, split: true });
        ent.push({ "value": "Region", "label": <FormattedMessage id="ReferenceDataFunctions.Region" />, split: true });
        ent.push({ "value": "Type", "label": <FormattedMessage id="ReferenceDataFunctions.Type" />, split: true, fieldOptions: getAddressTypeList(intl) });
    }
    else if (entity === 'ProfileConsent') {
        ent.push({ "value": "Active", "label": <FormattedMessage id="ReferenceDataFunctions.Active" /> });
        ent.push({ "value": "ConsentBegin", "label": <FormattedMessage id="ReferenceDataFunctions.ConsentBegin" /> });
        ent.push({ "value": "ConsentEnd", "label": <FormattedMessage id="ReferenceDataFunctions.ConsentEnd" /> });
        ent.push({ "value": "Method", "label": <FormattedMessage id="ReferenceDataFunctions.Method" /> });
        ent.push({ "value": "Type", "label": <FormattedMessage id="ReferenceDataFunctions.Type" />, fieldOptions: getConsentTypeList() });
    }
    else if (entity === 'ProfileContact') {
        ent.push({ "value": "Contact", "label": <FormattedMessage id="ReferenceDataFunctions.Contact" />, split: true });
        ent.push({ "value": "Type", "label": <FormattedMessage id="ReferenceDataFunctions.Type" />, split: true, fieldOptions: getContactTypeList(intl) });
        ent.push({ "value": "Main", "label": <FormattedMessage id="ReferenceDataFunctions.Main" />, split: true, fieldOptions: getContactMainTypeList(intl) });
    }
    else if (entity === 'ProfileDocument') {
        ent.push({ "value": "IssueDate", "label": <FormattedMessage id="ReferenceDataFunctions.IssueDate" /> });
        ent.push({ "value": "Number", "label": <FormattedMessage id="ReferenceDataFunctions.Number" />, split: true });
        ent.push({ "value": "Type", "label": <FormattedMessage id="ReferenceDataFunctions.Type" />, split: true, fieldOptions: getDocumentList(intl) });
        ent.push({ "value": "ValidUntil", "label": <FormattedMessage id="ReferenceDataFunctions.ValidUntil" /> });
    }
    else if (entity === 'ProfileInformation') {
        ent.push({ "value": "Area", "label": <FormattedMessage id="ReferenceDataFunctions.Area" /> });
        ent.push({ "value": "Notes", "label": <FormattedMessage id="ReferenceDataFunctions.Notes" /> });
    }
    else if (entity === 'ProfilePreference') {
        ent.push({ "value": "Action", "label": <FormattedMessage id="ReferenceDataFunctions.Action" /> });
        ent.push({ "value": "Notes", "label": <FormattedMessage id="ReferenceDataFunctions.Notes" /> });
        ent.push({ "value": "Type", "label": <FormattedMessage id="ReferenceDataFunctions.Type" /> });
    }
    else if (entity === 'ProfileSaleMarketing') {
        ent.push({ "value": "DistributionChannel", "label": <FormattedMessage id="ReferenceDataFunctions.DistributionChannel" /> });
        ent.push({ "value": "Segment", "label": <FormattedMessage id="ReferenceDataFunctions.Segment" /> });
        ent.push({ "value": "SubSegment", "label": <FormattedMessage id="ReferenceDataFunctions.SubSegment" /> });
        ent.push({ "value": "VipCode", "label": <FormattedMessage id="ReferenceDataFunctions.VipCode" /> });
    }
    else if (entity === 'ProfileSocialMedia') {
        ent.push({ "value": "Notes", "label": <FormattedMessage id="ReferenceDataFunctions.Notes" /> });
        ent.push({ "value": "Type", "label": <FormattedMessage id="ReferenceDataFunctions.Type" />, fieldOptions: getSocialMediaList() });
        ent.push({ "value": "Url", "label": <FormattedMessage id="ReferenceDataFunctions.Url" /> });
    }

    return ent;
};


export function getComparationList() {
    const ent = [
        {
            value: "ExactMatch",
            label: <FormattedMessage id="DataQualityTemplateDetail.ExactMatch" />
        },
        {
            value: "AllWords",
            label: <FormattedMessage id="DataQualityTemplateDetail.AllWords" />
        },
        {
            value: 'BiggerThan',
            label: <FormattedMessage id="DataQualityTemplateDetail.BiggerThan" />
        }
    ];
    return ent;
};

export function getJobName(intl) {
    const name = [
        {
            value: "ScheduleTasks.Jobs.JobsManager",
            label: intl.formatMessage({ id: "ScheduleTasks.JobsManager" }),
            description: intl.formatMessage({ id: "ScheduleTasks.JobsManagerDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.ClearKDPHardDeletedTargetsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.ClearKDPHardDeletedTargetsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.ClearKDPHardDeletedTargetsJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.AutomaticMergeJob",
            label: intl.formatMessage({ id: "ScheduleTasks.AutomaticMergeJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.AutomaticMergeJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.TagDiscoveryJob',
            label: intl.formatMessage({ id: "ScheduleTasks.TagDiscoveryJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.TagDiscoveryJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.MarketingCampaignsFeedbackJob",
            label: intl.formatMessage({ id: "ScheduleTasks.MarketingCampaignsFeedbackJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.MarketingCampaignsFeedbackJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.SendAlertsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.SendAlertsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendAlertsJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.BulkImportJob',
            label: intl.formatMessage({ id: "ScheduleTasks.BulkImportJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.BulkImportJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.GetReviewProSurveysJob",
            label: intl.formatMessage({ id: "ScheduleTasks.GetReviewProSurveysJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.GetReviewProSurveysJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.GetEgoiSurveysJob",
            label: intl.formatMessage({ id: "ScheduleTasks.GetEgoiSurveysJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.GetEgoiSurveysJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.GetProfileNowSurveysJob",
            label: intl.formatMessage({ id: "ScheduleTasks.GetProfileNowSurveysJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.GetProfileNowSurveysJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.ReviewProExportGuestsJob',
            label: intl.formatMessage({ id: "ScheduleTasks.ReviewProExportGuestsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.ReviewProExportGuestsJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.RetentionPolicyJob",
            label: intl.formatMessage({ id: "ScheduleTasks.RetentionPolicyJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.RetentionPolicyJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.CountProfilesJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CountProfilesJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CountProfilesJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.CardIntegrationJob',
            label: intl.formatMessage({ id: "ScheduleTasks.CardIntegrationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CardIntegrationJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.MarketingCampaignsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.MarketingCampaignsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.MarketingCampaignsJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.CustomMarketingCampaignsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CustomMarketingCampaignsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CustomMarketingCampaignsJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.PopulateCoordinatesJob",
            label: intl.formatMessage({ id: "ScheduleTasks.PopulateCoordinatesJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.PopulateCoordinatesJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.RoibackImportProfilesJob',
            label: intl.formatMessage({ id: "ScheduleTasks.RoibackImportProfilesJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.RoibackImportProfilesJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.EgoiImportProfilesJob",
            label: intl.formatMessage({ id: "ScheduleTasks.EgoiImportProfilesJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.EgoiImportProfilesJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.IssueLoyaltyCardsJob",
            label: intl.formatMessage({ id: "ScheduleTasks.IssueLoyaltyCardsJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.IssueLoyaltyCardsJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.DataQualityReportJob',
            label: intl.formatMessage({ id: "ScheduleTasks.DataQualityReportJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.DataQualityReportJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.MonthlyReportJob",
            label: intl.formatMessage({ id: "ScheduleTasks.MonthlyReportJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.MonthlyReportJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.LoyaltyCalculationJob",
            label: intl.formatMessage({ id: "ScheduleTasks.LoyaltyCalculationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.LoyaltyCalculationJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.LoyaltyCardDeactivateJob",
            label: intl.formatMessage({ id: "ScheduleTasks.LoyaltyCardDeactivateJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.LoyaltyCardDeactivateJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.SendGridMailHandleStatusJob',
            label: intl.formatMessage({ id: "ScheduleTasks.SendGridMailHandleStatusJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.SendGridMailHandleStatusJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.CampaignTargetCountJob",
            label: intl.formatMessage({ id: "ScheduleTasks.CampaignTargetCountJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CampaignTargetCountJobDescription" })
        },
        {
            value: "ScheduleTasks.Jobs.MonitorScheduleJob",
            label: intl.formatMessage({ id: "ScheduleTasks.MonitorScheduleJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.MonitorScheduleJobDescription" })
        },
        {
            value: 'ScheduleTasks.Jobs.CardIntegrationJob',
            label: intl.formatMessage({ id: "ScheduleTasks.CardIntegrationJob" }),
            description: intl.formatMessage({ id: "ScheduleTasks.CardIntegrationJobDescription" })
        },
    ];
    return name;
};

const HelpLink = [
    /*{
        id: 'manageruserhelp',
        link: 'https://hostpms.atlassian.net/wiki/spaces/CM/pages/297533441/Utilizadores'
    }*/
    {
        id: 'activityHelp',
        link: 'https://hostpms.atlassian.net/wiki/spaces/ProfileNow/pages/2726068244/Activity'
    },
    {
        id: 'alertsHelp',
        link: 'https://hostpms.atlassian.net/wiki/spaces/ProfileNow/pages/2687565853/Alerts'
    },
    {
        id: 'webHooksHelp',
        link: 'https://hostpms.atlassian.net/wiki/spaces/ProfileNow/pages/2687631557/Webhooks'
    },
    {
        id: 'trackingHelp',
        link: 'https://hostpms.atlassian.net/wiki/spaces/ProfileNow/pages/2687631679/Tracking'
    },
    {
        id: 'mailHelp',
        link: 'https://hostpms.atlassian.net/wiki/spaces/ProfileNow/pages/2688778248/Mail'
    },

];

export function getHelpLink(id) {
    const lnk = HelpLink.find(el => el.id === id);
    if (lnk) {
        return lnk.link;
    }
    else {
        return null;
    }
}

export function getRunPeriodList() {
    const periodOptions = [
        {
            value: 'Hourly',
            label: <FormattedMessage id="ReferenceDataFunctions.Hourly" />
        },
        {
            value: 'Daily',
            label: <FormattedMessage id="ReferenceDataFunctions.Daily" />
        },
        {
            value: 'Weekly',
            label: <FormattedMessage id="ReferenceDataFunctions.Weekly" />
        },
        {
            value: 'Monthly',
            label: <FormattedMessage id="ReferenceDataFunctions.Monthly" />
        },
        {
            value: 'Never',
            label: <FormattedMessage id="ReferenceDataFunctions.Never" />
        }
    ]
    return periodOptions;
};

export function getOperatorTypeList() {
    const operatorTypeOptions = [
        {
            value: 'Equal',
            label: <FormattedMessage id="ReferenceDataFunctions.Equal" />
        },
        {
            value: 'NotEqual',
            label: <FormattedMessage id="ReferenceDataFunctions.NotEqual" />
        },
        {
            value: 'GreaterThan',
            label: <FormattedMessage id="ReferenceDataFunctions.GreaterThan" />
        },
        {
            value: 'LessThan',
            label: <FormattedMessage id="ReferenceDataFunctions.LessThan" />
        },
        {
            value: 'GreaterThanOrEqual',
            label: <FormattedMessage id="ReferenceDataFunctions.GreaterThanOrEqual" />
        },
        {
            value: 'LessThanOrEqual',
            label: <FormattedMessage id="ReferenceDataFunctions.LessThanOrEqual" />
        },
        {
            value: 'Contains',
            label: <FormattedMessage id="ReferenceDataFunctions.Contains" />
        },
        {
            value: 'DoesNotContain',
            label: <FormattedMessage id="ReferenceDataFunctions.DoesNotContain" />
        }
    ]
    return operatorTypeOptions;
};

export function getConjunctionTypeList() {
    const conjunctionTypeOptions = [
        {
            value: 'And',
            label: <FormattedMessage id="ReferenceDataFunctions.And" />
        },
        {
            value: 'Or',
            label: <FormattedMessage id="ReferenceDataFunctions.Or" />
        }
    ]
    return conjunctionTypeOptions;
};

export function getSocialMediaList() {
    const socialMedia = [
        {
            value: 'Facebook',
            label: 'Facebook',
            icon: <i className="fab fa-facebook"></i>
        },
        {
            value: 'Instagram',
            label: 'Instagram',
            icon: <i className="fab fa-instagram"></i>
        },
        {
            value: 'Linkedin',
            label: 'Linkedin',
            icon: <i className="fab fa-linkedin"></i>
        },
        {
            value: 'Twitter',
            label: 'Twitter',
            icon: <i className="fab fa-twitter"></i>
        },
        {
            value: 'YouTube',
            label: 'YouTube',
            icon: <i className="fab fa-youtube"></i>
        },
        {
            value: 'Reddit',
            label: 'Reddit',
            icon: <i className="fab fa-reddit"></i>
        },
        {
            value: 'Discord',
            label: 'Discord',
            icon: <i className="fab fa-discord"></i>
        },
        {
            value: 'SnapChat',
            label: 'SnapChat',
            icon: <i className="fab fa-snapchat"></i>
        },
        {
            value: 'Pinterest',
            label: 'Pinterest',
            icon: <i className="fab fa-pinterest"></i>
        },
        {
            value: 'Website',
            label: 'Website',
            icon: <i className="fas fa-browser"></i>
        },
        {
            value: 'Unknown',
            label: 'Unknown',
            icon: <i className="fas fa-browser"></i>
        },
        {
            value: 'GooglePlus',
            label: 'Google Plus',
            icon: <i className="fab fa-google-plus"></i>
        }
    ]
    return socialMedia;
};


//#region Document Types
export function getDocumentAllList(intl) {

    return getOriginalDocument(intl).concat(getDocumentNumberBr(intl), getFiscalNumberBr(intl), getSpainDocuments(intl));
};

export function getDocumentList(intl) {
    let documents = getOriginalDocument(intl);
    const modules = global.modules;

    if (modules) {
        if (modules.some(mdl => mdl === "DocNumberBr")) {
            documents = documents.concat(getDocumentNumberBr(intl));
        }
        if (modules.some(mdl => mdl === "FiscalNumberBR")) {
            documents = documents.concat(getFiscalNumberBr(intl));
        }
        if (modules.some(mdl => mdl === "DocNumberEs")) {
            documents = documents.concat(getSpainDocuments(intl));
        }
    }

    return documents;
};

export function getOriginalDocument(intl) {
    const documents = [
        {
            value: 'Passport',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Passport" })
        },
        {
            value: 'IdCard',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.IdCart" })
        },
        {
            value: 'FiscalNumber',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.FiscalNumber" })
        }
    ];

    return documents;
}

export function getDocumentNumberBr(intl) {
    const documents = [
        {
            value: 'RG',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.RG" })
        },
        {
            value: 'RNE',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.RNE" })
        },
        {
            value: 'BR_InstEstadual',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.BR_InstEstadual" })
        },
        {
            value: 'BR_InstMunicipal',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.BR_InstMunicipal" })
        }
    ];

    return documents;
}

export function getSpainDocuments(intl) {
    const documents = [
        {
            value: 'DNI',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.DNI" })
        }
    ];

    return documents;
}

export function getFiscalNumberBr(intl) {
    const documents = [
        {
            value: 'CPF',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.CPF" })
        },
        {
            value: 'CNPJ',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.CNPJ" })
        }
    ];

    return documents;
}
//#endregion

export function getContactTypeList(intl) {
    const contactType = [
        {
            value: 'Phone',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Phone" }),
            icon: 'fas fa-phone'
        },
        {
            value: 'Fax',
            label: 'Fax',
            icon: 'fas fa-fax'
        },
        {
            value: 'Mobile',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Mobile" }),
            icon: 'icon-icon-sms'
        },
        {
            value: 'Email',
            label: 'Email',
            icon: 'fas fa-at'
        }
    ]
    return contactType;
};

export function getContactMainTypeList(intl) {
    const contactType = [
        {
            value: true,
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Main" })
        },
        {
            value: false,
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Secondary" })
        }
    ]
    return contactType;
};

//#region Consents
export function getConsentTypeList() {        
    const consentType = [
        {
            value: 'General',
            label: <FormattedMessage id="ReferenceDataFunctions.General" />
        },
        {
            value: 'Interfaces',
            label: <FormattedMessage id="ReferenceDataFunctions.Interface" />
        },
        {
            value: 'Mailings',
            label: <FormattedMessage id="ReferenceDataFunctions.Mailings" />
        },
        {
            value: 'GuestInfo',
            label: <FormattedMessage id="ReferenceDataFunctions.GuestInfo" />
        },
        {
            value: 'Stats',
            label: <FormattedMessage id="ReferenceDataFunctions.Stats" />
        },
        {
            value: 'Partners',
            label: <FormattedMessage id="ReferenceDataFunctions.Partner" />
        },
        {
            value: 'Notifications',
            label: <FormattedMessage id="ReferenceDataFunctions.Notification" />
        }
    ]
    return consentType;
};

export function getAllConsentTypeList() {
    const consentType = [
        {
            value: 'Info',
            label: <FormattedMessage id="ReferenceDataFunctions.Info" />
        },
        {
            value: 'Privacy',
            label: <FormattedMessage id="ReferenceDataFunctions.Privacy" />
        },
        {
            value: 'Activities',
            label: <FormattedMessage id="ReferenceDataFunctions.Activities" />
        },
        {
            value: 'Covid19',
            label: <FormattedMessage id="ReferenceDataFunctions.Covid19" />
        },
        {
            value: 'Laundry',
            label: <FormattedMessage id="ReferenceDataFunctions.Laundry" />
        },
        {
            value: 'Self_Responsability',
            label: <FormattedMessage id="ReferenceDataFunctions.Self_Responsability" />
        }
    ]

    return getConsentTypeList().concat(consentType);
};

export function getConsentMethods() {
    const consentMethods = [
        {
            value: 'PAPER',
            label: <FormattedMessage id="ReferenceDataFunctions.Paper" />
        },
        {
            value: 'WEBSITE',
            label: <FormattedMessage id="ReferenceDataFunctions.Website" />
        },
        {
            value: 'AUTO',
            label: <FormattedMessage id="ReferenceDataFunctions.Auto" />
        },
        {
            value: '<vazio>',
            label: <FormattedMessage id="ReferenceDataFunctions.<vazio>" />
        }
    ];

    return consentMethods;
};
//#endregion

export function getAddressTypeList(intl) {
    const addressType = [
        {
            value: 'Main',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Main" })
        },
        {
            value: 'Billing',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Billing" })
        }
    ]
    return addressType;
};

//#region rules
export function getRuleTypeList(intl) {
    const ruleTypeOptions = [
        {
            value: 'FieldCheck',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.FieldCheck" })
        },
        {
            value: 'DuplicationCheck',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.DuplicationCheck" })
        },
        {
            value: 'MasterDuplicationCheck',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.MasterDuplicationCheck" })
        },
        {
            value: 'OtaEmailCheck',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.OtaEmailCheck" })
        },
        {
            value: 'OthersCheck',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.OthersCheck" })
        }

    ]
    return ruleTypeOptions;
};

export function getDuplicateMatchTypeList(intl) {
    const duplicateMatchType = [
        {
            value: 'Email',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Contact" })
        },
        {
            value: 'DocumentNumber',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.DocumentNumber" })
        },
        {
            value: 'Name',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Name" })
        },
        {
            value: 'LoyaltyCard',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.LoyaltyCard" })
        },
        {
            value: 'FiscalNumber',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.FiscalNumber" })
        },
        {
            value: 'Booking',
            label: 'Booking'
        },
        {
            value: 'Expedia',
            label: 'Expedia'
        },
        {
            value: 'Agoda',
            label: 'Agoda'
        },
        {
            value: 'ContactNumber',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.ContactNumber" })
        },
        {
            value: 'SecondaryMailOnly',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.SecondaryMailOnly" })
        },
        {
            value: 'SecondaryMobileOnly',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.SecondaryMobileOnly" })
        },
        {
            value: 'SecondaryPhoneOnly',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.SecondaryPhoneOnly" })
        },
        {
            value: 'ExpiredLoyaltyCard',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.ExpiredLoyaltyCard" })
        },
        {
            value: 'EmailVerification',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.EmailVerification" })
        }
    ]
    return duplicateMatchType;
};

export function getMatchTypeOptions(ruleType, intl) {
    let options = getDuplicateMatchTypeList(intl);

    if (ruleType === 'OtaEmailCheck') {
        return options.filter(el => el.value === 'Booking' || el.value === 'Expedia' || el.value === 'Agoda');
    }
    else if (ruleType === 'DuplicationCheck') {
        const notIncluded = ['FiscalNumber', 'Booking', 'Expedia', 'Agoda', 'ContactNumber', "SecondaryMailOnly", "SecondaryMobileOnly", "SecondaryPhoneOnly", 'ExpiredLoyaltyCard'];

        return options.filter(el => !notIncluded.some(ni => ni === el.value));
    }
    else if (ruleType === 'MasterDuplicationCheck') {
        const included = ['FiscalNumber', 'Email', 'ContactNumber', "SecondaryMailOnly", "SecondaryMobileOnly", "SecondaryPhoneOnly", 'ExpiredLoyaltyCard'];

        return options.filter(el => included.some(i => i === el.value));
    }
    else if (ruleType === 'OthersCheck') {
        const included = ['EmailVerification'];

        return options.filter(el => included.some(i => i === el.value));
    }
}

//Se true, mostrar duplicações, se não mostrar estado 
export function showDuplications(ruleType, duplicateMatchType) {
    const showStatus = ['ContactNumber', 'SecondaryMailOnly', 'SecondaryMobileOnly', 'SecondaryPhoneOnly', 'ExpiredLoyaltyCard'];

    return !(ruleType && ruleType === 'MasterDuplicationCheck' && showStatus.some(el => el === duplicateMatchType) || ruleType === 'FieldCheck')
}

//#endregion

export function getProfileType(intl) {

    const profileType = [
        {
            value: 'Person',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Person" }),
            icon: "icon-icon-profile"
        },
        {
            value: 'Company',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Company" }),
            icon: "fas fa-building"
        },
        {
            value: 'Agency',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Agency" }),
            icon: "fas fa-plane"
        },
        {
            value: 'CRO', // Central Reservation Office
            label: intl.formatMessage({ id: "ReferenceDataFunctions.CRO" }),
            icon: "fas fa-suitcase"
        },
        {
            value: 'Group',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Group" }),
            icon: "fas fa-users"
        },
        {
            value: 'Supplier',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Supplier" }),
            icon: "fas fa-boxes"
        }
    ]

    if (global.modules?.some(m => m === 'Proprietary')) {
        profileType.push({
            value: 'Owner',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Owner" }),
            icon: "fas fa-user-tie"
        });
    }

    if (global.modules?.some(m => m === 'TimeShare')) {
        profileType.push({
            value: 'TimeSharing',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.TimeSharing" }),
            icon: "fas fa-user-clock"
        });
    }
    else if (global.modules?.some(m => m === 'Contact')) {
        profileType.push({
            value: 'TimeSharing',
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Contact" }),
            icon: "fas fa-user-clock"
        });
    }

    return profileType;
};

export function getHotelGroupModeType() {
    const modeType = [
        {
            value: 'Automatic',
            label: <FormattedMessage id="ReferenceDataFunctions.Automatic" />
        },
        {
            value: 'SemiAutomatic',
            label: <FormattedMessage id="ReferenceDataFunctions.SemiAutomatic" />
        },
        {
            value: 'Manual',
            label: <FormattedMessage id="ReferenceDataFunctions.Manual" />
        }
    ]
    return modeType;
};

export function getRelationType() {
    const relationType = [
        {
            value: 'Family',
            label: <FormattedMessage id="ReferenceDataFunctions.Family" />
        },
        {
            value: 'Employee',
            label: <FormattedMessage id="ReferenceDataFunctions.Employee" />
        },
        {
            value: 'Subsidiary',
            label: <FormattedMessage id="ReferenceDataFunctions.Subsidiary" />
        },
        {
            value: 'Partner',
            label: <FormattedMessage id="ReferenceDataFunctions.Partner" />
        },
        {
            value: 'Employer',
            label: <FormattedMessage id="ReferenceDataFunctions.Employer" />
        },
        {
            value: 'HeadQuarters',
            label: <FormattedMessage id="ReferenceDataFunctions.HeadQuarters" />
        },
        {
            value: 'Linked',
            label: <FormattedMessage id="ReferenceDataFunctions.Linked" />
        }
    ]
    return relationType;
};

export function getCountriesByRegion() {
    const countriesByRegion = [
        {
            region: 'Europa Norte',
            countries: [
                { value: 'DK', label: 'Denmark' },
                { value: 'EE', label: 'Estonia' },
                { value: 'FO', label: 'Faroe Islands' },
                { value: 'FI', label: 'Finland' },
                { value: 'GL', label: 'Greenland' },
                { value: 'IS', label: 'Iceland' },
                { value: 'IE', label: 'Ireland' },
                { value: 'VL', label: 'Latvia' },
                { value: 'LT', label: 'Lithuania' },
                { value: 'NO', label: 'Norway' },
                { value: 'SE', label: 'Sweden' },
                { value: 'GB', label: 'United Kingdom' },
            ]
        },
        {
            region: 'Europa Ocidental',
            countries: [
                { value: 'AT', label: 'Austria' },
                { value: 'BE', label: 'Belgium' },
                { value: 'FR', label: 'France' },
                { value: 'DE', label: 'Germany' },
                { value: 'LI', label: 'Liechtenstein' },
                { value: 'LU', label: 'Luxembourg' },
                { value: 'NL', label: 'Netherlands' },
            ]
        },
        {
            region: 'Europa Oriental',
            countries: [
                { value: 'BY', label: 'Belarus' },
                { value: 'BG', label: 'Bulgaria' },
                { value: 'CZ', label: 'Czech Republic' },
                { value: 'HU', label: 'Hungary' },
                { value: 'MD', label: 'Moldova' },
                { value: 'PL', label: 'Poland' },
                { value: 'RO', label: 'Romania' },
                { value: 'RU', label: 'Russia' },
                { value: 'SK', label: 'Slovakia' },
                { value: 'CH', label: 'Switzerland' },
                { value: 'UK', label: 'Ukraine' },
            ]
        },
        {
            region: 'Europa Sul',
            countries: [
                { value: 'AL', label: 'Albania' },
                { value: 'AD', label: 'Andorra' },
                { value: 'BA', label: 'Bosnia and Herzegovina' },
                { value: 'HR', label: 'Croatia' },
                { value: 'CY', label: 'Cyprus' },
                { value: 'GI', label: 'Gibraltar' },
                { value: 'GR', label: 'Greece' },
                { value: 'VA', label: 'Holy See' },
                { value: 'IT', label: 'Italy' },
                { value: 'XK', label: 'Kosovo' },
                { value: 'MK', label: 'North Macedonia' },
                { value: 'MT', label: 'Malta' },
                { value: 'MC', label: 'Monaco' },
                { value: 'ME', label: 'Montenegro' },
                { value: 'PT', label: 'Portugal' },
                { value: 'SM', label: 'San Marino' },
                { value: 'RS', label: 'Serbia' },
                { value: 'SI', label: 'Slovenia' },
                { value: 'ES', label: 'Spain' }
            ]
        },
        {
            region: 'Asia',
            countries: [
                { value: 'CN', label: 'China' },
                { value: 'IN', label: 'India' },
                { value: 'ID', label: 'Indonesia' },
                { value: 'PK', label: 'Pakistan' },
                { value: 'BD', label: 'Bangladesh' },
                { value: 'JP', label: 'Japan' },
                { value: 'PH', label: 'Philippines' },
                { value: 'VN', label: 'Vietnam' },
                { value: 'TR', label: 'Turkey' },
                { value: 'IR', label: 'Iran' },
                { value: 'TH', label: 'Thailand' },
                { value: 'MM', label: 'Myanmar' },
                { value: 'KR', label: 'Republic of Korea' },
                { value: 'IQ', label: 'Iraq' },
                { value: 'AF', label: 'Afghanistan' },
                { value: 'SA', label: 'Saudi Arabia' },
                { value: 'UZ', label: 'Uzbekistan' },
                { value: 'MY', label: 'Malaysia' },
                { value: 'YE', label: 'Yemen' },
                { value: 'NP', label: 'Nepal' },
                { value: 'KP', label: 'North Korea' },
                { value: 'LK', label: 'Sri Lanka' },
                { value: 'KZ', label: 'Kazakhstan' },
                { value: 'SY', label: 'Syria' },
                { value: 'KH', label: 'Cambodia' },
                { value: 'JO', label: 'Jordan' },
                { value: 'AZ', label: 'Azerbaijan' },
                { value: 'AE', label: 'United Arab Emirates' },
                { value: 'TJ', label: 'Tajikistan' },
                { value: 'IL', label: 'Israel' },
                { value: 'LA', label: 'Laos' },
                { value: 'LB', label: 'Lebanon' },
                { value: 'KG', label: 'Kyrgyzstan' },
                { value: 'TM', label: 'Turkmenistan' },
                { value: 'SG', label: 'Singapore' },
                { value: 'OM', label: 'Oman' },
                { value: 'PS', label: 'State of Palestine' },
                { value: 'KW', label: 'Kuwait' },
                { value: 'GE', label: 'Georgia' },
                { value: 'MN', label: 'Mongolia' },
                { value: 'AM', label: 'Armenia' },
                { value: 'QA', label: 'Qatar' },
                { value: 'BH', label: 'Bahrain' },
                { value: 'TL', label: 'Timor-Leste' },
                { value: 'CY', label: 'Cyprus' },
                { value: 'BT', label: 'Bhutan' },
                { value: 'MV', label: 'Maldives' },
                { value: 'BN', label: 'Brunei' }
            ]
        },
        {
            region: 'America',
            countries: [
                { value: 'BM', label: 'Bermuda' },
                { value: 'GL', label: 'Greenland' },
                { value: 'CA', label: 'Canada' },
                { value: 'PM', label: 'Saint Pierre and Miquelon' },
                { value: 'US', label: 'United States' },
                { value: 'BZ', label: 'Belize' },
                { value: 'SV', label: 'El Salvador' },
                { value: 'GT', label: 'Guatemala' },
                { value: 'HN', label: 'Honduras' },
                { value: 'MX', label: 'Mexico' },
                { value: 'NI', label: 'Nicaragua' },
                { value: 'PA', label: 'Panama' },
                { value: 'AR', label: 'Argentina' },
                { value: 'BO', label: 'Bolivia' },
                { value: 'BV', label: 'Bouvet Island' },
                { value: 'BR', label: 'Brazil' },
                { value: 'CL', label: 'Chile' },
                { value: 'EC', label: 'Ecuador' },
                { value: 'FK', label: 'Falkland Islands' },
                { value: 'GF', label: 'French Guiana' },
                { value: 'GY', label: 'Guyana' },
                { value: 'CO', label: 'Colombia' },
                { value: 'PY', label: 'Paraguay' },
                { value: 'PE', label: 'Peru' },
                { value: 'GS', label: 'South Georgia and South Sandwich Islands' },
                { value: 'SR', label: 'Suriname' },
                { value: 'UY', label: 'Uruguay' },
                { value: 'VE', label: 'Venezuela' },
                { value: 'AI', label: 'Anguilla' },
                { value: 'AG', label: 'Antigua and Barbuda' },
                { value: 'AW', label: 'Aruba' },
                { value: 'BS', label: 'Bahamas' },
                { value: 'BB', label: 'Barbados' },
                { value: 'BQ', label: 'Bonaire, Saint Eustatius and Saba' },
                { value: 'CW', label: 'Curacao' },
                { value: 'DM', label: 'Dominica' },
                { value: 'DO', label: 'Dominican Republic' },
                { value: 'GD', label: 'Grenada' },
                { value: 'GP', label: 'Guadeloupe' },
                { value: 'HT', label: 'Haiti' },
                { value: 'JM', label: 'Jamaica' },
                { value: 'VG', label: 'British Virgin Islands' },
                { value: 'VI', label: 'U.S. Virgin Islands' },
                { value: 'KY', label: 'Cayman Islands' },
                { value: 'CU', label: 'Cuba' },
                { value: 'MQ', label: 'Martinique' },
                { value: 'MS', label: 'Montserrat' },
                { value: 'PR', label: 'Puerto Rico' },
                { value: 'MF', label: 'Saint Martin' },
                { value: 'BL', label: 'Saint Barthélemy' },
                { value: 'SX', label: 'Sint Maarten' },
                { value: 'KN', label: 'Saint Kitts and Nevis' },
                { value: 'LC', label: 'Saint Lucia' },
                { value: 'VC', label: 'Saint Vincent and the Grenadines' },
                { value: 'TT', label: 'Trinidad and Tobago' },
                { value: 'TC', label: 'Turks and Caicos Islands' },
            ]
        },
        {
            region: 'CPLP',
            countries: [
                { value: 'AO', label: 'Angola' },
                { value: 'BR', label: 'Brasil' },
                { value: 'CV', label: 'Cabo Verde' },
                { value: 'GW', label: 'Guiné-Bissau' },
                { value: 'GQ', label: 'Guiné Equatorial' },
                { value: 'MZ', label: 'Moçambique' },
                { value: 'PT', label: 'Portugal' },
                { value: 'ST', label: 'São Tomé e Príncipe' },
                { value: 'TL', label: 'Timor-Leste' }
            ]
        }
    ]
    return countriesByRegion;
};

export function getDimensions() {
    const dimensions = [
        {
            type: 'Profile',
            dimensions: [
                { value: 'Age', label: 'Age' },
                { value: 'Country', label: 'Country' },
                { value: 'Nationality', label: 'Nationality' },
                { value: 'ProfileType', label: 'Profile Type' },
                { value: 'Region', label: 'Region' },
                { value: 'Tag', label: 'Tag' },
                { value: 'Score', label: 'Score' }
            ]
        },
        {
            type: 'Reservation',
            dimensions: [
                { value: 'Channel', label: 'Channel' },
                { value: 'RateCode', label: 'Rate Code' },
                { value: 'RoomCode', label: 'Room Code' },
                { value: 'ReservationBookingMonth', label: 'Reservation Booking Month' },
                { value: 'NumberOfRooms', label: 'Number of Rooms' }
            ]
        },
        {
            type: 'Revenue',
            dimensions: [
                { value: 'RevenueBookingMonth', label: 'Revenue Booking Month' },
                { value: 'ChargeCode', label: 'Charge Code' },
                { value: 'PaymentType', label: 'Payment Type' }
            ]
        },
        {
            type: 'Review',
            dimensions: [
                { value: 'StaffReview', label: 'Staff' },
                { value: 'ComfortReview', label: 'Comfort' },
                { value: 'FacilitiesReview', label: 'Facilities' },
                { value: 'CleanlinessReview', label: 'Cleanliness' },
                { value: 'LocationReview', label: 'Location' },
                { value: 'ValueForMoneyReview', label: 'Value for Money' },
                //{ value: 'ServiceReview', label: 'Service' },
                //{ value: 'FoodDrinkReview', label: 'Food & Drink' },
                //{ value: 'RoomReview', label: 'Room' },
                //{ value: 'ReceptionReview', label: 'Reception' },
                //{ value: 'PoolReview', label: 'Pool' },
                //{ value: 'RestaurantReview', label: 'Restaurant' },
                //{ value: 'RecommendationReview', label: 'Recommendation' },
                { value: 'ReviewScore', label: 'Score' },
            ]
        }, 
        {
            type: 'Marketing',
            dimensions: [
                { value: 'MarketingCampaign', label: 'Campaign' },
                { value: 'MarketingType', label: 'Marketing Type' }
            ]
        }
    ]
    return dimensions;
};

export function getMetrics() {
    const metrics = [
        {
            type: 'Reservation',
            metrics: [
                { value: 'TotalRoomStays', label: 'Sum (Room Stays)' },
                { value: 'TotalReservations', label: 'Sum (Reservations)' },
                { value: 'RoomStaysAmount', label: 'Sum (Room Stays Amount)' },
                { value: 'AverageRoomStay', label: 'Avg (Room Stay)' },
                { value: 'AveragePricePerReservation', label: 'Avg (Price per Reservation)' },
                { value: 'AveragePricePerRoomStay', label: 'Avg (Price per Room Stay)' },
            ]
        },
        {
            type: 'Revenue',
            metrics: [
                { value: 'RevenueTotalAmount', label: 'Sum (Amount)' },
                { value: 'RevenueTotalItems', label: 'Sum (Items)' },
                { value: 'AverageItemPrice', label: 'Avg (Item Price)' },
            ]
        }
    ]
    return metrics;
};

export function getTagGroupIcons() {
    const icons = [
        {
            group: 'Sports',
            icon: 'icon-icon-sports'
        },
        {
            group: 'Beverages',
            icon: 'icon-icon-beverages'
        },
        {
            group: 'Reservation',
            icon: 'icon-icon-reservations2'
        },
        {
            group: 'SPA',
            icon: 'icon-icon-wellness'
        },
        {
            group: 'Others',
            icon: ''
        },
    ]
    return icons;
};

export function getRegularExpressionType() {
    const type = [
        {
            value: 'Ignore',
            label: <FormattedMessage id="ReferenceDataFunctions.Ignore" />
        },
        {
            value: 'Rule',
            label: <FormattedMessage id="ReferenceDataFunctions.Rule" />
        },
        {
            value: 'Transform',
            label: <FormattedMessage id="ReferenceDataFunctions.Transform" />
        }
    ]
    return type;
};

export function getLicenseModules(intl) {
    const modules = [
        {
            label: "ProfileNow Access",
            options: [
                { value: 'Profile360', label: intl.formatMessage({ id: "ReferenceDataFunctions.Profile360" }) },
                { value: 'ProfileNowExternalApis', label: 'External Apis' },
                { value: 'ManualReservations', label: intl.formatMessage({ id: "ReferenceDataFunctions.ManualReservations" }) },
                { value: 'LoyaltyProgram', label: 'Loyalty Program' },
                { value: 'Loyalty', label: 'Loyalty' }, 
                { value: 'RestrictInfoToUserHotel', label: 'Restrict Info To User Hotel' },
                { value: 'OneCardByType', label: intl.formatMessage({ id: "ReferenceDataFunctions.OneCardByType" }) },
                { value: 'InstantMasterCreation', label: intl.formatMessage({ id: "ReferenceDataFunctions.InstantMasterCreation" }) },
                { value: 'HeyBookingEngine', label: "Hey Booking Engine" },
                { value: 'ML_ReservationCancel', label: "Reservation Cancel" },
                { value: 'SalesProcess', label: "Sales Process" },
                { value: 'Journeys', label: "Journeys" }
                
            ]
        },
        {
            label: "ProfileNow Doc Type",
            options: [
                { value: 'DocNumberBr', label: "Doc Number Br" },
                { value: 'FiscalNumberBR', label: 'Fiscal Number BR' },
                { value: 'DocNumberEs', label: 'Doc Number Es' }
            ]
        },
        {
            label: "ProfileNow User Type",
            options: [
                { value: 'Proprietary', label: "Proprietary" },
                { value: 'TimeShare', label: 'TimeShare' },
                { value: 'Contact', label: 'Contact' }
            ]
        },
        {
            label: "Other Integrations",
            options: [
                { value: 'ReviewPro', label: "ReviewPro" },
                { value: 'HeyCard', label: 'Hey Card' },
                { value: 'Roiback', label: 'Roiback' },
                { value: 'ChatGPT', label: 'ChatGPT' },
                { value: 'MicrosoftOffice365', label: 'Microsoft Office 365' },
            ]
        },
        {
            label: "Social",
            options: [
                { value: 'GoogleBusiness', label: "Google Business" },
                { value: 'FacebookContent', label: 'Facebook Content' }
            ]
        },
        {
            label: "Communication",
            options: [
                { value: 'Egoi', label: "E-goi" },
                { value: 'WhatsApp', label: 'WhatsApp' },
                { value: 'HiJiffy', label: 'HiJiffy' },
                { value: 'SendGrid', label: 'Send Grid' },
                { value: 'TwilioMessaging', label: 'Twilio Messaging' }
            ]
        },
        {
            label: "Mail Template",
            options: [
                { value: 'StripoSharedAccount', label: "StripoSharedAccount" },
                { value: 'StripoOwnAccount', label: 'StripoOwnAccount' }
            ]
        },
        {
            label: "Forms",
            options: [
                { value: 'ProfileNowForms', label: "Profile Now Forms" },
                { value: 'Typeform', label: 'Typeform' }
            ]
        }
    ]

    return modules;
};

export function getEgoiSenderStatus() {
    const modules = [
        {
            value: 'active',
            label: <FormattedMessage id="generic.active" />,
            color: 'primary'
        },
        {
            value: 'moderation',
            label: <FormattedMessage id="ReferenceDataFunctions.Moderation" />,
            color: 'warning'
        },
        {
            value: 'rejected',
            label: <FormattedMessage id="ReferenceDataFunctions.Rejected" />,
            color: 'danger'
        }
    ]
    return modules;
};


export function getReviewFields() {
    const type = [
        { value: 'Staff', label: <FormattedMessage id="ProfileReview.Staff" />, reviewValue: "reviewStaff" },
        { value: 'Comfort', label: <FormattedMessage id="ProfileReview.Comfort" />, reviewValue: "reviewComfort" },
        { value: 'Facilities', label: <FormattedMessage id="ProfileReview.Facilities" />, reviewValue: "reviewFacilities" },
        { value: 'Cleanliness', label: <FormattedMessage id="ProfileReview.Cleanliness" />, reviewValue: "reviewCleanliness" },
        { value: 'Location', label: <FormattedMessage id="ProfileReview.Location" />, reviewValue: "reviewLocation" },
        { value: 'ValueForMoney', label: <FormattedMessage id="ProfileReview.ValueForMoney" />, reviewValue: "reviewValueForMoney" },
        { value: 'Service', label: <FormattedMessage id="ProfileReview.Service" />, reviewValue: "reviewService" },
        { value: 'FoodDrink', label: <FormattedMessage id="ProfileReview.Food&Drink" />, reviewValue: "reviewFoodDrink" },
        { value: 'Room', label: <FormattedMessage id="ProfileReview.Room" />, reviewValue: "reviewRoom" },
        { value: 'Reception', label: <FormattedMessage id="ProfileReview.Reception" />, reviewValue: "reviewReception" },
        { value: 'Pool', label: <FormattedMessage id="ProfileReview.Pool" />, reviewValue: "reviewPool" },
        { value: 'Restaurant', label: <FormattedMessage id="ProfileReview.Restaurant" />, reviewValue: "reviewRestaurant" },
        { value: 'Recommendation', label: <FormattedMessage id="ProfileReview.Recommendation" />, reviewValue: "reviewRecommendation" }
    ]
    return type;
};

export function getAllReviewFields() {
    const textReviews = [
        { value: 'Positive', label: <FormattedMessage id="ProfileReview.Positive" /> },
        { value: 'Negative', label: <FormattedMessage id="ProfileReview.Negative" /> }
    ]

    return getReviewFields().concat(textReviews);
};



//Campaigns
export function getMarketingType() {
    const marketingType = [
        { value: 'Campaign', label: <FormattedMessage id="Campaigns.Campaign" /> },
        { value: 'Birthday', label: <FormattedMessage id="Campaigns.Birthday" /> },
        { value: 'CreationDay', label: <FormattedMessage id="Campaigns.CreationDay" /> },
        { value: 'CampaignInquiry', label: <FormattedMessage id="Campaigns.CampaignInquiry" /> },
        { value: 'LinkedCampaign', label: <FormattedMessage id="Campaigns.LinkedCampaign" /> }
    ]
    return marketingType;
};

//Custom Campaigns
export function getCustomMarketingType() {
    const marketingType = [
        { value: 'Custom', label: <FormattedMessage id="Campaigns.Custom" /> },
        { value: 'CustomAB', label: <FormattedMessage id="Campaigns.CustomAB" /> },
        { value: 'CustomLinked', label: <FormattedMessage id="Campaigns.CustomLinked" /> },
        { value: 'CustomInquiry', label: <FormattedMessage id="Campaigns.CustomInquiry" /> }
    ]
    return marketingType;
};

export function getSenderChannel(hasEgoi, hasTwillio) {
    const senderChannel = [
        { value: 'Email', label: 'Email' }
    ]

    if (hasEgoi || hasTwillio) {
        senderChannel.push(
            { value: 'SMS', label: 'SMS' }
        );
    }

    return senderChannel;
};

export function getFrequency() {
    const frequency = [
        { value: 'Arrival', label: <FormattedMessage id = "Campaigns.Arrival" /> },
        { value: 'Departure', label: <FormattedMessage id="Campaigns.Departure" /> },
        { value: 'ReservationDate', label: <FormattedMessage id="Campaigns.ReservationDate" /> },
        { value: 'CancelationDate', label: <FormattedMessage id="Campaigns.CancelationDate" /> },
        { value: 'InHouse', label: <FormattedMessage id="Campaigns.InHouse" /> },
        { value: 'Custom', label: <FormattedMessage id="Campaigns.custom" /> }
    ]
    return frequency;
};

//#region MarketingAlerts
export function getAlertType() {
    const type = [
        {
            value: 'Review',
            label: <FormattedMessage id="ReferenceDataFunctions.Review" />,
            fieldType: [
                { value: 'Cleanliness', label: <FormattedMessage id="ProfileReview.Cleanliness" /> },
                { value: 'Comfort', label: <FormattedMessage id="ProfileReview.Comfort" /> },
                { value: 'Facilities', label: <FormattedMessage id="ProfileReview.Facilities" /> },
                { value: 'Location', label: <FormattedMessage id="ProfileReview.Location" /> },
                { value: 'Staff', label: <FormattedMessage id="ProfileReview.Staff" /> },
                { value: 'ValueForMoney', label: <FormattedMessage id="ProfileReview.ValueForMoney" /> },
                { value: 'Service', label: <FormattedMessage id="ProfileReview.Service" /> },
                { value: 'FoodDrink', label: <FormattedMessage id="ProfileReview.Food&Drink" /> },
                { value: 'Room', label: <FormattedMessage id="ProfileReview.Room" /> },
                { value: 'Reception', label: <FormattedMessage id="ProfileReview.Reception" /> },
                { value: 'Pool', label: <FormattedMessage id="ProfileReview.Pool" /> },
                { value: 'Restaurant', label: <FormattedMessage id="ProfileReview.Restaurant" /> },
                { value: 'Recommendation', label: <FormattedMessage id="ProfileReview.Recommendation" /> }
            ]
        },
        {
            value: 'Survey',
            label: 'Survey',
            fieldType: [
                { value: 'ReviewPro', label: "Review Pro" },
                { value: 'Egoi', label: "Egoi" },
                { value: 'TypeForm', label: "Typeform" }
            ]
        },
        {
            value: 'LoyaltyCardIssue',
            label: <FormattedMessage id="ReferenceDataFunctions.LoyaltyCardIssue" />,
            fieldType: [
                { value: 'ProcessTime', label: <FormattedMessage id="ReferenceDataFunctions.ProcessTime" /> },
            ]
        },
        {
            value: 'FormSubmit',
            label: <FormattedMessage id="ReferenceDataFunctions.FormSubmit" />,
            fieldType: [
                { value: 'PNForm', label: "PN Form" },
            ]
        },
    ]
    return type;
};

export function getAlertFieldCompare() {
    const fieldCompare = [
        { value: '>=', label: <FormattedMessage id="MarketingAlert.GreaterOrEqualTo" /> },
        { value: '<=', label: <FormattedMessage id="MarketingAlert.LessOrEqualTo" /> },
        { value: '=', label: <FormattedMessage id="MarketingAlert.Equal" /> }
    ]
    return fieldCompare;
};

export function getAlertActionType() {
    const alertActionType = [
        { value: 'Client', label: <FormattedMessage id="MarketingAlert.Client" /> },
        { value: 'Fixed', label: <FormattedMessage id="MarketingAlert.Fixed" /> }
    ]
    return alertActionType;
};

//#endregion

//#region AiConfig
export function getMetaAnalysisTags() {
    const metaAnalysisTags = [
        { value: 'Location', label: <FormattedMessage id="AiConfig.Location" /> },
        { value: 'Staff', label: <FormattedMessage id="AiConfig.Staff" /> },
        { value: 'CheckIn', label: <FormattedMessage id="AiConfig.CheckIn" /> },
        { value: 'CheckOut', label: <FormattedMessage id="AiConfig.CheckOut" /> },
        { value: 'Breakfast', label: <FormattedMessage id="AiConfig.Breakfast" /> },
        { value: 'Pool', label: <FormattedMessage id="AiConfig.Pool" /> },
        { value: 'Lunch', label: <FormattedMessage id="AiConfig.Lunch" /> },
        { value: 'Dinner', label: <FormattedMessage id="AiConfig.Dinner" /> },
        { value: 'Room', label: <FormattedMessage id="AiConfig.Room" /> },
        { value: 'Cleanliness', label: <FormattedMessage id="AiConfig.Cleanliness" /> }
    ]
    return metaAnalysisTags;
};
//#endregion
//Egoi Webhook
export function getEgoiWebhookActions(intl) {
    const actions = [
        { value: 'forget_subscription', label: intl.formatMessage({ id: "EgoiWebhookList.forget_subscription" }) },
        { value: 'change_consent', label: intl.formatMessage({ id: "EgoiWebhookList.change_consent" }) },
        { value: 'web_push_bounce', label: intl.formatMessage({ id: "EgoiWebhookList.web_push_bounce" }) },
        { value: 'web_push_click', label: intl.formatMessage({ id: "EgoiWebhookList.web_push_click" }) },
        { value: 'web_push_delivered', label: intl.formatMessage({ id: "EgoiWebhookList.web_push_delivered" }) },
        { value: 'web_push_open', label: intl.formatMessage({ id: "EgoiWebhookList.web_push_open" }) },
        { value: 'web_push_send', label: intl.formatMessage({ id: "EgoiWebhookList.web_push_send" }) },
        { value: 'web_push_subscription', label: intl.formatMessage({ id: "EgoiWebhookList.web_push_subscription" }) },
        { value: 'web_push_unsubscription', label: intl.formatMessage({ id: "EgoiWebhookList.web_push_unsubscription" }) },
        { value: 'email_send', label: intl.formatMessage({ id: "EgoiWebhookList.email_send" }) },
        { value: 'email_open', label: intl.formatMessage({ id: "EgoiWebhookList.email_open" }) },
        { value: 'email_click', label: intl.formatMessage({ id: "EgoiWebhookList.email_click" }) },
        { value: 'email_soft_bounce', label: intl.formatMessage({ id: "EgoiWebhookList.email_soft_bounce" }) },
        { value: 'email_hard_bounce', label: intl.formatMessage({ id: "EgoiWebhookList.email_hard_bounce" }) },
        { value: 'sms_send', label: intl.formatMessage({ id: "EgoiWebhookList.sms_send" }) },
        { value: 'voice_send', label: intl.formatMessage({ id: "EgoiWebhookList.voice_send" }) },
        { value: 'unsubscribe', label: intl.formatMessage({ id: "EgoiWebhookList.unsubscribe" }) },
        { value: 'subscription', label: intl.formatMessage({ id: "EgoiWebhookList.subscription" }) },
        { value: 'resubscription', label: intl.formatMessage({ id: "EgoiWebhookList.resubscription" }) },
        { value: 'facebook_like', label: intl.formatMessage({ id: "EgoiWebhookList.facebook_like" }) },
        { value: 'social_share', label: intl.formatMessage({ id: "EgoiWebhookList.social_share" }) },
        { value: 'double_optin', label: intl.formatMessage({ id: "EgoiWebhookList.double_optin" }) },
        { value: 'email_spam_complaint', label: intl.formatMessage({ id: "EgoiWebhookList.email_spam_complaint" }) },
        { value: 'email_field_disable', label: intl.formatMessage({ id: "EgoiWebhookList.email_field_disable" }) },
        { value: 'cellphone_field_disable', label: intl.formatMessage({ id: "EgoiWebhookList.cellphone_field_disable" }) },
        { value: 'phone_field_disable', label: intl.formatMessage({ id: "EgoiWebhookList.phone_field_disable" }) },
        { value: 'push_send', label: intl.formatMessage({ id: "EgoiWebhookList.push_send" }) },
        { value: 'push_open', label: intl.formatMessage({ id: "EgoiWebhookList.push_open" }) },
        { value: 'push_click', label: intl.formatMessage({ id: "EgoiWebhookList.push_click" }) },
        { value: 'push_received', label: intl.formatMessage({ id: "EgoiWebhookList.push_received" }) },
        { value: 'push_error', label: intl.formatMessage({ id: "EgoiWebhookList.push_error" }) },
        { value: 'push_canceled', label: intl.formatMessage({ id: "EgoiWebhookList.push_canceled" }) }
    ]
    return actions;
};

export function getEgoiTransactionalWebhookActions(intl) {
    const actions = [
        { value: 'sent', label: intl.formatMessage({ id: "EgoiWebhookList.sent" }) },
        { value: 'processed', label: intl.formatMessage({ id: "EgoiWebhookList.processed" }) },
        { value: 'view', label: intl.formatMessage({ id: "EgoiWebhookList.view" }) },
        { value: 'click', label: intl.formatMessage({ id: "EgoiWebhookList.click" }) },
        { value: 'bounce', label: intl.formatMessage({ id: "EgoiWebhookList.bounce" }) },
        { value: 'canceled', label: intl.formatMessage({ id: "EgoiWebhookList.canceled" }) },
        { value: 'failed', label: intl.formatMessage({ id: "EgoiWebhookList.failed" }) },
        { value: 'abuse', label: intl.formatMessage({ id: "EgoiWebhookList.abuse" }) },
        { value: 'remove', label: intl.formatMessage({ id: "EgoiWebhookList.remove" }) },
        { value: 'delivered', label: intl.formatMessage({ id: "EgoiWebhookList.delivered" }) }
    ]
    return actions;
};

export function getEgoiTransactionalWebhookActionsByChannel(intl) {
    const actions = {
        'Email': [
            { value: 'sent', label: intl.formatMessage({ id: "EgoiWebhookList.sent" }) },
            { value: 'processed', label: intl.formatMessage({ id: "EgoiWebhookList.processed" }) },
            { value: 'view', label: intl.formatMessage({ id: "EgoiWebhookList.view" }) },
            { value: 'click', label: intl.formatMessage({ id: "EgoiWebhookList.click" }) },
            { value: 'bounce', label: intl.formatMessage({ id: "EgoiWebhookList.bounce" }) },
            { value: 'canceled', label: intl.formatMessage({ id: "EgoiWebhookList.canceled" }) },
            { value: 'failed', label: intl.formatMessage({ id: "EgoiWebhookList.failed" }) },
            { value: 'abuse', label: intl.formatMessage({ id: "EgoiWebhookList.abuse" }) },
            { value: 'remove', label: intl.formatMessage({ id: "EgoiWebhookList.remove" }) }
        ],
        'SMS': [
            { value: 'processed', label: intl.formatMessage({ id: "EgoiWebhookList.processed" }) },
            { value: 'failed', label: intl.formatMessage({ id: "EgoiWebhookList.failed" }) },
            { value: 'sent', label: intl.formatMessage({ id: "EgoiWebhookList.sent" }) },
            { value: 'delivered', label: intl.formatMessage({ id: "EgoiWebhookList.delivered" }) },
            { value: 'canceled', label: intl.formatMessage({ id: "EgoiWebhookList.canceled" }) }
        ],
        'null': []
    }
        
    return actions;
};

export function getEgoiTransactionalWebhookChannelAndActions(intl) {
    const channels = [
        {
            value: 'Email',
            label: 'Email',
            idx: 0,
            actions: [
                { value: 'sent', label: intl.formatMessage({ id: "EgoiWebhookList.sent" }) },
                { value: 'processed', label: intl.formatMessage({ id: "EgoiWebhookList.processed" }) },
                { value: 'view', label: intl.formatMessage({ id: "EgoiWebhookList.view" }) },
                { value: 'click', label: intl.formatMessage({ id: "EgoiWebhookList.click" }) },
                { value: 'bounce', label: intl.formatMessage({ id: "EgoiWebhookList.bounce" }) },
                { value: 'canceled', label: intl.formatMessage({ id: "EgoiWebhookList.canceled" }) },
                { value: 'failed', label: intl.formatMessage({ id: "EgoiWebhookList.failed" }) },
                { value: 'abuse', label: intl.formatMessage({ id: "EgoiWebhookList.abuse" }) },
                { value: 'remove', label: intl.formatMessage({ id: "EgoiWebhookList.remove" }) }
            ]
        },
        {
            value: 'SMS',
            label: 'SMS',
            idx: 1,
            actions: [
                { value: 'processed', label: intl.formatMessage({ id: "EgoiWebhookList.processed" }) },
                { value: 'failed', label: intl.formatMessage({ id: "EgoiWebhookList.failed" }) },
                { value: 'sent', label: intl.formatMessage({ id: "EgoiWebhookList.sent" }) },
                { value: 'delivered', label: intl.formatMessage({ id: "EgoiWebhookList.delivered" }) },
                { value: 'canceled', label: intl.formatMessage({ id: "EgoiWebhookList.canceled" }) }
            ]
        }
    ];
    return channels;
}

export function getMarketingStatisticsStatus(intl) {
    const stats = [
        /*{ value: 'processed', label: intl.formatMessage({ id: "ReferenceDataFunctions.Processed" }), color: '#FBC753' },    //yellow*/
        { value: 'sent', label: intl.formatMessage({ id: "ReferenceDataFunctions.Sent" }), color: '#4CC9F0' },              //cyan
        /*{ value: 'delivered', label: intl.formatMessage({ id: "ReferenceDataFunctions.Delivered" }), color: '#6EDE8A' },    //green*/
        { value: 'viewed', label: intl.formatMessage({ id: "ReferenceDataFunctions.Viewed" }), color: '#CF53FA' },          //violet
        { value: 'clicked', label: intl.formatMessage({ id: "ReferenceDataFunctions.Clicked" }), color: '#0665ff' },        //host
        { value: 'bounced', label: intl.formatMessage({ id: "ReferenceDataFunctions.Bounced" }), color: '#FF9827' },        //orange
        { value: 'rejected', label: intl.formatMessage({ id: "ReferenceDataFunctions.Rejected" }), color: '#F38375' },      //light-red
        { value: 'failed', label: intl.formatMessage({ id: "ReferenceDataFunctions.Failed" }), color: '#ff4444' },          //red
        { value: 'removed', label: intl.formatMessage({ id: "ReferenceDataFunctions.Removed" }), color: '#7054E5' },        //purple
        { value: 'hardBounced', label: intl.formatMessage({ id: "ReferenceDataFunctions.hardBounced" }), color: '#E58823' },//dark-orange
        { value: 'softBounced', label: intl.formatMessage({ id: "ReferenceDataFunctions.softBounced" }), color: '#FFC17D' } //light-orange
    ]
    return stats;
};

export function getPMSReservationStatus(intl) {
    const actions = [
        { value: '0', label: intl.formatMessage({ id: "ReferenceDataFunctions.Normal" }), color: 'primary' },
        { value: '2', label: intl.formatMessage({ id: "ReferenceDataFunctions.Option" }), color: 'secondary' },
        { value: '3', label: intl.formatMessage({ id: "ReferenceDataFunctions.WaitingList" }), color: 'warning' },
        { value: '4', label: intl.formatMessage({ id: "ReferenceDataFunctions.PendingReservation" }), color: 'warning' },
        { value: '5', label: intl.formatMessage({ id: "ReferenceDataFunctions.OOO" }), color: 'secondary' },
        { value: '6', label: intl.formatMessage({ id: "ReferenceDataFunctions.Cancelation" }), color: 'danger' },
        { value: '7', label: intl.formatMessage({ id: "ReferenceDataFunctions.NoShow" }), color: 'danger' },
        { value: '8', label: intl.formatMessage({ id: "ReferenceDataFunctions.OOI" }), color: 'secondary' },
        { value: '9', label: intl.formatMessage({ id: "ReferenceDataFunctions.Refused" }), color: 'danger' },
        { value: '10', label: intl.formatMessage({ id: "ReferenceDataFunctions.CheckIn" }), color: 'primary' },
        { value: '20', label: intl.formatMessage({ id: "ReferenceDataFunctions.CheckOut" }), color: 'secondary' },
        { value: '51', label: intl.formatMessage({ id: "ReferenceDataFunctions.Maintenance" }), color: 'secondary' },
        { value: '99', label: intl.formatMessage({ id: "ReferenceDataFunctions.Deleted" }), color: 'danger' }
    ]
    
    return actions;
};

export function getScoreConfigType() {
    const type = [
        { value: 'RoomNights', label: <FormattedMessage id="ReferenceDataFunctions.RoomNights" /> },
        { value: 'Revenew', label: <FormattedMessage id="ReferenceDataFunctions.Revenue" /> },
        { value: 'Reviews', label: <FormattedMessage id="ReferenceDataFunctions.Reviews" /> },
        { value: 'Tag', label: <FormattedMessage id="ReferenceDataFunctions.Tag" /> }
    ]

    return type;
};

export function getReferenceDataType() {
    const type = [
        { value: 'Channel', label: <FormattedMessage id="ReferenceDataFunctions.Channel" />, tagType: 'ReservationChannel', endpoint: 'hotelReferenceData' },
    ]

    return type;
};

export function getPMSReferenceDataType() {
    const type = [
        { value: 'Package', label: <FormattedMessage id="ReferenceDataFunctions.Package" />, tagType: 'ReservationRate', endpoint: 'ReferenceData' },
        { value: 'Category', label: <FormattedMessage id="ReferenceDataFunctions.RoomCategory" />, tagType: 'ReservationRoom', endpoint: 'ReferenceData' },
        { value: 'PriceList', label: <FormattedMessage id="ReferenceDataFunctions.PriceList" />, endpoint: 'ReferenceData' }, //Without tag type
        { value: 'Charges', label: <FormattedMessage id="ReferenceDataFunctions.Revenue" />, tagType: 'None', endpoint: 'ReferenceData' },
        { value: 'Segments', label: <FormattedMessage id="ReferenceDataFunctions.Segments" />, tagType: 'ReservationSegment', endpoint: 'ReferenceData' },
        { value: 'SubSegments', label: <FormattedMessage id="ReferenceDataFunctions.SubSegments" />, tagType: 'ReservationSubsegment', endpoint: 'ReferenceData' },
        { value: 'DistributionChannels', label: <FormattedMessage id="ReferenceDataFunctions.DistChannel" />, tagType: 'ReservationMarket', endpoint: 'ReferenceData' },
        { value: 'VipCodes', label: <FormattedMessage id="ReferenceDataFunctions.VipCodes" />, endpoint: 'ReferenceData' }, //Without tag type
        { value: 'CancelReasons', label: <FormattedMessage id="ReferenceDataFunctions.CancelReason" />, endpoint: 'ReferenceData' }, //Without tag type
        { value: 'Sections', label: <FormattedMessage id="ReferenceDataFunctions.Sections" />, endpoint: 'ReferenceData' }, //Without tag type
        { value: 'BlackList', label: <FormattedMessage id="ReferenceDataFunctions.BlackList" />, endpoint: 'ReferenceData' }, //Without tag type
    ]

    return type;
};


//Country code
var countryISOMapping = {
    AFG: "AF",
    ALA: "AX",
    ALB: "AL",
    DZA: "DZ",
    ASM: "AS",
    AND: "AD",
    AGO: "AO",
    AIA: "AI",
    ATA: "AQ",
    ATG: "AG",
    ARG: "AR",
    ARM: "AM",
    ABW: "AW",
    AUS: "AU",
    AUT: "AT",
    AZE: "AZ",
    BHS: "BS",
    BHR: "BH",
    BGD: "BD",
    BRB: "BB",
    BLR: "BY",
    BEL: "BE",
    BLZ: "BZ",
    BEN: "BJ",
    BMU: "BM",
    BTN: "BT",
    BOL: "BO",
    BIH: "BA",
    BWA: "BW",
    BVT: "BV",
    BRA: "BR",
    VGB: "VG",
    IOT: "IO",
    BRN: "BN",
    BGR: "BG",
    BFA: "BF",
    BDI: "BI",
    KHM: "KH",
    CMR: "CM",
    CAN: "CA",
    CPV: "CV",
    CYM: "KY",
    CAF: "CF",
    TCD: "TD",
    CHL: "CL",
    CHN: "CN",
    HKG: "HK",
    MAC: "MO",
    CXR: "CX",
    CCK: "CC",
    COL: "CO",
    COM: "KM",
    COG: "CG",
    COD: "CD",
    COK: "CK",
    CRI: "CR",
    CIV: "CI",
    HRV: "HR",
    CUB: "CU",
    CYP: "CY",
    CZE: "CZ",
    DNK: "DK",
    DJI: "DJ",
    DMA: "DM",
    DOM: "DO",
    ECU: "EC",
    EGY: "EG",
    SLV: "SV",
    GNQ: "GQ",
    ERI: "ER",
    EST: "EE",
    ETH: "ET",
    FLK: "FK",
    FRO: "FO",
    FJI: "FJ",
    FIN: "FI",
    FRA: "FR",
    GUF: "GF",
    PYF: "PF",
    ATF: "TF",
    GAB: "GA",
    GMB: "GM",
    GEO: "GE",
    DEU: "DE",
    GHA: "GH",
    GIB: "GI",
    GRC: "GR",
    GRL: "GL",
    GRD: "GD",
    GLP: "GP",
    GUM: "GU",
    GTM: "GT",
    GGY: "GG",
    GIN: "GN",
    GNB: "GW",
    GUY: "GY",
    HTI: "HT",
    HMD: "HM",
    VAT: "VA",
    HND: "HN",
    HUN: "HU",
    ISL: "IS",
    IND: "IN",
    IDN: "ID",
    IRN: "IR",
    IRQ: "IQ",
    IRL: "IE",
    IMN: "IM",
    ISR: "IL",
    ITA: "IT",
    JAM: "JM",
    JPN: "JP",
    JEY: "JE",
    JOR: "JO",
    KAZ: "KZ",
    KEN: "KE",
    KIR: "KI",
    PRK: "KP",
    KOR: "KR",
    KWT: "KW",
    KGZ: "KG",
    LAO: "LA",
    LVA: "LV",
    LBN: "LB",
    LSO: "LS",
    LBR: "LR",
    LBY: "LY",
    LIE: "LI",
    LTU: "LT",
    LUX: "LU",
    MKD: "MK",
    MDG: "MG",
    MWI: "MW",
    MYS: "MY",
    MDV: "MV",
    MLI: "ML",
    MLT: "MT",
    MHL: "MH",
    MTQ: "MQ",
    MRT: "MR",
    MUS: "MU",
    MYT: "YT",
    MEX: "MX",
    FSM: "FM",
    MDA: "MD",
    MCO: "MC",
    MNG: "MN",
    MNE: "ME",
    MSR: "MS",
    MAR: "MA",
    MOZ: "MZ",
    MMR: "MM",
    NAM: "NA",
    NRU: "NR",
    NPL: "NP",
    NLD: "NL",
    ANT: "AN",
    NCL: "NC",
    NZL: "NZ",
    NIC: "NI",
    NER: "NE",
    NGA: "NG",
    NIU: "NU",
    NFK: "NF",
    MNP: "MP",
    NOR: "NO",
    OMN: "OM",
    PAK: "PK",
    PLW: "PW",
    PSE: "PS",
    PAN: "PA",
    PNG: "PG",
    PRY: "PY",
    PER: "PE",
    PHL: "PH",
    PCN: "PN",
    POL: "PL",
    PRT: "PT",
    PRI: "PR",
    QAT: "QA",
    REU: "RE",
    ROU: "RO",
    RUS: "RU",
    RWA: "RW",
    BLM: "BL",
    SHN: "SH",
    KNA: "KN",
    LCA: "LC",
    MAF: "MF",
    SPM: "PM",
    VCT: "VC",
    WSM: "WS",
    SMR: "SM",
    STP: "ST",
    SAU: "SA",
    SEN: "SN",
    SRB: "RS",
    SYC: "SC",
    SLE: "SL",
    SGP: "SG",
    SVK: "SK",
    SVN: "SI",
    SLB: "SB",
    SOM: "SO",
    SXM: "SX",
    ZAF: "ZA",
    SGS: "GS",
    SSD: "SS",
    ESP: "ES",
    LKA: "LK",
    SDN: "SD",
    SUR: "SR",
    SJM: "SJ",
    SWZ: "SZ",
    SWE: "SE",
    CHE: "CH",
    SYR: "SY",
    TWN: "TW",
    TJK: "TJ",
    TZA: "TZ",
    THA: "TH",
    TLS: "TL",
    TGO: "TG",
    TKL: "TK",
    TON: "TO",
    TTO: "TT",
    TUN: "TN",
    TUR: "TR",
    TKM: "TM",
    TCA: "TC",
    TUV: "TV",
    UGA: "UG",
    UKR: "UA",
    ARE: "AE",
    GBR: "GB",
    USA: "US",
    UMI: "UM",
    URY: "UY",
    UZB: "UZ",
    VUT: "VU",
    VEN: "VE",
    VNM: "VN",
    VIR: "VI",
    WLF: "WF",
    ESH: "EH",
    YEM: "YE",
    ZMB: "ZM",
    ZWE: "ZW",
    XKX: "XK"
}

export function getCountryISO2(countryCode) {
    return countryISOMapping[countryCode]
}

export function getCountryISO3(countryCode) {
    var result = Object.keys(countryISOMapping).find(key => countryISOMapping[key] === countryCode.toUpperCase());
    
    return result
}

export function getStates() {
    return [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NY', label: 'New York' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
    ]
}


const countryDialCodes = {
    "BD": "+880",
    "BE": "+32",
    "BF": "+226",
    "BG": "+359",
    "BA": "+387",
    "BB": "+1-246",
    "WF": "+681",
    "BL": "+590",
    "BM": "+1-441",
    "BN": "+673",
    "BO": "+591",
    "BH": "+973",
    "BI": "+257",
    "BJ": "+229",
    "BT": "+975",
    "JM": "+1-876",
    "BV": "",
    "BW": "+267",
    "WS": "+685",
    "BQ": "+599",
    "BR": "+55",
    "BS": "+1-242",
    "JE": "+44-1534",
    "BY": "+375",
    "BZ": "+501",
    "RU": "+7",
    "RW": "+250",
    "RS": "+381",
    "TL": "+670",
    "RE": "+262",
    "TM": "+993",
    "TJ": "+992",
    "RO": "+40",
    "TK": "+690",
    "GW": "+245",
    "GU": "+1-671",
    "GT": "+502",
    "GS": "",
    "GR": "+30",
    "GQ": "+240",
    "GP": "+590",
    "JP": "+81",
    "GY": "+592",
    "GG": "+44-1481",
    "GF": "+594",
    "GE": "+995",
    "GD": "+1-473",
    "GB": "+44",
    "GA": "+241",
    "SV": "+503",
    "GN": "+224",
    "GM": "+220",
    "GL": "+299",
    "GI": "+350",
    "GH": "+233",
    "OM": "+968",
    "TN": "+216",
    "JO": "+962",
    "HR": "+385",
    "HT": "+509",
    "HU": "+36",
    "HK": "+852",
    "HN": "+504",
    "HM": "",
    "VE": "+58",
    "PR": "+1-787",
    "PS": "+970",
    "PW": "+680",
    "PT": "+351",
    "SJ": "+47",
    "PY": "+595",
    "IQ": "+964",
    "PA": "+507",
    "PF": "+689",
    "PG": "+675",
    "PE": "+51",
    "PK": "+92",
    "PH": "+63",
    "PN": "+870",
    "PL": "+48",
    "PM": "+508",
    "ZM": "+260",
    "EH": "+212",
    "EE": "+372",
    "EG": "+20",
    "ZA": "+27",
    "EC": "+593",
    "IT": "+39",
    "VN": "+84",
    "SB": "+677",
    "ET": "+251",
    "SO": "+252",
    "ZW": "+263",
    "SA": "+966",
    "ES": "+34",
    "ER": "+291",
    "ME": "+382",
    "MD": "+373",
    "MG": "+261",
    "MF": "+590",
    "MA": "+212",
    "MC": "+377",
    "UZ": "+998",
    "MM": "+95",
    "ML": "+223",
    "MO": "+853",
    "MN": "+976",
    "MH": "+692",
    "MK": "+389",
    "MU": "+230",
    "MT": "+356",
    "MW": "+265",
    "MV": "+960",
    "MQ": "+596",
    "MP": "+1-670",
    "MS": "+1-664",
    "MR": "+222",
    "IM": "+44-1624",
    "UG": "+256",
    "TZ": "+255",
    "MY": "+60",
    "MX": "+52",
    "IL": "+972",
    "FR": "+33",
    "IO": "+246",
    "SH": "+290",
    "FI": "+358",
    "FJ": "+679",
    "FK": "+500",
    "FM": "+691",
    "FO": "+298",
    "NI": "+505",
    "NL": "+31",
    "NO": "+47",
    "NA": "+264",
    "VU": "+678",
    "NC": "+687",
    "NE": "+227",
    "NF": "+672",
    "NG": "+234",
    "NZ": "+64",
    "NP": "+977",
    "NR": "+674",
    "NU": "+683",
    "CK": "+682",
    "XK": "",
    "CI": "+225",
    "CH": "+41",
    "CO": "+57",
    "CN": "+86",
    "CM": "+237",
    "CL": "+56",
    "CC": "+61",
    "CA": "+1",
    "CG": "+242",
    "CF": "+236",
    "CD": "+243",
    "CZ": "+420",
    "CY": "+357",
    "CX": "+61",
    "CR": "+506",
    "CW": "+599",
    "CV": "+238",
    "CU": "+53",
    "SZ": "+268",
    "SY": "+963",
    "SX": "+599",
    "KG": "+996",
    "KE": "+254",
    "SS": "+211",
    "SR": "+597",
    "KI": "+686",
    "KH": "+855",
    "KN": "+1-869",
    "KM": "+269",
    "ST": "+239",
    "SK": "+421",
    "KR": "+82",
    "SI": "+386",
    "KP": "+850",
    "KW": "+965",
    "SN": "+221",
    "SM": "+378",
    "SL": "+232",
    "SC": "+248",
    "KZ": "+7",
    "KY": "+1-345",
    "SG": "+65",
    "SE": "+46",
    "SD": "+249",
    "DO": "+1-809",
    "DM": "+1-767",
    "DJ": "+253",
    "DK": "+45",
    "VG": "+1-284",
    "DE": "+49",
    "YE": "+967",
    "DZ": "+213",
    "US": "+1",
    "UY": "+598",
    "YT": "+262",
    "UM": "+1",
    "LB": "+961",
    "LC": "+1-758",
    "LA": "+856",
    "TV": "+688",
    "TW": "+886",
    "TT": "+1-868",
    "TR": "+90",
    "LK": "+94",
    "LI": "+423",
    "LV": "+371",
    "TO": "+676",
    "LT": "+370",
    "LU": "+352",
    "LR": "+231",
    "LS": "+266",
    "TH": "+66",
    "TF": "",
    "TG": "+228",
    "TD": "+235",
    "TC": "+1-649",
    "LY": "+218",
    "VA": "+379",
    "VC": "+1-784",
    "AE": "+971",
    "AD": "+376",
    "AG": "+1-268",
    "AF": "+93",
    "AI": "+1-264",
    "VI": "+1-340",
    "IS": "+354",
    "IR": "+98",
    "AM": "+374",
    "AL": "+355",
    "AO": "+244",
    "AQ": "",
    "AS": "+1-684",
    "AR": "+54",
    "AU": "+61",
    "AT": "+43",
    "AW": "+297",
    "IN": "+91",
    "AX": "+358-18",
    "AZ": "+994",
    "IE": "+353",
    "ID": "+62",
    "UA": "+380",
    "QA": "+974",
    "MZ": "+258"
}

export function getCountryDialCodes(countryCode) {
    return countryDialCodes[countryCode];
}

export function getAllCountryDialCodes() {
    return countryDialCodes;
}

//#region timezone

//If hotel's timezone is configured, converts the date received to that timezone, if not, it uses UTC +00:00
export function getConvertedDateToHotelTimezone(date) {

    var convertedDate = date ? global.hotelTimezone ? moment(date).tz(global.hotelTimezone) : moment(date) : '';

    return convertedDate;
};

//Convert date to UTC
export function getDateConvertedToUTC(date) {
    var convertedDate = date ? global.hotelTimezone ? moment.tz(date, "YYYY-MM-DD HH:mm", global.hotelTimezone).utc() : moment(date) : '';

    return convertedDate;
};

//#endregion

export function getParameterOperator() {
    const type = [
        { value: 'GreaterThanOrEqual', label: <FormattedMessage id="ReferenceDataFunctions.GreaterThanOrEqual" /> },
        { value: 'LessThanOrEqual', label: <FormattedMessage id="ReferenceDataFunctions.LessThanOrEqual" /> }
    ]

    return type;
};

//#region countryList 
export function setCountryLabelsPT(countryList) {
    countryList().setLabel('AF', 'Afeganistão');
    countryList().setLabel('AX', 'Ilhas Åland');
    countryList().setLabel('AL', 'Albânia');
    countryList().setLabel('DZ', 'Argélia');
    countryList().setLabel('AS', 'Samoa Americana');
    countryList().setLabel('AD', 'Andorra');
    countryList().setLabel('AO', 'Angola');
    countryList().setLabel('AI', 'Anguilha');
    countryList().setLabel('AQ', 'Antártida');
    countryList().setLabel('AG', 'Antígua e Barbuda');
    countryList().setLabel('AR', 'Argentina');
    countryList().setLabel('AM', 'Arménia');
    countryList().setLabel('AW', 'Aruba');
    countryList().setLabel('AU', 'Austrália');
    countryList().setLabel('AT', 'Áustria');
    countryList().setLabel('AZ', 'Azerbaijão');
    countryList().setLabel('BS', 'Bahamas');
    countryList().setLabel('BH', 'Bahrein');
    countryList().setLabel('BD', 'Bangladesh');
    countryList().setLabel('BB', 'Barbados');
    countryList().setLabel('BY', 'Bielorrússia');
    countryList().setLabel('BE', 'Bélgica');
    countryList().setLabel('BZ', 'Belize');
    countryList().setLabel('BJ', 'Benim');
    countryList().setLabel('BM', 'Bermudas');
    countryList().setLabel('BT', 'Butão');
    countryList().setLabel('BO', 'Bolívia');
    countryList().setLabel('BQ', 'Bonaire');
    countryList().setLabel('BA', 'Bósnia e Herzegovina');
    countryList().setLabel('BW', 'Botswana');
    countryList().setLabel('BV', 'Ilha Bouvet');
    countryList().setLabel('BR', 'Brasil');
    countryList().setLabel('IO', 'Território Britânico do Oceano Índico');
    countryList().setLabel('BN', 'Brunei');
    countryList().setLabel('BG', 'Bulgária');
    countryList().setLabel('BF', 'Burkina Faso');
    countryList().setLabel('BI', 'Burundi');
    countryList().setLabel('KH', 'Camboja');
    countryList().setLabel('CM', 'Camarões');
    countryList().setLabel('CA', 'Canadá');
    countryList().setLabel('CV', 'Cabo Verde');
    countryList().setLabel('KY', 'Ilhas Caimão');
    countryList().setLabel('CF', 'República Centro Africana');
    countryList().setLabel('TD', 'Chade');
    countryList().setLabel('CL', 'Chile');
    countryList().setLabel('CN', 'China');
    countryList().setLabel('CX', 'Ilha Christmas');
    countryList().setLabel('CC', 'Ilhas Cocos (Keeling)');
    countryList().setLabel('CO', 'Colômbia');
    countryList().setLabel('KM', 'Comores');
    countryList().setLabel('CG', 'Congo');
    countryList().setLabel('CD', 'República Democrática do Congo');
    countryList().setLabel('CK', 'Ilhas Cook');
    countryList().setLabel('CR', 'Costa Rica');
    countryList().setLabel('CI', 'Costa do Marfim');
    countryList().setLabel('HR', 'Croácia');
    countryList().setLabel('CU', 'Cuba');
    countryList().setLabel('CW', 'Curaçao');
    countryList().setLabel('CY', 'Chipre');
    countryList().setLabel('CZ', 'República Checa');
    countryList().setLabel('DK', 'Dinamarca');
    countryList().setLabel('DJ', 'Djibouti');
    countryList().setLabel('DM', 'Dominica');
    countryList().setLabel('DO', 'República Dominicana');
    countryList().setLabel('EC', 'Equador');
    countryList().setLabel('EG', 'Egito');
    countryList().setLabel('SV', 'El Salvador');
    countryList().setLabel('GQ', 'Guiné Equatorial');
    countryList().setLabel('ER', 'Eritreia');
    countryList().setLabel('EE', 'Estónia');
    countryList().setLabel('ET', 'Etiópia');
    countryList().setLabel('FK', 'Ilhas Malvinas');
    countryList().setLabel('FO', 'Ilhas Feroe');
    countryList().setLabel('FJ', 'Fiji');
    countryList().setLabel('FI', 'Finlândia');
    countryList().setLabel('FR', 'França');
    countryList().setLabel('GF', 'Guiana Francesa');
    countryList().setLabel('PF', 'Polinésia Francesa');
    countryList().setLabel('TF', 'Território Sul Francês');
    countryList().setLabel('GA', 'Gabão');
    countryList().setLabel('GM', 'Gâmbia');
    countryList().setLabel('GE', 'Georgia');
    countryList().setLabel('DE', 'Alemanha');
    countryList().setLabel('GH', 'Gana');
    countryList().setLabel('GI', 'Gibraltar');
    countryList().setLabel('GR', 'Grécia');
    countryList().setLabel('GL', 'Gronelândia');
    countryList().setLabel('GD', 'Granada');
    countryList().setLabel('GP', 'Guadalupe');
    countryList().setLabel('GU', 'Guam');
    countryList().setLabel('GT', 'Guatemala');
    countryList().setLabel('GG', 'Guernsey');
    countryList().setLabel('GN', 'Guiné');
    countryList().setLabel('GW', 'Guiné-Bissau');
    countryList().setLabel('GY', 'Guiana');
    countryList().setLabel('HT', 'Haiti');
    countryList().setLabel('HM', 'Ilha Heard e Ilhas McDonald');
    countryList().setLabel('VA', 'Vaticano');
    countryList().setLabel('HN', 'Honduras');
    countryList().setLabel('HK', 'Hong Kong');
    countryList().setLabel('HU', 'Hungria');
    countryList().setLabel('IS', 'Islândia');
    countryList().setLabel('IN', 'Índia');
    countryList().setLabel('ID', 'Indonésia');
    countryList().setLabel('IR', 'Irão');
    countryList().setLabel('IQ', 'Iraque');
    countryList().setLabel('IE', 'Irlanda');
    countryList().setLabel('IM', 'Ilha de Man');
    countryList().setLabel('IL', 'Israel');
    countryList().setLabel('IT', 'Itália');
    countryList().setLabel('JM', 'Jamaica');
    countryList().setLabel('JP', 'Japão');
    countryList().setLabel('JE', 'Jersey');
    countryList().setLabel('JO', 'Jordânia');
    countryList().setLabel('KZ', 'Cazaquistão');
    countryList().setLabel('KE', 'Quênia');
    countryList().setLabel('KI', 'Kiribati');
    countryList().setLabel('KP', 'Coreia do Norte');
    countryList().setLabel('KR', 'Coreia do Sul');
    countryList().setLabel('KW', 'Kuwait');
    countryList().setLabel('KG', 'Quirguistão');
    countryList().setLabel('LA', 'Laos');
    countryList().setLabel('LV', 'Letónia');
    countryList().setLabel('LB', 'Líbano');
    countryList().setLabel('LS', 'Lesoto');
    countryList().setLabel('LR', 'Libéria');
    countryList().setLabel('LY', 'Líbia');
    countryList().setLabel('LI', 'Liechtenstein');
    countryList().setLabel('LT', 'Lituânia');
    countryList().setLabel('LU', 'Luxemburgo');
    countryList().setLabel('MO', 'Macau');
    countryList().setLabel('MK', 'Macedónia do Norte');
    countryList().setLabel('MG', 'Madagáscar');
    countryList().setLabel('MW', 'Malawi');
    countryList().setLabel('MY', 'Malásia');
    countryList().setLabel('MV', 'Maldivas');
    countryList().setLabel('ML', 'Mali');
    countryList().setLabel('MT', 'Malta');
    countryList().setLabel('MH', 'Ilhas Marshall');
    countryList().setLabel('MQ', 'Martinica');
    countryList().setLabel('MR', 'Mauritânia');
    countryList().setLabel('MU', 'Maurícia');
    countryList().setLabel('YT', 'Mayotte');
    countryList().setLabel('MX', 'México');
    countryList().setLabel('FM', 'Micronésia');
    countryList().setLabel('MD', 'Moldávia');
    countryList().setLabel('MC', 'Mónaco');
    countryList().setLabel('MN', 'Mongólia');
    countryList().setLabel('ME', 'Montenegro');
    countryList().setLabel('MS', 'Montserrat');
    countryList().setLabel('MA', 'Marrocos');
    countryList().setLabel('MZ', 'Moçambique');
    countryList().setLabel('MM', 'Myanmar');
    countryList().setLabel('NA', 'Namíbia');
    countryList().setLabel('NR', 'Nauru');
    countryList().setLabel('NP', 'Nepal');
    countryList().setLabel('NL', 'Holanda');
    countryList().setLabel('NC', 'Nova Caledónia');
    countryList().setLabel('NZ', 'Nova Zelândia');
    countryList().setLabel('NI', 'Nicarágua');
    countryList().setLabel('NE', 'Níger');
    countryList().setLabel('NG', 'Nigéria');
    countryList().setLabel('NU', 'Niue');
    countryList().setLabel('NF', 'Ilha Norfolk');
    countryList().setLabel('MP', 'Ilhas Marianas');
    countryList().setLabel('NO', 'Noruega');
    countryList().setLabel('OM', 'Omã');
    countryList().setLabel('PK', 'Paquistão');
    countryList().setLabel('PW', 'Palau');
    countryList().setLabel('PS', 'Palestina');
    countryList().setLabel('PA', 'Panamá');
    countryList().setLabel('PG', 'Papua-Nova Guiné');
    countryList().setLabel('PY', 'Paraguai');
    countryList().setLabel('PE', 'Peru');
    countryList().setLabel('PH', 'Filipinas');
    countryList().setLabel('PN', 'Ilhas Pitcairn');
    countryList().setLabel('PL', 'Polónia');
    countryList().setLabel('PT', 'Portugal');
    countryList().setLabel('PR', 'Porto Rico');
    countryList().setLabel('QA', 'Catar');
    countryList().setLabel('RE', 'Ilha da Reunião');
    countryList().setLabel('RO', 'Roménia');
    countryList().setLabel('RU', 'Rússia');
    countryList().setLabel('RW', 'Ruanda');
    countryList().setLabel('BL', 'São Bartolomeu');
    countryList().setLabel('SH', 'Santa Helena, Ascensão e Tristão da Cunha');
    countryList().setLabel('KN', 'São Cristóvão e Nevis');
    countryList().setLabel('LC', 'Santa Lúcia');
    countryList().setLabel('MF', 'São Martinho (França)');
    countryList().setLabel('PM', 'São Pedro e Miquelão');
    countryList().setLabel('VC', 'São Vicente e Granadinas');
    countryList().setLabel('WS', 'Samoa');
    countryList().setLabel('SM', 'San Marino');
    countryList().setLabel('ST', 'São Tomé e Príncipe');
    countryList().setLabel('SA', 'Arábia Saudita');
    countryList().setLabel('SN', 'Senegal');
    countryList().setLabel('RS', 'Sérvia');
    countryList().setLabel('SC', 'Seicheles');
    countryList().setLabel('SL', 'Serra Leoa');
    countryList().setLabel('SG', 'Singapura');
    countryList().setLabel('SX', 'São Martinho (Holanda)');
    countryList().setLabel('SK', 'Eslováquia');
    countryList().setLabel('SI', 'Eslovénia');
    countryList().setLabel('SB', 'Ilhas Salomão');
    countryList().setLabel('SO', 'Somália');
    countryList().setLabel('ZA', 'África do Sul');
    countryList().setLabel('GS', 'Ilhas Geórgia do Sul e Sandwich do Sul');
    countryList().setLabel('SS', 'Sudão do Sul');
    countryList().setLabel('ES', 'Espanha');
    countryList().setLabel('LK', 'Sri Lanka');
    countryList().setLabel('SD', 'Sudão');
    countryList().setLabel('SR', 'Suriname');
    countryList().setLabel('SJ', 'Svalbard e Jan Mayen');
    countryList().setLabel('SZ', 'Suazilândia');
    countryList().setLabel('SE', 'Suécia');
    countryList().setLabel('CH', 'Suíça');
    countryList().setLabel('SY', 'Síria');
    countryList().setLabel('TW', 'Taiwan');
    countryList().setLabel('TJ', 'Tajiquistão');
    countryList().setLabel('TZ', 'Tanzânia');
    countryList().setLabel('TH', 'Tailândia');
    countryList().setLabel('TL', 'Timor-Leste');
    countryList().setLabel('TG', 'Togo');
    countryList().setLabel('TK', 'Toquelau');
    countryList().setLabel('TO', 'Tonga');
    countryList().setLabel('TT', 'Trindade e Tobago');
    countryList().setLabel('TN', 'Tunísia');
    countryList().setLabel('TR', 'Turquia');
    countryList().setLabel('TM', 'Turquemenistão');
    countryList().setLabel('TC', 'Ilhas Turcas e Caicos');
    countryList().setLabel('TV', 'Tuvalu');
    countryList().setLabel('UG', 'Uganda');
    countryList().setLabel('UA', 'Ucrânia');
    countryList().setLabel('AE', 'Emirados Árabes Unidos');
    countryList().setLabel('GB', 'Reino Unido');
    countryList().setLabel('US', 'Estados Unidos da América');
    countryList().setLabel('UM', 'Ilhas Menores Distantes dos Estados Unidos');
    countryList().setLabel('UY', 'Uruguai');
    countryList().setLabel('UZ', 'Uzbequistão');
    countryList().setLabel('VU', 'Vanuatu');
    countryList().setLabel('VE', 'Venezuela');
    countryList().setLabel('VN', 'Vietname');
    countryList().setLabel('VG', 'Ilhas Virgens Britânicas');
    countryList().setLabel('VI', 'Ilhas Virgens Americanas');
    countryList().setLabel('WF', 'Wallis e Futuna');
    countryList().setLabel('EH', 'Saara Ocidental');
    countryList().setLabel('YE', 'Iémen');
    countryList().setLabel('ZM', 'Zâmbia');
    countryList().setLabel('ZW', 'Zimbaué');
    countryList().setLabel('XK', 'Kosovo');
}

export function setCountryLabelsEN(countryList) {
    countryList().setLabel('AF', 'Afghanistan');
    countryList().setLabel('AX', 'Åland Islands');
    countryList().setLabel('AL', 'Albania');
    countryList().setLabel('DZ', 'Algeria');
    countryList().setLabel('AS', 'American Samoa');
    countryList().setLabel('AD', 'Andorra');
    countryList().setLabel('AO', 'Angola');
    countryList().setLabel('AI', 'Anguilla');
    countryList().setLabel('AQ', 'Antarctica');
    countryList().setLabel('AG', 'Antigua and Barbuda');
    countryList().setLabel('AR', 'Argentina');
    countryList().setLabel('AM', 'Armenia');
    countryList().setLabel('AW', 'Aruba');
    countryList().setLabel('AU', 'Australia');
    countryList().setLabel('AT', 'Austria');
    countryList().setLabel('AZ', 'Azerbaijan');
    countryList().setLabel('BS', 'Bahamas');
    countryList().setLabel('BH', 'Bahrein');
    countryList().setLabel('BD', 'Bangladesh');
    countryList().setLabel('BB', 'Barbados');
    countryList().setLabel('BY', 'Belarus');
    countryList().setLabel('BE', 'Belgium');
    countryList().setLabel('BZ', 'Belize');
    countryList().setLabel('BJ', 'Benim');
    countryList().setLabel('BM', 'Bermuda');
    countryList().setLabel('BT', 'Bhutan');
    countryList().setLabel('BO', 'Bolivia, Plurinational State of');
    countryList().setLabel('BQ', 'Bonaire, Sint Eustatius and Saba');
    countryList().setLabel('BA', 'Bosnia and Herzegovina');
    countryList().setLabel('BW', 'Botswana');
    countryList().setLabel('BV', 'Bouvet Island');
    countryList().setLabel('BR', 'Brazil');
    countryList().setLabel('IO', 'British Indian Ocean Territory');
    countryList().setLabel('BN', 'Brunei Darussalam');
    countryList().setLabel('BG', 'Bulgaria');
    countryList().setLabel('BF', 'Burkina Faso');
    countryList().setLabel('BI', 'Burundi');
    countryList().setLabel('KH', 'Cambodia');
    countryList().setLabel('CM', 'Cameroon');
    countryList().setLabel('CA', 'Canada');
    countryList().setLabel('CV', 'Cape Verde');
    countryList().setLabel('KY', 'Cayman Islands');
    countryList().setLabel('CF', 'Central African Republic');
    countryList().setLabel('TD', 'Chad');
    countryList().setLabel('CL', 'Chile');
    countryList().setLabel('CN', 'China');
    countryList().setLabel('CX', 'Christmas Island');
    countryList().setLabel('CC', 'Cocos (Keeling) Islands');
    countryList().setLabel('CO', 'Colombia');
    countryList().setLabel('KM', 'Comores');
    countryList().setLabel('CG', 'Congo');
    countryList().setLabel('CD', 'Congo, the Democratic Republic of the');
    countryList().setLabel('CK', 'Cook Islands');
    countryList().setLabel('CR', 'Costa Rica');
    countryList().setLabel('CI', `Côte d'Ivoire`);
    countryList().setLabel('HR', 'Croatia');
    countryList().setLabel('CU', 'Cuba');
    countryList().setLabel('CW', 'Curaçao');
    countryList().setLabel('CY', 'Cyprus');
    countryList().setLabel('CZ', 'Czech Republic');
    countryList().setLabel('DK', 'Denmark');
    countryList().setLabel('DJ', 'Djibouti');
    countryList().setLabel('DM', 'Dominica');
    countryList().setLabel('DO', 'Dominican Republic');
    countryList().setLabel('EC', 'Ecuador');
    countryList().setLabel('EG', 'Egypt');
    countryList().setLabel('SV', 'El Salvador');
    countryList().setLabel('GQ', 'Equatorial Guinea');
    countryList().setLabel('ER', 'Eritrea');
    countryList().setLabel('EE', 'Estonia');
    countryList().setLabel('ET', 'Ethiopia');
    countryList().setLabel('FK', 'Falkland Islands (Malvinas)');
    countryList().setLabel('FO', 'Faroe Islands');
    countryList().setLabel('FJ', 'Fiji');
    countryList().setLabel('FI', 'Finland');
    countryList().setLabel('FR', 'France');
    countryList().setLabel('GF', 'French Guiana');
    countryList().setLabel('PF', 'French Polynesia');
    countryList().setLabel('TF', 'French Southern Territories');
    countryList().setLabel('GA', 'Gabon');
    countryList().setLabel('GM', 'Gambia');
    countryList().setLabel('GE', 'Georgia');
    countryList().setLabel('DE', 'Germany');
    countryList().setLabel('GH', 'Ghana');
    countryList().setLabel('GI', 'Gibraltar');
    countryList().setLabel('GR', 'Greece');
    countryList().setLabel('GL', 'Greenland');
    countryList().setLabel('GD', 'Grenada');
    countryList().setLabel('GP', 'Guadalupe');
    countryList().setLabel('GU', 'Guam');
    countryList().setLabel('GT', 'Guatemala');
    countryList().setLabel('GG', 'Guernsey');
    countryList().setLabel('GN', 'Guinea');
    countryList().setLabel('GW', 'Guinea-Bissau');
    countryList().setLabel('GY', 'Guyana');
    countryList().setLabel('HT', 'Haiti');
    countryList().setLabel('HM', 'Heard Island and McDonald Islands');
    countryList().setLabel('VA', 'Holy See (Vatican City State)');
    countryList().setLabel('HN', 'Honduras');
    countryList().setLabel('HK', 'Hong Kong');
    countryList().setLabel('HU', 'Hungary');
    countryList().setLabel('IS', 'Iceland');
    countryList().setLabel('IN', 'India');
    countryList().setLabel('ID', 'Indonesia');
    countryList().setLabel('IR', 'Iran, Islamic Republic of');
    countryList().setLabel('IQ', 'Iraq');
    countryList().setLabel('IE', 'Ireland');
    countryList().setLabel('IM', 'Isle of Man');
    countryList().setLabel('IL', 'Israel');
    countryList().setLabel('IT', 'Italy');
    countryList().setLabel('JM', 'Jamaica');
    countryList().setLabel('JP', 'Japan');
    countryList().setLabel('JE', 'Jersey');
    countryList().setLabel('JO', 'Jordan');
    countryList().setLabel('KZ', 'Kazakhstan');
    countryList().setLabel('KE', 'Kenya');
    countryList().setLabel('KI', 'Kiribati');
    countryList().setLabel('KP', `Korea, Democratic People's Republic of`);
    countryList().setLabel('KR', 'Korea, Republic of');
    countryList().setLabel('KW', 'Kuwait');
    countryList().setLabel('KG', 'Kyrgyzstan');
    countryList().setLabel('LA', `Lao People's Democratic Republic`);
    countryList().setLabel('LV', 'Latvia');
    countryList().setLabel('LB', 'Lebanon');
    countryList().setLabel('LS', 'Lesotho');
    countryList().setLabel('LR', 'Liberia');
    countryList().setLabel('LY', 'Libya');
    countryList().setLabel('LI', 'Liechtenstein');
    countryList().setLabel('LT', 'Lithuania');
    countryList().setLabel('LU', 'Luxemburg');
    countryList().setLabel('MO', 'Macao');
    countryList().setLabel('MK', 'Macedonia, the Former Yugoslav Republic of');
    countryList().setLabel('MG', 'Madagascar');
    countryList().setLabel('MW', 'Malawi');
    countryList().setLabel('MY', 'Malaysia');
    countryList().setLabel('MV', 'Maldives');
    countryList().setLabel('ML', 'Mali');
    countryList().setLabel('MT', 'Malta');
    countryList().setLabel('MH', 'Marshall Islands');
    countryList().setLabel('MQ', 'Martinique');
    countryList().setLabel('MR', 'Mauritania');
    countryList().setLabel('MU', 'Mauritius');
    countryList().setLabel('YT', 'Mayotte');
    countryList().setLabel('MX', 'Mexico');
    countryList().setLabel('FM', 'Micronesia, Federated States of');
    countryList().setLabel('MD', 'Moldova, Republic of');
    countryList().setLabel('MC', 'Monaco');
    countryList().setLabel('MN', 'Mongolia');
    countryList().setLabel('ME', 'Montenegro');
    countryList().setLabel('MS', 'Montserrat');
    countryList().setLabel('MA', 'Morocco');
    countryList().setLabel('MZ', 'Mozambique');
    countryList().setLabel('MM', 'Myanmar');
    countryList().setLabel('NA', 'Namibia');
    countryList().setLabel('NR', 'Nauru');
    countryList().setLabel('NP', 'Nepal');
    countryList().setLabel('NL', 'Netherlands');
    countryList().setLabel('NC', 'New Caledonia');
    countryList().setLabel('NZ', 'New Zealand');
    countryList().setLabel('NI', 'Nicaragua');
    countryList().setLabel('NE', 'Niger');
    countryList().setLabel('NG', 'Nigeria');
    countryList().setLabel('NU', 'Niue');
    countryList().setLabel('NF', 'Norfolk Island');
    countryList().setLabel('MP', 'Northern Mariana Islands');
    countryList().setLabel('NO', 'Norway');
    countryList().setLabel('OM', 'Oman');
    countryList().setLabel('PK', 'Pakistan');
    countryList().setLabel('PW', 'Palau');
    countryList().setLabel('PS', 'Palestine, State of');
    countryList().setLabel('PA', 'Panama');
    countryList().setLabel('PG', 'Papua New Guinea');
    countryList().setLabel('PY', 'Paraguay');
    countryList().setLabel('PE', 'Peru');
    countryList().setLabel('PH', 'Philippines');
    countryList().setLabel('PN', 'Pitcairn');
    countryList().setLabel('PL', 'Poland');
    countryList().setLabel('PT', 'Portugal');
    countryList().setLabel('PR', 'Puerto Rico');
    countryList().setLabel('QA', 'Qatar');
    countryList().setLabel('RE', 'Réunion');
    countryList().setLabel('RO', 'Romania');
    countryList().setLabel('RU', 'Russian Federation');
    countryList().setLabel('RW', 'Rwanda');
    countryList().setLabel('BL', 'Saint Barthélemy');
    countryList().setLabel('SH', 'Saint Helena, Ascension and Tristan da Cunha');
    countryList().setLabel('KN', 'Saint Kitts and Nevis');
    countryList().setLabel('LC', 'Saint Lucia');
    countryList().setLabel('MF', 'Saint Martin (French part)');
    countryList().setLabel('PM', 'Saint Pierre and Miquelon');
    countryList().setLabel('VC', 'Saint Vincent and the Grenadines');
    countryList().setLabel('WS', 'Samoa');
    countryList().setLabel('SM', 'San Marino');
    countryList().setLabel('ST', 'Sao Tome and Príncipe');
    countryList().setLabel('SA', 'Saudi Arabia');
    countryList().setLabel('SN', 'Senegal');
    countryList().setLabel('RS', 'Serbia');
    countryList().setLabel('SC', 'Seychelles');
    countryList().setLabel('SL', 'Sierra Leone');
    countryList().setLabel('SG', 'Singapore');
    countryList().setLabel('SX', 'Sint Maarten (Dutch part)');
    countryList().setLabel('SK', 'Slovakia');
    countryList().setLabel('SI', 'Slovenia');
    countryList().setLabel('SB', 'Solomon Islands');
    countryList().setLabel('SO', 'Somalia');
    countryList().setLabel('ZA', 'South Africa');
    countryList().setLabel('GS', 'South Georgia and the South Sandwich Islands');
    countryList().setLabel('SS', 'South Sudan');
    countryList().setLabel('ES', 'Spain');
    countryList().setLabel('LK', 'Sri Lanka');
    countryList().setLabel('SD', 'Sudan');
    countryList().setLabel('SR', 'Suriname');
    countryList().setLabel('SJ', 'Svalbard and Jan Mayen');
    countryList().setLabel('SZ', 'Swaziland');
    countryList().setLabel('SE', 'Sweden');
    countryList().setLabel('CH', 'Switzerland');
    countryList().setLabel('SY', 'Syrian Arab Republic');
    countryList().setLabel('TW', 'Taiwan, Province of China');
    countryList().setLabel('TJ', 'Tajikistan');
    countryList().setLabel('TZ', 'Tanzania, United Republic of');
    countryList().setLabel('TH', 'Thailand');
    countryList().setLabel('TL', 'Timor-Leste');
    countryList().setLabel('TG', 'Togo');
    countryList().setLabel('TK', 'Tokelau');
    countryList().setLabel('TO', 'Tonga');
    countryList().setLabel('TT', 'Trinidad and Tobago');
    countryList().setLabel('TN', 'Tunisia');
    countryList().setLabel('TR', 'Turkey');
    countryList().setLabel('TM', 'Turkmenistan');
    countryList().setLabel('TC', 'Turks and Caicos Islands');
    countryList().setLabel('TV', 'Tuvalu');
    countryList().setLabel('UG', 'Uganda');
    countryList().setLabel('UA', 'Ukraine');
    countryList().setLabel('AE', 'United Arab Emirates');
    countryList().setLabel('GB', 'United Kingdom');
    countryList().setLabel('US', 'United States');
    countryList().setLabel('UM', 'United States Minor Outlying Islands');
    countryList().setLabel('UY', 'Uruguay');
    countryList().setLabel('UZ', 'Uzbekistan');
    countryList().setLabel('VU', 'Vanuatu');
    countryList().setLabel('VE', 'Venezuela, Bolivarian Republic of');
    countryList().setLabel('VN', 'Vietnam');
    countryList().setLabel('VG', 'Virgin Islands, British');
    countryList().setLabel('VI', 'Virgin Islands, U.S.');
    countryList().setLabel('WF', 'Wallis and Futuna');
    countryList().setLabel('EH', 'Western Sahara');
    countryList().setLabel('YE', 'Yemen');
    countryList().setLabel('ZM', 'Zambia');
    countryList().setLabel('ZW', 'Zimbabwe');
    countryList().setLabel('XK', 'Kosovo');
}


export function getCountrySuggestions() {
    const languages = [
        {
            language: 'pt',
            label: <FormattedMessage id="ReferenceDataFunctions.PTSpeakingCountries" />,
            countries: [
                { value: 'PT', label: countryList().getLabel('PT') },
                { value: 'AO', label: countryList().getLabel('AO') },
                { value: 'BR', label: countryList().getLabel('BR') },
                { value: 'CV', label: countryList().getLabel('CV') },
                { value: 'GW', label: countryList().getLabel('GW') },
                { value: 'MZ', label: countryList().getLabel('MZ') },
                { value: 'ST', label: countryList().getLabel('ST') },
                { value: 'TL', label: countryList().getLabel('TL') }
            ]
        },
        {
            language: 'en',
            label: <FormattedMessage id="ReferenceDataFunctions.ENSpeakingCountries" />,
            countries: [
                { value: 'US', label: countryList().getLabel('US') },
                { value: 'GB', label: countryList().getLabel('GB') },
                { value: 'AU', label: countryList().getLabel('AU') },
                { value: 'MU', label: countryList().getLabel('MU') },
                { value: 'NZ', label: countryList().getLabel('NZ') },
                { value: 'AG', label: countryList().getLabel('AG') },
                { value: 'BS', label: countryList().getLabel('BS') },
                { value: 'BB', label: countryList().getLabel('BB') },
                { value: 'BZ', label: countryList().getLabel('BZ') },
                { value: 'CA', label: countryList().getLabel('CA') },
                { value: 'DM', label: countryList().getLabel('DM') },
                { value: 'GD', label: countryList().getLabel('GD') },
                { value: 'GY', label: countryList().getLabel('GY') },
                { value: 'IE', label: countryList().getLabel('IE') },
                { value: 'JM', label: countryList().getLabel('JM') },
                { value: 'KN', label: countryList().getLabel('KN') },
                { value: 'LC', label: countryList().getLabel('LC') },
                { value: 'VC', label: countryList().getLabel('VC') },
                { value: 'TT', label: countryList().getLabel('TT') }
            ]
        },
        {
            language: 'es',
            label: <FormattedMessage id="ReferenceDataFunctions.ESSpeakingCountries" />,
            countries: [
                { value: 'ES', label: countryList().getLabel('ES') },
                { value: 'AR', label: countryList().getLabel('AR') },
                { value: 'BO', label: countryList().getLabel('BO') },
                { value: 'CL', label: countryList().getLabel('CL') },
                { value: 'CO', label: countryList().getLabel('CO') },
                { value: 'CR', label: countryList().getLabel('CR') },
                { value: 'CU', label: countryList().getLabel('CU') },
                { value: 'EC', label: countryList().getLabel('EC') },
                { value: 'GT', label: countryList().getLabel('GT') },
                { value: 'HN', label: countryList().getLabel('HN') },
                { value: 'MX', label: countryList().getLabel('MX') },
                { value: 'NI', label: countryList().getLabel('NI') },
                { value: 'PA', label: countryList().getLabel('PA') },
                { value: 'PY', label: countryList().getLabel('PY') },
                { value: 'PE', label: countryList().getLabel('PE') },
                { value: 'PR', label: countryList().getLabel('PY') },
                { value: 'DO', label: countryList().getLabel('DO') },
                { value: 'SV', label: countryList().getLabel('SV') },
                { value: 'UY', label: countryList().getLabel('UY') },
                { value: 'VE', label: countryList().getLabel('VE') }
            ]
        },
        {
            language: 'fr',
            label: <FormattedMessage id="ReferenceDataFunctions.FRSpeakingCountries" />,
            countries: [
                { value: 'FR', label: countryList().getLabel('FR') },
                { value: 'BJ', label: countryList().getLabel('BJ') },
                { value: 'BF', label: countryList().getLabel('BF') },
                { value: 'CD', label: countryList().getLabel('CD') },
                { value: 'CG', label: countryList().getLabel('CG') },
                { value: 'CI', label: countryList().getLabel('CI') },
                { value: 'GA', label: countryList().getLabel('GA') },
                { value: 'ML', label: countryList().getLabel('ML') },
                { value: 'SN', label: countryList().getLabel('SN') },
                { value: 'TG', label: countryList().getLabel('TG') },
                { value: 'MC', label: countryList().getLabel('MC') },
                { value: 'NE', label: countryList().getLabel('NE') }
            ]
        },
        {
            language: 'de',
            label: <FormattedMessage id="ReferenceDataFunctions.DESpeakingCountries" />,
            countries: [
                { value: 'DE', label: countryList().getLabel('DE') },
                { value: 'AT', label: countryList().getLabel('AT') },
                { value: 'LI', label: countryList().getLabel('LI') },
                { value: 'LU', label: countryList().getLabel('LU') },
                { value: 'CH', label: countryList().getLabel('CH') },
            ]
        }
    ]

    return languages;
};

//#endregion


//Profile data quality metrics
export function getProfileDataQualityMetrics() {
    const metrics = [
        { value: 'Email', label: 'Email', icon: 'fas fa-at' },
        { value: 'Phone', label: <FormattedMessage id="ReferenceDataFunctions.Phone" />, icon: 'fas fa-phone' },
        { value: 'Mobile', label: <FormattedMessage id="ReferenceDataFunctions.Mobile" />, icon: 'icon-icon-sms' },
        { value: 'Nationality', label: <FormattedMessage id="ReferenceDataFunctions.Nationality" />, icon: 'fas fa-flag' },
        { value: 'LoyaltyCard', label: <FormattedMessage id="ReferenceDataFunctions.LoyaltyCard" />, icon: 'icon-icon-member-card' },
        { value: 'Passport', label: <FormattedMessage id="ReferenceDataFunctions.Passport" />, icon: 'fas fa-passport' },
        { value: 'IdCard', label: <FormattedMessage id="ReferenceDataFunctions.IdCard" />, icon: 'fas fa-id-card-alt' },
        { value: 'FiscalNumber', label: <FormattedMessage id="ReferenceDataFunctions.FiscalNumber" />, icon: 'fas fa-id-card' },
        { value: 'OTAEmail', label: <FormattedMessage id="ReferenceDataFunctions.OtaEmail" />, icon: 'fas fa-at' },
        { value: 'Consent', label: <FormattedMessage id="ReferenceDataFunctions.Consent" />, icon: 'icon-icon-consents' }
    ]

    return metrics;
};

export function getToMasterProps() {
    const props = [
        { value: 'Email2', label: <FormattedMessage id="ReferenceDataFunctions.Email2" /> },
        { value: 'Phone2', label: <FormattedMessage id="ReferenceDataFunctions.Phone2" /> },
        { value: 'Mobile2', label: <FormattedMessage id="ReferenceDataFunctions.Mobile2" /> },
        { value: 'Credit', label: <FormattedMessage id="ReferenceDataFunctions.Credit" /> },
        { value: 'Marketing', label: <FormattedMessage id="ReferenceDataFunctions.SalesMarketing" /> },
        { value: 'Warning', label: <FormattedMessage id="ReferenceDataFunctions.Warning" /> },
        { value: 'Loyalty_Points', label: <FormattedMessage id="ReferenceDataFunctions.Loyalty_Points" /> },
        { value: 'CustomData', label: <FormattedMessage id="ReferenceDataFunctions.CustomData" /> }
    ]

    return props;
};


//#region Data Rule Actions
export function getTransformationActionType() {
    const props = [
        { value: 'Split', label: <FormattedMessage id="ReferenceDataFunctions.Split" /> },
        { value: 'Transformation', label: <FormattedMessage id="ReferenceDataFunctions.Transformation" /> }
    ]

    return props;
}

export function getRemoveSource() {
    const props = [
        { value: true, label: <FormattedMessage id="ReferenceDataFunctions.RemoveSourceField" /> },
        { value: false, label: <FormattedMessage id="ReferenceDataFunctions.KeepSourceField" /> }
    ]

    return props;
}

export function getOverlapActionType() {
    const props = [
        { value: 'Ignore', label: <FormattedMessage id="ReferenceDataFunctions.Ignore" /> },
        { value: 'Delete', label: <FormattedMessage id="ReferenceDataFunctions.Delete" /> }
    ]

    return props;
}
//#endregion


export function getResolvedStatus(intl) {
    const resolvedSatusOptions = [
        {
            value: 'Resolved',
            label: intl.formatMessage({ id: "TaskResultDetail.Resolved" }),
            badge: <Badge color="success" className="py-1 px-2"> <FormattedMessage id="TaskResultDetail.Resolved" /></Badge>,
            color: "success",
            bool: true
        },
        {
            value: 'NotResolved',
            label: intl.formatMessage({ id: "TaskResultDetail.NotResolved" }),
            badge: <Badge color="danger" className="py-1 px-2"><FormattedMessage id="TaskResultDetail.NotResolved" /> </Badge>,
            color: "danger",
            bool: false
        },
        {
            value: 'Ignored',
            label: intl.formatMessage({ id: "TaskResultDetail.Ignored" }),
            badge: <Badge color="secondary" className="py-1 px-2"><FormattedMessage id="TaskResultDetail.Ignored" /> </Badge>,
            color: "secondary",
            bool: null
        }
    ];
    return resolvedSatusOptions;
}


export function getWeekDays(intl) {
    return [
        intl.formatMessage({ id: "DataQualityTemplateDetail.Monday" }),
        intl.formatMessage({ id: "DataQualityTemplateDetail.Tuesday" }),
        intl.formatMessage({ id: "DataQualityTemplateDetail.Wednesday" }),
        intl.formatMessage({ id: "DataQualityTemplateDetail.Thursday" }),
        intl.formatMessage({ id: "DataQualityTemplateDetail.Friday" }),
        intl.formatMessage({ id: "DataQualityTemplateDetail.Saturday" }),
        intl.formatMessage({ id: "DataQualityTemplateDetail.Sunday" })
    ];
}

//#region Data Quality Report

export function getDailyReportElements() {
    return [
        { value: 'Names', label: <FormattedMessage id="ReferenceDataFunctions.Names" />, description: <FormattedMessage id="ReferenceDataFunctions.NamesDesc" />  },
        { value: 'Email', label: <FormattedMessage id="ReferenceDataFunctions.Email" />, description: <FormattedMessage id="ReferenceDataFunctions.EmailDesc" /> },
        { value: 'Phone', label: <FormattedMessage id="ReferenceDataFunctions.Phone" />, description: <FormattedMessage id="ReferenceDataFunctions.PhoneDesc" /> },
        { value: 'Mobile', label: <FormattedMessage id="ReferenceDataFunctions.Mobile" />, description: <FormattedMessage id="ReferenceDataFunctions.MobileDesc" /> },
        { value: 'OtaEmails', label: <FormattedMessage id="ReferenceDataFunctions.OtaEmails" />, description: <FormattedMessage id="ReferenceDataFunctions.OtaEmailsDesc" /> },
        { value: 'Consents', label: <FormattedMessage id="ReferenceDataFunctions.Consents" />, description: <FormattedMessage id="ReferenceDataFunctions.ConsentsDesc" /> },
        { value: 'ProfileType', label: <FormattedMessage id="ReferenceDataFunctions.ProfileType" />, description: <FormattedMessage id="ReferenceDataFunctions.ProfileTypeDesc" />  },
        { value: 'DuplicateEmails', label: <FormattedMessage id="ReferenceDataFunctions.DuplicateEmails" />, description: <FormattedMessage id="ReferenceDataFunctions.DuplicateEmailsDesc" />  },
        { value: 'DuplicateLoyaltyCards', label: <FormattedMessage id="ReferenceDataFunctions.DuplicateLoyaltyCards" />, description: <FormattedMessage id="ReferenceDataFunctions.DuplicateLoyaltyCardsDesc" />  },
        { value: 'DuplicateFiscalNumbers', label: <FormattedMessage id="ReferenceDataFunctions.DuplicateFiscalNumbers" />, description: <FormattedMessage id="ReferenceDataFunctions.DuplicateFiscalNumbersDesc" /> },
        { value: 'CheckInOnly', label: <FormattedMessage id="ReferenceDataFunctions.CheckInOnly" />, description: <FormattedMessage id="ReferenceDataFunctions.CheckInOnlyDesc" /> }
    ];
}

export function getDataQualityReportType() {
    return [
        { value: 'InhouseReport', label: <FormattedMessage id="DailyReport.InhouseReport" />, templateType: 'InHouseGuest', icon: "fas fa-clipboard-check" },
        { value: 'MonthlyOverview', label: <FormattedMessage id="DailyReport.MonthlyOverview" />, templateType: 'MonthlyOverview', icon: "fas fa-calendar-day" },
        { value: 'TaskResults', label: <FormattedMessage id="DailyReport.TaskResults" />, templateType: 'TaskResults', icon: "fas fa-clipboard-list" },
    ];
}

//#endregion

//#region color
export function getColorPallete() {
    return [
        '#0867fa',
        '#59B2C2',
        '#48d7ea',
        '#8B75EA',
        '#CF53FA',
        '#FA339A',
        "#F16D84",
        '#FFBE2D',
        '#FF8A00',
        '#62a140',
        '#66E27F',
        '#8EE3C8',
        '#D7EAFF',
        '#405FC1'
    ];
}

export function getNextColor(index) {
    return getColorPallete()[index % getColorPallete().length];
}
//#endregion


export function getLanguagesCodes() {
    return [
        "af_NA",
        "af_ZA",
        "ak_GH",
        "am_ET",
        "ar_AE",
        "ar_BH",
        "ar_DJ",
        "ar_DZ",
        "ar_EG",
        "ar_EH",
        "ar_ER",
        "ar_IL",
        "ar_IQ",
        "ar_JO",
        "ar_KM",
        "ar_KW",
        "ar_LB",
        "ar_LY",
        "ar_MA",
        "ar_MR",
        "ar_OM",
        "ar_PS",
        "ar_QA",
        "ar_SA",
        "ar_SD",
        "ar_SO",
        "ar_SS",
        "ar_SY",
        "ar_TD",
        "ar_TN",
        "ar_YE",
        "as_IN",
        "be_BY",
        "bg",
        "bm_ML",
        "bn_BD",
        "bn_IN",
        "bo_CN",
        "bo_IN",
        "br_FR",
        "bs_La",
        "ca_AD",
        "ca_ES",
        "ca_FR",
        "ca_IT",
        "ce_RU",
        "cs",
        "cu_RU",
        "cy_GB",
        "da_DK",
        "da_GL",
        "de_AT",
        "de_BE",
        "de_CH",
        "de",
        "de_IT",
        "de_LI",
        "de_LU",
        "dz_BT",
        "ee_GH",
        "ee_TG",
        "el_CY",
        "el_GR",
        "en_AE",
        "en_AG",
        "en_AI",
        "en_AS",
        "en_AT",
        "en_AU",
        "en_BB",
        "en_BE",
        "en_BI",
        "en_BM",
        "en_BS",
        "en_BW",
        "en_BZ",
        "en_CA",
        "en_CC",
        "en_CH",
        "en_CK",
        "en_CM",
        "en_CX",
        "en_CY",
        "en_DE",
        "en_DG",
        "en_DK",
        "en_DM",
        "en_ER",
        "en_FI",
        "en_FJ",
        "en_FK",
        "en_FM",
        "en_GB",
        "en_GD",
        "en_GG",
        "en_GH",
        "en_GI",
        "en_GM",
        "en_GU",
        "en_GY",
        "en_HK",
        "en_IE",
        "en_IL",
        "en_IM",
        "en_IN",
        "en_IO",
        "en_JE",
        "en_JM",
        "en_KE",
        "en_KI",
        "en_KN",
        "en_KY",
        "en_LC",
        "en_LR",
        "en_LS",
        "en_MG",
        "en_MH",
        "en_MO",
        "en_MP",
        "en_MS",
        "en_MT",
        "en_MU",
        "en_MW",
        "en_MY",
        "en_NA",
        "en_NF",
        "en_NG",
        "en_NL",
        "en_NR",
        "en_NU",
        "en_NZ",
        "en_PG",
        "en_PH",
        "en_PK",
        "en_PN",
        "en_PR",
        "en_PW",
        "en_RW",
        "en_SB",
        "en_SC",
        "en_SD",
        "en_SE",
        "en_SG",
        "en_SH",
        "en_SI",
        "en_SL",
        "en_SS",
        "en_SX",
        "en_SZ",
        "en_TC",
        "en_TK",
        "en_TO",
        "en_TT",
        "en_TV",
        "en_TZ",
        "en_UG",
        "en_UM",
        "en_US",
        "en_VC",
        "en_VG",
        "en_VI",
        "en_VU",
        "en_WS",
        "en_ZA",
        "en_ZM",
        "en_ZW",
        "es_AR",
        "es_BO",
        "es_BR",
        "es_BZ",
        "es_CL",
        "es_CO",
        "es_CR",
        "es_CU",
        "es_DO",
        "es_EA",
        "es_EC",
        "es",
        "es_GQ",
        "es_GT",
        "es_HN",
        "es_IC",
        "es_MX",
        "es_NI",
        "es_PA",
        "es_PE",
        "es_PH",
        "es_PR",
        "es_PY",
        "es_SV",
        "es_US",
        "es_UY",
        "es_VE",
        "et_EE",
        "eu_ES",
        "fa_AF",
        "fa_IR",
        "fi_FI",
        "fo_DK",
        "fo",
        "fr",
        "fy_NL",
        "ga_GB",
        "ga_IE",
        "gd_GB",
        "gl_ES",
        "gu_IN",
        "gv_IM",
        "ha_GH",
        "ha_NE",
        "ha_NG",
        "he_IL",
        "hi_IN",
        "hr_BA",
        "hr_HR",
        "hu_HU",
        "hy_AM",
        "id_ID",
        "ig_NG",
        "ii_CN",
        "is_IS",
        "it_CH",
        "it",
        "it_SM",
        "it_VA",
        "ja_JP",
        "jv_ID",
        "ka_GE",
        "ki_KE",
        "kk_KZ",
        "kl_GL",
        "km_KH",
        "kn_IN",
        "ko_KP",
        "ko_KR",
        "ku_TR",
        "kw_GB",
        "ky_KG",
        "lb_LU",
        "lg_UG",
        "ln_AO",
        "ln_CD",
        "ln_CF",
        "ln_CG",
        "lo_LA",
        "lt",
        "lu_CD",
        "lv",
        "mg_MG",
        "mi_NZ",
        "mk_MK",
        "ml_IN",
        "mn",
        "mr_IN",
        "ms_BN",
        "ms_ID",
        "ms_MY",
        "ms_SG",
        "mt_MT",
        "my_MM",
        "nb_NO",
        "nb_SJ",
        "nd_ZW",
        "ne_IN",
        "ne_NP",
        "nl_AW",
        "nl_BE",
        "nl_BQ",
        "nl_CW",
        "nl",
        "nl_SR",
        "nl_SX",
        "nn_NO",
        "om_ET",
        "om_KE",
        "or_IN",
        "os_GE",
        "os_RU",
        "pl",
        "ps_AF",
        "ps_PK",
        "pt_AO",
        "pt_BR",
        "pt_CH",
        "pt_CV",
        "pt_GQ",
        "pt_GW",
        "pt_LU",
        "pt_MO",
        "pt_MZ",
        "pt_PT",
        "pt_ST",
        "pt_TL",
        "qu_BO",
        "qu_EC",
        "qu_PE",
        "rm_CH",
        "rn_BI",
        "ro_MD",
        "ro_RO",
        "ru_BY",
        "ru_KG",
        "ru_KZ",
        "ru_MD",
        "ru_RU",
        "ru_UA",
        "rw_RW",
        "se_FI",
        "se_NO",
        "se_SE",
        "sg_CF",
        "si_LK",
        "sk_SK",
        "sl_SI",
        "sn_ZW",
        "so_DJ",
        "so_ET",
        "so_KE",
        "so_SO",
        "sq_AL",
        "sq_MK",
        "sq_XK",
        "sv_AX",
        "sv_FI",
        "sv_SE",
        "sw_CD",
        "sw_KE",
        "sw_TZ",
        "sw_UG",
        "ta_IN",
        "ta_LK",
        "ta_MY",
        "ta_SG",
        "te_IN",
        "tg_TJ",
        "th_TH",
        "ti_ER",
        "ti_ET",
        "tk_TM",
        "to_TO",
        "tr_CY",
        "tr_TR",
        "tt_RU",
        "ug_CN",
        "uk_UA",
        "ur_IN",
        "ur_PK",
        "vi_VN",
        "wo_SN",
        "xh_ZA",
        "yo_BJ",
        "yo_NG",
        "zu_ZA"
    ];
}


export function getMediaTypes(intl) {
    return [
        { value: 'Sticker', label: intl.formatMessage({ id: "MediaType.Sticker" }) },
        { value: 'Audio', label: intl.formatMessage({ id: "MediaType.Audio" }) },
        { value: 'Image', label: intl.formatMessage({ id: "MediaType.Image" }) },
        { value: 'Document', label: intl.formatMessage({ id: "MediaType.Document" }) },
        { value: 'Video', label: intl.formatMessage({ id: "MediaType.Video" }) },
    ];
}


export function getEmojis() {
    return [
        '😊', '😀', '😄', '😁', '😆', '🙂', '😉', '🥰', '🤩', '😘',
        '❤', '👋', '🤚', '🖐', '👌', '👍', '👎', '👏', '🤝', '✍'
    ];
}

export function GoogleMediaTypes(intl) {
    return [ 
        {
            value: 'CATEGORY_UNSPECIFIED',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.CATEGORY_UNSPECIFIED" })
        },
        {
            value: 'COVER',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.COVER" })
        },
        {
            value: 'PROFILE',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.PROFILE" })
        },
        {
            value: 'LOGO',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.LOGO" })
        },
        {
            value: 'EXTERIOR',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.EXTERIOR" })
        },
        {
            value: 'INTERIOR',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.INTERIOR" })
        },
        {
            value: 'PRODUCT',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.PRODUCT" })
        },
        {
            value: 'AT_WORK',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.AT_WORK" })
        },
        {
            value: 'FOOD_AND_DRINK',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.FOOD_AND_DRINK" })
        },
        {
            value: 'MENU',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.MENU" })
        },
        {
            value: 'COMMON_AREA',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.COMMON_AREA" })
        },
        {
            value: 'ROOMS',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.CATEGORY_UROOMSNSPECIFIED" })
        },
        {
            value: 'TEAMS',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.TEAMS" })
        },
        {
            value: 'ADDITIONAL',
            label: intl.formatMessage({ id: "GoogleBusinessConfig.ADDITIONAL" })
        }
    ]
}


export function getISSOptions(intl) {
    return [
        {
            value: 0,
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Normal" }) 
        },
        {
            value: 1,
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Exempt" }) 
        },
        {
            value: 2,
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Withheld" }) 
        }
    ]
}

export function googleTextTreatment(text) {
    if(!text) return text;
    let result = text.replace(/(\r\n|\n|\r)/gm, "");
    const translated = text.indexOf("(Translated by Google)");
    const original = text.indexOf("(Original)");

    if(!translated ===  -1) return result
    if(translated > -1 && original > -1){
        result = text.substring(original + "(Original)".length, text.length - 1);
    }
    else{
        result = text.substring(0, translated);
    }

    return result
}

export function getContextPrompts(contextType, extra) {

    const genericContextPrompts = [
        {
            type: 'wordsLimit',
            text: `In less than ${extra} words.`
        },
        {
            type: 'review',
            text: 'Answer to this google maps review of an hotel in a nice way, like you are a public relations director from that hotel. Answer in the review language. If the review is negative offer condolences and say that you are working on solving the issue. In the end add greetings as the hotel staff. Answer like the other answers to reviews of the hotel.'
        },
        {
            type: 'reviewerName',
            text: ` The reviewer name is "${extra}".`
        },
        {
            type: 'reviewScore',
            text: ` The review score was ${extra}/5.`
        },
        {
            type: 'reviewText',
            text: ` The review is: '${extra}'.`
        },
        {
            type: 'question',
            text: `Answer to this google question of an hotel in a nice way, to the best of your knowledge like you are a public relations director from that hotel. Answer in the question language. In the end add greetings as the hotel staff. Answer like the other answers to questions of the hotel. The Question: "${extra}"`
        },
    ]
    return genericContextPrompts.find(({type}) => type === contextType)?.text;
}


export function getGenderOptions(intl) {
    return [
        {
            value: "F",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Female" })
        },
        {
            value: "M",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Male" })
        },
        {
            value: "O",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Other" })
        }
    ]
}


//#region MachineLearning

export function getMLModelType(intl) {
    return [
        {
            value: "CancelationProbability",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.CancelationProbability" })
        }
    ]
}

//#endregion


//#region Form
export function getFormTypes(intl, isInquiry) {
    const formTypes = [];

    if(!isInquiry){
        if (global.modules.some(m => m === 'GoogleBusiness')) {
            formTypes.push({
                value: 'GoogleBusiness',
                label: intl.formatMessage({ id: "ReferenceDataFunctions.GoogleBusiness" })
            });
        }
    }
    else{
        if (global.modules.some(m => m === 'Typeform')) {
            formTypes.push({
                value: 'Typeform',
                label: 'Typeform',
                getFormsUrl: `/api/gms/TypeForm/Forms?page=1&pageSize=200`,
                getSingleFormUrl: `/api/gms/TypeForm/FormQuestions`,
            });
        }
        if (global.modules.some(m => m === 'ProfileNowForms')) {
            formTypes.push({
                value: 'ProfileNowForms',
                label: 'Profile Now',
                getFormsUrl: `/api/gms/Survey/v1/survey?hotelGroup=${global.hotelGroupId}&all=true`,
                getSingleFormUrl: `/api/gms/Survey/v1/survey`,
            });
        }
        if (global.modules.some(m => m === 'ReviewPro')) {
            formTypes.push({
                value: 'ReviewPro',
                label: 'ReviewPro',
                getFormsUrl: `/api/gms/ReviewPro/v1/Surveys`,
                getSingleFormUrl: `/api/gms/ReviewPro/v1/SurveyQuestions`,
            });
        }
    }

    return formTypes
}

export function getFormSource() { //OriginType
    return [
        {
            value: "Egoi",
            label: "E-goi",
            logo: egoi
        },
        //{
        //    value: "Roiback",
        //    label: "Roiback",
        //    logo: roiback
        //},
        {
            value: "ReviewPro",
            label: "Review Pro",
            logo: reviewPro
        },
        {
            value: "PNForm",
            label: "Profile Now",
            logo: profileNow
        },
        {
            value: "TypeForm",
            label: "Typeform",
            logo: typeform
        },
    ]
}
//#endregion

//#region Review Summary Config

export function getChannelOptions(intl) {
    return [
        {
            value: "Reviews",
            label: "Reviews"
        },
        {
            value: "Inquiry",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Inquiry" })
        }
    ]
}

export function getReportTypeOptions(intl) {
    return [
        {
            value: "Report",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Report" })
        },
        {
            value: "MetaData",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.MetaData" })
        }
    ]
}

export function getSummaryTopics(intl) {
    return [
        {
            value: "Sentiment",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Sentiment" })
        },
        {
            value: "ImprovActions",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.ImprovActions" })
        },
        {
            value: "Services",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.Services" })
        },
        {
            value: "ReviewSummary",
            label: intl.formatMessage({ id: "ReferenceDataFunctions.ReviewSummary" })
        }
    ]
}

//#endregion


//#region openAI

export function getOpenAICallTypes(intl) {
    return [
        { value: "ReviewAnalisys", label: intl.formatMessage({ id: "AIConfig.ReviewAnalisys" }) },
        { value: "ReviewSummary", label: intl.formatMessage({ id: "AIConfig.ReviewSummary" }) },
        { value: "ReviewResponse", label: intl.formatMessage({ id: "AIConfig.ReviewResponse" }) },
        { value: "Marketing", label: intl.formatMessage({ id: "AIConfig.Marketing" }) },
        { value: "QA", label: intl.formatMessage({ id: "AIConfig.QA" }) }
    ];
}

//#endregion


//#region Custom Fields
export function getCustomFieldsTypes(intl) {
    return [
        { value: "Bool", label: intl.formatMessage({ id: "CustomFields.Bool" }) },
        { value: "Date", label: intl.formatMessage({ id: "CustomFields.Date" }) },
        { value: "Decimal", label: intl.formatMessage({ id: "CustomFields.Decimal" }) },
        { value: "Int", label: intl.formatMessage({ id: "CustomFields.Int" }) },
        { value: "String", label: intl.formatMessage({ id: "CustomFields.String" }) }
    ];
}
//#endregion


export function deepClone(data) {
    return JSON.parse(JSON.stringify(data));
}


export function getNrNights(checkIn, checkOut) {
    if (checkIn && checkIn) {
        const ci = moment(checkIn).format('YYYY-MM-DD');
        const co = moment(checkOut).format('YYYY-MM-DD');

        return moment(co).diff(moment(ci), 'days')
    }
}

export function getDatesRangeArray(formatDate = "YYYY-MM-DD", type = 'days', startDate, endDate) {
    
    return Array(moment(endDate).diff(moment(startDate), type)).fill(undefined)
        .reduce((acc, cur) => {
            acc.push(moment(startDate).add(acc.length, type).format(formatDate));

            return acc;

        }, [moment(startDate).format(formatDate)]);
}

export function getComboLanguages() {
    const languages = [
        { value: 'af-ZA', label: 'Afrikaans (South Africa)' },
        { value: 'ar-AE', label: 'Arabic (U.A.E.)' },
        { value: 'ar-BH', label: 'Arabic (Bahrain)' },
        { value: 'ar-DZ', label: 'Arabic (Algeria)' },
        { value: 'ar-EG', label: 'Arabic (Egypt)' },
        { value: 'ar-IQ', label: 'Arabic (Iraq)' },
        { value: 'ar-JO', label: 'Arabic (Jordan)' },
        { value: 'ar-KW', label: 'Arabic (Kuwait)' },
        { value: 'ar-LB', label: 'Arabic (Lebanon)' },
        { value: 'ar-LY', label: 'Arabic (Libya)' },
        { value: 'ar-MA', label: 'Arabic (Morocco)' },
        { value: 'ar-OM', label: 'Arabic (Oman)' },
        { value: 'ar-QA', label: 'Arabic (Qatar)' },
        { value: 'ar-SA', label: 'Arabic (Saudi Arabia)' },
        { value: 'ar-SY', label: 'Arabic (Syria)' },
        { value: 'ar-TN', label: 'Arabic (Tunisia)' },
        { value: 'ar-YE', label: 'Arabic (Yemen)' },
        { value: 'az-AZ', label: 'Azeri (Azerbaijan)' },
        { value: 'be-BY', label: 'Belarusian (Belarus)' },
        { value: 'bg-BG', label: 'Bulgarian (Bulgaria)' },
        { value: 'bs-BA', label: 'Bosnian (Bosnia and Herzegovina)' },
        { value: 'ca-ES', label: 'Catalan (Spain)' },
        { value: 'cs-CZ', label: 'Czech (Czech Republic)' },
        { value: 'cy-GB', label: 'Welsh (United Kingdom)' },
        { value: 'da-DK', label: 'Danish (Denmark)' },
        { value: 'de-AT', label: 'German (Austria)' },
        { value: 'de-CH', label: 'German (Switzerland)' },
        { value: 'de-DE', label: '	German (Germany)' },
        { value: 'de-LI', label: 'German (Liechtenstein)' },
        { value: 'de-LU', label: 'German (Luxembourg)' },
        { value: 'dv-MV', label: 'Divehi (Maldives)' },
        { value: 'el-GR', label: 'Greek (Greece)' },
        { value: 'en-AU', label: 'English (Australia)' },
        { value: 'en-BZ', label: 'English (Belize)' },
        { value: 'en-CA', label: 'English (Canada)' },
        { value: 'en-CB', label: 'English (Caribbean)' },
        { value: 'en-GB', label: 'English (United Kingdom)' },
        { value: 'en-IE', label: 'English (Ireland)' },
        { value: 'en-JM', label: 'English (Jamaica)' },
        { value: 'en-NZ', label: 'English (New Zealand)' },
        { value: 'en-PH', label: 'English (Republic of the Philippines)' },
        { value: 'en-TT', label: 'English (Trinidad and Tobago)' },
        { value: 'en-US', label: 'English (United States)' },
        { value: 'en-ZA', label: 'English (South Africa)' },
        { value: 'en-ZW', label: 'English (Zimbabwe)' },
        { value: 'es-AR', label: 'Spanish (Argentina)' },
        { value: 'es-BO', label: 'Spanish (Bolivia)' },
        { value: 'es-CL', label: 'Spanish (Chile)' },
        { value: 'es-CO', label: 'Spanish (Colombia)' },
        { value: 'es-CR', label: 'Spanish (Costa Rica)' },
        { value: 'es-DO', label: 'Spanish (Dominican Republic)' },
        { value: 'es-EC', label: 'Spanish (Ecuador)' },
        { value: 'es-ES', label: 'Spanish (Spain)' },
        { value: 'es-GT', label: 'Spanish (Guatemala)' },
        { value: 'es-HN', label: 'Spanish (Honduras)' },
        { value: 'es-MX', label: 'Spanish (Mexico)' },
        { value: 'es-NI', label: 'Spanish (Nicaragua)' },
        { value: 'es-PA', label: 'Spanish (Panama)' },
        { value: 'es-PE', label: 'Spanish (Peru)' },
        { value: 'es-PR', label: 'Spanish (Puerto Rico)' },
        { value: 'es-PY', label: 'Spanish (Paraguay)' },
        { value: 'es-SV', label: 'Spanish (El Salvador)' },
        { value: 'es-UY', label: 'Spanish (Uruguay)' },
        { value: 'es-VE', label: 'Spanish (Venezuela)' },
        { value: 'et-EE', label: 'Estonian (Estonia)' },
        { value: 'eu-ES', label: 'Basque (Spain)' },
        { value: 'fa-IR', label: 'Farsi (Iran)' },
        { value: 'fi-FI', label: 'Finnish (Finland)' },
        { value: 'fo-FO', label: 'Faroese (Faroe Islands)' },
        { value: 'fr-BE', label: 'French (Belgium)' },
        { value: 'fr-CA', label: 'French (Canada)' },
        { value: 'fr-CH', label: 'French (Switzerland)' },
        { value: 'fr-FR', label: 'French (France)' },
        { value: 'fr-LU', label: 'French (Luxembourg)' },
        { value: 'fr-MC', label: 'French (Principality of Monaco)' },
        { value: 'gl-ES', label: 'Galician (Spain)' },
        { value: 'gu-IN', label: 'Gujarati (India)' },
        { value: 'he-IL', label: 'Hebrew (Israel)' },
        { value: 'hi-IN', label: 'Hindi (India)' },
        { value: 'hr-BA', label: 'Croatian (Bosnia and Herzegovina)' },
        { value: 'hr-HR', label: 'Croatian (Croatia)' },
        { value: 'hu-HU', label: 'Hungarian (Hungary)' },
        { value: 'hy-AM', label: 'Armenian (Armenia)' },
        { value: 'id-ID', label: 'Indonesian (Indonesia)' },
        { value: 'is-IS', label: 'Icelandic (Iceland)' },
        { value: 'it-CH', label: 'Italian (Switzerland)' },
        { value: 'it-IT', label: 'Italian (Italy)' },
        { value: 'ja-JP', label: 'Japanese (Japan)' },
        { value: 'ka-GE', label: 'Georgian (Georgia)' },
        { value: 'kk-KZ', label: 'Kazakh (Kazakhstan)' },
        { value: 'kn-IN', label: 'Kannada (India)' },
        { value: 'ko-KR', label: 'Korean (Korea)' },
        { value: 'kok-IN', label: 'Konkani (India)' },
        { value: 'ky-KG', label: 'Kyrgyz (Kyrgyzstan)' },
        { value: 'lt-LT', label: 'Lithuanian (Lithuania)' },
        { value: 'lv-LV', label: 'Latvian (Latvia)' },
        { value: 'mi-NZ', label: 'Maori (New Zealand)' },
        { value: 'mk-MK', label: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)' },
        { value: 'mn-MN', label: 'Mongolian (Mongolia)' },
        { value: 'mr-IN', label: 'Marathi (India)' },
        { value: 'ms-BN', label: 'Malay (Brunei Darussalam)' },
        { value: 'ms-MY', label: 'Malay (Malaysia)' },
        { value: 'mt-MT', label: 'Maltese (Malta)' },
        { value: 'nb-NO', label: 'Norwegian (bokmål) (Norway)' },
        { value: 'nl-BE', label: 'Dutch (Belgium)' },
        { value: 'nl-NL', label: 'Dutch (Netherlands)' },
        { value: 'nn-NO', label: 'Norwegian (Nynorsk) (Norway)' },
        { value: 'ns-ZA', label: 'Northern Sotho (South Africa)' },
        { value: 'pa-IN', label: 'Punjabi (India)' },
        { value: 'pl-PL', label: 'Polish (Poland)' },
        { value: 'ps-AR', label: 'Pashto (Afghanistan)' },
        { value: 'pt-BR', label: 'Portuguese (Brazil)' },
        { value: 'pt-PT', label: 'Portuguese (Portugal)' },
        { value: 'qu-BO', label: 'Quechua (Bolivia)' },
        { value: 'qu-EC', label: 'Quechua (Ecuador)' },
        { value: 'qu-PE', label: 'Quechua (Peru)' },
        { value: 'ro-RO', label: 'Romanian (Romania)' },
        { value: 'ru-RU', label: 'Russian (Russia)' },
        { value: 'sa-IN', label: 'Sanskrit (India)' },
        { value: 'se-FI', label: 'Sami (Finland)' },
        { value: 'se-NO', label: 'Sami (Norway)' },
        { value: 'se-SE', label: 'Sami (Sweden)' },
        { value: 'sk-SK', label: 'Slovak (Slovakia)' },
        { value: 'sl-SI', label: 'Slovenian (Slovenia)' },
        { value: 'sq-AL', label: 'Albanian (Albania)' },
        { value: 'sr-BA', label: 'Serbian (Bosnia and Herzegovina)' },
        { value: 'sr-SP', label: 'Serbian (Serbia and Montenegro)' },
        { value: 'sv-FI', label: 'Swedish (Finland)' },
        { value: 'sv-SE', label: 'Swedish (Sweden)' },
        { value: 'sw-KE', label: 'Swahili (Kenya)' },
        { value: 'syr-SY', label: 'Syriac (Syria)' },
        { value: 'ta-IN', label: 'Tamil (India)' },
        { value: 'te-IN', label: 'Telugu (India)' },
        { value: 'th-TH', label: 'Thai (Thailand)' },
        { value: 'tl-PH', label: 'Tagalog (Philippines)' },
        { value: 'tn-ZA', label: 'Tswana (South Africa)' },
        { value: 'tr-TR', label: 'Turkish (Turkey)' },
        { value: 'tt-RU', label: 'Tatar (Russia)' },
        { value: 'uk-UA', label: 'Ukrainian (Ukraine)' },
        { value: 'ur-PK', label: 'Urdu (Islamic Republic of Pakistan)' },
        { value: 'uz-UZ', label: 'Uzbek (Uzbekistan)' },
        { value: 'vi-VN', label: 'Vietnamese (Viet Nam)' },
        { value: 'xh-ZA', label: 'Xhosa (South Africa)' },
        { value: 'zh-CN', label: 'Chinese (S)' },
        { value: 'zh-HK', label: 'Chinese (Hong Kong)' },
        { value: 'zh-MO', label: 'Chinese (Macau)' },
        { value: 'zh-SG', label: 'Chinese (Singapore)' },
        { value: 'zh-TW', label: 'Chinese (T)' },
        { value: 'zu-ZA', label: 'Zulu (South Africa)' }
    ]

    return languages;
}


//#region Process Issues
export function getProcessIssuesTypes(intl) {
    return [
        { value: "Report", label: intl.formatMessage({ id: "ProcessIssues.Report" }) },
        { value: "ProfileCard", label: intl.formatMessage({ id: "ProcessIssues.ProfileCard" }) },
        { value: "LoyaltyCalc", label: intl.formatMessage({ id: "ProcessIssues.LoyaltyCalc" }) },
        { value: "TargetCount", label: intl.formatMessage({ id: "ProcessIssues.TargetCount" }) },
        { value: "Alert", label: intl.formatMessage({ id: "ProcessIssues.Alert" }) },
        { value: "Merge", label: intl.formatMessage({ id: "ProcessIssues.Merge" }) }
    ];
}

export function getProcessIssuesEntityTypes(intl) {
    return [
        { value: "LoyaltyCardIssue", label: intl.formatMessage({ id: "ProcessIssues.LoyaltyCardIssue" }) },
        { value: "FormReply", label: intl.formatMessage({ id: "ProcessIssues.FormReply" }) },
        { value: "Monthly", label: intl.formatMessage({ id: "ProcessIssues.Monthly" }) },
        { value: "Daily", label: intl.formatMessage({ id: "ProcessIssues.Daily" }) },
        { value: "CampaignTarget", label: intl.formatMessage({ id: "ProcessIssues.CampaignTarget" }) },
        { value: "LoyaltyConfig", label: intl.formatMessage({ id: "ProcessIssues.LoyaltyConfig" }) },
        { value: "LoyaltyCardProfile", label: intl.formatMessage({ id: "ProcessIssues.LoyaltyCardProfile" }) },
        { value: "Message", label: intl.formatMessage({ id: "ProcessIssues.Message" }) },
        { value: "Profile", label: intl.formatMessage({ id: "ProcessIssues.Profile" }) }
    ];
}
    
//#endregion

//#region Geo Chart
export const subcontinentList = {
    //Northern Africa
    '015': ['DZ', 'EG', 'EH', 'LY', 'MA', 'SD', 'SS', 'TN'],
    //Western Africa
    '011': ['BF', 'BJ', 'CI', 'CV', 'GH', 'GM', 'GN', 'GW', 'LR', 'ML', 'MR', 'NE', 'NG', 'SH', 'SL', 'SN', 'TG'],
    //Middle Africa
    '017': ['AO', 'CD', 'ZR', 'CF', 'CG', 'CM', 'GA', 'GQ', 'ST', 'TD'],
    //Eastern Africa
    '014': ['BI', 'DJ', 'ER', 'ET', 'KE', 'KM', 'MG', 'MU', 'MW', 'MZ', 'RE', 'RW', 'SC', 'SO', 'TZ', 'UG', 'YT', 'ZM', 'ZW'],
    //Southern Africa
    '018': ['BW', 'LS', 'NA', 'SZ', 'ZA'],

    //Northern Europe	
    '154': ['GG', 'JE', 'AX', 'DK', 'EE', 'FI', 'FO', 'GB', 'IE', 'IM', 'IS', 'LT', 'LV', 'NO', 'SE', 'SJ'],
    //Western Europe
    '155': ['AT', 'BE', 'CH', 'DE', 'DD', 'FR', 'FX', 'LI', 'LU', 'MC', 'NL'],
    //Eastern Europe
    '151': ['BG', 'BY', 'CZ', 'HU', 'MD', 'PL', 'RO', 'RU', 'SU', 'SK', 'UA'],
    //Southern Europe
    '039': ['AD', 'AL', 'BA', 'ES', 'GI', 'GR', 'HR', 'IT', 'ME', 'MK', 'MT', 'RS', 'PT', 'SI', 'SM', 'VA', 'YU'],

    //Northern America
    '021': ['BM', 'CA', 'GL', 'PM', 'US'],
    //Caribbean
    '029': ['AG', 'AI', 'AN', 'AW', 'BB', 'BL', 'BS', 'CU', 'DM', 'DO', 'GD', 'GP', 'HT', 'JM', 'KN', 'KY', 'LC', 'MF', 'MQ', 'MS', 'PR', 'TC', 'TT', 'VC', 'VG', 'VI'],
    //Central America
    '013': ['BZ', 'CR', 'GT', 'HN', 'MX', 'NI', 'PA', 'SV'],
    //South America
    '005': ['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'PE', 'PY', 'SR', 'UY', 'VE'],

    //Central Asia
    '143': ['TM', 'TJ', 'KG', 'KZ', 'UZ'],
    //Eastern Asia
    '030': ['CN', 'HK', 'JP', 'KP', 'KR', 'MN', 'MO', 'TW'],
    //Southern Asia
    '034': ['AF', 'BD', 'BT', 'IN', 'IR', 'LK', 'MV', 'NP', 'PK'],
    //South-Eastern Asia
    '035': ['BN', 'ID', 'KH', 'LA', 'MM', 'MY', 'PH', 'SG', 'TH', 'TL', 'TP', 'VN'],
    //Western Asia
    '145': ['AE', 'AM', 'AZ', 'BH', 'CY', 'GE', 'IL', 'IQ', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', 'SA', 'NT', 'SY', 'TR', 'YE', 'YD'],

    //Australia and New Zealand
    '053': ['AU', 'NF', 'NZ'],
    //Melanesia
    '054': ['FJ', 'NC', 'PG', 'SB', 'VU'],
    //Micronesia
    '057': ['FM', 'GU', 'KI', 'MH', 'MP', 'NR', 'PW'],
    //Polynesia
    '061': ['AS', 'CK', 'NU', 'PF', 'PN', 'TK', 'TO', 'TV', 'WF', 'WS'],
};


//#endregion