import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, Nav, NavItem, NavLink, Row, UncontrolledPopover } from 'reactstrap';
import { FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import SalesDealProposalRooms from './SalesDealProposalRooms';
import SalesDealProposalEvents from './SalesDealProposalEvents';
import SalesDealProposalStats from './SalesDealProposalStats';
import SalesDealProposalCalendar from './SalesDealProposalCalendar';
import ImportGroupResModal from './ImportGroupResModal';
import ProposalHistoryModal from './ProposalHistoryModal';
import { deleteAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';

class SalesDealDetailProposal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            proposals: [],
            filters: [],
            activeTab: 'Stats',
            importGroupResModal: false,
            proposalHistoryModal: false
        };
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.deal?.salesDealProposals?.length !== this.props.deal?.salesDealProposals?.length){
            this.initialCalc(nextProps.deal);
        }
    }

    componentDidMount(){
        this.initialCalc(this.props.deal);
    }

    initialCalc = (deal) => {
        if(!deal?.salesDealProposals)
            return;

        const proposals = deal.salesDealProposals.map(p => ({...p, proposalJSON: JSON.parse(p.proposalJSON)}));
        
        this.setState({
            proposals,
        });
    }

    setFilters = (objectId, objectType, parentId) => {
        const { filters } = this.state;

        const idx = filters.findIndex((f)  => f.objectId === objectId && f.objectType === objectType && f.parentId === parentId);

        if(idx >= 0){
            filters.splice(idx, 1);
        }
        else{
            filters.push({
                objectId,
                objectType,
                parentId
            });
        }

        this.setState({ filters });
    }

    toggleTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    toggleImportGroupResModal = () => {
        this.setState({ importGroupResModal: !this.state.importGroupResModal });
    }

    importGroupRes = (selectedGroupResId, hotelId, proposalId, cb) => {
        this.setState({ block: true }, () => {
            const { deal } = this.props;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false }, () => {
                        if(cb)
                            cb();
                    });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => {
                            handleNotification(data);
                            if(cb)
                                cb();
                        });
                        return;
                    }

                    handleNotification(data, <FormattedMessage id="SalesDeal.ReservationImported" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false }, () => {
                        if(!proposalId){
                            this.toggleImportGroupResModal();
                            if(cb){
                                const newDeal = {
                                    ...deal,
                                    salesDealProposals: [...(deal.salesDealProposals??[]), data.data[0]]
                                };
                                this.props.updateDeal(newDeal);
                            }
                        }
                        else{
                            const proposalIdx = deal.salesDealProposals.findIndex(({id}) => id === proposalId);
                            const proposals = [...deal.salesDealProposals];
                            
                            proposals.splice(proposalIdx, 1, data.data[0]);

                            const newDeal = {
                                ...deal,
                                salesDealProposals: [...proposals]
                            };
                            this.props.updateDeal(newDeal);
                        }
                        if(cb)
                            cb();
                    });
                    
                    return;
                }
                else
                    this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/Proposal/Import?externalId=${selectedGroupResId}&hotelId=${hotelId}&salesDealId=${deal.id}${proposalId ? `&salesDealProposalId=${proposalId}` : ''}`);
        });
    }

    removeGroupReservation = (key) => {
        const { deal } = this.props;
        const proposalId = deal.salesDealProposals[key]?.id;

        if(!proposalId)
            return;

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    
                    handleNotification(data, <FormattedMessage id="SalesDeal.RemovedGroupReservation" />, <FormattedMessage id="generic.success" />);

                    const newDeal = {...deal, 
                        salesDealProposals: deal.salesDealProposals.filter(({id}) => id !== proposalId)
                    };
                    
                    this.setState({ block: false }, () => this.props.updateDeal(newDeal));
                    return;
                }
                else
                    this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/Proposal/${proposalId}`)
        );
    }

    toggleProposalHistoryModal = () => {
        this.setState({ proposalHistoryModal: !this.state.proposalHistoryModal });
    }

    render() {
        const { selectedPipeline, deal } = this.props;
        const { block, error, proposals, filters, activeTab, importGroupResModal, proposalHistoryModal } = this.state;

        const hasFilter = (id, type, parent) => filters.some(({objectId, objectType, parentId}) => objectId === id && objectType === type && parentId === parent);

        return (
            deal ?
                selectedPipeline?.type === "GroupReservation" ?
                    <Card className='border-0 h-100'>
                        <ErrorAlert error={error} />
                        <BlockUi tag="div" blocking={block} className='h-100'>
                            {proposals && proposals.length ?
                                <Row className='h-100'>
                                    <Col className='pt-2 col-9'>
                                        <div className='h-100'>
                                            <div>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div>
                                                        <h5 className='m-0'><FormattedMessage id="SalesDeal.ProposalDetails"/></h5>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <Button className="btn btn-host btn-sm" onClick={this.toggleImportGroupResModal}>
                                                            <FormattedMessage id="SalesDeal.AddNewProposal"/>
                                                            <i className="ml-2 fas fa-receipt"/>
                                                        </Button>
                                                        <Button className="ml-2 btn btn-host btn-sm">
                                                            <i className="far fa-paper-plane"/>
                                                        </Button>
                                                        <Button className="ml-2 btn btn-host btn-sm">
                                                            <i className="far fa-file-excel"/>
                                                        </Button>
                                                        <Button className="ml-2 btn btn-host btn-sm" onClick={this.toggleProposalHistoryModal}>
                                                            <i className="fas fa-history"/>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                <div>
                                                    <Row>
                                                        <Col className='col-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-hotel mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Hotels"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>1</div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-calendar-day mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Nights"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>5</div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-bed mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Rooms"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>27</div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-theater-masks mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Events"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>1</div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-3 mt-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-male mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.TotalPax"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>95</div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-3 mt-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-store-alt mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Spaces"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>
                                                                        44
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-3 mt-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-microphone mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.Equipment"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>
                                                                        86
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        <Col className='col-3 mt-3'>
                                                            <Card className='shadow bg-white border-0 p-3' style={{ borderRadius: '5px' }}>
                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                    <div className='text-muted'>
                                                                        <i className="fas fa-dollar-sign mr-2"/>
                                                                        <FormattedMessage id="SalesDeal.TotalValue"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.4em' }}>
                                                                        <FormatAmountNumber value={12500}/>
                                                                    </div>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                    <div className='mt-4 d-flex align-items-center justify-content-between' style={{ borderBottom: "1px solid #dee2e6" }}>
                                                        <Nav tabs className="border-0">
                                                            <NavItem>
                                                                <NavLink className={activeTab === 'Stats' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Stats')} style={{ cursor: "pointer" }}>
                                                                    <i className="fas fa-chart-pie mr-2"/>
                                                                    <FormattedMessage id="SalesDeal.Stats"/>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={activeTab === 'Rooms' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Rooms')} style={{ cursor: "pointer" }}>
                                                                    <i className="fas fa-bed mr-2"/>
                                                                    <FormattedMessage id="SalesDeal.Rooms"/>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={activeTab === 'Events' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Events')} style={{ cursor: "pointer" }}>
                                                                    <i className="fas fa-theater-masks mr-2"/>
                                                                    <FormattedMessage id="SalesDeal.Events"/>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink className={activeTab === 'Calendar' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('Calendar')} style={{ cursor: "pointer" }}>
                                                                    <i className="far fa-calendar-alt mr-2"/>
                                                                    <FormattedMessage id="SalesDeal.Calendar"/>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                        <>
                                                            <Button className="ml-2 btn btn-host btn-sm" id={`AppliedFilters`}>
                                                                <i className="fas fa-sliders-h"/>
                                                            </Button>
                                                            <UncontrolledPopover target={`AppliedFilters`} placement={"left-start"} placementPrefix='coolPopover bs-popover'>
                                                                <div className='py-2 px-3' style={{ minWidth: '200px', maxWidth: '250px' }}>
                                                                    <div className='text-muted d-flex align-items-center mb-2' style={{ fontSize: '1.1em' }}>
                                                                        <i className="fas fa-sliders-h mr-2 mt-1"/>
                                                                        <FormattedMessage id="SalesDeal.Filters"/>
                                                                    </div>
                                                                    {filters.length > 0 ?
                                                                        (() => {
                                                                            const treatedFilters = filters
                                                                                .map(({parentId}) => parentId)
                                                                                .filter((value, index, array) => array.indexOf(value) === index)
                                                                                .map(parentId => {
                                                                                    const fs = filters.filter((f) => f.parentId === parentId);
                                                                                    const keys = fs.map(({objectType}) => objectType).filter((value, index, array) => array.indexOf(value) === index);
                                                                                    const returnValue = {
                                                                                        parentId,
                                                                                        appliedFilters: []
                                                                                    };

                                                                                    keys.forEach((k) => {
                                                                                        returnValue.appliedFilters.push({
                                                                                            type: k,
                                                                                            list: fs.filter((f) => f.objectType === k).map(f => f.objectId)
                                                                                        });
                                                                                    });
                                                                                    
                                                                                    return returnValue
                                                                                }
                                                                            );
                                                                            
                                                                            return (
                                                                                <div>
                                                                                    {treatedFilters.sort(a => !a.parentId ? -1 : 1).map((parent, key) =>
                                                                                        <div key={key} className='mt-2 pt-2' style={{ borderTop: key ? '1px solid lightgrey' : '' }}>
                                                                                            {parent.parentId ?
                                                                                                <div>
                                                                                                    <b>{parent.parentId}</b>
                                                                                                </div>
                                                                                            :''}
                                                                                            {parent.appliedFilters.map((t, key) =>
                                                                                                <div className={!parent.parentId ? key ? 'mt-2 pt-2' : '' : 'mt-2 px-2'} key={key} style={{ borderTop: !parent.parentId && key ? '1px solid lightgrey' : '' }}>
                                                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                                                        <div>
                                                                                                            {parent.parentId ?
                                                                                                                <div className='text-muted'>
                                                                                                                    <FormattedMessage id={`SalesDeal.${t.type}`}/>
                                                                                                                </div>
                                                                                                            :
                                                                                                                <b>
                                                                                                                    <FormattedMessage id={`SalesDeal.${t.type}`}/>
                                                                                                                </b>
                                                                                                            }
                                                                                                        </div>
                                                                                                        {t.list.some(a => !a) ?
                                                                                                            <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => this.setFilters(null, t.type, parent.parentId)}>
                                                                                                                <i className="fas fa-times"></i>
                                                                                                            </div>
                                                                                                        :''}
                                                                                                    </div>
                                                                                                    {t.list.filter(a => a).map((filter, key) =>
                                                                                                        <div key={key} className='mt-2 d-flex align-items-start justify-content-between px-2'>
                                                                                                            <div>
                                                                                                                {filter}
                                                                                                            </div>
                                                                                                            <div style={{ color: '#A80C19' }} className='cursor-pointer' onClick={_ => this.setFilters(filter.objectId, t.type, parent.parentId)}>
                                                                                                                <i className="fas fa-times"></i>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        })()
                                                                    :
                                                                        <div className='text-muted mt-2 text-center'>
                                                                            <FormattedMessage id="SalesDeal.NoFilters"/>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </UncontrolledPopover>
                                                        </>
                                                    </div>
                                                </div>
                                                <div className='mt-4'>
                                                    <div>
                                                        {activeTab === 'Stats' ?
                                                            <SalesDealProposalStats
                                                                filters={filters}
                                                            />
                                                        : activeTab === 'Rooms' ?
                                                            <SalesDealProposalRooms
                                                                filters={filters}
                                                            />
                                                        : activeTab === 'Events' ?
                                                            <SalesDealProposalEvents
                                                                filters={filters}
                                                            />
                                                        : activeTab === 'Calendar' ?
                                                            <SalesDealProposalCalendar
                                                                proposals={proposals}
                                                                filters={filters}
                                                            />
                                                        :''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='pt-2 col-3 px-0 d-flex justify-content-between flex-column h-100' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                                        <div className='h-100'>
                                            {proposals && proposals.length > 0 ?
                                                <div className='d-flex justify-content-between h-100 flex-column h-100'>
                                                    <div className='d-flex align-items-center justify-content-between px-3'>
                                                        <h5 className='m-0'><FormattedMessage id="SalesDeal.ProposalResume"/></h5>
                                                    </div>
                                                    <div className='pt-4 px-3'>
                                                        {proposals.map((proposal, key) => {
                                                            const hotel = global.hotelList.find(({value}) => value === proposal.hotelId);
                                                            const rooms = proposal.proposalJSON?.data && proposal.proposalJSON.data[0]?.DetailsRooms;
                                                            const events = proposal.proposalJSON?.data && proposal.proposalJSON.data[0]?.DetailsEvents;

                                                            return (
                                                                <div key={key} className='shadow w-100 mb-3 bg-white p-3'>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <div style={{ maxWidth: '80%' }}>
                                                                            <div className='cursor-pointer text-left' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.setFilters(proposal.externalId, "Reservation")}>
                                                                                <b>{proposal.externalId}</b>
                                                                                {hasFilter(proposal.externalId, "Reservation") ?
                                                                                    <div style={{
                                                                                        width: '5px',
                                                                                        height: '5px',
                                                                                        background: '#0665ff',
                                                                                        borderRadius: '100%',
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '-7px'
                                                                                    }}/>
                                                                                :''}
                                                                            </div>
                                                                            <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative', fontSize: '0.8em' }} onClick={_ => this.setFilters(hotel?.label, "Hotel")}>
                                                                                <div className='text-truncate text-muted'>
                                                                                    {hotel?.label}
                                                                                </div>
                                                                                {hasFilter(hotel?.label, "Hotel") ?
                                                                                    <div style={{
                                                                                        width: '5px',
                                                                                        height: '5px',
                                                                                        background: '#0665ff',
                                                                                        borderRadius: '100%',
                                                                                        position: 'absolute',
                                                                                        top: '0',
                                                                                        right: '-7px'
                                                                                    }}/>
                                                                                :''}
                                                                            </div>
                                                                        </div>
                                                                        <KebabMenu
                                                                            direction={'left'}
                                                                            extraFields={[{
                                                                                function: _ => this.importGroupRes(proposal.externalId, proposal.hotelId, proposal.id),
                                                                                icon: 'fas fa-sync-alt mr-2',
                                                                                text: 'SalesDeal.UpdateReservation'
                                                                            }]}
                                                                            deleteFunction={_ => this.removeGroupReservation(key)}
                                                                            deleteText={"SalesDeal.Remove"}
                                                                        />
                                                                    </div>
                                                                    <div className='px-2 mt-3'>
                                                                        {proposal.numberOfRooms ?
                                                                            <div className='pb-2'>
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.setFilters(null, "Rooms", proposal.externalId)}>
                                                                                        <FormattedMessage id="SalesDeal.Rooms"/>
                                                                                        {hasFilter(null, "Rooms", proposal.externalId) ?
                                                                                            <div style={{
                                                                                                width: '5px',
                                                                                                height: '5px',
                                                                                                background: '#0665ff',
                                                                                                borderRadius: '100%',
                                                                                                position: 'absolute',
                                                                                                top: '0',
                                                                                                right: '-7px'
                                                                                            }}/>
                                                                                        :''}
                                                                                    </div>
                                                                                    <div>
                                                                                        <FormatAmountNumber currency={global.hotelCurrency} value={proposal.roomsValue??0}/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='px-2 mt-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                                                    {rooms.map((room, key) =>
                                                                                        <React.Fragment key={key}>
                                                                                            {key ?
                                                                                                <hr className='my-1'/>
                                                                                            :''}
                                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                                <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.setFilters(room.CategoryCode, "Rooms", proposal.externalId)}>
                                                                                                    {room.CategoryCode}
                                                                                                    <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                                                        {room.Rooms}
                                                                                                    </div>
                                                                                                    {hasFilter(room.CategoryCode, "Rooms", proposal.externalId) ?
                                                                                                        <div style={{
                                                                                                            width: '5px',
                                                                                                            height: '5px',
                                                                                                            background: '#0665ff',
                                                                                                            borderRadius: '100%',
                                                                                                            position: 'absolute',
                                                                                                            top: '0',
                                                                                                            right: '-7px'
                                                                                                        }}/>
                                                                                                    :''}
                                                                                                </div>
                                                                                                <div>
                                                                                                    <FormatAmountNumber currency={global.hotelCurrency} value={room.PriceTotal??0}/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        :''}
                                                                        {proposal.numberOfSpaces ?
                                                                            <div className='pb-2 mt-3'>
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className='cursor-pointer' style={{ width: 'fit-content', position: 'relative' }} onClick={_ => this.setFilters(null, "Events", proposal.externalId)}>
                                                                                        <FormattedMessage id="SalesDeal.Events"/>
                                                                                        {hasFilter(null, "Events", proposal.externalId) ?
                                                                                            <div style={{
                                                                                                width: '5px',
                                                                                                height: '5px',
                                                                                                background: '#0665ff',
                                                                                                borderRadius: '100%',
                                                                                                position: 'absolute',
                                                                                                top: '0',
                                                                                                right: '-7px'
                                                                                            }}/>
                                                                                        :''}
                                                                                    </div>
                                                                                    <div>
                                                                                        <FormatAmountNumber currency={global.hotelCurrency} value={proposal.spacesValue??0}/>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='px-2' style={{ borderTop: '1px solid lightgrey', fontSize: '0.9em' }}>
                                                                                    {events.map((event, key) =>
                                                                                        <React.Fragment key={key}>
                                                                                            {key ?
                                                                                                <hr className='my-1'/>
                                                                                            :''}
                                                                                            <div className='d-flex align-items-center justify-content-between mt-2'>
                                                                                                <div className='d-flex cursor-pointer' style={{ width: 'fit-content', position: 'relative', maxWidth: '80%' }} onClick={_ => this.setFilters(event.EventName, "Events", proposal.externalId)}>
                                                                                                    <div className='text-truncate'>{event.EventName}</div>
                                                                                                    <div style={{ fontSize: '0.9em' }} className='ml-1 text-muted'>
                                                                                                        {event.Spaces.length}
                                                                                                    </div>
                                                                                                    {hasFilter(event.EventName, "Events", proposal.externalId) ?
                                                                                                        <div style={{
                                                                                                            width: '5px',
                                                                                                            height: '5px',
                                                                                                            background: '#0665ff',
                                                                                                            borderRadius: '100%',
                                                                                                            position: 'absolute',
                                                                                                            top: '0',
                                                                                                            right: '-7px'
                                                                                                        }}/>
                                                                                                    :''}
                                                                                                </div>
                                                                                                <div>
                                                                                                    <FormatAmountNumber currency={global.hotelCurrency} value={event.PriceTotal??0}/>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        :''}
                                                                        {proposals.length > 1 ?
                                                                            <div className='d-flex align-items-center justify-content-between mt-2 pt-2 pb-2' style={{ borderTop: '1px solid lightgrey' }}>
                                                                                <div>
                                                                                    <FormattedMessage id="SalesDeal.Total"/>
                                                                                </div>
                                                                                <div>
                                                                                    <FormatAmountNumber
                                                                                        currency={global.hotelCurrency}
                                                                                        value={proposal.totalValue ?? 0}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        :''}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        )}
                                                    </div>
                                                    <div className='px-3'>
                                                        <div className='mt-2 p-3 shadow bg-white' style={{ position: 'sticky', bottom: '0' }}>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='d-flex align-items-center justify-content-start'>
                                                                    <div>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesDeal.Rooms"/>
                                                                        </div>
                                                                        <div>
                                                                            {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfRooms??0), 0) ?? 0}
                                                                        </div>
                                                                    </div>
                                                                    <div className='ml-4'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesDeal.Spaces"/>
                                                                        </div>
                                                                        <div>
                                                                            {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfSpaces??0), 0) ?? 0}
                                                                        </div>
                                                                    </div>
                                                                    <div className='ml-4'>
                                                                        <div className='title-sm'>
                                                                            <FormattedMessage id="SalesDeal.Equipment"/>
                                                                        </div>
                                                                        <div>
                                                                            {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfEquipment??0), 0) ?? 0}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='text-right'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesDeal.Total"/>
                                                                    </div>
                                                                    <div style={{ fontSize: '1.2em' }}>
                                                                        <b>
                                                                            <FormatAmountNumber
                                                                                currency={global.hotelCurrency}
                                                                                value={deal.salesDealProposals.reduce((prev, curr) => prev + (curr.totalValue??0), 0) ?? 0}
                                                                            />
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            :
                                                <div className='mt-4 text-center'>
                                                    <div className='text-muted'>
                                                        <FormattedMessage id="SalesDeal.NoProposalsYet"/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            :
                                <div className='mt-4 text-center'>
                                    <div className='text-muted'>
                                        <FormattedMessage id="SalesDeal.NoProposalsYet"/>
                                    </div>
                                    <div className='mt-2'>
                                        <Button onClick={this.toggleImportGroupResModal} className="btn btn-host btn-sm">
                                            <FormattedMessage id="SalesDeal.AddNewProposal"/>
                                            <i className="ml-2 fas fa-receipt"></i>
                                        </Button>
                                    </div>
                                </div>
                            }
                        </BlockUi>

                        {importGroupResModal ?
                            <ImportGroupResModal
                                toggleModal={this.toggleImportGroupResModal}
                                modal={true}
                                salesDealId={deal.id}
                                importGroupRes={this.importGroupRes}
                            />
                        :''}

                        {proposalHistoryModal ?
                            <ProposalHistoryModal
                                modal={true}
                                toggleModal={this.toggleProposalHistoryModal}
                                salesDealId={deal.id}
                            />
                        :''}
                    </Card>
                :''
            :''
        );
    }
}

export default SalesDealDetailProposal;
