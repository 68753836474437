import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Input, Button, Form, Card } from 'reactstrap'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { DefaultPage, StyledCard, CommonHelper } from '../../Base/CommonUIComponents';
import { getAPI } from '../../Base/API';
import ActivityDetail from './ActivityDetail';
import { Pagination, ResultsPerPage, TotalResults } from '../../Base/PaginationComponents';
import { handleNotification } from "../../Base/Notification";
import Authorization from '../../Base/Authorization';


class Activity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            startDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            block: false,
            error: false,
            focusedInput: null,
            activityData: [],
            modal: false,
            selectedObjId: null,
            limit: 1000,
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            activityDataPaginated: []
        };
    }

    componentDidMount(){
        this.getSendGridActivity();
    }

    getSendGridActivity = (e) => {
        if (e) e.preventDefault();
        const { startDate, endDate, email } = this.state;

        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response && data.response.messages) {
                    this.setState({ activityData: data.response.messages }, () => {this.changePage()});
                }else{
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/gms/SendGrid/v1/messages?query=${email ? 'to_email="' + email + '"' : 'last_event_time BETWEEN TIMESTAMP "' + moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") + '" AND TIMESTAMP "' + moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") + '"'}`);
        });
    }

    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, () => this.changePage());
        }
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.changePage());
    }

    changePage = () => {
        const { activityData, pageIndex, pageSize } = this.state;

        const totalItems = activityData.length;
        const activityDataPaginated = activityData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

        this.setState({ activityDataPaginated: activityDataPaginated, totalItems: totalItems, block: false });
    }

    toggleModal = (objId) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedObjId: objId
        }));
    }

    render() {
        const { block, error, startDate, endDate, email, focusedInput, activityData, modal, selectedObjId, pageSize, pageIndex, totalItems, activityDataPaginated } = this.state;
        const { page } = this.props;
        
        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> 
                            <i className={page?.icon} /> 
                            <FormattedMessage id="SendGrid.Activity" /> 
                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    <CommonHelper help={<FormattedMessage id="SendGridActivity.resume" />} id={'activityHelp'}  />
                                )}
                                no={() => <div></div>}
                            />
                        </h5>
                    </Col>
                </Row>
                <Form onSubmit={(e) => this.getSendGridActivity(e)}>
                    <Row className='d-flex justify-content-between align-items-center mb-4'>
                        <Col className='col-3'>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                placeholder={this.props.intl.formatMessage({ id: "SendGridSupression.Email" })}
                            />
                        </Col>
                        <Col>
                            <DateRangePicker
                                startDate={startDate ? moment(startDate) : null}
                                startDateId="startDate"
                                isOutsideRange={_ => false}
                                endDate={endDate ? moment(endDate) : null}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate ?  moment(startDate) : null, endDate: endDate ?  moment(endDate) : null })}
                                focusedInput={focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                showClearDates={true}
                                required={true}
                            />
                        </Col>
                        <Col className='text-right'>
                            <Button 
                                type='submit'
                                className="btn btn-sm btn-host"
                            >
                                <i className="fas fa-search"></i>
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {activityDataPaginated && activityDataPaginated.length > 0 ?
                    <>
                        <Row className=" text-muted align-items-center mb-2 mt-3 px-2">
                            <Col sm={3}> <FormattedMessage id="SendGridActivity.Status" /></Col>
                            <Col sm={4}> <FormattedMessage id="SendGridActivity.Message" /> </Col>
                            <Col sm={3} className='pl-0'> <FormattedMessage id="SendGridActivity.LastEventReceived" /> </Col>
                            <Col sm={1} className='p-0'> <FormattedMessage id="SendGridActivity.Opens" /> </Col>
                            <Col sm={1} className='p-0'> <FormattedMessage id="SendGridActivity.Clicks" /> </Col>
                        </Row>

                        <div className="pt-1">
                            {activityDataPaginated.map((obj, key) =>
                                <Card className="shadow py-2 px-3 overbglight mb-2 cursor-pointer d-flex justify-content-center" key={key} onClick={() => this.toggleModal(obj.msg_id)} style={{minHeight: '65px'}}>
                                    <Row className="my-1 align-items-center">
                                        <Col sm={3} className="d-flex align-items-center">
                                            {obj.status === 'delivered' ? 
                                                <span> <i className="fas fa-check-circle text-success mr-2" /> <FormattedMessage id="SendGridActivity.Delivered" /> </span>
                                            :   obj.status === 'not_delivered' ?
                                                <span> <i className="fas fa-times-circle text-danger mr-2" /> <FormattedMessage id="SendGridActivity.NotDelivered" /> </span>
                                            :   
                                                <span> <i className="fas fa-exclamation-circle text-warning mr-2" /> <FormattedMessage id="SendGridActivity.Processing" /> </span>
                                            }
                                        </Col>
                                        <Col sm={4} className="d-flex flex-column align-items-start justify-content-center">
                                            <span className='font-weight-bold'><FormattedMessage id="SendGridActivity.To" /> {obj.to_email}</span>
                                            {obj.subject}
                                        </Col>
                                        <Col sm={3} className="d-flex align-items-center">
                                            {moment(obj.last_event_time).format('DD/MM/YYYY hh:mm')}
                                        </Col>
                                        <Col sm={1} className="d-flex align-items-center">
                                            {obj.opens_count}
                                        </Col>
                                        <Col sm={1} className="d-flex align-items-center">
                                            {obj.clicks_count}
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </div>
                        {modal === true ?
                            <ActivityDetail
                                toggleModal={() => this.toggleModal}
                                selectedObjId={selectedObjId}
                                modal={modal}
                            />
                        :''}
                    </>
                :
                    !block ?
                    <DefaultPage text="SendGridActivity.ThereAreNoActivity" icon={page?.icon} />
                
                :''}

                {activityData && activityData.length > 10 && !block ?
                    <Row className="my-3">
                        <Col>
                            <ResultsPerPage
                                changeSizePerPage={this.changePageSize}
                                pageSize={pageSize}
                            />

                            <span className="ml-2">
                                <TotalResults
                                    end={pageIndex * pageSize + activityDataPaginated.length}
                                    start={pageIndex * pageSize + 1}
                                    total={totalItems}
                                />
                            </span>
                        </Col>

                        <Col className="text-right">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                :''}
                
            </StyledCard>
        );
    }
}

export default injectIntl(Activity);
