import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI, postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { SendMessageModal } from './SendMessageModal';
import CustomSelect from '../Base/CustomSelect';

export class SendEmailModal extends Component {

    constructor(props) {
        super(props);
        this.getSenders = this.getSenders.bind(this);
        this.getTemplates = this.getTemplates.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.form = React.createRef();
        this.state = {
            blockSenders: true,
            blockTemplates: true,
            senders: [],
            templates: [],
            sender: {},
            template: {},
            contact: null
        }
    }

    componentDidMount() {
        if (this.props.contacts && this.props.contacts.length > 0) {
            const contact = this.props.contacts.some(el => el.main) ? this.props.contacts.find(el => el.main) : this.props.contact ? this.props.contact[0] : '';

            this.setState({ contact }, () => {
                this.getSenders();
                this.getTemplates();
            })
        }
    }

    getSenders() {
        const { senderType } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data && data.items && data.items.length > 0) {
                const senders = data.items.map(resp => {
                    resp.value = resp.sender_id;
                    resp.label = resp[senderType];
                    return resp;
                });
                this.setState({ errorSenders: errorMessage, blockSenders: false, senders: senders });
            }
            else {
                this.setState({ errorSenders: errorMessage, blockSenders: false });
            }
        }, `/api/gms/Marketing/v1/egoi/senders/${senderType}?offset=0&limit=100`);
    }

    getTemplates() {
        const { type } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const templates = data.response.map(resp => {
                    resp.value = resp.id;
                    resp.label = resp.templateName;
                    return resp;
                });
                this.setState({ errorTemplates: errorMessage, blockTemplates: false, templates: templates });
            }
            else {
                this.setState({ errorTemplates: errorMessage, blockTemplates: false });
            }
        }, `/api/gms/Marketing/v1/egoitransactional/template/${type}`);
    }

    handleChange(combo, evt) {
        if (evt) {
            const { name } = evt;
            this.setState({ [name]: combo ? combo : {} });
        }
    }

    sendMessage() {
        const { type, profileId } = this.props;
        const { sender, template, contact } = this.state;
        if (!this.form.current.reportValidity()) {
            return;
        }

        const request = {
            openTrackingEnable: template.openTrackingEnable,
            clickTrackingEnable: template.clickTracking,
            templateId: template.id,
            senderId: sender.sender_id,
            email: contact.contact,
            mobile: contact.contact,
            textMessage: template.text
        };

        this.setState({ blockSenders: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorSenders: errorMessage, blockSenders: false });
                return;
            }
            if (data) {
                if ((data.Errors && data.Errors.length > 0) || (data.errors && data.errors.length > 0)) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="SendEmailModal.MessageSentWithSuccess" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ errorSenders: errorMessage, blockSenders: false });
        }, `/api/gms/Marketing/v1/egoitransactional/${type}/send/${profileId}`, request);
    }

    render() {
        const { isOpen, toggle, buttonLabel } = this.props;
        const { blockSenders, blockTemplates, errorSenders, errorTemplates, senders, sender, templates, template, contact } = this.state;

        return (
            <SendMessageModal
                blockSenders={blockSenders}
                blockTemplates={blockTemplates}
                buttonLabel={buttonLabel}
                contact={contact}
                errorSenders={errorSenders}
                errorTemplates={errorTemplates}
                form={this.form}
                handleChange={this.handleChange}
                isOpen={isOpen}
                sender={sender}
                senders={senders}
                sendMessage={this.sendMessage}
                toggle={toggle}
            >
                <div>
                    <Row className="mt-2">
                        <Col>
                            <CustomSelect name="template" options={templates} value={templates.find(t => t.value === template.value)} onChange={this.handleChange} required />
                        </Col>
                    </Row>
                    {
                        template && template.text ?
                            <Card>
                                {template.text}
                            </Card>
                            :
                            <div dangerouslySetInnerHTML={{ __html: template && template.htmlBody }} />
                    }
                </div>
            </SendMessageModal>
        );
    }
}