import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Authorization from "./Base/Authorization";
import NoAccessPage from './NoAccessPage';
import { Route } from 'react-router-dom';
import { SearchButton } from './SearchButton';
import ErrorBoundary from '../Utils/ErrorBoundary';
import GoogleAnalytics from '../Utils/GoogleAnalytics';
import { BrowserVersionNotSupported, isBrowserVersionSupported } from './BrowserVersion';

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.state = {
            isBrowserVersionSupported: true
        };
    }

    componentDidMount() {
        this.setState({ isBrowserVersionSupported: isBrowserVersionSupported() })
    }


    render () {
        return (
            this.state.isBrowserVersionSupported ?
                <ErrorBoundary>
                    <GoogleAnalytics/>
                    <div className="bg-light">
                        <Authorization
                            perform="GmsClient"
                            yes={() => (
                                <>
                                    <NavMenu />
                                    <Container className="bg-light h-100 container-fluid">
                                        {this.props.children}

                                        <SearchButton />                            
                                    </Container>
                                </>
                            )}
                            no={() => (
                                <Route path='/' component={NoAccessPage} />
                            )}
                        />
                    </div>
                </ErrorBoundary>
                :
                <BrowserVersionNotSupported />
        );
    }
}
