import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Input, Modal, Nav, NavItem, NavLink, Row, Form } from 'reactstrap';
import CustomSelect from '../Base/CustomSelect';
import moment from 'moment-timezone';
import { SingleDatePicker } from 'react-dates';
import { postAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { getSalesDealActionTypes } from '../Base/ReferenceDataFunctions';

class SalesActionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            deal: null,
            actionTypesOptions: getSalesDealActionTypes(this.props.intl, true),
            actionStatusOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Scheduled` }), value: 'Scheduled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Cancelled` }), value: 'Cancelled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.OnGoing` }), value: 'OnGoing' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Finished` }), value: 'Finished' }
            ],
            action: this.props.selectedAction || {
                type: "Note",
                title: null,
                summary: null,
                content: null,
                subject: null,
                preview: null,
                isRead: null,
                externalFolderId: null,
                subject: null,
                externalId: null,
                isDraft: null,
                externalProvider: null,
                salesDealActionThreadId: null,
                status: "Scheduled",
                createdAt: moment().format('YYYY/MM/DD 00:00'),
                startDate: moment().format('YYYY/MM/DD 00:00'),
                endDate: null,//moment().add(30, 'm').format('YYYY/MM/DD HH:mm'),
                salesPipelineStageId: this.props.salesPipelineStageId,
                salesDealId: this.props.salesDealId,
                url: null,
                users: [],
                customers: []
            },
            timeOptions: this.getArray(24).flatMap((d, index) => {
                const hour = index.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })
                return (
                    [{
                        label: `${hour}:00`,
                        value: `${hour}:00`,
                    }, {
                        label: `${hour}:30`,
                        value: `${hour}:30`,
                    }]
                );
            }),
            customersAndUsersOptions: []
        };
    }

    componentDidMount(){
        const { customers, salesUsers, intl } = this.props;
        this.setState({ customersAndUsersOptions: [
            {
                label: intl.formatMessage({ id: "SalesProcess.Customers" }),
                options: [...customers]
            },
            {
                label: intl.formatMessage({ id: "SalesProcess.Staff" }),
                options: [...salesUsers]
            }
        ]});
    }

    createAction = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return;
                    }
                    
                    this.props.updateActions(data, false);
                    this.props.toggle();

                    handleNotification(data, <FormattedMessage id="SalesDeal.ActionSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                    this.setState({ block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action`, { ...this.state.action });
        })
    }
    
    toggleActionTab = tab => {
        this.setState({ action: {...this.state.action, type: tab} });
    }

    handleComboChange = (combo, name) => {
        this.setState({ action: {...this.state.action, [name]: combo ? combo.value : null} });
    }

    handleChange = e => {
        const { value, name } = e.target;
        this.setState({ action: {...this.state.action, [name]: value} });
    }

    handleMultiComboChange = (combo, name) => {
        this.setState({ action: { ...this.state.action, [name]: combo ? combo?.map(cmb => cmb.value) : [] } });
    }

    handleDateChange = date => {
        const formattedDate = moment(date).format("YYYY-MM-DD HH:mm");
        this.setState({ action: { ...this.state.action, startDate: formattedDate, endDate: formattedDate } });
    }

    handleHoursChange = (combo, isEnd) => {
        const { action } = this.state;

        const date = moment(action.startDate);
        const hour = combo?.value ? combo?.value.substring(0, 2) : '00';
        const minute = combo?.value ? combo?.value.substring(3) : '00';

        date.set({ hour, minute });

        const formattedstartDate = moment(date).format("YYYY-MM-DD HH:mm");
        const formattedendDate = isEnd ? moment(date).format("YYYY-MM-DD HH:mm") : moment(date).add(30, 'm').format("YYYY-MM-DD HH:mm");
        
        this.setState({
            action: {
                ...this.state.action,
                startDate: isEnd ? this.state.action.startDate : formattedstartDate,
                endDate: isEnd ? formattedstartDate : formattedendDate
            }
        });
    }

    getArray(size) {
        const array = [];
        for (let i = 0; i < size; i++) {
            array.push(i);
        }
        return array;
    }

    fileChanged = (e) => {
        e.target.value = null;
    };

    handleFileChange = () => {

    }

    render() {
        const { action, actionTypesOptions, actionStatusOptions, block, timeOptions, customersAndUsersOptions } = this.state;
        const { toggle, salesUsers, customers } = this.props;

        return (
            <Modal isOpen={true} toggle={toggle}>
                <BlockUi tag="div" blocking={block}>
                    <Button onClick={toggle} className="closeModal">
                        <i className="fas fa-times fa-sm"/>
                    </Button>
                    <Card className='border-0 p-4 shadow'>
                        <Form onSubmit={this.createAction}>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <h5><FormattedMessage id="SalesProcess.NewAction" /></h5>
                                </div>
                                {action.type !== 'Email' ?
                                    <Button className="btn btn-host btn-sm" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                :''}
                            </div>
                            <div>
                                <div className='mt-1'>
                                    <Nav tabs className="border-0">
                                        {actionTypesOptions.map((opType, key) =>
                                            <NavItem key={key}>
                                                <NavLink className={action.type === opType.value ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleActionTab(opType.value)} style={{ cursor: "pointer" }}>
                                                    <i className={`mr-2 ${opType.icon}`} />
                                                    <FormattedMessage id={`SalesProcess.${opType.label}`} />
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </Nav>
                                </div>
                                {action.type === 'Email' ?
                                    <div>
                                        <div className='mt-3'>
                                            <div>
                                                <b><FormattedMessage id="SalesProcess.InternalConfig"/></b>
                                            </div>
                                            <Row className='mt-2'>
                                                <Col className='col-6'>
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.Title" />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            type="text"
                                                            value={action.title}
                                                            name="title"
                                                            required={true}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className='col-6'>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SalesProcess.InternalSummary" />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            type="text"
                                                            value={action.summary}
                                                            name="summary"
                                                            required={true}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='mt-3'>
                                            <div>
                                                <b><FormattedMessage id="SalesProcess.EmailConfig"/></b>
                                            </div>
                                            <Row className='mt-2'>
                                                <Col className='col-12'>
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.Subject" />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            type="text"
                                                            value={action.subject}
                                                            name="subject"
                                                            required={true}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-4 mt-3">
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.From" />
                                                    </div>
                                                    <div>
                                                        <CustomSelect
                                                            value={customersAndUsersOptions.flatMap(el => el.options).filter(({ value }) => action.users?.some(crd => crd.role === "From" && value === crd.userId) || action.customers?.some(crd => crd.role === "From" && value === crd.userId))}
                                                            options={customersAndUsersOptions}
                                                            isMulti={true}
                                                            required={true}
                                                            isClearable={false}
                                                            isSearchable={true}
                                                            placeholder=""
                                                            onChange={(combo, action) => this.handleComplexMultiComboChange(combo, action, 'From')}
                                                            // onChange={e => }
                                                            // value={hotelId ? hotels.flatMap(el => el.options).filter(el => hotelId.find(v => el.value == v)) : ''}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className="col-4 mt-3">
                                                    <div>
                                                        <div className='title-sm required'>
                                                            <FormattedMessage id="SalesProcess.To" />
                                                        </div>
                                                        <div>
                                                            <CustomSelect
                                                                value={customersAndUsersOptions.filter(({ value }) => action.customers?.some(crd => value === crd))}
                                                                options={customersAndUsersOptions}
                                                                isMulti={true}
                                                                required={false}
                                                                isClearable={false}
                                                                isSearchable={false}
                                                                placeholder=""
                                                                onChange={e => this.handleMultiComboChange(e, 'customers')}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="col-4 mt-3">
                                                    <div>
                                                        <div className='title-sm'>
                                                            <FormattedMessage id="SalesProcess.BCC" />
                                                        </div>
                                                        <div>
                                                            <CustomSelect
                                                                value={customersAndUsersOptions.filter(({ value }) => action.customers?.some(crd => value === crd))}
                                                                options={customersAndUsersOptions}
                                                                isMulti={true}
                                                                required={false}
                                                                isClearable={false}
                                                                isSearchable={false}
                                                                placeholder=""
                                                                onChange={e => this.handleMultiComboChange(e, 'customers')}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="col-12 mt-3">
                                                    <div className='title-sm required'>
                                                        <FormattedMessage id="SalesProcess.EmailContent" />
                                                    </div>
                                                    <div>
                                                        <Input
                                                            style={{ minHeight: '150px' }}
                                                            type="textarea"
                                                            value={action.content}
                                                            name="content"
                                                            required={true}
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col className='col-12 text-right mt-3'>
                                                    <div className='position-relative'>
                                                        <Button type="submit" className="btn btn-host btn-sm" style={{ borderRadius: '0.2rem 0 0 0.2rem' }}>
                                                            <FormattedMessage id="SalesProcess.SendEmail"/>
                                                            <i className="fas fa-paper-plane ml-2"></i>
                                                        </Button>
                                                        <Button onClick={_ => this.setState({ submitOptions: !this.state.submitOptions })} className="btn btn-host btn-sm" id="StatusOptions" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                                                            <i className='fas fa-chevron-down'/>
                                                        </Button>
                                                        {this.state.submitOptions ?
                                                            <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                                                <div>
                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer'>
                                                                        <i className="fas fa-sticky-note mr-2"/>
                                                                        <FormattedMessage id="SalesProcess.SaveDraft"/>
                                                                    </div>
                                                                    <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer'>
                                                                        <i className="far fa-clock mr-2"/>
                                                                        <FormattedMessage id="SalesProcess.ScheduleSend"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        :''}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                : action.type === 'Call' ?
                                    <Row>
                                        <Col className="col-2">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.Date" />
                                            </div>
                                            <SingleDatePicker
                                                id="startDateId"
                                                isOutsideRange={day => false}
                                                date={action.startDate ? moment(action.startDate) : ''}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={this.handleDateChange}
                                                showClearDate={true}
                                            />
                                        </Col>
                                        <Col className='col-2'>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.Start" />
                                            </div>
                                            <CustomSelect
                                                value={action.startDate ? timeOptions.find(({ value }) => value === `${moment(action.startDate).format('HH:mm')}`) : ''}
                                                options={timeOptions}
                                                required={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                onChange={(combo) => this.handleHoursChange(combo, false)}
                                            />
                                        </Col>
                                        <Col className='col-2'>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.End" />
                                            </div>
                                            <CustomSelect
                                                value={action.endDate ? timeOptions.find(({ value }) => value === `${moment(action.endDate).format('HH:mm')}`) : ''}
                                                options={timeOptions}
                                                required={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                onChange={e => this.handleHoursChange(e, true)}
                                            />
                                        </Col>
                                        <Col className="col-6">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.To" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    value={customers.filter(({ value }) => action.customers?.some(crd => value === crd))}
                                                    options={customers}
                                                    isMulti={true}
                                                    required={false}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    placeholder=""
                                                    onChange={e => this.handleMultiComboChange(e, 'customers')}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 mt-2">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.Summary" />
                                            </div>
                                            <div>
                                                <Input
                                                    style={{ minHeight: '150px' }}
                                                    type="textarea"
                                                    value={action.summary}
                                                    name="summary"
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                : action.type === 'Note' ?
                                    <Row>
                                        <Col className="col-12">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.Note" />
                                            </div>
                                            <div>
                                                <Input
                                                    style={{ minHeight: '150px' }}
                                                    type="textarea"
                                                    value={action.summary}
                                                    name="summary"
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                : action.type === 'File' ?
                                    <Row>
                                        <Col className="col-12">
                                            <Row>
                                                <Col className='col-6'>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SalesProcess.Description" />
                                                    </div>
                                                </Col>
                                                <Col className='col-6'>
                                                    <div className='title-sm'>
                                                        <FormattedMessage id="SalesProcess.File" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='col-6'>
                                                    <Input
                                                        type="text"
                                                        value={action.summary}
                                                        name="summary"
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                                <Col className='col-6'>
                                                    <input
                                                        required={true}
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        disabled={this.state.hotelId ? false : true}
                                                        onChange={e => this.handleFileChange(e)}
                                                        onClick={(e) => this.fileChanged(e)}
                                                    />
                                                    <label className="custom-file-label mx-3" for="customFile">
                                                        <FormattedMessage id="SalesProcess.File" />
                                                    </label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                : action.type === 'Meeting' ?
                                    <Row>
                                        <Col className="col-2">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.Date" />
                                            </div>
                                            <SingleDatePicker
                                                id="startDateId"
                                                isOutsideRange={day => false}
                                                date={action.startDate ? moment(action.startDate) : ''}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                onDateChange={this.handleDateChange}
                                                showClearDate={true}
                                            />
                                        </Col>
                                        <Col className='col-2'>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.Start" />
                                            </div>
                                            <CustomSelect
                                                value={action.startDate ? timeOptions.find(({ value }) => value === `${moment(action.startDate).format('HH:mm')}`) : ''}
                                                options={timeOptions}
                                                required={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                onChange={(combo) => this.handleHoursChange(combo, false)}
                                            />
                                        </Col>
                                        <Col className='col-2'>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.End" />
                                            </div>
                                            <CustomSelect
                                                value={action.endDate ? timeOptions.find(({ value }) => value === `${moment(action.endDate).format('HH:mm')}`) : ''}
                                                options={timeOptions}
                                                required={false}
                                                isClearable={false}
                                                isSearchable={false}
                                                onChange={e => this.handleHoursChange(e, true)}
                                            />
                                        </Col>
                                        <Col className="col-6">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.To" />
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    value={customers.filter(({ value }) => action.customers?.some(crd => value === crd))}
                                                    options={customers}
                                                    isMulti={true}
                                                    required={false}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    placeholder=""
                                                    onChange={e => this.handleMultiComboChange(e, 'customers')}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 mt-2">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.InternalNotes" />
                                            </div>
                                            <div>
                                                <Input
                                                    style={{ minHeight: '150px' }}
                                                    type="textarea"
                                                    value={action.summary}
                                                    name="summary"
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="col-12 mt-2">
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.MeetingDesc" />
                                            </div>
                                            <div>
                                                <Input
                                                    style={{ minHeight: '150px' }}
                                                    type="textarea"
                                                    value={action.content}
                                                    name="content"
                                                    required={true}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                : ''}
                            </div>
                        </Form>
                    </Card>
                </BlockUi>
            </Modal>
        )
    }
}

export default injectIntl(SalesActionModal);