import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormatAmountNumber } from '../Base/CommonUIComponents';
import { Col, Row } from 'reactstrap';

class SalesDealProposalRooms extends Component {
    
    render() {
        const { filters } = this.props;
        
        return (
            <div>
                <div className='d-flex align-items-center justify-content-between text-muted'>
                    <div style={{ fontSize: '1.1em' }}>
                        <i className="fas fa-bed mr-2"/>
                        <FormattedMessage id="SalesDeal.Rooms"/>
                    </div>
                    <div style={{ fontSize: '1.4em' }}>
                        <FormatAmountNumber value={3000}/>
                    </div>
                </div>
                <div className='overflow-auto'>
                    <Row className='mt-2 w-100 px-2 d-flex align-items-center flex-nowrap text-muted'>
                        <Col className='col-3 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', }}>
                            Category
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Rate
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Package
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Rooms
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Adults
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Children
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Status
                        </Col>
                        <Col className='col-2 py-3 text-center' style={{ background: '#165c7d12', borderBottom: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
                            Price
                        </Col>
                    </Row>
                    <div>
                        {[1,2,3,4].map((a, key) =>
                            <Row key={key} className='w-100 px-2 d-flex align-items-center flex-nowrap'>
                                <Col className='col-3 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '' }}>
                                    Category {a}
                                </Col>
                                <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                    Rate {a}
                                </Col>
                                <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                    Package {a}
                                </Col>
                                <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                    12
                                </Col>
                                <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                    24
                                </Col>
                                <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                    10
                                </Col>
                                <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                    Definitive
                                </Col>
                                <Col className='col-2 py-3 text-center text-truncate' style={{ background: key % 2 ? '#fafcff' : '', borderLeft: '1px solid lightgrey' }}>
                                    <FormatAmountNumber value={1233}/>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesDealProposalRooms;
