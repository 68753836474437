import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink, Input, ModalBody, Modal, Form, Button } from 'reactstrap';
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import { deleteAPI, getAPI, postAPI } from '../../Base/API';
import { CommonHelper, StyledCard } from '../../Base/CommonUIComponents';
import ConfirmActionModal from '../../Base/ConfirmActionModal';
import { handleNotification } from '../../Base/Notification';
import { StripoFolders } from './CommonComponents';
import TemplatePreview from './TemplatePreview';
import CustomSelect from '../../Base/CustomSelect';
import NewEmailModal from './NewEmailModal';

class StripoHome extends Component {

    constructor (props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            pageSize: 9,
            pageIndex: 0,
            totalItems: {},
            templates: [],
            emails: [],
            activeTab: '0',
            folders:[],
            editableNewFolder: null,
            selectedFolder: null,
            modal: false,
            newTemplateName: '',
            newFolderName: '',
            copyTemplateId: null,
            dragging: false,
            newFolder: false,
            deleteTemplateModal: false
        };
    }

    componentDidMount() {
        this.getEmails();
    }

    getEmails = (templates, id) => {
        const { pageSize, pageIndex, folders, totalItems } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                };

                const propName = templates ? 'templates' : 'emails';

                const copy = data.response ? [...this.state[propName]].concat(data.response.map((t) => {
                    t.pageIndex = pageIndex;
                    return t;
                })) : [...this.state[propName]];

                const totalItemsCopy = {...totalItems};
                totalItemsCopy[id ? id : null] = data.totalItems;

                this.setState({ totalItems: totalItemsCopy, error: errorMessage, [propName]: copy.filter((item, pos) => {
                    return copy.findIndex(({id}) => item.id === id) == pos;
                }) }, () => {
                    if(!folders || folders.length === 0) this.getFolders();
                });
            }
            this.setState({ block: false });
        }, `/api/gms/Template/v1/templates/master?type=${templates ? 'Template' : 'Email'}&pageSize=${pageSize}&pageIndex=${pageIndex}${id ? `&groupId=${id}` : ''}`);
    }

    getFolders = () => {

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                };

                this.setState({ folders: data.response ? data.response : [], block: false });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Template/v1/templates/group?pageSize=30&pageIndex=0`);
    }

    newTemplate = (e, copyTemplateId) => {
        e.preventDefault();
        this.setState({ block: true });
        const { history } = this.props;
        const { newTemplateName, selectedFolder, activeTab, basedTemplate } = this.state;

        const body = {
            name: newTemplateName,
            type: basedTemplate ? 'Email' : activeTab === '0' ? 'Email' : 'Template',
            templateGroupId: selectedFolder,
            hotelGroupId: activeTab === '0' || selectedFolder === null ? global.hotelGroupId : "00000000-0000-0000-0000-000000000000"
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, editTemplate: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                };

                history.push({ pathname: `/EmailCreation/${data.response ? data.response[0]?.id : ''}${copyTemplateId ? `/${copyTemplateId}` : '/false'}${selectedFolder ? `/${selectedFolder}` : ''}` });
            };
        }, '/api/gms/Template/v1/templates/master', body);
    }

    toggleTab = (val) => {
        const { templates } = this.state;

        if(val === '1' && !templates?.length > 0){
            this.setState({ activeTab: val, block: true, selectedFolder: null }, () => this.getEmails(true));
            return;    
        }

        this.setState({ activeTab: val, pageIndex: 0, selectedFolder: null });
    }

    selectFolder = (id) => {
        const { selectedFolder, templates, emails, activeTab } = this.state;
        const copyEmails = [...emails];
        const copyTemplates = [...templates];
        if(id !== selectedFolder){
            this.setState({ selectedFolder: id, editFolder: null, pageIndex: 0, emails: [], templates: [] }, () => {
                if(emails?.filter(({templateGroupId}) => templateGroupId === id).length === 0 && templates?.filter(({templateGroupId}) => templateGroupId === id).length === 0){
                    this.setState({ block: true, emails: copyEmails, templates: copyTemplates }, () => this.getEmails(activeTab === "1", id));
                }
                else{
                    this.setState({ emails: copyEmails, templates: copyTemplates });
                }
            });
        }
    }

    toggleEditFolder = (id) => {
        const curr = this.state.folders.find((f) => f.id === id);
        this.setState({ editFolder: id, newFolderName: curr.name });
    }

    openDeleteModal = (id) => {
        this.setState({ deleteModal: id });
    }

    toggleDeleteModal = () => {
        this.setState(prev => ({ deleteModal: !prev.deleteModal }));;
    }

    deleteFolder = () => {
        const { deleteModal, folders } = this.state;

        const copy = [...folders];

        this.setState({ block: true, folders: [] }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };
                
                this.setState({ block: false, folders: copy.filter(({id}) => id !== deleteModal), deleteModal: false, selectedFolder: null },
                    () => handleNotification(data,<FormattedMessage id="stripo.successfulyDeleted" />, <FormattedMessage id="AdminUserManagement.Success" />)
                );
            },`/api/gms/Template/v1/templates/group/${deleteModal}`)
        );
    }

    saveNewFolder = () => {
        const { folders, activeTab } = this.state;
        this.setState({ block: true }, () => 
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, editTemplate: false });
                    return;
                }
                if (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    };

                    if(data.response && data.response.length > 0){
                        this.setState({ block: false, folders: [...folders].concat(data.response[0]), selectedFolder: data.response[0]?.id });
                    };
                };
            }, '/api/gms/Template/v1/templates/group', { name: this.props.intl.formatMessage({ id: 'stripo.newFolder' }), hotelGroupId: activeTab === '0' ? global.hotelGroupId : "00000000-0000-0000-0000-000000000000" })
        );
    }

    handleNewFolderName = (e) => {
        this.setState({ newFolderName: e.target?.value })
    }

    saveFolderEdit = () => {
        const { editFolder, newFolderName } = this.state;
        const folders = [...this.state.folders];
        const folder = folders.find(({id}) => id === editFolder);

        if(folder.name !== newFolderName){
            this.setState({ block: false });

            folder.name = newFolderName;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, editTemplate: false });
                    return;
                }
                if (data) {
                    if (data.Errors && data.Errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    };

                    if(data.response && data.response.length > 0){
                        this.setState({ block: false, newFolderName: null, editFolder: null, folders: folders }, () =>
                            handleNotification(data, null, <FormattedMessage id="AdminUserManagement.Success" />)
                        );
                    };
                };
            }, '/api/gms/Template/v1/templates/group', folder);
        }
        else this.setState({ editFolder: null });
    }
    
    handleNewTemplateName = (e) => {
        e.preventDefault();
        this.setState({ newTemplateName: e?.target?.value });
    }

    toggleModal = (e, copyTemplateId, basedTemplate) => {
        e.preventDefault();
        this.setState(prev => ({ modal: !prev.modal, copyTemplateId: copyTemplateId, basedTemplate: basedTemplate }));
    }
    
    deleteTemplate = () => {
        const { deleteTemplateModal, activeTab } = this.state;

        const propName = activeTab === '1' ? 'templates' : 'emails';
        const copy = [...this.state[propName]];

        this.setState({ block: true, [propName]: [] }, () =>{

            deleteAPI(result =>{
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };

                this.setState({ [propName]: copy.filter(({id}) => id !== deleteTemplateModal), error: errorMessage, deleteTemplateModal: false, pageIndex: 0 },
                    () => {
                        handleNotification(data, null, <FormattedMessage id="AdminUserManagement.Success" />);
                        this.getEmails(activeTab === '1');
                    }
                );

            },`/api/gms/Template/v1/templates/master/${deleteTemplateModal}`);
        });
    }

    toggleDeleteTemplateModal = () => {
        this.setState(prev => ({ deleteTemplateModal: !isNaN(prev.deleteModal) }));;
    }

    openDeleteTemplateModal = (id) => {
        this.setState({ deleteTemplateModal: id });
    }

    saveTemplateName = (id, templateGroupId, type, templateName, callback) => {

        const propName = type === 'Email' ? 'emails' : 'templates';
        
        const copy = this.state[propName];

        const curr = copy.find((t) => t.id === id);

        if(curr.name !== templateName){
            this.setState({ block: true }, () => {
                curr.name = templateName;

                const body = {
                    id: id,
                    name: templateName,
                    type: type,
                    templateGroupId: templateGroupId
                };

                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false, editTemplate: false });
                        return;
                    }
                    if (data) {
                        if (data.Errors && data.Errors.length > 0) {
                            this.setState({ block: false }, () => handleNotification(data));
                        }
                        else{
                            this.setState({ block: false, [propName]: copy }, () => {
                                callback();
                                handleNotification(data, null, <FormattedMessage id="AdminUserManagement.Success" />)
                            });
                        }
                    };
                }, '/api/gms/Template/v1/templates/master', body);
            });
        }
        else{
            callback();
        }
    }

    onDragStart = (e, template) => {
        e.dataTransfer.setData("template", JSON.stringify(template))
        this.setState({ dragging: true });
    }

    onDragEnd = (e) => {
        e.preventDefault();
        this.setState({ dragging: false, overFolder: null });
    }

    onDragOver = (e, groupId) => {
        e.preventDefault();
        this.setState({ overFolder: groupId });
    }

    onDrop = (e, groupId) => {
        e.preventDefault();
        const { activeTab, selectedFolder } = this.state;

        const template = JSON.parse(e.dataTransfer.getData('template'));
        
        if(template && template.templateGroupId !== groupId){
            
            const propName = template.type === 'Email' ? 'emails' : 'templates';
            template.templateGroupId = groupId;

            this.setState({ dragging: false, overFolder: null }, () =>
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
                    
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false, editTemplate: false,});
                        return;
                    }
                    if (data) {
                        if (data.Errors && data.Errors.length > 0) {
                            handleNotification(data);
                        };
                        this.setState({ [propName]: [], block: true }, () => this.getEmails(activeTab === '1', selectedFolder));
                    };
                }, '/api/gms/Template/v1/templates/master', template)
            );
        }
        else this.setState({ dragging: false });
    }

    handlePagination = (value) => {
        const { pageIndex, activeTab, selectedFolder } = this.state;
        const propName = activeTab === '1' ? 'templates' : 'emails';
        const copy = [...this.state[propName]];

        if(this.state[propName].filter((t) => t.pageIndex === pageIndex + value && t.templateGroupId === selectedFolder).length === 0){
            this.setState({
                block: true,
                pageIndex: pageIndex + value
            }, () => this.getEmails(activeTab === '1', selectedFolder));
        }
        else this.setState({ [propName]: [] }, () => this.setState({ pageIndex: pageIndex + value, [propName]: copy }));
    }

    render() {
        const { block, error, templates, emails, activeTab, folders, selectedFolder, newTemplateName, modal, overFolder, deleteModal, editFolder, deleteTemplateModal,
        newFolderName, pageIndex, pageSize, totalItems, dragging, basedTemplate, copyTemplateId } = this.state;

        const { intl } = this.props;

        const hasTemplates =
            selectedFolder ?
                activeTab === '0' ?
                    emails?.filter(({templateGroupId}) => templateGroupId === selectedFolder).length > 0
                :
                    templates?.filter(({templateGroupId}) => templateGroupId === selectedFolder).length > 0
            :
                false;
        
        const maxTemplates = templates?.filter((e) => e.pageIndex === pageIndex && e.templateGroupId === selectedFolder)?.length
        const maxEmails = emails?.filter((e) => e.pageIndex === pageIndex && e.templateGroupId === selectedFolder)?.length

        return (
            <StyledCard block={block} error={error}>
                {deleteModal ? 
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "stripo.deleteFolder" })}
                        toggleModal={this.toggleDeleteModal}
                        modal={deleteModal}
                        actionFunction={this.deleteFolder}
                    />
                :''}
                {deleteTemplateModal ? 
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage(activeTab === '0' ? { id: "stripo.deleteEmail" } : { id: "stripo.deleteTemplate" })}
                        toggleModal={this.toggleDeleteTemplateModal}
                        modal={deleteTemplateModal}
                        actionFunction={this.deleteTemplate}
                    />
                :''}

                {modal ?
                    <NewEmailModal
                        toggleModal={this.toggleModal}
                        copyTemplateId={copyTemplateId}
                        newTemplateName={newTemplateName}
                        handleNewTemplateName={this.handleNewTemplateName}
                        newTemplate={this.newTemplate}
                        isNewEmail={activeTab === '0'}
                        folders={folders}
                    />
                :''}

                <Row className='py-2'>
                    <Col>
                        <h4><FormattedMessage id="NavMenu.EmailTemplate"/></h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Nav tabs className="border-0 mb-3">
                            <NavItem>
                                <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                    <b><FormattedMessage id="stripo.Emails"/></b>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                    <b> <FormattedMessage id="stripo.Templates" /></b>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col className='col-2'>
                        {activeTab === '0' ? 
                            <CommonHelper help={<FormattedMessage id="stripo.templateHelp" />} id={'templateHelp'} />
                        :
                            <CommonHelper help={<FormattedMessage id="stripo.emailHelp" />} id={'emailHelp'} />
                        }
                    </Col>
                </Row>
                <Row className='mt-3 mb-4'>
                    <Col>
                        <StripoFolders
                            folders={folders}
                            overFolder={overFolder}
                            hasTemplates={hasTemplates}
                            selectedFolder={selectedFolder}
                            selectFolder={this.selectFolder}
                            onDrop={this.onDrop}
                            onDragOver={this.onDragOver}
                            saveNewFolder={this.saveNewFolder}
                            handleNewFolderName={this.handleNewFolderName}
                            toggleEditFolder={this.toggleEditFolder}
                            toggleDeleteModal={this.openDeleteModal}
                            newFolderName={newFolderName}
                            dragging={dragging}
                            editFolder={editFolder}
                            saveFolderEdit={this.saveFolderEdit}
                            isEmail={activeTab === '0'}
                        />
                    </Col>
                </Row>
                <TabContent activeTab={activeTab}>
                    {activeTab === '0' ?
                        <TabPane tabId="0" className="border-0 my-0 p-0 ">
                            <Row className="mt-2">
                                <Col className='my-2' style={{ flex: '0 0 20%', maxWidth: '20%'}}>
                                    <Card onClick={this.toggleModal} className="border-0 p-3 h-100 shadow">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor:'pointer', background:'#f1eeee', color: '#a4a1a1', height: '350px' }}>
                                            <i style={{ fontSize: '20px' }} className="fas fa-plus"/>
                                        </div>
                                        <Row className='pt-3'>
                                            <Col>
                                                <h5><FormattedMessage id="stripo.createNewTemplate"/></h5>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                {emails.filter((e) => e.templateGroupId === selectedFolder && e.pageIndex === pageIndex)?.map((email, k) =>
                                    <Col className='my-2' key={k} style={{ flex: '0 0 20%', maxWidth: '20%' }}>
                                        <TemplatePreview
                                            type={email.type}
                                            templateGroupId={email.templateGroupId}
                                            key={k}
                                            templateName={email.name}
                                            id={email.id}
                                            thumbnail={email.templateDefaultPreview}
                                            countries={email.templates?.map(({ country }) => country)}
                                            history={this.props.history}
                                            openDeleteTemplateModal={this.openDeleteTemplateModal}
                                            saveTemplateName={this.saveTemplateName}
                                            toggleModal={this.toggleModal}
                                            lastUpdate={email.updatedAt}
                                            intl={intl}
                                            onDragStart={this.onDragStart}
                                            onDragEnd={this.onDragEnd}
                                            template={email}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </TabPane>
                    :''}
                    {activeTab === '1' ?
                        <TabPane tabId="1" className="border-0 my-0 p-0 ">
                            <Row className="mt-2">
                                <Col className='my-2' style={{ flex: '0 0 20%', maxWidth: '20%' }}>
                                    <Card onClick={this.toggleModal} className="border-0 p-3 h-100 shadow">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', background: '#f1eeee', color: '#a4a1a1', height: '350px' }}>
                                            <i style={{ fontSize: '20px' }} className="fas fa-plus" />
                                        </div>
                                        <Row className='pt-3'>
                                            <Col>
                                                <h5><FormattedMessage id="stripo.createNewTemplate" /></h5>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                {templates?.filter((e) => e.templateGroupId === selectedFolder && e.pageIndex === pageIndex)?.map((template, k) =>
                                    <Col className='my-2' key={k} style={{ flex: '0 0 20%', maxWidth: '20%' }}>
                                        <TemplatePreview
                                            type={template.type}
                                            templateGroupId={template.templateGroupId}
                                            key={k}
                                            templateName={template.name}
                                            id={template.id}
                                            thumbnail={template.templateDefaultPreview}
                                            countries={template.templates?.map(({ country }) => country)}
                                            history={this.props.history}
                                            intl={intl}
                                            openDeleteTemplateModal={this.openDeleteTemplateModal}
                                            saveTemplateName={this.saveTemplateName}
                                            toggleModal={this.toggleModal}
                                            lastUpdate={template.updatedAt}
                                            onDragStart={this.onDragStart}
                                            onDragEnd={this.onDragEnd}
                                            template={template}
                                            isTemplate
                                        />
                                    </Col>
                                )}
                            </Row>
                        </TabPane>
                    :''}
                </TabContent>
                {totalItems[selectedFolder] > 0 && (pageSize < totalItems[selectedFolder]) ?
                    <Row className="my-4">
                        <Col>
                            <span className="ml-2">
                                <TotalResults
                                    end={pageIndex * pageSize + (activeTab === '1' ? maxTemplates : maxEmails)}
                                    start={pageIndex * pageSize + 1}
                                    total={totalItems[selectedFolder]}
                                />
                            </span>
                        </Col>

                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={totalItems[selectedFolder] <= ((pageIndex + 1) * pageSize)}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                :''}
            </StyledCard>
        )
    }
}

export default injectIntl(StripoHome)