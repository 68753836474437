import React, { Component } from 'react';
import { BlankCard } from '../../../../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from '../../../../Base/API';
import { handleNotification } from '../../../../Base/Notification';
import { Button, Input, Modal, ModalBody, Row, Col } from 'reactstrap';
import TemplatePreview from '../../../../Marketing/WhatsApp/TemplatePreview';
import { Pagination } from '../../../../Base/PaginationComponents';
import WhatsAppMessage from '../../../../Marketing/WhatsApp/WhatsAppMessage';
import SpeechBubble from '../../../../Marketing/WhatsApp/SpeechBubble';
import SelectMedia from './SelectMedia';

export class SelectTemplate extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            blockMedia: false,
            pageSize: 8,
            pageIndex: 0,
            totalMedia: 0,
            templates: [],
            templateParams: {},
            steps: [
                { step: 1, active: true },
                { step: 2, active: false }
            ],
            currentPage: 1,
            regex: new RegExp(/^\{{[^{{}}]*$|^[^{{}}]*\}}$|\{{[^{{}}]*\}}/g)
        }
    }

    componentDidMount() {
        this.getTemplates();
    }

    getTemplates = (cursor, isPrevious) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                else if (data.response) {
                    const templates = [];

                    data.response[0].data.forEach((template) => {
                        const curr = templates.find(({ name }) => name === template.name);

                        if (curr) {
                            curr.templates.push(template);
                        }
                        else {
                            const newTemplate = {
                                templates: [template],
                                name: template.name
                            };
                            templates.push(newTemplate);
                        }
                    });

                    const hasNext = data.response[0]?.paging && data.response[0]?.paging.next ? true : false;
                    const hasBefore = data.response[0]?.paging && data.response[0]?.paging.previous ? true : false;
                    const previousCursor = data.response[0]?.paging.cursors ? data.response[0]?.paging.cursors.before : null;
                    const nextCursor = data.response[0]?.paging.cursors ? data.response[0]?.paging.cursors.after : null;

                    this.setState({ templates: templates, block: false, hasNext, hasBefore, previousCursor, nextCursor });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/WhatsApp/v1/messageTemplate?limit=8${cursor ? `&pageCursor=${cursor}` : ''}${isPrevious ? `&next=${false}` : ''}`)
    }

    prepareRequest = (e) => {
        e.preventDefault();

        const template = { ...this.state.selectedTemplate };
        const { templateParams } = this.state;

        const templateToSend = {
            name: template.name,
            language: template.language,
            fullTemplate: template,
            components: []
        };

        template.components.forEach(el => {
            if (el.type === 'HEADER' && (el.example || templateParams.header)) {
                const obj = {
                    type: "header",
                    parameters: []
                }


                if (el.format === "TEXT") {
                    obj.parameters = [...this.state.templateParams.header.textToReplace];

                }
                else if (el.example) {
                    obj.parameters.push({ type: el.format?.toLowerCase(), image: { link: el.example.header_handle } });
                }
                else if (templateParams.header.media) {
                    obj.parameters.push({ type: templateParams.header.media.type, media: { id: templateParams.header.media.mediaId } });

                }


                templateToSend.components.push(obj);
            }
            else if (el.type === 'BODY' ) {
                const obj = {
                    type: "body",
                }

                if (this.state.templateParams.body) {
                    obj.parameters = [...this.state.templateParams.body.textToReplace];
                }


                templateToSend.components.push(obj);
            }
            else if (el.type === 'BUTTONS') {
                el.buttons.forEach((button, index) => {

                    if (button.type === "QUICK_REPLY") {
                        const component = {
                            type: "button",
                            subtype: "quick_reply",
                            parametes: [{
                                text: button.text
                            }],                            
                        };


                        templateToSend.components.push(component);
                    }
                    //else {
                    //    component.sub_type = "call_to_action";
                        
                    //    component.parameters.push({
                    //        type: "payload",
                    //        text: button.text,
                    //        payload: button.url
                    //    })
                    //}
                });

            }
        })


        this.props.toggleModal();

        this.props.sendMessage(null, null, null, templateToSend);
    }

    orderComponents = (components) => {
        const res = components.sort((a, b) => {
            if (a.type === "HEADER") return -1;
            if (b.type === "HEADER") return 1;

            if (a.type === "BODY") return -1;
            if (b.type === "BODY") return 1;

            if (a.type === "FOOTER") return -1;
            if (b.type === "FOOTER") return 1;

            if (a.type === "BUTTONS") return -1;
            return 1;
        });
        return res;
    }

    selectTemplate = (template) => {
        var templateParams = {};
        let mediaType = null;

        if (template.components) {
            const header = template.components.find(el => el.type === 'HEADER');
            const body = template.components.find(el => el.type === 'BODY');

            //Find {{params}}
            const headerMatch = header?.text?.match(this.state.regex);
            const bodyMatch = body?.text?.match(this.state.regex);

            if (headerMatch) {
                const toReplace = [];

                headerMatch.forEach((el, idx) => {
                    toReplace.push({
                        id: idx, type: 'text', text: ''
                    })
                })

                templateParams.header = {
                    params: headerMatch,
                    textToReplace: toReplace,
                    text: header.text
                }
            }

            if (bodyMatch) {
                const toReplace = [];

                bodyMatch.forEach((el, idx) => {
                    toReplace.push({
                        id: idx, type: 'text', text: ''
                    })
                })

                templateParams.body = {
                    params: bodyMatch,
                    textToReplace: toReplace,
                    text: body.text
                }
            }


            //Media
            if (header && header.format !== 'TEXT' && !header.hasOwnProperty('example')) {
                templateParams.header = {
                    media: {
                        type: header.format
                    }
                }

                mediaType = header.format;
            }
        }


        this.setState({
            templateParams,
            selectedTemplate: template,
            mediaType
        })
    }

    getHtml = (data) => {
        return data.text.replace(this.state.regex, (match) => {
            const index = data.params.findIndex(el => el === match);

            return `<b key=${index}>${data.textToReplace[index]?.text || match}</b>`;
        })
    }

    getTextReplaced = (data) => {
        return data.text.replace(this.state.regex, (match) => {
            const index = data.params.findIndex(el => el === match);

            return (data.textToReplace[index] && data.textToReplace[index]?.text) || match;
        })
    }

    isBtnDisabled = () => {
        let isDisabled = true;

        if (this.state.steps[0].active === true && this.state.selectedTemplate) {
            isDisabled = false;
        }

        if (this.state.steps[1].active === true) {
            const { templateParams } = this.state;
            const headerParams = templateParams.header ?
                templateParams.header.textToReplace ? templateParams.header.textToReplace.every(el => el.text)
                    : templateParams.header.media?.hasOwnProperty('mediaId')
                    : true;
            const bodyParams = templateParams.body ? templateParams.body.textToReplace?.every(el => el.text) : true;
            
            isDisabled = !(headerParams && bodyParams);
        }

        return isDisabled
    }

    changeStep = (step) => {
        const val = this.state.steps.find(el => el.active === true).step + step;

        var stateCopy = Object.assign({}, this.state);

        stateCopy.steps = stateCopy.steps.slice();
        for (var i = 0; i < stateCopy.steps.length; i++) {
            if (stateCopy.steps[i].step === val) {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = true;
            }
            else {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = false;
            }
        }
        this.setState(stateCopy);
    }

    handlePagination = (pos) => {
        const { hasNext, hasBefore, previousCursor, nextCursor, currentPage } = this.state;

        if (pos > 0 && hasNext) this.setState({ block: true, currentPage: currentPage + 1 }, () => this.getTemplates(nextCursor, false));
        if (pos < 0 && hasBefore) this.setState({ block: true, currentPage: currentPage - 1 }, () => this.getTemplates(previousCursor, true));
    }

    handleChange = (evt, propName, index) => {
        const { value } = evt.target;
        const { selectedTemplate } = this.state;
        const templateParams = { ...this.state.templateParams };

        templateParams[propName].textToReplace[index].text = value;


        //full template
        const compIndex = selectedTemplate.components.findIndex(el => el.type === propName.toUpperCase());

        if (compIndex !== -1) {
            selectedTemplate.components[compIndex].text = this.getTextReplaced(templateParams[propName]);
        }


        this.setState({
            templateParams,
            selectedTemplate
        })
    }

    toggleMedia = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    selectMedia = (selectedMedia) => {
        const { templateParams, selectedTemplate } = this.state;

        templateParams.header.media = selectedMedia;

        //full template
        selectedTemplate.components[0].mediaId = selectedMedia.mediaId;
        selectedTemplate.components[0].media = selectedMedia;

        this.setState({
            templateParams,
            selectedTemplate,
            modal: false
        })
    }

    render() {
        const { templatesModal } = this.props;
        const { steps, templates, templateParams, modal, selectedTemplate } = this.state;


        return (
            <>
                {modal ?
                    <SelectMedia
                        imageModal={modal}
                        toggleModal={this.toggleMedia}
                        submit={this.selectMedia}
                        onlySelect={true}
                        mediaType={this.state.mediaType.charAt(0) + this.state.mediaType.slice(1).toLowerCase()}
                    />
                : ''}

                <Modal isOpen={templatesModal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                    <Button onClick={this.props.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <ModalBody>
                        <BlankCard block={this.state.block} error={this.state.error}>
                            <Row className="mb-2">
                                <Col className="col-1 text-left d-flex align-items-center">
                                    {steps[0].active === false ?
                                        <Button className="btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={this.changeStep.bind(this, -1)}>
                                            <i className="fas fa-angle-left" />
                                        </Button>
                                    : ''}
                                </Col>

                                <Col className="py-1 mb-4">
                                    <ul className="steps">
                                        <li className={'step ' + (steps[0].active === true ? ' step-active' : '')}>
                                            <div className="step-content">
                                                <span className="step-text small">
                                                    <FormattedMessage id="CampaignDetails.Step1" />
                                                </span>
                                                <span className="step-circle"/>
                                            </div>
                                        </li>
                                        <li className={'step ' + (steps[1].active === true ? ' step-active' : '')}>
                                            <div className="step-content">
                                                <span className="step-text small">
                                                    <FormattedMessage id="CampaignDetails.Step2" />
                                                </span>
                                                <span className="step-circle" />
                                            </div>
                                        </li>
                                    </ul>
                                </Col>

                                <Col className="col-1 text-right d-flex align-items-center">
                                    {steps[1].active === true ?
                                        <Button className="btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={(e) => this.prepareRequest(e)} disabled={this.isBtnDisabled()}>
                                            <i className="fas fa-save" />
                                        </Button>
                                        :
                                        <Button className="btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={this.changeStep.bind(this, 1)} disabled={this.isBtnDisabled()}>
                                            <i className="fas fa-angle-right" />
                                        </Button>
                                    }
                                </Col>
                            </Row>


                            {steps[0].active === true ?
                                <>
                                    <h5> <FormattedMessage id="WhatsApp.SelectTemplate" /> </h5>

                                    <Row>
                                        {templates?.map((master, key) =>
                                            <TemplatePreview
                                                history={this.props.history}
                                                languages={master.templates?.map(({ language }) => language)}
                                                name={master.name}
                                                templates={master.templates}
                                                key={key}
                                                orderComponents={this.orderComponents}
                                                components={master.components}
                                                selectable={true}
                                                selectTemplate={this.selectTemplate}
                                                selectedTemplate={this.state.selectedTemplate?.id}
                                                disableNotApprovedTemplates={true}
                                            />
                                        )}
                                    </Row>


                                    {this.state.hasNext || this.state.hasBefore ?
                                        <Row className="my-4">
                                            <Col className="text-right mr-3">
                                                <Pagination
                                                    isPrevDisabled={!this.state.hasBefore}
                                                    isNextDisabled={!this.state.hasNext}
                                                    currentPage={this.state.currentPage}
                                                    handlePrevButton={() => this.handlePagination(-1)}
                                                    handleNextButton={() => this.handlePagination(1)}
                                                />
                                            </Col>
                                        </Row>
                                    : ''}
                                </>
                            : ''}


                            {steps[1].active === true ?
                                <>
                                    {templateParams ?
                                        <Row>
                                            {templateParams.header || templateParams.body ?
                                                <Col>
                                                    {templateParams.header ?
                                                        <div className="mb-5">
                                                            <h5 className="mb-3 pb-2 border-bottom">Header</h5>

                                                            {templateParams.header.params ?
                                                                <div className="pl-3">
                                                                    {templateParams.header.params.map((param, key) =>
                                                                        <Row className="align-items-center" key={key}>
                                                                            <Col sm={2}>
                                                                                Param {param.replace("{{", '').replace("}}", '')}
                                                                            </Col>

                                                                            <Col className="col-10 mb-2">
                                                                                <Input
                                                                                    name={`param${key}`}
                                                                                    onChange={(e) => this.handleChange(e, 'header', key)}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    )}
                                                                </div>
                                                                : templateParams.header.media ?
                                                                    <Row className="pl-3">
                                                                        <Col sm={2}> <FormattedMessage id="MediaManagement.Media" /></Col>
                                                                        <Col>
                                                                            <Button className="btn-host btn-small" onClick={this.toggleMedia}>
                                                                                <FormattedMessage id="WhatsApp.SelectMedia" />
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    : ''}
                                                        </div>
                                                        : ''}

                                                    {templateParams.body ?
                                                        <div>
                                                            <h5 className="mb-3 pb-2 border-bottom">Body</h5>

                                                            <div className="pl-3">
                                                                {templateParams.body.params.map((param, key) =>
                                                                    <Row className="align-items-center" key={key}>
                                                                        <Col sm={2}>
                                                                            Param {param.replace("{{", '').replace("}}", '')}
                                                                        </Col>

                                                                        <Col className="col-10 mb-2">
                                                                            <Input
                                                                                onChange={(e) => this.handleChange(e, 'body', key)}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </Col>
                                            : ''}

                                            <Col>
                                                <h5 className="pb-2 border-bottom"> <FormattedMessage id="WhatsApp.PreviewTemplate" /> </h5>

                                                <WhatsAppMessage height="60vh">
                                                    <SpeechBubble
                                                        showInteractions={false}
                                                        components={selectedTemplate?.components || []}
                                                        fromUser={false}
                                                        maxWidth='70%'
                                                        currentMedia={selectedTemplate?.components?.length > 0 && selectedTemplate?.components[0].media}
                                                    />
                                                </WhatsAppMessage>
                                            </Col>
                                        </Row>
                                    : ''}
                                </>
                                : ''}

                        </BlankCard>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
export default injectIntl(SelectTemplate);