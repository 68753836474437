import React, { Component } from 'react'
import { getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { postAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../Base/ErrorAlert';
import CampaignTargetParams from './CampaignTargetParams';
import CampaignTargetDetails from './CampaignTargetDetails';
import CampaignTargetConfig from './CampaignTargetConfig';

class Targets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pages: [
                { name: 'Config', icon: <i className={`mr-2 fas fa-cogs`}/> },
                { name: 'Details', icon: <i className={`mr-2 fab fa-searchengin`}/> },
                { name: 'Targets', icon: <i className={`mr-2 fas fa-hat-wizard`}/> },
            ],
            activeTab: 'Config',
            campaignTarget: null,
            originalTarget: null,
            block: true,
            error: null
        };
    }

    componentDidMount() {
        const id = this.props.match.params?.id;

        if(id){
            this.getTargetDetails(id);
        }
        else{
            this.setState({ block: false, campaignTarget: { params: {} } });
        }
    }

    getTargetDetails = (id) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    this.setState({ block: false }, () => handleNotification(data));
                }
                if(data.response && data.response[0]){
                    const campaignTarget = data.response[0];

                    if(campaignTarget.params && typeof campaignTarget.params === "string"){
                        campaignTarget.params = JSON.parse(campaignTarget.params);
                    }
                    
                    this.setState({ campaignTarget, originalTarget: campaignTarget, block: false });
                    return;
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Marketing/v1/CampaignTargets/${id}`);
    }

    saveCampaignTargets = (e, newCampaignTarget) => {
        let { campaignTarget } = this.state;
        e.preventDefault();
        this.setState({ block: true }, () => {
            let payload = {...campaignTarget};
            if(newCampaignTarget){
                payload = {...newCampaignTarget};
            }
            payload.params = JSON.stringify(payload.params);

            if(!payload.id){
                payload.active = true;
            }

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                    }
                    if(data.response && data.response[0]){
                        
                        if(!payload.id){
                            window.history.replaceState(null, '', '/Targets/' + data.response[0].id);
                        }
                        campaignTarget = data.response[0];

                        if(campaignTarget.params){
                            campaignTarget.params = JSON.parse(campaignTarget.params);
                        }

                        this.setState({ block: false, campaignTarget }, () => {
                            handleNotification(data, <FormattedMessage id="CampaignTargets.Saved" />, <FormattedMessage id="generic.success" />);
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/Marketing/v1/CampaignTargets`, payload);
        });
    }

    handleChange = (e) => {
        const { campaignTarget } = this.state;
        campaignTarget[e.target.name] = e.target.value;
        this.setState({ campaignTarget });
    }

    handleSelect = (name, combo) => {
        const { campaignTarget } = this.state;
        campaignTarget[name] = combo?.value;
        this.setState({ campaignTarget });
    }

    toggle = (name) => {
        this.setState({ activeTab: name });
    }

    render() {
        const { block, error, pages, activeTab, campaignTarget } = this.state;

        return (
            <div className="border-0 h-100 py-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <Row className='mt-2'>
                        <Col className='col-3'>
                            <div className="side-menu">
                                <Card className="border-0 shadow mb-3 p-3">
                                <h5 className='m-0'>
                                    {campaignTarget?.name ?? <FormattedMessage id="CampaignTargets.New"/>}
                                </h5>
                                {campaignTarget?.description ?
                                    <div className='text-muted mt-2' style={{ fontSize: '0.8em' }}>
                                        {campaignTarget?.description}
                                    </div>
                                :''}
                                </Card>

                                <Nav tabs vertical className='mt-4 border-0'>
                                    <Card className="bg-white p-2 border-0 mb-3 shadow">
                                        {pages.map((page, key) =>
                                            <NavItem key={key} className="pb-2">
                                                <NavLink className={activeTab === page.name ? 'text-primary border-0 gptMenuEntry active' : 'bg-white border-0 gptMenuEntry'} onClick={_ => this.toggle(page.name)} style={{ cursor: 'pointer' }} >
                                                    {activeTab === page.name ?
                                                        <div style={{
                                                            'left': '0',
                                                            'position': 'absolute',
                                                            'borderRadius': '0px 30px 30px 0px',
                                                            'width': '7px',
                                                            'height': '14px',
                                                            'background': '#0667FF 0% 0% no-repeat padding-box',
                                                            'opacity': '1',
                                                            'marginTop': '4px'
                                                        }}/>
                                                    : ''}
                                                    <div className='d-flex align-items-center'>
                                                        {page.icon}
                                                        <FormattedMessage id={"CampaignTargets."+ page.name}/>
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        )}
                                    </Card>
                                </Nav>
                            </div>
                        </Col>
                        <Col>
                            {campaignTarget ?
                                <TabContent activeTab={activeTab} className='border-0'>
                                    <TabPane tabId='Config' className='border-0 p-0'>
                                        {activeTab === 'Config' ?
                                            <CampaignTargetConfig
                                                {...this.state}
                                                campaignTarget={campaignTarget}
                                                saveCampaignTargets={this.saveCampaignTargets}
                                                handleChange={this.handleChange}
                                                handleSelect={this.handleSelect}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId='Details' className='border-0 p-0'>
                                        {activeTab === 'Details' ?
                                            <CampaignTargetDetails
                                                {...this.state}
                                                campaignTarget={campaignTarget}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId='Targets' className='border-0 p-0'>
                                        {activeTab === 'Targets' ?
                                            <CampaignTargetParams
                                                {...this.state}
                                                campaignTarget={campaignTarget}
                                                saveCampaignTargets={this.saveCampaignTargets}
                                            />
                                        :''}
                                    </TabPane>
                                </TabContent>
                            :''}
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(Targets)