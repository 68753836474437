import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { getAPI, postAPI } from "../Base/API";
import { StyledCard } from "../Base/CommonUIComponents";
import { CustomTable } from '../Base/CustomTable';

export class ManageRegEx extends Component {

    constructor(props) {
        super(props);
        this.getRegEx = this.getRegEx.bind(this);
        this.state = {
            block: true,
            ruleList: [],
            topic: null
        };
    }

    componentDidMount() {
        this.getRegEx();
    }

    getRegEx() {


        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ ruleList: data.response });
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/gms/DataQuality/v1/regularExpressionRule');
    }



    render() {
        const { block, error, ruleList, currentPage, resultsPerPage, hotel } = this.state;
        const columns = [
            {
                dataField: "code",
                text: this.props.intl.formatMessage({
                    id: "ManageRegEx.code"
                }),
                sort: true
            },
            {
                dataField: "description",
                text: this.props.intl.formatMessage({
                    id: "ManageRegEx.description"
                }),
                sort: true
            },
            {
                dataField: "regularExpressionType",
                text: this.props.intl.formatMessage({
                    id: "ManageRegEx.regularExpressionType"
                }),
                sort: true
            }
        ];
        const selectRow = {
            mode: "radio",
            hideSelectColumn: true,
            bgColor: "#f8f9fa",
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push({
                    pathname: "/RegExDetail/" + row.id,
                    rule: row
                })
            
            }
        };

        return (

            <StyledCard block={block} error={error} icon={'fas fa-not-equal'} title={'ManageRegEx.title'}>
                <Row>
                    <Col className="text-right">
                        <Link to="/RegExDetail">
                        <Button className="btn btn-sm btn-host">
                            <i className="fas fa-plus"></i>
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={ruleList ? ruleList : []}
                                columns={columns}
                                page={currentPage}
                                sizePerPage={resultsPerPage}
                                totalSize={ruleList && ruleList.length}
                                shadow={false}
                                selectRow={selectRow}
                                remote={false}
                                hasPagination={true}
                                search={{ searchFormatted: true }}
                                hideSizePerPage={false}
                                showTotal={true}
                            />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(ManageRegEx)
