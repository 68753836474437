import React, { Component } from 'react'
import { Button, Col, Row, Input, Nav, NavItem, NavLink, PopoverBody } from 'reactstrap';
import { EmptyCard, FormatAmountNumber, KebabMenu } from '../Base/CommonUIComponents';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import SalesActionModal from './SalesActionModal';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { deleteAPI, putAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getSalesDealActionTypes } from '../Base/ReferenceDataFunctions';
import CustomToolTip from '../Base/CustomToolTip';
import SalesDealDetailProposal from './SalesDealDetailProposal';

class SalesDealDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deal: null,
            dealModal: false,
            confirmActionModal: false,
            source: null,
            selectedPipeline: null,
            specificEditStep: null,
            actionTypesOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Email` }), icon: 'far fa-envelope', value: 'Email' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Call` }), icon: 'fas fa-phone', value: 'Call' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Note` }), icon: 'far fa-sticky-note', value: 'Note' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.File` }), icon: 'fas fa-file', value: 'File' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Meeting` }), icon: 'far fa-building', value: 'Meeting' }
            ],
            actionStatusOptions: [
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Scheduled` }), value: 'Scheduled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Cancelled` }), value: 'Cancelled' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.OnGoing` }), value: 'OnGoing' },
                { label: this.props.intl.formatMessage({ id: `SalesProcess.Finished` }), value: 'Finished' }
            ],
            newAction: {
                type: "Note",
                title: this.props.intl.formatMessage({ id: `SalesProcess.NewNote` }),
                summary: this.props.intl.formatMessage({ id: `SalesProcess.NewNoteSummary` }),
                content: this.props.intl.formatMessage({ id: `SalesProcess.NewNoteContent` }),
                status: "Scheduled",
                createdAt: moment().format('YYYY/MM/DD'),
                startDate: moment().format('YYYY/MM/DD')
            },
            activeActionTab: 'Note',
            actionModal: false,
            statusReason: "",
            activeTab: 'Overview',
            customers: [],
            orderedActions: [],
            focusActions: [],
            proposalCollapse: {}
        };
    }

    componentDidMount(){
        const { deals, selectedDealId, salesSources, salesPipelines } = this.props;

        const deal = deals?.find(({id}) => id === selectedDealId);
        
        if(!deal)
            return;

        const selectedPipeline = salesPipelines?.find(({ stages }) => stages?.some(({ value }) => value === deal.salesPipelineStageId));
        const customers = [];

        if(deal.customer){
            customers.push({
                value: deal.customer.id,
                label: deal.customer.name,
                ...deal.customer
            });
        }
        if(deal.mainContact){
            customers.push({
                value: deal.mainContact.id,
                label: deal.mainContact.name,
                ...deal.mainContact
            });
        }
        if(deal.salesDealProfile){
            deal.salesDealProfile.forEach(p => {
                customers.push({
                    value: p.profileId,
                    label: p.name,
                    ...p
                });
            });
        }

        const source = salesSources?.find(s => s.value === deal.salesSourceId);
        
        const actionableActionTypes = getSalesDealActionTypes(this.props.intl, undefined, true);

        const orderedActions = deal.salesDealActions
            ?.sort((a, b) => moment(a.startDate).isAfter(b.startDate, 'day') ? -1 : 1) || []; // order by start date

        const focusActions = deal.salesDealActions?.filter(({type, startDate, endDate, status}) =>
            actionableActionTypes.some(({value}) => value === type) &&
            (
                (
                    (startDate && moment().isSameOrBefore(startDate, 'day')) || // Vai decorrer
                    (endDate && moment().isSameOrBefore(endDate, 'day')) // A decorrer
                ) ||
                (
                    (status === "Scheduled" || status === "OnGoing") &&
                    startDate &&
                    moment().isSameOrAfter(startDate, 'day')
                )
            )
        )?.sort((a, b) => moment(a.startDate).isAfter(b.startDate, 'day') ? -1 : 1) || [];


        this.setState({ deal, customers, selectedPipeline, orderedActions, focusActions, source });
    }

    changeDealStatus = (e, status) => {
        e.preventDefault();
        if (status) {
            this.setState({ block: true }, () => {
                putAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }
                        if (data.data && data.data.length > 0) {
                            handleNotification(data, <FormattedMessage id="SalesDeal.StatusChanged" />, <FormattedMessage id="generic.success" />);

                            const { deal } = this.state;

                            if (!deal.salesDealActions) deal.salesDealActions = [];

                            deal.salesDealActions.unshift(data.data[0].salesDealActions[0]);
                            deal.statusReason = data.data[0].statusReason;

                            this.toggleConfirmActionModal();
                            this.setState({ deal, error: errorMessage, block: false });

                            return;
                        }   
                    }

                    this.toggleConfirmActionModal();
                    this.setState({ error: errorMessage, block: false });
                }, `/api/gms/SalesProcess/v1/deals/${this.state.deal.id}/status/${status}`, this.state.statusReason);
            });
        }        
    }

    deleteAction = (e, id, status) => {
        this.setState({ block: true }, () => {
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                        return
                    }

                    this.updateActions(this.state.selectedAction, true);
                    this.toggleConfirmActionModal();

                    this.setState({ block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/action/${id}/status/${status}`);
        })
    }

    toggleEdit = (specificEditStep) => {
        this.setState({ dealModal: !this.state.dealModal, specificEditStep: isNaN(specificEditStep) ? null : specificEditStep });
    }

    handleStageChange = (id) => {
        this.setState({ 
            deal: {...this.state.deal, salesPipelineStageId: id }
        });
    }

    handleActionComboChange = (e, name) => {
        const value = e?.value
        this.setState({ deal: {...this.state.deal, [name]: value } });
    }

    handleActionChange = (e) => {
        const { name, value } = e;
        this.setState({ deal: {...this.state.deal, [name]: value } });
    }

    toggleActionTab = activeActionTab => {
        this.setState({ newAction: {...this.state.newAction, type: activeActionTab} })
    }

    toggleActionModal = (action) => {
        this.setState({
            actionModal: !this.state.actionModal,
            selectedAction: action
        })
    }

    toggleConfirmActionModal = (action, status) => {
        this.setState({
            confirmActionModal: !this.state.confirmActionModal,
            selectedAction: action,
            changeToStatus: status
        })
    }

    updateDeal = (dealUpdated) => {
        const deal = Object.assign(this.state.deal, dealUpdated);

        this.setState({ deal });
    }

    updateActions = (action, isToRemove) => {
        this.setState({ block: true }, () => {
            const { deal } = this.state;

            if (!deal.salesDealActions) deal.salesDealActions = [];

            const index = deal.salesDealActions.findIndex(el => el.id === action.id);

            if (index !== -1) {
                if (isToRemove) {
                    deal.salesDealActions.splice(index, 1);
                }
                else {
                    deal.salesDealActions[index] = action;
                }
            }
            else {
                deal.salesDealActions.unshift(action);
            }

            this.setState({ deal, block: false });
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    toggleTab = (tab) => {
        if(tab !== this.state.activeTab)
            this.setState({ activeTab: tab });
    }

    toggleProposalCalendarModal = () => {
        this.setState({ proposalCalendarModal: !this.state.proposalCalendarModal });
    }

    toggleProposalCollapse = (proposalId, el) => {
        const { proposalCollapse } = this.state;
        
        if(!proposalCollapse[proposalId]){
            proposalCollapse[proposalId] = {}
        }

        proposalCollapse[proposalId][el] = (proposalCollapse[proposalId][el] ? false : true);

        this.setState({ proposalCollapse })
    }

    updateDeal = (deal) => {
        this.setState({ deal });
    }

    render() {
        const { deal, source, selectedPipeline, block, error, selectedAction, confirmActionModal, actionModal, dealModal, changeToStatus, activeTab, customers, orderedActions,
            focusActions, specificEditStep } = this.state;
        const { selectDealDetail, intl, salesSources, salesUsers, salesPipelines } = this.props;

        return (
            deal ?
                <>
                    <>
                        {dealModal ?
                            <SalesDealModal
                                deal={deal}
                                modal={dealModal}
                                salesUsers={salesUsers}
                                toggleModal={this.toggleEdit}
                                salesStages={selectedPipeline.stages}
                                salesSources={salesSources}
                                updateDeal={this.updateDeal}
                                salesPipelineId={selectedPipeline.id}
                                salesPipelines={salesPipelines}
                                specificEditStep={specificEditStep}
                            />
                        : ''}

                        {actionModal ?
                            <SalesActionModal
                                customers={customers}
                                toggle={_ => this.toggleActionModal()}
                                salesUsers={salesUsers}
                                salesDealId={deal.id}
                                salesPipelineStageId={deal.salesPipelineStageId}
                                selectedAction={selectedAction || null}
                                updateActions={this.updateActions}
                            />
                        : ''}


                        {confirmActionModal ?
                            <ConfirmActionModal
                                modal={confirmActionModal}
                                toggleModal={() => this.toggleConfirmActionModal()}
                                actionFunction={(e) => selectedAction ? this.deleteAction(e, selectedAction.id, changeToStatus) : this.changeDealStatus(e, changeToStatus)}
                                text={selectedAction ? <FormattedMessage id="SalesDeal.ConfirmDeleteText" values={{ action: selectedAction.title }} /> : <FormattedMessage id={`SalesDeal.ConfirmChangeStatusTo${changeToStatus}`} />}
                                block={block}
                            >
                                {changeToStatus === 'Cancelled' ?
                                    <div className="px-4 mb-4">
                                        <div className="title-sm"> <FormattedMessage id="SalesDeal.CancellationReason" /></div>
                                        <Input
                                            required
                                            type="textarea"
                                            name="statusReason"
                                            placeholder=""
                                            onChange={this.handleChange}
                                            maxLength={100}
                                        />
                                    </div>
                                : ''}
                            </ConfirmActionModal>
                        : ''}
                    </>
                    <EmptyCard block={block} error={error} className='border-0 shadow p-3 bg-white'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div style={{ width: '55%' }} className='d-flex align-items-start justify-content-start'>
                                <div onClick={_ => selectDealDetail()} className='p-3 cursor-pointer'>
                                    <i className='fas fa-chevron-left'/>
                                </div>
                                <div className='ml-2'>
                                    <div>
                                        <h5 className='m-0'>{deal.name}</h5>
                                    </div>
                                    {deal.customer ?
                                        <div className='mt-1'>
                                            <span className='text-muted hover-underline'>
                                                <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                    {deal.customer.name}
                                                </Link>
                                            </span>
                                        </div>
                                    :''}
                                </div>
                            </div>
                            <div style={{ width: '45%' }} className='d-flex align-items-center justify-content-end'>
                                <div style={{ width: '20%' }} className='mr-4'>
                                    <div className='title-sm mb-0'>
                                        <FormattedMessage id="SalesDeal.Status"/>
                                    </div>
                                    <div>
                                        {["WaitingClient", "WaitingHotel", "Finished"].includes(deal.status) ?
                                            <div>
                                                <FormattedMessage id={`ReferenceDataFunctions.${deal.status}`}/>
                                            </div>
                                        :  deal.status === "Effective" ?
                                            <div>
                                                <FormattedMessage id="SalesProcess.Effective"/>
                                            </div>
                                        :
                                            <div>
                                                <FormattedMessage id="SalesProcess.Lost"/>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className='position-relative'>
                                        <Button onClick={_ => this.toggleConfirmActionModal(null, 'Finished')} className="btn btn-host btn-sm" style={{ background: '#36ad51', borderColor: '#36ad51', borderRadius: '0.2rem 0 0 0.2rem' }}>
                                            <FormattedMessage id="SalesDeal.WinDeal"/>
                                            <i className="fas fa-trophy ml-2"></i>
                                        </Button>
                                        <Button onClick={_ => this.setState({ statusOptions: !this.state.statusOptions })} className="btn btn-host btn-sm" id="StatusOptions" style={{ borderRadius: '0 0.2rem 0.2rem 0', background: '#bfbfbf', border: '1px solid #bfbfbf' }}>
                                            <i className='fas fa-chevron-down'/>
                                        </Button>
                                        {this.state.statusOptions ?
                                            <div style={{ borderRadius: '5px', zIndex: '1050', right: '0', width: 'max-content', fontSize: '0.95em' }} className='shadow bg-white mt-1 position-absolute'>
                                                <div>
                                                    {deal.status !== "WaitingHotel" ?
                                                        <>
                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingHotel')}>
                                                                <i className="fas fa-users mr-2"/>
                                                                <FormattedMessage id="ReferenceDataFunctions.WaitingHotel"/>
                                                            </div>
                                                        </>
                                                    :''}
                                                    {deal.status !== "WaitingClient" ?
                                                        <>
                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'WaitingClient')}>
                                                                <i className="fas fa-hotel mr-2"/> 
                                                                <FormattedMessage id="ReferenceDataFunctions.WaitingClient"/>
                                                            </div>
                                                        </>
                                                    :''}
                                                    {deal.status !== "Cancelled" ?
                                                        <>
                                                            <hr className='m-0'/>
                                                            <div className='changeStatusOption p-2 d-flex align-items-center justify-content-between text-danger cursor-pointer' onClick={_ => this.toggleConfirmActionModal(null, 'Cancelled')}>
                                                                <i className="fas fa-handshake-slash mr-2"/>
                                                                <FormattedMessage id="SalesDeal.LoseDeal"/>
                                                            </div>
                                                        </>
                                                    :''}
                                                </div>
                                            </div>
                                        :''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Nav tabs className="my-3">
                            <NavItem>
                                <NavLink className={activeTab === 'Overview' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Overview')} style={{ cursor: "pointer" }}>
                                    <i className="fas fa-briefcase mr-2"/>
                                    <FormattedMessage id="SalesDeal.Overview"/>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 'Actions' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('Actions')} style={{ cursor: "pointer" }}>
                                    <i className="fas fa-play mr-2"/>
                                    <FormattedMessage id="SalesDeal.Actions"/>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 'Proposal' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('Proposal')} style={{ cursor: "pointer" }}>
                                    <i className="fas fa-receipt mr-2"/>
                                    <FormattedMessage id="SalesDeal.Proposal"/>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <div>
                            {activeTab === 'Overview' ?
                                <Row className='mt-2 h-100'>
                                    <Col className='pt-2 col-9'>
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div>
                                                    <h5><FormattedMessage id="SalesProcess.Focus"/></h5>
                                                </div>
                                                <div>
                                                    <Button onClick={_ => this.toggleActionModal()} className="btn btn-host btn-sm">
                                                        <i className="far fa-file mr-2"/>
                                                        <FormattedMessage id="SalesProcess.NewAction"/>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                {focusActions && focusActions.length > 0 ?
                                                    focusActions.map((action, key, arr) =>
                                                        <div key={key}>
                                                            <ActionCard
                                                                action={action}
                                                                k={key}
                                                                intl={intl}
                                                                focusActions={arr}
                                                                editFunction={() => this.toggleActionModal(action)}
                                                                deleteFunction={() => this.toggleConfirmActionModal(action, 'Cancelled')}
                                                                interveningUsers={deal ? deal.salesDealUser : []}
                                                                salesUsers={salesUsers}
                                                            />
                                                        </div>
                                                    )
                                                :
                                                    <div className='text-muted text-center mt-4'>
                                                        <FormattedMessage id="SalesProcess.NothingToFocus"/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='pt-2 col-3 px-0 d-flex justify-content-between h-100 flex-column' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                                        <div>
                                            <div className='d-flex aling-items-center justify-content-between px-3'>
                                                <div className=''>
                                                    <h5 className='m-0'><FormattedMessage id="SalesDeal.DealResume"/></h5>
                                                </div>
                                                <div>
                                                    <Button onClick={this.toggleEdit} className="btn btn-host btn-sm">
                                                        <i className="fas fa-edit"/>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='px-3 pt-3 text-left'>
                                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                                    <div>
                                                        <b><FormattedMessage id="SalesProcess.DealDetails"/></b>
                                                    </div>
                                                    <div className='px-2'>
                                                        <Row className='w-100'>
                                                            {source?.label ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.Source"/>
                                                                    </div>
                                                                    <div>
                                                                        {source.label}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    {selectedPipeline.label}
                                                                </div>
                                                                <div className='mt-1 h-100 d-flex w-100 px-1'>
                                                                    {selectedPipeline.stages ?
                                                                        selectedPipeline?.stages.map((stage, key) =>{
                                                                            const selectedStage = stage.value === deal.salesPipelineStageId;
                                                                            const stageName = deal?.salesPipelineStage?.name;
                                                                            
                                                                            return (
                                                                                <>
                                                                                    <div id={`stage-${key}`} key={key} className={`h-100 ${selectedStage ? 'cursor-pointer' : ''}`} style={{ width: '100%' }}>
                                                                                        <div className='text-center pipeline smallPipeline'>
                                                                                            <div className={`arrow top smallPipeline noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                            <div className={`arrow bottom noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {selectedStage && stageName ? 
                                                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`stage-${key}`}>
                                                                                            <PopoverBody>
                                                                                                {stageName}
                                                                                            </PopoverBody>
                                                                                        </CustomToolTip>
                                                                                    :''}
                                                                                </>
                                                                            )}
                                                                        )
                                                                    :''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.negotiationStartDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.NegotiationStartDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.negotiationStartDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.negotiationEndDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.NegotiationEndDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.negotiationEndDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.expectedEndDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.ExpectedEndDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.expectedEndDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.expectedValue ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.ExpectedValue"/>
                                                                    </div>
                                                                    <div>
                                                                        {<FormatAmountNumber value={deal.expectedValue}/>}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.businessStartDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.BusinessStartDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.businessStartDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.businessEndDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.BusinessEndDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.businessEndDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.effectiveValue ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.EffectiveValue"/>
                                                                    </div>
                                                                    <div>
                                                                        {<FormatAmountNumber value={deal.effectiveValue}/>}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.budget ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.Budget"/>
                                                                    </div>
                                                                    <div>
                                                                        <FormatAmountNumber value={deal.budget}/>
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.totalRevenue ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.TotalRevenue"/>
                                                                    </div>
                                                                    <div>
                                                                        <FormatAmountNumber value={deal.totalRevenue}/>
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.hotels ?
                                                                <Col className='col-12 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.RelatedHotels"/>
                                                                    </div>
                                                                    <div>
                                                                        {deal.hotels?.map((hotel, key) =>
                                                                            <React.Fragment key={key}>
                                                                                {key ?
                                                                                    <span>,</span>
                                                                                :''}
                                                                                <span className={key ? 'ml-1' : ''}>
                                                                                    {hotel.name || hotel.id}
                                                                                </span>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.summary ?
                                                                <Col className='col-12 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.Summary"/>
                                                                    </div>
                                                                    <div>
                                                                        {deal.summary}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <b><FormattedMessage id="SalesProcess.Clients" /></b>
                                                        <Button onClick={() => this.toggleEdit(3)} className="btn btn-host btn-sm" style={{ visibility: "hidden" }}>
                                                            <i className="fas fa-edit"/>
                                                        </Button>
                                                    </div>
                                                    <Row className='px-2 w-100'>
                                                        {deal.customer?.name ?
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                <FormattedMessage id="SalesProcess.Customer"/>
                                                                </div>
                                                                <div>
                                                                    <span className='text-muted hover-underline'>
                                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                                            {deal.customer.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        :''}
                                                        {deal.mainContact?.name ?
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.MainContact"/>
                                                                </div>
                                                                <div>
                                                                    <span className='text-muted hover-underline'>
                                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.mainContact.id }}>
                                                                            {deal.mainContact.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        :''}
                                                        {deal.salesDealProfile?.map((u, k) => {
                                                            const user = customers.find(({id}) => id === u.id);
                                                            return (
                                                                <Col key={k} className={`col-6 mt-2`}>
                                                                    <div className='title-sm'>{u.role}</div>
                                                                    {user?.name ?
                                                                        <div>{`${user.name}`}</div>
                                                                    :'-'}
                                                                </Col>
                                                            )}
                                                        )}
                                                    </Row>
                                                </div>
                                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <b><FormattedMessage id="SalesProcess.Staff"/></b>
                                                        <Button onClick={() => this.toggleEdit(4)} className="btn btn-host btn-sm" style={{ visibility: "hidden" }}>
                                                            <i className="fas fa-edit"/>
                                                        </Button>
                                                    </div>
                                                    <Row className='px-2 w-100'>
                                                        {deal.salesDealUser?.map((u, k) => {
                                                            const user = salesUsers.find(({id}) => id === u.userId);
                                                            return (
                                                                <Col key={k} className={`col-6 mt-2`}>
                                                                    <div className='title-sm'>{u.role}</div>
                                                                    {user?.firstName && user.lastName ?
                                                                        <div>{`${user.firstName} ${user.lastName}`}</div>
                                                                    :'-'}
                                                                </Col>
                                                            )}
                                                        )}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        {deal.salesDealProposals && deal.salesDealProposals.length ?
                                            <div className='px-3'>
                                                <div className='mt-2 p-3 shadow bg-white' style={{ position: 'sticky', bottom: '0' }}>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='d-flex align-items-center justify-content-start'>
                                                            <div>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesDeal.Rooms"/>
                                                                </div>
                                                                <div>
                                                                    {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfRooms??0), 0) ?? 0}
                                                                </div>
                                                            </div>
                                                            <div className='ml-4'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesDeal.Spaces"/>
                                                                </div>
                                                                <div>
                                                                    {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfSpaces??0), 0) ?? 0}
                                                                </div>
                                                            </div>
                                                            <div className='ml-4'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesDeal.Equipment"/>
                                                                </div>
                                                                <div>
                                                                    {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfEquipment??0), 0) ?? 0}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-right'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SalesDeal.Total"/>
                                                            </div>
                                                            <div style={{ fontSize: '1.2em' }}>
                                                                <b>
                                                                    <FormatAmountNumber
                                                                        currency={global.hotelCurrency}
                                                                        value={deal.salesDealProposals.reduce((prev, curr) => prev + (curr.totalValue??0), 0) ?? 0}
                                                                    />
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :''}
                                    </Col>
                                </Row>
                            : activeTab === 'Actions' ?
                                <Row className='h-100'>
                                    <Col className='pt-2 col-9'>
                                        <div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div>
                                                    <h5><FormattedMessage id="SalesProcess.Actions"/></h5>
                                                </div>
                                                <div>
                                                    <Button onClick={_ => this.toggleActionModal()} className="btn btn-host btn-sm">
                                                        <i className="far fa-file mr-2"/>
                                                        <FormattedMessage id="SalesProcess.NewAction"/>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                {orderedActions && orderedActions.length > 0 ?
                                                    orderedActions.map((action, key, arr) =>
                                                        <div key={key}>
                                                            <ActionCard
                                                                action={action}
                                                                k={key}
                                                                intl={intl}
                                                                focusActions={arr}
                                                                editFunction={() => this.toggleActionModal(action)}
                                                                deleteFunction={() => this.toggleConfirmActionModal(action, 'Cancelled')}
                                                                interveningUsers={deal ? deal.salesDealUser : []}
                                                                salesUsers={salesUsers}
                                                            />
                                                        </div>
                                                    )
                                                :
                                                    <div className='text-muted text-center mt-4'>
                                                        <FormattedMessage id="SalesProcess.NoActions"/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className='pt-2 col-3 px-0 d-flex justify-content-between h-100 flex-column' style={{ textAlign: 'center', top: '0', position: 'sticky' }}>
                                        <div>
                                            <div className='d-flex aling-items-center justify-content-between px-3'>
                                                <div className=''>
                                                    <h5 className='m-0'><FormattedMessage id="SalesDeal.DealResume"/></h5>
                                                </div>
                                                <div>
                                                    <Button onClick={this.toggleEdit} className="btn btn-host btn-sm">
                                                        <i className="fas fa-edit"/>
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className='px-3 pt-3 text-left'>
                                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                                    <div>
                                                        <b><FormattedMessage id="SalesProcess.DealDetails"/></b>
                                                    </div>
                                                    <div className='px-2'>
                                                        <Row className='w-100'>
                                                            {source?.label ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.Source"/>
                                                                    </div>
                                                                    <div>
                                                                        {source.label}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    {selectedPipeline.label}
                                                                </div>
                                                                <div className='mt-1 h-100 d-flex w-100 px-1'>
                                                                    {selectedPipeline.stages ?
                                                                        selectedPipeline?.stages.map((stage, key) =>{
                                                                            const selectedStage = stage.value === deal.salesPipelineStageId;
                                                                            const stageName = deal?.salesPipelineStage?.name;
                                                                            
                                                                            return (
                                                                                <>
                                                                                    <div id={`stage-${key}`} key={key} className={`h-100 ${selectedStage ? 'cursor-pointer' : ''}`} style={{ width: '100%' }}>
                                                                                        <div className='text-center pipeline smallPipeline'>
                                                                                            <div className={`arrow top smallPipeline noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                            <div className={`arrow bottom noShadow ${selectedStage ? 'selectedStage' : ''}`}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {selectedStage && stageName ? 
                                                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="bottom" target={`stage-${key}`}>
                                                                                            <PopoverBody>
                                                                                                {stageName}
                                                                                            </PopoverBody>
                                                                                        </CustomToolTip>
                                                                                    :''}
                                                                                </>
                                                                            )}
                                                                        )
                                                                    :''}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.negotiationStartDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.NegotiationStartDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.negotiationStartDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.negotiationEndDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.NegotiationEndDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.negotiationEndDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.expectedEndDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.ExpectedEndDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.expectedEndDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.expectedValue ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.ExpectedValue"/>
                                                                    </div>
                                                                    <div>
                                                                        {<FormatAmountNumber value={deal.expectedValue}/>}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.businessStartDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.BusinessStartDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.businessStartDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.businessEndDate ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.BusinessEndDate"/>
                                                                    </div>
                                                                    <div>
                                                                        {moment(deal.businessEndDate).format('YYYY-MM-DD')}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.effectiveValue ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.EffectiveValue"/>
                                                                    </div>
                                                                    <div>
                                                                        {<FormatAmountNumber value={deal.effectiveValue}/>}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                            {deal.budget ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.Budget"/>
                                                                    </div>
                                                                    <div>
                                                                        <FormatAmountNumber value={deal.budget}/>
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.totalRevenue ?
                                                                <Col className='col-6 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.TotalRevenue"/>
                                                                    </div>
                                                                    <div>
                                                                        <FormatAmountNumber value={deal.totalRevenue}/>
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.hotels ?
                                                                <Col className='col-12 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.RelatedHotels"/>
                                                                    </div>
                                                                    <div>
                                                                        {deal.hotels?.map((hotel, key) =>
                                                                            <React.Fragment key={key}>
                                                                                {key ?
                                                                                    <span>,</span>
                                                                                :''}
                                                                                <span className={key ? 'ml-1' : ''}>
                                                                                    {hotel.name || hotel.id}
                                                                                </span>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                        <Row className='w-100'>
                                                            {deal.summary ?
                                                                <Col className='col-12 mt-2'>
                                                                    <div className='title-sm'>
                                                                        <FormattedMessage id="SalesProcess.Summary"/>
                                                                    </div>
                                                                    <div>
                                                                        {deal.summary}
                                                                    </div>
                                                                </Col>
                                                            :''}
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <b><FormattedMessage id="SalesProcess.Clients" /></b>
                                                        <Button onClick={() => this.toggleEdit(3)} className="btn btn-host btn-sm" style={{ visibility: "hidden" }}>
                                                            <i className="fas fa-edit"/>
                                                        </Button>
                                                    </div>
                                                    <Row className='px-2 w-100'>
                                                        {deal.customer?.name ?
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                <FormattedMessage id="SalesProcess.Customer"/>
                                                                </div>
                                                                <div>
                                                                    <span className='text-muted hover-underline'>
                                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                                            {deal.customer.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        :''}
                                                        {deal.mainContact?.name ?
                                                            <Col className='col-6 mt-2'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesProcess.MainContact"/>
                                                                </div>
                                                                <div>
                                                                    <span className='text-muted hover-underline'>
                                                                        <Link to={{ pathname: "/ProfileDetails/" + deal.mainContact.id }}>
                                                                            {deal.mainContact.name}
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        :''}
                                                        {deal.salesDealProfile?.map((u, k) => {
                                                            const user = customers.find(({id}) => id === u.id);
                                                            return (
                                                                <Col key={k} className={`col-6 mt-2`}>
                                                                    <div className='title-sm'>{u.role}</div>
                                                                    {user?.name ?
                                                                        <div>{`${user.name}`}</div>
                                                                    :'-'}
                                                                </Col>
                                                            )}
                                                        )}
                                                    </Row>
                                                </div>
                                                <div className='shadow w-100 mb-3 bg-white p-3'>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <b><FormattedMessage id="SalesProcess.Staff"/></b>
                                                        <Button onClick={() => this.toggleEdit(4)} className="btn btn-host btn-sm" style={{ visibility: "hidden" }}>
                                                            <i className="fas fa-edit"/>
                                                        </Button>
                                                    </div>
                                                    <Row className='px-2 w-100'>
                                                        {deal.salesDealUser?.map((u, k) => {
                                                            const user = salesUsers.find(({id}) => id === u.userId);
                                                            return (
                                                                <Col key={k} className={`col-6 mt-2`}>
                                                                    <div className='title-sm'>{u.role}</div>
                                                                    {user?.firstName && user.lastName ?
                                                                        <div>{`${user.firstName} ${user.lastName}`}</div>
                                                                    :'-'}
                                                                </Col>
                                                            )}
                                                        )}
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        {deal.salesDealProposals && deal.salesDealProposals.length ?
                                            <div className='px-3'>
                                                <div className='mt-2 p-3 shadow bg-white' style={{ position: 'sticky', bottom: '0' }}>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='d-flex align-items-center justify-content-start'>
                                                            <div>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesDeal.Rooms"/>
                                                                </div>
                                                                <div>
                                                                    {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfRooms??0), 0) ?? 0}
                                                                </div>
                                                            </div>
                                                            <div className='ml-4'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesDeal.Spaces"/>
                                                                </div>
                                                                <div>
                                                                    {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfSpaces??0), 0) ?? 0}
                                                                </div>
                                                            </div>
                                                            <div className='ml-4'>
                                                                <div className='title-sm'>
                                                                    <FormattedMessage id="SalesDeal.Equipment"/>
                                                                </div>
                                                                <div>
                                                                    {deal.salesDealProposals.reduce((prev, curr) => prev + (curr.numberOfEquipment??0), 0) ?? 0}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='text-right'>
                                                            <div className='title-sm'>
                                                                <FormattedMessage id="SalesDeal.Total"/>
                                                            </div>
                                                            <div style={{ fontSize: '1.2em' }}>
                                                                <b>
                                                                    <FormatAmountNumber
                                                                        currency={global.hotelCurrency}
                                                                        value={deal.salesDealProposals.reduce((prev, curr) => prev + (curr.totalValue??0), 0) ?? 0}
                                                                    />
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :''}
                                    </Col>
                                </Row>
                            : activeTab === 'Proposal' ?
                                <SalesDealDetailProposal
                                    selectedPipeline={selectedPipeline}
                                    deal={deal}
                                    updateDeal={this.updateDeal}
                                />
                            :''}
                        </div>
                    </EmptyCard>
                </>
            :''
        )
    }
}

export default injectIntl(SalesDealDetail);


const ActionCard = ({ action, intl, focusActions, editFunction, deleteFunction, k, interveningUsers, salesUsers }) => {
    const status = moment().isSameOrAfter(action.startDate, 'date') ? (action.endDate && moment().isSameOrBefore(action.endDate, 'date')) ? "OnGoing" : "Late" : "Soon";
    const isActionable = action.type !== 'Status' && action.type !== 'Create' && action.type !== 'Stage';
    return (
        <div>
            <Row>
                <Col className={`col-1 d-flex align-items-center justify-content-center flex-column pr-0`}>
                    <div>
                        <div
                            title={intl.formatMessage({ id: `SalesProcess.${action.type}` })}
                            style={{
                                fontSize: '1.1em',
                                background: isActionable ? '#0665ff' : '#bcbcbc',
                                color: 'white',
                                width: isActionable ? '40px' : '30px',
                                height: isActionable ? '40px' : '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '100%'
                            }}
                        >
                            {action.type === 'Email' ?
                                <i style={{ marginTop: '1px' }} className="far fa-envelope"></i>
                            : action.type === 'Call' ?
                                <i className="fas fa-phone"></i>
                            : action.type === 'Note' ?
                                <i style={{ marginTop: '1px' }} className="far fa-sticky-note"></i>
                            : action.type === 'File' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-file"></i>
                            : action.type === 'Meeting' ?
                                <i style={{ marginTop: '1px' }} className="far fa-building"></i>
                            : action.type === 'Status' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-power-off"></i>
                            : action.type === 'Create' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-briefcase"/>
                            : action.type === 'Stage' ?
                                <i style={{ marginTop: '1px' }} className="fas fa-level-up-alt"/>
                            : ''}
                        </div>
                    </div>
                    <div style={{
                        width: '1px',
                        height: '100%',
                        borderLeft: k < (focusActions.length - 1) ? '1px dashed lightgrey' : '',
                        borderRight: k < (focusActions.length - 1) ? '1px dashed lightgrey' : ''
                    }}/>
                </Col>
                <Col className='col-11'>
                    <div className='pb-2 px-3 mb-4 card shadow border-0'>
                        <div className='d-flex align-items-start justify-content-between pt-3 pb-2'>
                            <div>
                                <div className='d-flex align-items-center justify-content-start'>
                                    {action.status ?
                                        <div className="mr-2 d-flex align-items-center justify-content-center" title={intl.formatMessage({ id: `SalesProcess.${action.status}` })} style={{ fontSize: '1.2em' }}>
                                            {action.status === 'Scheduled' ?
                                                status === "Late" ?
                                                    <i title={intl.formatMessage({ id: `SalesProcess.LateAction` })} className="text-danger fas fa-exclamation-circle"/>
                                                : status === "Soon" ?
                                                    <i title={intl.formatMessage({ id: `SalesProcess.ActionSoon` })} style={{ color: 'lightgrey' }} className="fas fa-minus-circle"/>
                                                :
                                                    <i title={intl.formatMessage({ id: `SalesProcess.OnGoingAction` })} style={{ color: '#0665ff' }} className="fas fa-arrows-alt-h"/>
                                                // <i className="fas fa-calendar-day color-host" />
                                            : action.status === 'Cancelled' ?
                                                <i className="fas fa-times-circle color-light-red"/>
                                            : action.status === 'OnGoing' ?
                                                <i className="fas fa-arrows-alt-h" style={{ color: "#0665ff" }}/>
                                            : action.status === 'Finished' ?
                                                <i className="fas fa-check-circle text-success"/>
                                            : ''}
                                        </div>
                                    : ''}
                                    <div style={{ fontSize: '1.1em' }}>
                                        {action.title}
                                    </div>
                                </div>                                                                            
                                <div className={`text-muted mt-1 ${interveningUsers && interveningUsers.length > 0 && action.type !== 'Email' && isActionable ? 'mb-3' : ''}`} style={{ fontSize: '0.9em' }}>
                                    <div className='emailDescription smallDescription'>
                                        {action.summary}
                                    </div>
                                </div>
                                {interveningUsers?.length > 0 && action.type !== 'Email' && isActionable ? (
                                    <div>
                                        {(() => {
                                            const firstUser = salesUsers?.find(({ id }) => id === interveningUsers[0].userId);
                                            return (
                                                <div>
                                                    <div id={`interveningUsers-${k}`} className={`emailDescription smallDescription text-muted mt-1 ${interveningUsers.length > 1 ? 'cursor-pointer' : ''}`} style={{ fontSize: '0.9em', width: 'fit-content' }}>
                                                        {firstUser?.firstName && firstUser?.lastName && (
                                                            <div className='d-flex align-items-center mr-2'>
                                                                <div>
                                                                    <i title={intl.formatMessage({ id: `SalesProcess.Intervener` })} className="far fa-user"></i>
                                                                </div>
                                                                <div className='ml-2'>
                                                                    {`${firstUser.firstName} ${firstUser.lastName} ${interveningUsers.length > 1 ? '...' : ''}`}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {interveningUsers.length > 1 ?
                                                        <CustomToolTip placementPrefix="coolTooltipWhite bs-tooltip" placement="right" target={`interveningUsers-${k}`}>
                                                            <PopoverBody>
                                                                {interveningUsers.map((u, k) => {
                                                                    const user = salesUsers?.find(({ id }) => id === u.userId);
                                                                    return user ? (
                                                                        <Col key={k} className={`col-12 ${k !== interveningUsers.length - 1 ? 'mb-2' : ''} p-0`}>
                                                                            <div className='mt-1'>
                                                                                <div className='emailDescription smallDescription'>
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <div>
                                                                                            <i title={intl.formatMessage({ id: `SalesProcess.Intervener` })} className="far fa-user"></i>
                                                                                        </div>
                                                                                        <div className='ml-2'>
                                                                                            {`${user.firstName} ${user.lastName}`}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    ) : null;
                                                                })}
                                                            </PopoverBody>
                                                        </CustomToolTip>
                                                    :''}
                                                </div>
                                            );
                                        })()}
                                    </div>
                                ) : ''}
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>                                                                            
                                <div className='d-flex align-items-center justify-content-end text-muted' style={{ fontSize: '0.9em' }}>
                                    {action.startDate ?
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            {moment(action.startDate).format('YYYY-MM-DD HH:mm')}
                                        </div>
                                    : ''}
                                    {action.endDate && (!moment(action.endDate).isSame(action.startDate, 'date')) ?
                                        <>
                                            {action.startDate ?
                                                <div className='mx-1'>-</div>
                                            :''}
                                            {moment(action.startDate).isSame(action.endDate, 'day') ?
                                                <div>
                                                    {moment(action.endDate).format('hh:mm')}
                                                </div>
                                            :
                                                <div style={{ whiteSpace: 'nowrap' }}>
                                                    {moment(action.endDate).format('YYYY-MM-DD HH:mm')}
                                                </div>
                                            }
                                        </>
                                    : ''}
                                </div>
                                <div className='ml-2'>
                                    <KebabMenu
                                        editFunction={editFunction}
                                        deleteFunction={deleteFunction}
                                    />
                                </div>
                            </div>
                        </div>
                        {action.type === "Email" ?
                            <>
                                <hr className='m-0' />
                                <div className='pt-2' style={{ fontSize: '0.9em' }}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.From"/>
                                            </div>
                                            <div>
                                                Carlos Brandão
                                            </div>
                                        </div>
                                        <div>
                                            <div className='title-sm'>
                                                <FormattedMessage id="SalesProcess.To"/>
                                            </div>
                                            <div>
                                                José Manuel...
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-1'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesProcess.Subject"/>
                                        </div>
                                        <div>
                                            {action.subject}
                                        </div>
                                    </div>
                                    <div className='mt-1'>
                                        <div className='title-sm'>
                                            <FormattedMessage id="SalesProcess.Email"/>
                                        </div>
                                        <div>
                                            <div className='emailDescription'>
                                                {action.preview ?? action.content}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        :''}
                    </div>
                </Col>
            </Row>
        </div>
    )
}