import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Form, Col, Row, Label, FormGroup, Modal, ModalBody, Input } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from "../Base/CustomSelect";
import { putAPI } from "../Base/API";
import countryList from 'react-select-country-list';

class AddressDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            addressData: this.props.address ? this.props.address : {},
        };
    }

    handleChange = (e, type) => {
        const { name, value } = e.target;

        this.setState({
            addressData: {
                ...this.state.addressData,
                [name]: value
            }
        })
    }

    handleChangeSelect = (combo, name) => {
        this.setState({
            addressData: {
                ...this.state.addressData,
                [name]: combo ? combo.value : null
            }
        })
    };

    saveAddress = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var address = { ...this.state.addressData };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="AddressDetails.AddressSaved" />, <FormattedMessage id="generic.success" />);
                    this.props.updateTable(data.response[0], 'addresses');
                    this.props.toggleModal();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/Address/${this.props.profileId}`, JSON.stringify({ request: address }));
    }

    render() {
        const { block, error, addressData } = this.state;

        return (
            <Modal isOpen={this.props.modal} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Form onSubmit={this.saveAddress}>
                            <Row className="mb-3">
                                <Col>
                                    <h5>{this.props.address ? <FormattedMessage id="AddressDetails.EditAddress" /> : <FormattedMessage id="AddressDetails.AddAddress" />}</h5>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit"> <i className="fas fa-save" /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Type" /></Label>
                                        <Col sm={8} >
                                            <CustomSelect
                                                options={this.props.availableAddressType}
                                                required
                                                isClearable
                                                isSearchable
                                                placeholder={""}
                                                onChange={(e) => this.handleChangeSelect(e, "type")}
                                                value={this.props.availableAddressType.find(c => c.value === addressData.type)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="ProfileDetails.AddressLine" /> 1</Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="addressLine1"
                                        onChange={(e) => this.handleChange(e)}
                                        value={addressData.addressLine1 ? addressData.addressLine1 : ''}
                                        maxLength="200"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="ProfileDetails.AddressLine" /> 2</Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="addressLine2"
                                        onChange={(e) => this.handleChange(e)}
                                        value={addressData.addressLine2 ? addressData.addressLine2 : ''}
                                        maxLength="200"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="ProfileDetails.AddressLine" /> 3</Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="addressLine3"
                                        onChange={(e) => this.handleChange(e)}
                                        value={addressData.addressLine3 ? addressData.addressLine3 : ''}
                                        maxLength="200"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2}> <FormattedMessage id="ProfileDetails.AddressLine" /> 4</Label>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name="addressLine4"
                                        onChange={(e) => this.handleChange(e)}
                                        value={addressData.addressLine4 ? addressData.addressLine4 : ''}
                                        maxLength="200"
                                    />
                                </Col>
                            </FormGroup>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Region" /> </Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="region"
                                                onChange={(e) => this.handleChange(e)}
                                                value={addressData.region ? addressData.region : ''}
                                                maxLength="100"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.PostalCode" /> </Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="postalCode"
                                                onChange={(e) => this.handleChange(e)}
                                                value={addressData.postalCode ? addressData.postalCode : ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Locality" /> </Label>
                                        <Col sm={8}>
                                            <Input
                                                type="text"
                                                name="locality"
                                                onChange={(e) => this.handleChange(e)}
                                                value={addressData.locality ? addressData.locality : ''}
                                                maxLength="100"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup row>
                                        <Label sm={4}> <FormattedMessage id="ProfileDetails.Country" /></Label>
                                        <Col sm={8}>
                                            <CustomSelect
                                                options={countryList().getData()}
                                                placeholder=""
                                                value={addressData.country ? countryList().getData().find(country => country.value === addressData.country) : ''}
                                                onChange={(combo) => this.handleChangeSelect(combo, 'country')}
                                                isClearable isSearchable
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}
export default injectIntl(AddressDetails)