import React, { Component } from 'react'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SalesSource from './SalesSource';
import SalesPipeline from './SalesPipeline';
import SalesPipelineStage from './SalesPipelineStage';
import SalesDealList from './SalesDealList';
import SalesDealDetail from './SalesDealDetail';
import { handleNotification } from '../Base/Notification';
import { deleteAPI, getAPI } from '../Base/API';
import SalesDealUsers from './SalesDealUsers';
import { FormattedMessage } from 'react-intl';
import SalesProcessStats from './SalesProcessStats';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Base/ErrorAlert';
import SalesProcessSettings from './SalesProcessSettings';

export default class SalesProcess extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            tabs: [
                { name: 'SalesSource', icon: 'fas fa-code-branch', wasOpened: false },
                { name: 'SalesPipeline', icon: 'fas fa-sliders-h', wasOpened: false },
                { name: 'SalesPipelineStage', icon: 'fas fa-route', wasOpened: false },
                { name: 'SalesDealList', icon: 'fas fa-briefcase', wasOpened: false },
                { name: 'SalesDealUsers', icon: 'fas fa-users', wasOpened: false },
                { name: 'SalesProcessStats', icon: 'far fa-chart-bar', wasOpened: false },
                { name: 'SalesProcessSettings', icon: 'fas fa-cog', wasOpened: false },
                { name: 'SalesDealDetail', notMenu: true },
            ],
            activeTab: "SalesPipelineStage",
            dealListPrevious: false,
            salesPipelines: [],
            salesSources: [],
            deals: [],
            selectedDealId: null,
            selectedPipeline: null,
            blockPipelines: false,
            blockSources: false,
            blockUsers: true,
            salesUsers: [],
            dealList: []
        };
    }

    componentDidMount(){
        this.getSalesPipelines();
    }

    getSalesPipelines = () => {
        this.setState({ blockPipelines: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockPipelines: false }, this.getSalesSources);
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = data.data?.map(el => ({
                        value: el.id,
                        label: el.name,
                        stages: el.salesPipelineStages.map(stage => ({
                            value: stage.id,
                            label: stage.name,
                            type: stage.type,
                            ...stage
                        })),
                        ...el
                    })) || [];

                    const savedValue = localStorage.getItem("MainSalesPipeline")??'';
                    const selectedPipeline =  list.find(({value}) => value === parseInt(savedValue)) ?? list[0];
                    
                    this.setState({ salesPipelines: list, blockPipelines: false }, () => {
                        this.getSalesSources();
                        if(selectedPipeline)
                            this.getDeals(selectedPipeline);
                    });
                    return;
                }
                else this.setState({ error: errorMessage, blockPipelines: false }, this.getSalesSources);
            }, `/api/gms/SalesProcess/v1/pipeline`)
        });
    }

    getUsers = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUsers: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                this.setState({ salesUsers: data.data?.map(u => ({
                    value: u.id,
                    label: `${u.firstName} ${u.lastName}`,
                    ...u
                })), blockUsers: false }, () => this.handleOffice365NewToken());
            }
        }, '/api/gms/SalesProcess/v1/salesprocessusers');
    }

    deletePipeline = (id) => {
        this.setState({ blockPipelines: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockPipelines: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        this.setState({ deleteModal: false, blockPipelines: false }, () =>
                            handleNotification(data)
                        );
                        return;
                    }
                    else {
                        this.setState({ deleteModal: false, blockPipelines: false }, () => {
                            handleNotification(data, <FormattedMessage id="SalesPipeline.DeletedSuccessfully" />, <FormattedMessage id="generic.success" />);
                            this.getSalesPipelines();
                        });
                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/pipeline/${id}`)
        );
    }

    getSalesSources = () => {
        this.setState({ blockSources: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockSources: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    const list = data.data?.map(el => ({ value: el.id, label: el.name })) || [];

                    this.setState({ salesSources: list, blockSources: false }, () => this.getUsers());
                    return;
                }
                else this.setState({ error: errorMessage, blockSources: false });
            }, `/api/gms/SalesProcess/v1/sources`);
        });
    }

    getDeals = (pipeline) => {
        this.setState({ block: true, selectedPipeline: pipeline }, () => {
            localStorage.setItem("MainSalesPipeline", pipeline?.value);
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data && data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    
                    this.setState({ deals: data.data || [], block: false });
                    return;
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/gms/SalesProcess/v1/deals/pipeline/${pipeline.value}`);
        });
    }
    
    handleOffice365NewToken = () => {
        const search = new URLSearchParams(window.location.search);
        if(!search)
            return;

        const code = search.get("code");

        if(!code || !code.length)
            return;

        const { salesUsers } = this.state;
        const userId = salesUsers.find(user => user.userId === global.userId)?.id;

        if(!userId)
            return;
        
        this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data && data.response && data.response[0]) {
                    search.delete("code");
                    this.setState({ block: false }, () => handleNotification(data, <FormattedMessage id="SalesProcess.SuccessfullyAuthenticated" />, <FormattedMessage id="generic.success" />));
                }
            }, `/api/gms/SalesProcess/v1/m365/${userId}/exchangecode?code=${code}`)
        );
    }

    updateDealStage = (deal) => {
        const { deals } = this.state;
        const index = deals?.findIndex(el => el.id === deal.id);

        if (index !== -1) {
            if (!deals[index].salesDealActions) deals[index].salesDealActions = [];

            deals[index].salesDealActions.unshift(deal.salesDealActions[0]);
            deals[index].salesPipelineStage = deal.salesPipelineStage;
            deals[index].salesPipelineStageId = deal.salesPipelineStageId;
            
            this.setState({ deals });
        }
    }

    toggleTab = (tab) => {
        this.setState({ activeTab: tab, selectedDealId: null });
    }

    selectDealDetail = (id, dealListPrevious) => {
        this.setState({ selectedDealId: id, dealListPrevious, activeTab: id ? 'SalesDealDetail' : this.state.dealListPrevious ? 'SalesDealList' : 'SalesPipelineStage' });
    }

    getDealList = (qs, cb) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                cb(errorMessage);
                return;
            }
            if (data) {
                if (data && data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                
                this.setState({ dealList: data.data || [] }, () => cb());
                return;
            }
            else cb(errorMessage);
        }, `/api/gms/SalesProcess/v1/deals` + qs);
    }

    render() {
        const { history } = this.props;
        const { tabs, block, error, activeTab, salesPipelines, deals, selectedDealId, salesSources, selectedPipeline, blockPipelines, salesUsers, blockUsers,
            dealList, dealListPrevious } = this.state;

        return (
            <div className="border-0 h-100 py-2">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <div className='w-100 d-flex'>
                        <div style={{ width: '4%' }} className='mr-2 side-menu'>
                            <Nav tabs vertical className='border-0 pt-2' style={{ position: 'sticky', top: '0' }}>
                                <Card className="bg-white p-2 border-0 shadow" style={{ width: 'fit-content' }}>
                                    {tabs.filter(({notMenu}) => !notMenu).map((page, key) =>
                                        <NavItem key={key} className="pb-2">
                                            <NavLink className={activeTab === page.name ? 'text-primary border-0  ' : 'bg-white border-0 '} onClick={_ => this.toggleTab(page.name)} style={{ cursor: 'pointer' }} >
                                                {activeTab === page.name ?
                                                    <div style={{
                                                        'left': '0',
                                                        'position': 'absolute',
                                                        'borderRadius': '0px 30px 30px 0px',
                                                        'width': '7px',
                                                        'height': '14px',
                                                        'background': '#0667FF 0% 0% no-repeat padding-box',
                                                        'opacity': '1',
                                                        'marginTop': '4px'
                                                    }}/>
                                                : ''}
                                                <div title={page.name} className='d-flex align-items-center justify-content-center py-2'>
                                                    <i className={`${page.icon}`}/>
                                                    {/* <FormattedMessage id={"SalesProcess."+ page.name}/> */}
                                                </div>
                                            </NavLink>
                                        </NavItem>
                                    )}
                                </Card>
                            </Nav>
                        </div>
                        <div className='mt-2' style={{ width: '96%' }}>
                            <TabContent activeTab={activeTab} className='border-0'>
                                {tabs.map((tab, key) =>
                                    <TabPane key={key} tabId={tab.name} className='border-0 pt-0'>
                                        {activeTab === tab.name || tab.wasOpened ?
                                            <>
                                                {tab.name === "SalesSource" ?
                                                    <SalesSource
                                                        salesPipelines={salesPipelines}
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        history={history}
                                                    />
                                                : tab.name === "SalesPipeline" ?
                                                    <SalesPipeline
                                                        icon={tab.icon}
                                                        salesPipelines={salesPipelines}
                                                        getSalesPipelines={this.getSalesPipelines}
                                                        deletePipeline={this.deletePipeline}
                                                        blockPipelines={blockPipelines}
                                                    />
                                                : tab.name === "SalesPipelineStage" ?
                                                    <SalesPipelineStage
                                                        icon={tab.icon}
                                                        salesUsers={salesUsers}
                                                        salesPipelines={salesPipelines}
                                                        salesSources={salesSources}
                                                        deals={deals}
                                                        selectDealDetail={this.selectDealDetail}
                                                        getDeals={this.getDeals}
                                                        selectedPipeline={selectedPipeline}
                                                        blockPipelines={this.state.blockPipelines}
                                                        updateDealStage={this.updateDealStage}
                                                    />
                                                : tab.name === "SalesDealList" ?
                                                    <SalesDealList
                                                        selectDealDetail={this.selectDealDetail}
                                                        icon={tab.icon}
                                                        getDeals={this.getDeals}
                                                        salesUsers={salesUsers}
                                                        salesPipelines={salesPipelines}
                                                        salesSources={salesSources}
                                                        dealList={dealList}
                                                        getDealList={this.getDealList}
                                                    />
                                                : tab.name === "SalesDealUsers" ?
                                                    <SalesDealUsers
                                                        salesUsers={salesUsers}
                                                        blockUsers={blockUsers}
                                                        getUsers={this.getUsers}
                                                    />
                                                : tab.name === "SalesDealDetail" ?
                                                    <SalesDealDetail
                                                        salesUsers={salesUsers}
                                                        deals={dealListPrevious && dealList?.length > 0 ? dealList : deals}
                                                        selectedDealId={selectedDealId}
                                                        salesSources={salesSources}
                                                        selectDealDetail={this.selectDealDetail}
                                                        salesPipelines={salesPipelines}
                                                        getDeals={this.getDeals}
                                                        selectedPipeline={selectedPipeline}
                                                    />
                                                : tab.name === "SalesProcessStats" ?
                                                    <SalesProcessStats/>
                                                : tab.name === "SalesProcessSettings" ?
                                                    <SalesProcessSettings/>
                                                :''}
                                            </>
                                        :''}
                                    </TabPane>
                                )}
                            </TabContent>
                        </div>
                    </div>
                </BlockUi>
            </div>
        )
    }
}
