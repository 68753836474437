import React, { Component } from 'react';
import { ActiveInactiveStatusBadge, Crt, StyledCard } from '../../Base/CommonUIComponents';
import { Button, Label, Input, Row, Col, Card, CardBody, } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI } from "../../Base/API";
import moment from 'moment';
import { getProfileDataQualityMetrics } from '../../Base/ReferenceDataFunctions';
import { handleNotification } from '../../Base/Notification';
import { Doughnut } from 'react-chartjs-2';
import { Chart } from 'chart.js';

class ProfileSummary extends Component {
    static displayName = ProfileSummary.name;

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            profileDataQuality: {},
            metrics: getProfileDataQualityMetrics(),
            legend: [{ label: this.props.intl.formatMessage({ id: 'ProfileSummary.Filled' }), color: '#56CFE1' },
                { label: this.props.intl.formatMessage({ id: 'ProfileSummary.Unfilled' }), color: '#EDEDED' },
                { label: this.props.intl.formatMessage({ id: 'ProfileSummary.Valid' }), color: '#6EDE8A' },
                { label: this.props.intl.formatMessage({ id: 'ProfileSummary.Errors' }), color: '#F38375' }],
            calculateResultsDisabled: false
            //calculateResultsDisabled: true
        };
    };

    componentDidMount() {
        this.getConfig();
    }

    getConfig = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    this.setState({ profileDataQuality: data.response ? data.response[0] : {}, block: false, modal: false });
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/DataQuality/v1/qualityProfile`);
    }

    calculateResults = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="ProfileSummary.Calculate" />, <FormattedMessage id="generic.success" />);
                        this.setState({ profileDataQuality: data.response[0], block: false, modal: false });
                    }
                    else {
                        handleNotification({}, <FormattedMessage id="ProfileSummary.Timeout" />, <FormattedMessage id="generic.Timeout" />, 'info');
                        this.setState({ block: false, modal: false, calculateResultsDisabled: true });
                    }
                }
            }
            else this.setState({ block: false });
        }, `/api/gms/DataQuality/v1/qualityProfile/calculate`);
    }

    getChartDatasets = (totalMasters, results, errors) => {
        const { legend } = this.state;

        const filled = results?.length > 0 && results[0]?.filled;
        const valid = results?.length > 0 && results[0]?.valid;

        const percFilled = filled && totalMasters ? parseFloat(filled * 100 / totalMasters).toFixed() : 0;
        const percNotFilled = filled && totalMasters ? parseFloat((totalMasters - filled) * 100 / totalMasters).toFixed() : 0;

        const percValid = valid && filled ? parseFloat(valid * 100 / filled).toFixed() : 0;
        const percErrors = errors && filled ? parseFloat(errors * 100 / filled).toFixed() : 0;


        const chartData = {
            "labels": legend.map(el=> el.label),
            "datasets": [
                { "data": [percFilled, percNotFilled], "backgroundColor": [legend[0].color, legend[1].color] },
                { "data": [percValid, percErrors], "backgroundColor": [legend[2].color, legend[3].color] }
            ]
        }

        return chartData
    }

    render() {
        const { block, calculateResultsDisabled, error, profileDataQuality, metrics, legend } = this.state;

        const optionsPie = {
            maintainAspectRatio: false,
            cutout: '45%',
            plugins: {
                legend: {
                    display: false,
                    position: "left",
                    labels: {
                        generateLabels: function (chart) {
                            // Get the default label list
                            const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                            const labelsOriginal = original.call(this, chart);

                            // Build an array of colors used in the datasets of the chart
                            let datasetColors = chart.data.datasets.map(function (e) {
                                return e.backgroundColor;
                            });
                            datasetColors = datasetColors.flat();

                            // Modify the color and hide state of each label
                            labelsOriginal.forEach(label => {
                                // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                                label.datasetIndex = (label.index - label.index % 2) / 2;

                                // The hidden state must match the dataset's hidden state
                                label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                                // Change the color to match the dataset
                                label.fillStyle = datasetColors[label.index];
                            });

                            return labelsOriginal;
                        }
                    },
                    onClick: function (mouseEvent, legendItem, legend) {
                        // toggle the visibility of the dataset from what it currently is
                        legend.chart.getDatasetMeta(
                            legendItem.datasetIndex
                        ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
                        legend.chart.update();
                    }
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const labelIndex = (context.datasetIndex * 2) + context.dataIndex;
                            return  `${context.chart.data.labels[labelIndex]}: ${context.formattedValue}%`;
                        }
                    }
                },
                datalabels: {
                    color: 'white',
                    formatter: (ctx, data) => {
                        return ctx ? ctx : '';
                    },
                    formatter: (val, context) => {
                        return `${val}%`
                    },
                },
            }
        }

        return (
            <StyledCard block={block} error={error} >
                <Row className="mb-4">
                    <Col>
                        <h5> <i className="fas fa-user-cog" /> <FormattedMessage id="NavMenu.ProfileSummary" /> </h5>
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-sm btn-host" onClick={this.calculateResults} disabled={calculateResultsDisabled}>
                            <i className="fas fa-calculator" />
                        </Button>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col sm={3}>
                        <Crt
                            text={'Total Masters'}
                            value={profileDataQuality?.totalMaster || 0}
                        />
                    </Col>
                    <Col>
                        <Card className="shadow border-0 h-100 my-0 py-0">
                            <CardBody>
                                <Row className="mb-3">
                                    <Col><h5 className="text-muted"><FormattedMessage id="ProfileSummary.Legend" /></h5></Col>
                                </Row>
                                <Row style={{ float: 'right', minWidth: '70%' }}>
                                    {legend.map((item, key) =>
                                        <Col key={key}>
                                            <div className="mb-1 pl-2" style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '12px', fontStyle: 'normal', fontColor: '#666', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                <div className="align-items-center" style={{ display: 'inline-flex' }} >
                                                    <div style={{ width: '35px', height: '9.5px', backgroundColor: item.color }} className="mr-2" />
                                                    {item.label}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={3}>
                        <Crt
                            text={<FormattedMessage id="BatchLogs.LastRun" />}
                            value={profileDataQuality && profileDataQuality.lastRun ? moment(profileDataQuality.lastRun).format("YYYY-MM-DD HH:mm") : ''}
                        />
                    </Col>
                </Row>

                <Row>
                    {profileDataQuality && profileDataQuality.criteria && profileDataQuality.criteria.map((criteria, idx) => {
                        const metric = metrics.find(m => m.value === criteria.criteria);
                        const errors = criteria.results?.length > 0 ? criteria.results[0].filled - criteria.results[0].valid : 0;
                        const totalMasters = profileDataQuality.totalMaster;
                        const unfilled = criteria.results?.length > 0 ? totalMasters - criteria.results[0]?.filled : null;

                        const pieData = this.getChartDatasets(totalMasters, criteria.results, errors);


                        return <Col sm={3} key={idx} className="mb-4">
                            <Card className="border-0 shadow py-4 h-100">
                                <Row className="align-items-center justify-content-center text-muted mb-3">
                                    <i className={`${metric.icon} mr-1`} />
                                    <h5 className="m-0"><b> {metric.label} </b></h5>
                                </Row>

                                <div className="text-center mt-4 mb-5">
                                    {pieData.datasets && pieData.datasets.length > 0 && pieData.datasets[0].data.length > 0 ?
                                        <Doughnut data={pieData} height={200} options={optionsPie} />
                                    : ''}
                                </div>

                                <Row>
                                    <Col className="text-center pr-2" style={{ color: '#22CCE2'}}>
                                        <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Filled" })}>
                                            {criteria.results && criteria.results[0]?.filled || '-'}
                                        </b>
                                        <br />
                                        {/*<FormattedMessage id="ProfileSummary.Filled" />*/}
                                    </Col>
                                    <Col className="text-center px-2" style={{ color: '#959595' }}>
                                        <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Unfilled" })}>
                                            {unfilled || '-'}
                                        </b>
                                        <br />
                                        {/*<FormattedMessage id="ProfileSummary.Unfilled" />*/}
                                    </Col>
                                    <Col className="text-center color-green px-2">
                                        <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Valid" })}>
                                            {criteria.results && criteria.results[0]?.valid || '-'}
                                        </b>
                                        <br/>
                                        {/*<FormattedMessage id="ProfileSummary.Valid"/>*/}
                                    </Col>
                                    <Col className="text-center  color-light-red pl-2">
                                        <b style={{ fontSize: '19px' }} title={this.props.intl.formatMessage({ id: "ProfileSummary.Errors" })}>
                                            {errors || '-'}
                                        </b>
                                        <br />
                                        {/*<FormattedMessage id="ProfileSummary.Errors" />*/}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    })}
                </Row>

                
            </StyledCard>
        );
    }
}

export default injectIntl(ProfileSummary); 