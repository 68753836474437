import React, { Component } from 'react';
import { Button, Col, FormGroup, Modal, ModalBody, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard } from '../../../Base/CommonUIComponents';
import CustomSelect from '../../../Base/CustomSelect';
import { handleNotification } from '../../../Base/Notification';
import { putAPI } from '../../../Base/API';
import { ProfileCreditFields } from '../../ProfileCommonFunctions';

export class ProfileCreditDetail extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setCombo = this.setCombo.bind(this);
        this.saveCredit = this.saveCredit.bind(this);
        this.state = {
            block: false,
            credit: {
                hasCityLedger: false,
                creditLimitType: "UNRESTRICTED",
                creditLimitTypePOS: "UNRESTRICTED"
            }
        };
    }

    componentDidMount() {
        if (this.props.credit !== null) {
            const { credit } = this.state;
            Object.assign(credit, this.props.credit);
            this.setState({ credit });
        }
    }

    handleSwitch(evt) {
        if (evt && evt.target) {
            const { credit } = this.state;
            const { name, checked } = evt.target;
            credit[name] = checked;
            this.setState({ credit });
        }
    }

    handleChange(evt) {
        if (evt && evt.target) {
            const { credit } = this.state;
            const { name, value } = evt.target;
            credit[name] = parseFloat(value);
            this.setState({ credit });
        }
    }

    setCombo(combo, evt) {
        if (evt) {
            const { credit } = this.state;
            credit[evt.name] = combo && combo.value;
            this.setState({ credit });
        }
    }

    saveCredit(evt) {
        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        const { profileId, updateTable } = this.props;
        const { credit } = this.state;

        const request = {
            request: credit
        };

        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ProfileDetails.creditSaved" />, <FormattedMessage id="generic.success" />);
                    Object.assign(credit, data.response[0]);
                    this.setState({ credit, error: errorMessage, block: false }, _ => updateTable(data.response[0], 'profileCredit'));
                    this.props.toggle();
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/gms/Profile/v1/profile/credit/${profileId}`, request);
    }

    render() {
        const { isOpen, toggle, title, hotels } = this.props;
        const { block, error, credit } = this.state;

        return (
            <Modal isOpen={isOpen}>
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody className="bg-light">
                    <BlankCard block={block} error={error}>
                        <Row className="mb-3">
                            <Col>
                                <h5>{title}</h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={this.saveCredit}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <form ref={this.form}>
                            {hotels?.length > 0 ?
                                <FormGroup row>
                                    <Col className="col-2">
                                        <FormattedMessage id="generic.Hotel" />
                                    </Col>
                                    <Col className="col-4" style={{ zIndex: '999' }}>
                                        <CustomSelect
                                            id="hotelId"
                                            name="hotelId"
                                            options={hotels.filter(h => !h.isDisabled)}
                                            value={hotels.find(opt => opt.value === credit.hotelId)}
                                            onChange={this.setCombo}
                                            required
                                            isDisabled={credit.id}
                                            placeholder={""}
                                        />
                                    </Col>
                                </FormGroup>
                                : ''}

                            <ProfileCreditFields
                                credit={credit}
                                handleSwitch={this.handleSwitch}
                                handleChange={this.handleChange}
                                setCombo={this.setCombo}
                                creditLimitTypeOptions={this.props.creditLimitTypeOptions}
                            />
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>    
        );
    }
}