import React, { Component } from 'react';
import { Row, Col, Button, UncontrolledTooltip, Modal, Label } from 'reactstrap';
import { handleNotification } from '../../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from "../../Base/CommonUIComponents";
import CustomSelect from "../../Base/CustomSelect";
import { getAPI, deleteAPI, postAPI } from '../../Base/API';
import AddMapping from './AddMapping';
import { CustomTable } from '../../Base/CustomTable';
import { CheckAuthorization } from '../../Base/Authorization';
import Authorization from '../../Base/Authorization';

class TagMapping extends Component {
    TagMapping = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            modal: false,
            tagMapping: [],
            tagsWithInfo: ['Single', 'Double', 'Multiple', 'Family', 'Early Booker', 'Late Booker', 'Room Service', 'Maintenance', 'Longstay', 'Shortstay', 'Stays'],
            importedCsv: [],
            csvmodal: false,
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this.getHotels();
    }

    getTags = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    const list = data.response.map(group => ({ ...group, tag: group.tag.map(tag => ({ ...tag, mapping: [] })) }));

                    this.setState({ tagMapping: list }, () => this.getMapping());
                }
                this.setState({ block: false });
            }
        }, `/api/gms/Profile/v1/tag/groupDetails?tagType=system`)
    }
       
    getMapping = () => {
        this.setState({ block: true });

        const param = this.state.hotelId ? `?hotelId=${this.state.hotelId}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            var allTag = this.state.allTagMapping;
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const mapping = [...this.state.tagMapping].map(el => { return { ...el, tag: el.tag.map(t => { return { ...t, mapping: []} }) }});

                if (data.response && data.response.length > 0) {
                    data.response.forEach(tm => {
                        const tagGroup = mapping.find(tg => tg.tag && tg.tag.find(tag => tag.id === tm.tagId));
                        const tag = tagGroup && tagGroup.tag.find(el => el.id === tm.tagId);

                        if (tag) {
                            tag.mapping.push(tm);
                        }
                    })
                }
                if (!this.state.hotelId) {
                    allTag = mapping;
                }
                this.setState({ tagMapping: mapping, allTagMapping: allTag })
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/tag/mapping` + param);
    }

    getHotels = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.response) {
                    const hotels = data.response.map(h => {
                        const hotel = { value: `${h.hotelId}`, label: `${h.name2}` };
                        return hotel;
                    });

                    this.setState({ hotels });
                }
            }
            this.setState({ block: false }, this.getTags);
        }, '/api/gms/Hotel/v1/hotel/list?onlyFromHotelGroup=true');
    }


    removeTagMapping = (e, tagMapping) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TagMapping.TagMappingRemoved" />, <FormattedMessage id="generic.success" />);
                    this.updateMapping(tagMapping, true);
                }
            }
            this.setState({ error: errorMessage, block: false, modal: false });
        }, `/api/gms/Profile/v1/tag/mapping/${tagMapping.id}`);
    }

    updateMapping = (mapping, isToRemove) => {
        const tagMapping = [...this.state.tagMapping];
        let groupIndex = null, tagIndex = null;

        tagMapping.forEach((group, i) => {
            group.tag.forEach((tag, idx) => {
                if (tag.id === mapping.tagId) {
                    groupIndex = i;
                    tagIndex = idx;
                }
            })
        });

        if (isToRemove) {
            tagMapping[groupIndex].tag[tagIndex].mapping = tagMapping[groupIndex].tag[tagIndex].mapping.filter(el => el.id !== mapping.id);
        }
        else {
            const index = tagMapping[groupIndex].tag[tagIndex].mapping.findIndex(el => el.id === mapping.id);

            if (mapping.id && index !== -1) {
                tagMapping[groupIndex].tag[tagIndex].mapping[index] = mapping;
            }
            else {
                tagMapping[groupIndex].tag[tagIndex].mapping.push(mapping);
            }
        }

        this.setState({ tagMapping });
    }

    toggleModal = (tags, selected) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            tagOptions: tags,
            selectedTag: selected
        }));
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    downloadCSV = () => {
        const { tagMapping, hotels } = this.state;

        let csvData = 'ChargeCode,TagId,TagName,HotelId,HotelName,ParameterValue \n';

        tagMapping.forEach(function (tm) {
            tm.tag.forEach(function (t) {
                t.mapping.forEach(function (m) {
                    csvData += m.chargeCode + ',' + t.id + ',' + t.code + ',' + m.hotelId + ',' + hotels.find(h => h.value === m.hotelId).label + ',' + m.parameterValue + '\n';
                })
            })
        })

        let anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
        anchor.target = '_blank';
        anchor.download = 'tagMapping.csv';
        anchor.click();
    }

    importCSV = (CSV) => {
        const { allTagMapping, hotels } = this.state;
        var reader = new FileReader();

        reader.readAsText(CSV);

        reader.onload = (e) => {
            var allTextLines = e.target.result.split(/\r\n|\n/);
            var lines = allTextLines.map(data => data.split(/,|;/));
            var list = [];
            var listApi = [];
            lines.forEach((line, idx) => {
                let i = 0;
                var jsObj = {};
                var validTag = null;
                jsObj["Reason"] = []
                while (i < line.length) {
                    if (idx !== 0) {
                        jsObj[lines[0][i]] = line[i];
                    }
                    if ((validTag === null || validTag) && lines[0][i] == "HotelId") {
                        validTag = hotels.filter(e => e.value === line[i]).length > 0;
                        if (!validTag) {
                            jsObj["Reason"].push(<FormattedMessage id="TagMapping.InvalidHotelId" />, line[i]);
                        }
                    }
                    if ((validTag === null || validTag) && lines[0][i] == "HotelName") {
                        validTag = hotels.filter(e => e.label === line[i]).length > 0;
                        if (!validTag) {
                            jsObj["Reason"].push(<FormattedMessage id="TagMapping.InvalidHotelName" />, line[i]);
                        }
                    }

                    i++;
                }
                if (jsObj["ParameterValue"] == "null") {
                    jsObj["ParameterValue"] = null
                }
                if (jsObj["HotelId"] == null && jsObj["HotelName"] == null) {
                    validTag = false
                    jsObj["Reason"].push(<FormattedMessage id="TagMapping.InvalidObjHotel" />);
                }
                if (jsObj["TagId"] == null && jsObj["TagName"] == null) {
                    validTag = false
                    jsObj["Reason"].push(<FormattedMessage id="TagMapping.InvalidObjTag" />);
                }
                if (jsObj["ChargeCode"] == null) {
                    validTag = false
                    jsObj["Reason"].push(<FormattedMessage id="TagMapping.InvalidObjChargeCode" />);
                }
                if (isNaN(jsObj["ParameterValue"]) && jsObj["ParameterValue"] != null) {
                    validTag = false
                    jsObj["Reason"].push(<FormattedMessage id="TagMapping.InvalidParameterValue" />);
                }
                if (hotels.filter(e => e.value === jsObj["HotelId"]).length > 0 && jsObj["HotelName"] == null) {
                    var hotelName = hotels.find(e => e.value === jsObj["HotelId"]).label;
                    jsObj["HotelName"] = hotelName;
                }  
                if (hotels.filter(e => e.label === jsObj["HotelName"]).length > 0) {
                    var hotelId = jsObj["HotelId"] != null ? jsObj["HotelId"] : hotels.find(e => e.label === jsObj["HotelName"]).value;
                    jsObj["HotelId"] = hotelId;
                }  
                if (jsObj["TagName"] == null) {
                    var tagName;
                    allTagMapping.forEach(function (tm) {
                        if (tm.tag.filter(e => e.id == jsObj["TagId"]).length > 0) {
                            tagName = tm.tag.find(t => t.id == jsObj["TagId"]).code;
                        }
                    });
                    jsObj["TagName"] = tagName;
                }

                allTagMapping.forEach(function (tm) {
                    tm.tag.forEach(function (t) {
                        t.mapping.forEach(function (m) {
                            if (m.hotelId == jsObj["HotelId"] && m.chargeCode == jsObj["ChargeCode"] && t.code == jsObj["TagName"] && m.parameterValue == jsObj["ParameterValue"]) {
                                validTag = false;
                                jsObj["Reason"].push(<FormattedMessage id="TagMapping.ExistingTag" />);
                            }
                        })
                    })
                })
                jsObj["ValidTag"] = validTag;

                if (idx !== 0 && line[0]) {
                    if (validTag) {
                        listApi.push(jsObj);
                    }
                    if (parseInt(jsObj["ParameterValue"]) === 0 || jsObj["ParameterValue"] == null) {
                        jsObj["ParameterValue"] = "-"
                    }
                    list.push(jsObj);
                }
            })
            this.setState({ block: false, importedCsv: list, importedCsvApi: listApi }, this.toggleCsvModal)
        }
    }

    importCsvApi = () => {
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TagMapping.TagMappingImported" />, <FormattedMessage id="generic.success" />);
                }
            }
            this.setState({ error: errorMessage, block: false, csvmodal: false }, this.getMapping);
        }, `/api/gms/Profile/v1/tag/importmapping/`, this.state.importedCsvApi);
    }

    handleFiles = (event) => {
        var files = event.target.files;

        if (window.FileReader && files[0].size < 2097152) {
            this.setState({ block: true }, this.importCSV(files[0]));
        }
        else {
            this.errorHandler(<FormattedMessage id="TagMapping.FileReader" />)
        }
    };

    fileChanged = (e) => {
        e.target.value = null;
    };

    importCSVClicked = () => {
        document.getElementById("tagMappingFileSelector").click();
    }

    getCSVStatus = (cell, row, idx) => {
        return (
            <div>
                {!cell &&
                    <UncontrolledTooltip target={`Tooltip${idx}`} placement="left" >
                        <div>
                            <b><FormattedMessage id='Profile.Missing' /></b>
                            {row.Reason.map((miss, key) => {
                                    return (
                                        <div key={key}>{miss}</div>
                                    )}
                            )}
                        </div>
                    </UncontrolledTooltip>
                }
                <span id={`Tooltip${idx}`}>
                    <i style={{ color: cell ? '#76EFAC' : '#EE6A5F' }} className={`fas fa-${cell ? 'check' : 'times'}`}></i>
                </span>
            </div>
        )
    }

    toggleCsvModal = () => {
        this.setState({ csvmodal: !this.state.csvmodal });
    }

    render() {
        const { error, block, tagMapping, hotels, tagsWithInfo, csvmodal } = this.state;
        const columnsCSV = [
            {
                dataField: 'ChargeCode',
                text: this.props.intl.formatMessage({ id: "TagMapping.TableCode" }), 
                sort: true
            },
            {
                dataField: 'TagName',
                text: this.props.intl.formatMessage({ id: "TagMapping.TagCode" }),
                sort: true
            },
            {
                dataField: 'HotelId',
                text: this.props.intl.formatMessage({ id: "TagMapping.HotelId" }), 
                sort: true
            },
            {
                dataField: 'HotelName',
                text: this.props.intl.formatMessage({ id: "TagMapping.HotelName" }), 
                sort: true,
            },
            {
                dataField: 'ParameterValue',
                text: this.props.intl.formatMessage({ id: "TagMapping.ParameterValue" }),
                sort: false,
            },
            {
                dataField: 'ValidTag',
                text: this.props.intl.formatMessage({ id: "TagMapping.ValidTag" }),
                sort: true,
                formatter: (cell, row, idx) => this.getCSVStatus(cell, row, idx)
            }
        ]
        return ( 
            <StyledCard block={block} error={error}>
                <Row>
                    <Col>
                        <h5>
                            <i className="fas fa-tag mr-2"></i>
                            <FormattedMessage id="TagMapping.Title" />
                        </h5>
                    </Col>
                </Row>
                <Modal isOpen={csvmodal} fade={false} style={{ minWidth: 0 }} className='xxl'>
                    <Button onClick={this.toggleCsvModal} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    <Row className="mt-3">
                        <Col className="text-center" >
                            <h2>
                                <FormattedMessage id="TagMapping.ImportedCsv" />
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right mr-5">
                            <Button className="btn-sm btn-host mr-2" onClick={this.importCsvApi}>
                                <i className="fas fa-save" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="ml-5 mt-3 mb-3 mr-5">
                            <CustomTable
                                data={this.state.importedCsv}
                                columns={columnsCSV}
                                shadow={false}
                                sizePerPage={20}
                            />
                        </Col>
                    </Row>
                </Modal>
                <Row className="my-4">
                    <Col className='col-3'>
                        <Label className='px-0' className='col-4'> <FormattedMessage id="generic.Hotel" /></Label>
                        <CustomSelect
                            options={hotels}
                            name='hotelId'
                            onChange={this.handleSelect.bind(this, 'hotelId')}
                            isClearable isSearchable
                            placeholder={<FormattedMessage id="generic.Hotel" />}
                        />
                    </Col>
                    <Authorization
                        perform="importTag:view"
                        yes={() => (
                            <Col className='col-3'>
                                <Label className='px-0' className='col-4'> <FormattedMessage id="Profile.ImportFile" /></Label>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        id="tagMappingFileSelector"
                                        onChange={(e) => this.handleFiles(e)}
                                        accept=".csv"
                                        onClick={(e) => this.fileChanged(e)}
                                    />
                                    <label className="custom-file-label" for="customFile">
                                        <FormattedMessage id="generic.ChooseFile" />
                                    </label>
                                </div>
                            </Col>
                        )}
                        no={() => <div></div>}
                    />
                    <Col className="text-right">
                        <Button className="btn-sm btn-host mr-2" onClick={this.downloadCSV}>
                            <i className="fas fa-file-csv"></i>
                        </Button>
                        <Button className="btn-sm btn-host" onClick={this.getMapping}>
                            <i className="icon-icon-search" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {tagMapping && tagMapping.map((tagGroup, key) =>
                        <Col className="col-6 mb-3" key={key}>
                            <Row className="d-flex align-items-center">
                                <Col> <b> {tagGroup.code} </b> </Col>
                                {CheckAuthorization("tagMapping:save", null) ?
                                    <Button className="btn-sm btn-host text-right mr-3" onClick={() => this.toggleModal(tagGroup.tag, null)}>
                                        <i className="fas fa-plus" />
                                    </Button>
                                    : ''}
                            </Row>

                            <hr className="mt-1 mb-2" />

                            {tagGroup.tag && tagGroup.tag.map((tag, index) => {
                                return <Row className="mb-2" key={index}>
                                    <Col className="py-2">
                                        <Row>
                                            <Col>
                                                {tag.code}
                                            </Col>
                                            <Col className="col-4 pr-4">
                                                {tagsWithInfo.some(el => el === tag.code) ?
                                                    <div>
                                                        <i className="fas fa-info-circle pointer text-muted" id={`${tag.code.replace(/\s/g, '')}Info`} />

                                                        <UncontrolledTooltip target={`${tag.code.replace(/\s/g, '')}Info`} placement="bottom">
                                                            <FormattedMessage id={`TagMapping.${tag.code.replace(/\s/g, '')}InfoText`} />
                                                        </UncontrolledTooltip>
                                                    </div>
                                                    : ''}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-8">
                                        {tag.mapping.map((tm, key2) =>
                                            <span className="bg-host color-white mr-3" style={{ borderRadius: '5px', display: 'inline-block', fontSize: '11px', height: '30px' }} key={key2}>
                                                <div >
                                                    <span className="d-flex justify-content-between pointer" style={{ padding: '5px 8px' }}>
                                                        <span onClick={() => this.toggleModal(tagGroup.tag, tm)}>
                                                            {tag.parameterized ?
                                                                tag.parameterOperator && tag.parameterOperator !== 'None' ?
                                                                    <span> {this.props.intl.formatMessage({ id: `ReferenceDataFunctions.${tag.parameterOperator}` })} {tm.parameterValue} </span>
                                                                    : ''
                                                                : <span> {tm.chargeCode} </span>}
                                                        </span>
                                                        <i className="fas fa-times pointer ml-3 pt-1" onClick={(e) => this.removeTagMapping(e, tm)} />
                                                    </span>

                                                    <div className="px-2 mx-1" style={{ backgroundColor: '#FAFCFF', borderRadius: '10px', border: '1px solid #C9DEFE', position: 'relative', top: '-2px', color: 'black', display: 'inline-block' }}>
                                                        {tm.hotelId && hotels.some(h => h.value === tm.hotelId) ? hotels.find(h => h.value === tm.hotelId).label : ''}
                                                    </div>
                                                </div>
                                            </span>
                                        )}
                                    </Col>
                                </Row>
                            })}
                        </Col>
                    )}
                </Row>

                {this.state.modal ?
                    <AddMapping
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        options={this.state.tagOptions}
                        updateMapping={this.updateMapping}
                        selectedTag={this.state.selectedTag}
                        removeTagMapping={this.removeTagMapping}
                        hotels={hotels}
                    />
                    : ''}
            </StyledCard>
        );
    }
}
export default injectIntl(TagMapping)