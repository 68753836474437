import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row, UncontrolledTooltip, Card, Badge } from 'reactstrap';
import { getAPI, deleteAPI } from "../Base/API";
import { ProfileIsMasterBadge, StyledCard } from "../Base/CommonUIComponents";
import { CustomTable } from '../Base/CustomTable';
import { Doughnut, Bar } from 'react-chartjs-2';
import { handleNotification } from '../Base/Notification';
import CustomSelect from "../Base/CustomSelect";
import BlockUi from 'react-block-ui';

import Authorization, { CheckAuthorization } from '../Base/Authorization';
import ConfirmActionModal from '../Base/ConfirmActionModal';
import { getColorPallete, showDuplications } from '../Base/ReferenceDataFunctions';

export class TaskResultDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockTable: false,
            runDetailsProfiles: [],
            deleteModal: false,
            occurrencesByHotel: {
                "datasets": [{
                    "label": "Occurrences by hotel", "data": [], "backgroundColor": getColorPallete() }], "labels": [] },
            occurrencesByRules: { "datasets": [{ "label": "# Occurrences", "data": [], "backgroundColor": "#0867fa" }], "labels": [] },
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0,
            isResolved: null,
            selectedRuleId: null
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            let params = new URLSearchParams(window.location.search);

            let pageIndex = params.get('page') - 1;
            let pageSize = params.get('pageSize');
            let isResolved = params.get('isResolved');
            let selectedRuleId = params.get('ruleId');
            let hotelId = params.get('hotelId');

            this.setState({ pageSize, pageIndex, isResolved, selectedRuleId, hotelId },
                () => {
                    this.getResultDetailsProfiles();
                    this.getResultDetailsStats();
                }
            );
        }
    }

    getResultDetailsProfiles = (totalItemsToExport, cb) => {
        this.setState({ blockTable: true });
        this.updateUrlParams();

        var params = '';
        if (this.state.isResolved !== null) params += `&isResolved=${this.state.isResolved}`;
        if (this.state.selectedRuleId) params += `&ruleId=${ this.state.selectedRuleId}`;
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockTable: false });
                return;
            }
            if (data) {
                if (totalItemsToExport) {
                    this.setState({ runDetailsProfiles: data.response }, () => {cb(); this.getResultDetailsProfiles() });
                }
                else {
                    this.setState({ runDetailsProfiles: data.response, totalItems: data.totalItems, blockTable: false });
                }
            }
        }, `/api/gms/DataQuality/v1/DataQualityRunDetails/${this.props.match.params.id}/Profiles?pageSize=${totalItemsToExport ? totalItemsToExport : this.state.pageSize}&pageIndex=${totalItemsToExport ? 0 : this.state.pageIndex}` + params);
    }

    updateUrlParams = () => {
        const { pageSize, pageIndex, isResolved, selectedRuleId, hotelId } = this.state;

        let url = `?page=${pageIndex + 1}&pageSize=${pageSize}`;

        if (isResolved !== null && isResolved !== undefined) url += `&isResolved=${isResolved}`;
        if (selectedRuleId) url += `&ruleId=${selectedRuleId}`;
        if (hotelId) url += `&hotelId=${hotelId}`;

        if (window.location.search !== url) {
            this.props.history.push({ search: url });
        }
    }

    getResultDetailsStats = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.calculateChartsData(data.response);
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityRunDetails/${this.props.match.params.id}/Stats`);
    }

    calculateChartsData = (data) => {
        let hotels = [], ruleOptions = [];

        var occurrencesByHotel = { ...this.state.occurrencesByHotel }
        var occurrencesByRules = { ...this.state.occurrencesByRules }
        var values = []
        var occurrences = []

        data.forEach(el => {
            if (!occurrencesByHotel.labels.includes(el.hotelName)) {
                occurrencesByHotel.labels.push(el.hotelName);
                hotels.push({ value: el.hotelId, label: el.hotelName });
            }
            if (!occurrencesByRules.labels.includes(el.ruleName)) {
                occurrencesByRules.labels.push(el.ruleName)
                ruleOptions.push({ value: el.ruleId, label: el.ruleName })
            }

            const hotelIndex = occurrencesByHotel.labels.indexOf(occurrencesByHotel.labels.find(h => h === el.hotelName));
            values[hotelIndex] = values[hotelIndex] ? values[hotelIndex] + el.occurences : 0 + el.occurences;

            const a = occurrencesByRules.labels.indexOf(occurrencesByRules.labels.find(h => h === el.ruleName));
            occurrences[a] = occurrences[a] ? occurrences[a] + el.occurences : 0 + el.occurences;

        })

        occurrencesByHotel.datasets[0].data = values;
        occurrencesByRules.datasets[0].data = occurrences;

        this.setState({
            occurrencesByHotel,
            hotels,
            ruleOptions
        })
    }

    removeRunDetail = (evt, runDetailId) => {
        evt.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TaskResultDetail.RunDeleted" />, <FormattedMessage id="generic.success" />);
                    this.getResultDetails(this.props.match.params.id);
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityRunDetails/${runDetailId}`);
    }

    removeRun = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="TaskResultDetail.RunDeleted" />, <FormattedMessage id="generic.success" />); 
                    this.props.history.push("/TaskResultDashboard");
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/DataQuality/v1/DataQualityRun/${this.props.match.params.id}`);
    }

    getDuplcationOrStatus = (cell, row, statusOptions) => {
        return cell != null && row.rules && showDuplications(row.rules[0].ruleType, row.rules[0].duplicateMatchType) ?
            row.rules && row.rules.map((rule, k) => {
                const totalDup = cell.length;

                return totalDup === 0 ?
                    <Badge color="success" className="py-1 px-2"><FormattedMessage id="TaskResultDetail.Resolved" /> </Badge>
                :
                    <div key={k} className="mr-3" >
                        {row.masterId && row.profileId !== row.masterId ?
                            <>
                                <i className="fas fa-check-circle color-green mr-2" id={`merged-${row.profileId}`} />
                                <UncontrolledTooltip placement="bottom" target={`merged-${row.profileId}`}>
                                    <FormattedMessage id="TaskResultDashboard.ProfileAlreadyHasAnAssociatedMaster" />
                                </UncontrolledTooltip>
                            </>
                            : ''}

                        #{totalDup} {this.props.intl.formatMessage({ id: "TaskResultDetail.PossibleDuplications" })}

                        <Button className="btn-sm btn-host float-right mr-2"
                            onClick={() => this.props.history.push({ pathname: `/ProfileMergeDetail`, state: { duplicatedIds: [row.profileId, ...row.duplicateIds] } })}>
                            Merge
                        </Button>
                    </div>
            })
            : row.rules && row.rules[0] && row.rules[0].resolved ?
                statusOptions.filter(opt => opt.value === row.rules[0].resolved).map(opt => opt.badge)
                : <Badge color="danger" className="py-1 px-2"><FormattedMessage id="TaskResultDetail.NotResolved" /> </Badge>
    }
    
    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
            runDetailsProfiles: []
        }, () => { this.getResultDetailsProfiles() });
    }

    doSearch = (data) => {
        this.setState({
            pageIndex: 0,
            runDetailsProfiles: []
        }, () => { this.getResultDetailsProfiles(null, null, data) });
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    handleSelectWithSearch = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        }, this.getResultDetailsProfiles);
    }

    handleCustomExportClick = (cb) => {
        this.getResultDetailsProfiles(5000, cb);
    }

    getHotelName = (row) => {
        const hotelId = row?.rules[0]?.hotelId;

        if (this.state.hotels) {
            const hotel = hotelId ? this.state.hotels.find(h => h.value === hotelId) : null;

            return hotel ? hotel.label : hotelId
        }
        else return hotelId;
    }

    toggleModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    }


    render() {
        const { block, error, runDetailsProfiles, blockTable, occurrencesByRules, isResolved, occurrencesByHotel, hotels, hotelId, ruleOptions, deleteModal } = this.state;

        const statusOptions = [
            {
                value: 'Resolved',
                label: this.props.intl.formatMessage({ id: "TaskResultDetail.Resolved" }),
                badge: <Badge color="success" className="py-1 px-2"> <FormattedMessage id="TaskResultDetail.Resolved" /></Badge>
            },
            {
                value: 'NotResolved',
                label: this.props.intl.formatMessage({ id: "TaskResultDetail.NotResolved" }),
                badge: <Badge color="danger" className="py-1 px-2"><FormattedMessage id="TaskResultDetail.NotResolved" /> </Badge>
            },
            {
                value: 'Ignored',
                label: this.props.intl.formatMessage({ id: "TaskResultDetail.Ignored" }),
                badge: <Badge color="secondary" className="py-1 px-2"><FormattedMessage id="TaskResultDetail.Ignored" /> </Badge>
            }
        ];

        const columns = [
            {
                dataField: "rules[0].hotelId",
                text: 'Hotel',
                formatter: (cell, row) => this.getHotelName(row),
                csvFormatter: (cell, row) => this.getHotelName(row)
            },
            {
                dataField: "pmsId",
                text: 'PMS ID',
                hidden: true,
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: "profileFirstName",
                text: this.props.intl.formatMessage({ id: "TaskResultDetail.FirstName" }),
                formatter: (cell, row) => {
                    return <div>
                        {row.profileFirstName} {row.profileLastName}
                        <span className="float-right pr-5"> <ProfileIsMasterBadge isMaster={row.isMaster} linked={!row.isMaster && row.masterId} /> </span>
                    </div>
                },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        var list = row.rules && row.rules[0] && row.rules[0].resolved ? null : [];
                        if (list) {
                            row.rules && row.rules.forEach(el => list.push(el.ruleId));
                        }

                        this.props.history.push({ pathname: "/ProfileDetails/" + row.profileId, rules: list });
                    },
                },
                style: { cursor: 'pointer', textDecoration: 'underline' },
                sort: true,
                csvFormatter: (cell, row) => `${row.profileFirstName} ${row.profileLastName}`
            },
            {
                dataField: "isMaster",
                text: "Master",
                hidden: true,
                csvFormatter: (cell, row) => cell ? 'Master' : row.masterId ? 'Linked' : ''
            },
            {
                dataField: "rules",
                text: this.props.intl.formatMessage({
                    id: "TaskResultDetail.Rules"
                }),
                formatter: (cell, row) =>  cell.map((x, k) => <div key={k}>{x.ruleName}</div>),
                sort: true,
                csvFormatter: (cell) => cell ? cell.map(el => el.ruleName).join(', ') : ''
            },
            {
                dataField: "duplicateIds",
                text: this.props.intl.formatMessage({ id: "TaskResultDetail.Duplications/Status" }),
                formatter: (cell, row) => this.getDuplcationOrStatus(cell, row, statusOptions),
                sort: true,
                headerStyle: { width: "25%" },
                csvFormatter: (cell, row) => {
                    if (row.rules && showDuplications(row.rules[0].ruleType, row.rules[0].duplicateMatchType)) {
                        const totalDup = cell.length;
                        if (totalDup === 0) {
                            return this.props.intl.formatMessage({ id: "TaskResultDetail.Resolved" })
                        }
                        else return `#${totalDup} ${this.props.intl.formatMessage({ id: "TaskResultDetail.PossibleDuplications" })}`
                    }
                    else if (row.rules && row.rules[0] && row.rules[0].resolved)
                        return statusOptions.filter(opt => opt.value === row.rules[0].resolved).map(opt => opt.label)
                    else return this.props.intl.formatMessage({ id: "TaskResultDetail.NotResolved" })
                }
            },
            {
                dataField: "value",
                text: this.props.intl.formatMessage({ id: "TaskResultDetail.Value" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: "rules.runId",
                text: this.props.intl.formatMessage({ id: "TaskResultDetail.Delete" }),
                formatter: (cell, row) => <span onClick={(e) => this.removeRunDetail(e, row.rules[0].runId)} style={{ cursor: 'pointer' }} className="ml-3"> <i className="fas fa-trash-alt" /> </span>,
                headerStyle: { width: "5%" },
                csvExport: false,
                hidden: CheckAuthorization("taskResultDetail:delete", null)
            },
        ];
        
        const optionsPie = {
            cutout: '60%',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    position: "left"
                },
                datalabels: { display: false }
            },
            onClick: (evt, element, chart) => {
                if (element.length > 0) {
                    this.handleSelectWithSearch('hotelId', hotels[element[0].index]);
                }
            }
        }

        const options = {
            elements: {
                PointElement: {
                    radius: 0
                }
            },
            plugins: { datalabels: { display: false } },
            scales: {
                y: {
                    display: true,
                    stacked: true,
                    beginAtZero: true
                },
                x: {
                    stacked: true,
                },
            },
            onClick: (evt, element) => {
                if (element.length > 0) {
                    this.handleSelectWithSearch('selectedRuleId', ruleOptions[element[0].index]);
              }
            }
        }

        return (
            <>
                <BlockUi tag="div" blocking={block}>
                    <Row>
                        <Col className="col-6">
                            <Card className="shadow border-0 h-100" body>
                                <h5 className="text-muted"><FormattedMessage id="TaskResultDetail.OccurrencesByHotel" /></h5>
                                {occurrencesByHotel.datasets && occurrencesByHotel.datasets.length > 0 && occurrencesByHotel.datasets[0].data.length > 0 ?
                                    <div>
                                        <Doughnut data={this.state.occurrencesByHotel} height={240} options={optionsPie} />
                                    </div>
                                    : ''}
                            </Card>
                        </Col>

                        <Col className="col-6">
                            <Card className="shadow border-0 h-100" body>
                                <h5 className="text-muted"><FormattedMessage id="TaskResultDetail.OccurrencesByRules" /></h5>
                                {occurrencesByRules.datasets && occurrencesByRules.datasets.length > 0 && occurrencesByRules.datasets[0].data.length > 0 ?
                                    <div>
                                        <Bar data={occurrencesByRules} height={90} options={options} />
                                    </div>
                                : ''}
                            </Card>
                        </Col>
                    </Row>
                </BlockUi>
                <div className="my-3" />
                <StyledCard block={blockTable} error={error} icon={'fas fa-file-alt'} title={'TaskResultDetail.Title'}>
                    <Row className="my-3">
                        <Col className="col-2">
                            <CustomSelect
                                options={statusOptions}
                                isClearable
                                isSearchable
                                onChange={this.handleSelect.bind(this, 'isResolved')}
                                value={statusOptions.find(op => op.value === isResolved)}
                                placeholder={this.props.intl.formatMessage({ id: "generic.status" }) }
                            />
                        </Col>
                        {ruleOptions && ruleOptions.length > 0 &&
                            <Col className="col-2">
                                <CustomSelect
                                    options={ruleOptions}
                                    isClearable
                                    isSearchable
                                    onChange={this.handleSelect.bind(this, 'selectedRuleId')}
                                    value={this.state.selectedRuleId ? ruleOptions.find(op => op.value.toString() === this.state.selectedRuleId.toString()) : ''}
                                    placeholder={this.props.intl.formatMessage({ id: "generic.ruleId" })}
                                />
                            </Col>
                        }
                        <Col className="col-2">
                            <CustomSelect
                                options={hotels}
                                isClearable
                                isSearchable
                                onChange={this.handleSelect.bind(this, 'hotelId')}
                                value={hotelId ? hotels?.find(h => h.value === hotelId) : ''}
                                placeholder={<FormattedMessage id="generic.Hotel" />}
                            />
                        </Col>

                        <Col className="text-right">
                            <Button className="btn-sm btn-host" onClick={() => this.doSearch()}>
                                <i className="icon-icon-search" />
                            </Button>

                            <Authorization
                                perform="taskResultDetail:run:delete"
                                yes={() => (
                                    <>
                                        <Button className="btn-sm btn-host ml-2" id="deleteRun" onClick={this.toggleModal}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="deleteRun">
                                            <FormattedMessage id="TaskResultDetail.DeleteRun" />
                                        </UncontrolledTooltip>

                                        {deleteModal ?
                                            <ConfirmActionModal
                                                modal={deleteModal}
                                                toggleModal={this.toggleModal}
                                                actionFunction={this.removeRun}
                                                text={<FormattedMessage id="TaskResultDetail.ConfirmDeleteText" />}
                                                block={block}
                                            />
                                        : ''}
                                    </>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>

                    <CustomTable
                        data={runDetailsProfiles}
                        columns={columns}
                        page={this.state.pageIndex + 1}
                        sizePerPage={this.state.pageSize}
                        totalSize={this.state.totalItems}
                        onTableChange={this.handleTableChange}
                        shadow={false}
                        showTotal={true}
                        remote={true}
                        keyField='duplicateIds'
                        exportCSV={true}
                        customExportCsv={true}
                        customExportTooltip={<FormattedMessage id="TaskResultDetail.ExportCsvTooltip" values={{ elems: 5000}} />}
                        handleCustomExportClick={this.handleCustomExportClick.bind(this)}
                        hideSizePerPage={false}
                    />
                </StyledCard>
              
            </>
        );
    }
}
export default injectIntl(TaskResultDetail)