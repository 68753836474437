import React, { Component } from 'react';
import { Button, Col, Input, Modal, ModalBody, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';

export class SendMessageModal extends Component {

    render() {
        const { blockSenders, blockTemplates, buttonLabel, children, contact, errorSenders, errorTemplates, form, handleChange, isOpen, sender, senders, sendMessage, toggle } = this.props;

        return (
            <Modal isOpen={isOpen} fade={false} size={"xl"} style={{ minWidth: 0 }} >
                <Button onClick={toggle} className="closeModal">
                    <i className="fas fa-times fa-sm"></i>
                </Button>
                <ModalBody>
                    <BlankCard block={blockSenders || blockTemplates} error={errorSenders || errorTemplates}>
                        <form ref={form}>
                            <Row className="mb-3">
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm mr-1" onClick={sendMessage}>
                                        {buttonLabel}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col className="col-1">
                                    <FormattedMessage id="SendEmailModal.Sender" />
                                </Col>
                                <Col className="col-11">
                                    <CustomSelect name="sender" options={senders} value={senders.find(s => s.value === sender.value)} onChange={handleChange} required />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col className="col-1 ">
                                    <FormattedMessage id="SendEmailModal.To" />
                                </Col>
                                <Col className="col-11">
                                    <Input type="text" value={contact && contact.contact} disabled />
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col><FormattedMessage id="SendEmailModal.Template" /></Col>
                            </Row>
                            <hr className="mt-1 mb-3" />                            
                            <Row >
                                <Col>
                                    {
                                        children
                                    }
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}