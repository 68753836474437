import React, { Component } from 'react'
import { StyledCard } from '../../Base/CommonUIComponents'
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { Col, Row, Label, Input, Form, FormGroup, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from "../../Base/CustomSelect";

class TwilioTestSms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            ToPhone: null,
            senderType: { value: 0, label: this.props.intl.formatMessage({ id: "TwilioMessaging.Numbers" }) },
            messageType: { value: 0, label: this.props.intl.formatMessage({ id: "TwilioTestSms.Message" }) },
            ServiceSid: null,
            SendPhone: null,
            Message: null,
            TemplateSid: null
        };
    }

    sendSms = (e) => {
        e.preventDefault();
        const { ToPhone, SendPhone, ServiceSid, Message, TemplateSid } = this.state;

        const regex = /^\+[1-9]\d{0,14}$/;
        if (!regex.test(ToPhone)) {
            const data = { errors: [{ message: <FormattedMessage id="TwilioMessaging.InvalidToPhone" values={{ 0: ToPhone }} /> }] }
            handleNotification(data);

            return;
        }

        this.setState({ block: true },
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if (data.response) {
                        handleNotification(data, <FormattedMessage id="TwilioTestSms.SmsSentSuccessfully" />, <FormattedMessage id="generic.success" />);
                        this.setState({ block: false });
                        return;
                    }

                    this.setState({ block: false });
                }
            }, `/api/gms/Twilio/v1/sms/send?to=${encodeURIComponent(ToPhone)}&from=${encodeURIComponent(SendPhone ? SendPhone.label : '')}&message=${Message ?? ''}&service=${ServiceSid ? ServiceSid.value : ''}&template=${TemplateSid ? TemplateSid.value : ''}`));
    }



    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
                [name]: value
        })
    }

    handlePhoneChange = (e) => {
        const { name, value } = e.target;
        const regex = /^\+[1-9]\d{0,14}$/;

        if (value === '' || value === '+' || regex.test(value)) {
            this.setState({
                testsms: {
                    ...this.state.testsms,
                    [name]: value
                }
            })
        }
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo
        })
    };

    handleTypeSelect = (combo) => {
        this.setState({
            senderType: combo, ServiceSid: null, SendPhone: null
        })
    };

    handleMessageTypeSelect = (combo) => {
        this.setState({
            messageType: combo, Message: null, TemplateSid: null
        })
    };



    render() {
        const { page, twilioPhones, services, templates } = this.props;
        const { block, error, ToPhone, senderType, ServiceSid, SendPhone, messageType, Message, TemplateSid } = this.state;

        const serviceTypes = [
            { value: 0, label: this.props.intl.formatMessage({ id: "TwilioMessaging.Numbers" }) },
            { value: 1, label: this.props.intl.formatMessage({ id: "TwilioMessaging.Services" }) }
        ]

        const messageTypes = [
            { value: 0, label: this.props.intl.formatMessage({ id: "TwilioTestSms.Message" }) },
            { value: 1, label: this.props.intl.formatMessage({ id: "TwilioMessaging.Template" }) }
        ]

        return (
            <StyledCard block={block} error={error}>
                <Row className="mb-4 py-1">
                    <Col>
                        <h5> <i className={page?.icon} /> {page?.name} </h5>
                    </Col>
                </Row>
                <Form className="ml-5" style={{ width: "50%" }} onSubmit={this.sendSms}>
                    <FormGroup row className="mb-4 py-1">
                        <Col>
                            <Label> <FormattedMessage id="TwilioTestSms.ToPhone" /></Label>
                            <Input
                                type="string"
                                name="ToPhone"
                                onChange={(e) => this.handleChange(e)}
                                value={ToPhone || ''}
                                maxLength="13"
                                placeholder="+351918273645"
                                required
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-4 py-1">
                        <Col>
                            <Label> <FormattedMessage id="TwilioTestSms.SenderType" /></Label>
                            <CustomSelect
                                options={serviceTypes}
                                value={senderType ?? ''}
                                onChange={this.handleTypeSelect.bind("senderType")}
                                required
                            />
                        </Col>
                    </FormGroup>
                    {senderType && senderType.value === 0 ?
                        <FormGroup row className="mb-4 py-1">
                            <Col>
                                <Label> <FormattedMessage id="TwilioTestSms.SendPhone" /></Label>
                                <CustomSelect
                                    options={twilioPhones}
                                    value={SendPhone ?? ''}
                                    onChange={this.handleSelect.bind(this, "SendPhone")}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        :
                        senderType && senderType.value === 1 ?
                            <FormGroup row className="mb-4 py-1">
                                <Col>
                                    <Label> <FormattedMessage id="TwilioTestSms.SendPhone" /></Label>
                                    <CustomSelect
                                        options={services}
                                        value={ServiceSid ?? ''}
                                        onChange={this.handleSelect.bind(this, "ServiceSid")}
                                        required
                                    />
                                </Col>
                            </FormGroup>

                            : ''}
                    <FormGroup row className="mb-4 py-1">
                        <Col>
                            <Label> <FormattedMessage id="TwilioTestSms.MessageType" /></Label>
                            <CustomSelect
                                options={messageTypes}
                                value={messageType ?? ''}
                                onChange={this.handleMessageTypeSelect.bind("messageType")}
                                required
                            />
                        </Col>
                    </FormGroup>
                    {messageType && messageType.value === 0 ?
                        <FormGroup row className="mb-4 py-1">
                            <Col>
                                <Label> <FormattedMessage id="TwilioTestSms.Message" /></Label>
                                <Input
                                    type="textarea"
                                    name="Message"
                                    onChange={(e) => this.handleChange(e)}
                                    value={Message || ''}
                                    maxLength="160"
                                    required
                                />
                            </Col>
                        </FormGroup>
                        :
                        messageType && messageType.value === 1 ?
                            <FormGroup row className="mb-4 py-1">
                                <Col>
                                    <Label> <FormattedMessage id="TwilioMessaging.Template" /></Label>
                                    <CustomSelect
                                        options={templates}
                                        value={TemplateSid ?? ''}
                                        onChange={this.handleSelect.bind(this, "TemplateSid")}
                                        required
                                    />
                                </Col>
                            </FormGroup>

                            : ''}
                    <Row>
                        <Col>
                            <Button className="btn-sm btn-host" type="submit">
                                <FormattedMessage id="TwilioTestSms.SendSms" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </StyledCard>
        )
    }
}
export default injectIntl(TwilioTestSms);

