import moment from 'moment-timezone';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl'
import { Card, Col, Row } from 'reactstrap'
import { Pagination, TotalResults } from '../../Base/PaginationComponents';
import RunModal from './RunModal';
import { getFormTypes } from '../../Base/ReferenceDataFunctions';
import { getAPI } from '../../Base/API';

class ConfigRuns extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            selectedRunId: null,
            formOptions: []
        };
    }

    componentDidMount() {
        this.props.getSummaryConfigRuns();
    }

    componentWillReceiveProps(newProps){
        if(newProps.summaryConfigRuns === this.props.summaryConfigRuns){
            return;
        }
        const { summaryConfigRuns, reviewConfigs } = newProps;

        if(!summaryConfigRuns || !reviewConfigs) return;
        const formOptions = [];

        summaryConfigRuns.forEach((run) => {
            const config = reviewConfigs && reviewConfigs.find(({id}) => id === run.reviewSummaryConfigId);
            if(config && config.formId){
                const formType = getFormTypes(this.props.intl, config.channel === 'Inquiry').find(ft => ft.value === config.formType);
                if(formType?.getFormsUrl){
                    getAPI(result => {
                        const { data, error } = result;
                        if (error) {
                            var errorMessage = [];
                            errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                            this.setState({ error: errorMessage });
                            return;
                        }
                        if (data && data.response && data.response.length > 0) {
                            const form = data.response.find((item) => item.id === parseInt(config.formId) || item.id === config.formId);
                            formOptions.push({ 'value': form.id, 'label': form.name || form.title });
                            this.setState({ formOptions });
                        }
                        else{
                            this.setState({ error: errorMessage });
                            return;
                        }
                    }, formType.getFormsUrl);
                }
            }
        });
    }

    toggleModal = (id) => {
        this.setState({ modal: !this.state.modal, selectedRunId: id });
    }

    render() {
        const { summaryConfigRuns, reviewConfigs, runsTotalItems, runsPageSize, runsPageIndex } = this.props;
        const { modal, selectedRunId, formOptions } = this.state;

        return (
            <>
                {modal && selectedRunId && summaryConfigRuns ?
                    <RunModal
                        toggleModal={this.toggleModal}
                        summaryConfigRuns={summaryConfigRuns}
                        selectedRunId={selectedRunId}
                        reviewConfigs={reviewConfigs}
                        formOptions={formOptions}
                    />
                :''}
                <div>
                    <Row className='mb-3'>
                        <Col>
                            <h5 className="m-0">
                                <i className="fas fa-step-backward mr-2"/>
                                <FormattedMessage id="SummaryConfig.Runs" />
                            </h5>
                        </Col>
                    </Row>
                    {summaryConfigRuns && summaryConfigRuns.length > 0 ?
                        summaryConfigRuns.sort((a, b) => moment(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1).map((run, key) => {
                            const config = reviewConfigs && reviewConfigs.find(({id}) => id === run.reviewSummaryConfigId);
                            return (
                                <Row className={`mt-2 cursor-pointer`} onClick={_ => this.toggleModal(run.id)} key={key}>
                                    <Col>
                                        <Card className="border-0 shadow p-2">
                                            <Row className='p-2'>
                                                <Col className='col-1 pr-0'>
                                                    <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                                        <FormattedMessage id="SummaryConfig.ReportType"/>
                                                    </div>
                                                    <div>
                                                        {config?.reportType || '-'}
                                                    </div>
                                                </Col>
                                                <Col className='col-3'>
                                                    <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                                        <FormattedMessage id="SummaryConfig.Form"/>
                                                    </div>
                                                    <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} title={formOptions.find(({value}) => value === parseInt(config?.formId) || value === config?.formId)?.label}>
                                                        
                                                        {config?.formId && formOptions && formOptions.length > 0 ?
                                                            <b>{formOptions.find(({value}) => value === parseInt(config.formId) || value === config.formId)?.label}</b>
                                                        : config?.reportType === "MetaData" && config?.channel === "Reviews" ?
                                                            <b>
                                                                <FormattedMessage id="SummaryConfig.Reviews"/>
                                                            </b>
                                                        :'-'}
                                                    </div>
                                                </Col>
                                                <Col className='col-3'>
                                                    <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                                        <FormattedMessage id="SummaryConfig.Prompt"/>
                                                    </div>
                                                    <div title={config?.promptConfig?.name} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                        {config?.promptConfig?.name || "-"}
                                                    </div>
                                                </Col>
                                                <Col className='col-1'/>
                                                <Col className='d-flex align-items-center'>
                                                    <div>
                                                        <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                                            <FormattedMessage id="SummaryConfig.FromDate"/>
                                                        </div>
                                                        <div>
                                                            {moment(run.fromDate).format("YYYY-MM-DD")}
                                                        </div>
                                                    </div>
                                                    <div className='mx-2 h-100 d-flex align-items-end justify-content-center pb-1 text-muted'>-</div>
                                                    <div>
                                                        <div style={{ fontSize: '0.8em' }} className='text-muted'>
                                                            <FormattedMessage id="SummaryConfig.ToDate"/>
                                                        </div>
                                                        <div>
                                                            {moment(run.toDate).format("YYYY-MM-DD")}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className='col-2 text-right'>
                                                    <div className='text-muted' style={{ fontSize: '0.8em' }}>
                                                        {moment(run.updatedAt).format("YYYY-MM-DD HH:mm")}
                                                    </div>
                                                    <div style={{ color: run.status === "Running" ? '#6EDE8A' : run.status === "Finished" ? '#0665ff' :'' }}>
                                                        <b><FormattedMessage id={`SummaryConfig.${run.status}`}/></b>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        })
                    :
                        <div className='text-center'>
                            <FormattedMessage id="SummaryConfig.NoRuns"/>
                        </div>
                    }
                    {runsTotalItems > runsPageSize ?
                        <Row className="mt-2">
                            <Col>
                                <span className="ml-2">
                                    <TotalResults
                                        end={runsPageIndex * runsPageSize + summaryConfigRuns.length}
                                        start={runsPageIndex * runsPageSize + 1}
                                        total={runsTotalItems}
                                    />
                                </span>
                            </Col>

                            <Col className="text-right mr-3">
                                <Pagination
                                    isPrevDisabled={runsPageIndex === 0}
                                    isNextDisabled={runsTotalItems <= ((runsPageIndex + 1) * runsPageSize)}
                                    currentPage={runsPageIndex + 1}
                                    handlePrevButton={() => this.props.handleRunsPagination(-1)}
                                    handleNextButton={() => this.props.handleRunsPagination(1)}
                                />
                            </Col>
                        </Row>
                    :''}
                </div>
            </>
        )
    }
}

export default injectIntl(ConfigRuns);