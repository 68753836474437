import React, { Component, Fragment } from "react";
import Select, { components } from "react-select";

const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {
                    children && (
                        <i
                            className={props.selectProps.icon ? props.selectProps.icon : ''}
                            aria-hidden="true"
                            style={{ position: "absolute", left: 6 }}
                        />
                    )
                }
                <span style={props.selectProps.icon ? { paddingLeft: 15 } : {} }>
                    {children}
                </span>
                
            </components.ValueContainer>
        )
    );
};

const styles = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    valueContainer: base => ({
        ...base,
        paddingLeft: 10
    })
}

const CustomSelectOption = ({ innerProps, label, icon }) => (
    <div {...innerProps}>
        {icon && <i className={icon} style={{ marginRight: "5px" }} />}
        {label}
    </div>
);

export default class CustomSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || ""
        };
    }

    selectRef = null;

    setSelectRef = ref => {
        this.selectRef = ref;
    };

    onChange = (value, actionMeta) => {
        this.props.onChange(value, actionMeta);
        this.setState({ value });
    };

    getValue = () => {
        if (this.props.value !== undefined) {
            return this.props.value;
        }
        return this.state.value || "";
    };

    clear() {
        this.setInputValue('');
    }

    formatOptionLabel = ({ label, icon }) => { 
        return <CustomSelectOption label={label} icon={icon} />
    };        

    render() {
        const { SelectComponent, required, ...props } = this.props;
        const { isDisabled } = this.props;
        const enableRequired = !isDisabled && required;
        const components = { ValueContainer };

        return (
            <Fragment>
                <Select
                    {...props}
                    ref={this.setSelectRef}
                    onChange={this.onChange}
                    components={components}
                    formatOptionLabel={this.formatOptionLabel}
                    isOptionDisabled={(option) => option?.disabled}
                    styles={ styles }
                />
                {enableRequired ?
                    <div style={{ position: 'relative' }}>
                        <input
                            tabIndex={-1}
                            autoComplete="off"
                            style={{ opacity: 0, width: "95%", height: 0, position: 'absolute' }}
                            value={this.getValue()}
                            onChange={() => { }}
                            onFocus={() => this.selectRef.focus()}
                            required={required}
                        />
                    </div>
                :''}
            </Fragment>
        );
    }
}
