import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button } from 'reactstrap';
import { DefaultPage, FormatAmountNumber } from '../Base/CommonUIComponents';
import CustomSelect from '../Base/CustomSelect';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { DateRangePicker } from 'react-dates';
import moment from 'moment-timezone';
import SalesDealModal from './SalesDealModal/SalesDealModal';
import { handleNotification } from '../Base/Notification';
import { putAPI } from '../Base/API';
import { ErrorAlert } from '../Base/ErrorAlert';
import BlockUi from 'react-block-ui';
import SalesActionsPopOver from './SalesActionsPopOver';

class SalesPipelineStage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStaff: null,
            startDate: null,
            endDate: null,
            error: null,
            block: false
        };
    }

    changeDealStage = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else if (data.data?.length > 0) {
                        handleNotification(data, <FormattedMessage id="SalesDeal.DealMoved" />, <FormattedMessage id="generic.success" />);
                        
                        this.props.updateDealStage(data.data[0]);
                        this.setState({ error: errorMessage, block: false });

                        return;
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `api/gms/SalesProcess/v1/deals/${this.state.grabbingDealId}/pipelinestage/${this.state.dragToStageId}`);
        });
    }

    handlePipeline = (combo) => {
        this.props.getDeals(combo);
    }

    handleSelectedStaff = (el) => {
        this.setState({ selectedStaff: el?.value });
    }

    handleDateChanges = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    onDragStart = (e, grabbingDealId, excludedStageId) => {
        e.dataTransfer.setData("deal", JSON.stringify(grabbingDealId));

        this.setState(prevState => ({ ...prevState, showDragArea: true, grabbingDealId, excludedStageId }))
    }

    onDragEnd = () => {
        this.setState({ showDragArea: false, dragToStageId: null, grabbingDealId: null, excludedStageId: null })
    }

    onDragOver = (e, stageId) => {
        e.preventDefault();

        if (this.state.dragToStageId !== stageId) {
            this.setState({ dragToStageId: stageId });
        }
    }

    render() {
        const { salesPipelines, salesSources, deals, intl, selectDealDetail, selectedPipeline, blockPipelines, salesUsers, getDeals } = this.props;
        const { selectedStaff, startDate, endDate, excludedStageId, showDragArea, dragToStageId, createDeal, error, block } = this.state;

        const sourceDeals = deals.filter(({salesDealUser, businessStartDate, businessEndDate}) => 
            (!selectedStaff || salesDealUser?.some(u => u.userId === selectedStaff)) &&
            (!startDate || (businessStartDate && startDate.isSameOrBefore(moment(businessStartDate), 'date'))) &&
            (!endDate || (businessEndDate && endDate.isSameOrAfter(moment(businessEndDate), 'date')))
        );
        
        return (
            <div className='h-100 pb-2'>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    {createDeal ?
                        <SalesDealModal
                            modal={createDeal}
                            salesUsers={salesUsers}
                            toggleModal={_ => this.toggleModal('createDeal')}
                            salesStages={selectedPipeline.stages}
                            salesSources={salesSources}
                            getDeals={_ => this.props.getDeals(selectedPipeline)}
                            salesPipelineId={selectedPipeline.id}
                            salesPipelines={salesPipelines}
                        />
                    :''}

                    <Row className="mb-4">
                        <Col>
                            <h4 className='m-0'>
                                <i className={`${this.props.icon} mr-2`} />
                                <FormattedMessage id="SalesProcess.SalesPipelineStage" />
                            </h4>
                        </Col>
                    </Row>

                    <div className='d-flex align-items-center justify-content-between' style={{ gap: '2%' }}>
                        <div style={{ width: '20%' }}>
                            <div className='title-sm'>
                                <i className={`fas fa-sliders-h mr-2`} />
                                <FormattedMessage id="SalesProcess.SalesPipelines"/>
                            </div>
                            <div>
                                <CustomSelect
                                    options={salesPipelines}
                                    required={true}
                                    isClearable={false}
                                    isMulti={false}
                                    value={salesPipelines?.filter(({ value }) => value === selectedPipeline?.value) || ''}
                                    isSearchable={true}
                                    onChange={this.handlePipeline}
                                    placeholder={intl.formatMessage({ id: "SalesProcess.SalesPipelines" })}
                                    isLoading={blockPipelines}
                                />
                            </div>
                        </div>
                        <div style={{ width: '20%' }}>
                            <div className='title-sm'>
                                <i className="fas fa-users mr-2"/>
                                <FormattedMessage id="SalesProcess.Staff"/>
                            </div>
                            <div>
                                <CustomSelect
                                    options={salesUsers}
                                    required={true}
                                    isClearable={true}
                                    isMulti={false}
                                    value={salesUsers?.find(({value}) => value === selectedStaff)}
                                    isSearchable={true}
                                    onChange={this.handleSelectedStaff}
                                    placeholder={intl.formatMessage({ id: "SalesProcess.Staff" })}
                                />
                            </div>
                        </div>
                        <div style={{ width: '20%' }}>
                            <div className='title-sm'>
                                <i className="far fa-calendar-alt mr-2"/>
                                <FormattedMessage id="SalesProcess.BusinessDates"/>
                            </div>
                            <div>
                                <DateRangePicker
                                    startDate={startDate}
                                    startDateId="startDateId"
                                    endDate={endDate}
                                    endDateId="endDateId"
                                    isOutsideRange={_ => false}
                                    onDatesChange={this.handleDateChanges}
                                    focusedInput={this.state.focusedInput}
                                    showClearDates={true}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                />
                            </div>
                        </div>
                        <div style={{ width: '40%' }} className='d-flex align-items-end justify-content-end'>
                            {sourceDeals && sourceDeals.length > 0 ?
                                <div className='d-flex aling-items-center justify-content-end pr-4'>
                                    <div>
                                        <FormatAmountNumber value={sourceDeals?.reduce((a, d) => a + (d.expectedValue??0), 0) ?? 0} />
                                    </div>
                                    <span className='mx-2'>-</span>
                                    <div>
                                        <b className='mr-1'>{sourceDeals?.length ?? '0'}</b>
                                        <FormattedMessage id="SalesProcess.Deals" />
                                    </div>
                                </div>
                                : ''}
                            {selectedPipeline ?
                                <Button className="btn-sm btn-host" onClick={_ => this.toggleModal('createDeal')}>
                                    <i className="mr-1 fas fa-plus" />
                                    <FormattedMessage id="SalesProcess.Deal" />
                                </Button>
                            : ''}
                        </div>
                    </div>
                    {selectedPipeline ?
                        <div className='mt-4 p-3 h-100 d-flex w-100 overflow-auto'>
                            {selectedPipeline.stages?.sort((a, b) => a.index - b.index).map((stage, key) => {
                                const relatedDeals = sourceDeals.filter(({ salesPipelineStageId }) => salesPipelineStageId === stage.value);

                                return (
                                    <div key={key} className='h-100 mr-3 px-2' style={{ minWidth: '250px', width: '100%', minHeight: '60vh' }}
                                        onDragOver={(e) => { this.onDragOver(e, (excludedStageId !== stage.value ? stage.value : null)) }}
                                        onDrop={(e) => { if (excludedStageId !== stage.value) this.changeDealStage(e) }}
                                    >
                                        <div className='text-center pipeline'>
                                            <div className='arrow top'></div>
                                            <div className='pipelineName bg-white text-truncate'>
                                                <div className='d-flex align-items-center justify-content-center' style={{ maxWidth: '100%' }}>
                                                    {stage.type === "Lead" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-bullseye mr-2"/>
                                                    : stage.type === "DealProcess" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-briefcase mr-2"/>
                                                    : stage.type === "Effective" ?
                                                        <i title={intl.formatMessage({ id: `SalesProcess.${stage.type}` })} className="fas fa-user-tie mr-2"/>
                                                    :''}
                                                    <div title={stage.label} className='text-truncate' style={{ maxWidth: '100%' }}>
                                                        {stage.label}
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: '0.9em' }} className='text-muted d-flex align-items-center justify-content-center'>
                                                    <div>
                                                        <FormatAmountNumber value={relatedDeals.reduce((a, b) => a + (b.expectedValue || 0), 0)}/>
                                                    </div>
                                                    <div className='mx-2'>-</div>
                                                    <div>
                                                        <span className='mr-1'>{relatedDeals.length}</span>
                                                        <FormattedMessage id="SalesProcess.Deals"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='arrow bottom'></div>
                                        </div>
                                        {showDragArea && dragToStageId === stage.value ?
                                            <div className="mt-3" style={{
                                                height: '15px',
                                                backgroundColor: '#165C7D20',
                                                borderRadius: '10px'
                                            }} />
                                        : ''}
                                        <div className='d-flex flex-column mt-2 h-100'>
                                            {relatedDeals.map((deal, k) =>
                                                <div key={k} className='mt-2 py-2 px-3 w-100 bg-white shadow cursor-pointer'
                                                    style={{ borderRadius: '5px' }}
                                                    onClick={_ => selectDealDetail(deal.id)}
                                                    draggable={true}
                                                    onDragStart={(event) => this.onDragStart(event, deal.id, stage.value)}
                                                    onDragEnd={this.onDragEnd}
                                                >
                                                    <div>
                                                        <div className='d-flex align-items-start justify-content-between'>
                                                            <div>
                                                                <div>
                                                                    {deal.name}
                                                                </div>
                                                                {deal.customer.name ?
                                                                    <div>
                                                                        <span style={{ fontSize: '0.8em' }} className='text-muted hover-underline'>
                                                                            <Link to={{ pathname: "/ProfileDetails/" + deal.customer.id }}>
                                                                                {deal.customer.name}
                                                                            </Link>
                                                                        </span>
                                                                    </div>
                                                                :''}
                                                            </div>
                                                            <SalesActionsPopOver
                                                                id={`SalesAction-${key}-${k}`}
                                                                salesUsers={salesUsers}
                                                                placement={'right'}
                                                                deal={deal}
                                                                getDeals={getDeals}
                                                                selectedPipeline={selectedPipeline}
                                                            />
                                                        </div>
                                                        <div className='mt-1 d-flex align-items-center justify-content-between'>
                                                            {deal.businessStartDate ?
                                                                <div className='text-muted' style={{ fontSize: '0.9em' }}>
                                                                    <span>
                                                                        {moment(deal.businessStartDate).format('YYYY-MM-DD')}
                                                                    </span>
                                                                    {deal.businessEndDate ?
                                                                        <>
                                                                            <span className='mx-1'>/</span>
                                                                            <span>{moment(deal.businessEndDate).format('YYYY-MM-DD')}</span>
                                                                        </>
                                                                    :''}
                                                                </div>
                                                            :''}
                                                            <div>
                                                                <b><FormatAmountNumber value={deal.expectedValue || 0}/></b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            )}
                        </div>
                    
                    :
                        <DefaultPage text="SalesProcess.SelectPipeline" icon={this.props.icon} />
                    }
                </BlockUi>
            </div>
        )
    }
}

export default injectIntl(SalesPipelineStage);