import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import MediaPreview from './Media/MediaPreview';
import { getEmojis } from '../../Base/ReferenceDataFunctions';
import { Button, Card, Modal, ModalBody } from 'reactstrap';
import { BlankCard } from '../../Base/CommonUIComponents';
import MapModal from '../../Base/MapModal';

export default class SpeechBubble extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showReactions: false,
            showContactDetails: false
        }
    }

    toggleCollapse = (name) => {
        this.setState(prevState => ({
            [name]: !prevState[name]
        }))
    }

    selectEmoji = (emoji) => {
        this.setState({
            showReactions: false
        }, (e) => this.props.sendReaction(e, { messageId: this.props.messageId, emoji, messageIndex: this.props.messageIndex }))
    }

    render() {
        const { components, big, fromUser = true, status, repliedMessage, maxWidth, hasMoreThan24h, currentMedia, imageMaxHeight, reaction, loadImage = false, fromWhatsAppChat, getMediaDetails = {}, minMediaWidth = '', isTemplate } = this.props;


        return (
            <div className={"d-flex speechBubbleRow" + (reaction ? " mb-4" : " mb-2")} style={{ justifyContent: (fromUser ? 'start' : 'end') }}>
                <div style={{
                /*position: 'absolute',*/ top: '2rem', padding: '0px 1rem 0 2rem', maxWidth: (maxWidth || '95%'),
                    width: Array.isArray(components) ? !hasMoreThan24h ? (components?.filter(({ type, format }) => type === "HEADER" && format === "DOCUMENT" || format === "VIDEO" || format === "AUDIO").length > 0 ? '360px' : 'unset') : components?.filter(({ type, format }) => type === "HEADER" && (format === "VIDEO" || format === "IMAGE")).length > 0 ? '100%' : 'unset' : 'unset',
                    justifyContent: (fromUser ? 'end' : 'start')
            }}>
                    <div>
                        <div className={"speechBubble shadow " + (fromUser ? 'left-point' : 'right-point' )}>
                            {repliedMessage  ?	
                                <div className="mb-2 p-1 replyTo">
                                    <div className="pl-1 mr-2 mb-1"> {repliedMessage.from} </div>
                                    <span className="pl-1"> {repliedMessage.message} </span>
                                </div>
                            : ''}

                            <div>
                                {Array.isArray(components) ?
                                    components.map((component, key) => {

                                        return <div key={key}>
                                            {component.type === "HEADER" ?
                                                <div>
                                                    {currentMedia ?
                                                        <>
                                                            <MediaPreview
                                                                newMedia={false}
                                                                media={currentMedia}
                                                                getMediaDetails={this.props.getMediaDetails}
                                                                loadImage={loadImage}
                                                                maxHeight='150px'
                                                                maxFileHeight='100px'
                                                                fromWhatsAppChat={fromWhatsAppChat}
                                                                components={components}
                                                                minWidth={minMediaWidth}
                                                                loadMediaHeight="100px"
                                                                block={this.props.blockMedia}
                                                            />

                                                            <span style={{ fontSize: '12px' }}> {currentMedia.caption} </span>
                                                        </>
                                                        : component.example?.header_handle && component.example?.header_handle[0] ?
                                                            <MediaPreview
                                                                newMedia={true}
                                                                maxHeight={imageMaxHeight}
                                                                media={{ preview: component.example?.header_handle[0], type: component.format.toLowerCase() }}
                                                                getMediaDetails={() => { }}
                                                                minWidth={minMediaWidth}
                                                                loadMediaHeight="100px"
                                                                loadImage={false}
                                                                isHeaderHandle={true}
                                                            />
                                                            : component.format === "TEXT" ?
                                                                <div>
                                                                    <b>{component.text}</b>
                                                                </div>

                                                                : component.format === "LOCATION" ?
                                                                    <div style={{ fontSize: '12px', width: '300px' }}>
                                                                        <a href={`https://www.google.com/maps/search/?api=1&query=${component.latitude},${component.longitude}`} target="_blank">
                                                                            <MapModal
                                                                                mapHeight='100px'
                                                                                latitude={component.latitude}
                                                                                longitude={component.longitude}
                                                                                onClick={() => { }}
                                                                                disableInteractions={true}
                                                                            />
                                                                        </a>
                                                                        {component.name &&
                                                                            <div className="mt-2 color-host">
                                                                                <a href={component.url || `https://www.google.com/maps/search/?api=1&query=${component.latitude},${component.longitude}`} target="_blank">
                                                                                    {component.name}
                                                                                </a>
                                                                            </div>
                                                                        }
                                                                        {component.address && <span> {component.address}</span>}
                                                                    </div>
                                                                    :
                                                                    <div style={{
                                                                        width: '100%', height: '100px', background: 'rgb(204, 208, 213)', display: 'flex', alignItems: 'center',
                                                                        justifyContent: 'center', fontSize: '3em', color: 'white', marginBottom: '0.5rem', minWidth: big ? '200px' : minMediaWidth
                                                                    }}>
                                                                        {component.format === "IMAGE" ?
                                                                            <i className="far fa-image" />
                                                                            : component.format === "VIDEO" ?
                                                                                <i className="fas fa-video" />
                                                                                : component.format === "DOCUMENT" ?
                                                                                    <i className="fas fa-file" />
                                                                                    : ''}

                                                                    </div>
                                                    }
                                                </div>
                                                : component.type === "BODY" ?
                                                    <div style={{ fontSize: '12px', whiteSpace: 'break-spaces' }}>
                                                        {
                                                            component.text?.slice(0, 300)
                                                            //to avoid overflow
                                                        }
                                                    </div>
                                                    : component.type === "FOOTER" ?
                                                        <div className='mt-2' style={{ color: 'grey', fontSize: '12px' }}>
                                                            {component.text}
                                                        </div>
                                                        : component.type === "BUTTONS" && component.buttons && component.buttons.length > 0 ?
                                                            <div className='text-center mt-2 pointer' style={{ fontSize: '12px', color: '#33b7f6', borderTop: '1px #979797 solid' }}>
                                                                {component.buttons?.map((button, k) =>
                                                                    <div key={k} className={`${k === 0 ? 'mt-2 mb-1' : ''}`}>
                                                                        <b>
                                                                            {button.type === "URL" ?
                                                                                <i className="fas fa-external-link-alt mr-2" />
                                                                                : ''}
                                                                            <span>
                                                                                {button.text}
                                                                            </span>
                                                                        </b>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            : ''}
                                        </div>
                                    })
                                    : components?.type === "CONTACT" && components?.contacts?.length > 0 ?
                                        components.contacts?.length > 1 ?
                                            <div style={{ fontSize: '12px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }} className="pointer" onClick={() => this.toggleCollapse('showContactDetails')}>
                                                    <div style={{ width: '35px', height: '35px', borderRadius: '35px', backgroundColor: '#ccd0d5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <i className="icon-icon-associated-profiles color-white" style={{ fontSize: '16px' }}/>
                                                    </div>
                                                    <div className="ml-2 text-host pointer">
                                                        {components.contacts[0].name?.formatted_name}
                                                        <FormattedMessage id="WhatsApp.OtherContacts" values={{ contacts: components.contacts.length - 1 }} />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ fontSize: '12px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }} className="pointer" onClick={() => this.toggleCollapse('showContactDetails')}>
                                                    <div style={{ width: '35px', height: '35px', borderRadius: '35px', backgroundColor: '#ccd0d5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <i className="icon-icon-profile color-white" style={{ fontSize: '15px' }} />
                                                        </div>
                                                    <div className="ml-2 text-host">
                                                        {components.contacts[0].name?.formatted_name}
                                                    </div>
                                                </div>
                                            </div>
                                : ''}
                            </div>

                            {status ?
                                status.failure !== null ?
                                    <div className="mt-1" style={{ color: '#546168', fontSize: '11px', textAlign: 'right' }}>
                                        {moment(status.failure).format("HH:mm")}

                                        {isTemplate || hasMoreThan24h ?
                                            <i className="icon-icon-warnings-enabled text-warning ml-1" />
                                            :
                                            <i className="icon-icon-warnings-enabled text-warning ml-1 pointer" onClick={this.props.resendMessage} />
                                        }
                                    </div>

                                : status.sent !== null &&
                                    <div className="mt-1" style={{ color: '#546168', fontSize: '11px', textAlign: 'right' }}>
                                        {moment(status.sent).format("HH:mm")}

                                        {status.read !== null ?
                                            <i className="fas fa-check-double color-host ml-1" />
                                            : status.delivered !== null ?
                                                    <i className={"fas fa-check-double ml-1 " + (!this.props.isAnOldMessage ? ' pointer' : '')} onClick={!this.props.isAnOldMessage ? this.props.markAsRead : () => {}} />
                                                : <i className={"fa-sm fas fa-check  ml-1"} />
                                        }
                                    </div>
                            : ''}

                            {reaction ?
                                <div className="circle-sm shadow color-red" style={{ backgroundColor: 'white', position: 'absolute', bottom: '-18px', right: '5px', border: '1px solid #dddddd' }}>
                                    {reaction.body}
                                </div>
                                : ''}

                            {/*hasMoreThan24h ?
                                <div className="msgInteraction text-muted pointer" style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                    <i className="fas fa-sm fa-chevron-down"/>
                                </div>
                            : ''*/}
                        </div>
                    </div>
                </div>

                {fromUser && !hasMoreThan24h && !this.props.isAnOldMessage ?
                    <div className="msgInteraction">
                        <div className="circle-sm mr-1 pointer" style={{ backgroundColor: '#7878786b', position: 'relative' }} onClick={() => this.toggleCollapse('showReactions')} >
                            <i className="fas fa-sm fa-laugh-beam color-white" />

                            {this.state.showReactions ?
                                <div className="bg-white shadow d-flex color-red" style={{ position: 'absolute', bottom: '30px', left: '-30px', /*marginBottom: '85px',*/ padding: '5px 10px', borderRadius: '10px' }}>
                                    {getEmojis().map((el, idx) => <span key={idx} className="mx-1 pointer" onClick={() => this.selectEmoji(el)}> {el} </span>)}
                                </div>
                            : ''}
                        </div>


                        <div className="circle-sm color-white pointer" style={{ backgroundColor: '#7878786b' }} onClick={this.props.replyMessage && this.props.replyMessage}>
                            <i className="fas fa-sm fa-reply" />
                        </div>
                    </div>
                : ''}

                {this.state.showContactDetails && components ?
                    <Modal isOpen={this.state.showContactDetails} fade={false} size={"md"} style={{ minWidth: 0 }} >
                        <Button onClick={() => this.toggleCollapse('showContactDetails')} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <ModalBody>
                            <BlankCard >
                                <h5 className="mb-4 ml-2">{components.contacts?.length} <FormattedMessage id="WhatsApp.Contact(s)" /></h5>

                                {components.contacts?.map((contact, key) =>
                                    <Card className="border-0 shadow mb-2 p-4" key={key}>
                                        <div className="d-flex align-items-center mb-3">
                                            <div style={{ width: '35px', height: '35px', borderRadius: '35px', backgroundColor: '#ccd0d5', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <i className="icon-icon-profile color-white" style={{ fontSize: '15px' }} />
                                            </div>
                                            <div className="ml-3">
                                                <div>{contact.name?.formatted_name}</div>
                                                {contact.org ?
                                                    <span className="text-muted">
                                                        {contact.org.title} - {contact.org.company}
                                                    </span>
                                                : ''}
                                            </div>
                                        </div>

                                        {contact.phones?.length > 0 && contact.phones.map((phone, idx) =>
                                            <div className="d-flex align-items-center border-bottom p-2" key={idx}>
                                                <div>
                                                    <i className="fas fa-phone mr-3 ml-1" />
                                                </div>
                                                <div className="ml-2">
                                                    <div>{phone.phone}</div>
                                                    <span className="text-muted">{phone.type}</span>
                                                </div>
                                            </div>
                                        )}

                                        {contact.addresses?.length > 0 && contact.addresses.map((address, idx) =>
                                            <div className="d-flex align-items-center border-bottom p-2" key={idx}>
                                                <div>
                                                    <i className="icon-icon-addresses mr-3 ml-1" />
                                                </div>
                                                <div className="ml-2">
                                                    <div>{address.street} {address.city} {address.state} {address.zip} {address.country}</div>
                                                    <span className="text-muted">{address.type}</span>
                                                </div>
                                            </div>
                                        )}

                                        {contact.emails?.length > 0 && contact.emails.map((email, idx) =>
                                            <div className="d-flex align-items-center border-bottom p-2" key={idx}>
                                                <div>
                                                    <i className="fas fa-at mr-3 ml-1" />
                                                </div>
                                                <div className="ml-2">
                                                    <div>{email.email}</div>
                                                    <span className="text-muted">{email.type}</span>
                                                </div>
                                            </div>
                                        )}

                                        {contact.urls?.length > 0 && contact.urls.map((url, idx) =>
                                            <div className="d-flex align-items-center border-bottom p-2" key={idx}>
                                                <div>
                                                    <i className="fas fa-globe mr-3 ml-1" />
                                                </div>
                                                <div className="ml-2">
                                                    <div>{url.url}</div>
                                                    <span className="text-muted">{url.type}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Card>
                                )}
                            </BlankCard>
                        </ModalBody>
                    </Modal>
                    : ''}
            </div>
        )
    }
}