import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import { getColorPallete, getTagGroupIcons } from '../Base/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';
import { ErrorAlert } from "../Base/ErrorAlert";
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../Base/API';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import { FormatAmountNumber, SelectHotelWithLoadedData } from '../Base/CommonUIComponents';
import { ChartWithCustomLegend, getPieOptions } from '../Base/CustomCharts';


class ProfileOverview extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            blockReservations: false,
            blockRevenue: false,
            profile: {},
            collapse: false,
            reservationsByHotel: { "datasets": [{ "label": "Reservations by Hotel", "data": [], "backgroundColor": getColorPallete() }], "labels": [] },
            reservationsByChannel: { "datasets": [{ "label": "Reservations by Channel", "data": [], "backgroundColor": getColorPallete() }, { "label": "Reservations by Market", "data": [], "backgroundColor": getColorPallete() }], "labels": [] },
            totalChargesAmount: { labels: [], datasets: [{ label: 'Total Amount', fill: true, tension: 0.3, data: [], borderColor: '#0867fa', backgroundColor: '#0867fa33' }] },
            channelMarketLegend: { channel: [], market: [] },
            revenueSummary: [],
            amountByHotel: {},
            amountByCharge: {},
            revenueByCharge: { datasets: [], labels: [] },
            pastReservations: [],
            futureReservations: [],
            avgStays: [],
            tags: null,
            amountByChargeLegend: [],
            backgroundColor: getColorPallete(),
            hotelId: this.props.fixedHotelFilter ?? null
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.profileId) {
            this.getProfileReservationsSummary();
            this.getRevenueSummary();
        }
    }

    getRevenueSummary = () => {
        this.setState({ blockRevenue: true });

        var params = '';
        if (this.props.startDate) params += `beginDate=${moment(this.props.startDate).format('YYYY-MM-DD')}&`;
        if (this.props.endDate) params += `endDate=${moment(this.props.endDate).format('YYYY-MM-DD')}&`;
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRevenue: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ blockRevenue: false })
                }
                else {
                    this.setState({
                        revenueSummary: data.response
                    }, () => this.calculateRevenueData(data.response))
                };
            }
        }, `/api/gms/Reservation/v1/revenue/${this.props.profileId}/reveneuSummary?` + params)
    }

    getProfileReservationsSummary = () => {
        this.setState({ blockReservations: true });

        var params = '';
        if (this.props.startDate) params += `startDate=${moment(this.props.startDate).format('YYYY-MM-DD')}&`;
        if (this.props.endDate) params += `endDate=${moment(this.props.endDate).format('YYYY-MM-DD')}&`;
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockReservations: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ blockReservations: false });
                }
                else {
                    this.setState({
                        reservationSummary: data.response
                    }, () => this.calculateReservationsData(data.response))
                }
            }
        }, `/api/gms/Reservation/v1/reservations/profile/${this.props.profileId}/summary?` + params)
    }

    calculateReservationsData = (reservationSummary) => {
        //NSC = no show or canceled
        let pastReservations = [], futureReservations = [], avgStays = []; //By currency code
        let resByHotel = new Map();

        let reservationsByHotel = { "datasets": [{ "label": "Reservations by Hotel", "data": [], "backgroundColor": getColorPallete() }], "labels": [] };
        let reservationsByChannel = { "datasets": [{ "label": "Reservations by Channel", "data": [], "backgroundColor": getColorPallete() }, { "label": "Reservations by Market", "data": [], "backgroundColor": getColorPallete() }], "labels": [] };

        let channelMarketLegend = { channel: [], market: [] };
        reservationsByChannel.datasets[0].data = [];
        reservationsByChannel.datasets[1].data = [];
        reservationsByHotel.datasets[0].data = [];


        reservationSummary && reservationSummary.forEach(hotel => {
            let currencyIdx = pastReservations.findIndex(el => el.currency === hotel.hotelCurrencyCode);

            if (currencyIdx === -1) {
                currencyIdx = pastReservations.length;

                futureReservations.push({
                    currency: hotel.hotelCurrencyCode,
                    totalReservations: hotel.futureReservations,
                    totalReservationsNSC: hotel.canceledFutureReservations,
                    totalAmount: hotel.futureReservationsAmount
                });

                pastReservations.push({ currency: hotel.hotelCurrencyCode, totalReservations: 0, totalReservationsNSC: 0, totalAmount: 0, totalRoomNights: 0 }); //All Reservations
                avgStays.push({ currency: hotel.hotelCurrencyCode, avgStays: 0, avgAmount: 0 });
            }
            else {
                futureReservations[currencyIdx].totalReservations += hotel.futureReservations;
                futureReservations[currencyIdx].totalReservationsNSC += hotel.canceledFutureReservations;
                futureReservations[currencyIdx].totalAmount += hotel.futureReservationsAmount;
            }

            if (resByHotel.get(hotel.hotelName) === undefined) {
                resByHotel.set(hotel.hotelName, 0);
            }

            hotel.dataByDate && hotel.dataByDate.forEach(date => {

                date.dataByChannel && date.dataByChannel.forEach(channel => {
                    let channelIdx = channelMarketLegend.channel.findIndex(el => el.label === `Channel-${channel.channel}`);
                    let marketIdx = channelMarketLegend.market.findIndex(el => el.label === `Market-${channel.distChannelCode}`);

                    if (channelIdx === -1) {
                        channelMarketLegend.channel.push({ value: channel.channel, label: `Channel-${channel.channel}`, total: 0 });
                        channelIdx = channelMarketLegend.channel.length -  1;
                    }
                    if (marketIdx === -1) {
                        channelMarketLegend.market.push({ value: channel.distChannelCode, label: `Market-${channel.distChannelCode}`, total: 0 });
                        marketIdx = channelMarketLegend.market.length - 1;
                    }

                    channel.dataByStatus && channel.dataByStatus.forEach(reservation => {

                        //Cards
                        if (reservation.pmsStatus != 6 && reservation.pmsStatus != 7) {
                            pastReservations[currencyIdx].totalRoomNights += reservation.totalRoomNights;
                            pastReservations[currencyIdx].totalReservations += reservation.totalReservations;
                            pastReservations[currencyIdx].totalAmount += reservation.totalAmount;
                        }
                        else {
                            pastReservations[currencyIdx].totalReservationsNSC += reservation.totalReservations;
                        }

                        //Reservation By Hotel
                        resByHotel.set(hotel.hotelName, resByHotel.get(hotel.hotelName) + 1);

                        //Reservation By Channel and Market
                        channelMarketLegend.channel[channelIdx].total += 1;
                        channelMarketLegend.market[marketIdx].total += 1;
                    })
                })
            })
        })


        //Avg
        avgStays.forEach((el, key) => {
            const allReservations = pastReservations[key];

            el.avgStays = allReservations.totalRoomNights / allReservations.totalReservations;
            el.avgAmount = allReservations.totalAmount / allReservations.totalRoomNights;
        })

        //Reservation By Hotel
        reservationsByHotel.labels = [...resByHotel.keys()];
        reservationsByHotel.labels.forEach((item, idx) => {
            reservationsByHotel.datasets[0].data.push(resByHotel.get(item));
        });

        //Reservation By Channel and Market
        const results = this.getReservationData(reservationsByChannel, channelMarketLegend);

        reservationsByChannel = results.reservationsByChannel;
        channelMarketLegend = results.channelMarketLegend;

        this.setState({
            pastReservations, futureReservations, avgStays,
            reservationsByHotel, channelMarketLegend,
            blockReservations: false
        })
    }

    getReservationData = (reservationsByChannel, channelMarketLegend) => {
        const color = getColorPallete();
        let aux = 0;

        channelMarketLegend.channel.forEach(el => {
            reservationsByChannel.labels.push(el.label);
            reservationsByChannel.datasets[0].labels = el.label;

            el.color = color[aux];
            aux++;
        })

        channelMarketLegend.market.forEach(el => {
            reservationsByChannel.labels.push(el.label);
            reservationsByChannel.datasets[1].labels = el.label;

            el.color = color[aux];
            aux++;
        })

        reservationsByChannel.labels.forEach(el => {
            const index = [...channelMarketLegend.channel].findIndex(channel => channel.label === el);

            reservationsByChannel.datasets[0].data.push(index !== -1 ? channelMarketLegend.channel[index].total : 0);
        });

        reservationsByChannel.labels.forEach(el => {
            const index = channelMarketLegend.market.findIndex(market => market.label === el);

            reservationsByChannel.datasets[1].data.push(index !== -1 ? channelMarketLegend.market[index].total : 0);
        });

        return { reservationsByChannel, channelMarketLegend };
    }

    calculateRevenueData = (revenueSummary) => {
        var amountCharge = [];
        var amountByHotel = { datasets: [], labels: [] };
        var amountByCharge = { datasets: [{ label: "Amount by Charge", data: [], backgroundColor: getColorPallete() }], labels: [] };
        var totalChargesAmount = { datasets: [], labels: [] };
        const colorPalette = getColorPallete()

        //Month
        var months = this.getMounths(revenueSummary);

        amountByHotel.labels = months;
        totalChargesAmount.labels = months;

        revenueSummary && revenueSummary.forEach((revenue, i) => {
            if (revenue.isPayment === false) {
                const dateIndex = amountByHotel.labels.indexOf(amountByHotel.labels.find(el => el === moment(revenue.pmsTimestamp).format('MMM YYYY')));

                //Amount by hotel
                const hotelLabel = `${revenue.hotelName} (${revenue.hotelCurrencyCode})`;
                const currentHotel = amountByHotel.datasets.find(el => el.label === hotelLabel);

                if (currentHotel === undefined) {
                    amountByHotel.datasets.push({ label: hotelLabel, data: Array(months.length).fill(0), backgroundColor: colorPalette[amountByHotel.datasets.length] });
                    amountByHotel.datasets[amountByHotel.datasets.length - 1].data[dateIndex] = revenue.totalAmount;
                }
                else {
                    currentHotel.data[dateIndex] += revenue.totalAmount;
                }


                //Amount by charge
                amountCharge = this.buildAmountCharge(amountCharge, revenue);


                //Total Charge Amount
                let totalChargesAmountIndex = totalChargesAmount.datasets.findIndex(el => el.label === revenue.hotelCurrencyCode);

                if (totalChargesAmountIndex === -1) {
                    totalChargesAmountIndex = totalChargesAmount.datasets.length;

                    totalChargesAmount.datasets.push({
                        label: revenue.hotelCurrencyCode,
                        data: Array(months.length).fill(0),
                        backgroundColor: colorPalette[totalChargesAmount.datasets.length],
                        borderColor: colorPalette[totalChargesAmount.datasets.length]
                    });
                }
                totalChargesAmount.datasets[totalChargesAmountIndex].data[dateIndex] += revenue.totalAmount;

            }
        })

        let aux = 0;
        amountCharge.map(el => {
            el.groupedLegend && el.groupedLegend.map(cg => {
                cg.color = (colorPalette[aux] || '#e5e5e5');
                aux++;
                amountByCharge.labels.push(`${cg.label} (${cg.currency})`);
                amountByCharge.datasets[0].data.push(parseFloat(cg.totalAmount).toFixed(2));
            })
        });

        amountByHotel.datasets = amountByHotel.datasets.map(abh => ({ ...abh, data: abh.data.map(el => (parseFloat(el).toFixed(2))) }));
        totalChargesAmount.datasets.forEach(dataset => {
            dataset.data = dataset.data?.map(el => (parseFloat(el).toFixed(2)));
        })

        this.setState({
            amountByHotel, amountByCharge, totalChargesAmount,
            amountByChargeLegend: amountCharge,
            blockRevenue: false
        })
    }

    getMounths = (revenueSummary) => {
        var months = [];

        if (this.props.startDate && this.props.endDate) {
            const start = this.props.startDate;
            const end = this.props.endDate;
            var currMonth = moment(start).clone();

            months.push(currMonth.format('MMM YYYY'))
            while (currMonth.add(1, 'month').diff(end) < 0) {
                months.push(moment(currMonth).format('MMM YYYY'));
            }
        }
        else {
            if (revenueSummary) {
                revenueSummary.sort((a, b) => { return moment(a.pmsTimestamp) - moment(b.pmsTimestamp) }).forEach((data) => {
                    const date = moment(data.pmsTimestamp).format('MMM YYYY');

                    if (!months.some(el => el === date)) {
                        months.push(date);
                    }
                })
            }
        }

        return months;
    }

    buildAmountCharge = (amountCharge, revenue) => {
        const salesGroupLabel = (revenue.salesGroupDescription || "N/A") + ` (${revenue.hotelCurrencyCode})`;
        const chargeGroupLabel = (revenue.chargeGroupDescription || "N/A") ;

        const salesGroup = amountCharge.findIndex(el => el.label === salesGroupLabel);

        if (salesGroup === -1) {
            amountCharge.push({ label: salesGroupLabel, groupedLegend: [{ label: chargeGroupLabel, totalAmount: revenue.totalAmount, color: '#e5e5e5', currency: revenue.hotelCurrencyCode, isVisible: true }] })
        }
        else {
            const chargeGroupIdx = amountCharge[salesGroup].groupedLegend.findIndex(el => el.label === chargeGroupLabel);

            if (chargeGroupIdx === -1) {
                amountCharge[salesGroup].groupedLegend.push({ label: chargeGroupLabel, totalAmount: revenue.totalAmount, color: '#e5e5e5', currency: revenue.hotelCurrencyCode });
            }
            else {
                amountCharge[salesGroup].groupedLegend[chargeGroupIdx].totalAmount += revenue.totalAmount;
            }
        }

        return amountCharge;
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => { this.getRevenueSummary(); this.getProfileReservationsSummary(); })
    }

    getTags = (tagGroupIcons) => {
        const { tags } = this.props;

        return (tags ?
            <Row>
                {tags.map((tagGroup, key) =>
                    <Col className="col-6 mb-2" key={key}>
                        <h6>
                            <b> {tagGroup.tagGroupCode} </b>
                            {tagGroupIcons.find(group => group.group.toLowerCase() === tagGroup.tagGroupCode.toLowerCase()) ?
                                <i className={'ml-2 ' + tagGroupIcons.find(group => group.group.toLowerCase() === tagGroup.tagGroupCode.toLowerCase()).icon} />
                            : ''}
                        </h6>
                        {tagGroup.tags.map((tag, i) =>
                            <span key={i}>
                                <Badge className="mr-2 p-2 mb-2" color="primary"> {tag.code}
                                    {tag.frequency > 0 ? <span className="bg-white text-host ml-2 px-1" style={{ borderRadius: '20px' }}>{tag.frequency} </span> : ''}
                                </Badge>
                            </span>
                        )}
                    </Col>
                )}
            </Row>
            : '')
    }

    renderMonthElement = ({ month, onYearSelect }) => {

        let i;
        let years = [];
        for(i = moment().year(); i >= moment().year() - 10; i--) {
            years.push(<option onClick={(e) => onYearSelect(month, e.target.id)} id={i} key={`year-${i}`}>{i}</option>);
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Row>
                    <Col>
                        {moment(month).locale(this.props.intl.locale).format('MMMM')}
                    </Col>
                    <Col>
                        <select onChange={(e) => onYearSelect(month, e.target.value)} value={month.year()}>
                            {years}
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { error, pastReservations, futureReservations, avgStays, blockReservations, blockRevenue, tags, amountByChargeLegend, reservationsByHotel, reservationsByChannel, amountByCharge, amountByHotel, totalChargesAmount } = this.state;
        const { startDate, endDate, handleDates, hotels, intl } = this.props;

        const resByChannelMarketBigLeg = this.state.channelMarketLegend.channel.length + this.state.channelMarketLegend.market.length > 6 ? true : false;

        const optionsPie = getPieOptions();

        const optionsLine = {
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: intl.formatMessage({ id: "ProfileOverview.Months" }),
                        align: 'end',
                        font: { size: '11px' },
                        padding: 0
                    }
                },
                y: {
                    beginAtZero: true,
                    min: 0,
                    grid: { display: false },
                    title: {
                        display: true,
                        text: intl.formatMessage({ id: "ProfileOverview.Amount" }),
                        align: 'end',
                        font: { size: '10px' },
                        padding: { top: 0 }
                    }
                }
            },
            plugins: {
                legend: { position: 'top' },
                datalabels: { display: false }
            }
        }

        const optionsBar = {
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                    grid: { display: false },
                    display: true,
                    beginAtZero: true,
                    min: 0,
                    title: {
                        display: true,
                        text: intl.formatMessage({ id: "ProfileOverview.Months" }),
                        align: 'end',
                        font: { size: '11px' },
                        padding: 0
                    }
                },
                y: {
                    stacked: true,
                    grid: { display: true },
                    title: {
                        display: true,
                        text: intl.formatMessage({ id: "ProfileOverview.Amount" }),
                        align: 'end',
                        font: { size: '10px' },
                        padding: { top: 0 }
                    }
                }
            },
            plugins: {
                legend: { position: 'top' },
                datalabels: { display: false }
            }
        }

        const tagGroupIcons = getTagGroupIcons();

        return (
            <div>
                <ErrorAlert error={error} />
                <Card className="ml-1 my-0 mr-0 border-0 bg-light">
                    <div className="m-2 p-2">
                        <Row className="mb-3">
                            <Col className="col-4 ">
                                <Row>
                                    <Col className="col-6">
                                        <SelectHotelWithLoadedData
                                            name='hotelId'
                                            onChangeFunc={this.handleChangeSelect}
                                            value={this.state.hotelId || ''}
                                            options={hotels}
                                        />
                                    </Col>
                                    <Col className="col-6">
                                        <Card className={"py-2 text-center border-0 shadow" + (this.props.warnings && this.props.warnings.find(warn => warn.hasWarning === true) ? "" : ' text-muted bg-light')} id="warnings" style={{ minHeight : "38px" }}>
                                            <div>
                                                <i className={this.props.warnings && this.props.warnings.find(warn => warn.hasWarning === true) ? 'icon-icon-warnings-enabled text-warning mr-2' : 'icon-icon-warnings-disabled mr-2'} />
                                                <FormattedMessage id="ProfileDetails.Warnings" />
                                            </div>
                                        </Card>

                                        {this.props.warnings && this.props.warnings.find(warn => warn.hasWarning === true) ?
                                            <UncontrolledTooltip placement="bottom" target="warnings"> 
                                                {this.props.warnings.map((warning, key) => warning.hasWarning ?
                                                    <span key={key}> {warning.warningText} <br /></span>
                                                : '')}
                                            </UncontrolledTooltip>
                                        : ''}
                                    </Col>
                                </Row>
                                
                            </Col>
                           
                            <Col className="text-center">
                                <h5><b>Overview</b></h5>
                            </Col>

                            <Col className="text-right">
                                <DateRangePicker
                                    startDate={startDate}
                                    startDateId="startDate"
                                    isOutsideRange={day => false}
                                    endDate={endDate}
                                    endDateId="endDate"
                                    onDatesChange={({ startDate, endDate }) => handleDates(startDate, endDate)}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={props => this.renderMonthElement(props)}
                                />

                                <Button className="btn btn-host ml-2" onClick={this.doSearch}>
                                    <i className="icon-icon-search"></i>
                                </Button>
                            </Col>
                        </Row>                      


                        <BlockUi tag="div" blocking={blockReservations}>
                            <Row className="mt-2">
                                <Col>
                                    <Card className="shadow border-0 h-100">
                                        <CardBody>
                                            <Row >
                                                <Col><h5 className="text-muted"><FormattedMessage id="ProfileOverview.PastReservations" /></h5></Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6 mb-1">
                                                    <Row className="title-sm">
                                                        <Col className="col-6 pr-0">
                                                            <FormattedMessage id="ProfileOverview.Effective"/>
                                                        </Col>
                                                        <Col className="col-6 pr-0">
                                                            <FormattedMessage id="ProfileOverview.Canceled" />
                                                        </Col>
                                                    </Row> 
                                                </Col>
                                            </Row>
                                            {pastReservations.map((pastRes, key) =>
                                                <Row key={key} className={key > 0 ? ' mt-2' : ''}>
                                                    <Col className="my-auto">
                                                        <Row>
                                                            <Col className="col-6 pr-0">
                                                                <h4> <b># {pastRes.totalReservations}</b></h4> 
                                                            </Col>
                                                            <Col className="col-6 pr-0">
                                                                <h4> <b className="color-light-red"># {pastRes.totalReservationsNSC || 0}</b> </h4>
                                                            </Col>
                                                        </Row> 
                                                    </Col>
                                                    <Col className="text-right ">
                                                        <h4 className="mb-0">
                                                            <b> <FormatAmountNumber value={pastRes.totalAmount} currency={pastRes.currency} /></b>
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            )}
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col>
                                    <Card className="shadow  border-0 h-100">
                                        <CardBody>
                                            <Row>
                                                <Col><h5 className="text-muted"><FormattedMessage id="ProfileOverview.FutureReservations" /></h5></Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6 mb-1">
                                                    <Row className="title-sm">
                                                        <Col className="col-6 pr-0">
                                                            <FormattedMessage id="ProfileOverview.Effective" />
                                                        </Col>
                                                        <Col className="col-6 pr-0">
                                                            <FormattedMessage id="ProfileOverview.Canceled" />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {futureReservations.map((futureRes, key) =>
                                                <Row key={key} className={key > 0 ? ' mt-2' : ''}>
                                                    <Col className="my-auto">
                                                        <Row>
                                                            <Col className="col-6 pr-0">
                                                                <h4> <b># {futureRes.totalReservations}</b></h4>
                                                            </Col>
                                                            <Col className="col-6 pr-0">
                                                                <h4> <b className="color-light-red"># {futureRes.totalReservationsNSC || 0}</b> </h4>
                                                            </Col>
                                                        </Row> 
                                                    </Col>
                                                    <Col className="text-right ">
                                                        <h4 className="mb-0">
                                                            <b> <FormatAmountNumber value={futureRes.totalAmount} currency={futureRes.currency} /></b>
                                                        </h4>
                                                    </Col>
                                                </Row>
                                            )}
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col>
                                    <Card className="shadow  border-0 h-100">
                                        <CardBody className="d-flex flex-column justify-content-between">
                                            <Row className="mb-2">
                                                <Col><h5 className="text-muted"><FormattedMessage id="ProfileOverview.AvgStays" /></h5></Col>
                                            </Row>
                                            <div>
                                                {avgStays.map((avg, key) =>
                                                    <Row key={key} className={key > 0 ? ' mt-2' : ''}>
                                                        <Col className="my-auto">
                                                            <h4><b># <FormattedNumber value={avg.avgStays || 0} maximumFractionDigits={2} /> </b></h4>
                                                        </Col>
                                                        <Col className="text-right ">
                                                            <h4 className="mb-0">
                                                                <b> <FormatAmountNumber value={avg.avgAmount || 0} currency={avg.currency} /></b>
                                                            </h4>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>

                                </Col>
                            </Row>
                        </BlockUi>

                        <BlockUi tag="div" blocking={blockReservations}>
                            <Row className="mt-3">
                                <Col className="col-4">
                                    <Card className="shadow border-0 h-100">
                                        <CardBody>
                                            <Row className="mb-2">
                                                <Col className="col-7">
                                                    <h5 className="text-muted"> <FormattedMessage id="ProfileOverview.Interests/Tags" /></h5>
                                                </Col>
                                                <Col className="col-5 pl-0 text-right title-sm">
                                                    <span id="RegardlessOfDates" >
                                                        <i className="text-secondary fas fa-info-circle " style={{ fontSize: '13px' }} />
                                                    </span>
                                                    <UncontrolledTooltip data-trigger="focus" placement="left" target="RegardlessOfDates">
                                                        <FormattedMessage id="ProfileOverview.RegardlessOfDates" />
                                                    </UncontrolledTooltip>
                                                </Col>
                                            </Row>
                                            {this.getTags(tagGroupIcons)}
                                        </CardBody>
                                    </Card>
                                </Col> 

                                <Col className="col-4">
                                    <Card className="shadow  border-0 h-100">
                                        <CardBody style={{ height: '270px' }}>
                                            <h5 className="text-muted"><FormattedMessage id="ProfileOverview.ReservationByHotel" /></h5>

                                            {reservationsByHotel.datasets && reservationsByHotel.datasets.length > 0 && reservationsByHotel.datasets[0].data.length > 0 ?
                                                <ChartWithCustomLegend
                                                    type="Doughnut"
                                                    colSize={{ chart: 7, legend: 5 }}
                                                    height={180}
                                                    legendPlacement="left"
                                                    data={reservationsByHotel}
                                                />
                                            : ''}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-4">
                                    <Card className="shadow border-0 h-100">
                                        <CardBody style={{ height: '270px' }}>
                                            <h5 className="text-muted"><FormattedMessage id="ProfileOverview.ReservationByChannel" /></h5>
                                            <Row className="h-100">
                                                <Col className={"col-5 pr-0 " + (resByChannelMarketBigLeg ? ' chartLegend' : ' d-flex flex-column justify-content-center pb-3')} style={{ color: '#9d9d9d' }}>
                                                    {this.state.channelMarketLegend.channel.length > 0 ?
                                                        <div>
                                                            <div className="text-muted font_size_xxs" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '11px' }}>
                                                                 <FormattedMessage id="ProfileOverview.Channels" />
                                                            </div>

                                                            <div>
                                                                {this.state.channelMarketLegend.channel.map((channel, key) =>
                                                                    <div key={key} className="mb-1 pl-2" style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '12px', fontStyle: 'normal', fontColor: '#666', whiteSpace: 'nowrap', textOverflow: 'ellipsis'/*, overflow: 'hidden', display: 'inline-flex'*/ }}>
                                                                        <div className="align-items-center" style={{ display: 'inline-flex' }} title={channel.value}>
                                                                            <div style={{ width: '35px', height: '9.5px', backgroundColor: channel.color }} className="mr-2" />
                                                                            {channel.value}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    : ''}

                                                    {this.state.channelMarketLegend.market.length > 0 ?
                                                        <div className="mt-2">
                                                            <div className="text-muted font_size_xxs" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '11px' }}>
                                                                <FormattedMessage id="ProfileOverview.Market" />
                                                            </div>
                                                            <div>
                                                                {this.state.channelMarketLegend.market.map((market, key) =>
                                                                    <div key={key} className="mb-1 pl-2" style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '12px', fontStyle: 'normal', fontColor: '#666', whiteSpace: 'nowrap', textOverflow: 'ellipsis'/*, overflow: 'hidden', display: 'inline-flex'*/ }}>
                                                                        <div className="align-items-center" style={{ display: 'inline-flex' }} label={market.value}>
                                                                            <div style={{ width: '35px', height: '9.5px', backgroundColor: market.color }} className="mr-2" />
                                                                            {market.value}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </Col>
                                                <Col className="p-0 col-7 pb-3 d-flex align-items-center">
                                                    <div>
                                                        {reservationsByChannel.datasets && reservationsByChannel.datasets.length > 0 && reservationsByChannel.datasets[0].data.length > 0 ?
                                                            <div className="w-100">
                                                                <Doughnut height={180} data={reservationsByChannel} options={optionsPie} />
                                                            </div>
                                                        : ''}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </BlockUi>
                        
                        <BlockUi tag="div" blocking={blockRevenue}>
                            <Row className="mt-3">
                                <Col className="col-4">
                                    <Card className="shadow border-0 h-100">
                                        <CardBody>
                                            <h5 className="text-muted"><FormattedMessage id="ProfileOverview.RevenueByHotel" /></h5>
                                            {amountByHotel.datasets && amountByHotel.datasets.length > 0 && amountByHotel.datasets[0].data.length > 0 ?
                                                <div className="w-100">
                                                    <Bar data={amountByHotel} options={optionsBar} />
                                                </div>
                                            : ''}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-4">
                                    <Card className="shadow border-0 h-100">
                                        <CardBody style={{ height: '270px' }}>
                                            <h5 className="text-muted"><FormattedMessage id="ProfileOverview.RevenueByCharge" /></h5>

                                            {amountByCharge.datasets && amountByCharge.datasets.length > 0 && amountByCharge.datasets[0].data.length > 0 ?
                                                <ChartWithCustomLegend
                                                    type="Doughnut"
                                                    colSize={{ chart: 7, legend: 5 }}
                                                    height={180}
                                                    legendPlacement="left"
                                                    data={amountByCharge}
                                                    groupedLegend={amountByChargeLegend}
                                                />
                                            : ''}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col className="col-4"> 
                                    <Card className="shadow border-0 h-100">
                                        <CardBody>
                                            <h5 className="text-muted"><FormattedMessage id="ProfileOverview.MonthlyChargeRevenue" /></h5>
                                            {totalChargesAmount.datasets && totalChargesAmount.datasets.length > 0 && totalChargesAmount.datasets[0].data.length > 0 ?
                                                <div className="w-100">
                                                    <Line data={totalChargesAmount} height={150} options={optionsLine} />
                                                </div>
                                             : ''}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </BlockUi>
                    </div>
                </Card>
            </div>
        );
    }
}
export default injectIntl(ProfileOverview)